import React from 'react';
import { useAuth } from './useAuth';
import {
  LocationImageAndText,
  CoachImageAndText,
} from '../../modules/service/models/service.model';

import ServicesService from '../../data/services/services.service';
import SessionProvider from '../../providers/SessionProvider';
import { useOrgId } from '.';
import { SelectedService as AvailableService } from '../../modules/book/models/booking.model';
import { Api } from '../../api/Api';

/**
 * Temporarry solution until we implement redux on all parts of the app.
 * @returns
 */

export const useFetchPurchasedLessonPacks = () => {
  const auth = useAuth();
  const { orgId } = useOrgId();

  const [purchasedServices, setPuchasedServices] = React.useState<AvailableService[]>([]);
  const [locations, setLocations] = React.useState<LocationImageAndText[]>([]);
  const [coaches, setCoaches] = React.useState<CoachImageAndText[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchServices = async () => {
    const isAuth = await auth.doAuthLogic();
    if (!isAuth) return;

    const userId = SessionProvider.getUserId();
    const services: AvailableService[] = await ServicesService.getServicesForUserAsync(
      orgId,
      userId,
    );

    const purchasedServicesList = services.filter((service) =>
      ServicesService.isPurchased(service),
    );

    setPuchasedServices(purchasedServicesList);
  };

  const fetchLocations = async (): Promise<LocationImageAndText[]> => {
    try {
      const response = await Api.ClientRoutes.Org.getLocationsImageAndText({ orgId });
      setLocations(response.data);
      return response.data;
    } catch (e) {
      console.log('There are errors in fetching locations');
    }
    return [];
  };

  const fetchCoaches = async (): Promise<CoachImageAndText[]> => {
    try {
      const response = await Api.ClientRoutes.Org.getCoachesImageAndText({ orgId });
      setCoaches(response.data);
      return response.data;
    } catch (e) {
      console.log('There are errors in fetching coaches');
    }
    return [];
  };

  const fetchAll = async () => {
    setIsLoading(true);
    await fetchServices();
    await fetchLocations();
    await fetchCoaches();
    setIsLoading(false);
  };

  React.useEffect(() => {
    fetchAll();
  }, []);

  return {
    isLoading,
    purchasedServices,
    locations,
    coaches,
  };
};
