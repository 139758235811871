import { LessonClientStatus } from 'data/entities/lessons.entity';
import { getUnixMilliseconds } from 'common/utils/date.helpers';
import { OrgCountLessons, OrgLessonCategories } from '../entities/orgLessonCategories.entity';
import { Config } from '../../config';
import { BaseRepo } from './base.repo';

export class OrgLessonCategoryRepository extends BaseRepo {
  // #region Private Properties
  private orgLessonCategoriesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('org_lesson_categories');
  // #endregion

  // #region Public Methods
  async getByOrgIdAsync(orgId: string): Promise<OrgLessonCategories | undefined> {
    return this.orgLessonCategoriesCollection?.findOne({ org_id: orgId });
  }
  // #endregion

  // #region Public Methods
  async getLessonsCountByOrgIdUserIdAsync(
    userId: string,
    orgId: string,
  ): Promise<OrgCountLessons[] | []> {
    const currentTime = getUnixMilliseconds();
    return this.orgLessonCategoriesCollection?.aggregate([
      {
        $unwind: '$lesson_categories',
      },
      {
        $match: {
          org_id: orgId,
        },
      },
      {
        $lookup: {
          from: 'lessons',
          localField: 'lesson_categories._id',
          foreignField: 'org_lesson_category_ids',
          as: 'cat_lessons',
          pipeline: [
            {
              $match: {
                clients: {
                  $elemMatch: {
                    user_id: userId,
                    status: LessonClientStatus.Accepted,
                  },
                },
                date_start: { $lt: currentTime },
                $or: [
                  {
                    cancellation: null,
                  },
                  {
                    'cancellation.is_cancelled': false,
                  },
                ],
              },
            },
          ],
        },
      },
      {
        $group: {
          _id: '$lesson_categories._id',
          count: { $sum: { $size: '$cat_lessons' } },
        },
      },
    ]);
  }
  // #endregion
}
