import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { EmergencyContact } from 'data/entities/profile.entity';
import { Textfield } from '../../../../components/Textfield';
import { Grid } from '../../../../components/Grid';
import { Button } from '../../../../components/Button/MuiButtons/Button';
import UserService from '../../../../data/services/user.service';
import { UserDetailInfoState } from './useProfile';
import { Phone, emptyPhoneValue } from '../../../../components/PhoneField';
import { EmergencyPerson } from './EmergencyPerson';

interface EmergencyInformationProps extends EmergencyInformationFormFields {
  userId: string;
  orgId: string;
  refetch: () => void;
}

interface EmergencyInformationFormFields {
  profileInfo: UserDetailInfoState;
}

export const EmergencyInformation = ({
  userId,
  orgId,
  profileInfo,
  refetch,
}: EmergencyInformationProps) => {
  const theme = useTheme();
  const isViewedFromMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [profileInfoChanges, setProfileInfoChanges] = React.useState(0);
  const [form, setForm] = React.useState<EmergencyInformationFormFields>({
    profileInfo,
  });

  const getProfileValue = (key: keyof UserDetailInfoState): string =>
    (form.profileInfo[key] as string) ?? '';

  const handleProfileChange = (key: keyof UserDetailInfoState, value: string) => {
    setForm({ ...form, profileInfo: { ...form.profileInfo, [key]: value } });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleEmergencyPersonFieldChange = (
    index: number,
    key: keyof EmergencyContact,
    value: string,
  ) => {
    const tmpUpdatedProfileInfo = { ...form.profileInfo };
    if (
      tmpUpdatedProfileInfo.emergency_contacts &&
      tmpUpdatedProfileInfo.emergency_contacts[index]
    ) {
      if (key !== 'contact') {
        tmpUpdatedProfileInfo.emergency_contacts[index][key] = value;
      }
    }
    setForm({ profileInfo: tmpUpdatedProfileInfo });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleEmergencyPersonPhoneFieldChange = (index: number, value: Phone) => {
    const tmpUpdatedProfileInfo = { ...form.profileInfo };
    if (
      tmpUpdatedProfileInfo.emergency_contacts &&
      tmpUpdatedProfileInfo.emergency_contacts[index]
    ) {
      tmpUpdatedProfileInfo.emergency_contacts[index].contact = value;
    }
    setForm({ profileInfo: tmpUpdatedProfileInfo });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleProfileInfoSubmit = async () => {
    const { allergies, medication, emergency_contacts } = form.profileInfo;
    const updates = {
      allergies,
      medication,
      emergency_contacts,
    };
    await UserService.updateUserDetailsV2({ userId, orgId, updates });
  };

  /** Final submit */
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (profileInfoChanges) {
      await handleProfileInfoSubmit();
      setProfileInfoChanges(0);
    }
    await refetch();
    setIsSubmitting(false);
    setIsEditMode(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Stack
          direction="row"
          justifyContent={isViewedFromMobile ? 'flex-start' : 'flex-end'}
          alignItems="center"
        >
          <FormGroup>
            <FormControlLabel
              control={<Switch color="success" checked={isEditMode} />}
              label="Edit mode"
              onChange={() => setIsEditMode(!isEditMode)}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid item md={12}>
        <Textfield
          isEditMode={isEditMode}
          label="Allergies"
          value={getProfileValue('allergies')}
          onChange={(value) => {
            handleProfileChange('allergies', value);
          }}
        />
      </Grid>
      <Grid item md={12}>
        <Textfield
          isEditMode={isEditMode}
          label="Medication"
          value={getProfileValue('medication')}
          onChange={(value) => {
            handleProfileChange('medication', value);
          }}
        />
      </Grid>
      <Grid item md={12}>
        {form.profileInfo.emergency_contacts?.map((contact, index) => {
          const firstName = contact.first_name ?? '';
          const lastName = contact.last_name ?? '';
          const relationship = contact.relationship ?? '';
          const phone: Phone = contact.contact ? (contact.contact as Phone) : emptyPhoneValue;

          return (
            <EmergencyPerson
              key={index}
              isEditMode={isEditMode}
              groupLabel={`Contact person #${index + 1}`}
              firstName={{
                value: firstName,
                onChange: (value) => {
                  handleEmergencyPersonFieldChange(index, 'first_name', value);
                },
              }}
              lastName={{
                value: lastName,
                onChange: (value) => {
                  handleEmergencyPersonFieldChange(index, 'last_name', value);
                },
              }}
              phone={{
                value: phone,
                onChange: (value) => {
                  handleEmergencyPersonPhoneFieldChange(index, value);
                },
              }}
              relationship={{
                value: relationship,
                onChange: (value) => {
                  handleEmergencyPersonFieldChange(index, 'relationship', value);
                },
              }}
            />
          );
        })}
      </Grid>

      {isEditMode && (
        <Grid item md={12}>
          <Stack direction="row" justifyContent="center" alignItems="center" columnGap={1}>
            <Button
              label={!isSubmitting ? 'Save' : 'Updating Profile ...'}
              disabled={isSubmitting}
              onClick={handleSubmit}
            />
            <Button
              label="Cancel"
              disabled={isSubmitting}
              variant="outlined"
              onClick={() => setIsEditMode(false)}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};
