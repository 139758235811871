import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { bookingSlice, BookingStep, SelectedService } from 'redux/slices';
import { ScheduledDate } from 'modules/service/models/service.model';
import { BrandThemeContext } from 'common/context';
import {
  BookingContainer,
  LessonsGroup,
  PrepaidLesson,
  Lesson,
  LessonPack,
  Programme,
} from './components';
import type { RootState } from '../../../../redux/store';
import ServicesService from '../../../../data/services/services.service';
import { useOrgId } from '../../../../common/hooks';
import { useAuth } from '../../../../common/hooks/useAuth';
import SessionProvider from '../../../../providers/SessionProvider';
import {
  SelectedService as AvailableService,
  SelectedServicePackagesBought,
} from '../../models/booking.model';
import { ServiceTypeEnum } from '../../../../data/enums/ServiceType.enum';
import { useBooking, useService } from './hooks';
import { Grid } from '../../../../components/Grid';
import { Event } from './components/lessons/selections/Event';

interface UpdateLessonCountInput {
  serviceId: string;
  coachId?: string;
  operation: 'increase' | 'decrease';
}

interface UpdateLessoncoachIdInput {
  serviceId: string;
  coachId: string;
}

interface GetTooltipLabelInput {
  total: number;
  singular: string;
  plural: string;
}

export const BookingSelectService = () => {
  const theme = useContext(BrandThemeContext);
  const { orgId } = useOrgId();
  const [isLoading, setIsLoading] = useState(true);
  const { handleResetBooking } = useBooking();

  const auth = useAuth();
  const booking = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  const {
    getServiceDetails,
    getOrgDetails,
    isEvent,
    isProgramme,
    isAdvancedLesson,
    getUpdatedServicesWithAdditionalData,
    isTimeslotSelectionFlowNeeded,
  } = useService();

  /** Functions to limit users to purchase from the same type */
  const selectedServices = booking.selected.services;
  const availableServices = booking.data.services;

  /** The following 2 lines needs to use double exclamation mark to work as it is basically converting null -> boolean */
  const isLessonInvite = !!booking.selected.lessonInviteId;
  const isServiceInvite = !!booking.selected.serviceInviteId;
  const isLessonPackTimeSlotSelection = !!booking.selected.isLessonPackTimeSlotSelection;
  const shouldFetchServices = !isLessonInvite && !isServiceInvite && !isLessonPackTimeSlotSelection;

  /** Handlers */
  const fetchServices = async () => {
    const isAuth = await auth.doAuthLogic();

    /** First get both coachIds and locationIds without changing the state */
    let coachIds: string[] = [];
    let locationIds: string[] = [];

    if (booking.selected.coachId) {
      coachIds = [booking.selected.coachId];
      locationIds = await ServicesService.getLocationsCoachIsOfferingServicesAsync(
        booking.selected.coachId,
        orgId,
      );
    }

    if (booking.selected.locationId) {
      locationIds = [booking.selected.locationId];
      coachIds = await ServicesService.getCoachesByServiceLocationAsync(
        booking.selected.locationId,
        orgId,
      );
    }

    let availableServicesFromApi: AvailableService[] = [];

    if (isAuth) {
      const userId = SessionProvider.getUserId();
      const email = SessionProvider.getEmail();
      availableServicesFromApi = await ServicesService.getServiceByCoachesAndLocationsForUserAsync(
        orgId,
        userId,
        email,
        coachIds,
        locationIds,
      );
    } else {
      availableServicesFromApi = await ServicesService.getServicesByCoachesAndLocationsAsync(
        orgId,
        coachIds,
        locationIds,
      );
    }

    if (isServiceInvite) {
      const availableServiceIds = availableServices.map((service: AvailableService) => service.id);
      availableServicesFromApi = availableServicesFromApi.filter((service: AvailableService) =>
        availableServiceIds.includes(service.id),
      );
    }

    dispatch(bookingSlice.actions.getServices(availableServicesFromApi));
  };

  const fetchAll = async () => {
    setIsLoading(true);
    await getOrgDetails(orgId);

    // Check if it is related to invitation
    if (shouldFetchServices) await fetchServices();
    setIsLoading(false);
  };

  const getCurrentCount = (serviceId: string): number => {
    const selectedService = booking.selected.services.find((service) => service.id === serviceId);
    if (selectedService) return selectedService.count ?? 0;
    return 0;
  };

  const updateLessonCoachId = ({ serviceId, coachId }: UpdateLessoncoachIdInput) => {
    const updatedServices = booking.selected.services.map((service: SelectedService) => {
      if (service.id === serviceId)
        return {
          ...service,
          coachId,
        };
      return service;
    });

    dispatch(bookingSlice.actions.updateSelectedServices(updatedServices));
  };

  const updateLessonCount = ({ serviceId, coachId, operation }: UpdateLessonCountInput) => {
    const serviceDetails = getServiceDetails(serviceId);
    if (serviceDetails) {
      let tmpSelectedServices = JSON.parse(
        JSON.stringify(booking.selected.services),
      ) as SelectedService[];
      const selectedServiceIndex = tmpSelectedServices.findIndex(
        (service) => service.id === serviceId,
      );

      /** If service has been added before (we just need to increment the qty) */
      if (selectedServiceIndex >= 0) {
        let currentCount = tmpSelectedServices[selectedServiceIndex].count ?? 0;
        if (operation === 'increase') {
          /** Increase */
          currentCount += 1;
        } else {
          /** Decrease */
          currentCount = currentCount - 1 >= 0 ? currentCount - 1 : 0;
          if (tmpSelectedServices[selectedServiceIndex].scheduledDates.length > 0)
            tmpSelectedServices[selectedServiceIndex].scheduledDates.pop();
        }
        tmpSelectedServices[selectedServiceIndex] = {
          ...tmpSelectedServices[selectedServiceIndex],
          count: currentCount,
        };
      } else if (operation === 'increase') {
        /** Service has not been added before, we cannot simply update the QTY */
        /** Only assign scheduled dates when it is Adv Lesson, Programme/Event (pay all at once) */
        let scheduledDates: ScheduledDate[] = [];
        if (isAdvancedLesson(serviceDetails)) scheduledDates = serviceDetails.scheduledDates;
        if (isProgramme(serviceDetails) || isEvent(serviceDetails)) {
          if (serviceDetails.isPackage) {
            scheduledDates = serviceDetails.scheduledDates;
          }
        }

        tmpSelectedServices.push({
          id: serviceId,
          coachId: coachId ?? '',
          count: 1,
          isCalendarActive: false,
          scheduledDates,
          addedAt: DateTime.now().toMillis(),
          packagesBought: serviceDetails.packagesBought as SelectedServicePackagesBought,
        });
      }

      tmpSelectedServices = tmpSelectedServices.map((service) => ({
        ...service,
        isCalendarActive: false,
      }));

      if (tmpSelectedServices[0]) {
        tmpSelectedServices[0].isCalendarActive = true;
      }

      dispatch(
        bookingSlice.actions.updateSelectedServices(
          tmpSelectedServices.filter((service) => service.count > 0),
        ),
      );
    }
  };

  const decreaseLessonCount = ({
    serviceId,
    coachId,
  }: Omit<UpdateLessonCountInput, 'operation'>) => {
    updateLessonCount({ serviceId, coachId, operation: 'decrease' });
  };

  const increaseLessonCount = (params: Omit<UpdateLessonCountInput, 'operation'>) => {
    const { serviceId } = params;
    const serviceDetails = getServiceDetails(serviceId);
    const coachId =
      !params.coachId && serviceDetails?.coaches && serviceDetails?.coaches.length < 2
        ? serviceDetails?.coaches[0].id
        : params.coachId;

    if (!serviceDetails) return;

    if (serviceDetails.isPackage && !serviceDetails.unscheduledPackageOccurrences) {
      if (getCurrentCount(serviceId) < 1) {
        updateLessonCount({ serviceId, coachId, operation: 'increase' });
      }
    } else {
      let maxCount;
      if (serviceDetails.scheduledDates && serviceDetails.scheduledDates.length) {
        maxCount = serviceDetails.scheduledDates.length;
      }

      if (maxCount) {
        if (getCurrentCount(serviceId) < maxCount) {
          updateLessonCount({ serviceId, coachId, operation: 'increase' });
        }
      } else {
        updateLessonCount({ serviceId, coachId, operation: 'increase' });
      }
    }
  };

  /** Different types of services, and we will display them differently */
  const purchasedServices = booking.data.services.filter((service) =>
    ServicesService.isPurchased(service),
  );

  const lessons = booking.data.services.filter(
    (service) => service.serviceTypeEnum === ServiceTypeEnum.Lesson,
  );

  const lessonPacksAndNotPurchased = booking.data.services.filter(
    (service) =>
      service.serviceTypeEnum === ServiceTypeEnum.Package && !ServicesService.isPurchased(service),
  );

  const programmes = booking.data.services.filter(
    (service) => service.serviceTypeEnum === ServiceTypeEnum.Programme,
  );

  const events = booking.data.services.filter(
    (service) => service.serviceTypeEnum === ServiceTypeEnum.Event,
  );

  const countService = booking.selected.services.filter(
    (service) => (service.count ?? 0) > 0,
  ).length;

  const getSelectedServicesTotal = (): number => {
    let total = 0;
    booking.selected.services.forEach((selectedService) => {
      total += selectedService.count;
    });
    return total;
  };

  const areSelectedServicesBelongToList = (services: AvailableService[]): boolean => {
    /** Later on, we might need to enable based on service type, not ID */
    if (!selectedServices || !selectedServices.length) return false;
    if (!services || !services.length) return false;

    const mainServiceIds = services.map((service) => service.id);
    return selectedServices.every((selectedService) => mainServiceIds.includes(selectedService.id));
  };

  const getSectionServiceTotal = (services: AvailableService[]): number => {
    return areSelectedServicesBelongToList(services) ? getSelectedServicesTotal() : 0;
  };

  const getTooltipLabel = ({ total, singular, plural }: GetTooltipLabelInput): string => {
    switch (total) {
      case 0:
        return '';
      case 1:
        return singular;
      default:
        return plural;
    }
  };

  const getTooltipMessage = (incomingServiceType: string): string => {
    if (!booking.selected.services || !booking.selected.services.length) return '';

    /** Calculate total */
    const total = getSelectedServicesTotal();

    /** Get service title text */
    let selectedServiceTypes = '';

    if (getSectionServiceTotal(purchasedServices)) {
      selectedServiceTypes = getTooltipLabel({
        total,
        singular: 'purchased lesson pack',
        plural: 'purchased lesson packs',
      });
    }

    if (getSectionServiceTotal(lessons)) {
      selectedServiceTypes = getTooltipLabel({
        total,
        singular: 'lesson',
        plural: 'lessons',
      });
    }

    if (getSectionServiceTotal(lessonPacksAndNotPurchased)) {
      selectedServiceTypes = getTooltipLabel({
        total,
        singular: 'lesson pack',
        plural: 'lesson packs',
      });
    }

    if (getSectionServiceTotal(programmes)) {
      selectedServiceTypes = getTooltipLabel({
        total,
        singular: 'programme',
        plural: 'programmes',
      });
    }

    if (getSectionServiceTotal(events)) {
      selectedServiceTypes = getTooltipLabel({
        total,
        singular: 'event',
        plural: 'events',
      });
    }

    return `
      You have ${total} ${selectedServiceTypes} selected. To add ${
      incomingServiceType === 'event' ? 'an' : 'a'
    } ${incomingServiceType}, either clear your selection, or
      purchase the ${selectedServiceTypes}, then start again.
    `;
  };

  const isServiceWithMultipleCoaches = (id: string): boolean => {
    const service = getServiceDetails(id);
    return service ? service && service.coaches && service.coaches.length > 1 : false;
  };

  const areAllSelectedServicesCoached = () => {
    const selectedServicesWithoutCoach = booking.selected.services.filter(
      (service) => !service.coachId && isServiceWithMultipleCoaches(service.id),
    );
    return selectedServicesWithoutCoach.length <= 0;
  };

  const isFirstSelectedServiceAPackage = (): boolean | null => {
    if (!booking.selected.services.length) return null;

    const firstServiceDetails = getServiceDetails(booking.selected.services[0].id);
    if (!firstServiceDetails) return null;

    return firstServiceDetails?.isPackage;
  };

  const isServiceDisabled = (serviceId: string) => {
    const serviceDetails = getServiceDetails(serviceId) as AvailableService;

    /** If first selected service is not a package, then disable all packages */
    if (isFirstSelectedServiceAPackage() === false) {
      return serviceDetails.isPackage;
    }

    /** If first selected service is a package, then disable all other services since user can only purchase 1 package at a time */
    return !!(
      booking.selected.services.length &&
      booking.selected.services[0].id !== serviceId &&
      isFirstSelectedServiceAPackage() === true
    );
  };

  const isLessonGroupDisabled = (services: AvailableService[]): boolean => {
    if (selectedServices.length) {
      return !areSelectedServicesBelongToList(services);
    }
    return false;
  };

  const handleContinueBtnClick = () => {
    const { updatedDetailedServices, updatedSelectedServices } =
      getUpdatedServicesWithAdditionalData();

    dispatch(bookingSlice.actions.getServices(updatedDetailedServices));
    dispatch(bookingSlice.actions.updateSelectedServices(updatedSelectedServices));

    const nextStep: BookingStep = isTimeslotSelectionFlowNeeded()
      ? BookingStep.SelectTime
      : BookingStep.Summary;
    dispatch(bookingSlice.actions.changeStep(nextStep));
  };

  const handleClearSelectionBtnClick = () => {
    dispatch(bookingSlice.actions.updateSelectedServices([]));
  };

  useEffect(() => {
    fetchAll();
  }, []);

  /** Decide which service type dropdown that will be expanded */
  let totalServiceTypeDisplayed = 0;
  if (purchasedServices.length > 0) totalServiceTypeDisplayed++;
  if (lessons.length > 0) totalServiceTypeDisplayed++;
  if (lessonPacksAndNotPurchased.length > 0) totalServiceTypeDisplayed++;
  if (programmes.length > 0) totalServiceTypeDisplayed++;
  if (events.length > 0) totalServiceTypeDisplayed++;

  return (
    <BookingContainer
      isLoading={isLoading}
      title="Select service section"
      backButton={{
        onClick: () => {
          handleResetBooking(false);
          dispatch(bookingSlice.actions.changeStep(BookingStep.FindCoachLocation));
        },
      }}
      continueButton={{
        disabled: countService < 1 || !areAllSelectedServicesCoached(),
        onClick: handleContinueBtnClick,
      }}
    >
      <Grid container>
        {!booking.data.services.length && (
          <Grid item md={12}>
            <Box textAlign="center" mb={4} mt={4} color={theme.colors.red.opacity_100}>
              No services found. Try updating your search preferences.
            </Box>
          </Grid>
        )}

        {booking.selected.services.length > 0 && (
          <Grid item md={12}>
            <Box textAlign="right" mb={1} mt={4}>
              <Box
                display="inline-block"
                sx={{
                  cursor: 'pointer',
                  color: theme.colors.jungleGreen.opacity_100,
                  textDecoration: 'underline',
                }}
                onClick={handleClearSelectionBtnClick}
              >
                Clear selection
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item md={12}>
          {purchasedServices.length > 0 && (
            <LessonsGroup
              title="Purchased lesson packs"
              total={getSectionServiceTotal(purchasedServices)}
              disabled={isLessonGroupDisabled(purchasedServices)}
              tooltipMessage={getTooltipMessage('purchased lesson pack')}
              isExpanded
            >
              {purchasedServices.map((service, index) => {
                return (
                  <PrepaidLesson
                    key={index}
                    id={service.id}
                    counter={{
                      count: getCurrentCount(service.id),
                      onDecrease: () =>
                        decreaseLessonCount({
                          serviceId: service.id,
                        }),
                      onIncrease: () =>
                        increaseLessonCount({
                          serviceId: service.id,
                        }),
                      onChangeCoach(coachId) {
                        updateLessonCoachId({ serviceId: service.id, coachId });
                      },
                    }}
                  />
                );
              })}
            </LessonsGroup>
          )}

          {lessons.length > 0 && (
            <LessonsGroup
              title="Lessons"
              total={getSectionServiceTotal(lessons)}
              disabled={isLessonGroupDisabled(lessons)}
              tooltipMessage={getTooltipMessage('lesson')}
              isExpanded={totalServiceTypeDisplayed === 1}
            >
              {lessons.map((service, index) => {
                return (
                  <Lesson
                    key={index}
                    id={service.id}
                    counter={{
                      count: getCurrentCount(service.id),
                      onDecrease: () =>
                        decreaseLessonCount({
                          serviceId: service.id,
                        }),
                      onIncrease: (coachId?: string) => {
                        increaseLessonCount({
                          serviceId: service.id,
                          coachId,
                        });
                      },
                      onChangeCoach(coachId) {
                        updateLessonCoachId({ serviceId: service.id, coachId });
                      },
                    }}
                  />
                );
              })}
            </LessonsGroup>
          )}

          {lessonPacksAndNotPurchased.length > 0 && (
            <LessonsGroup
              title="Lesson Packs"
              total={getSectionServiceTotal(lessonPacksAndNotPurchased)}
              disabled={isLessonGroupDisabled(lessonPacksAndNotPurchased)}
              tooltipMessage={getTooltipMessage('lesson pack')}
              isExpanded={totalServiceTypeDisplayed === 1}
            >
              {lessonPacksAndNotPurchased.map((service, index) => {
                return (
                  <LessonPack
                    key={index}
                    id={service.id}
                    counter={{
                      count: getCurrentCount(service.id),
                      onDecrease: () =>
                        decreaseLessonCount({
                          serviceId: service.id,
                        }),
                      onIncrease: () =>
                        increaseLessonCount({
                          serviceId: service.id,
                        }),
                      onChangeCoach(coachId) {
                        updateLessonCoachId({ serviceId: service.id, coachId });
                      },
                    }}
                  />
                );
              })}
            </LessonsGroup>
          )}

          {programmes.length > 0 && (
            <LessonsGroup
              title="Programmes"
              total={getSectionServiceTotal(programmes)}
              disabled={isLessonGroupDisabled(programmes)}
              tooltipMessage={getTooltipMessage('programme')}
              isExpanded={totalServiceTypeDisplayed === 1}
            >
              {programmes.map((service, index) => {
                if (isServiceDisabled(service.id)) return null;
                return (
                  <Programme
                    key={index}
                    id={service.id}
                    counter={{
                      count: getCurrentCount(service.id),
                      onDecrease: () =>
                        decreaseLessonCount({
                          serviceId: service.id,
                        }),
                      onIncrease: () =>
                        increaseLessonCount({
                          serviceId: service.id,
                        }),
                      onChangeCoach(coachId) {
                        updateLessonCoachId({ serviceId: service.id, coachId });
                      },
                    }}
                  />
                );
              })}
            </LessonsGroup>
          )}

          {events.length > 0 && (
            <LessonsGroup
              title="Events"
              total={getSectionServiceTotal(events)}
              disabled={isLessonGroupDisabled(events)}
              tooltipMessage={getTooltipMessage('event')}
              isExpanded={totalServiceTypeDisplayed === 1}
            >
              {events.map((service, index) => {
                return (
                  <Event
                    key={index}
                    id={service.id}
                    counter={{
                      count: getCurrentCount(service.id),
                      onDecrease: () =>
                        decreaseLessonCount({
                          serviceId: service.id,
                        }),
                      onIncrease: () =>
                        increaseLessonCount({
                          serviceId: service.id,
                        }),
                      onChangeCoach(coachId) {
                        updateLessonCoachId({ serviceId: service.id, coachId });
                      },
                    }}
                  />
                );
              })}
            </LessonsGroup>
          )}
        </Grid>
      </Grid>
    </BookingContainer>
  );
};
