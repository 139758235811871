import React, { useRef, useState } from 'react';
import { Avatar } from '@mui/material';
import { User } from 'data/entities/users.entity';
import { AvatarHelper } from 'common/utils/avatar.helpers';
import SessionProvider from 'providers/SessionProvider';
import styles from './ClientNotesItem.module.scss';
import DropdownIcon from '../../../../assets/icons/menu/meatball-menu.svg';
import DropdownMenu from '../../../../components/DropdownMenu/DropdownMenu';

interface IProps {
  id: string;
  text: string;
  onClientNoteRemoved?: any;
  onShowUpdateModal: any;
  clients: User[];
  userId: string;
}

const ClientNotesItem: React.FC<IProps> = ({
  id,
  text,
  onClientNoteRemoved,
  onShowUpdateModal,
  clients,
  userId,
}) => {
  let user = null;
  const currentUserId = SessionProvider.getUserId();
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const index = clients.findIndex((item: User) => item._id === userId);
  if (index >= 0) user = clients[index];

  const dropdownAvatarTriggerRef = useRef(null);

  const onDropdownClick = () => {
    setDropdownOpened(!dropdownOpened);
  };

  const dropdownItems = [
    {
      label: 'Edit',
      onClick: () => {
        onShowUpdateModal(id, true);
      },
    },
    {
      label: 'Remove',
      onClick: () => {
        onClientNoteRemoved(id);
      },
    },
  ];

  return (
    <div className={styles.ClientNotesItem}>
      <div className={styles.noteSection}>
        {user && currentUserId !== user._id && (
          <div className={styles.avatarSection}>
            {user.detail?.avatar_file && user.detail?.avatar_file.length > 0 ? (
              <Avatar
                className={styles.avatar}
                alt={`${user.first_name} ${user.last_name}`}
                src={user.detail?.avatar_file[0].file_url ?? ''}
                sx={{
                  width: '32px',
                  height: '32px',
                }}
              />
            ) : (
              <Avatar className={styles.avatar} sx={{ width: '32px', height: '32px' }}>
                {AvatarHelper.stringToAvatar({
                  firstName: user.first_name,
                  lastName: user.last_name,
                })}
              </Avatar>
            )}

            <span className={styles.userName}>
              {user.first_name} {user.last_name}
            </span>
          </div>
        )}
        <div className={styles.note}>{text}</div>
      </div>
      {currentUserId === userId && (
        <div
          ref={dropdownAvatarTriggerRef}
          className={styles.dropdownItem}
          onClick={onDropdownClick}
        >
          <img alt="dropdown-icon" className={styles.dropdownIcon} src={DropdownIcon} />
          <div className={styles.dropdown}>
            <DropdownMenu
              visible={dropdownOpened}
              onCloseDropdown={() => setDropdownOpened(false)}
              outsideRefsIgnore={dropdownAvatarTriggerRef}
              data={dropdownItems}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientNotesItem;
