import React from 'react';
import { EventType } from 'data/entities/lessons.entity';
import { Carousel } from '../../../../components/Carousel';

import styles from './NewsFeed.module.scss';
import NewsItem from '../NewsItem/NewsItem';
import { OrgBookingRules } from '../../../book/models/booking.model';

interface INewsFeed {
  onOpenEvent?: Function;
  setEventData?: Function;
  activityFeed: any;
  deleteEvent?: Function;
  cancelLesson?: Function;
  orgBookingRules?: OrgBookingRules;
  noItemsFoundText?: string;
  onCancelInvitation?: (eventType: EventType, id: string, inviteId: string) => void;
}

const NewsFeed: React.FC<INewsFeed> = ({
  onOpenEvent,
  setEventData,
  activityFeed,
  deleteEvent,
  cancelLesson,
  orgBookingRules,
  noItemsFoundText,
  onCancelInvitation,
}) => {
  return (
    <div className={styles.wrapper}>
      {activityFeed.length > 0 ? (
        <Carousel
          navigation
          slides={activityFeed.map((item: any) => {
            return (
              <NewsItem
                key={item._id}
                feedItem={item}
                onOpenEvent={onOpenEvent}
                setEventData={setEventData}
                deleteEvent={deleteEvent}
                cancelLesson={cancelLesson}
                orgBookingRules={orgBookingRules}
                onCancelInvitation={onCancelInvitation}
              />
            );
          })}
        />
      ) : (
        <div className={styles.empty}>
          {' '}
          {noItemsFoundText ?? 'You have no lessons, practice, or events. Book a lesson to begin.'}
        </div>
      )}
    </div>
  );
};
export default NewsFeed;
