import * as Realm from 'realm-web';
import { Config } from '../../config';

export class BaseRepo {
  // #region Private Variables
  private readonly _app: Realm.App;

  private _mongo;
  // #endregion

  // #region CTOR
  constructor() {
    this._app = new Realm.App({ id: Config().RealmAppId as string });
    this._mongo = this._app.currentUser?.mongoClient(Config().RealmClusterName as string);
  }
  // #endregion

  // #region Getters/Setters
  public get app() {
    return this._app;
  }

  protected get mongo() {
    return this._mongo;
  }

  protected set mongo(mongoParam: globalThis.Realm.Services.MongoDB | undefined) {
    this._mongo = mongoParam;
  }
  // #endregion

  // #region Methods

  /**
   * Signs the user in as anonymous to allow queries to the db
   * @returns new mongodb value
   */
  protected async LogUserInAnonymously() {
    const credentials = Realm.Credentials.anonymous();
    const user = await this.app.logIn(credentials);
    const mongo = user.mongoClient(Config().RealmClusterName as string);
    this.mongo = mongo;
    return mongo;
  }

  /**
   * If the current user is null, it will then log them in anonymously and return the new collection object.
   * @param collectionName collection name in mongodb (e.g. services, user_details)
   * @param collectionObject MongoDbCollection object
   * @returns
   */
  protected async AllowAnonymousQuery(
    collectionName: string,
    collectionObject: globalThis.Realm.Services.MongoDB.MongoDBCollection<any>,
  ) {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      return mongo.db(Config().RealmDbName as string).collection(collectionName);
    }

    return collectionObject;
  }

  // #endregion
}
