import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Textfield } from '../../../../components/Textfield';
import { Grid } from '../../../../components/Grid';
import { PhoneField, Phone } from '../../../../components/PhoneField';

interface EmergencyPersonProps {
  isEditMode: boolean;
  groupLabel: string;
  firstName: EmergencyPersonFieldProps;
  lastName: EmergencyPersonFieldProps;
  relationship: EmergencyPersonFieldProps;
  phone: EmergencyPersonPhoneFieldProps;
}

interface EmergencyPersonFieldProps {
  value: string;
  onChange: (value: string) => void;
}

interface EmergencyPersonPhoneFieldProps {
  value: Phone;
  onChange: (value: Phone) => void;
}

export const EmergencyPerson = (props: EmergencyPersonProps) => {
  const { isEditMode, firstName, lastName, relationship, phone, groupLabel } = props;
  return (
    <Box marginTop={2} paddingTop={2}>
      <Typography fontWeight="bold">{groupLabel}</Typography>
      <Box border={1} borderRadius={1} borderColor="#eeeeee" padding={2} marginTop={2}>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              label="First name"
              value={firstName.value}
              onChange={firstName.onChange}
            />
          </Grid>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              label="Last name"
              value={lastName.value}
              onChange={lastName.onChange}
            />
          </Grid>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              label="Relationship"
              value={relationship.value}
              onChange={relationship.onChange}
            />
          </Grid>
          <Grid item md={6}>
            <PhoneField
              label="Phone number"
              value={phone.value}
              defaultCountry="nz"
              isEditMode={isEditMode}
              onChange={phone.onChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
