import React from 'react';

interface CalendarContextValue {
  isLoading: boolean;
}

const defaultCalendarContextValue: CalendarContextValue = {
  isLoading: false,
};

export const CalendarContext = React.createContext(defaultCalendarContextValue);
