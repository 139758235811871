import React, { useState } from 'react';
import styles from './SignupEmailVerificationPage.module.scss';
import { Api } from '../../../api/Api';

const SignupEmailVerificationPage = () => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token') || '';
  const tokenId = params.get('tokenId') || '';
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const verifyToken = () => {
    Api.ClientRoutes.Auth.verifySingupEmail(token, tokenId)
      .then(() => setVerificationSuccess(true))
      .catch((err) => {
        setVerificationSuccess(false);
        setErrorMessage(err.message);
        console.error(err, 'verification error');
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.wrapper}>
          <h1>Avgar Pro</h1>
        </div>
        <div className={styles.wrapper}>
          <h3>Thank you for signing up!</h3>
        </div>
        <div className={styles.wrapper}>
          <span>Click below to</span>
        </div>
        <div className={styles.wrapper}>
          <button type="button" className={styles.button} onClick={verifyToken}>
            Verify
          </button>
        </div>
      </div>

      {!loading && verificationSuccess && (
        <>
          <div className={styles.wrapper}>
            <span>You have successfully verified your email</span>
          </div>
          <div className={styles.wrapper}>
            <span>You can close this window now</span>
          </div>
        </>
      )}
      {!loading && !verificationSuccess && (
        <>
          <div className={styles.wrapper}>
            <span>Something went wrong with the verification</span>
          </div>
          <div className={styles.wrapper}>
            <span>{errorMessage}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default SignupEmailVerificationPage;
