import React from 'react';

import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import styles from './DeleteAttachmentView.module.scss';
import { OkButtonType } from '../../../../components/Button/Button';

interface DeleteAttachmentViewProps {
  visible: boolean;
  onClose: Function;
  onDeleteAttachment: Function;
  okButtonDisable: boolean;
}

const DeleteAttachmentView: React.FC<DeleteAttachmentViewProps> = ({
  visible,
  onClose,
  onDeleteAttachment,
  okButtonDisable,
}) => (
  <>
    <div className={styles.visibleDesktop}>
      <ModalWindow
        show={visible}
        title="Are you sure you want to delete this attachment?"
        saveBtnLabel="Delete attachment"
        okButtonType={OkButtonType.Danger}
        okButtonDisabled={okButtonDisable}
        onCloseClick={onClose}
        onSaveClick={() => {
          onDeleteAttachment();
          onClose();
        }}
      >
        <span />
      </ModalWindow>
    </div>
    <div className={styles.visibleMobile}>
      <MobileDrawer
        visible={visible}
        title="Delete attachment"
        onClose={onClose}
        okButtonText="Delete attachment"
        cancelButtonText="Cancel"
        onClickOkButton={() => {
          onDeleteAttachment();
          onClose();
        }}
        okButtonType={OkButtonType.Danger}
      >
        <div>
          <div className={styles.title}>Are you sure you want to delete this attachment?</div>
        </div>
      </MobileDrawer>
    </div>
  </>
);

export default DeleteAttachmentView;
