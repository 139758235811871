/* eslint-disable no-underscore-dangle */
import React, { useState, useRef, memo, useCallback } from 'react';
import clsx from 'clsx';
import { Lesson } from 'data/entities/lessons.entity';
import { Attachment, AttachmentModel } from 'data/entities/attachment.entity';
import { ButtonSavingType } from 'data/enums/ButtonType.enum';
import SessionProvider from 'providers/SessionProvider';
import { AttachmentSectionIndex, CoachUploads } from '../../models/attachment';
import styles from './AttachmentsSection.module.scss';
import PdfIcon from '../../../../assets/images/FilePDF.svg';
import DropdownMenu from '../../../../components/DropdownMenu/DropdownMenu';
import AttachmentDeleteBtn from '../AttachmentDeleteBtn/AttachmentDeleteBtn';
import DeleteAttachmentView from '../DeleteAttachmentView/DeleteAttachmentView';
import PlayIcon from '../../../../assets/images/play.png';
import AddBtnIcon from '../../../../assets/icons/add-btn.svg';
import getResizedImageUri from '../../../../common/utils/getResizedImageUri.helpers';

interface AttachmentsSectionProps {
  sectionIndex: number;
  title: string;
  attachments: any[];
  onAddFile: (isSelfFlag: boolean) => void;
  setSelectedAttachment: Function;
  openAddNoteModal: Function;
  deleteAttachment: (attachment: Attachment, sectionIndex: number) => void;
  onOpenExercisesView: (exerciseId: string) => void;
  allowNewContent: boolean;
  lesson: Lesson;
  setSectionIndex: (index: number) => void;
  isNoContent: boolean;
  onAddExercise: (isExercise: boolean) => void;
  setIsNoteSaving: (status: number) => void;
}
const AttachmentsSection = ({
  sectionIndex,
  title,
  attachments,
  onAddFile,
  setSelectedAttachment,
  openAddNoteModal,
  deleteAttachment,
  onOpenExercisesView,
  allowNewContent = true,
  lesson,
  setSectionIndex,
  isNoContent,
  onAddExercise,
  setIsNoteSaving,
}: AttachmentsSectionProps) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [selectedToDeleteAttachment, setSelectedToDeleteAttachment] = useState(null);

  const userId = SessionProvider.getUserId();
  const dropdownTriggerRef = useRef<HTMLDivElement>(null);

  const onClickToAttachment = useCallback(
    (attachment: any) => {
      setIsNoteSaving(ButtonSavingType.save);
      return sectionIndex === CoachUploads
        ? onOpenExercisesView(attachment.exerciseObj._id)
        : setSelectedAttachment(attachment);
    },
    [onOpenExercisesView, sectionIndex, setSelectedAttachment],
  );

  return (
    <div className={styles.wrapper}>
      <DeleteAttachmentView
        okButtonDisable={
          selectedToDeleteAttachment !== null &&
          userId !== (selectedToDeleteAttachment as AttachmentModel).userId
        }
        visible={!!selectedToDeleteAttachment}
        onClose={() => setSelectedToDeleteAttachment(null)}
        onDeleteAttachment={() => {
          if (selectedToDeleteAttachment)
            deleteAttachment(selectedToDeleteAttachment, sectionIndex);
        }}
      />
      {isNoContent &&
        sectionIndex === AttachmentSectionIndex.ReasonOfLesson &&
        !lesson.is_self_taught && (
          <div className={styles.noContent}>Practice shared with coach:</div>
        )}
      <div className={styles.title}>
        <span>{title}</span>
        {(sectionIndex === AttachmentSectionIndex.ClientUploads ||
          (lesson.is_self_taught &&
            (sectionIndex === AttachmentSectionIndex.ReasonOfLesson ||
              sectionIndex === AttachmentSectionIndex.TheSolution ||
              sectionIndex === CoachUploads))) &&
          allowNewContent && (
            <div
              ref={dropdownTriggerRef}
              className={styles.addFileButton}
              onClick={() => setVisibleDropdown(!visibleDropdown)}
            >
              <div className={styles.dropDownWrapper}>
                <DropdownMenu
                  visible={visibleDropdown}
                  onCloseDropdown={() => setVisibleDropdown(false)}
                  outsideRefsIgnore={dropdownTriggerRef}
                  data={
                    sectionIndex !== CoachUploads
                      ? [
                          {
                            label: 'File',
                            onClick: () => {
                              setSectionIndex(sectionIndex);
                              onAddFile(
                                lesson.is_self_taught && !lesson.share_practice_lesson_with_coach,
                              );
                            },
                          },
                          {
                            label: 'Text note',
                            onClick: () => {
                              setSectionIndex(sectionIndex);
                              openAddNoteModal();
                            },
                          },
                          {
                            label: 'From favorites',
                            onClick: () => {
                              setSectionIndex(sectionIndex);
                              onAddExercise(false);
                            },
                          },
                        ]
                      : [
                          {
                            label: 'Coach Exercises',
                            onClick: () => {
                              setSectionIndex(sectionIndex);
                              onAddExercise(true);
                            },
                          },
                        ]
                  }
                />
              </div>
              {/* <div className={styles.labelAddBtn}> */}
              {sectionIndex === CoachUploads ? (
                'Add coach exercise from your library +'
              ) : (
                <img alt="add-btn-icon" src={AddBtnIcon} className={styles.addBtn} />
              )}
              {/* </div> */}
            </div>
          )}
      </div>
      {!attachments.length && (
        <div className={styles.emptySection}>
          <span>
            {sectionIndex === AttachmentSectionIndex.ClientUploads
              ? 'Upload a photo, video, file, or write a note for your coach to review'
              : ''}
          </span>
        </div>
      )}
      <div className={styles.attachments}>
        {attachments.map((attachment) => {
          const isFavoriteMark = clsx(
            attachment.isFavorite ? styles.attachmentIsFavorite : styles.mock,
          );

          if (attachment.uploading) {
            return (
              <div key={attachment?.fileObject?._id}>
                <div className={styles.progressBar} style={{ width: `${attachment.percent}%` }} />
              </div>
            );
          }

          if (!attachment.isFileAttachment && !attachment.exerciseObj) {
            return (
              <div
                className={`${styles.textItem} ${isFavoriteMark}`}
                key={attachment.attachmentId}
                onClick={() => onClickToAttachment(attachment)}
              >
                {(sectionIndex === AttachmentSectionIndex.ClientUploads ||
                  (lesson.is_self_taught &&
                    (sectionIndex === AttachmentSectionIndex.ReasonOfLesson ||
                      sectionIndex === AttachmentSectionIndex.TheSolution))) &&
                  userId === (attachment as AttachmentModel).userId && (
                    <AttachmentDeleteBtn
                      onClick={() => setSelectedToDeleteAttachment(attachment)}
                    />
                  )}
                <p>{attachment.noteContent}</p>
              </div>
            );
          }

          if (attachment.fileExtension?.toLowerCase() === 'pdf') {
            return (
              <div
                className={`${styles.pdfItem} ${isFavoriteMark}`}
                key={attachment.attachmentId}
                onClick={() => onClickToAttachment(attachment)}
              >
                {(sectionIndex === AttachmentSectionIndex.ClientUploads ||
                  (lesson.is_self_taught &&
                    (sectionIndex === AttachmentSectionIndex.ReasonOfLesson ||
                      sectionIndex === AttachmentSectionIndex.TheSolution))) &&
                  userId === (attachment as AttachmentModel).userId && (
                    <AttachmentDeleteBtn
                      onClick={() => setSelectedToDeleteAttachment(attachment)}
                    />
                  )}
                <img alt="" src={PdfIcon} />
              </div>
            );
          }

          return (
            <div key={attachment.attachmentId} className={styles.videoContainer}>
              <div className={isFavoriteMark}>
                {(sectionIndex === AttachmentSectionIndex.ClientUploads ||
                  (lesson.is_self_taught &&
                    (sectionIndex === AttachmentSectionIndex.ReasonOfLesson ||
                      sectionIndex === AttachmentSectionIndex.TheSolution))) &&
                  userId === (attachment as AttachmentModel).userId && (
                    <AttachmentDeleteBtn
                      onClick={() => setSelectedToDeleteAttachment(attachment)}
                    />
                  )}
                <img
                  alt=""
                  className={styles.videoThumbnail}
                  src={
                    attachment?.contentType?.startsWith('video')
                      ? getResizedImageUri(attachment.thumbnailUrl, '512x512')
                      : getResizedImageUri(attachment.fileUrl, '512x512') || ''
                  }
                  onClick={() => onClickToAttachment(attachment)}
                />
                {attachment?.contentType?.startsWith('video') && (
                  <img
                    className={styles.playIcon}
                    alt="play-icon"
                    src={PlayIcon}
                    onClick={() => onClickToAttachment(attachment)}
                  />
                )}
              </div>
              {attachment?.contentType?.startsWith('video') ? (
                <span className={styles.videoTitle} onClick={() => onClickToAttachment(attachment)}>
                  {attachment.title}
                </span>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(AttachmentsSection);
