import { lightGreen, grey } from '@mui/material/colors';

/* eslint-disable */
export const styles = {
  tabs: {
    '& .Mui-selected': {
      color: `#ffffff!important`,
      backgroundColor: lightGreen[800],
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    cursor: 'pointer',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    backgroundColor: grey[200],
    color: grey[600],
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    marginRight: '4px',
  },
};
