import React, { useEffect, useState } from 'react';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';

import './styles/swiper.scss';
import './styles/swipper-navigation.scss';

export const Carousel = ({
  slides,
  breakpoints,
  navigation,
  onActiveIndexChange,
  defaultIndex = 0,
}: CarouselContainerProps) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const defaultBreakpoints: SwiperOptions['breakpoints'] = {
    480: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    720: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    1920: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(defaultIndex);
    }
  }, [swiper]);

  return (
    <Swiper
      direction="horizontal"
      modules={[Navigation]}
      navigation={navigation}
      onActiveIndexChange={onActiveIndexChange}
      breakpoints={breakpoints ?? defaultBreakpoints}
      onSwiper={setSwiper}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={index} style={{ padding: 2 }}>
            {slide}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

interface CarouselContainerProps extends Pick<SwiperOptions, 'breakpoints' | 'navigation'> {
  slides: React.ReactNode[];
  onActiveIndexChange?: (swiper: SwiperClass) => void;
  defaultIndex?: number;
}
