import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import styles from './AddressInput.module.scss';
import { Config } from '../../../config';

interface AddressInputField {
  value?: string;
  label: string;
  placeholder?: string;
  setValue?: (address: any) => void;
}

const AddressInputField = ({ label, placeholder, value, setValue }: AddressInputField) => {
  return (
    <div className={styles.addressInput}>
      <label>{label}</label>
      <Autocomplete
        className={styles.addressField}
        apiKey={Config().AddressKey}
        placeholder={placeholder}
        onPlaceSelected={(selected: any) => {
          if (setValue && selected && selected?.geometry?.location) {
            setValue({
              ...selected,
              lat: selected.geometry.location.lat(),
              lng: selected.geometry.location.lng(),
            });
          }
        }}
        options={{
          types: ['address'],
        }}
        defaultValue={value ?? 'Auckland'}
      />
    </div>
  );
};

export default AddressInputField;
