import React, { useState } from 'react';
import styles from './PracticeLocationDetailItem.module.scss';

interface IPracticeLocationDetailItem {
  label: string;
  description: string;
}

const PracticeLocationDetailItem: React.FC<IPracticeLocationDetailItem> = ({
  label,
  description,
}) => {
  const [checkLabel, setCheckLabel] = useState(label);

  switch (checkLabel) {
    case 'name':
      setCheckLabel('');
      break;
    default:
  }

  return checkLabel ? (
    <div className={styles.PracticeLocationDetailItem}>
      <span>{checkLabel}</span>
      <div className={styles.description}>{description}</div>
    </div>
  ) : null;
};

export default PracticeLocationDetailItem;
