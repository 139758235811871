import { Config } from '../../config';
import { Exercise } from '../entities/exercises.entity';
import { BaseRepo } from './base.repo';

export class ExercisesRepository extends BaseRepo {
  // #region Private properties
  private exercisesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('exercises');
  // #endregion

  // #region Public methods
  async getExerciseById(id: string) {
    return this.exercisesCollection?.findOne({ _id: id });
  }

  async getExercisesByIds(ids: string[]): Promise<Exercise[] | undefined> {
    return this.exercisesCollection?.find({
      _id: { $in: ids },
    });
  }
  // #endregion
}
