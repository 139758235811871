import React, { useContext } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ScheduledDate } from 'redux/slices';
import { BrandThemeContext } from 'common/context';
import { BaseLessonInformationProps } from './interfaces';
import { useTimeSlots, useService, useLesson } from '../../hooks';

interface LessonCardProps extends Pick<BaseLessonInformationProps, 'id'> {
  count: number;
  scheduledDates: ScheduledDate[];
  isActive: boolean;
  onSelectCard: () => void;
  onCancelBookingTime: (dateStart: number) => void;
  timezone: string;
}

export const LessonCard = ({
  id,
  count,
  scheduledDates,
  isActive,
  onSelectCard,
  onCancelBookingTime,
  timezone,
}: LessonCardProps) => {
  const theme = useContext(BrandThemeContext);
  const { getFormattedPrice } = useLesson();
  const { getStartEndDetailFromMilisWithTimeZone } = useTimeSlots();
  const { getServiceDetails, shouldPriceDisplayed } = useService();

  const service = getServiceDetails(id);
  if (!service) return null;

  const { durationInMinutes, title } = service;
  const formatedPrice = getFormattedPrice(service.priceInCents);

  const backgroundColor = isActive ? theme.colors.black.opacity_10 : theme.colors.white;
  const color = isActive ? theme.colors.black.opacity_100 : theme.colors.black.opacity_20;
  const cancelIconColor = theme.colors.jungleGreen.opacity_100;
  const border = isActive ? 0 : `1px solid ${theme.colors.black.opacity_5}`;

  const defaultTextStyle = {
    paddingTop: '1px',
    fontSize: '0.9rem',
  };

  const timeSlots: number[] = [];
  for (let i = 1; i <= count; i++) timeSlots.push(i);

  return (
    <Box>
      <Card
        id={`lesson-${id}`}
        sx={{ backgroundColor, color, cursor: 'pointer', border }}
        onClick={onSelectCard}
        elevation={0}
      >
        <CardContent>
          <Stack direction="column">
            <Box>
              <Typography fontSize="1rem" fontWeight="bold" marginBottom={1.5}>
                {title}
              </Typography>
              <Stack direction="row" spacing={1} marginBottom={0.5}>
                <Box width={25}>
                  <AccessTimeIcon />
                </Box>
                <Typography {...defaultTextStyle}>{durationInMinutes} mins</Typography>
                {shouldPriceDisplayed(service) && (
                  <Typography {...defaultTextStyle}>{formatedPrice} pp</Typography>
                )}
              </Stack>
              {timeSlots.map((_, index) => {
                return (
                  <Stack direction="row" spacing={1} key={index} marginBottom={0.5}>
                    <Box width={25}>
                      <CalendarMonthOutlinedIcon />
                    </Box>
                    {scheduledDates && scheduledDates[index] ? (
                      <>
                        <Box {...defaultTextStyle} minWidth={140}>
                          {
                            getStartEndDetailFromMilisWithTimeZone(
                              scheduledDates[index].dateStart,
                              timezone,
                            ).dateTimeShort
                          }
                        </Box>
                        <Stack direction="row" marginLeft={15}>
                          <CheckBoxIcon
                            sx={{
                              color: cancelIconColor,
                              fontSize: '1.2rem',
                              marginRight: '4px',
                            }}
                            onClick={() => onCancelBookingTime(scheduledDates[index].dateStart)}
                          />
                          <Box
                            {...defaultTextStyle}
                            fontSize="0.8rem"
                            onClick={() => onCancelBookingTime(scheduledDates[index].dateStart)}
                          >
                            Remove
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <Box
                        fontSize="0.8rem"
                        bgcolor={theme.colors.jungleGreen.opacity_25}
                        color={theme.colors.white}
                        padding={0.5}
                        paddingX={1.5}
                        borderRadius={1}
                        width="100%"
                      >
                        Please select a date and time
                      </Box>
                    )}
                  </Stack>
                );
              })}
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
