import { useCallback, useMemo, useState } from 'react';
import * as Realm from 'realm-web';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import JwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { authenticationSlice } from 'redux/slices';
import { Config } from '../../config';
import SessionProvider, { VerifyTokenResultModel } from '../../providers/SessionProvider';
import { AuthLocalStorage } from '../../modules/auth/utils/auth.helper';
import { getSearchParamsWithoutFrom } from '../utils/common.helpers';
import { StaticRoutes } from '../utils/routes/StaticRoutes.helper';
import Logger from '../../middleware/logger.middleware';
import { Api } from '../../api/Api';
import { VerifyTokenResult } from '../../data/enums/VerifyTokenResult.enum';
import { RefreshTokenModel } from '../../api/models/auth.model';
import { getUnixMilliseconds } from '../utils/date.helpers';

interface LogUserOutWithPreviousParamArgs {
  savePreviousIntoHistory: true;
  previousParams: URLSearchParams;
}

interface LogUserOutWithoutPreviousParamArgs {
  savePreviousIntoHistory: false;
}

type LogUserOutArgs = LogUserOutWithPreviousParamArgs | LogUserOutWithoutPreviousParamArgs;

export const useAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const app = Realm.getApp(Config().RealmAppId as string);
  const [isAuthorized, setAuthorized] = useState(false);

  const removeUser = useCallback(async () => {
    if (app.currentUser) {
      await app.removeUser(app?.currentUser);
      dispatch(authenticationSlice.actions.updateIsAuthenticated(false));
      dispatch(authenticationSlice.actions.updateAuthenticatedUser(null));
    }
  }, [app]);

  const logUserOutAndRedirectToLoginPage = useCallback(
    async (args: LogUserOutArgs) => {
      await removeUser();
      await Auth.signOut();
      if (SessionProvider.getAccessToken()) {
        SessionProvider.removeSession();
        AuthLocalStorage.removeAuthLocalStorage();
      }

      if (args.savePreviousIntoHistory) {
        const paramsResult = args.previousParams;
        const fromString = location.pathname;
        paramsResult.append('from', location.pathname);
        history.push({
          pathname: '/login',
          search: paramsResult.toString(),
          state: { from: fromString },
        });
      } else {
        history.push('/login');
      }

      // finally reload the page
      window.location.reload();
    },
    [removeUser, history, location],
  );

  const logUserOutAndRedirectToBookingPage = useCallback(async () => {
    await removeUser();
    await Auth.signOut();
    if (SessionProvider.getAccessToken()) {
      SessionProvider.removeSession();
      AuthLocalStorage.removeAuthLocalStorage();
    }
    window.location.reload();
  }, [removeUser, history, location]);

  const redirectAfterSignIn = useCallback(async () => {
    const from = params.get('from');
    const redirect = get(history, 'location.state.from', from ?? StaticRoutes.Home);
    const searchParams = getSearchParamsWithoutFrom();
    const path = `${redirect}${searchParams}`;
    history.push(path, history.location.state);
  }, [history, params]);

  const doAuthLogic = useCallback(async () => {
    if (Logger.isDevEnvironment) {
      console.log('Authorising...');
    }
    const token = SessionProvider.getAccessToken();
    if (!token || token === undefined) {
      if (Logger.isDevEnvironment) {
        console.log('Token is not valid, returning false');
      }
      return false;
    }

    const isRealmUserNotNull = app.currentUser !== null;
    let isAuthResult = false;
    if (isRealmUserNotNull && SessionProvider.isValueValid(token)) {
      if (SessionProvider.isValueValid(SessionProvider.getUserId())) {
        const { exp }: { exp: number } = JwtDecode(token as string);
        isAuthResult = exp * 1000 > getUnixMilliseconds();
      }
    }

    if (isAuthResult) {
      return true;
    }

    const localRefreshToken = AuthLocalStorage.getRefreshToken;
    if (localRefreshToken && localRefreshToken !== null) {
      if (Logger.isDevEnvironment) {
        console.log('found refreshToken in localStorage');
      }
      const response = await Api.ClientRoutes.Auth.refreshToken(localRefreshToken);
      const refreshTokenResponse: RefreshTokenModel = response.data;
      if (refreshTokenResponse) {
        SessionProvider.updateRefreshTokenSession(
          refreshTokenResponse.accessToken,
          AuthLocalStorage.getUserId,
          AuthLocalStorage.getEmail,
        );
        isAuthResult = true;
        if (Logger.isDevEnvironment) {
          console.log('New access token obtained. User authenticated');
        }
      }
    }

    if (isAuthResult) {
      return true;
    }

    try {
      const response = await Api.ClientRoutes.Auth.verifyToken(token);
      const verifyTokenResultModel: VerifyTokenResultModel = response.data;

      if (verifyTokenResultModel.verifyTokenResultEnum === VerifyTokenResult.Expired) {
        if (Logger.isDevEnvironment) {
          console.log(
            'verifyTokenResult == Expired and no refresh token found. Removing login session',
          );
        }
        SessionProvider.removeSession();
      }

      if (verifyTokenResultModel.verifyTokenResultEnum !== VerifyTokenResult.Success) {
        if (Logger.isDevEnvironment) {
          console.log('verifyTokenResult != Success. Removing login session');
        }
        SessionProvider.removeSession();
      }

      switch (verifyTokenResultModel.verifyTokenResultEnum) {
        case VerifyTokenResult.Success:
          if (Logger.isDevEnvironment) {
            console.log('Token verified');
          }
          return true;
        case VerifyTokenResult.Invalid:
          if (Logger.isDevEnvironment) {
            console.log('Token is invalid');
          }
          return false;
        case VerifyTokenResult.Expired:
          if (Logger.isDevEnvironment) {
            console.log('Token has expired');
          }
          return false;
        default:
          if (Logger.isDevEnvironment) {
            console.log('Verify Token Result out of bounds');
          }
          return false;
      }
    } catch (err) {
      AuthLocalStorage.removeAuthLocalStorage();
      console.error(err);
      return false;
    }
  }, [app.currentUser]);

  const authorize = async () => {
    try {
      const newAuthResponse = await doAuthLogic();
      if (!isAuthorized) {
        if (setAuthorized !== undefined && isAuthorized !== newAuthResponse) {
          if (Logger.isDevEnvironment) {
            console.log('Booking Page Top Level Authorized');
            console.log('isAuthorized: ', newAuthResponse);
          }
          setAuthorized(newAuthResponse);
        }
      }
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.log('Error in Authorization', error);
      }
    }
  };

  const authenticatedUserProfile = app.currentUser?.profile;

  return {
    params,
    logUserOutAndRedirectToLoginPage,
    logUserOutAndRedirectToBookingPage,
    redirectAfterSignIn,
    doAuthLogic,
    isAuthorized,
    setAuthorized,
    authorize,
    authenticatedUserProfile,
  };
};
