import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { BrandThemeContext } from 'common/context';
import { HiddenServiecItemProps } from './interfaces';

export const HiddenServiceItem = ({ message }: HiddenServiecItemProps) => {
  const theme = useContext(BrandThemeContext);
  return (
    <div>
      {message ? (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            inset: 0,
            backgroundColor: theme.colors.black.opacity_30,
            fontSize: 20,
            fontWeight: 'bold',
          }}
        >
          {message}
        </Box>
      ) : (
        ''
      )}
    </div>
  );
};
