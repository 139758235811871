import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ListPage from 'components/Pages/ListPage';
import { ExerciseModel } from 'data/entities/exercises.entity';
import ExercisesService from 'data/services/exercises.service';
import SessionProvider from 'providers/SessionProvider';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import { SubscriptionsHelper } from 'common/utils/subscriptions.helper';
import { UserSubscriptionsModel } from 'data/models/userSubscription.mode';
import { onSearch } from 'common/utils/search';
import OrganizationService from 'data/services/organization.service';
import { FavoriteModel } from 'data/entities/favorites.entity';
import FavouritesService from 'data/services/favourites.service';
import { AttachmentSectionIndex } from 'modules/attachment/models/attachment';
import List, { ListType } from '../../../../components/List/List';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';

type SplittedAttachment =
  | {
      sectionIndex: AttachmentSectionIndex;
      title: string;
      attachments: any[];
    }
  | {
      sectionIndex: number;
      title: string;
      attachments: ExerciseModel[];
    };

interface AddLibraryViewProps {
  exercises: ExerciseModel[];
  visible: boolean;
  onClose: Function;
  onContinue: (ids: ExerciseModel[] | FavoriteModel[]) => void;
  subscriptionInfo?: UserSubscriptionsModel;
  isExercises: boolean;
  attachments: SplittedAttachment[];
  sectionIndex: number;
}

const AddLibraryView = ({
  exercises,
  visible,
  onClose,
  onContinue,
  subscriptionInfo,
  isExercises,
  attachments,
  sectionIndex,
}: AddLibraryViewProps) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [newExercises, setNewExercises] = useState<ExerciseModel[]>([]);
  const [newFavorites, setNewFavorites] = useState<FavoriteModel[]>([]);
  const [categories, setCategories] = useState<LessonCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const { orgId } = useParams<Record<string, string>>();
  const currentOrgId = orgId ?? '';
  const currentUserId = SessionProvider.getUserId();

  useEffect(() => {
    if (isExercises) {
      const selectedItems = exercises.map((exercise: ExerciseModel) => exercise.exerciseObj._id);
      setSelectedItemIds(selectedItems);
    } else {
      const index = attachments.findIndex(
        (attachment) => attachment && attachment.sectionIndex === sectionIndex,
      );
      if (index >= 0) {
        const selectedItems = attachments[index].attachments.map(
          (attachment) => attachment.attachmentId,
        );
        setSelectedItemIds(selectedItems);
      } else {
        setSelectedItemIds([]);
      }
    }
  }, [isExercises, sectionIndex, attachments]);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const fetchAllDatas = [];
      fetchAllDatas.push(OrganizationService.getOrgLessonCategoriesById(currentOrgId));
      fetchAllDatas.push(
        await ExercisesService.getAllExercisesByUserAndOrg(currentUserId, currentOrgId),
      );
      fetchAllDatas.push(FavouritesService.getAllFavoritesAsModel(currentUserId, currentOrgId));

      const fetchedData = await Promise.all(fetchAllDatas);

      setCategories(fetchedData[0]);
      setNewExercises(fetchedData[1]);
      setNewFavorites(fetchedData[2]);

      setIsLoading(false);
    } catch (e) {
      console.log('fetching errors: ', e);
      setCategories([]);
      setNewExercises([]);
      setNewFavorites([]);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const showListItem = (item: any, i: number) => {
    return SubscriptionsHelper.showListItem(i, subscriptionInfo);
  };

  const filteredSource = useMemo(() => {
    if (isExercises) {
      const searchedSource = onSearch(newExercises, searchValue);
      return selectedCategories.length > 0
        ? (searchedSource as ExerciseModel[]).filter((item: ExerciseModel) =>
            item.exerciseObj.org_lesson_category_ids?.some((id: string) =>
              selectedCategories.includes(id as never),
            ),
          )
        : searchedSource;
    }

    let searchedSource = onSearch(newFavorites, searchValue);
    if (selectedItemIds.length > 0) {
      searchedSource = (searchedSource as FavoriteModel[]).filter(
        (item: FavoriteModel) => !selectedItemIds.includes(item.attachment?._id as never),
      );
    }
    return selectedCategories.length > 0
      ? (searchedSource as FavoriteModel[]).filter((item: FavoriteModel) => {
          return (
            item.favoriteType.lesson_category_ids?.some((id: string) =>
              selectedCategories.includes(id as never),
            ) && !selectedItemIds.includes(item.attachment?._id as never)
          );
        })
      : searchedSource;
  }, [isExercises, newFavorites, newExercises, selectedCategories, searchValue, selectedItemIds]);

  const addExercises = () => {
    if (isExercises) {
      const filteredExercise = newExercises.filter((exercise: ExerciseModel) => {
        const index = selectedItemIds.findIndex((id: string) => id === exercise.exerciseObj._id);
        if (index >= 0) return true;
        return false;
      });

      onContinue(filteredExercise);
    } else {
      const filteredFavorites = newFavorites.filter((favorite: FavoriteModel) => {
        const index = selectedItemIds.findIndex((id: string) => id === favorite.attachment?._id);
        if (index >= 0) return true;
        return false;
      });

      onContinue(filteredFavorites);
    }
  };

  const tabObject: Record<string, string | number> = isExercises
    ? { Exercises: 0 }
    : { Favorites: 0 };

  return (
    <ModalWindow
      show={visible}
      title={isExercises ? 'Add exercise' : 'Add favorite'}
      saveBtnLabel="Add"
      onCloseClick={onClose}
      onSaveClick={() => {
        addExercises();
        onClose();
      }}
    >
      <ListPage
        onCategorySelected={setSelectedCategories}
        tabs={tabObject}
        searchValue={searchValue}
        onSearchValue={setSearchValue}
        selectedTab={0}
        selectedCategories={selectedCategories}
        orgId={currentOrgId}
        onTabSelected={() => undefined}
      >
        <List
          items={filteredSource}
          listType={isExercises ? ListType.ExercisesList : ListType.FavouritesList}
          emptyLabel={isExercises ? 'You have no exercises yet.' : 'You have no favorites yet.'}
          categories={categories}
          showListItem={showListItem}
          showUpgradePlanButtonIndex={SubscriptionsHelper.showUpgradePlanButtonIndex(
            true,
            newExercises,
          )}
          isLoading={isLoading}
          selectedItemIds={selectedItemIds}
          setSelectedItemIds={setSelectedItemIds}
        />
      </ListPage>
    </ModalWindow>
  );
};

export default AddLibraryView;
