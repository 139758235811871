import React from 'react';
import Banner from '../../../../components/Banner/Banner';
import styles from './BannerTrial.module.scss';
import { BillingHelper } from '../../../../common/utils/billing.helper';
import { UserSubscriptionsModel } from '../../../../data/models/userSubscription.mode';

interface BannerTrialProps {
  subscriptionInfo?: UserSubscriptionsModel;
}

const BannerTrial = (props: BannerTrialProps) => {
  const { subscriptionInfo: billingSubscription } = props;

  if (BillingHelper.isTrial(billingSubscription) === false) {
    return null;
  }

  const renderContent = () => {
    // TODO: 08.08.23 - Maybe store trialEnd in user collection?
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // const date = getDateFromUnixSeconds(billingSubscription!.subscription.trialEnd);
    // const trialEndsDate = format(date, 'dd MMMM');
    return <span>Your trial ends .</span>;
  };

  return <Banner wrapperClassName={styles.wrapper} content={renderContent()} />;
};

export default BannerTrial;
