/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { isEqual } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import ModalWindow from '../../../components/ModalWindow/ModalWindow';
import styles from './PracticeLocationsModal.module.scss';
import InputField from '../../../components/InputField/InputField';
import AddressInput from '../../../components/Inputs/AddressInput/AddressInput';
import { Address } from '../../../data/entities/userContacts.entity';
import Logger from '../../../middleware/logger.middleware';
import { addressStringBuilder } from '../../../common/utils/response.helper';
import { FormErrorMessage } from '../../../common/models/base.model';
import { Validator } from '../../../common/utils/validator.helper';

interface PracticeLocationModalProps {
  showModal: boolean;
  onPracticeLocationSaved?: any;
  onModalCloseClick: any;
  saveLabel: string;
  titleModal: string;
  oldValue?: UserPracticeLocation;
}

interface FormErrors {
  locationName?: FormErrorMessage;
  address?: FormErrorMessage;
}

const PracticeLocationModal = ({
  showModal,
  onPracticeLocationSaved,
  onModalCloseClick,
  saveLabel,
  titleModal,
  oldValue,
}: PracticeLocationModalProps) => {
  const locationObj: UserPracticeLocation = {
    location_id: uuidv4(),
    name: '',
    address: {},
  };

  const [practiceLocation, setPracticeLocation] = useState<UserPracticeLocation>(
    oldValue ?? locationObj,
  );
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [currentName, setCurrentName] = useState<string>('');
  const [address, setAddress] = useState<Address>({});

  useEffect(() => {
    if (!showModal) {
      setCurrentName(practiceLocation.name);
      setAddress(practiceLocation.address);
      setFormErrors({});
    }
  }, [practiceLocation, oldValue, showModal]);

  const validateNameField = (value: string) => {
    if (!value.match(Validator.RegexDictionary.Name)) {
      setFormErrors({
        ...formErrors,
        name: { message: 'Please enter a valid Location name.' },
      } as FormErrors);
      return;
    }
    setFormErrors({ ...formErrors, name: undefined } as FormErrors);
  };

  const onAddName = (e: ChangeEvent<HTMLInputElement>) => {
    validateNameField(e.target.value.trim());
    setCurrentName(e.target.value);
    setPracticeLocation({ ...practiceLocation, name: e.target.value });
  };

  const googleAddressToFormattedAddress = (addressParam: any) => {
    if (!addressParam) {
      return undefined;
    }

    if (addressParam?.address_components) {
      const addressObject: Address = {};

      addressObject.latitude = addressParam?.lat ?? Infinity;
      addressObject.longitude = addressParam?.lng ?? Infinity;
      addressObject.suburb = '';

      const addressComponents = addressParam?.address_components || {};

      interface AddressKeys {
        [key: string]: string;
      }

      const keysObject: AddressKeys = {
        subpremise: 'subpremise',
        street_number: 'street_number',
        route: 'street_name',
        sublocality: 'suburb',
        locality: 'city',
        country: 'country',
        postal_code: 'zip_code',
      };

      for (const component of addressComponents) {
        component?.types?.forEach((type: string) => {
          const key = keysObject[type as keyof AddressKeys];

          if (key) {
            (addressObject as any)[key] = component.long_name;

            if (type === 'country') {
              addressObject.country_short_name = component.short_name;
            }
          }
        });
      }

      return addressObject;
    }

    return undefined;
  };

  const onAddAddress = (addressParam: any) => {
    const addressObject = googleAddressToFormattedAddress(addressParam);
    setAddress(addressObject ?? {});
    setPracticeLocation({
      ...practiceLocation,
      name: currentName,
      address: addressObject ?? {},
    });
  };

  const onSavePracticeLocationItem = () => {
    if (!practiceLocation) {
      if (Logger.isDevEnvironment) {
        console.log('practice_location is undefined.');
      }
      onModalCloseClick();
      return;
    }

    if (formErrors.address || formErrors.locationName) {
      return;
    }

    const nameError = !currentName ? { message: 'Location name is required.' } : undefined;

    setFormErrors({
      ...formErrors,
      locationName: nameError,
    });

    if (nameError) {
      return;
    }

    if (oldValue && isEqual(oldValue, practiceLocation)) {
      if (Logger.isDevEnvironment) {
        console.log('no fields were updated');
      }
      onModalCloseClick();
      return;
    }

    if (Logger.isDevEnvironment) {
      console.log('practice_location saved.');
    }

    const practiceLocationToSave: UserPracticeLocation = {
      name: currentName,
      location_id: practiceLocation.location_id,
      address: practiceLocation.address,
    };

    onPracticeLocationSaved(practiceLocationToSave);
    setPracticeLocation(locationObj);
  };

  return (
    <div>
      <ModalWindow
        show={showModal}
        title={titleModal}
        saveBtnLabel={saveLabel}
        onSaveClick={onSavePracticeLocationItem}
        onCloseClick={onModalCloseClick}
        okButtonDisabled={formErrors.address !== undefined || formErrors.locationName !== undefined}
      >
        <div className={styles.inputsWrapper}>
          <InputField
            type="text"
            placeholder="Location Name"
            label="Location Name *"
            showValidationMessage={false}
            customStyles={styles.practiceLocationForm}
            onChange={onAddName}
            defaultValue={currentName}
            stretchInput
          />
          <p className="text-danger"> {formErrors.locationName?.message}</p>
          <AddressInput
            placeholder="Street, City, Zip code "
            label="Address *"
            value={addressStringBuilder(address)}
            setValue={onAddAddress}
          />
        </div>
      </ModalWindow>
    </div>
  );
};

export default PracticeLocationModal;
