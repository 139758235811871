export const Config = () => ({
  AppUrl: import.meta.env.VITE_APP_URL,
  BaseCognitoOpenIdURL: import.meta.env.VITE_BASE_COGNITO_OPEN_ID_URL,
  BaseCoachURL: import.meta.env.VITE_BASE_COACH_URL,
  BaseClientURL: import.meta.env.VITE_BASE_URL,
  Billing: {
    URL: import.meta.env.VITE_BILLING_URL,
    InternalKey: import.meta.env.VITE_BILLING_INTERNAL_KEY,
    ApiUrl: import.meta.env.VITE_BILLING_API_URL,
  },
  AddressKey: import.meta.env.VITE_ADDRESS_KEY,
  StripeKey: import.meta.env.VITE_STRIPE_KEY,
  StripeFreeProductId: import.meta.env.VITE_STRIPE_FREE_PRODUCT_ID,
  RealmAppId: import.meta.env.VITE_REALM_APP_ID,
  RealmClusterName: import.meta.env.VITE_REALM_CLUSTER_NAME,
  RealmDbName: import.meta.env.VITE_REALM_DB_NAME,
  SendGridApiKey: import.meta.env.VITE_SENDGRID_API_KEY,
  // aws file upload
  AWSProjectRegion: import.meta.env.VITE_AWS_PROJECT_REGION,
  AWSCognitoIdentityPoolId: import.meta.env.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
  AWSCognitoRegion: import.meta.env.VITE_AWS_COGNITO_REGION,
  AWSUserPoolsId: import.meta.env.VITE_AWS_USER_POOLS_ID,
  AWSUserPoolsWebClientId: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
  AWSUserFilesS3Bucket: import.meta.env.VITE_AWS_USER_FILES_S3_BUCKET,
  AWSUserFilesS3BucketRegion: import.meta.env.VITE_AWS_USER_FILES_S3_BUCKET_REGION,
  AWSS3FilesUrl: import.meta.env.VITE_AWS_S3_FILES_URL,
  AWSCloudFrontUrl: import.meta.env.VITE_AWS_CLOUD_FRONT_URL,
  AWSResizeImageUrl: import.meta.env.VITE_AWS_RESIZE_IMAGE_URL,
});
