import React, { useState } from 'react';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import styles from './PracticeLocationDetail.module.scss';
import UserAvatar from '../../../../components/UserInfo/UserAvatar';
import PracticeLocationDetailItem from './PracticeLocationDetailItem/PracticeLocationDetailItem';
import PracticeLocationModal from '../../modals/PracticeLocationsModal';
import CloseButton from '../../../../components/Button/CloseButton/CloseButton';
import HeaderNavView from '../../../../components/Header/HeaderNavView/HeaderNavView';
import { addressStringBuilder } from '../../../../common/utils/response.helper';

interface IPracticeLocationDetail {
  currentPracticeLocation: UserPracticeLocation;
  onUpdatePracticeLocation?: any;
  onDeletePracticeLocation?: (locationId: string) => void;
  onCloseDetail: any;
  setHideAddBtn?: any;
}
const PracticeLocationDetail: React.FC<IPracticeLocationDetail> = ({
  currentPracticeLocation,
  onUpdatePracticeLocation,
  onDeletePracticeLocation,
  onCloseDetail,
  setHideAddBtn,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [confimationModal, setConfirmationModal] = useState(false);

  const onUpdateOpened = () => {
    setOpenModal(!openModal);
    setHideAddBtn(true);
  };

  const onDeleteLocation = async () => {
    if (onDeletePracticeLocation) onDeletePracticeLocation(currentPracticeLocation.location_id);
    setConfirmationModal(false);
  };

  const onPracticeLocationUpdated = (newPracticeLocation: UserPracticeLocation) => {
    onUpdatePracticeLocation(newPracticeLocation);
    onUpdateOpened();
    setHideAddBtn(true);
  };

  const addressObjToString = (address: any) => {
    return addressStringBuilder(address);
  };

  return (
    <>
      <PracticeLocationModal
        showModal={openModal}
        onModalCloseClick={onUpdateOpened}
        onPracticeLocationSaved={onPracticeLocationUpdated}
        saveLabel="Update"
        titleModal="Update Practice location"
        oldValue={currentPracticeLocation}
      />
      <div
        className={!openModal ? styles.PracticeLocationDetail : styles.PracticeLocationDetailHidden}
      >
        <div
          className={styles.backdrop}
          onClick={() => {
            onCloseDetail();
            document.body.style.overflow = 'auto';
          }}
        />
        <div className={styles.wrapper}>
          <div className={styles.closeBtn}>
            <CloseButton onClose={onCloseDetail} />
          </div>
          <HeaderNavView onClose={onCloseDetail} label="Practice location" />
          <div className={styles.PracticeLocationInfo}>
            <UserAvatar className={styles.avatar} name={currentPracticeLocation.name} />
            <div className={styles.infoWrapper}>
              <div className={styles.name}>{currentPracticeLocation.name}</div>
            </div>
          </div>
          <div className={styles.PracticeLocationItems}>
            {Object.entries(currentPracticeLocation).map(([key, value]) => {
              let description = value;

              if (key === 'address') {
                description = addressObjToString(value);
              }

              if (key !== 'location_id')
                return (
                  <PracticeLocationDetailItem key={key} label={key} description={description} />
                );
              return '';
            })}
          </div>
          <div className={styles.btns}>
            <div className={styles.editBtn} onClick={onUpdateOpened}>
              Edit
            </div>
            <div className={styles.deleteBtn} onClick={() => setConfirmationModal(true)}>
              Delete
            </div>
          </div>
        </div>
      </div>
      <ModalWindow
        show={confimationModal}
        title="Confirmation"
        saveBtnLabel="Delete"
        onSaveClick={onDeleteLocation}
        onCloseClick={() => setConfirmationModal(false)}
      >
        <div>Are you sure to delete to this location?</div>
      </ModalWindow>
    </>
  );
};
export default PracticeLocationDetail;
