import { Org } from '../endpoints/coach/Org.coach.endpoint';
import { Config } from '../../config';
import { BaseApi } from '../base/Api.base';

export default class CoachRoutes {
  private static api = new BaseApi(Config().BaseCoachURL);

  // Routes
  public Org = new Org(CoachRoutes.api);
}
