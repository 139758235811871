import { useReducer, useState } from 'react';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { DateTime } from 'luxon';
import countries from 'i18n-iso-countries';
import { Contact } from '../../../../data/entities/organization.entity';
import { Validator } from '../../../../common/utils/validator.helper';
import { capitalize } from '../../../../common/extensions/stringExtensions';
import { getDateFromIsoString } from '../../../../common/utils/date.helpers';

export interface SignUpErrorMessageEvent {
  firstNameError?: string;
  lastNameError?: string;
  emailInputError?: string;
  confirmEmailInputError?: string;
  passwordInputError?: string;
  confirmPasswordInputError?: string;
  phoneNumberInputError?: string;
  checkBoxesErrors: {
    termsOfService?: string;
    under18?: string;
  };
  requestError?: string;
  loginError?: string;
  dateOfBirth?: string;
  emergencyContact1?: EmergencyContact;
  emergencyContact2?: EmergencyContact;
}

export interface EmergencyContact {
  firstName?: string;
  lastName?: string;
  relationship?: string;
  contact?: Contact | string;
}

export interface SignUpCheckBoxes {
  termsOfService: boolean;
  rememberMe: boolean;
  under18: boolean;
}

export interface Under18Info {
  dateOfBirth?: number;
  allergies?: string;
  medication?: string;
  emergencyContact1?: EmergencyContact;
  emergencyContact2?: EmergencyContact;
}

export const useSignUp = (isInvite: boolean) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [contact, setContact] = useState<Contact>();
  const [checkboxes, setCheckBoxes] = useState<SignUpCheckBoxes>({
    termsOfService: false,
    rememberMe: false,
    under18: false,
  });
  const [under18Info, setUnder18Info] = useState<Under18Info>();
  const [errorMessageEvent, UpdateErrorMessageEvent] = useReducer(
    (prev: SignUpErrorMessageEvent, next: SignUpErrorMessageEvent) => {
      return { ...prev, ...next };
    },
    {
      firstNameError: undefined,
      lastNameError: undefined,
      emailInputError: undefined,
      passwordInputError: undefined,
      phoneNumberInputError: undefined,
      checkBoxesErrors: {
        termsOfService: undefined,
        under18: undefined,
      },
      requestError: undefined,
      loginError: undefined,
      emergencyContact1: undefined,
      emergencyContact2: undefined,
    },
  );

  const onFirstNameChanged = (e: any) => {
    const nameValue = capitalize(e.target.value.trim());
    setFirstName(nameValue);
  };

  const onLastNameChanged = (e: any) => {
    const lastNameValue = capitalize(e.target.value.trim());
    setLastName(lastNameValue);

    if (!lastNameValue) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        lastNameError: 'Please enter your last name',
      });
      return;
    }

    if (!lastNameValue.match(Validator.RegexDictionary.Name)) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        lastNameError: 'Please enter a valid last name',
      });
      return;
    }

    UpdateErrorMessageEvent({ ...errorMessageEvent, lastNameError: undefined });
  };

  const onEmailChanged = (e: any) => {
    const emailValue = e.target.value.trim().toLowerCase();
    setEmail(emailValue);

    if (!emailValue) {
      UpdateErrorMessageEvent({ ...errorMessageEvent, emailInputError: 'Please enter your email' });
      return;
    }

    if (!emailValue.match(Validator.RegexDictionary.Email)) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        emailInputError: 'Please enter a valid email',
      });
      return;
    }

    if (emailValue !== email) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        emailInputError: undefined,
        confirmEmailInputError: !isInvite ? 'Emails do not match' : '',
      });
      return;
    }

    UpdateErrorMessageEvent({ ...errorMessageEvent, emailInputError: undefined });
  };

  const onConfirmEmailChange = (e: any) => {
    const emailValue = e.target.value.trim().toLowerCase();
    setConfirmEmail(emailValue);

    if (!emailValue) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        confirmEmailInputError: 'Please enter your email',
      });
      return;
    }

    if (!emailValue.match(Validator.RegexDictionary.Email)) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        confirmEmailInputError: 'Please enter a valid email',
      });
      return;
    }

    if (emailValue !== email) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        confirmEmailInputError: 'Emails do not match',
      });
      return;
    }

    UpdateErrorMessageEvent({ ...errorMessageEvent, confirmEmailInputError: undefined });
  };

  const onPasswordChanged = (e: any) => {
    const passwordValue = e.target.value.trim();
    setPassword(passwordValue);

    if (passwordValue !== password) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        confirmPasswordInputError: 'Passwords do not match',
      });
      return;
    }

    UpdateErrorMessageEvent({ ...errorMessageEvent, confirmPasswordInputError: undefined });
  };

  const onConfirmPasswordChange = (e: any) => {
    const passwordValue = e.target.value.trim();
    setConfirmPassword(passwordValue);

    if (passwordValue !== password) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        confirmPasswordInputError: 'Passwords do not match',
      });
      return;
    }

    UpdateErrorMessageEvent({ ...errorMessageEvent, confirmPasswordInputError: undefined });
  };

  const onChangePhoneNumber = (value: any) => {
    const phoneNumber = value;

    if (!phoneNumber) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        phoneNumberInputError: undefined,
      });
      return;
    }

    if (!isPossiblePhoneNumber(phoneNumber)) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        phoneNumberInputError: 'Please enter a valid phone number.',
      });
    } else {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        phoneNumberInputError: undefined,
      });
    }

    let countryIso = '';
    let countryCallingCode = '';
    const formattedNumber = parsePhoneNumber(value);
    if (formattedNumber && formattedNumber.country) {
      countryIso = countries.alpha2ToAlpha3(formattedNumber.country);
      countryCallingCode = `+${formattedNumber.countryCallingCode}`;
    }
    const phoneNumberWithoutCallCode = phoneNumber.slice(countryCallingCode.length);

    const contactInfo: Contact = {
      country_code: countryCallingCode,
      phone_number: phoneNumberWithoutCallCode,
      country_iso: countryIso.toLowerCase(),
    };
    setContact(contactInfo);
  };

  // #endregion

  const hasErrors = Object.values(errorMessageEvent).some((value) => {
    // If the value is an object, recursively check its properties
    if (typeof value === 'object' && value !== null) {
      return Object.values(value).some((nestedValue) => !!nestedValue);
    }
    // For other cases, check if the value is truthy
    return !!value;
  });

  const isUnder18DetailsComplete = () => {
    if (checkboxes.under18 === false) {
      return true;
    }

    if (hasErrors === true) {
      return false;
    }
    // TODO: logic here is not working?
    return (
      !!under18Info &&
      !!under18Info.dateOfBirth &&
      !!under18Info.emergencyContact1 &&
      !!under18Info.emergencyContact1.firstName &&
      !!under18Info.emergencyContact1.lastName &&
      !!under18Info.emergencyContact1.relationship &&
      !!under18Info.emergencyContact1.contact
    );
  };

  const isSignUpFieldsComplete =
    hasErrors === false &&
    !!email &&
    !!password &&
    !!firstName &&
    !!lastName &&
    checkboxes.termsOfService === true &&
    isUnder18DetailsComplete() === true;

  // #region under18 Events
  /**
   *
   * @param isoDateString ISODate (e.g. 1998-02-13)
   */
  const onChangeDateOfBirth = (e: any) => {
    const isoDateString = e.target.value;
    if (!isoDateString) {
      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        dateOfBirth: 'Date of birth is required.',
      });
    }
    const date: DateTime = getDateFromIsoString(isoDateString);
    const dateOfBirth = date.toMillis();

    UpdateErrorMessageEvent({
      ...errorMessageEvent,
      dateOfBirth: undefined,
    });
    setUnder18Info({ ...under18Info, dateOfBirth });
  };

  const onChangeAllergies = (e: any) => {
    const allergies = e.target.value.trim();
    setUnder18Info({ ...under18Info, allergies });
  };

  const onChangeMedication = (e: any) => {
    const medication = e.target.value.trim();
    setUnder18Info({ ...under18Info, medication });
  };

  const onChangeEmergencyContactFirstName = (e: any, index: number) => {
    if (index === 0) {
      const emergencyContactFirstName = e.target.value.trim();
      if (!emergencyContactFirstName.match(Validator.RegexDictionary.Name)) {
        const errorMessage = 'Please enter a valid First Name.';

        UpdateErrorMessageEvent({
          ...errorMessageEvent,
          emergencyContact1: {
            ...errorMessageEvent.emergencyContact1,
            firstName: errorMessage,
          },
        });
      } else {
        UpdateErrorMessageEvent({
          ...errorMessageEvent,
          emergencyContact1: { ...errorMessageEvent.emergencyContact1, firstName: undefined },
        });
      }

      setUnder18Info({
        ...under18Info,
        emergencyContact1: {
          ...under18Info?.emergencyContact1,
          firstName: emergencyContactFirstName,
        },
      });
    }
  };

  const onChangeEmergencyContactLastName = (e: any, index: number) => {
    const emergencyContactLastName = e.target.value.trim();
    if (index === 0) {
      if (!emergencyContactLastName.match(Validator.RegexDictionary.Name)) {
        const errorMessage = 'Please enter a valid Last Name.';

        UpdateErrorMessageEvent({
          ...errorMessageEvent,
          emergencyContact1: {
            ...errorMessageEvent.emergencyContact1,
            lastName: errorMessage,
          },
        });
      } else {
        UpdateErrorMessageEvent({
          ...errorMessageEvent,
          emergencyContact1: { ...errorMessageEvent.emergencyContact1, lastName: undefined },
        });
      }
    }

    if (index === 0) {
      setUnder18Info({
        ...under18Info,
        emergencyContact1: {
          ...under18Info?.emergencyContact1,
          lastName: emergencyContactLastName,
        },
      });
    }
  };

  const onChangeEmergencyContactRelationship = (e: any, index: number) => {
    const relationship = e.target.value.trim();
    if (index === 0) {
      setUnder18Info({
        ...under18Info,
        emergencyContact1: { ...under18Info?.emergencyContact1, relationship },
      });
    }
  };

  const onChangeEmergencyContactPhone = (e: any, index: number) => {
    const phoneNumber = e;

    if (!phoneNumber) {
      if (index === 0) {
        UpdateErrorMessageEvent({
          ...errorMessageEvent,
          emergencyContact1: {
            ...errorMessageEvent.emergencyContact1,
            contact: undefined,
          },
        });
      }

      return;
    }

    if (index === 0) {
      if (!isPossiblePhoneNumber(phoneNumber)) {
        const phoneNumberError = 'Please enter a valid phone number.';

        UpdateErrorMessageEvent({
          ...errorMessageEvent,
          emergencyContact1: {
            ...errorMessageEvent.emergencyContact1,
            contact: phoneNumberError,
          },
        });
      } else {
        UpdateErrorMessageEvent({
          ...errorMessageEvent,
          emergencyContact1: { ...errorMessageEvent.emergencyContact1, contact: undefined },
        });
      }
    }

    let countryIso = '';
    let countryCallingCode = '';
    const formattedNumber = parsePhoneNumber(e);
    if (formattedNumber && formattedNumber.country) {
      countryIso = countries.alpha2ToAlpha3(formattedNumber.country);
      countryCallingCode = `+${formattedNumber.countryCallingCode}`;
    }
    const phoneNumberWithoutCallCode = phoneNumber.slice(countryCallingCode.length);

    const emergencyContactDetails: Contact = {
      country_code: countryCallingCode,
      phone_number: phoneNumberWithoutCallCode,
      country_iso: countryIso.toLowerCase(),
    };

    if (index === 0) {
      setUnder18Info({
        ...under18Info,
        emergencyContact1: { ...under18Info?.emergencyContact1, contact: emergencyContactDetails },
      });
    }
  };
  // #endregion

  return {
    firstName,
    lastName,
    email,
    confirmEmail,
    password,
    confirmPassword,
    contact,
    errorMessageEvent,
    checkboxes,
    under18Info,
    onFirstNameChanged,
    onLastNameChanged,
    onEmailChanged,
    onConfirmEmailChange,
    onPasswordChanged,
    onConfirmPasswordChange,
    onChangePhoneNumber,
    setCheckBoxes,
    UpdateErrorMessageEvent,
    setEmail,
    setFirstName,
    setLastName,
    onChangeDateOfBirth,
    onChangeAllergies,
    onChangeMedication,
    onChangeEmergencyContactFirstName,
    onChangeEmergencyContactLastName,
    onChangeEmergencyContactRelationship,
    onChangeEmergencyContactPhone,
    isSignUpFieldsComplete,
    isUnder18DetailsComplete,
  };
};
