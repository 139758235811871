/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';

import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import { ICategory } from '../../models/category.model';
import CheckBox from '../../../../components/Checkbox/Checkbox';
import styles from './Categories.module.scss';

interface CategoriesProps {
  categories: LessonCategory[];
  selectedCategories: string[];
  setSelectedCategories: Function;
}

const Categories = ({ categories, selectedCategories, setSelectedCategories }: CategoriesProps) => {
  const onCheck = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedCategories([...selectedCategories, id]);
    } else {
      setSelectedCategories(selectedCategories.filter((categoryId: string) => categoryId !== id));
    }
  };

  const orderedCategories = useMemo(
    () =>
      categories.sort((a, b) => {
        if (a.index_order === null || a.index_order === undefined) {
          return 1;
        }

        if (b.index_order === null || b.index_order === undefined) {
          return -1;
        }

        return a.index_order - b.index_order;
      }),
    [categories],
  );

  return (
    <div className={styles.checkboxWrapper}>
      <div className={styles.title}>Categories</div>
      {orderedCategories?.map((category: ICategory | LessonCategory) => (
        <CheckBox
          key={(category as ICategory).id || (category as LessonCategory)._id}
          id={(category as ICategory).id || (category as LessonCategory)._id}
          label={category.name}
          labelStyles={styles.label}
          onCheck={(checked: boolean) =>
            onCheck(checked, (category as ICategory).id || (category as LessonCategory)._id)
          }
          checked={selectedCategories.includes(
            (category as ICategory).id || (category as LessonCategory)._id,
          )}
        />
      ))}
    </div>
  );
};

export default Categories;
