import React from 'react';
import { Tooltip } from '@mui/material';
import styles from './LessonSection.module.scss';
import NextIcon from '../../../../assets/icons/navbar-icons/arrow.svg';
import HintIcon from '../../../../assets/icons/hint-icon.svg';

interface IProps {
  title: string;
  itemsCount: number;
  onClick: any;
  hintMessage?: string;
}

const LessonSection: React.FC<IProps> = ({ title, itemsCount, onClick, hintMessage = '' }) => (
  <div className={styles.section} onClick={onClick}>
    <div className={styles.title}>
      {title}
      {hintMessage !== '' && (
        <Tooltip title={hintMessage}>
          <img alt="Hint Icon" src={HintIcon} className={styles.hintIcon} />
        </Tooltip>
      )}
    </div>
    <div className={styles.actions}>
      <span className={styles.count}>{itemsCount}</span>
      <img alt="next-icon" src={NextIcon} />
    </div>
  </div>
);

export default LessonSection;
