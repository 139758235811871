import React from 'react';
import { Box } from '@mui/material';

import { BannerPurchasedLessonPacks } from 'modules/home/components/BannerPurchasedLessonPacks';
import BannerUpcomingLesson from 'modules/home/components/BannerUpcomingLesson/BannerUpcomingLesson';
import BannerTrial from 'modules/home/components/BannerTrial/BannerTrial';
import { useUserSubscription } from 'common/hooks';

const Banners = () => {
  const { subscriptionInfo } = useUserSubscription();
  return (
    <Box>
      <BannerPurchasedLessonPacks />
      <BannerUpcomingLesson />
      <BannerTrial subscriptionInfo={subscriptionInfo} />
    </Box>
  );
};

export default Banners;
