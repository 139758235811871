import React, { useState, useEffect, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import {
  parseISO,
  getWeek,
  parse,
  format,
  startOfWeek,
  endOfWeek,
  setWeek,
  addDays,
  getISOWeeksInYear,
} from 'date-fns';
import { flow } from 'lodash';
import { DateTime } from 'luxon';

import { SwiperClass } from 'swiper/react';
import { Carousel } from 'components/Carousel';
import LeftArrowIcon from '../../../../assets/icons/arrows/left-arrow.svg';
import RightArrowIcon from '../../../../assets/icons/arrows/right-arrow.svg';

import styles from './MobileCalendar.module.scss';
import { now } from '../../../../common/utils/date.helpers';
import Logger from '../../../../middleware/logger.middleware';

interface IMobileCalendarProps {
  selectedDate: string;
  setSelectedDate: Function;
  onFetchEvents: (startDate: DateTime, endDate: DateTime) => Promise<any[]>;
  getDaysIndicators?: (startDate: DateTime, endDate: DateTime, events: any[]) => number[];
}

const MobileCalendar: React.FC<IMobileCalendarProps> = ({
  selectedDate,
  setSelectedDate,
  onFetchEvents,
  getDaysIndicators,
}) => {
  const [year, setYear] = useState(0);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [prevWeek, setPrevWeek] = useState(currentWeek);
  const [daysIndicators, setDaysIndicators] = useState<number[]>([]);

  useEffect(() => {
    if (!currentWeek && !year) {
      setCurrentWeek(getWeek(parseISO(selectedDate)));
      setYear(parseISO(selectedDate).getFullYear());
    }
  }, [currentWeek, selectedDate, year]);

  const onWeekChange = useCallback(
    async (yearParam: number, weekParam: number) => {
      setPrevWeek(weekParam);
      if (Logger.isDevEnvironment) console.log('onWeekChange()', yearParam, weekParam);
      const date = now().set({ weekYear: yearParam, weekNumber: weekParam });
      const startDate = date.startOf('week').minus({ days: 1 });
      const endDate = date.endOf('week');
      if (onFetchEvents) {
        const events = await onFetchEvents(startDate, endDate);
        if (getDaysIndicators) {
          setDaysIndicators(getDaysIndicators(startDate, endDate, events));
        }
      }
    },
    [getDaysIndicators, onFetchEvents],
  );

  useEffect(() => {
    if (year && currentWeek && currentWeek !== prevWeek) {
      onWeekChange(year, currentWeek);
    }
  }, [currentWeek, onFetchEvents, onWeekChange, prevWeek, year]);

  const currentWeekDateLabel = useMemo(() => {
    if (year && currentWeek) {
      const date = setWeek(parse(`${year}`, 'yyyy', new Date()), currentWeek);
      const startDate = format(startOfWeek(date), 'MMM d');
      const endDate = format(endOfWeek(date), 'MMM d, yyyy');

      return `${startDate} - ${endDate}`;
    }
    return '';
  }, [currentWeek, year]);

  const daysOfWeek = useMemo(() => {
    if (year && currentWeek) {
      const startWeek2 = flow([
        (date) => parse(date.toString(), 'yyyy', new Date()),
        (date) => setWeek(date, currentWeek),
        startOfWeek,
      ])(year);

      return new Array(7).fill(null).map((_, index) => {
        const date = addDays(startWeek2, index);

        return {
          key: uuidv4(),
          date: format(date, 'yyyy-M-d'),
          day: format(date, 'd'),
        };
      });
    }

    return [];
  }, [currentWeek, year]);

  const getButtonClassNames = useCallback(
    (currentDate: string) => {
      const date = format(parseISO(selectedDate), 'yyyy-M-d');

      return date === currentDate ? clsx(styles.dayButton, styles.activeButton) : styles.dayButton;
    },
    [selectedDate],
  );

  const nextWeek = () => {
    const dWeeks = getISOWeeksInYear(parse(year.toString(), 'yyyy', new Date())) - 1;

    if (dWeeks === currentWeek) {
      setCurrentWeek(1);
      setYear(year + 1);
    } else {
      setCurrentWeek(currentWeek + 1);
    }
  };

  const previousWeek = () => {
    if (currentWeek === 1) {
      setCurrentWeek(getISOWeeksInYear(parse((year - 1).toString(), 'yyyy', new Date())) - 1);
      setYear(year - 1);
    } else {
      setCurrentWeek(currentWeek - 1);
    }
  };

  const onActiveIndexChange = (swiper: SwiperClass) => {
    if (swiper.activeIndex === 0) previousWeek();
    if (swiper.activeIndex === 2) nextWeek();
    swiper.slideTo(1);
  };

  const weeklySliders = (
    <div className={styles.dateGrid}>
      {daysOfWeek.map(({ key, date, day }, index) => (
        <button
          key={key}
          className={getButtonClassNames(date)}
          type="button"
          onClick={() => setSelectedDate(parse(date, 'yyyy-MM-dd', new Date()).toISOString())}
        >
          {day}
          {daysIndicators && (
            <div className={styles.daysIndicators}>
              {new Array(daysIndicators[index])
                .fill(null)
                .slice(0, 4)
                .map((_, i) => (
                  <div className={styles.dayIndicator} key={i} />
                ))}
            </div>
          )}
        </button>
      ))}
    </div>
  );

  return (
    <div className={styles.mobileCalendar}>
      <div className={styles.header}>
        <span>{currentWeekDateLabel}</span>
        <div className={styles.arrows}>
          <span onClick={previousWeek}>
            <img alt="left-arrow" src={LeftArrowIcon} />
          </span>
          <span onClick={nextWeek}>
            <img alt="right-arrow" src={RightArrowIcon} />
          </span>
        </div>
      </div>
      <div className={styles.weekDays}>
        <span>S</span>
        <span>M</span>
        <span>T</span>
        <span>W</span>
        <span>T</span>
        <span>F</span>
        <span>S</span>
      </div>
      <Carousel
        slides={[weeklySliders, weeklySliders, weeklySliders]}
        onActiveIndexChange={onActiveIndexChange}
        defaultIndex={1}
      />
    </div>
  );
};

export default MobileCalendar;
