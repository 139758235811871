import React from 'react';

import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import Categories from '../Categories/Categories';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';

interface MobileCategoriesProps {
  visible: boolean;
  categories: LessonCategory[];
  selectedCategories: string[];
  setSelectedCategories: Function;
  onClose: Function;
}

const MobileCategories: React.FC<MobileCategoriesProps> = ({
  visible,
  categories,
  selectedCategories,
  setSelectedCategories,
  onClose,
}) => {
  const onApply = () => {
    onClose();
  };

  return (
    <MobileDrawer
      visible={visible}
      title="Filters"
      headerRightButton="clear"
      okButtonText="Apply"
      onClickOkButton={onApply}
      onClose={onClose}
      onClickHeaderRightButton={() => setSelectedCategories([])}
    >
      <Categories
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
    </MobileDrawer>
  );
};

export default MobileCategories;
