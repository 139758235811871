import { GuestAuthorizeModel } from 'api/models/auth.model';
import { BookingConfirmedEmailModel } from 'api/models/booking/bookingConfirmedEmail.model';
import { CreateBookingSessionModel } from 'api/models/booking/CreateBookingSession.model';
import { UpdateBookingSessionModel } from 'api/models/booking/updateBookingSession.model';
import { GetBookingSessionModel } from 'api/models/booking/getBookingSession.model';
import { BookingProcessModel } from 'api/models/booking/BookingProcess.model';
import { BaseRoute } from '../../base/Route.base';

export class Booking extends BaseRoute {
  async getBookingSession(clientSecret: string): Promise<GetBookingSessionModel> {
    return (await this.api.get(`booking/sessions?paymentIntentId=${clientSecret}`)).data;
  }

  async getGuestBookingSession(
    paymentIntentId: string,
    guestAuthorizeModel: GuestAuthorizeModel,
  ): Promise<GetBookingSessionModel> {
    return (
      await this.api.getWithGuestAuthorize(
        `booking/sessions?paymentIntentId=${paymentIntentId}`,
        guestAuthorizeModel,
      )
    ).data;
  }

  async deleteBookingSessionAsync(clientSecret: string) {
    return await this.api.delete(`booking/sessions?paymentIntentId=${clientSecret}`);
  }

  async bookingProcessAsync(bookingProcessModel: BookingProcessModel) {
    return (await this.api.post('booking/process', bookingProcessModel)).data;
  }

  async deleteGuestBookingSessionAsync(
    clientSecret: string,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    return await this.api.deleteWithGuestAuthorize(
      `booking/sessions?paymentIntentId=${clientSecret}`,
      guestAuthorizeModel,
    );
  }

  async createBookingSessionAsync(
    createBookingSessionModel: CreateBookingSessionModel,
  ): Promise<number> {
    return (await this.api.post('booking/sessions', createBookingSessionModel)).data;
  }

  async createGuestBookingSessionAsync(
    createBookingSessionModel: CreateBookingSessionModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ): Promise<number> {
    return (
      await this.api.postWithGuestAuthorize(
        'booking/sessions',
        createBookingSessionModel,
        guestAuthorizeModel,
      )
    ).data;
  }

  async updateBookingSessionAsync(updateSessionStatusModel: UpdateBookingSessionModel) {
    return await this.api.put('booking/sessions', updateSessionStatusModel);
  }

  async updateGuestBookingSessionAsync(
    updateSessionStatusModel: UpdateBookingSessionModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    return await this.api.putWithGuestAuthorize(
      'booking/sessions',
      updateSessionStatusModel,
      guestAuthorizeModel,
    );
  }

  async sendBookingConfirmedEmail(bookingConfirmedEmailModel: BookingConfirmedEmailModel) {
    return await this.api.post('booking/email/bookingConfirmed', bookingConfirmedEmailModel);
  }

  async sendGuestBookingConfirmedEmail(
    bookingConfirmedEmailModel: BookingConfirmedEmailModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    return await this.api.postWithGuestAuthorize(
      'booking/email/bookingConfirmed',
      bookingConfirmedEmailModel,
      guestAuthorizeModel,
    );
  }
}
