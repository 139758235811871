import { PayloadAction } from '@reduxjs/toolkit';
import { GuestAuthorizeModel } from 'api/models/auth.model';
import { AuthenticationState, AuthenticatedUser, InvitedUser } from './types';

export const loadWholeAuthenticationState = (
  state: AuthenticationState,
  action: PayloadAction<AuthenticationState>,
) => {
  state = action.payload;
};

export const updateInvitedUser = (
  state: AuthenticationState,
  action: PayloadAction<InvitedUser | null>,
) => {
  state.invitedUser = action.payload;
};

export const changeSignInOpen = (state: AuthenticationState, action: PayloadAction<boolean>) => {
  state.signInOpen = action.payload;
};

export const changeSignUpOpen = (state: AuthenticationState, action: PayloadAction<boolean>) => {
  state.signUpOpen = action.payload;
};

export const updateGuestModel = (
  state: AuthenticationState,
  action: PayloadAction<GuestAuthorizeModel>,
) => {
  state.guestModel = action.payload;
};

export const updateIsHeaderVisible = (
  state: AuthenticationState,
  action: PayloadAction<boolean>,
) => {
  state.isHeaderVisible = action.payload;
};

export const updateIsAuthenticated = (
  state: AuthenticationState,
  action: PayloadAction<boolean>,
) => {
  state.isAuthenticated = action.payload;
};

export const updateIsVerifyingToken = (
  state: AuthenticationState,
  action: PayloadAction<boolean>,
) => {
  state.isVerifyingToken = action.payload;
};

export const updateAuthenticatedUser = (
  state: AuthenticationState,
  action: PayloadAction<AuthenticatedUser | null>,
) => {
  state.authenticatedUser = action.payload;
};
