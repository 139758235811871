export const styles = {
  countries: {
    width: 'max-content',
    fieldset: {
      display: 'none',
    },
    // eslint-disable-next-line
      '&.Mui-focused:has(div[aria-expanded="false"])': {
      fieldset: {
        display: 'block',
      },
    },
    // eslint-disable-next-line
      '.MuiSelect-select': {
      padding: '8px',
      paddingRight: '24px !important',
    },
    svg: {
      right: 0,
    },
  },
  adornment: {
    marginRight: '2px',
    marginLeft: '-8px',
  },
};
