import React, { ReactComponentElement, useEffect } from 'react';
import clsx from 'clsx';
import styles from './ModalWindow.module.scss';
import Button from '../Button/Button';
import BackIcon from '../../assets/icons/navbar-icons/arrow-back.svg';

interface IProps {
  show: boolean;
  title?: string;
  children: ReactComponentElement<any>;
  saveBtnLabel?: string;
  onSaveClick?: any;
  okButtonDisabled?: boolean;
  okButtonType?: string;
  onCloseClick?: any;
  hideFooter?: boolean;
  customStyles?: string;
  loading?: boolean;
  extraButton?: boolean;
  extraBtnLabel?: string;
  onExtraClick?: any;
  extraButtonDisabled?: boolean;
}

const ModalWindow: React.FC<IProps> = ({
  show,
  title,
  children,
  saveBtnLabel = '',
  onSaveClick,
  okButtonDisabled = false,
  okButtonType,
  onCloseClick,
  hideFooter = false,
  customStyles,
  loading,
  extraButton,
  extraBtnLabel,
  onExtraClick,
  extraButtonDisabled,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [show]);

  const modalStyles = clsx(styles.modal, customStyles);

  return show ? (
    <div>
      <div className={styles.backdrop} onClick={onCloseClick}>
        <div className={modalStyles} onClick={(e) => e.stopPropagation()}>
          <div className={styles.mobileHeader}>
            <img alt="back-icon" src={BackIcon} onClick={onCloseClick} />
            <span className={styles.mobileTitle}>{title}</span>
            <span />
          </div>
          <div className={styles.body}>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{children}</div>
          </div>
          {!hideFooter && (
            <div className={styles.actions}>
              <div className={styles.closeBtn} onClick={onCloseClick}>
                Close
              </div>
              {extraButton && (
                <Button
                  className={styles.button}
                  label={extraBtnLabel ?? ''}
                  type={okButtonType}
                  onClick={onExtraClick}
                  loading={loading}
                  disabled={extraButtonDisabled}
                />
              )}
              <Button
                className={styles.button}
                label={saveBtnLabel}
                type={okButtonType}
                onClick={onSaveClick}
                loading={loading}
                disabled={okButtonDisabled}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default ModalWindow;
