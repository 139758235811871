import React, { useContext } from 'react';
import moment from 'moment-timezone';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ScheduledDate } from 'redux/slices';
import ServicesService from 'data/services/services.service';
import { BrandThemeContext } from 'common/context';
import { BaseLessonInformationProps } from './interfaces';
import { LessonInfoWithIcon } from './common';
import { useTimeSlots, useLesson, useService, useLocation, useSummary } from '../../hooks';
import { useMobileViewDetector } from '../../../../../../common/hooks/useMobileViewDetector';

interface ScheduledDateWithPositionsLeft extends ScheduledDate {
  participants: number;
  positionsLeft: number;
}

interface LessonSummaryCardProps extends BaseLessonInformationProps {
  count: number;
  timezone: string;
  selectedCoachName: string;
  scheduledDates: ScheduledDateWithPositionsLeft[];
  isLessonInvite: boolean;
}

export const LessonSummaryCard = ({
  id,
  count,
  timezone,
  selectedCoachName,
  scheduledDates,
  isLessonInvite,
}: LessonSummaryCardProps) => {
  const theme = useContext(BrandThemeContext);
  const { isViewedFromMobile } = useMobileViewDetector();
  const { getCoachesNames, getFormattedPrice } = useLesson();
  const { getStartEndDetailFromMilisWithTimeZone } = useTimeSlots();
  const { getLocationNameById } = useLocation();
  const {
    getServiceDetails,
    getServiceType,
    isAdvancedLesson,
    isProgramme,
    isEvent,
    shouldPriceDisplayed,
  } = useService();
  const { formattedDateText } = useSummary();

  const backgroundColor = theme.colors.black.opacity_5;
  const color = theme.colors.black.opacity_100;

  const service = getServiceDetails(id);
  if (!service) return null;

  const { durationInMinutes, title, assistanceStaff, locationIds } = service;
  const formatedPrice = getFormattedPrice(service.priceInCents);
  const formatedTotalPrice = getFormattedPrice(service.priceInCents * (count ?? 1));

  return (
    <Box mb={2}>
      <Card id={`lesson-${id}`} sx={{ backgroundColor, color, cursor: 'pointer' }} elevation={1}>
        <CardContent>
          {isViewedFromMobile && (
            <Box
              fontSize="0.8rem"
              bgcolor={theme.colors.black.opacity_20}
              borderRadius={1}
              marginRight={1}
              marginBottom={1}
              paddingX={1}
              color={theme.colors.white}
              display="inline-block"
              textTransform="uppercase"
            >
              {getServiceType(id)}
            </Box>
          )}

          <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
            {!isViewedFromMobile && (
              <Stack direction="row" justifyContent="normal">
                <Box
                  height="1.2rem"
                  lineHeight="1.2rem"
                  fontSize="0.7rem"
                  bgcolor={theme.colors.black.opacity_20}
                  borderRadius={1}
                  marginRight={1}
                  marginTop="2px"
                  paddingX={1}
                  color={theme.colors.white}
                  textTransform="uppercase"
                  whiteSpace="nowrap"
                >
                  {getServiceType(id)}
                </Box>
                <Typography fontSize="1rem" fontWeight="bold">
                  {isAdvancedLesson(service) ||
                  (service.isPackage && (isProgramme(service) || isEvent(service)))
                    ? title
                    : `${count} x ${title}`}
                </Typography>
              </Stack>
            )}

            {isViewedFromMobile && (
              <Typography fontSize="1rem" fontWeight="bold">
                {count} x {title}
              </Typography>
            )}

            {shouldPriceDisplayed(service) && (
              <Typography fontSize="1rem" whiteSpace="nowrap">
                {formatedTotalPrice} pp
              </Typography>
            )}
          </Stack>

          <Stack direction="column">
            <Grid container>
              <Grid item xs={12} md={12}>
                <LessonInfoWithIcon
                  type="time"
                  texts={[
                    `${durationInMinutes} mins`,
                    shouldPriceDisplayed(service) ? `${formatedPrice} pp` : '',
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <LessonInfoWithIcon type="coach" texts={[selectedCoachName]} />
                {assistanceStaff && assistanceStaff.length > 0 && (
                  <LessonInfoWithIcon
                    type="additionalCoach"
                    texts={[
                      `${
                        assistanceStaff?.length > 1 ? 'Additional Coaches' : 'Additional Coach'
                      } ${getCoachesNames(assistanceStaff)}`,
                    ]}
                  />
                )}
                {locationIds.map((locationId) => (
                  <LessonInfoWithIcon
                    key={locationId}
                    type="place"
                    texts={[getLocationNameById(locationId)]}
                  />
                ))}
              </Grid>
            </Grid>
            <Box paddingLeft={0} paddingTop={2}>
              {scheduledDates.map((scheduledDate, index) => {
                const timeStart = getStartEndDetailFromMilisWithTimeZone(
                  scheduledDate.dateStart,
                  timezone,
                ).dateTimeShort;

                const timeEnd = getStartEndDetailFromMilisWithTimeZone(
                  scheduledDate.dateEnd,
                  timezone,
                ).timeShort;

                const isOverdue = ServicesService.isOverdue(service, isLessonInvite);
                const isOverdueText = formattedDateText(scheduledDate.dateStart, isOverdue);

                const timeZone = `(GMT${moment.tz(timezone).format('Z')})`;

                return (
                  <Box
                    fontSize="0.95rem"
                    key={index}
                    marginBottom={0.5}
                    bgcolor={theme.colors.white}
                    padding={1.5}
                    borderRadius={1}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6}>
                        {isOverdueText}
                        {timeStart} - {timeEnd} {timeZone}
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {scheduledDate.positionsLeft}{' '}
                        {scheduledDate.positionsLeft === 1 ? 'position' : 'positions'} left
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};
