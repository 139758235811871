import { DateTime } from 'luxon';
import { DayOfWeekEnum } from '../../data/enums/DayOfWeek.enum';
import { Event } from '../../data/entities/events.entity';
import { Lesson } from '../../data/entities/lessons.entity';

export const normalizeYear = (value: string, max: number): string => {
  return value.length < max ? normalizeYear(`0${value}`, max) : value;
};

/**
 * Returns new date with the parsed string (e.g. '12:30').
 * @param date
 * @param timeString
 * @returns
 */
export function getFormattedDateUsingHHMMstring(date: DateTime, timeString: string): DateTime {
  const isAm = timeString.includes('am');
  const isPm = timeString.includes('pm');

  if (!isAm && !isPm) {
    const timeArray = timeString.split(':');
    const hours = parseInt(timeArray[0], 10);
    const timeMinutes = parseInt(timeArray[1], 10);
    return date.set({ hour: hours, minute: timeMinutes });
  }

  let formattedTimeString = '';
  let timeHours = 0;
  if (isAm) {
    formattedTimeString = timeString.replace('am', '').trim();
    timeHours = parseInt(formattedTimeString.split(':')[0], 10);
    if (timeHours === 12) timeHours -= 12;
  } else if (isPm) {
    formattedTimeString = timeString.replace('pm', '').trim();
    timeHours = parseInt(formattedTimeString.split(':')[0], 10);
    if (timeHours < 12) timeHours += 12;
  }
  const timeMinutes = parseInt(formattedTimeString.split(':')[1], 10);
  return date.set({ hour: timeHours, minute: timeMinutes });
}

/**
 * Create a DateTime for the current instant, in the system's time zone.
 * @returns
 */
export function now() {
  return DateTime.now();
}

/**
 * Create a DateTime in UTC
 * @returns
 */
export function utcNow() {
  return DateTime.utc();
}

/**
 * Gets the current unix milliseconds since epoch. This has no offset and is the base utc.
 * @returns number 1696466610184
 */
export function getUnixMilliseconds() {
  return utcNow().toMillis();
}

/**
 * Create a DateTime from a number of milliseconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
 * @param date Unix milliseconds
 * @returns
 */
export function getDateFromUnixMilli(date: number) {
  return DateTime.fromMillis(date);
}

/**
 * Converts date to zoned Date object.
 * @param date Unix time in milliseconds since epoch (1696466610184) || date value in string
 * @param timezone e.g. Pacific/Auckland, America/Detroit etc.
 * @returns
 */
export function getZonedDate(date: number, timezone: string) {
  // Parse the input date as a DateTime object
  const dateTime = DateTime.fromMillis(Number(date));

  // Convert the DateTime object to the specified time zone
  const zonedDate = dateTime.setZone(timezone);

  // Return the zoned DateTime object
  return zonedDate;
}

export function getZonedDateNow(timezone: string) {
  const currentUnix = getUnixMilliseconds();
  return getZonedDate(currentUnix, timezone);
}

/**
 *
 * @param isoDateString 2016-05-25T09:08:34.123
 * @returns DateTime object
 */
export function getDateFromIsoString(isoDateString: string) {
  return DateTime.fromISO(isoDateString);
}

/**
 *
 * @param isoDateString 2016-05-25T09:08:34.123
 * @param timezone e.g. Pacific/Auckland, America/Detroit etc.
 * @returns DateTime object
 */
export function getZonedDateFromIsoString(isoDateString: string, timezone: string) {
  const date = DateTime.fromISO(isoDateString);
  return getZonedDate(date.toMillis(), timezone);
}

/**
 * If DateTime.weekday does not  matching the targetDayOfWeek, it will get the next DateTime which matches.
 * @param currentDate DateTime object
 * @param ISOweekDate 1 = Monday, 7 = Sunday.
 * @see — https://en.wikipedia.org/wiki/ISO_week_date
 * @returns DateTime object.
 */
export function getNextDayOfWeek(currentDate: DateTime, ISOweekDate: number) {
  let date = currentDate;
  while (date.weekday !== ISOweekDate) {
    date = date.plus({ days: 1 });
  }
  return date;
}

/**
 * Converts DayOfWeekEnum to ISO_week_date
 * @param dayOfWeekEnum
 * @see — https://en.wikipedia.org/wiki/ISO_week_date
 * @returns ISO week Date, Monday = 1, Sunday = 7
 */
export function convertDayOfWeekToIsoWeek(dayOfWeekEnum: DayOfWeekEnum) {
  const isoWeekDates = [7, 1, 2, 3, 4, 5, 6];
  return isoWeekDates[dayOfWeekEnum];
}

export const getDateTimeFormat = (dateTime: DateTime) => dateTime.toFormat('EEE d MMM yyyy H:mm a');

export const isUserTimeZoneEvent = (event: Event | Lesson) => {
  if ((event as Event).date_begin > 0) {
    return true;
  }

  const lesson = event as Lesson;
  return lesson.is_self_taught === true;
};

/**
 * Normalizes the seconds and milliseconds of a date time object.
 * @example { hour: 8, minute: 0, second: 10, millisecond: 498 } => { hour: 8, minute: 0, second: 0, millisecond: 0}
 * @param dateTime
 * @returns new DateTime object
 */
export const normalizeSecondsAndMilliseconds = (dateTime: DateTime) => {
  return dateTime.set({
    second: 0,
    millisecond: 0,
  });
};
