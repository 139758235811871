/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { AuthenticatedUser, authenticationSlice } from 'redux/slices';

import styles from './LoginPage.module.scss';
import logo from '../../../../assets/images/auth/login-image.png';
import InputField from '../../../../components/InputField/InputField';
import Link from '../../../../components/Link/Link';
import AuthMenu from '../../components/AuthMenu/AuthMenu';
import SessionProvider from '../../../../providers/SessionProvider';
import { redirectToOrgInvitation } from '../../../../common/utils/redirectOrgInvite';
import FormErrorMessage from '../../components/FormErrorMessage/FormErrorMessage';
import validators from '../../../../validations/validators';
import Logger from '../../../../middleware/logger.middleware';
import UserService from '../../../../data/services/user.service';
import { OrgInvitationModel } from '../../../../data/models/orgInvitation.model';
import { useAuth } from '../../../../common/hooks/useAuth';
import Spinnerv2 from '../../../../components/Spinnerv2/Spinnerv2';

const LoginPage = () => {
  const dispatch = useDispatch();

  const authHook = useAuth();
  const { params } = authHook;
  const emailParam = params.get('email')?.toLowerCase();
  const [email, setEmail] = useState(emailParam ?? '');
  const [password, setPassword] = useState('');
  const [messageToasted, setIsMessageToasted] = useState(false);
  const [cookies] = useCookies(['orgInvitation']);
  const [rememberMe] = useState<boolean>(true);
  const [errorMessageEvent, UpdateErrorMessageEvent] = useReducer(
    (prev: any, next: any) => {
      return { ...prev, ...next };
    },
    {
      emailInputError: '',
      passwordInputError: '',
      requestError: '',
    },
  );
  const [isAuthorising] = useState(false);
  const history = useHistory();

  /**
   * NOTE:
   * I commented this as it is not being used for the eslint fix ticket.
   * I don't want to delete it as I think it may be important later on
   */
  // const signupLink = `/signup${params.toString() ? `?${params.toString()}` : ''}`;

  const onEmailChanged = (e: any) => {
    UpdateErrorMessageEvent({ emailInputError: '' });
    setEmail((e.target.value as string).toLowerCase());
  };
  const onPasswordChanged = (e: any) => {
    UpdateErrorMessageEvent({ passwordInputError: '' });
    setPassword(e.target.value);
  };
  const onSignInClicked = async () => {
    UpdateErrorMessageEvent({ requestError: '' });
    const emailValidation = email.match(validators.emailAddressFormat);
    if (!emailValidation) {
      UpdateErrorMessageEvent({ emailInputError: 'Must be a valid email address' });
      return;
    }

    try {
      const result = await UserService.userLogin(email, password);
      if (!result) {
        UpdateErrorMessageEvent({ requestError: 'User is null' });
        return;
      }

      const { accessToken, refreshToken, userId, firstName, lastName } = result;

      SessionProvider.updateSession(
        accessToken as string,
        refreshToken as string,
        userId as string,
        email,
        firstName,
        rememberMe,
      );

      const authenticatedUser: AuthenticatedUser = {
        accessToken: accessToken as string,
        refreshToken: refreshToken as string,
        userId,
        firstName,
        lastName,
      };

      /** First step to decouple logic by using redux state */
      dispatch(authenticationSlice.actions.updateIsAuthenticated(true));
      dispatch(authenticationSlice.actions.updateAuthenticatedUser(authenticatedUser));

      const orgInvitationModel: OrgInvitationModel | undefined = cookies.orgInvitation;
      if (orgInvitationModel) {
        redirectToOrgInvitation(history, orgInvitationModel);
      } else {
        authHook.redirectAfterSignIn();
      }
    } catch (error: any) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      UpdateErrorMessageEvent({ requestError: 'Invalid username/password' });
    }
  };

  useEffect(() => {
    if (!messageToasted) {
      const errorMessage = params.get('error');
      if (errorMessage) {
        toast.warn(errorMessage);
      }
      setIsMessageToasted(true);
    }
  }, [messageToasted, params]);

  if (isAuthorising) {
    return <Spinnerv2 message="Authorising..." />;
  }

  return (
    <AuthMenu
      logo={logo}
      withWelcome
      headerTitle="Sign in to your account"
      btnLabel="Sign in"
      onBtnClicked={onSignInClicked}
      // authLink={signupLink}
      // authLinkHeader={"Don't have an account?"}
      // authLinkText="Sign up "
      isBtn
    >
      <InputField
        type="email"
        placeholder="Email"
        onChange={onEmailChanged}
        defaultValue={email}
        required
      />
      <FormErrorMessage message={errorMessageEvent.emailInputError} />
      <InputField type="password" placeholder="Password" onChange={onPasswordChanged} required />
      <FormErrorMessage message={errorMessageEvent.passwordInputError} />

      <div className={styles.actions}>
        <Link url="/login/forgotPassword" text="Forgot password?" />
      </div>
      <FormErrorMessage message={errorMessageEvent.requestError} />
    </AuthMenu>
  );
};

export default LoginPage;
