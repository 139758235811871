import React from 'react';
import styles from './HeaderNavView.module.scss';
import backBtn from '../../../assets/images/arrows/left-arrow.svg';

interface IHeaderViewNav {
  onClose: any;
  label: string;
}

const HeaderNavView: React.FC<IHeaderViewNav> = ({ onClose, label }) => {
  return (
    <div className={styles.HeaderNavView}>
      <img src={backBtn} alt="left back" onClick={onClose} className={styles.arrow} />
      <span>{label}</span>
      <span> </span>
    </div>
  );
};

export default HeaderNavView;
