/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { Exercise, ExerciseModel } from '../entities/exercises.entity';
import {
  ClientNote,
  EventType,
  Lesson,
  LessonClient,
  LessonClientStatus,
} from '../entities/lessons.entity';
import RealmRepositories from '../base/realm.repo';
import Logger from '../../middleware/logger.middleware';
import { Config } from '../../config';
import { getUnixMilliseconds, now } from '../../common/utils/date.helpers';

export default class LessonsService {
  static async getAllUserLessonsByOrgId(userId: string, orgId: string) {
    const result = await RealmRepositories.Lessons.getAllUserLessonsByOrgId(userId, orgId);
    return result;
  }

  static async getAllUserPracticeByOrgId(
    userId: string,
    orgId: string,
    selectedTab: number,
  ): Promise<Lesson[]> {
    const result = await RealmRepositories.Lessons.getAllUserPracticesByOrgId(
      userId,
      orgId,
      selectedTab,
    );

    return result;
  }

  static async getUserViewableLessonsByOrdId(userId: string, orgId: string) {
    const lessons = await LessonsService.getAllUserLessonsByOrgId(userId, orgId);
    return lessons.filter((x) => {
      const foundLessonClient = x.clients.find((client) => client.user_id === userId);
      return (
        foundLessonClient?.status === (LessonClientStatus.Accepted || LessonClientStatus.Cancelled)
      );
    });
  }

  static async getUserAcceptedLessonsByOrdId(userId: string, orgId: string) {
    const lessons = await LessonsService.getAllUserLessonsByOrgId(userId, orgId);
    return lessons.filter((x) => {
      const foundLessonClient = x.clients.find((client) => client.user_id === userId);
      return foundLessonClient?.status === LessonClientStatus.Accepted;
    });
  }

  static async getAllUserSelfPracticeByOrgId(
    userId: string,
    orgId: string,
    self: boolean,
  ): Promise<Lesson> {
    const result = await RealmRepositories.Lessons.getAllUserSelfPracticesByOrgId(
      userId,
      orgId,
      self,
    );
    return result;
  }

  static async getAllLessonsByPackageId(packageId: string): Promise<Lesson[] | undefined> {
    const result = await RealmRepositories.Lessons.getAllLessonsByPackageId(packageId);
    return result;
  }

  static async getExercisesByLessonId(lessonId: string) {
    const result = await RealmRepositories.Lessons.getLessonById(lessonId);
    const exercisesArray: ExerciseModel[] = [];
    if (result) {
      if (result?.exercise_ids) {
        const exercises = await this.getExercisesByIds(result?.exercise_ids);
        return exercises;
      }
    }

    return exercisesArray;
  }

  static async getExercisesByIds(exerciseIds: string[]) {
    const exercisesArray: ExerciseModel[] = [];

    const exercises: Exercise[] | undefined = await RealmRepositories.Exercises.getExercisesByIds(
      exerciseIds,
    );

    if (exercises) {
      // TODO: 23.05.23 - Perf: Add to promises, awaiting in foreach is slow.
      for await (const exercise of exercises) {
        const exerciseObject: ExerciseModel = {
          title: exercise.title,
          exerciseObj: exercise,
          attachments: [],
        };
        const attachments = await RealmRepositories.Attachments.getAttachmentsByIds(
          exercise.attachment_ids ?? [],
        );
        if (attachments) {
          for await (const attachment of attachments) {
            const attachmentType = JSON.parse(attachment.attachment_type);

            if (Object.keys(attachmentType).includes('file_id')) {
              const index = Object.keys(attachmentType).indexOf('file_id');
              const fileId = Object.values(attachmentType)[index];
              const file = await RealmRepositories.Files.getFileByIdAsync(fileId as string);

              exerciseObject.attachments.push(file);
              if (!exerciseObject.fileUrl) {
                exerciseObject.fileUrl = Config().AWSCloudFrontUrl + file.file_name;
                exerciseObject.thumbnailUrl =
                  (Config().AWSCloudFrontUrl as string) + file.thumbnail_url;
                exerciseObject.fileExtension = file.extension;
                exerciseObject.attachmentId = attachment._id;
                exerciseObject.contentType = file.content_type;
              }
            }

            exerciseObject.sectionIndex = 4;
          }
        }
        exercisesArray.push(exerciseObject);
      }
    }

    return exercisesArray;
  }

  static async createPracticeLesson(
    orgId: string,
    userId: string,
    title: string,
    note: string,
    dateStart: number,
    dateEnd: number,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCategories: string[],
    isShareCoach: boolean,
  ) {
    const clientData: LessonClient = {
      user_id: userId,
      status: LessonClientStatus.Accepted,
      is_accepted: true,
      is_payment_in_app: false,
      is_paid: false,
      date_accepted: getUnixMilliseconds(),
    };

    const client_notes: ClientNote[] = [];
    if (note) {
      const clientNote: ClientNote = {
        _id: uuidv4(),
        user_id: userId,
        message: note,
        date_created: getUnixMilliseconds(),
        date_updated: getUnixMilliseconds(),
        deleted: false,
      };
      client_notes.push(clientNote);
    }

    const lesson: Lesson = {
      _id: uuidv4(),
      org_id: orgId,
      title,
      type: 0,
      price_in_cents: 0,
      clients: [clientData],
      client_notes,
      date_start: dateStart,
      date_end: dateEnd,
      is_self_taught: true,
      date_created: getUnixMilliseconds(),
      date_updated: getUnixMilliseconds(),
      deleted: false,
      service_id: '',
      max_participants: 1,
      duration_in_minutes: Math.floor(
        DateTime.fromMillis(dateEnd).diff(DateTime.fromMillis(dateStart), 'minutes').minutes,
      ),
      coaches: [],
      is_package: false,
      allow_guests: false,
      is_invite_only: false,
      share_practice_lesson_with_coach: isShareCoach,
      contact_ids: selectedContactList,
      location_id: chosenLocationId,
      org_lesson_category_ids: selectedCategories,
    };

    try {
      await RealmRepositories.Lessons.createPracticeLesson(lesson);
    } catch (e) {
      console.log(e);
    }

    return lesson;
  }

  static UpdateInvitedClientStatus(
    lessonId: string,
    invitedId: string,
    userId: string,
    inviteStatusEnum: LessonClientStatus,
  ) {
    return RealmRepositories.Lessons.updateInvitedClientStatus(
      lessonId,
      invitedId,
      userId,
      inviteStatusEnum,
    );
  }

  // only for practice, is_self_taught is true & if current user is the only client
  static async updateLessonTitleAndCategories(id: string, title: string, categories: string[]) {
    const result = await RealmRepositories.Lessons.updateLessonTitleAndCategories(
      id,
      title,
      categories,
    );
    return result;
  }

  static async updatePracticeLesson(
    lessonID: string,
    lessonTitle: string,
    note: string,
    startDate: number,
    endDate: number,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCategories: string[],
    isShareCoach: boolean,
  ) {
    const result = await RealmRepositories.Lessons.updatePracticeLesson(
      lessonID,
      lessonTitle,
      note,
      startDate,
      endDate,
      chosenLocationId,
      selectedContactList,
      selectedCategories,
      isShareCoach,
    );
    return result;
  }

  static async addClientNote(
    lessonId: string,
    userId: string,
    message: string,
  ): Promise<Lesson | undefined> {
    const note = {
      _id: uuidv4(),
      user_id: userId,
      message,
      date_created: getUnixMilliseconds(),
      date_updated: getUnixMilliseconds(),
      deleted: false,
    };

    // We are using realm-web, which does not support aggregation as part of update.
    // We have to fetch the document, and then evaluate the field type.
    const lesson = await RealmRepositories.Lessons.getLessonById(lessonId);
    if (!lesson) {
      if (Logger.isDevEnvironment) {
        console.error('Could not find lesson with id: ', lessonId);
      }
      return undefined;
    }

    let result;
    if (!lesson.client_notes) {
      result = await RealmRepositories.Lessons.setLessonClientNotes(lessonId, note);
    } else {
      result = await RealmRepositories.Lessons.pushLessonClientNotes(lessonId, note);
    }

    return result;
  }

  static async addClientConfidentialNote(
    lessonId: string,
    message: string,
    userId: string,
  ): Promise<Lesson | undefined> {
    const selfNote = {
      _id: uuidv4(),
      message,
      user_id: userId,
      date_created: getUnixMilliseconds(),
      date_updated: getUnixMilliseconds(),
      deleted: false,
    };

    // We are using realm-web, which does not support aggregation as part of update.
    // We have to fetch the document, and then evaluate the field type.
    const lesson = await RealmRepositories.Lessons.getLessonById(lessonId);
    if (!lesson) {
      if (Logger.isDevEnvironment) {
        console.error('Could not find lesson with id: ', lessonId);
      }
      return undefined;
    }

    let result;
    if (!lesson.client_confidential_notes) {
      result = await RealmRepositories.Lessons.setLessonClientConfidentialNotes(lessonId, selfNote);
    } else {
      result = await RealmRepositories.Lessons.pushLessonClientConfidentialNotes(
        lessonId,
        selfNote,
      );
    }

    return result;
  }

  static async getClientNotesByLessonId(lessonId: string): Promise<ClientNote[] | undefined> {
    const result = await RealmRepositories.Lessons.getLessonById(lessonId);
    return result?.client_notes;
  }

  static async getLessonById(lessonId: string): Promise<Lesson | undefined> {
    const result = await RealmRepositories.Lessons.getLessonById(lessonId);
    return result;
  }

  static async getLessonByIdAndUserId(
    lessonId: string,
    userId: string,
  ): Promise<Lesson | undefined> {
    const result = await RealmRepositories.Lessons.getLessonByIdAndUserId(lessonId, userId);
    return result;
  }

  /**
   * Gets the lesson and checks if the user belongs to the lesson via userId/email.
   *
   * If the user belongs to the lesson only via email it does the following:
   * - Checks if the user is a client of the organization, if no adds them as a client.
   * - Updates all lesson which match the user's email and user_id is null or 'guest.
   * @param lessonId
   * @param userId
   * @param email
   * @returns
   */
  static async getAcceptedLessonByIdByUserIdOrEmail(
    lessonId: string,
    userId: string,
    email: string,
  ): Promise<Lesson | undefined> {
    const lesson = await RealmRepositories.Lessons.getUserAcceptedLessonByIdAndIsValid(
      lessonId,
      userId,
      email,
    );
    if (!lesson) return undefined;

    const lessonClientByUserId = lesson.clients.find((x) => x.user_id === userId);
    if (lessonClientByUserId) return lesson;

    try {
      await RealmRepositories.Lessons.updateClientUserIdsForAllLessons(
        lesson.org_id,
        email,
        userId,
      );
    } catch (ex: any) {
      console.error(ex);
    }

    return lesson;
  }

  static async getAcceptedOrCancelledLessonsForUser(
    userId: string,
    email: string,
    orgId: string,
    selectedTab: number,
    selectedParentTab: number,
    selectedCategories: string[],
    searchValue: string,
    currentIndex: number,
    pageSize: number,
  ) {
    const lessons =
      await RealmRepositories.Lessons.getUserAcceptedOrCancelledLessonsByUserIdOrEmailAndOrgId(
        userId,
        email,
        orgId,
        selectedTab,
        selectedParentTab,
        selectedCategories,
        searchValue,
        currentIndex,
        pageSize,
      );

    if (!lessons || lessons.length === 0) return undefined;

    let missingUserId = false;
    lessons.forEach((x: Lesson) => {
      const foundClient = x.clients.find((client) => client.user_id === userId);
      if (!foundClient) {
        if (Logger.isDevEnvironment) console.log(`Found a lesson missing user_id`);
        missingUserId = true;
        // eslint-disable-next-line no-useless-return
        return;
      }
    });
    if (!missingUserId) return lessons;

    try {
      if (Logger.isDevEnvironment) console.log('Updating all lessons to include user_id');
      await RealmRepositories.Lessons.updateClientUserIdsForAllLessons(orgId, email, userId);
    } catch (ex: any) {
      console.error(ex);
    }

    return lessons;
  }

  static async getLessonByOrgIdAndUserId(
    orgId: string,
    userId: string,
  ): Promise<Lesson[] | undefined> {
    const result = await RealmRepositories.Lessons.getLessonByOrgIdAndUserId(orgId, userId);
    return result;
  }

  static async updateClientNote(lessonId: string, noteId: string, text: string) {
    const result = await RealmRepositories.Lessons.updateClientNote(lessonId, noteId, text);
    return result;
  }

  static async updateClientConfidentialNote(lessonId: string, noteId: string, text: string) {
    const result = await RealmRepositories.Lessons.updateClientConfidentialNote(
      lessonId,
      noteId,
      text,
    );
    return result;
  }

  static async deleteClientNote(lessonId: string, noteId: string) {
    const result = await RealmRepositories.Lessons.deleteClientNote(lessonId, noteId);
    return result;
  }

  static async deleteClientConfidentialNote(lessonId: string, noteId: string) {
    const result = await RealmRepositories.Lessons.deleteClientConfidentialNote(lessonId, noteId);
    return result;
  }

  static async getActivityFeed(userId: string, orgId: string) {
    // TODO: 18.04.23 - Future Feature: Pagination
    const returnResult = [];
    const nextTenLessons = await RealmRepositories.Lessons.getNextTenLessonsForUser(orgId, userId);
    if (nextTenLessons) {
      const lessons = nextTenLessons.map((lesson: any) => {
        return { ...lesson, type: EventType.Lesson };
      });
      returnResult.push(lessons);
    }

    const nextTenEvents = await RealmRepositories.Events.getNextTenEventsForUser(orgId, userId);
    if (nextTenEvents) {
      const events = nextTenEvents.map((event: any) => {
        return { ...event, type: EventType.Event };
      });
      returnResult.push(events);
    }

    const sortedResult = returnResult
      .flat()
      .sort(
        (a: any, b: any) =>
          parseFloat(a.type === EventType.Lesson ? a.date_start : a.date_begin) -
          parseFloat(b.type === EventType.Lesson ? b.date_start : b.date_begin),
      )
      .slice(0, 10); // Limit total to 10
    return sortedResult;
  }

  static async getInvitedLessons(userId: string, orgId: string, email: string) {
    try {
      const invitedLessons = await RealmRepositories.Lessons.getUserInvitedLessons(
        orgId,
        userId,
        email,
      );
      const lessons = invitedLessons?.map((lesson: Lesson) => {
        return { ...lesson, type: EventType.LessonInvite };
      });
      return lessons;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async getProductivityData(userId: string, orgId: string) {
    const monthsLabel = [];
    const startOfCurrentMonth = now().startOf('month');
    let lessonsCounts = [];
    let practiceLessonsCounts = [];
    let eventCounts = [];
    const lessonPromises = [];
    const eventPromises = [];
    const practicePromises = [];
    const helperArr = [5, 4, 3, 2, 1, 0];
    for await (const count of helperArr) {
      // TODO: 23.05.23 - Perf: awaiting in for loop is not good.
      monthsLabel.push(now().get('month') - count);
      const currentStartOfMonthTimestamp = startOfCurrentMonth.minus({ months: count }).toMillis();
      const nextStartOfMonthTimestamp = startOfCurrentMonth
        .minus({ months: count })
        .plus({ months: 1 })
        .toMillis();

      lessonPromises.push(
        await RealmRepositories.Lessons.getLessonsCountForUserBetweenDates(
          orgId,
          userId,
          currentStartOfMonthTimestamp,
          nextStartOfMonthTimestamp,
        ),
      );
      practicePromises.push(
        await RealmRepositories.Lessons.getPracticeLessonsCountForUserBetweenDates(
          orgId,
          userId,
          currentStartOfMonthTimestamp,
          nextStartOfMonthTimestamp,
        ),
      );
      eventPromises.push(
        await RealmRepositories.Events.getEventsCountForUserBetweenDates(
          orgId,
          userId,
          currentStartOfMonthTimestamp,
          nextStartOfMonthTimestamp,
        ),
      );
    }

    lessonsCounts = await Promise.all(lessonPromises);
    practiceLessonsCounts = await Promise.all(practicePromises);
    eventCounts = await Promise.all(eventPromises);

    const convertedLessonsCount: number[] = lessonsCounts.map((item) =>
      item !== undefined ? parseInt(item as unknown as string, 10) : 0,
    );

    const convertedPracticeLessonsCount: number[] = practiceLessonsCounts.map((item) =>
      item !== undefined ? parseInt(item as unknown as string, 10) : 0,
    );

    const convertedEventsCount: number[] = eventCounts.map((item) =>
      item !== undefined ? parseInt(item as unknown as string, 10) : 0,
    );

    return {
      months: monthsLabel,
      lessons: convertedLessonsCount,
      practiceLessons: convertedPracticeLessonsCount,
      events: convertedEventsCount,
    };
  }

  static isOverdue(lesson: Lesson): boolean {
    return lesson.date_start < getUnixMilliseconds();
  }

  static isPast(lesson: Lesson): boolean {
    return lesson.date_end < getUnixMilliseconds();
  }

  static isFullyBooked(lesson: Lesson): boolean {
    const acceptedClients = lesson.clients.filter(
      (client: LessonClient) =>
        client.is_accepted === true && client.status === LessonClientStatus.Accepted,
    );

    return acceptedClients.length >= lesson.max_participants;
  }

  static async getLessonInvitationEmailByInvitationId(
    invitationId: string,
  ): Promise<string | undefined> {
    const result = await RealmRepositories.Lessons.getLessonInvitationEmailByInvitationId(
      invitationId,
    );
    return result;
  }
}
