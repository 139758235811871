/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import clsx from 'clsx';

import { BrandThemeContext } from 'common/context';
import SessionProvider from 'providers/SessionProvider';
import { LessonsTabs } from 'modules/lesson/models/lessons.model';
import { Lesson } from 'data/entities/lessons.entity';
import LessonHelper from 'common/utils/lessons.helper';

import EmptyState from 'components/EmptyState/EmptyState';
import AttachmentsThumbnailsList from 'modules/attachment/components/AttachmentsThumbnailsList/AttachmentsThumbnailsList';
import DateList from 'components/List/DateList/DateList';
import HiddenListItem from 'components/List/HiddenListItem';
import InlineSpinner from 'components/InlineSpinner/InlineSpinner';
import listViewStyles from './ListView.module.scss';

interface ListViewProps {
  source: Lesson[];
  onItemClick: any;
  emptyListText: string;
  itemDropdown?: any;
  previewItemColumn?: any;
  showListItem?: any;
  selectedTab?: number;
  isLoading?: boolean;
}

const ListView = ({
  source,
  onItemClick,
  emptyListText,
  itemDropdown,
  previewItemColumn,
  showListItem = () => {
    return true;
  },
  selectedTab,
  isLoading = false,
}: ListViewProps) => {
  const theme = useContext(BrandThemeContext);
  const timeZone = SessionProvider.getTimeZoneInfo();

  let sortedSource = source.sort((a, b) => {
    return b.date_start - a.date_start;
  });

  if (selectedTab !== LessonsTabs.Upcoming)
    sortedSource = sortedSource.sort((a, b) => {
      if (!showListItem(a) && showListItem(b)) return 1;
      if (showListItem(a) && !showListItem(b)) return -1;
      return 0;
    });
  else
    sortedSource = source.sort((a, b) => {
      return a.date_start - b.date_start;
    });

  const getHiddenListItemStartIndex = () => {
    const lessonsWithIsDisplayedFlag = sortedSource.map((item, i) => ({
      ...item,
      isDisplayed: showListItem(item, i),
    }));
    return lessonsWithIsDisplayedFlag.findIndex((lesson) => !lesson.isDisplayed);
  };

  const styles = {
    wrapper: {
      flex: 1,
      overflow: 'hidden',
      bgcolor: theme.colors.white,
      borderRadius: '20px',
      border: 'none',
    },
  };

  return (
    <Stack sx={styles.wrapper}>
      {!source.length && !isLoading && <EmptyState text={emptyListText} />}
      {sortedSource.map((item: Lesson, i) =>
        !showListItem(item, i) ? (
          <HiddenListItem
            index={i}
            key={i}
            showUpgradePlanButtonIndex={getHiddenListItemStartIndex()}
            subscriptionText="Subscribe to Total Plan to view all your past lessons"
          />
        ) : (
          <Box key={i}>
            <div
              key={(item as Lesson)._id}
              className={listViewStyles.itemWrapper}
              onClick={() => onItemClick((item as Lesson)._id, showListItem(item, i))}
            >
              {(item as Lesson).date_start && (
                <DateList dateUnixMilli={(item as Lesson).date_start} timeZoneInfo={timeZone} />
              )}
              {!(item as Lesson).date_start &&
                previewItemColumn &&
                previewItemColumn((item as Lesson)._id)}
              <div className={listViewStyles.titleColumn}>
                <div className={listViewStyles.title}>{item.title}</div>
                {(item as Lesson)?.thumbnails && !!(item as Lesson).thumbnails?.length && (
                  <AttachmentsThumbnailsList thumbnails={(item as Lesson)?.thumbnails || []} />
                )}
              </div>
              {LessonHelper.isLessonCancelled(item as Lesson) && (
                <span className={listViewStyles.canceledLabel}>Canceled</span>
              )}
            </div>
            {itemDropdown && itemDropdown((item as Lesson)._id)}
          </Box>
        ),
      )}
      {isLoading ? (
        <Box sx={styles.wrapper} marginY="5" className={clsx(listViewStyles.wrapper, 'mt-5 mb-5')}>
          <InlineSpinner />
        </Box>
      ) : (
        ''
      )}
    </Stack>
  );
};

export default ListView;
