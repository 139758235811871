import React from 'react';
import { parseISO, format } from 'date-fns';

import LeftArrowIcon from '../../../../assets/icons/arrows/left-arrow.svg';
import RightArrowIcon from '../../../../assets/icons/arrows/right-arrow.svg';

import styles from './CalendarPageHeader.module.scss';

interface ICalendarPageHeaderProps {
  selectedDate: string;
  nextDay: () => void;
  previousDay: () => void;
}

const CalendarPageHeader: React.FC<ICalendarPageHeaderProps> = ({
  selectedDate,
  nextDay,
  previousDay,
}) => (
  <div className={styles.header}>
    <span className={styles.date}>{format(parseISO(selectedDate), 'EEEE d')}</span>
    <div className={styles.arrows}>
      <span onClick={previousDay}>
        <img alt="left-arrow" src={LeftArrowIcon} />
      </span>
      <span onClick={nextDay}>
        <img alt="right-arrow" src={RightArrowIcon} />
      </span>
    </div>
  </div>
);

export default CalendarPageHeader;
