import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import useLocalStorageState from 'use-local-storage-state';
import { toast } from 'react-toastify';
import { bookingSlice, BookingStep } from 'redux/slices';
import { ServiceTypeEnum } from 'data/enums/ServiceType.enum';
import {
  BookingContainer,
  BookingSummaryInfo,
  BookingSessionTimer,
  CardPaymentContainer,
} from './components';
import { Grid } from '../../../../components/Grid';
import { useService, useSummary, useBooking } from './hooks';
import { useOrgId, useUserId } from '../../../../common/hooks';
import {
  CreateBookingSessionModel,
  BookingSessionServiceModel,
  BookingSessionData,
} from '../../../../api/models/booking/CreateBookingSession.model';
import type { RootState } from '../../../../redux/store';
import { Api } from '../../../../api/Api';
import { Session } from '../../models/booking.model';
import Logger from '../../../../middleware/logger.middleware';
import StaticDictionary from '../../../../common/utils/staticDictionary.helper';
import { AxiosErrorHandler } from '../../../../common/utils/errorHandler.helpers';
import { CreatePaymentIntentRequest } from '../../../../api/models/payments/intent/createPaymentIntentRequest';

export const BookingPayment = () => {
  const { orgId } = useOrgId();
  const { userId } = useUserId();
  const { handleResetBooking } = useBooking();
  const booking = useSelector((state: RootState) => state.booking);
  const application = useSelector((state: RootState) => state.application);
  const dispatch = useDispatch();

  const { getServiceDetails, getCoachIds, getLocationIds } = useService();
  const { isPaymentRequired } = useSummary();
  const { checkoutSummary, paymentMethod, paymentPreference } = booking.selected;
  const currencyIsoCode = application.active.orgDetails?.currency_type;
  const isLessonInvite = !!booking.selected.lessonInviteId;

  const [isCreatingBookingSession, setIsCreatingBookingSession] = React.useState(true);
  const [isDeletingBookingSession, setIsDeletingBookingSession] = React.useState(false);
  const [isCreatingPaymentIntent, setIsCreatingPaymentIntent] = React.useState(false);

  const [bookingSession, setBookingSession, bookingSessionOptions] =
    useLocalStorageState<Session>('bookingSession');

  const isPaymentNeeded =
    booking.selected.checkoutSummary?.totalInCents &&
    booking.selected.checkoutSummary?.totalInCents > 0;

  const createIndividualBookingSessionService = (
    serviceId: string,
  ): BookingSessionServiceModel | null => {
    const serviceDetails = getServiceDetails(serviceId);

    if (serviceDetails) {
      /**
        isUpfrontPayment is only used to check whether the user already paid or not and it's not to be confused with the is_upfront_payment from lessons schema
        TODO: update isUpfrontPayment to a better name 
      */
      const isPrepaidAndUpfrontPayment =
        serviceDetails.serviceTypeEnum === ServiceTypeEnum.Package &&
        serviceDetails.isUpfrontPayment;

      let scheduledDates: BookingSessionServiceModel['scheduledDates'] = [];

      if (isPrepaidAndUpfrontPayment) {
        scheduledDates = [{ dateStart: 0, dateEnd: 0 }];
      } else {
        const curService = booking.selected.services.find((service) => service.id === serviceId);
        if (curService) {
          scheduledDates = curService.scheduledDates;
        }
      }

      return {
        serviceId,
        scheduledDates,
      };
    }

    return null;
  };

  const getSelectedServicesWithDetails = () => {
    return booking.selected.services.map((service) =>
      getServiceDetails(service.id),
    ) as BookingSessionData['selectedServices'];
  };

  const getSelectedCoachId = (): BookingSessionData['selectedCoachIconAndText'] => {
    const coach = booking.data.coaches.find(
      (coachItem) => coachItem.id === booking.selected.coachId,
    );
    return {
      id: booking.selected.coachId as string,
      text: coach?.imageAndText.text as string,
    };
  };

  const getSelectedLocation = (): BookingSessionData['selectedLocationIconAndText'] => {
    const location = booking.data.locations.find(
      (locationItem) => locationItem.id === booking.selected.locationId,
    );
    return {
      id: booking.selected.locationId as string,
      text: location?.imageAndText.text as string,
    };
  };

  const createBookingSession = async () => {
    /** Do not create another session if previous session is not expired yet */
    if (
      isPaymentNeeded &&
      bookingSession &&
      bookingSession.id &&
      bookingSession.expires &&
      bookingSession.expires > DateTime.now().toMillis()
    ) {
      if (Logger.isDevEnvironment) {
        console.log(
          'createBookingSession: previous session is still valid, so we will not create another booking session',
        );
      }

      setIsCreatingBookingSession(false);
      return;
    }

    const bookingSessionData: BookingSessionData = {
      bookingStepValue: BookingStep.Pay,
      selectedServices: getSelectedServicesWithDetails(),
      selectedCoachIconAndText: getSelectedCoachId(),
      selectedLocationIconAndText: getSelectedLocation(),
      promoCode: booking.selected.promoCode ?? undefined,
      checkoutSummary: booking.selected.checkoutSummary ?? undefined,
      isLessonInvite,
      isServiceInvite: undefined,
    };

    const bookingSessionServices = booking.selected.services
      .map((service) => createIndividualBookingSessionService(service.id))
      .filter((service) => service !== null) as BookingSessionServiceModel[];

    const paymentIntentId = uuidv4();
    const createBookingSessionModel: CreateBookingSessionModel = {
      paymentIntentId,
      bookingSessionServices,
      bookingJsonData: JSON.stringify(bookingSessionData),
    };

    setIsCreatingBookingSession(true);

    try {
      const bookingSessionExpiresAt = await Api.ClientRoutes.Booking.createBookingSessionAsync(
        createBookingSessionModel,
      );

      setBookingSession({
        id: paymentIntentId,
        expires: bookingSessionExpiresAt,
      });

      /**
       * TODO: clean this once refactoring is complete.
       * THESE ARE NEEDED FOR TESTING PURPOSES DURING DEVELOPMENT.
       * Once the refactor is done, I will clean this block
       */
      // setBookingSession({
      //   id: paymentIntentId,
      //   expires: DateTime.now().plus({ seconds: 30 }).toMillis(),
      // });
    } catch (e) {
      console.log('Error in creating booking session');
    }

    setIsCreatingBookingSession(false);
  };

  const handleConfirmAndPay = async () => {
    setIsCreatingPaymentIntent(true);

    try {
      if (Logger.isDevEnvironment) {
        console.log('handleConfirmAndPay');
      }

      if (!paymentMethod || !checkoutSummary || !currencyIsoCode) {
        if (!checkoutSummary) {
          toast.error('Cannot find checkout summary details');
        }

        if (!paymentMethod) {
          toast.error('Cannot find payment method');
        }

        if (!currencyIsoCode) {
          toast.error('Cannot find default currency iso code');
        }

        setTimeout(() => {
          handleResetBooking(true);
          setIsCreatingPaymentIntent(false);
        }, 5000);
        return;
      }

      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      if (!url.searchParams.get(StaticDictionary.UrlParameters.Payments.CurrencyUrlParam)) {
        url.searchParams.append(
          StaticDictionary.UrlParameters.Payments.CurrencyUrlParam,
          currencyIsoCode,
        );
      }

      const coachIds = getCoachIds();
      const locationIds = getLocationIds();

      const bookingPaymentIntent: CreatePaymentIntentRequest = {
        orgId,
        currency: currencyIsoCode.toLowerCase(),
        amountInCents: checkoutSummary.totalInCents,
        subTotalInCents: checkoutSummary.subTotalInCents,
        feeInCents: checkoutSummary.bookingFeeInCents,
        userId,
        selectedCoachId: JSON.stringify(coachIds),
        selectedLocationId: JSON.stringify(locationIds),
        selectedServicesIds: booking.selected.services.map((service) => service.id),
        paymentMethodId: paymentMethod.id,
        returnUrl: url.toString(),
      };

      const paymentIntentCreatedModel = await Api.ClientRoutes.Payments.createPaymentIntent(
        bookingPaymentIntent,
      );

      const afterPaymentUrl = url;
      afterPaymentUrl.searchParams.append(
        StaticDictionary.UrlParameters.Payments.PaymentAuthentication,
        'completed',
      );

      /**
       * We then need to confirm the Payment Intent. This may return a nextAction in the response such as 3D secure.
       */
      const confirmPaymentIntentResponse = await Api.ClientRoutes.Payments.confirmPaymentIntent(
        paymentIntentCreatedModel.paymentIntentId,
        {
          returnUrl: afterPaymentUrl.toString(),
        },
      );

      if (confirmPaymentIntentResponse.next_action) {
        /**
         * NOTE:
         * If nextAction is specified by the backend, let's go to 3D Secure
         */

        const { next_action: nextAction } = confirmPaymentIntentResponse;

        if (
          nextAction.redirect_to_url &&
          nextAction.redirect_to_url.url &&
          nextAction.redirect_to_url.return_url
        ) {
          window.location.href = nextAction.redirect_to_url.url;
        }
      } else {
        /**
         * NOTE:
         * If nextAction not specified, we then try to con
         */

        const newUrl = url.toString();
        window.history.pushState({ path: newUrl }, '', newUrl);
        dispatch(bookingSlice.actions.changeStep(BookingStep.ProcessBooking));
      }
    } catch (err: any) {
      // const error = {
      //   response: err.response,
      //   request: err.request,
      //   message: err.message,
      // };

      // const compiledErrorMessage = AxiosErrorHandler.getErrorMessage(error);
      toast.error(
        'We couldn’t validate this card. Please check the number, expiry and security code, or try another card.',
      );

      dispatch(bookingSlice.actions.updatePaymentMethod(null));
    }

    setIsCreatingPaymentIntent(false);
  };

  React.useEffect(() => {
    createBookingSession();
  }, []);

  const isContinueBtnDisabled = !booking.selected.paymentMethod || isCreatingPaymentIntent;

  return (
    <BookingContainer
      isLoading={isCreatingBookingSession || isDeletingBookingSession}
      title="Confirm and pay"
      backButton={{
        onClick: async () => {
          setIsDeletingBookingSession(true);
          if (bookingSession) {
            try {
              bookingSessionOptions.removeItem();
              await Api.ClientRoutes.Booking.deleteBookingSessionAsync(bookingSession.id);
            } catch (err: any) {
              const message = AxiosErrorHandler.getErrorMessage({
                request: err.request,
                response: err.response,
                message: err.message,
              });
              toast.error(message);
            }
          }

          dispatch(bookingSlice.actions.changeStep(BookingStep.Summary));
          setIsDeletingBookingSession(false);
        },
        disabled: isLessonInvite,
      }}
      continueButton={{
        onClick: () => handleConfirmAndPay(),
        disabled: isContinueBtnDisabled,
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          {bookingSession && bookingSession.expires && (
            <BookingSessionTimer
              expiresAtMilis={bookingSession && bookingSession.expires ? bookingSession.expires : 0}
              onExpire={() => handleResetBooking(true)}
            />
          )}
        </Grid>
        <Grid item md={12}>
          <BookingSummaryInfo>
            {paymentPreference === 'in_app' && isPaymentRequired() && (
              <CardPaymentContainer
                paymentMethod={booking.selected.paymentMethod}
                onSuccess={(newPaymentMethod) => {
                  dispatch(bookingSlice.actions.updatePaymentMethod(newPaymentMethod));
                }}
              />
            )}
          </BookingSummaryInfo>
        </Grid>
      </Grid>
    </BookingContainer>
  );
};
