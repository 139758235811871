/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { orderBy } from 'lodash';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import UserService from 'data/services/user.service';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import { Address } from 'data/entities/userContacts.entity';
import { getUnixMilliseconds } from 'common/utils/date.helpers';
import ClientConfidentialNotesContainer from 'modules/lesson/containers/ClientConfidentialNotes/ClientConfidentialNotesContainer';
import LessonLocation from 'modules/lesson/components/LessonLocation/LessonLocation';
import { UserSubscriptionsModel } from 'data/models/userSubscription.mode';
import { CoachUploads } from 'modules/attachment/models/attachment';
import { FavoriteModel } from 'data/entities/favorites.entity';
import FavouritesService from 'data/services/favourites.service';
import { AttachmentModel } from '../../../../data/entities/attachment.entity';
import styles from './LessonPage.module.scss';
import LessonHeader from '../../components/LessonHeader/LessonHeader';
import { Lesson, LessonClientStatus, Note } from '../../../../data/entities/lessons.entity';
import LessonDateBegin from '../../components/LessonDateBegin/LessonDateBegin';
import EditLesson from '../../components/EditLesson/EditLesson';
import LessonAttachmentsContainer from '../../../attachment/containers/LessonAttachmentsContainer/LessonAttachmentsContainer';
import ClientNotesContainer from '../../containers/ClientNotes/ClientNotesContainer';
import { generateUploadAttachment } from '../../../attachment/utils/attachment.helpers';
import { LessonCategory } from '../../../../data/entities/orgLessonCategories.entity';
import SessionProvider from '../../../../providers/SessionProvider';
import { ExerciseModel } from '../../../../data/entities/exercises.entity';
import { Config } from '../../../../config';
import { OrgBookingRules } from '../../../book/models/booking.model';
import { Api } from '../../../../api/Api';
import Spinnerv2 from '../../../../components/Spinnerv2/Spinnerv2';
import { AxiosErrorHandler } from '../../../../common/utils/errorHandler.helpers';
import LessonHelper from '../../../../common/utils/lessons.helper';
import OrganizationHelper from '../../../../common/utils/organization.helper';
import Logger from '../../../../middleware/logger.middleware';
import { StaticRoutes } from '../../../../common/utils/routes/StaticRoutes.helper';
import Button from '../../../../components/Button/Button';
import { getBaseOrgRoute } from '../../../../common/utils/common.helpers';
import AttachmentsService from '../../../../data/services/attachments.service';
import LessonsService from '../../../../data/services/lessons.service';
import OrganizationService from '../../../../data/services/organization.service';

interface LessonPageProps {
  subscriptionInfo?: UserSubscriptionsModel;
}

const LessonPage = ({ subscriptionInfo }: LessonPageProps) => {
  const [isFetchingLesson, setIsFetchingLesson] = useState<boolean>(true);
  const [lesson, setLesson] = useState<Lesson>();
  const [attachments, setAttachments] = useState<any[]>([]);
  const [categories, setCategories] = useState<LessonCategory[]>([]);
  const [lessonCategories, setLessonCategories] = useState([]);
  const [lessonExercises, setLessonExercises] = useState<ExerciseModel[]>([]);
  const [visibleEditLessonModal, setVisibleEditLessonModal] = useState(false);
  const { lessonId } = useParams<Record<string, string | undefined>>();
  const { orgId } = useParams<Record<string, string>>();
  const [orgBookingRules, setOrgBookingRules] = useState<OrgBookingRules>(
    new OrgBookingRules(orgId),
  );
  const [isInvalidLesson, setIsInvalidLesson] = useState(false);
  const [isCancellingLesson, setIsCancellingLesson] = useState<boolean>(false);
  const [isCancellingPackage, setIsCancellingPackage] = useState<boolean>(false);
  const [location, setLocation] = useState<Address>({});
  const [locationName, setLocationName] = useState('');
  const [sectionFileIndex, setSectionFileIndex] = useState<number>(0);
  const [isUpdating, setIsUpdating] = useState(false);

  const currentOrgId = orgId ?? '';
  const attachmentsFromState = useRef<any>();
  const currentUserId = SessionProvider.getUserId();
  const history = useHistory();
  const userId = SessionProvider.getUserId();
  const email = SessionProvider.getEmail();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingLesson(true);
        const lessonObject = await LessonsService.getAcceptedLessonByIdByUserIdOrEmail(
          lessonId as string,
          userId,
          email,
        );
        if (!lessonObject) {
          setIsInvalidLesson(true);
          return;
        }

        const userPracticeLocations = await UserService.getPracticeLocations({ userId, orgId });
        const locationIndex = userPracticeLocations.findIndex(
          (locationItem: UserPracticeLocation) =>
            locationItem.location_id === lessonObject?.location_id,
        );

        if (locationIndex >= 0) {
          setLocation(userPracticeLocations[locationIndex].address);
          setLocationName(userPracticeLocations[locationIndex].name);
        }

        await OrganizationHelper.GetOrgBookingRules(orgId, setOrgBookingRules);

        const lessonAttachments = await AttachmentsService.getAllUserUploadsForLesson(
          lessonId as string,
          currentUserId,
          currentOrgId,
        );

        setAttachments(lessonAttachments ?? []);
        attachmentsFromState.current = lessonAttachments;

        const lessonObj = lessonObject;
        setLesson(lessonObj);

        const category = await OrganizationService.getOrgLessonCategoriesById(currentOrgId);
        setCategories(category);
        if (category) {
          const filteredLessonCategories = category.filter((item: LessonCategory) =>
            lessonObj?.org_lesson_category_ids?.includes(item._id),
          );
          setLessonCategories(filteredLessonCategories);
        }

        const lessonExercisesObj = await LessonsService.getExercisesByLessonId(lessonId as string);

        setLessonExercises(lessonExercisesObj);
      } catch (err: any) {
        /* empty */
      } finally {
        setIsFetchingLesson(false);
      }
    };

    if (lessonId) fetchData();
  }, [lessonId]);

  const onUpdateLesson = async (title: string, categoriesIds: string[]) => {
    await LessonsService.updateLessonTitleAndCategories(lessonId as string, title, categoriesIds);
    setLesson({ ...lesson, org_lesson_category_ids: categoriesIds, title } as Lesson);
    setLessonCategories(
      (categories as never[]).filter((category: LessonCategory) =>
        categoriesIds?.includes(category._id),
      ),
    );
    setVisibleEditLessonModal(false);
  };

  const onUpdatePracticeLesson = async (
    lessonID: string,
    lessonTitle: string,
    note: string,
    startDate: number,
    endDate: number,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCategories: string[],
    isShareCoach: boolean,
  ) => {
    setIsUpdating(true);
    const noteList: Note[] = [
      {
        _id: uuidv4(),
        message: note,
        deleted: false,
      },
    ];
    setLesson({
      ...lesson,
      title: lessonTitle,
      org_lesson_category_ids: selectedCategories,
      date_start: startDate,
      date_end: endDate,
      location_id: chosenLocationId,
      contact_ids: selectedContactList,
      share_practice_lesson_with_coach: isShareCoach,
      date_updated: getUnixMilliseconds(),
      notes: noteList,
    } as Lesson);
    await LessonsService.updatePracticeLesson(
      lessonID,
      lessonTitle,
      note,
      startDate,
      endDate,
      chosenLocationId,
      selectedContactList,
      selectedCategories,
      isShareCoach,
    );
    setIsUpdating(false);
    setVisibleEditLessonModal(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const cancelLesson = async (lessonId: string, reason?: string) => {
    try {
      setIsCancellingLesson(true);
      await Api.ClientRoutes.Lessons.cancelLesson(lesson!._id, {
        userId: currentUserId,
        reason,
      });

      toast.success(
        LessonHelper.LessonCancelledToastMessage(
          lesson!,
          orgBookingRules.cancellationPeriodInMinutes,
        ),
      );
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.info(compiledErrorMessage);
    } finally {
      // We then update lesson here
      const lessonClient = lesson!.clients.find((lessonClientSearch) => {
        return lessonClientSearch.user_id === currentUserId;
      });
      if (lessonClient) {
        lessonClient.status = LessonClientStatus.Cancelled;
      }
      setLesson(lesson);
      setIsCancellingLesson(false);
    }
  };

  const onCancelPackage = async (packageId: string, reason?: string) => {
    try {
      setIsCancellingPackage(true);

      await Api.ClientRoutes.Lessons.cancelPackage(lesson!.package_id!, {
        userId: currentUserId,
        reason,
      });

      toast.success(LessonHelper.PackageCancelledToastMessage());
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.info(compiledErrorMessage);
    } finally {
      // We then update lesson here
      const lessonClient = lesson!.clients.find((lessonClientSearch) => {
        return lessonClientSearch.user_id === currentUserId;
      });
      if (lessonClient) {
        lessonClient.status = LessonClientStatus.Cancelled;
      }
      setLesson(lesson);
      setIsCancellingPackage(false);
    }
  };

  const onUploadFiles = useCallback(
    async (files: FileList) => {
      const uploadFiles = Array.from(files).map((file) => ({
        attachment: {
          ...generateUploadAttachment(file, currentOrgId, currentUserId),
          uploading: true,
          percent: 0,
          sectionIndex: sectionFileIndex,
        },
        file,
      }));

      attachmentsFromState.current = [
        ...attachmentsFromState.current,
        ...uploadFiles.map(({ attachment }) => attachment),
      ];
      setAttachments(attachmentsFromState.current);

      uploadFiles.map(async ({ attachment, file }) => {
        const component = 'Lesson';
        const onUploading = (id: string, percent: number) => {
          attachmentsFromState.current = attachmentsFromState.current.map(
            (attachmentFromState: any) =>
              attachmentFromState?.fileObject?._id === id
                ? { ...attachmentFromState, uploading: true, percent }
                : attachmentFromState,
          );

          setAttachments(() => {
            return attachmentsFromState.current;
          });
        };
        const onUploaded = async (id: string, url: string, cognitoId: string, type: string) => {
          attachmentsFromState.current = attachmentsFromState.current.map(
            (attachmentFromState: any) =>
              attachmentFromState?.fileObject?._id === id
                ? { ...attachmentFromState, uploading: false }
                : attachmentFromState,
          );

          setAttachments(() => {
            return attachmentsFromState.current;
          });

          const fileUrl = `${Config().AWSS3FilesUrl}private/${cognitoId}/${url}`;
          const fileName = `private/${cognitoId}/${url}`;
          if (Logger.isDevEnvironment) {
            console.log(fileName, 'fileName');
          }

          let thumbnail = '';
          if (type === 'video') {
            const uploadThumbnail = async (thumb: string) => {
              const typeString = 'image/jpeg';
              const blob = await fetch(thumb).then((res) => res.blob());
              const thumbnailId = uuidv4();
              const onThumbnailUploaded = async (
                thumbId: string,
                ThumbnailUrl: string,
                thumbCognitoId: string,
              ) => {
                const thumbnailUrl = `private/${thumbCognitoId}/${ThumbnailUrl}`;
                thumbnail = thumbnailUrl;

                attachmentsFromState.current = attachmentsFromState.current.map(
                  (attachmentFromState: any) =>
                    attachmentFromState?.fileObject?._id === id
                      ? {
                          ...attachmentFromState,
                          fileObject: {
                            ...attachmentFromState.fileObject,
                            file_url: fileUrl,
                            file_name: fileName,
                            thumbnail_url: thumbnail,
                          },
                        }
                      : attachmentFromState,
                );

                setAttachments(attachmentsFromState.current);
                let attachmentResult: AttachmentModel | undefined;
                attachmentsFromState.current.map(async (attachmentFromState: any) => {
                  if (attachmentFromState?.fileObject?._id === id) {
                    try {
                      attachmentResult = await AttachmentsService.addFileAttachmentForLesson(
                        attachmentFromState.fileObject,
                        lesson?._id as string,
                        sectionFileIndex,
                      );
                      if (attachmentResult) {
                        attachmentsFromState.current = attachmentsFromState.current.map(
                          (currentAttachmentFromState: any) =>
                            currentAttachmentFromState?.fileObject?._id === id
                              ? attachmentResult
                              : currentAttachmentFromState,
                        );

                        setAttachments(() => {
                          return attachmentsFromState.current;
                        });
                      }
                    } catch (error: any) {
                      toast.error(error.message);
                    }
                  }
                });
              };
              await AttachmentsService.uploadFileAttachment(
                thumbnailId,
                currentUserId,
                currentOrgId,
                component,
                blob,
                onThumbnailUploaded,
                onUploading,
                typeString,
              );
            };
            generateVideoThumbnails(file, 0, type).then((result) => {
              uploadThumbnail(result[0]);
            });
          } else {
            attachmentsFromState.current = attachmentsFromState.current.map(
              (attachmentFromState: any) =>
                attachmentFromState?.fileObject?._id === id
                  ? {
                      ...attachmentFromState,
                      fileObject: {
                        ...attachmentFromState.fileObject,
                        file_url: fileUrl,
                        file_name: fileName,
                        thumbnail_url: thumbnail,
                      },
                    }
                  : attachmentFromState,
            );

            setAttachments(attachmentsFromState.current);
            let attachmentResult: AttachmentModel | undefined;
            attachmentsFromState.current.map(async (attachmentFromState: any) => {
              if (attachmentFromState?.fileObject?._id === id) {
                try {
                  attachmentResult = await AttachmentsService.addFileAttachmentForLesson(
                    attachmentFromState.fileObject,
                    lesson?._id as string,
                    sectionFileIndex,
                  );
                  if (attachmentResult) {
                    attachmentsFromState.current = attachmentsFromState.current.map(
                      (currentAttachmentFromState: any) =>
                        currentAttachmentFromState?.fileObject?._id === id
                          ? attachmentResult
                          : currentAttachmentFromState,
                    );

                    setAttachments(() => {
                      return attachmentsFromState.current;
                    });
                  }
                } catch (error: any) {
                  toast.error(error.message);
                }
              }
            });
          }
        };

        try {
          await AttachmentsService.uploadFileAttachment(
            attachment.fileObject._id,
            currentUserId,
            currentOrgId,
            component,
            file,
            onUploaded,
            onUploading,
            '',
          );
        } catch (error: any) {
          toast.error(error.message);
        }
      });
    },
    [currentOrgId, currentUserId, lesson?._id, sectionFileIndex],
  );

  const onAddTextNoteAttachment = useCallback(
    async (note: string) => {
      try {
        const noteAttachment = await AttachmentsService.addNoteAttachmentToLesson(
          lesson?._id as string,
          currentUserId,
          orgId as string,
          note,
          sectionFileIndex,
        );
        if (noteAttachment) {
          attachmentsFromState.current = [...attachmentsFromState.current, noteAttachment];
          setAttachments(() => {
            return [...attachments, noteAttachment];
          });
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    [attachments, currentUserId, lesson?._id, orgId, sectionFileIndex],
  );

  const onAddExercises = useCallback(
    async (newUploads: ExerciseModel[] | FavoriteModel[]) => {
      try {
        if (sectionFileIndex === CoachUploads) {
          const exerciseIds = (newUploads as ExerciseModel[]).map(
            (exercise: ExerciseModel) => exercise.exerciseObj._id,
          );

          await AttachmentsService.updateAttachments(lessonId as string, exerciseIds, CoachUploads);

          const newExercises = await LessonsService.getExercisesByIds(exerciseIds);
          setLessonExercises(newExercises);
        } else {
          let attachmentsIds: string[] = (newUploads as FavoriteModel[]).map(
            (favorite: FavoriteModel) => (favorite.attachment ? favorite.attachment._id : ''),
          );

          await AttachmentsService.updateAttachments(
            lessonId as string,
            attachmentsIds,
            sectionFileIndex,
          );

          attachmentsIds = attachmentsIds.filter((id: string) => {
            const sameAttachments = attachments.filter(
              (attach) =>
                attach && attach.attachmentId === id && attach.sectionIndex === sectionFileIndex,
            );
            return sameAttachments.length === 0;
          });

          const favorites = await FavouritesService.getAllUserFavouritesByOrg(
            currentUserId,
            currentOrgId,
          );

          const updatedAttachments = await AttachmentsService.getUploadsForLessons(
            attachmentsIds,
            favorites ?? [],
            lesson?._id as string,
            sectionFileIndex,
          );

          const updatedCurrentAttachments = [...attachments, ...updatedAttachments];

          setAttachments(updatedCurrentAttachments);
          attachmentsFromState.current = updatedCurrentAttachments;
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    [attachments, currentUserId, lesson?._id, sectionFileIndex],
  );

  const deleteAttachment = useCallback(
    async (attachment: any) => {
      try {
        await AttachmentsService.removeLessonUploads(
          lesson?._id as string,
          attachment.attachmentId,
          attachment.sectionIndex,
        );

        const updatedAttachments = attachments.filter(
          (attachmentObj: any) =>
            attachmentObj.attachmentId !== attachment.attachmentId ||
            attachmentObj.sectionIndex !== attachment.sectionIndex,
        );
        setAttachments(updatedAttachments);
        attachmentsFromState.current = updatedAttachments;
      } catch (error: any) {
        toast.error(error.message);
      }
    },
    [lesson?._id, attachments],
  );

  const isLessonCancelled = lesson ? LessonHelper.isLessonCancelled(lesson) : true;

  const onNotesCreated = async (noteList: Lesson['client_notes']) => {
    setLesson({ ...lesson, client_notes: noteList } as Lesson);
  };

  const onSelfNotesCreated = async (noteList: Lesson['client_confidential_notes']) => {
    setLesson({ ...lesson, client_confidential_notes: noteList } as Lesson);
  };

  if (isCancellingLesson) {
    return <Spinnerv2 message="Canceling lesson..." />;
  }

  if (isCancellingPackage) {
    return <Spinnerv2 message="Canceling package..." />;
  }

  if (isFetchingLesson) {
    return <Spinnerv2 message="Fetching lesson..." />;
  }

  if (isInvalidLesson) {
    return (
      <div
        style={{ width: 'fit-content' }}
        className="d-flex flex-column justify-content-center m-auto mt-5"
      >
        <h6 className="mb-3 text-center">
          The lesson does not exist or you do not belong to it. View all your lessons below
        </h6>
        <div>
          <Button
            label="Lessons"
            onClick={() => {
              const lessonsRoute = getBaseOrgRoute(window.location.pathname) + StaticRoutes.Lessons;
              history.push(`${lessonsRoute}`);
            }}
            className="m-auto"
          />
        </div>
      </div>
    );
  }

  return lesson ? (
    <div className={styles.pageWrapper}>
      <EditLesson
        visible={visibleEditLessonModal}
        lesson={lesson}
        categories={categories}
        onClose={() => setVisibleEditLessonModal(false)}
        onUpdateLesson={onUpdateLesson}
        onUpdatePracticeLesson={onUpdatePracticeLesson}
        isUpdating={isUpdating}
      />
      <div className={styles.content}>
        <LessonHeader
          lesson={lesson}
          categories={lessonCategories}
          onOpenEditModal={() => setVisibleEditLessonModal(true)}
          location={location}
          locationName={locationName}
          cancelLesson={cancelLesson}
          onCancelPackage={onCancelPackage}
          orgBookingRules={orgBookingRules}
        />
        <div className={styles.visibleDesktop}>
          <LessonAttachmentsContainer
            setAttachments={setAttachments}
            sectionFileIndex={sectionFileIndex}
            setSectionFileIndex={setSectionFileIndex}
            attachments={attachments}
            categories={categories}
            onUploadFiles={onUploadFiles}
            onAddTextNoteAttachment={onAddTextNoteAttachment}
            onAddExercises={onAddExercises}
            deleteAttachment={deleteAttachment}
            exercises={lessonExercises}
            lesson={lesson}
            subscriptionInfo={subscriptionInfo}
          />
        </div>
      </div>
      <div className={styles.additionalContent}>
        <div className={styles.additionalContentBlock}>
          <div className={styles.visibleNonMobile}>
            {lesson?.date_start && (
              <LessonDateBegin
                lesson={lesson}
                cancelLesson={cancelLesson}
                onCancelPackage={onCancelPackage}
                orgBookingRules={orgBookingRules}
              />
            )}
            <LessonLocation location={location} locationName={locationName} lesson={lesson} />
          </div>
          <ClientNotesContainer
            lessonId={lesson._id}
            isLessonCancelled={isLessonCancelled}
            onNotesCreated={onNotesCreated}
            clientNotes={orderBy(
              [...(lesson.client_notes || [])],
              (clientNote) => {
                return clientNote.date_created;
              },
              'desc',
            )}
          />
          <ClientConfidentialNotesContainer
            lessonId={lesson._id}
            isLessonCancelled={isLessonCancelled}
            onSelfNotesCreated={onSelfNotesCreated}
            clientNotes={orderBy(
              [...(lesson.client_confidential_notes || [])],
              (clientNote) => {
                return clientNote.date_created;
              },
              'desc',
            )}
          />
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
};

export default LessonPage;
