import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Contact } from 'data/entities/organization.entity';
import { AuthenticatedUser, authenticationSlice } from 'redux/slices';
import { redirectToOrgInvitation } from '../../../../common/utils/redirectOrgInvite';
import logo from '../../../../assets/images/auth/signup-image.png';
import AuthMenu from '../../components/AuthMenu/AuthMenu';
import { Api } from '../../../../api/Api';
import SessionProvider from '../../../../providers/SessionProvider';
import styles from './SignupPage.module.scss';
import { AxiosErrorHandler } from '../../../../common/utils/errorHandler.helpers';
import { InviteSignup } from '../../models/inviteSignup.model';
import Logger from '../../../../middleware/logger.middleware';
import { SignUpModel } from '../../../../api/models/auth/signup.model';
import UserService from '../../../../data/services/user.service';
import { OrgInvitationModel } from '../../../../data/models/orgInvitation.model';
import { UserType } from '../../../../data/enums/UserType.enum';
import { getSearchParamsExcluding } from '../../../../common/utils/common.helpers';
import { useAuth } from '../../../../common/hooks/useAuth';
import ServicesService from '../../../../data/services/services.service';
import { useSignUp } from './useSignUp';
import { SignUp } from './SignUp';
import { capitalize } from '../../../../common/extensions/stringExtensions';
import { Config } from '../../../../config';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props extends RouteComponentProps {}

const SignUpPage: React.FC<Props> = (props) => {
  const authHook = useAuth();
  const dispatch = useDispatch();
  const queryParams = authHook.params;

  const [isOrgInvite, setIsOrgInvite] = useState<boolean>(false);
  const [serviceClientInviteId, setServiceClientInviteId] = useState<string>('');
  const [isServiceClientInvite, setIsServiceClientInvite] = useState<boolean>(false);
  const [isInvite, setIsInvite] = useState<boolean>(false);

  const {
    firstName,
    lastName,
    email,
    confirmEmail,
    password,
    contact,
    checkboxes,
    under18Info,
    errorMessageEvent,
    onEmailChanged,
    onConfirmEmailChange,
    onFirstNameChanged,
    onLastNameChanged,
    onPasswordChanged,
    onConfirmPasswordChange,
    onChangePhoneNumber,
    UpdateErrorMessageEvent,
    setEmail,
    setFirstName,
    setLastName,
    setCheckBoxes,
    onChangeDateOfBirth,
    onChangeAllergies,
    onChangeMedication,
    onChangeEmergencyContactFirstName,
    onChangeEmergencyContactLastName,
    onChangeEmergencyContactRelationship,
    onChangeEmergencyContactPhone,
    isSignUpFieldsComplete,
  } = useSignUp(isInvite);

  const [isLoading, setIsLoading] = useState(false);
  const [cookies] = useCookies(['orgInvitation']);
  const { history, location } = props;
  const loginLink = `/login${getSearchParamsExcluding(['org_id', 'org_client_invite_id'])}`;

  const isRecovery = useMemo(() => {
    return new URLSearchParams(location.search).toString();
  }, [location.search]);

  useEffect(() => {
    const serviceInviteId = queryParams.get('service_client_invite_id');
    const isServiceInvite = serviceInviteId !== null && serviceInviteId !== '';
    const emailParam = (queryParams.get('email') ?? '').toLowerCase();

    setServiceClientInviteId(serviceInviteId || '');
    setIsServiceClientInvite(isServiceInvite);
    setIsInvite(emailParam !== '');

    if (isRecovery) {
      const signupData: InviteSignup = {
        email: (queryParams.get('email') ?? '').toLowerCase(),
        firstName: capitalize(queryParams.get('first_name') ?? ''),
        lastName: capitalize(queryParams.get('last_name') ?? ''),
      };

      if (Logger.isDevEnvironment) {
        console.log('signupData: ', signupData);
      }

      if (!signupData || !signupData.email) {
        // no signup data found, return
        if (Logger.isDevEnvironment) {
          console.log('no signup data found');
        }
        return;
      }

      if (signupData.email) {
        setIsOrgInvite(true);
        setEmail(signupData.email);
      }

      if (signupData.firstName) {
        setFirstName(signupData.firstName ?? '');
      }

      if (signupData.lastName) {
        setLastName(signupData.lastName ?? '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecovery, location.search]);

  const getOpenIDToken = async (userAccessToken: string, id: string) => {
    try {
      const response = await fetch(`${Config().BaseCognitoOpenIdURL}/cognito/getOpenIDToken`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessToken: userAccessToken,
          userId: id,
        }),
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error getting open ID token: ', error);
      return null;
    }
  };

  const signInUser = async () => {
    try {
      const loginResult = await UserService.userLogin(email, password);

      if (loginResult !== null) {
        const { accessToken, refreshToken, userId, firstName: firstUserName } = loginResult;

        if (accessToken && userId) {
          /**
           * Note:
           * This is a temporary fix, it should be done on the backend endpoint during the signup process.
           *
           */
          const fetchOpenIDTokenResult = await getOpenIDToken(accessToken, userId);
          await UserService.insertUserCognitoId(userId, fetchOpenIDTokenResult.data.IdentityId);
        }

        SessionProvider.updateSession(
          accessToken as string,
          refreshToken as string,
          userId as string,
          email,
          firstUserName,
          checkboxes.rememberMe,
        );

        const authenticatedUser: AuthenticatedUser = {
          accessToken: accessToken as string,
          refreshToken: refreshToken as string,
          userId,
          firstName,
          lastName,
        };

        /** First step to decouple logic by using redux state */
        dispatch(authenticationSlice.actions.updateIsAuthenticated(true));
        dispatch(authenticationSlice.actions.updateAuthenticatedUser(authenticatedUser));
      }
      const orgInvitationModel: OrgInvitationModel | undefined = cookies.orgInvitation;
      if (orgInvitationModel) {
        if (Logger.isDevEnvironment) console.log('redirecting to org invitation');
        redirectToOrgInvitation(history, orgInvitationModel);
      } else {
        authHook.redirectAfterSignIn();
      }
    } catch (error: any) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }

      UpdateErrorMessageEvent({
        ...errorMessageEvent,
        loginError: 'Please confirm your email address first',
      });
    }
  };

  const onSignUpClicked = async () => {
    UpdateErrorMessageEvent({ ...errorMessageEvent, requestError: '' });

    const firstNameError = !firstName ? 'Please enter your first name' : undefined;
    const lastNameError = !lastName ? 'Please enter your last name' : undefined;
    const emailInputError = !email ? 'Please enter your email' : undefined;
    const passwordInputError = !password ? 'Please enter your password' : undefined;
    const termsOfServiceError = !checkboxes.termsOfService
      ? 'Please indicate that you agree to the Terms of Use and Privacy Policy'
      : undefined;
    const under18Error = undefined;
    const checkBoxesErrors = {
      termsOfService: termsOfServiceError,
      under18: under18Error,
    };
    const checkConditionError = checkBoxesErrors.termsOfService && checkBoxesErrors.under18;

    UpdateErrorMessageEvent({
      firstNameError,
      lastNameError,
      emailInputError,
      passwordInputError,
      checkBoxesErrors,
    });

    if (
      firstNameError ||
      lastNameError ||
      emailInputError ||
      passwordInputError ||
      checkConditionError
    ) {
      return;
    }

    setIsLoading(true);

    try {
      const orgId = queryParams.get('org_id');
      const signUpModel: SignUpModel = {
        firstName,
        lastName,
        email,
        password,
        isStaffInvite: false,
        orgId: orgId ?? '',
        userDetails: {
          isUnder18: checkboxes.under18,
          contact,
          dateOfBirth: under18Info?.dateOfBirth,
          allergies: under18Info?.allergies,
          medication: under18Info?.medication,
          emergencyContact1: {
            first_name: under18Info?.emergencyContact1?.firstName ?? '',
            last_name: under18Info?.emergencyContact1?.lastName ?? '',
            relationship: under18Info?.emergencyContact1?.relationship ?? '',
            contact: under18Info?.emergencyContact1?.contact as Contact,
          },
          emergencyContact2: {
            first_name: under18Info?.emergencyContact2?.firstName ?? '',
            last_name: under18Info?.emergencyContact2?.lastName ?? '',
            relationship: under18Info?.emergencyContact2?.relationship ?? '',
            contact: under18Info?.emergencyContact2?.contact as Contact,
          },
        },
      };

      const orgInvitationModel: OrgInvitationModel | undefined = cookies.orgInvitation;
      if (orgInvitationModel) {
        signUpModel.orgId = orgInvitationModel.orgId;
        signUpModel.isStaffInvite = orgInvitationModel.userType === UserType.coach;
      }

      await Api.ClientRoutes.Auth.signUp(signUpModel);

      const orgClientInviteId = queryParams.get('org_client_invite_id');
      const isOrgClientInvite = orgClientInviteId !== null && orgClientInviteId !== '';
      if (!orgId || (!isOrgClientInvite && !isServiceClientInvite)) {
        return;
      }

      let validateInviteResult;
      if (isServiceClientInvite) {
        const inviteId = serviceClientInviteId!;
        validateInviteResult = await ServicesService.validateClientInvite(orgId, inviteId, email);
      }

      if (validateInviteResult && !validateInviteResult.isSuccess) {
        if (Logger.isDevEnvironment) {
          console.warn('Could not associate user', validateInviteResult.errors);
        }

        if (validateInviteResult.errors && validateInviteResult.errors.length > 0) {
          toast.error(validateInviteResult.errors[0].value);
        }

        return;
      }
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      UpdateErrorMessageEvent({ ...errorMessageEvent, requestError: compiledErrorMessage });
    } finally {
      await signInUser();
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  return (
    <AuthMenu
      logo={logo}
      withWelcome={false}
      headerTitle="Sign up to get started"
      btnLabel="Sign up"
      loading={isLoading}
      onBtnClicked={onSignUpClicked}
      authLink={loginLink}
      authLinkHeader="Already have an account?"
      authLinkText="Sign in"
      customStyles={styles}
      isBtn
      btnDisabled={isSignUpFieldsComplete === false || isLoading}
    >
      <SignUp
        isInvite={isInvite}
        firstName={firstName}
        lastName={lastName}
        email={email}
        confirmEmail={confirmEmail}
        isEmailFieldDisabled={isOrgInvite}
        onFirstNameChanged={onFirstNameChanged}
        onLastNameChanged={onLastNameChanged}
        onEmailChanged={onEmailChanged}
        onConfirmEmailChanged={onConfirmEmailChange}
        onPasswordChanged={onPasswordChanged}
        onConfirmPasswordChanged={onConfirmPasswordChange}
        onChangePhoneNumber={onChangePhoneNumber}
        errorMessageEvent={errorMessageEvent}
        checkBoxes={checkboxes}
        setCheckBoxes={setCheckBoxes}
        UpdateErrorMessageEvent={UpdateErrorMessageEvent}
        onChangeDateOfBirth={onChangeDateOfBirth}
        onChangeAllergies={onChangeAllergies}
        onChangeMedication={onChangeMedication}
        onChangeEmergencyContactFirstName={onChangeEmergencyContactFirstName}
        onChangeEmergencyContactLastName={onChangeEmergencyContactLastName}
        onChangeEmergencyContactRelationship={onChangeEmergencyContactRelationship}
        onChangeEmergencyContactPhone={onChangeEmergencyContactPhone}
      />
    </AuthMenu>
  );
};

export default withRouter(SignUpPage);
