import { useEffect, useState } from 'react';
import { UserDetail, UserHeightWeight } from '../../../../data/entities/profile.entity';
import { User } from '../../../../data/entities/users.entity';
import {
  SportInformationModel,
  emptySportInfo,
} from '../../../../data/entities/sportInformation.entity';
import RealmRepositories from '../../../../data/base/realm.repo';
import { File } from '../../../../data/entities/file.entity';
import UserHelper from '../../../../common/utils/user.helper';
import UserService from '../../../../data/services/user.service';
import { CurrencyData, getByIsoCode } from '../../../../common/utils/currencies.helper';
import OrganizationService from '../../../../data/services/organization.service';

interface UseProfileInput {
  orgId: string;
  userId: string;
}

export interface Profile {
  user: User;
  profile: UserDetailInfoState;
  sportInfo: SportInformationModel;
  picture: File;
  currency: CurrencyData;
}

export interface Credits {
  isLoaded: boolean;
  total: number;
}

/* 
UserDetailInfoState is a converted UserDetail in 
which height and weight is converted into the following shape 
*/
export interface UserDetailInfoState extends Omit<UserDetail, 'weight' | 'height'> {
  weight?: number;
  weightUnit: string;
  height?: number;
  heightUnit: string;
}

export const useProfile = ({ orgId, userId }: UseProfileInput) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [profile, setProfile] = useState<Profile>();
  const [credits, setCredits] = useState<Credits>({
    isLoaded: false,
    total: 0,
  });

  const notifyApiError = () => setErrorMessage('Error reading from API.');

  const getMeasurementFromState = (value: number, unit: string): UserHeightWeight => {
    return {
      value,
      measurement_unit_enum: parseInt(unit, 10),
    };
  };

  const getMeasurementUnitFromDbItem = (unit: number): string => {
    return unit.toString();
  };

  const fetchProfile = async () => {
    const tmpProfle: Partial<Profile> = {};
    try {
      setIsLoading(true);

      /** Profile */
      let userDetailsResponse = await UserService.getProfileDetails(userId, orgId);

      if (
        (!userDetailsResponse && !Array.isArray(userDetailsResponse)) ||
        userDetailsResponse.length < 1
      ) {
        await UserService.createUserDetails(
          userId,
          orgId,
          {
            deleted: false,
            user_id: userId,
            org_id: orgId,
            avatar: null,
            occupation: null,
            qualifications: null,
            weight: null,
            height: null,
            gender_enum: 1,
            contact: {
              country_code: '',
              country_iso: '',
              name: '',
              phone_number: '',
            },
            contact_preference: null,
            birth_place: null,
            date_of_birth: null,
            allergies: null,
            medication: null,
            emergency_contacts: null,
          },
          {
            addressObject: null,
          },
        );

        userDetailsResponse = (await UserService.getProfileDetails(userId, orgId)) as UserDetail[];
      }

      const [userDetail] = userDetailsResponse;

      // convert profile from profile mongodb shape into state shape
      tmpProfle.profile = {
        ...userDetail,
        weight: userDetail.weight?.value,
        weightUnit: getMeasurementUnitFromDbItem(userDetail.weight?.measurement_unit_enum ?? 0),
        height: userDetail.height?.value,
        heightUnit: getMeasurementUnitFromDbItem(userDetail.height?.measurement_unit_enum ?? 0),
      };

      /** Sport Information */
      const sportInfoResponse = await UserService.getUserSportInformationAsync(userId, orgId);
      tmpProfle.sportInfo = sportInfoResponse ?? {
        ...emptySportInfo,
        user_id: userId,
        org_id: orgId,
      };

      /** Avatar */
      const userDetailAvatar = userDetail?.avatar ?? '';
      if (userDetailAvatar) {
        const getAvatarFileReponse = await RealmRepositories.Attachments.getFilesByIds([
          userDetailAvatar,
        ]);
        if (getAvatarFileReponse && getAvatarFileReponse.length > 0) {
          const [profilePicture] = getAvatarFileReponse;
          tmpProfle.picture = profilePicture;
        }
      }

      /** User */
      const userResponse = await UserService.getUserAsync(userId);
      if (!userResponse) {
        notifyApiError();
        return;
      }
      tmpProfle.user = userResponse;

      /** Currency */
      const orgDetailsResponse = await OrganizationService.getOrgByIdAsync(orgId);
      if (!orgDetailsResponse || !orgDetailsResponse.currency_type) {
        notifyApiError();
        return;
      }
      const currencyResponse = getByIsoCode(orgDetailsResponse.currency_type);
      tmpProfle.currency = currencyResponse;

      /** Set State */
      setProfile(tmpProfle as Profile);
    } catch (err) {
      notifyApiError();
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCredits = async () => {
    try {
      const creditsResponse = await UserHelper.GetCreditsAsync(userId);
      if (!creditsResponse && creditsResponse !== 0) {
        notifyApiError();
        return;
      }

      setCredits({
        isLoaded: true,
        total: creditsResponse,
      });
    } catch (err) {
      notifyApiError();
      setCredits({
        isLoaded: true,
        total: 0,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchCredits();
  }, []); // eslint-disable-line

  /** Accessible Values */
  return {
    errorMessage,
    profile,
    credits,
    isLoading,
    fetchProfile,
    fetchCredits,
    getMeasurementFromState,
    getMeasurementUnitFromDbItem,
  };
};
