import { PaymentMethod } from '@stripe/stripe-js';
import {
  SelectedService as AvailableService,
  SelectedServicePackagesBought,
} from '../../../modules/book/models/booking.model';
import {
  LocationImageAndText,
  CoachImageAndText,
} from '../../../modules/service/models/service.model';
import { CheckoutSummary } from '../../../api/models/payments/checkoutSummary.model';
import { BusyTimeItem } from '../../../api/endpoints/client/Org.endpoint';

export type CompactBusyTime = Pick<
  BusyTimeItem,
  'dateStart' | 'dateEnd' | 'isFullyBooked' | 'isPurchased' | 'serviceId'
>;

export type PaymentPreference = 'in_app' | 'outside_app';

export enum BookingStep {
  FindCoachLocation = 0,
  SelectCoach = 1,
  SelectLocation = 2,
  SelectService = 3,
  SelectTime = 4,
  Summary = 5,
  Checkout = 6,
  Pay = 7,
  ProcessBooking = 8,
  Confirmed = 9,
}

export interface ScheduledDate {
  dateEnd: number;
  dateStart: number;
  isLessonCreated?: boolean;
}

export interface SelectedService {
  id: string;
  coachId: string;
  count: number;
  isCalendarActive: boolean;
  addedAt: number;
  scheduledDates: ScheduledDate[];
  packagesBought: SelectedServicePackagesBought;
}

export interface BookingState {
  bookingStep: BookingStep;
  data: {
    locations: LocationImageAndText[];
    coaches: CoachImageAndText[];
    services: AvailableService[];
    busyTimes: CompactBusyTime[];
  };
  selected: {
    coachId: string | null;
    locationId: string | null;
    services: SelectedService[];
    positionsLeft: ServicePositionsLeft[];
    checkoutSummary: CheckoutSummary | null;
    promoCode: string | null;
    paymentMethod: PaymentMethod | null;
    paymentPreference: PaymentPreference;
    timezone: string;
    isRefund: boolean;
    invoiceId: string;
    lessonInviteId: string | null;
    serviceInviteId: string | null;
    isLessonPackTimeSlotSelection: boolean;
  };
}

export interface ServicePositionsLeft {
  id: string;
  dateStart: number;
  dateEnd: number;
  participants: number;
  positionsLeft: number;
}
