/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import * as Realm from 'realm-web';
import { Auth } from 'aws-amplify';
import SessionProvider from '../../../../providers/SessionProvider';
import { Api } from '../../../../api/Api';
import Spinnerv2 from '../../../../components/Spinnerv2/Spinnerv2';
import { Config } from '../../../../config';
import { AuthLocalStorage } from '../../../auth/utils/auth.helper';
import OrganizationService from '../../../../data/services/organization.service';
import { UserType } from '../../../../data/enums/UserType.enum';
import { OrgInvitationModel } from '../../../../data/models/orgInvitation.model';
import CenteredError from '../../../../components/CenteredError/CenteredError';

const AcceptOrgClientInvitePage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [validInvite, setValidInvite] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['orgInvitation']);

  const history = useHistory();
  const userId = SessionProvider.getUserId();
  const app = Realm.getApp(Config().RealmAppId as string);

  const validateLogicCompleted = useRef(false);

  const handleLogOut = async () => {
    if (app.currentUser) {
      await app.removeUser(app?.currentUser);
    }
    await Auth.signOut();
    if (SessionProvider.getAccessToken()) {
      SessionProvider.removeSession();
      AuthLocalStorage.removeAuthLocalStorage();
    }

    history.push('/login');
  };

  const doInviteNotValidLogic = useCallback(
    (errorMessageParam: string) => {
      setErrorMessage(errorMessageParam);
      setValidInvite(false);
      setIsLoading(false);
      removeCookie('orgInvitation', { path: '/' });
    },
    [removeCookie],
  );

  const acceptInviteAsync = useCallback(
    async (orgInvitationModelResult: OrgInvitationModel) => {
      await Api.CoachRoutes.Org.acceptClientInvite(
        orgInvitationModelResult.orgId, // These values are validated in validateAndAcceptClientInvites
        orgInvitationModelResult.inviteId,
        userId,
      );
      removeCookie('orgInvitation', { path: '/' });
      history.push(`/org/${orgInvitationModelResult.orgId}/`);
    },
    [history, removeCookie, userId],
  );

  const validateAndAcceptClientInvite = useCallback(async () => {
    try {
      const orgInvitationModelResult: OrgInvitationModel | undefined = cookies.orgInvitation;
      if (!orgInvitationModelResult) {
        doInviteNotValidLogic('There was an error fetching your invitation data.');
        return;
      }

      const validationResult = await OrganizationService.validateOrgInvite(
        UserType.client,
        orgInvitationModelResult.orgId,
        orgInvitationModelResult.inviteId,
        SessionProvider.getEmail(),
      );

      if (!validationResult?.isActiveInviteAndCorrectUser) {
        doInviteNotValidLogic(validationResult.errorMessage);
        return;
      }

      setValidInvite(true);
      await acceptInviteAsync(orgInvitationModelResult);
    } catch (error: any) {
      doInviteNotValidLogic(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [acceptInviteAsync, cookies.orgInvitation, doInviteNotValidLogic]);

  useEffect(() => {
    if (!validateLogicCompleted.current) {
      validateLogicCompleted.current = true;
      validateAndAcceptClientInvite();
    }

    return () => {
      validateLogicCompleted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Spinnerv2 message="Accepting invite..." />;
  }

  if (!validInvite) {
    return (
      <CenteredError message={errorMessage} showBtn btnLabel="Log out" onBtnClick={handleLogOut} />
    );
  }

  return <Spinnerv2 message="Accepting invite..." />;
};

export default AcceptOrgClientInvitePage;
