import { useSelector } from 'react-redux';
import type { RootState } from '../../../../../redux/store';

export const useLocation = () => {
  const booking = useSelector((state: RootState) => state.booking);
  const getLocationName = (): string => {
    const selectedLocation = booking.data.locations.find(
      (location) => location.id === booking.selected.locationId,
    );

    if (selectedLocation) return selectedLocation.imageAndText.text;
    return '';
  };

  const getLocationNameById = (id: string) => {
    const location = booking.data.locations.find((locationItem) => locationItem.id === id);
    if (location) return location.name;
    return '';
  };

  return {
    locationName: getLocationName(),
    getLocationNameById,
  };
};
