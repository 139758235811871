import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DateTime } from 'luxon';
import BookingPageV2 from 'modules/book/pages/BookingPageV2';
import { BookingStep, bookingSlice, authenticationSlice } from 'redux/slices';
import { RootState } from 'redux/store';

import { useAuth, useOrgId, useUserId } from 'common/hooks';
import { AxiosErrorHandler } from 'common/utils/errorHandler.helpers';
import ServicesService from 'data/services/services.service';
import LessonsService from 'data/services/lessons.service';
import StaticDictionary from 'common/utils/staticDictionary.helper';
import Logger from 'middleware/logger.middleware';
import {
  SelectedService as AvailableService,
  SelectedServicePackagesBought,
} from '../../models/booking.model';
import { BookingHelper } from '../../utils/booking.helpers';
import { BookingContainer } from './components';
import { useService, useBooking, useTimeSlots, useLesson } from './hooks';

const LessonInvitePage: React.FC = () => {
  const authentication = useSelector((state: RootState) => state.authentication);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const params = new URLSearchParams(window.location.search);
  const invitationIdParam = params.get(StaticDictionary.UrlParameters.Booking.InvitationId);
  const { userId } = useUserId();
  const { orgId } = useOrgId();
  const { getOrgDetails, getUpdatedServicesWithAdditionalData, getActiveSelectedService } =
    useService();
  const activeSelectedService = getActiveSelectedService();
  const { handleResetBooking } = useBooking();
  const { getLesson, validateInvite, validateLesson } = useLesson();
  const { handleFetchBusyTimesForSelectedLesson } = useTimeSlots();
  const { authorize } = useAuth();
  const dispatch = useDispatch();

  /**
   * Handle error and set the error message.
   * @param message The error message to be set.
   */
  const handleError = (message: string) => {
    setError(message);
  };

  /**
   * Updates the lesson details and dispatches the necessary actions.
   * @param {AvailableService} selectedService - The selected service details.
   * @throws Will throw an error if there is an issue fetch selected coach and if success return  coach_id
   */
  const getSelectedCoachIdFromSelectedService = async (
    selectedService: AvailableService,
  ): Promise<string> => {
    const coachResult = await BookingHelper.GetSelectedCoach(selectedService);
    if (!coachResult) {
      return '';
    }
    return coachResult.id;
  };

  /**
   * Updates the lesson details and dispatches the necessary actions.
   * @param {AvailableService} selectedService - The selected service details.
   * @throws Will throw an error if there is an issue fetch selected coach and if success return location_id
   */
  const getSelectedLocationIdFromSelectedService = async (
    selectedService: AvailableService,
  ): Promise<string> => {
    const locationResult = await BookingHelper.GetSelectedLocation(selectedService, orgId);
    if (!locationResult) {
      return '';
    }
    return locationResult.id;
  };

  /**
   * Loads the lesson details and validates the invitation.
   * First, should retreive the lesson info, then location + coaches,
   * then available services + selected services
   * @throws Will handle the error if any issue occurs while loading the lessons.
   */
  const loadLessonInvitation = async (): Promise<void> => {
    try {
      dispatch(bookingSlice.actions.resetBooking());
      await getOrgDetails(orgId);

      if (Logger.isDevEnvironment) {
        console.log('loadLessonByInvitationIdAsync()');
      }

      if (!invitationIdParam) {
        handleError('Your invitation id is not valid');
      } else {
        dispatch(bookingSlice.actions.updateIsLessonInvite(invitationIdParam));
      }

      const lesson = await getLesson();

      /** Validate Lesson and invite before processing further */
      validateLesson(lesson, handleError);
      validateInvite(lesson, handleError);

      /** Grab the selected service from the lesson */
      const selectedService = await ServicesService.getServiceForLessonInviteAsync(
        lesson.service_id,
        orgId,
        userId,
      );
      if (!selectedService) {
        throw new Error(
          'Lesson service could not be found. It has either already occurred, you were not invited or no longer exists',
        );
      }
      // Update the services in the store but return services locally in the case state does not update in time
      dispatch(bookingSlice.actions.getServices([selectedService]));
      // Fetch the selected coach id from the lesson service
      const selectedCoachId = await getSelectedCoachIdFromSelectedService(selectedService);
      if (!selectedCoachId) {
        handleError('There was an error fetching selected coach from service invite.');
      }
      dispatch(bookingSlice.actions.changeCoachId(selectedCoachId));

      // Fetch the selected location id from the lesson service
      const selectedLocationId = await getSelectedLocationIdFromSelectedService(selectedService);
      if (!selectedLocationId) {
        handleError('There was an error fetching selected location from service invite.');
      }
      dispatch(bookingSlice.actions.changeLocationId(selectedLocationId));

      const selectedServicesDetails = [
        {
          id: lesson.service_id,
          coachId: selectedCoachId,
          count: 1,
          isCalendarActive: true,
          scheduledDates: [
            {
              dateStart: lesson.date_start,
              dateEnd: lesson.date_end,
            },
          ],
          addedAt: DateTime.now().toMillis(),
          packagesBought: selectedService.packagesBought as SelectedServicePackagesBought,
        },
      ];

      /** Use the useService hook method to process services further */
      const { updatedDetailedServices, updatedSelectedServices } =
        getUpdatedServicesWithAdditionalData([selectedService], selectedServicesDetails);
      dispatch(bookingSlice.actions.getServices(updatedDetailedServices));
      dispatch(bookingSlice.actions.updateSelectedServices(updatedSelectedServices));

      // Update payment preference in the selected service
      if (lesson.clients.length > 0) {
        const paymentPreference = lesson.clients[0].is_payment_in_app ? 'in_app' : 'outside_app';
        dispatch(bookingSlice.actions.updatePaymentPreference(paymentPreference));
      }
    } catch (errorCode) {
      handleError(errorCode as string);
    }
  };

  /**
   * Processes the lesson invitation flow.
   * @throws Will set the error message if any issue occurs during the process.
   */
  const fetchAll = async (): Promise<void> => {
    setIsLoading(true);
    try {
      await authorize();
      await loadLessonInvitation();
      dispatch(bookingSlice.actions.changeStep(BookingStep.Summary));
    } catch (err: any) {
      const message = AxiosErrorHandler.getErrorMessage({
        request: err.request,
        response: err.response,
        message: err.message,
      });
      handleError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const openSignInModal = async () => {
    /** Open signin popup if user is not signed in */
    if (!authentication.authenticatedUser) {
      let invitedEmail: string | undefined = '';
      if (invitationIdParam) {
        invitedEmail = await LessonsService.getLessonInvitationEmailByInvitationId(
          invitationIdParam,
        );
        dispatch(
          authenticationSlice.actions.updateInvitedUser(
            invitedEmail
              ? {
                  email: invitedEmail,
                }
              : null,
          ),
        );

        dispatch(authenticationSlice.actions.changeSignInOpen(true));
      }
    }
  };

  useEffect(() => {
    if (activeSelectedService) handleFetchBusyTimesForSelectedLesson(activeSelectedService.id);
  }, [activeSelectedService]);

  useEffect(() => {
    openSignInModal();
    fetchAll();
  }, []);

  return error !== '' || isLoading ? (
    <BookingContainer
      isLoading={isLoading}
      title="Lesson Invitation"
      backButton={{
        disabled: true,
      }}
      continueButton={{
        label: 'View services',
        onClick: () => handleResetBooking(true),
      }}
    >
      {authentication.authenticatedUser && (
        <div className="alert alert-secondary text-center d-flex flex-column gap-1" role="alert">
          <p className="fw-semibold">{error}.</p>
          <p>
            Please view available services below. Please ensure that you are logged in with the same
            email address that the booking or lesson invitation was sent to.
          </p>
        </div>
      )}
    </BookingContainer>
  ) : (
    <BookingPageV2 />
  );
};

export default LessonInvitePage;
