import React, { useState } from 'react';
import downloadIcon from '../../../../assets/icons/attachment-icons/download.svg';
import { downloadFile } from '../../../../common/utils/file.helpers';
import Spinner from '../../../../components/Spinner/Spinner';

interface IProps {
  text: string;
  url: string;
  type: string;
}

const AttachmentDownloadBtn: React.FC<IProps> = ({ url, type, text }) => {
  const [downloadProccessing, setDownloadProcessing] = useState(false);
  const onDownloadFiles = async () => {
    setDownloadProcessing(true);
    await downloadFile(url, type, text);
    setDownloadProcessing(false);
  };
  return downloadProccessing ? (
    <Spinner color="#000" />
  ) : (
    <>
      <a onClick={onDownloadFiles}>
        <img src={downloadIcon} alt="downloadIcon icon" />
      </a>
      <span>Download</span>
    </>
  );
};

export default AttachmentDownloadBtn;
