import React from 'react';
import styles from './SearchClientDropdown.module.scss';

interface ISearchClientDropdown {
  openList: boolean;
  children: any;
}

const SearchClientDropdown: React.FC<ISearchClientDropdown> = ({ children, openList }) => {
  if (!openList) {
    return null;
  }
  return (
    <div className={styles.dropdown}>
      <div className={styles.title}>Clients</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
export default SearchClientDropdown;
