import { Config } from '../../config';
import { BaseRepo } from './base.repo';

export default class OrgStaffRepository extends BaseRepo {
  // #region Private Properties
  private orgStaffCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('org_staff');
  // #endregion

  // #region Public Methods
  async getStaffByUserIdAsync(orgId: string, userId: string) {
    return this.orgStaffCollection?.findOne({
      org_id: orgId,
      'staff.user_id': userId,
    });
  }

  async getAvailableOrgStaff(orgId: string) {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.orgStaffCollection = mongo.db(Config().RealmDbName as string).collection('org_staff');
    }

    const agg = [
      {
        $match: {
          org_id: orgId,
        },
      },
      {
        $unwind: '$staff',
      },
      {
        $match: {
          'staff.deleted': false,
          'staff.hide_staff_from_booking_page': { $ne: true },
        },
      },
      {
        $lookup: {
          from: 'user',
          localField: 'staff.user_id',
          foreignField: '_id',
          pipeline: [
            {
              $project: {
                _id: 1,
                first_name: 1,
                last_name: 1,
              },
            },
          ],
          as: 'staffData',
        },
      },
      {
        $unwind: '$staffData',
      },
      {
        $project: {
          id: '$staffData._id',
          firstName: '$staffData.first_name',
          lastName: '$staffData.last_name',
          _id: 0,
        },
      },
    ];
    return this.orgStaffCollection?.aggregate(agg);
  }

  // #endregion
}
