import MuiGrid, { GridProps as MuiGridProps } from '@mui/material/Grid';
import React from 'react';

type GridProps = Omit<MuiGridProps, 'sx'>;
/**
 * Added display, justify and order props
 */
export const Grid = (props: GridProps) => {
  const {
    container,
    item,
    order,
    spacing,
    rowSpacing,
    columnSpacing,
    md,
    lg,
    xl,
    children,
    xs,
    sm,
  } = props;
  return (
    <MuiGrid
      container={container}
      item={item}
      order={order}
      md={md}
      lg={lg}
      xl={xl}
      sm={item && !xs && !sm ? 12 : sm}
      xs={item && !xs && !sm ? 12 : xs}
      spacing={spacing}
      rowSpacing={rowSpacing}
      columnSpacing={columnSpacing}
    >
      {children}
    </MuiGrid>
  );
};
