import React from 'react';

import styles from './EmptyState.module.scss';

interface EmptyStateProps {
  text: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ text }) => (
  <div className={styles.emptyLabel}>{text}</div>
);

export default EmptyState;
