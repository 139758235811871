import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

interface DatePickerTextFieldProps {
  label: string;
  value: number | null;
  onChange: (value: number | null) => void;
  hasError?: boolean;
  errorMessage?: string;
  isEditMode?: boolean;
}

export const DatePickerTextField = (props: DatePickerTextFieldProps) => {
  // eslint-disable-next-line
  const { label, value, hasError, errorMessage, isEditMode, onChange } = props;
  const inputDate = value ? dayjs(value) : null;
  return (
    <Stack spacing={1}>
      {isEditMode && (
        <>
          <Typography>{label}</Typography>
          <DatePicker
            defaultValue={inputDate}
            onChange={(newValue: Dayjs | null) => {
              onChange(newValue ? newValue.valueOf() : null);
            }}
            disableFuture
            format="DD/MM/YYYY"
            slotProps={{
              textField: {
                variant: 'filled',
                fullWidth: true,
                hiddenLabel: true,
                placeholder: 'Select date',
                InputProps: {
                  disableUnderline: true,
                },
              },
              inputAdornment: {
                position: 'start',
              },
            }}
          />
        </>
      )}
      {!isEditMode && (
        <>
          <Typography fontWeight="bold">{label}</Typography>
          <Typography>{value ? dayjs(value).format('DD MMM YYYY') : '-'}</Typography>
        </>
      )}
    </Stack>
  );
};
