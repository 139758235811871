// import countries from 'i18n-iso-countries';
import { CurrencyData } from './currencies.helper';

export class Conversions {
  /**
   *
   * @param decimal must be Decimal128 type
   * @returns number
   */
  public static decimal128ToNumber(decimal: any) {
    return parseInt(decimal.toString(), 10);
  }

  private static toCamel = (str: string) => {
    return str.replace(/([-_][a-z])/gi, ($1: any) => {
      if (str.indexOf($1) === 0) {
        return $1.toLowerCase().replace('-', '').replace('_', '');
      }
      return $1.toUpperCase().replace('-', '').replace('_', '');
    });
  };

  private static toSnake = (str: string) => {
    return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  };

  public static isObject = (obj: any) => {
    return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
  };

  public static toCurrency(currencyData: CurrencyData, amountInCents?: number) {
    if (!amountInCents || amountInCents === 0) {
      return 0.0;
    }

    switch (currencyData.minorUnits) {
      case 0:
        return amountInCents;
      case 1:
        return amountInCents / 10;
      case 2:
        return amountInCents / 100;
      case 3:
        return amountInCents / 1000;
      case 4:
        return amountInCents / 10000;
      default:
        return amountInCents / 100;
    }
  }

  public static toCurrencyString(currencyData: CurrencyData, amountInCents?: number) {
    const currencyAmount = Conversions.toCurrency(currencyData, amountInCents).toFixed(
      currencyData.minorUnits,
    );
    return `${currencyData.isoCode}${currencyData.symbol}${currencyAmount}`;
  }

  public static toCurrencyShortString(currencyData: CurrencyData, amountInCents?: number) {
    const currencyAmount = Conversions.toCurrency(currencyData, amountInCents).toFixed(
      currencyData.minorUnits,
    );
    return `${currencyData.symbol}${currencyAmount}`;
  }

  public static toNumber(currencyData: CurrencyData, amountInCents?: number) {
    const currencyAmount = Conversions.toCurrency(currencyData, amountInCents).toFixed(
      currencyData.minorUnits,
    );
    return currencyAmount;
  }

  /**
   *
   * @param obj Object Type {}
   * @returns The same object but with camelCase fields.
   * e.g. from { first_name }
   * to {firstName}
   * @credit qiAlex - https://stackoverflow.com/questions/59769649/recursively-convert-an-object-fields-from-snake-case-to-camelcase
   */
  public static keysToCamel = (obj: any): any => {
    if (Conversions.isObject(obj)) {
      const n: any = {};

      Object.keys(obj).forEach((k) => {
        n[Conversions.toCamel(k)] = Conversions.keysToCamel(obj[k]);
      });

      return n;
      // eslint-disable-next-line no-else-return
    } else if (Array.isArray(obj)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return obj.map((i: any) => {
        return Conversions.keysToCamel(i);
      });
    }

    return obj;
  };

  /**
   *
   * @param obj Object Type {}
   * @returns The same object but with snakeCase fields.
   * e.g. from { first_name }
   * to {firstName}
   * @credit https://stackoverflow.com/questions/54246477/how-to-convert-camelcase-to-snake-case
   */
  public static keysToSnake = (obj: any): any => {
    if (Conversions.isObject(obj)) {
      const n: any = {};

      Object.keys(obj).forEach((k) => {
        n[Conversions.toSnake(k)] = Conversions.keysToSnake(obj[k]);
      });

      return n;
      // eslint-disable-next-line no-else-return
    } else if (Array.isArray(obj)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return obj.map((i: any) => {
        return Conversions.keysToSnake(i);
      });
    }

    return obj;
  };
}
