import React from 'react';
import { get } from 'lodash';
import clsx from 'clsx';
import styles from './Header.module.scss';

interface IProps {
  title: string;
  subtitle?: string;
  customStyles?: any;
  sentEmail?: boolean;
}

const Header: React.FC<IProps> = ({ title, subtitle, customStyles, sentEmail }) => {
  const stylesTitle = clsx(sentEmail ? styles.titleForgetPage : styles.title);
  return (
    <div className={get(customStyles, 'header', '') ? customStyles.header : styles.header}>
      <div className={get(customStyles, 'title', '') ? customStyles.title : stylesTitle}>
        {title}
      </div>
      <div className={sentEmail ? styles.subtitleForgetPage : styles.subtitle}>{subtitle}</div>
    </div>
  );
};
export default Header;
