import { Config } from 'config';
import { BaseRepo } from './base.repo';
import { PackagesBoughtHistory } from '../entities/packagesBoughtHistory.entity';

export default class PackagesBoughtHistoryRepository extends BaseRepo {
  // #region Private Properties
  private packagesBoughtHistoryCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('packages_bought_history');
  // #endregion

  // #region Public Methods

  async createPackagesBoughtHistory(packagesBoughtHistory: PackagesBoughtHistory) {
    await this.packagesBoughtHistoryCollection?.insertOne(packagesBoughtHistory);
  }

  // #endregion
}
