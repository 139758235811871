import { RefObject, useEffect, useCallback } from 'react';

export const useOutsideClick = (
  refs: RefObject<HTMLDivElement>[],
  outsideClickHandler: Function,
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const isOutside = refs.every(
        (ref: RefObject<HTMLDivElement>) =>
          ref.current && !ref.current.contains(event.target as Node),
      );

      if (isOutside) {
        outsideClickHandler();
      }
    },
    [outsideClickHandler, refs],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);
};
