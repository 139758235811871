import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { redirectToOrg } from '../../../common/utils/common.helpers';
import SessionProvider from '../../../providers/SessionProvider';
import styles from './QuiestionnairePage.module.scss';
import QuestionnaireStart from '../components/QuestionnaireStart/QuestionnaireStart';
import QuestionnaireDone from '../components/QuestionnaireDone/QuestionnaireDone';
import Questionnaire from '../components/Questionnaire/Questionnaire';
import QuestionnaireInputs from '../components/QuestionnaireInputs/QuestionnaireInputs';
import { QuestionnaireStages, allQuestions } from '../models/questionnaire.model';
import UserService from '../../../data/services/user.service';

const addressObj = {
  street_number: '',
  street_name: '',
  suburb: '',
  city: '',
  zip_code: '',
  country: '',
  country_short_name: '',
  longitude: Infinity,
  latitude: Infinity,
};

const QuestionnairePage = () => {
  const [stage, setStage] = useState(QuestionnaireStages.Pending);
  const [formValues, setFormValues] = useState<any>();
  const [curQuestionIndex, setCurQuestionIndex] = useState(0);
  const [profile, setProfile] = useState<any>();
  const [sportInfo, setSportInfo] = useState<any | undefined>();

  const userId = SessionProvider.getUserId();
  const { orgId } = useParams<Record<string, string | undefined>>();
  const googleAddressToFormattedAddress = (address: any) => {
    let formattedAddress = '';
    const addressObject = { ...addressObj };
    addressObject.latitude = address?.lat ?? Infinity;
    addressObject.longitude = address?.lng ?? Infinity;

    if (address?.address_components) {
      // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
      for (const component of address?.address_components) {
        if (component?.types?.includes('street_number')) {
          formattedAddress += `${component.long_name} `;
          addressObject.street_number = `${component.long_name} `;
        }
        if (component?.types?.includes('route')) {
          formattedAddress += `${component.long_name} `;
          addressObject.street_name = `${component.long_name} `;
        }
        if (component?.types?.includes('sublocality')) {
          formattedAddress += `${component.long_name} `;
          addressObject.suburb = `${component.long_name}`;
        }
        if (component?.types?.includes('locality')) {
          formattedAddress += `${component.long_name} `;
          addressObject.city = `${component.long_name}`;
        }
        if (component?.types?.includes('country')) {
          formattedAddress += `${component.long_name} `;
          addressObject.country = `${component.long_name}`;
          addressObject.country_short_name = `${component.short_name}`;
        }
        if (component?.types?.includes('postal_code')) {
          addressObject.zip_code = `${component.long_name}`;
        }
      }
    }

    return { formattedAddress, addressObject };
  };

  const fetchProfileDetails = useCallback(async () => {
    try {
      const profileResponse = await UserService.getProfileDetails(
        userId as string,
        orgId as string,
      );
      setProfile(profileResponse);

      const sportResponse = await UserService.getUserSportInformationAsync(
        userId as string,
        orgId as string,
      );
      setSportInfo(sportResponse);
    } catch (error) {
      console.log(error);
    }
  }, [orgId, userId]);

  useEffect(() => {
    fetchProfileDetails();
  }, [fetchProfileDetails]);

  const currentQuestion = useMemo(() => allQuestions[curQuestionIndex], [curQuestionIndex]);

  const onExit = () => redirectToOrg(orgId as string);

  const onSubmitData = async () => {
    if (formValues) {
      const address = googleAddressToFormattedAddress(formValues.homeAddress);

      if (profile && profile.length > 0) {
        await UserService.updateUserDetails(formValues, address, profile);
      } else {
        await UserService.createUserDetails(userId as string, orgId as string, formValues, address);
      }

      if (sportInfo && sportInfo.length > 0) {
        await UserService.updateSportInformation(
          userId as string,
          orgId as string,
          sportInfo,
          formValues,
        );
      } else {
        await UserService.createSportInformation(userId as string, orgId as string, formValues);
      }
    }
  };

  return (
    <div className={styles.page}>
      {stage === QuestionnaireStages.Start && (
        <QuestionnaireStart onClose={onExit} getQuestionnaire={setStage} />
      )}
      {stage === QuestionnaireStages.Pending && (
        <Questionnaire
          currentQuestion={currentQuestion}
          currentQuestionIndex={curQuestionIndex}
          setCurrentQuestionIndex={setCurQuestionIndex}
          onChangeStage={setStage}
          onSubmitResults={onSubmitData}
          formValues={formValues}
          sportData={sportInfo}
        >
          <QuestionnaireInputs
            currentQuestion={currentQuestion}
            formValues={formValues}
            setFormValues={setFormValues}
            currentType={currentQuestion.type}
            profileData={profile}
            sportData={sportInfo}
          />
        </Questionnaire>
      )}
      {stage === QuestionnaireStages.Finish && <QuestionnaireDone onClose={onExit} />}
    </div>
  );
};

export default QuestionnairePage;
