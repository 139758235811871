import React, { useCallback, useEffect, useState } from 'react';
import styles from './ListPage.module.scss';
import Filters from '../../modules/lesson/components/Filters/Filters';
import TabView from '../TabView/TabView';
import OrganizationService from '../../data/services/organization.service';

interface ListPageProps {
  onCategorySelected: Function;
  onSearchValue?: Function;
  tabs: Record<string, string | number>;
  selectedTab: string | number;
  onTabSelected: Function;
  children: unknown;
  selectedCategories: string[];
  searchValue?: string;
  extraContent?: React.ReactNode;
  orgId?: string;
  title?: string;
}

const ListPage = ({
  onCategorySelected,
  selectedCategories,
  onSearchValue,
  searchValue,
  tabs,
  selectedTab,
  onTabSelected,
  children,
  extraContent,
  orgId,
  title = '',
}: ListPageProps) => {
  const [categories, setCategories] = useState([]);

  const fetchCategories = useCallback(async () => {
    const category = await OrganizationService.getOrgLessonCategoriesById(orgId || '');
    setCategories(category);
  }, [orgId]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <>
      {title !== '' && <div className={styles.title}>{title}</div>}
      <div className={styles.pageWrapper}>
        <Filters
          categories={categories}
          searchValue={searchValue}
          setSearchValue={onSearchValue}
          selectedCategories={selectedCategories}
          setSelectedCategories={onCategorySelected}
        />
        <div className={styles.listWrapper}>
          <TabView
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={onTabSelected}
            isNotSearch={!onSearchValue}
          />
          {children}
        </div>
        {extraContent}
      </div>
    </>
  );
};

export default ListPage;
