import { saveAs } from 'file-saver';
import axios from 'axios';

const downloadFromCloudFrontUrl = async (cloudfrontUrl: string, type: string) => {
  axios({
    url: cloudfrontUrl,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const ext = type === 'quicktime' ? 'mov' : type;
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `download.${ext}`);
    document.body.appendChild(link);
    link.click();
  });
};

export const downloadFile = async (url: string, type: string, text: string) => {
  if (type === 'note') {
    const blobText = new Blob([text], { type: 'text/plain;charset=utf-8' });
    saveAs(blobText, 'attachment.txt');
  } else {
    downloadFromCloudFrontUrl(url, type);
  }
};
