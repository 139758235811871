/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-classes-per-file */
import Cookies from 'js-cookie';
import moment from 'moment';
import { AuthLocalStorage } from '../modules/auth/utils/auth.helper';
import { VerifyTokenResult } from '../data/enums/VerifyTokenResult.enum';
import { getDomainFromUrl } from '../common/utils/common.helpers';
import { Config } from '../config';
import { OrgInvitationModel } from '../data/models/orgInvitation.model';

export interface VerifyTokenResultModel {
  verifyTokenResultEnum: VerifyTokenResult;
}

class Keys {
  public readonly OrgIdForInvite = 'OrgId';
}

export default class SessionProvider {
  public static Keys = new Keys();

  private static readonly authTokenName = 'Authorization';

  private static readonly cookieDomain =
    getDomainFromUrl(`${Config().AppUrl!}`) ?? `.${window.location.hostname}`;

  static Cookies = Cookies.withAttributes({
    domain: SessionProvider.cookieDomain,
  });

  // #region Setters
  public static setAccessToken = (token: string) => {
    SessionProvider.Cookies.set(SessionProvider.authTokenName, token);
  };

  public static setRefreshToken = (token: string) =>
    SessionProvider.Cookies.set('RefreshToken', token);

  public static setUserId = (userId: string) => SessionProvider.Cookies.set('UserId', userId);

  public static setEmail = (email: string) => SessionProvider.Cookies.set('Email', email);

  public static setFirstName = (firstName: string) =>
    SessionProvider.Cookies.set('FirstName', firstName);

  static setIsClient(isClient: boolean) {
    SessionProvider.Cookies.set('IsClientInvite', isClient.toString());
  }

  static setOrgInvitation(orgInvitationModel: OrgInvitationModel) {
    SessionProvider.Cookies.set('orgInvitation', JSON.stringify(orgInvitationModel));
  }

  static setTimeZone(timeZone: string) {
    SessionProvider.Cookies.set('TimeZone', timeZone, { expires: 1 });
  }

  static setOrgId(orgId: string) {
    SessionProvider.Cookies.set('OrgId', orgId);
  }

  // #endregion

  // #region Getters
  public static getAccessToken = () =>
    SessionProvider.Cookies.get(SessionProvider.authTokenName) ?? '';

  static getRefreshToken = () => SessionProvider.Cookies.get('RefreshToken') ?? '';

  static getUserId = () => SessionProvider.Cookies.get('UserId') ?? '';

  static getEmail = () => SessionProvider.Cookies.get('Email') ?? '';

  static getFirstName = () => SessionProvider.Cookies.get('FirstName') ?? '';

  static getTimeZoneInfo = () => SessionProvider.Cookies.get('TimeZone') ?? moment.tz.guess();

  static getOrgId = () => SessionProvider.Cookies.get('OrgId') ?? '';

  // #endregion

  static updateSession = async (
    token: string,
    refreshToken: string,
    userId: string,
    email: string,
    firstName: string,
    rememberMe: boolean,
  ) => {
    SessionProvider.setAccessToken(token);
    SessionProvider.setRefreshToken(refreshToken);
    SessionProvider.setUserId(userId);
    SessionProvider.setEmail(email);
    SessionProvider.setFirstName(firstName);

    if (rememberMe && refreshToken) {
      AuthLocalStorage.setAuthLocalStorage(refreshToken, userId, email);
    }
  };

  static removeSession = () => {
    SessionProvider.Cookies.remove(SessionProvider.authTokenName);
    SessionProvider.Cookies.remove('RefreshToken');
    SessionProvider.Cookies.remove('UserId');
    SessionProvider.Cookies.remove('Email');
    SessionProvider.Cookies.remove('TimeZone');
  };

  public static updateRefreshTokenSession = (token: string, userId: string, email: string) => {
    SessionProvider.setAccessToken(token);
    SessionProvider.setUserId(userId);
    SessionProvider.setEmail(email);
  };

  public static isValueValid(value: any) {
    return !!value && value.trim().length > 0 && value !== 'undefined';
  }
}
