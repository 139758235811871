/* eslint-disable no-irregular-whitespace */

class Logger {
  /**
   * Check current environment is development
   * @returns
   * import.meta.env.NODE_ENV === 'development'
   * */
  static isDevEnvironment = import.meta.env.MODE === 'development';
}

export default Logger;
