import React, { MouseEventHandler } from 'react';
import styles from './MobileAddButton.module.scss';
import AddBtnIcon from '../../../assets/icons/add-btn.svg';

interface IProps {
  onClick: MouseEventHandler<HTMLImageElement>;
  showBtn?: boolean;
}

const MobileAddButton = ({ onClick, showBtn }: IProps) => {
  return (
    <img
      alt="add-btn-icon"
      src={AddBtnIcon}
      className={showBtn ? styles.addBtn : styles.changeBtns}
      onClick={onClick}
    />
  );
};

export default MobileAddButton;
