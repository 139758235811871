/* eslint-disable no-underscore-dangle */
import React, { useState, useMemo } from 'react';
import { get, find, map } from 'lodash';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import { Attachment } from 'data/entities/attachment.entity';
import { AttachmentSectionIndex, CoachUploads } from 'modules/attachment/models/attachment';
import AddLibraryView from 'modules/attachment/components/AddLibraryView/AddLibraryView';
import { UserSubscriptionsModel } from 'data/models/userSubscription.mode';
import { FavoriteModel } from 'data/entities/favorites.entity';
import { ButtonSavingType } from 'data/enums/ButtonType.enum';
import styles from './LessonAttachmentsContainer.module.scss';
import { openFileUploader, splitAttachmentsToSections } from '../../utils/attachment.helpers';
import AttachmentsSection from '../../components/AttachmentsSection/AttachmentsSection';
import AttachmentView from '../../components/AttachmentView/AttachmentView';
import AddTextNoteView from '../../components/AddTextNoteView/AddTextNoteView';
import ExerciseView from '../../../../components/ExerciseView/ExerciseView';
import SessionProvider from '../../../../providers/SessionProvider';
import { ExerciseModel } from '../../../../data/entities/exercises.entity';
import AttachmentsService from '../../../../data/services/attachments.service';
import FavouritesService from '../../../../data/services/favourites.service';
import { Lesson } from '../../../../data/entities/lessons.entity';
import AddFileView from '../../components/AddFileView/AddFileView';
import LessonHelper from '../../../../common/utils/lessons.helper';

interface LessonAttachmentsContainerProps {
  attachments: any[];
  categories: LessonCategory[];
  setAttachments: Function;
  onUploadFiles: (files: FileList) => void;
  onAddTextNoteAttachment: (note: string) => void;
  deleteAttachment: (attachment: Attachment, sectionIndex: number) => void;
  sectionFileIndex: number;
  setSectionFileIndex: (index: number) => void;
  exercises: ExerciseModel[];
  lesson: Lesson;
  subscriptionInfo?: UserSubscriptionsModel;
  onAddExercises: (exercises: ExerciseModel[] | FavoriteModel[]) => void;
}

const LessonAttachmentsContainer = ({
  attachments,
  categories,
  onUploadFiles,
  onAddTextNoteAttachment,
  deleteAttachment,
  sectionFileIndex,
  setSectionFileIndex,
  setAttachments,
  exercises,
  lesson,
  subscriptionInfo,
  onAddExercises,
}: LessonAttachmentsContainerProps) => {
  const [selectedAttachment, setSelectedAttachment] = useState<any | null>(null);
  const [selectedExerciseId, setSelectedExerciseId] = useState<string | null>(null);
  const [visibleAddTextNoteModal, setVisibleAddTextNoteModal] = useState(false);
  const [visibleFileViewModal, setVisibleFileViewModal] = useState(false);
  const [visibleExerciseViewModal, setVisibleExerciseViewModal] = useState(false);
  const [visibleExerciseView, setVisibleExerciseView] = useState(false);
  const [isNoContent, setIsNoContent] = useState<boolean>(false);
  const [isExercises, setIsExercises] = useState<boolean>(false);
  const [isNoteSaving, setIsNoteSaving] = useState(ButtonSavingType.save);

  const currentUserId = SessionProvider.getUserId();
  const { orgId } = useParams<Record<string, string | undefined>>();
  const currentOrgId = orgId ?? '';

  const splitedAttachments = useMemo(() => {
    const attachmentArray = [
      ...splitAttachmentsToSections(attachments),
      {
        sectionIndex: CoachUploads,
        title: "Coach's exercises",
        attachments: exercises,
      },
    ].sort((a, b) => a.sectionIndex - b.sectionIndex);

    let attachmentCount = 0;
    attachmentArray.forEach((item) => {
      if (item.sectionIndex !== AttachmentSectionIndex.ClientUploads)
        attachmentCount += item.attachments.length;
    });
    setIsNoContent(attachmentCount === 0);

    if (lesson.is_self_taught) {
      const clientUploadIndex = attachmentArray.findIndex(
        (attachment) => attachment.sectionIndex === AttachmentSectionIndex.ClientUploads,
      );

      const reasonForLessonIndex = attachmentArray.findIndex(
        (attachment) => attachment.sectionIndex === AttachmentSectionIndex.ReasonOfLesson,
      );

      const theSolutionIndex = attachmentArray.findIndex(
        (attachment) => attachment.sectionIndex === AttachmentSectionIndex.TheSolution,
      );

      attachmentArray[reasonForLessonIndex].title = 'Reason for Practice';
      attachmentArray[theSolutionIndex].title = 'The practice goal';
      delete attachmentArray[clientUploadIndex];
    }

    return attachmentArray;
  }, [attachments, exercises]);

  const onFavoriteChanged = async (
    attachmentId: string,
    favoriteId: string,
    favoriteCategories: string[],
    isFavorite: boolean,
  ) => {
    if (!selectedAttachment) {
      return;
    }

    if (!isFavorite) {
      try {
        const favoriteData = {
          lesson_id: selectedAttachment.lessonId ?? '',
          lesson_category_ids: [],
          attachment_id: attachmentId,
          favorite_note: selectedAttachment.favNote,
        };

        await FavouritesService.updateFavoriteType(selectedAttachment.favId, favoriteData);
        selectedAttachment.isFavorite = false;
        selectedAttachment.lessonCategoriesInFavorite = [];

        setSelectedAttachment({ ...selectedAttachment });
        setAttachments(
          attachments.map((x) =>
            x.attachmentId === selectedAttachment.attachmentId
              ? { ...x, lessonCategoriesInFavorite: [], isFavorite: false }
              : x,
          ),
        );
      } catch (error: any) {
        toast.error(error.message);
      }
    } else if (isFavorite && favoriteCategories.length < 1) {
      selectedAttachment.isFavorite = true;
      setSelectedAttachment({ ...selectedAttachment });
      setAttachments(
        attachments.map((x) =>
          x.attachmentId === selectedAttachment.attachmentId ? { ...x, isFavorite: true } : x,
        ),
      );
    } else if (isFavorite && favoriteCategories.length > 0) {
      const favoriteData = {
        lesson_id: selectedAttachment.lessonId ?? '',
        lesson_category_ids: favoriteCategories,
        attachment_id: attachmentId,
        favorite_note: selectedAttachment.favNote,
      };

      try {
        if (!selectedAttachment.favId) {
          const result = await FavouritesService.createFavorite(
            currentUserId,
            currentOrgId,
            favoriteData,
          );

          if (result) {
            selectedAttachment.favId = result.insertedId;
          }
        } else {
          await FavouritesService.updateFavoriteType(selectedAttachment.favId, favoriteData);
        }

        selectedAttachment.isFavorite = true;
        selectedAttachment.lessonCategoriesInFavorite = favoriteCategories;
        setSelectedAttachment({ ...selectedAttachment });
        setAttachments(
          attachments.map((x) =>
            x.attachmentId === selectedAttachment.attachmentId
              ? {
                  ...x,
                  isFavorite: true,
                  favId: selectedAttachment.favId,
                  lessonCategoriesInFavorite: favoriteCategories,
                }
              : x,
          ),
        );
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const onNoteChanged = async (value: string) => {
    if (selectedAttachment) {
      setIsNoteSaving(ButtonSavingType.saving);
      selectedAttachment.favNote = value;

      const favoriteObject = {
        lesson_id: selectedAttachment.lessonId,
        lesson_category_ids: selectedAttachment.lessonCategoriesInFavorite,
        attachment_id: selectedAttachment.attachmentId,
        favorite_note: selectedAttachment.favNote,
      };

      if (selectedAttachment.favId) {
        await FavouritesService.updateFavoriteType(selectedAttachment.favId, favoriteObject);
        setSelectedAttachment({ ...selectedAttachment });
        setAttachments(
          attachments.map((x) =>
            x.attachmentId === selectedAttachment.attachmentId
              ? { ...x, favNote: selectedAttachment.favNote }
              : x,
          ),
        );

        toast.success('Note is saved.', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsNoteSaving(ButtonSavingType.saved);
      } else {
        try {
          const result = await FavouritesService.createFavorite(
            currentUserId,
            currentOrgId,
            favoriteObject,
          );

          if (result) {
            selectedAttachment.favId = result.insertedId;
            setSelectedAttachment({ ...selectedAttachment });
            setAttachments(
              attachments.map((x) =>
                x.attachmentId === selectedAttachment.attachmentId
                  ? { ...x, favId: result.insertedId, favNote: value }
                  : x,
              ),
            );
          }

          toast.success('Note is saved.', {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: true,
          });

          setIsNoteSaving(ButtonSavingType.saved);
        } catch (error: any) {
          toast.error(error.message);
          setIsNoteSaving(ButtonSavingType.save);
        }
      }
    }
  };

  const onChangeAttachmentTextNote = async (note: string) => {
    if (selectedAttachment) {
      await AttachmentsService.updateNoteAttachmentById(selectedAttachment.attachmentId, note);

      setAttachments(
        attachments.map((x) =>
          x.attachmentId === selectedAttachment.attachmentId ? { ...x, noteContent: note } : x,
        ),
      );
    }
  };

  const wrapperStyles =
    attachments.length || exercises.length ? styles.wrapper : `${styles.wrapper} ${styles.empty}`;

  const selectedExercise = useMemo(
    () => find(exercises, (x) => x.exerciseObj._id === selectedExerciseId),
    [exercises, selectedExerciseId],
  );

  const getCategoryName = (categoryId: string) => {
    return get(
      find(categories, (category) => category._id === categoryId),
      'name',
      [],
    ) as string;
  };

  const isLessonCancelled = lesson ? LessonHelper.isLessonCancelled(lesson) : true;

  const onAddExercise = (isExercise: boolean) => {
    setIsExercises(isExercise);
    setVisibleExerciseViewModal(true);
  };

  const addExercises = (uploadObjects: ExerciseModel[] | FavoriteModel[]) => {
    onAddExercises(uploadObjects);
  };

  return (
    <div className={wrapperStyles}>
      <AddTextNoteView
        visible={visibleAddTextNoteModal}
        onClose={() => setVisibleAddTextNoteModal(false)}
        onCreate={onAddTextNoteAttachment}
        lesson={lesson}
      />
      <AddFileView
        visible={visibleFileViewModal}
        onClose={() => setVisibleFileViewModal(false)}
        onContinue={() => openFileUploader(onUploadFiles)}
        lesson={lesson}
      />
      <AddLibraryView
        visible={visibleExerciseViewModal}
        onClose={() => setVisibleExerciseViewModal(false)}
        exercises={exercises}
        onContinue={addExercises}
        subscriptionInfo={subscriptionInfo}
        isExercises={isExercises}
        attachments={splitedAttachments}
        sectionIndex={sectionFileIndex}
      />
      <AttachmentView
        title="Lesson"
        visible={!!selectedAttachment}
        onClose={() => setSelectedAttachment(null)}
        attachment={selectedAttachment}
        categories={categories}
        onFavoriteChanged={onFavoriteChanged}
        onNoteChanged={onNoteChanged}
        onChangeAttachmentTextNote={onChangeAttachmentTextNote}
        isLoading={isNoteSaving}
      />
      <ExerciseView
        title={selectedExercise?.exerciseObj.title ?? ''}
        categories={map(
          get(selectedExercise?.exerciseObj, 'org_lesson_category_ids', []),
          getCategoryName,
        )}
        text={selectedExercise?.exerciseObj.description ?? ''}
        attachments={selectedExercise?.attachments}
        onClose={() => {
          setSelectedExerciseId(null);
          setVisibleExerciseView(false);
        }}
        open={visibleExerciseView}
      />
      {splitedAttachments.map((section) => {
        return (
          <AttachmentsSection
            lesson={lesson}
            setSectionIndex={setSectionFileIndex}
            key={section.sectionIndex}
            sectionIndex={section.sectionIndex}
            title={section.title}
            attachments={section.attachments}
            onAddFile={(isSelfFlag: boolean) => {
              if (isSelfFlag) openFileUploader(onUploadFiles);
              else setVisibleFileViewModal(true);
            }}
            setSelectedAttachment={setSelectedAttachment}
            openAddNoteModal={() => setVisibleAddTextNoteModal(true)}
            deleteAttachment={deleteAttachment}
            onOpenExercisesView={(exerciseId: string) => {
              setSelectedExerciseId(exerciseId);
              setVisibleExerciseView(true);
            }}
            allowNewContent={!isLessonCancelled}
            isNoContent={isNoContent}
            onAddExercise={onAddExercise}
            setIsNoteSaving={setIsNoteSaving}
          />
        );
      })}
    </div>
  );
};

export default LessonAttachmentsContainer;
