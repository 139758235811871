import React from 'react';
import { NavMenuItemsType } from '../../../../data/enums/NavMenuItemsType.enum';
import { MenuItems } from '../MenuItems';

const NavBarSupport = () => {
  return (
    <>
      {MenuItems.filter((item: any) => item.type === NavMenuItemsType.Profile).map(
        (currentItem: any) => {
          return (
            <li key={currentItem.alt}>
              <a href={currentItem.fullPath} style={{ cursor: 'pointer' }}>
                <img src={currentItem.icon} alt={currentItem.icon} width={20} height={20} />
                {currentItem.label}
              </a>
            </li>
          );
        },
      )}
    </>
  );
};

export default NavBarSupport;
