/* eslint-disable object-shorthand */
/* eslint-disable no-continue */
/* eslint-disable class-methods-use-this */
// NB* es-lint rules has been disabled as aggregation pipelines require specific syntax.
import { DayOfWeekEnum } from 'data/enums/DayOfWeek.enum';
import { ServiceTypeEnum } from 'data/enums/ServiceType.enum';
import { Lesson } from 'data/entities/lessons.entity';
import { SelectedService } from 'modules/book/models/booking.model';
import { Coach } from 'modules/service/models/service.model';
import { Conversions } from 'common/utils/conversions.helper';
import { Config } from '../../config';
import Logger from '../../middleware/logger.middleware';
import { NeverEndingPatternEntity, ScheduledDateEntity } from '../types/realm.types';
import { BaseRepo } from './base.repo';
import { ServiceStatusEnum } from '../enums/ServiceStatus.enum';
import { NeverEndingPattern } from '../entities/base/neverEndingPattern.entity';
import { CreditBalancePackagesBought } from '../entities/creditBalance.entity';
import {
  ServiceInviteStatusEnum,
  ServiceInvitedClient,
  ServiceScheduledDate,
  Services,
} from '../entities/service.entity';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';
import { Payment } from '../enums/Payment.enum';

// #region Interfaces
interface Coaches {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  avatar?: string;
  working_hours?: {
    working_hours: WorkingHour[];
    org_location_id: string;
  };
}
interface AssistanceStaff {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  working_hours?: {
    working_hours: WorkingHour[];
    org_location_id: string;
  };
}

export interface WorkingHour {
  time_periods: TimePeriod[];
  day_of_week_enum: DayOfWeekEnum;
  is_closed_day: boolean;
}

interface TimePeriod {
  start_time: string;
  end_time: string;
}

/**
 * Indicates how the lesson pack was purchased (Cash, Credit, PaymentPlatform etc.)
 */
interface ServicePrepaidPayment {
  _id: string;
  payment_enum: Payment;
}

/**
 * Instead of returning all the details of the service document, this interface is defined on what should be returned only.
 */
export interface FindServiceByCoachesAndLocationsAgg {
  _id: string;
  org_id: string;
  allow_guests: boolean;
  date_created: number;
  date_updated: number;
  deleted: boolean;
  email_invite_link: boolean;
  flexible_repeats_every_week: number;
  invite_message: string;
  is_flexible_start_time: boolean;
  is_invite_only: boolean;
  is_never_ending: boolean;
  is_package: boolean;
  is_payment_in_app: boolean;
  is_preset_work_hours: boolean;
  is_regular: boolean;
  location_ids: string[];
  occurs_more_than_once: boolean;
  service_status_enum: ServiceStatusEnum;
  title: string;
  user_id: string;
  scheduled_dates?: ServiceScheduledDate[];
  never_ending_pattern: NeverEndingPattern;
  service_type_enum: ServiceTypeEnum;
  price_in_cents: number;
  max_participants: number;
  duration_in_minutes: number;
  package_occurrences?: number;
  package_start_date?: number;
  invited_clients?: ServiceInvitedClient[];
  tax_rate_ids?: string[];
  is_fully_booked?: boolean;
  /** --- NB* Fields added during pipeline --- */
  /**
   * Added during pipeline. Package Type is service which clients must pay upfront for all the dates set by the coach.
   */
  is_package_type?: boolean;
  /**
   *  Service is prepaid when pre-purchasing a service, and then redeeming it at a later stage.
   *  @example User purchases x5 service. They can then use their prepaid service anytime within the booking rules.
   */
  is_prepaid_type?: boolean;
  /**
   * Indicates how the lesson pack was bought.
   */
  prepaid_payment?: ServicePrepaidPayment;
  /**
   * Lets us know if the service requires upfront payment.
   */
  is_upfront_payment?: boolean;
  /**
   * We add the current user's packages_bought field from their credit_balance doc to the service.
   */
  packages_bought?: CreditBalancePackagesBought;
  /**
   * Build the coaches object so it can be used directly in the service.
   */
  coaches: Coaches[];
  /**
   * Build the assistance staff object so it can be used directly in the service.
   */
  assistance_staff: AssistanceStaff[];
  /**
   * [No-op: Only in pipeline] Used to update the never_ending pattern with hours all coaches are only available.
   */
  working_hours_intersection?: WorkingHour[];
  matching_lessons: Lesson[];
}

interface UserInvitedServicesAggregation {
  title: string;
  is_package: boolean;
  location_ids: string[];
  assistance_staff: string[];
  org_id: string;
  is_never_ending: boolean;
  never_ending_pattern: NeverEndingPatternEntity;
  allow_guests: boolean;
  is_invite_only: boolean;
  is_flexible_start_time: boolean;
  package_id: string;
  is_preset_work_hours: boolean;
  invite_message: string;
  invited_clients: ServiceInviteStatusEnum[];
  is_payment_in_app: true;
  scheduled_dates: ScheduledDateEntity[];
  tax_rate_ids?: string[];
}
// #endregion

export class ServicesRepository extends BaseRepo {
  // #region Private Properties
  private servicesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('services');
  // #endregion

  // #region Private Methods

  /**
   * Sanitizes fields and adds a few new fields
   * @returns Aggregation to add new fields
   */
  private AddFieldsAgg = () => {
    return [
      {
        $addFields: {
          /** We set the field to an empty array by as this causes issues during the aggregation */
          tax_rate_ids: {
            $ifNull: ['$tax_rate_ids', []],
          },
          /** We set the field to an empty array by as this causes issues during the aggregation */
          scheduled_dates: {
            $ifNull: ['$scheduled_dates', []],
          },
          /** We set the field to an empty array by as this causes issues during the aggregation */
          assistance_staff: {
            $ifNull: ['$assistance_staff', []],
          },
          // These are new fields added do the help with the pipeline instead of checking each time.s
          is_prepaid_type: {
            $and: [{ $eq: ['$service_type_enum', ServiceTypeEnum.Package] }],
          },
          is_package_type: {
            $and: [
              { $eq: ['$is_package', true] },
              {
                $ne: ['$service_type_enum', ServiceTypeEnum.Package],
              },
              {
                $ne: ['$service_type_enum', ServiceTypeEnum.Lesson],
              },
            ],
          },
        },
      },
    ];
  };

  /**
   * Returns aggregation pipeline weather to show packages that have already started or not.
   * @param currentTime
   * @param allowPastServices
   * @returns Aggregation pipeline
   */
  private FacetPackagesStage = (currentTime: number, allowPastServices?: boolean) => {
    if (allowPastServices === true) {
      // no-op, we continue with filters
      return [];
    }

    return [
      {
        $match: {
          $expr: {
            $gte: [{ $arrayElemAt: ['$scheduled_dates.date_start', 0] }, currentTime],
          },
        },
      },
    ];
  };

  /**
   * Returns an aggregation pipeline weather to filter out services that have already started.
   * @param currentTime
   * @param allowPastServices
   * @returns Aggregation pipeline
   */
  private FacetRestOfServicesStage = (currentTime: number, allowPastServices?: boolean) => {
    if (allowPastServices === true) {
      return [];
    }

    return [
      {
        $match: {
          $expr: {
            $cond: [
              {
                $ne: ['$scheduled_dates', []],
              },
              {
                $gt: [
                  {
                    $size: {
                      $filter: {
                        input: '$scheduled_dates',
                        as: 'scheduledDate',
                        cond: {
                          $gte: ['$$scheduledDate.date_start', currentTime],
                        },
                      },
                    },
                  },
                  0,
                ],
              },
              {},
            ],
          },
        },
      },
      {
        $match: {
          $expr: {
            $cond: [
              {
                $and: [
                  {
                    $eq: ['$is_preset_work_hours', false],
                  },
                ],
              },
              {
                $ne: ['$scheduled_dates', []],
              },
              {},
            ],
          },
        },
      },
    ];
  };

  /**
   * Performs logic and filtering for each type of service concurrently
   * @param currentTime Unix milliseconds
   * @param orgId
   * @param userId
   * @param isInvite If it is an invitation, we then allow services to be shown in the past.
   * @returns
   */
  private FilterServicesByType = (
    currentTime: number,
    orgId: string,
    userId?: string,
    isInvite?: boolean,
  ) => {
    return [
      {
        $facet: {
          prepaidServices: [
            {
              $match: {
                is_prepaid_type: true,
              },
            },
            ...this.PrepaidServiceLogicAgg(orgId, userId),
          ],
          packages: [
            {
              $match: {
                is_package_type: true,
              },
            },
            ...this.FacetPackagesStage(currentTime, isInvite),
          ],
          restOfServices: [
            {
              $match: {
                is_prepaid_type: false,
                is_package_type: false,
              },
            },
            ...this.FacetRestOfServicesStage(currentTime, isInvite),
          ],
        },
      },
      {
        $project: {
          combinedServices: {
            $concatArrays: ['$prepaidServices', '$packages', '$restOfServices'],
          },
        },
      },
      {
        $unwind: '$combinedServices',
      },
      {
        $replaceRoot: {
          newRoot: '$combinedServices',
        },
      },
    ];
  };

  private FilterServiceLessonByType = (orgId: string, userId?: string) => {
    return [
      {
        $facet: {
          prepaidService: [
            {
              $match: {
                is_prepaid_type: true,
              },
            },
            ...this.PrepaidServiceLogicAgg(orgId, userId),
          ],
          normalService: [
            {
              $match: {
                is_prepaid_type: false,
              },
            },
          ],
        },
      },
      {
        $project: {
          combinedServices: {
            $concatArrays: ['$prepaidService', '$normalService'],
          },
        },
      },
      {
        $unwind: '$combinedServices',
      },
      {
        $replaceRoot: {
          newRoot: '$combinedServices',
        },
      },
    ];
  };

  private PrepaidServiceLogicAgg = (orgId: string, userId?: string) => {
    return [
      {
        $lookup: {
          from: 'credit_balance',
          pipeline: [
            {
              $match: {
                user_id: userId,
                org_id: orgId,
              },
            },
          ],
          as: 'credit_balance',
        },
      },
      {
        $unwind: { path: '$credit_balance', preserveNullAndEmptyArrays: true },
      },
      {
        $addFields: {
          packages_bought: {
            $first: {
              $filter: {
                input: '$credit_balance.packages_bought',
                as: 'package',
                cond: {
                  $eq: ['$$package.service_id', '$_id'],
                },
              },
            },
          },
        },
      },
      {
        $addFields: {
          packages_bought: {
            $mergeObjects: [
              '$packages_bought',
              {
                lessons_remaining: {
                  $subtract: [
                    '$packages_bought.lessons_occurrences_bought',
                    '$packages_bought.lessons_taken',
                  ],
                },
              },
            ],
          },
        },
      },
      {
        $lookup: {
          from: 'payments',
          as: 'payments',
          let: {
            serviceId: '$_id',
          },
          pipeline: [
            {
              $match: {
                user_id: userId,
                org_id: orgId,
                $expr: {
                  $in: ['$$serviceId', '$selected_services_ids'],
                },
              },
            },
            { $sort: { date_created: -1 } },
            { $limit: 1 },
          ],
        },
      },
      {
        $unwind: { path: '$payments', preserveNullAndEmptyArrays: true },
      },
      {
        $addFields: {
          is_upfront_payment: {
            $and: [
              {
                $or: [
                  {
                    $eq: ['$is_package_type', true],
                  },
                  {
                    $and: [
                      { $eq: ['$is_prepaid_type', true] },
                      {
                        $or: [
                          { $lt: ['$packages_bought.lessons_occurrences_bought', 1] },
                          {
                            $lt: ['$packages_bought.lessons_remaining', 1],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          prepaid_payment: {
            _id: '$payments._id',
            payment_enum: '$payments.payment_enum',
          },
        },
      },
      {
        $project: {
          credit_balance: 0,
          payments: 0,
        },
      },
    ];
  };

  private DoCoachAndLocationAgg = (currentTime: number, orgId: string) => [
    {
      $lookup: {
        from: 'org_staff',
        let: { org_id: '$org_id', assistance_staff: '$assistance_staff' },
        pipeline: [
          {
            $match: {
              $expr: {
                $eq: ['$$org_id', '$org_id'],
              },
            },
          },
          {
            $unwind: '$staff',
          },
          {
            $match: {
              $expr: {
                $and: [
                  {
                    $in: ['$staff.user_id', '$$assistance_staff'],
                  },
                  {
                    $ne: ['$staff.hide_staff_from_booking_page', true],
                  },
                  {
                    $ne: ['$staff.deleted', true],
                  },
                ],
              },
            },
          },
          {
            $replaceRoot: { newRoot: '$staff' },
          },
        ],
        as: 'matching_org_staff',
      },
    },
    {
      $lookup: {
        from: 'user',
        localField: 'assistance_staff',
        foreignField: '_id',
        let: {
          matching_org_staff: '$matching_org_staff',
        },
        pipeline: [
          {
            $match: {
              $expr: {
                $in: [
                  '$_id', // Field from the "user" collection
                  { $map: { input: '$$matching_org_staff', as: 'm', in: '$$m.user_id' } }, // Mapping the user_id values from matching_org_staff
                ],
              },
            },
          },
          {
            $project: {
              _id: 1,
              first_name: 1,
              last_name: 1,
              email: 1,
            },
          },
        ],
        as: 'assistance_staff',
      },
    },
    {
      $lookup: {
        from: 'user',
        localField: 'coaches',
        foreignField: '_id',
        pipeline: [
          {
            $project: {
              _id: 1,
              first_name: 1,
              last_name: 1,
              email: 1,
            },
          },
        ],
        as: 'coaches',
      },
    },
    {
      $lookup: {
        from: 'working_hours',
        localField: 'coaches._id',
        foreignField: 'user_id',
        as: 'working_hours',
        let: {
          org_id: '$org_id',
        },
        pipeline: [
          {
            $match: {
              $expr: {
                $and: [
                  {
                    $eq: ['$org_id', '$$org_id'],
                  },
                ],
              },
            },
          },
        ],
      },
    },
    {
      $lookup: {
        from: 'working_hours',
        localField: 'assistance_staff._id',
        foreignField: 'user_id',
        as: 'working_hours_staff',
        let: {
          org_id: '$org_id',
          user_id: '$assistance_staff._id',
        },
        pipeline: [
          {
            $match: {
              $expr: {
                $and: [
                  {
                    $eq: ['$org_id', '$$org_id'],
                  },
                ],
              },
            },
          },
        ],
      },
    },
    {
      $lookup: {
        from: 'user_details',
        localField: 'coaches._id',
        foreignField: 'user_id',
        pipeline: [
          {
            $match: {
              org_id: orgId,
            },
          },
        ],
        as: 'coaches_user_details',
      },
    },
    {
      $addFields: {
        coaches: {
          $map: {
            input: '$coaches',
            as: 'obj',
            in: {
              $mergeObjects: [
                '$$obj',
                {
                  avatar: {
                    $arrayElemAt: [
                      '$coaches_user_details.avatar',
                      {
                        $indexOfArray: ['$coaches', '$$obj'],
                      },
                    ],
                  },
                  working_hours: {
                    $reduce: {
                      input: '$working_hours',
                      initialValue: null,
                      in: {
                        $let: {
                          vars: {
                            filtered: {
                              $filter: {
                                input: '$$this.working_hours_locations',
                                as: 'working_hours_locations_var',
                                cond: {
                                  $and: [
                                    { $eq: ['$$this.user_id', '$$obj._id'] },
                                    {
                                      $eq: [
                                        '$$working_hours_locations_var.org_location_id',
                                        { $first: '$location_ids' },
                                      ],
                                    },
                                  ],
                                },
                              },
                            },
                          },
                          in: {
                            $cond: {
                              if: { $gt: [{ $size: '$$filtered' }, 0] },
                              then: { $arrayElemAt: ['$$filtered', 0] },
                              else: '$$value',
                            },
                          },
                        },
                      },
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
    {
      $addFields: {
        assistance_staff: {
          $map: {
            input: '$assistance_staff',
            as: 'assistant',
            in: {
              $mergeObjects: [
                '$$assistant',
                {
                  working_hours: {
                    $first: {
                      $map: {
                        input: {
                          $filter: {
                            input: '$working_hours_staff',
                            as: 'working_hours_staff_var',
                            cond: {
                              $eq: ['$$working_hours_staff_var.user_id', '$$assistant._id'],
                            },
                          },
                        },
                        as: 'wh',
                        in: {
                          $mergeObjects: [
                            '$$wh',
                            {
                              working_hours_locations: {
                                $first: {
                                  $filter: {
                                    input: '$$wh.working_hours_locations',
                                    as: 'loc',
                                    cond: {
                                      $in: ['$$loc.org_location_id', '$location_ids'],
                                    },
                                  },
                                },
                              },
                            },
                          ],
                        },
                      },
                    },
                  },
                },
              ],
            },
          },
        },
      },
    },
    {
      // We manually fetch the specific object to be top level object for working_hours field.
      $addFields: {
        assistance_staff: {
          $map: {
            input: '$assistance_staff',
            as: 'assistant',
            in: {
              $mergeObjects: [
                '$$assistant',
                {
                  working_hours: '$$assistant.working_hours.working_hours_locations',
                },
              ],
            },
          },
        },
      },
    },
    {
      $project: {
        scheduled_dates_lookup: 0,
        matching_org_staff: 0,
        working_hours_staff: 0,
      },
    },
  ];

  // #endregion

  // #region Public Methods

  async getById(id: string) {
    return this.servicesCollection?.findOne({ _id: id });
  }

  async getServicesByCoachAndLocationAsync(
    orgId: string,
    coachIds: string[],
    locationIds: string[],
  ): Promise<FindServiceByCoachesAndLocationsAgg[] | undefined> {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.servicesCollection = mongo.db(Config().RealmDbName as string).collection('services');
    }
    const currentTime = getUnixMilliseconds();
    const agg = [
      {
        $match: {
          org_id: orgId,
          deleted: false,
          service_status_enum: 0,
          allow_guests: true,
        },
      },
      {
        $match: {
          coaches: {
            $in: coachIds,
          },
        },
      },
      {
        $match: {
          location_ids: {
            $in: locationIds,
          },
        },
      },
      ...this.AddFieldsAgg(),
      ...this.FilterServicesByType(currentTime, orgId, undefined),
      ...this.DoCoachAndLocationAgg(currentTime, orgId),
    ];

    const services = await this.servicesCollection?.aggregate(agg);
    this.doWorkingHoursIntersectionAndUpdate(currentTime, services);
    return services;
  }

  async getServicesByCoachAndLocationForUserAsync(
    orgId: string,
    userId: string,
    email: string,
    coachIds: string[],
    locationIds: string[],
  ): Promise<FindServiceByCoachesAndLocationsAgg[] | undefined> {
    const currentTime = getUnixMilliseconds();
    const agg = [
      {
        $lookup: {
          from: 'lessons',
          localField: '_id',
          foreignField: 'service_id',
          as: 'matching_lessons',
        },
      },
      {
        $match: {
          org_id: orgId,
          deleted: false,
          service_status_enum: 0,
        },
      },
      {
        $match: {
          coaches: {
            $in: coachIds,
          },
        },
      },
      {
        $match: {
          location_ids: {
            $in: locationIds,
          },
        },
      },
      {
        $facet: {
          public_services: [
            {
              $match: {
                allow_guests: true,
              },
            },
          ],
          invited_clients_services: [
            // Gets all the services which use invited_clients field and:
            // user's Id is in invited_clients.user_id or email in invited_clients.email.
            {
              $match: {
                allow_guests: false,
                invited_clients: { $ne: null },
              },
            },
            {
              $match: {
                $expr: {
                  $or: [
                    {
                      $in: [userId, '$invited_clients.user_id'],
                    },
                    {
                      $in: [email, '$invited_clients.email'],
                    },
                  ],
                },
              },
            },
          ],
          client_category_services: [
            // Gets all services in which uses client_category_ids and:
            // Does any of the user's client_category_ids equal to any of the service client_category_ids?
            {
              $match: {
                allow_guests: false,
                invited_clients: { $eq: null },
                client_category_ids: {
                  $ne: null, // Ensure client_category_ids is not null
                  $not: { $size: 0 }, // Ensure client_category_ids is not an empty array
                },
              },
            },
            {
              $lookup: {
                from: 'org_clients',
                localField: 'org_id',
                foreignField: 'org_id',
                as: 'matching_org_client',
                let: { user_id: userId, client_category_ids: '$client_category_ids' },
                pipeline: [
                  {
                    $project: {
                      clients: {
                        $filter: {
                          input: '$clients',
                          as: 'client',
                          cond: {
                            $and: [
                              { $eq: ['$$client.user_id', '$$user_id'] },
                              {
                                $gt: [
                                  {
                                    $size: {
                                      $setIntersection: [
                                        '$$client_category_ids',
                                        '$$client.client_category_ids',
                                      ],
                                    },
                                  },
                                  0,
                                ],
                              },
                            ],
                          },
                        },
                      },
                    },
                  },
                  {
                    $match: {
                      $expr: { $gt: [{ $size: '$clients' }, 0] },
                    },
                  },
                ],
              },
            },
            {
              $match: {
                $expr: {
                  $gt: [{ $size: '$matching_org_client' }, 0],
                },
              },
            },
            {
              $project: {
                matching_org_client: 0,
              },
            },
          ],
        },
      },
      {
        $project: {
          combinedServices: {
            $concatArrays: [
              '$public_services',
              '$invited_clients_services',
              '$client_category_services',
            ],
          },
        },
      },
      {
        $unwind: '$combinedServices',
      },
      {
        $replaceRoot: {
          newRoot: '$combinedServices',
        },
      },
      ...this.AddFieldsAgg(),
      ...this.FilterServicesByType(currentTime, orgId, userId),
      ...this.DoCoachAndLocationAgg(currentTime, orgId),
    ];

    const services = await this.servicesCollection?.aggregate(agg);
    this.doWorkingHoursIntersectionAndUpdate(currentTime, services);
    return services;
  }

  async getServicesForUserAsync(
    orgId: string,
    userId: string,
  ): Promise<FindServiceByCoachesAndLocationsAgg[] | undefined> {
    const currentTime = getUnixMilliseconds();
    const agg = [
      {
        $match: {
          org_id: orgId,
          deleted: false,
          service_status_enum: 0,
        },
      },
      ...this.AddFieldsAgg(),
      ...this.FilterServicesByType(currentTime, orgId, userId),
      ...this.DoCoachAndLocationAgg(currentTime, orgId),
    ];

    const services = await this.servicesCollection?.aggregate(agg);
    this.doWorkingHoursIntersectionAndUpdate(currentTime, services);
    return services;
  }

  async getServiceInviteByIdAsync(
    serviceId: string,
    orgId: string,
    inviteId: string,
    userId: string,
    email: string,
  ): Promise<FindServiceByCoachesAndLocationsAgg[] | undefined> {
    const currentTime = getUnixMilliseconds();
    const agg = [
      {
        $match: {
          _id: serviceId,
          org_id: orgId,
          deleted: false,
          service_status_enum: 0,
        },
      },
      {
        $match: {
          invited_clients: {
            $elemMatch: {
              invite_id: inviteId,
              $or: [
                {
                  user_id: userId,
                },
                { email: email },
              ],
            },
          },
        },
      },
      ...this.AddFieldsAgg(),
      ...this.FilterServicesByType(currentTime, orgId, userId, true),
      ...this.DoCoachAndLocationAgg(currentTime, orgId),
    ];

    if (this.app.currentUser === null) {
      // Edge case, when user is not signed in booking page and need to filter locations/coaches based on selection.
      const mongo = await this.LogUserInAnonymously();
      this.servicesCollection = mongo.db(Config().RealmDbName as string).collection('services');
    }

    const services = await this.servicesCollection?.aggregate(agg);
    this.doWorkingHoursIntersectionAndUpdate(currentTime, services);
    return services;
  }

  async getServiceForLessonInviteAsync(
    serviceId: string,
    orgId: string,
    userId?: string,
  ): Promise<FindServiceByCoachesAndLocationsAgg[] | undefined> {
    const currentTime = getUnixMilliseconds();
    const agg = [
      {
        $match: {
          _id: serviceId,
          org_id: orgId,
          deleted: false,
          service_status_enum: 0,
        },
      },
      ...this.AddFieldsAgg(),
      ...this.FilterServiceLessonByType(orgId, userId),
      ...this.DoCoachAndLocationAgg(currentTime, orgId),
    ];

    if (this.app.currentUser === null) {
      // Edge case, when user is not signed in booking page and need to filter locations/coaches based on selection.
      const mongo = await this.LogUserInAnonymously();
      this.servicesCollection = mongo.db(Config().RealmDbName as string).collection('services');
    }
    const services = await this.servicesCollection?.aggregate(agg);
    this.doWorkingHoursIntersectionAndUpdate(currentTime, services);
    return services;
  }

  /** Doesn't get all details */
  async getServicesUserHasBeenInvitedToo(
    userId: string,
    orgId: string,
    email: string,
  ): Promise<UserInvitedServicesAggregation[] | undefined> {
    try {
      const agg = [
        {
          $lookup: {
            from: 'organization',
            localField: 'org_id',
            foreignField: '_id',
            as: 'organization',
          },
        },
        {
          $unwind: '$organization',
        },
        {
          $lookup: {
            from: 'org_locations',
            localField: 'org_id',
            foreignField: 'org_id',
            as: 'locations',
          },
        },
        {
          $unwind: '$locations',
        },
        {
          $match: {
            invited_clients: {
              $elemMatch: {
                date_accepted: {
                  $exists: false,
                },
                invite_status_enum: {
                  $in: [0, 1, null],
                },
                deleted: {
                  $in: [false, null],
                },
                invite_id: {
                  $nin: ['', null],
                },
                $or: [{ user_id: userId }, { email: email }],
              },
            },
            org_id: orgId,
            service_status_enum: 0,
            deleted: false,
          },
        },
        {
          $match: {
            $expr: {
              $and: [
                {
                  $in: [
                    '$service_type_enum',
                    [
                      0,
                      ServiceTypeEnum.Lesson,
                      ServiceTypeEnum.Package,
                      ServiceTypeEnum.Event,
                      ServiceTypeEnum.Programme,
                    ],
                  ],
                },
              ],
            },
          },
        },
      ];

      const invitedServices: UserInvitedServicesAggregation[] =
        await this.servicesCollection?.aggregate(agg);

      return invitedServices;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error('Error processing get user invited services');
        console.error(error);
      }
      return undefined;
    }
  }

  async getLocationsByServiceCoachesAsync(userId: string, orgId: string): Promise<string[]> {
    if (this.app.currentUser === null) {
      // Edge case, when user is not signed in booking page and need to filter locations/coaches based on selection.
      const mongo = await this.LogUserInAnonymously();
      this.servicesCollection = mongo.db(Config().RealmDbName as string).collection('services');
    }
    const agg = [
      {
        $match: {
          org_id: orgId,
          deleted: false,
          service_status_enum: 0,
          coaches: {
            $in: [userId],
          },
        },
      },
      {
        $group: {
          _id: '$location_ids',
        },
      },
      {
        $group: {
          _id: 0,
          location_ids: {
            $push: '$_id',
          },
        },
      },
      {
        $project: {
          _id: 0,
          location_ids: {
            $reduce: {
              input: '$location_ids',
              initialValue: [],
              in: {
                $setUnion: ['$$value', '$$this'],
              },
            },
          },
        },
      },
    ];
    const locationIds = await this.servicesCollection?.aggregate(agg);
    if (!locationIds || locationIds.length === 0) {
      return [];
    }
    return locationIds[0].location_ids;
  }

  async getCoachesByServiceLocationAsync(
    selectedLocation: string,
    orgId: string,
  ): Promise<string[]> {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.servicesCollection = mongo.db(Config().RealmDbName as string).collection('services');
    }

    const agg = [
      {
        $match: {
          org_id: orgId,
          deleted: false,
          service_status_enum: 0,
          location_ids: {
            $in: [selectedLocation],
          },
        },
      },
      {
        $group: {
          _id: '$coaches',
        },
      },
      {
        $group: {
          _id: 0,
          coaches: {
            $push: '$_id',
          },
        },
      },
      {
        $project: {
          _id: 0,
          coaches: {
            $reduce: {
              input: '$coaches',
              initialValue: [],
              in: {
                $setUnion: ['$$value', '$$this'],
              },
            },
          },
        },
      },
    ];

    const coachIds = await this.servicesCollection?.aggregate(agg);

    if (!coachIds || coachIds.length === 0) {
      return [];
    }
    return coachIds[0].coaches;
  }

  async updateInvitedClientStatus(
    serviceId: string,
    invitedId: string,
    userId: string,
    inviteStatusEnum: ServiceInviteStatusEnum,
  ) {
    const currentTime = getUnixMilliseconds();

    return this.servicesCollection?.updateOne(
      {
        $and: [{ _id: serviceId }, { 'invited_clients.invite_id': invitedId }],
      },
      {
        $set: {
          'invited_clients.$.user_id': userId,
          'invited_clients.$.invite_status_enum': inviteStatusEnum,
          'invited_clients.$.date_accepted': currentTime,
          'invited_clients.$.date_updated': currentTime,
        },
      },
    );
  }

  async updateInvitedClientUserId(id: string, email: string, userId: string) {
    return this.servicesCollection?.updateOne(
      {
        $and: [{ _id: id }, { 'invited_clients.email': email }],
      },
      {
        $set: {
          'invited_clients.$.user_id': userId,
        },
      },
    );
  }

  async getServiceByClientInvite(orgId: string, serviceClientInviteId: string): Promise<Services> {
    if (this.app.currentUser === null) {
      // Edge case, when user is not signed in booking page and need to validate the service.
      const mongo = await this.LogUserInAnonymously();
      this.servicesCollection = mongo.db(Config().RealmDbName as string).collection('services');
    }
    return this.servicesCollection?.findOne({
      org_id: orgId,
      'invited_clients.invite_id': serviceClientInviteId,
    });
  }

  async acceptClientInvite(serviceId: string, serviceClientInviteId: string, userId: string) {
    return this.servicesCollection?.updateOne(
      {
        _id: serviceId,
        'invited_clients.invite_id': serviceClientInviteId,
      },
      {
        $set: {
          'invited_clients.$.invite_status_enum': ServiceInviteStatusEnum.Accepted,
          'invited_clients.$.date_accepted': getUnixMilliseconds(),
          'invited_clients.$.date_updated': getUnixMilliseconds(),
          'invited_clients.$.user_id': userId,
        },
      },
    );
  }

  /**
   * This should ideally be in the aggregation pipeline, but has been moved outside for isolation.
   * @param currentTime The current time in unix milliseconds
   * @param services Array of services (nullable)
   * @returns Modified services array with the addition of working_hours_intersection, and never_ending_pattern
   */
  doWorkingHoursIntersectionAndUpdate = (
    currentTime: number,
    services?: FindServiceByCoachesAndLocationsAgg[],
  ) => {
    if (!services || services.length === 0) return services;

    services.forEach((service: any) => {
      service.working_hours_intersection = this.calculateIntersectingWorkingHours(
        service.coaches,
        service.assistance_staff,
      );

      if (service.is_never_ending === false && service.is_preset_work_hours === true) {
        // We update the never ending pattern to the new working hours union field.
        // The never ending pattern field is used on the Select Date and time page.
        service.never_ending_pattern = {
          repeats: 1,
          starts_from: currentTime,
          schedule: service.working_hours_intersection?.filter(
            (x: WorkingHour) => x.is_closed_day === false,
          ),
        };
      }
    });

    return services;
  };

  /**
   * This should ideally be in the aggregation pipeline, but has been moved outside for isolation.
   * @param currentTime The current time in unix milliseconds
   * @param selectedServices Array of selected services
   * @returns Modified services array with the addition of working_hours_intersection, and never_ending_pattern
   */
  doWorkingHoursIntersectionAndUpdateForSelectedServices = (
    currentTime: number,
    services: SelectedService[],
  ) => {
    if (!services || services.length === 0) return services;

    services.forEach((service: SelectedService) => {
      service.coaches = service.coaches.filter(
        (coach: Coach) => coach.id === service.chosenCoachId,
      );

      service.workingHoursIntersection = this.calculateIntersectingWorkingHours(
        Conversions.keysToSnake(service.coaches),
        Conversions.keysToSnake(service.assistanceStaff),
      );

      if (service.isNeverEnding === false && service.isPresetWorkHours === true) {
        // We update the never ending pattern to the new working hours union field.
        // The never ending pattern field is used on the Select Date and time page.
        service.neverEndingPattern = {
          repeats: 1,
          startsFrom: currentTime,
          schedule: Conversions.keysToCamel(
            service.workingHoursIntersection?.filter((x: WorkingHour) => x.is_closed_day === false),
          ),
        };
      }
    });

    return services;
  };

  /**
   * Finds the intersection of the coaches and all staff working hours.
   * @example Coach [{03:00-10:00}, {12:00 - 23:59}], Staff [{08:00-17:00}] Intersection = [{08:00-10:00}, {12:00-17:00}]
   * @param coaches All the coaches in the Service
   * @param assistance_staff Any staff in the service
   * @returns a new array of intersection working hours of the union.
   */
  calculateIntersectingWorkingHours = (
    coaches: Coaches[],
    assistance_staff?: AssistanceStaff[],
  ) => {
    if (!assistance_staff || assistance_staff.length === 0) {
      return coaches[0]!.working_hours?.working_hours;
    }

    const formatTime = (time: any) => {
      const hoursNumber = Math.floor(time / 60);
      const hoursString = `${hoursNumber < 10 ? '0' : ''}${hoursNumber}`;

      const remainder = time % 60;
      const minutesString = `${remainder < 10 ? '0' : ''}${remainder}`;

      return `${hoursString}:${minutesString}`;
    };

    const formatWorkingHours = (hours: any) => {
      return {
        start_time: formatTime(hours.start_time),
        end_time: formatTime(hours.end_time),
      };
    };

    const convertTimePeriod = (timePeriod: any) => {
      const { start_time, end_time } = timePeriod;
      const [startHour, startMinute] = start_time.split(':').map((val: any) => parseInt(val, 10));
      const [endHour, endMinute] = end_time.split(':').map((val: any) => parseInt(val, 10));
      return {
        start_time: startHour * 60 + startMinute,
        end_time: endHour * 60 + endMinute,
      };
    };

    const parseWorkingHours = (workingHours: any) => {
      if (workingHours.is_closed_day) return workingHours;
      const timePeriods = workingHours.time_periods;
      const newTimePeriods = timePeriods
        .map(convertTimePeriod)
        .filter((x: any) => x !== null)
        .sort((a: any, b: any) => a.start_time - b.start_time);
      workingHours.time_periods = newTimePeriods;
      return workingHours;
    };

    const findIntersection = (hours1: any, hours2: any) => {
      const start_time = Math.max(hours1.start_time, hours2.start_time);
      const end_time = Math.min(hours1.end_time, hours2.end_time);

      return start_time < end_time
        ? {
            start_time,
            end_time,
          }
        : null;
    };

    const coachWorkingHours = coaches[0].working_hours!.working_hours;
    const parsedCoachesWorkingHours = coachWorkingHours.map(parseWorkingHours);

    const allStaffWorkingHours = assistance_staff.map((x) => {
      return x.working_hours?.working_hours.map(parseWorkingHours);
    });

    const intersection = parsedCoachesWorkingHours;
    let breakStaffLoop = false;

    for (let i = 0; i < intersection.length; i++) {
      const wh = intersection[i];
      if (wh.is_closed_day) continue; // IF the day is currently closed, then we can continue to next day.
      for (let j = 0; j < allStaffWorkingHours.length; j++) {
        if (breakStaffLoop) {
          breakStaffLoop = false;
          break;
        }

        const currentAllStaffWh = allStaffWorkingHours[j];
        for (let k = 0; k < currentAllStaffWh!.length; k++) {
          const staffWh = currentAllStaffWh![k];

          if (wh.day_of_week_enum !== staffWh.day_of_week_enum) continue;

          if (staffWh.is_closed_day) {
            wh.is_closed_day = true;
            if (j < allStaffWorkingHours.length - 1) {
              // We step out of all staff working hours as intersection closed day is marked true.
              breakStaffLoop = true;
            }
            break;
          }

          for (let l = 0; l < wh.time_periods.length; l++) {
            // We iterate through the current main source and staff source
            // If no intersection is found, then the time_period is removed from the array.
            for (let m = 0; m < staffWh.time_periods.length; m++) {
              const foundIntersection = findIntersection(
                wh.time_periods[l],
                staffWh.time_periods[m],
              );
              if (foundIntersection) {
                wh.time_periods[l].start_time = foundIntersection.start_time;
                wh.time_periods[l].end_time = foundIntersection.end_time;
              } else {
                wh.time_periods.splice(l);
              }
            }
          }

          // We jump out into all staff as we found matching day, no need to loop through other days.
          break;
        }
      }
    }

    intersection.forEach((x) => {
      if (x.is_closed_day === true) {
        return x;
      }
      if (!x.time_periods || x.time_periods.length < 0) {
        return [{ start_time: '', end_time: '' }];
      }
      const convertedTimePeriods = x.time_periods.map(formatWorkingHours);
      x.time_periods = convertedTimePeriods;
      return x;
    });

    return intersection;
  };

  async getServiceInvitationUserIdByInvitationId(
    invitationId: string,
  ): Promise<string | undefined> {
    const result = await this.servicesCollection?.findOne({
      invited_clients: { $elemMatch: { invite_id: invitationId } },
    });
    if (result) {
      const service = result as Services;
      if (service.invited_clients) {
        const invitedClient = service.invited_clients.find(
          (client) => client.invite_id === invitationId,
        );
        if (invitedClient) {
          return invitedClient.user_id;
        }
      }
    }
    return undefined;
  }
  // #endregion
}
