import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { OrgListModel } from 'data/entities/organization.entity';
import OrganizationService from 'data/services/organization.service';
import { SportType } from 'data/enums/SportType.enum';
import { DataItem } from 'components/DropdownMenu/DropdownMenu';
import { User } from '../../data/entities/users.entity';
import RealmRepositories from '../../data/base/realm.repo';
import { checkUserTypeAndRedirect } from '../../common/utils/common.helpers';
import SessionProvider from '../../providers/SessionProvider';
import { File } from '../../data/entities/file.entity';
import { Config } from '../../config';

import UserAvatar from './UserAvatar';
import styles from './UserInfo.module.scss';
import UserService from '../../data/services/user.service';
import OrgSelectorDropdown from './OrgSelectorDropdown/OrgSelectorDropdown';

interface IUserInfo {
  onClose: Function;
}

const UserInfo: React.FC<IUserInfo> = ({ onClose }) => {
  const [profile, setProfile] = useState<User>();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [userOrgs, setUserOrgs] = useState<OrgListModel[]>();

  const { pathname } = useLocation();
  const userId = SessionProvider.getUserId();
  const orgId = pathname.split('/')[2];
  const currentOrgId = orgId ?? '';
  const [profilePicFile, setProfilePicFile] = useState<File>();

  const fetchData = useCallback(async () => {
    const orgListResponse: OrgListModel[] = await OrganizationService.getUserOrgList(userId);
    setUserOrgs(orgListResponse);

    if (orgId) {
      const index = orgListResponse.findIndex((org: OrgListModel) => org.orgId === orgId);
      if (index >= 0) setOrgName(orgListResponse[index].orgName);
    }

    const userResponse = await UserService.getUserAsync(userId as string);
    setProfile(userResponse ?? undefined);
    const profileResponse: any = await UserService.getProfileDetails(
      userId as string,
      currentOrgId as string,
    );
    if (profileResponse.length > 0 && profileResponse[0]?.avatar !== '') {
      const fileReponse = await RealmRepositories.Attachments.getFilesByIds([
        profileResponse[0]?.avatar || '',
      ]);
      if (fileReponse && fileReponse.length > 0) {
        setProfilePicFile(fileReponse[0]);
      }
    }
  }, [currentOrgId, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const organizationDropdown:
    | DataItem[]
    | { sportType: SportType; label: string; onClick: () => void; isHasAvatar: boolean }[]
    | undefined = [];

  if (userOrgs && userOrgs?.length > 0) {
    userOrgs
      ?.filter((item: OrgListModel) => item.orgId !== orgId)
      ?.forEach((org: OrgListModel) => {
        organizationDropdown.unshift({
          sportType: org.sportType,
          label: org.orgName,
          onClick: async () => {
            checkUserTypeAndRedirect(userId, org.orgId);
            onClose();
          },
          isHasAvatar: true,
        });

        return true;
      });
  }

  return (
    <>
      <div
        className={styles.userItem}
        onClick={() => {
          setDropDownOpen(!dropDownOpen);
        }}
      >
        <div className={styles.itemInfo}>
          <UserAvatar
            name={`${profile?.first_name} ${profile?.last_name}`}
            src={
              profilePicFile?.file_name
                ? (Config().AWSCloudFrontUrl as string) + (profilePicFile?.file_name || '')
                : ''
            }
          />
          <div className={styles.nameInfo}>
            <span className={styles.name}>{profile?.first_name}</span>
            <span className={styles.orgName}>{orgName}</span>
          </div>
        </div>
      </div>
      {dropDownOpen && (
        <OrgSelectorDropdown
          onClose={onClose}
          dropDownOpen={dropDownOpen}
          arrowTrigger={() => setDropDownOpen(false)}
          orgList={userOrgs ?? []}
        />
      )}
    </>
  );
};

export default UserInfo;
