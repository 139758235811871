import React from 'react';
import clsx from 'clsx';
import styles from './Banner.module.scss';

interface IProps {
  content: any;
  wrapperClassName?: string;
  contentClassName?: string;
}

const Banner: React.FC<IProps> = ({ content, wrapperClassName, contentClassName }) => {
  const bannerStyle = clsx(styles.wrapper, wrapperClassName);
  const contentStyle = clsx(styles.content, contentClassName);

  return (
    <div className={bannerStyle}>
      <div className={contentStyle}>{content}</div>
    </div>
  );
};

export default Banner;
