import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Api } from 'api/Api';
import SessionProvider from 'providers/SessionProvider';
import type { RootState } from 'redux/store';
import { bookingSlice, BookingStep, ServicePositionsLeft } from 'redux/slices';
import { useOrgId, useUserId } from 'common/hooks';

import { BookingFormatter } from 'modules/book/utils/booking.helpers';
import { IAxiosRequest } from 'common/models/axios.model';
import { GuestAuthorizeModel } from 'api/models/auth.model';
import {
  GetLessonPositionsLeftModel,
  LessonValidateModel,
  PackageValidateModel,
  SelectedService,
  ServiceInvitedClient,
  ServiceValidateModel,
} from 'modules/book/models/booking.model';
import { CalculateCheckoutSummaryModel } from 'api/models/payments/calculateCheckoutSummary.model';

import ServicesService from 'data/services/services.service';
import { getUnixMilliseconds } from 'common/utils/date.helpers';
import { ServiceTypeEnum } from 'data/enums/ServiceType.enum';
import { Lesson } from 'data/entities/lessons.entity';
import { useService } from './useService';
import { useLesson } from './useLesson';

export const useSummary = () => {
  const { orgId } = useOrgId();
  const { userId, email, firstName } = useUserId();
  const {
    getServiceDetails,
    getDetailedSelectedServices,
    getUpdatedServicesWithPositions,
    isServiceTimeslotSelectionFlowNeeded,
    isAdvancedLesson,
  } = useService();
  const { getLesson } = useLesson();
  const [currentLesson, setCurrentLesson] = useState<Lesson>();
  const { lessonId } = useParams<Record<string, string>>();

  const [isValidatingService, setIsValidatingService] = useState<boolean>(false);
  const [isRefreshingPositionsLeft, setIsRefreshingPositionsLeft] = useState<boolean>(false);

  const booking = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  const {
    paymentPreference,
    checkoutSummary,
    lessonInviteId,
    serviceInviteId,
    services: selectedServices,
  } = booking.selected;

  const isLessonInvite = !!lessonInviteId;

  const selectedDetailedServices = getDetailedSelectedServices();

  const isPrepaidAndPaid = ServicesService.isPrepaidAndPaid(selectedDetailedServices);
  const isAllServiceRequiresPayment = selectedDetailedServices.every(
    (service: SelectedService) => service.isPaymentInApp === true,
  );

  const isAllServiceNotRequiresPayment = selectedDetailedServices.every(
    (service: SelectedService) => service.isPaymentInApp === false,
  );

  const isIncludeTax = selectedDetailedServices.every(
    (service: SelectedService) => service.taxRateIds?.length !== 0,
  );
  const inviteRequiresPayment = selectedDetailedServices.every((service: SelectedService) =>
    service.invitedClients?.find(
      (invite: ServiceInvitedClient) =>
        (invite.userId === userId || invite.email === email) && invite.isPaymentInApp === true,
    ),
  );
  const isPrepaidAndUpfront = selectedDetailedServices.every(
    (value: SelectedService) =>
      /**
        isUpfrontPayment is only used to check whether the user already paid or not and it's not to be confused with the is_upfront_payment from lessons schema
        TODO: update isUpfrontPayment to a better name 
      */
      value.serviceTypeEnum === ServiceTypeEnum.Package && value.isUpfrontPayment,
  );

  /** Related to positions left */
  const getLessonPositionsLeftModelInput = (): GetLessonPositionsLeftModel[] => {
    return booking.selected.services.map((service) => {
      const serviceDetails = getServiceDetails(service.id);
      return {
        orgId,
        serviceId: service.id,
        maxParticipants: serviceDetails ? serviceDetails.maxParticipants : 0,
        locationId: serviceDetails ? serviceDetails.locationIds[0] : '',
        scheduledDates: service.scheduledDates,
      };
    });
  };

  const refreshPositionsLeft = async () => {
    /** This function refreshes the redux info for positions left from the database */
    /**
     * TODO: Current for lesson invites, the positionsLeft doesn't update on the database for single occurence
     * of program and event invites. For now, we will update the value manually and investigate this issue
     * at a further date.
     */
    try {
      setIsRefreshingPositionsLeft(true);
      const existingPositionsLeft = booking.selected.positionsLeft;
      const positionLefts = await Api.ClientRoutes.Lessons.getServicesPositionsLeft(
        getLessonPositionsLeftModelInput(),
      );
      const servicePositionsLefts: ServicePositionsLeft[] = positionLefts.data;

      const updatedServices = getUpdatedServicesWithPositions(servicePositionsLefts);

      const isNotUpdated = existingPositionsLeft.every(
        (positions, index) =>
          positions.dateStart === servicePositionsLefts[index].dateStart &&
          positions.dateEnd === servicePositionsLefts[index].dateEnd &&
          positions.participants === servicePositionsLefts[index].participants &&
          positions.positionsLeft === servicePositionsLefts[index].positionsLeft,
      );

      // Update position lefts locally in confirmed page
      if (booking.bookingStep === BookingStep.Confirmed && servicePositionsLefts && isNotUpdated) {
        const locallyUpdatedPositionsLeft = existingPositionsLeft.map((positions) => {
          return {
            id: positions.id,
            dateStart: positions.dateStart,
            dateEnd: positions.dateEnd,
            participants: positions.participants + 1,
            positionsLeft: positions.positionsLeft - 1,
          };
        });
        dispatch(bookingSlice.actions.getPositionsLeft(locallyUpdatedPositionsLeft));
      } else {
        dispatch(bookingSlice.actions.getPositionsLeft(servicePositionsLefts));
      }

      // Update each selected service with the new positions left
      dispatch(bookingSlice.actions.getServices(updatedServices));
      setIsRefreshingPositionsLeft(false);
    } catch (error: any) {
      console.log(error);
      setIsRefreshingPositionsLeft(false);
    }
  };

  /** Related to timezone */
  const getCurrentTimezone = (): string => {
    return booking.selected.timezone;
  };

  const getPositionsLeftInfo = (id: string, dateStart: number, dateEnd: number) => {
    return booking.selected.positionsLeft.find(
      (position) =>
        position.id === id && position.dateStart === dateStart && position.dateEnd === dateEnd,
    );
  };

  /** Related to calculation */
  const getCheckoutCalculationInput = (newPromoCode?: string): CalculateCheckoutSummaryModel => {
    return {
      orgId,
      userId,
      promoCode: newPromoCode,
      selectedServices: booking.selected.services.map((service) => {
        const serviceDetails = getServiceDetails(service.id) as SelectedService;
        const isPurchased = ServicesService.isPurchased(serviceDetails);

        let { priceInCents } = serviceDetails;

        if (
          /**
            isUpfrontPayment is only used to check whether the user already paid or not and it's not to be confused with the is_upfront_payment from lessons schema
            TODO: update isUpfrontPayment to a better name 
          */
          isPurchased ||
          (serviceDetails.serviceTypeEnum === ServiceTypeEnum.Package &&
            !serviceDetails.isUpfrontPayment)
        ) {
          priceInCents = 0;
        }

        return {
          name: serviceDetails.title,
          priceInCents,
          count: service.count,
          taxRateIds: serviceDetails.taxRateIds,
        };
      }),
      /** Integrate this later */
      // paymentMethodId,
    };
  };

  const isPaymentRequired = () => {
    if (
      (checkoutSummary?.totalInCents !== undefined && checkoutSummary.totalInCents <= 0) ||
      isPrepaidAndPaid ||
      (isLessonInvite && !isAllServiceRequiresPayment)
    )
      return false;
    return true;
  };

  const isCreditApplied = () => {
    return (
      paymentPreference === 'in_app' &&
      checkoutSummary &&
      checkoutSummary.creditAppliedInCents &&
      checkoutSummary.creditAppliedInCents > 0
    );
  };

  const isPaymentOptionsDisplayed = () => {
    if (isCreditApplied()) {
      return false;
    }

    if (isPrepaidAndPaid) {
      return false;
    }

    if (isLessonInvite) {
      const currentService = getServiceDetails(selectedServices[0].id);
      const isPaymentInAppRequired = currentService?.isPaymentInApp;
      const isClientPaymentRequired = currentLesson?.clients[0].is_payment_in_app;
      if (isPaymentInAppRequired && isClientPaymentRequired) {
        return false;
      }
    }

    if (!isLessonInvite && !isAllServiceNotRequiresPayment) {
      return false;
    }

    if (inviteRequiresPayment) {
      return false;
    }
    return true;
  };

  const isSummaryDisplayed = () => isCreditApplied() || isPaymentRequired();

  const validateChosenDatesForUser = async (isAuth: boolean) => {
    try {
      setIsValidatingService(true);
      const detailedSelectedServices = getDetailedSelectedServices();
      // Edge case, if user has somehow gotten to booking summary when it is fully booked, we do some local validation.
      let isNoPositionsLeft = false;
      let noPositionsLeftMessage = '';

      for (let i = 0; i < detailedSelectedServices.length; i += 1) {
        const service = detailedSelectedServices[i];
        if (!isServiceTimeslotSelectionFlowNeeded(service)) {
          // validate package positions left
          if (service.packagePositionsLeft !== undefined && service.packagePositionsLeft <= 0) {
            isNoPositionsLeft = true;
            noPositionsLeftMessage = `Package ${service.title} is fully booked. Please update your booking selection`;

            break;
          }
        } else if (service.chosenDates) {
          // validate chosen lesson dates
          for (let j = 0; j < service.chosenDates.length; j += 1) {
            const chosenDate = service.chosenDates[j];

            if (chosenDate.positionsLeft !== undefined && chosenDate.positionsLeft <= 0) {
              isNoPositionsLeft = true;
              noPositionsLeftMessage = `Service ${
                service.title
              } is fully booked on ${BookingFormatter.formatDate(
                chosenDate.dateStart,
                SessionProvider.getTimeZoneInfo(),
              )}. Please update your booking selection`;

              break;
            }
          }

          if (isNoPositionsLeft) {
            break;
          }
        }
      }

      if (isNoPositionsLeft) {
        const err: IAxiosRequest = {
          response: {
            status: 0,
            statusText: '',
            data: {
              message: noPositionsLeftMessage,
              title: noPositionsLeftMessage,
              errors: [],
              Detail: '',
            },
          },
        };
        throw err;
      }

      const invitationIdParam = lessonInviteId || serviceInviteId;

      const guestAuthorize: GuestAuthorizeModel = {
        userId: '',
        email: '',
        firstName: '',
      };

      if (!isAuth) {
        guestAuthorize.userId = userId;
        guestAuthorize.email = email;
        guestAuthorize.firstName = firstName;
      }

      const apiPromises = [];

      if (isLessonInvite && email) {
        const lessonValidateModelReq: LessonValidateModel = {
          orgId,
          lessonId,
          userId,
          email,
          userInviteId: invitationIdParam ?? undefined,
        };
        if (isAuth) {
          apiPromises.push(Api.ClientRoutes.Lessons.validateLessonForUser(lessonValidateModelReq));
        } else {
          apiPromises.push(
            Api.ClientRoutes.Lessons.validateLessonForGuestUser(
              lessonValidateModelReq,
              guestAuthorize,
            ),
          );
        }
      } else {
        for (let i = 0; i < detailedSelectedServices.length; i += 1) {
          const service = detailedSelectedServices[i];
          const serviceId = detailedSelectedServices[i].id;
          if (
            email &&
            !isServiceTimeslotSelectionFlowNeeded(service) &&
            !isAdvancedLesson(service) &&
            service.isPackage
          ) {
            const { packageId } = detailedSelectedServices[i];
            const lessonsPackagesValidateModel: PackageValidateModel = {
              orgId,
              serviceId,
              packageId: packageId || '',
              userId,
              email,
              userInviteId: invitationIdParam ?? undefined,
            };

            if (isAuth) {
              apiPromises.push(
                Api.ClientRoutes.Lessons.validateLessonPackageForUser(lessonsPackagesValidateModel),
              );
            } else {
              apiPromises.push(
                Api.ClientRoutes.Lessons.validateLessonPackageForGuestUser(
                  lessonsPackagesValidateModel,
                  guestAuthorize,
                ),
              );
            }
          } else if (email) {
            const serviceValidateModel: ServiceValidateModel = {
              orgId,
              userId,
              email,
              scheduledDates: selectedServices[i].scheduledDates,
              serviceId,
              selectedCoachId: selectedServices[i].coachId,
              userInviteId: invitationIdParam ?? undefined,
            };
            if (isAuth) {
              apiPromises.push(
                Api.ClientRoutes.Lessons.validateLessonServiceForUser(serviceValidateModel),
              );
            } else {
              apiPromises.push(
                Api.ClientRoutes.Lessons.validateLessonServiceForGuestUser(
                  serviceValidateModel,
                  guestAuthorize,
                ),
              );
            }
          }
        }
      }

      await Promise.all(apiPromises);
      setIsValidatingService(false);
    } finally {
      setIsValidatingService(false);
    }
  };

  const formattedDateText = (dateStart: number, isOverdue?: boolean) => {
    const currentTime = getUnixMilliseconds();
    let dateText = '';
    if (isOverdue && dateStart < currentTime) {
      dateText = 'OVERDUE - ';
    }
    return dateText;
  };

  useEffect(() => {
    if (lessonInviteId) {
      const fetchLesson = async () => {
        const res = await getLesson();
        if (res) setCurrentLesson(res);
      };
      fetchLesson();
    }
  }, []);

  return {
    isRefreshingPositionsLeft,
    refreshPositionsLeft,
    getLessonPositionsLeftModelInput,
    getPositionsLeftInfo,
    getCheckoutCalculationInput,
    getCurrentTimezone,
    isPaymentRequired,
    isCreditApplied,
    isSummaryDisplayed,
    isPaymentOptionsDisplayed,
    isPrepaidAndUpfront,
    isPrepaidAndPaid,
    isValidatingService,
    validateChosenDatesForUser,
    isIncludeTax,
    formattedDateText,
    isAllServiceNotRequiresPayment,
    isAllServiceRequiresPayment,
  };
};
