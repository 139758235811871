import React from 'react';

import styles from './MobileDrawer.module.scss';
import LeftArrow from '../../assets/images/arrows/left-arrow.svg';
import Button from '../Button/Button';
import MobileAddButton from '../Button/MobileAddButton/MobileAddButton';

interface MobileDrawerProps {
  visible: boolean;
  title: string;
  subTitle?: string;
  headerRightButton?: string | React.ReactNode;
  cancelButtonText?: string;
  okButtonText?: string;
  onClickHeaderRightButton?: Function;
  onClose: Function;
  onClickOkButton?: Function;
  onClickAddButton?: any;
  okButtonType?: string;
  children: React.ReactNode;
  loading?: boolean;
  okButtonDisabled?: boolean;
  extraButton?: boolean;
  extraBtnLabel?: string;
  onExtraClick?: any;
  extraButtonDisabled?: boolean;
}

const MobileDrawer: React.FC<MobileDrawerProps> = ({
  visible,
  title,
  subTitle,
  headerRightButton,
  cancelButtonText,
  okButtonText,
  onClickHeaderRightButton,
  onClose,
  onClickOkButton,
  onClickAddButton,
  okButtonType,
  children,
  loading,
  okButtonDisabled = false,
  extraButton,
  extraBtnLabel,
  onExtraClick,
  extraButtonDisabled,
}) => {
  const footerStyle = cancelButtonText ? styles.footer : styles.singleButtonFooter;

  if (visible) {
    document.body.style.overflow = 'hidden';
  }
  const onCloseWindow = () => {
    document.body.style.overflow = 'auto';
    onClose();
  };

  return visible ? (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img
          className={styles.arrowLeft}
          alt="left-arrow"
          src={LeftArrow}
          onClick={() => onCloseWindow()}
        />
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
          {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
        </div>
        {headerRightButton && onClickHeaderRightButton ? (
          <span className={styles.headerRightButton} onClick={() => onClickHeaderRightButton()}>
            {headerRightButton}
          </span>
        ) : (
          <span> </span>
        )}
      </div>
      <div className={styles.content}>{children}</div>
      {onClickOkButton && (
        <div className={footerStyle}>
          {!extraButton && cancelButtonText && (
            <div className={styles.cancelButton} onClick={() => onCloseWindow()}>
              {cancelButtonText}
            </div>
          )}
          {extraButton && (
            <Button
              className={styles.okButton}
              label={extraBtnLabel ?? ''}
              type={okButtonType}
              onClick={() => {
                onExtraClick();
                document.body.style.overflow = 'auto';
              }}
              loading={loading}
              disabled={extraButtonDisabled}
            />
          )}
          {onClickOkButton && (
            <Button
              label={okButtonText || 'Ok'}
              className={styles.okButton}
              type={okButtonType}
              onClick={() => {
                onClickOkButton();
                document.body.style.overflow = 'auto';
              }}
              loading={loading}
              disabled={okButtonDisabled}
            />
          )}
        </div>
      )}
      {onClickAddButton && <MobileAddButton onClick={onClickAddButton} showBtn />}
    </div>
  ) : null;
};

export default MobileDrawer;
