import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { BrandThemeContext } from 'common/context';
import { now } from 'common/utils/date.helpers';
import { useMobileViewDetector } from 'common/hooks';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface IProps {
  lessons: number[];
  practiceLessons: number[];
  events: number[];
  months: number[];
}

const BarCharts = ({ lessons, practiceLessons, events, months }: IProps) => {
  const theme = useContext(BrandThemeContext);
  const labels = months.map((month) => now().set({ month }).toFormat('MMMM'));
  const { isViewedFromTablet } = useMobileViewDetector();

  const data: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: 'Lessons',
        data: lessons,
        backgroundColor: theme.colors.jungleGreen.opacity_100,
        borderRadius: 5,
      },
      {
        label: 'Events',
        data: events,
        backgroundColor: theme.colors.argentinianBlue.opacity_100,
        borderRadius: 5,
      },
      {
        label: 'Practice',
        data: practiceLessons,
        backgroundColor: theme.colors.utOrange.opacity_100,
        borderRadius: 5,
      },
    ],
  };
  const options: ChartOptions<'bar'> = {
    plugins: {
      title: {
        display: false,
        text: 'Productivity Summary',
      },
      legend: {
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          useBorderRadius: true,
          borderRadius: 10,
        },
        title: {
          display: true,
          text: '',
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: theme.colors.jungleGreen.opacity_100,
          display: true,
          backdropColor: theme.colors.white,
          showLabelBackdrop: true,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          drawOnChartArea: true,
          circular: true,
          tickBorderDash: [5, 5],
          tickBorderDashOffset: 0.5,
          tickLength: 25,
        },
        ticks: {
          color: theme.colors.jungleGreen.opacity_100,
          display: true,
          backdropPadding: 10,
          showLabelBackdrop: false,
          callback(value: any) {
            return value;
          },
        },
        border: {
          display: false,
        },
      },
    },
  };

  return (
    <Box minHeight={isViewedFromTablet ? 320 : 546}>
      <Bar data={data} options={options} />
    </Box>
  );
};

export default BarCharts;
