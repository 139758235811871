import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isAfter } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import { LessonsTabs } from 'modules/lesson/models/lessons.model';
import styles from './PastLessons.module.scss';
import ListView from '../../../../components/Pages/Items/ListView/ListView';
import { Lesson } from '../../../../data/entities/lessons.entity';
import SessionProvider from '../../../../providers/SessionProvider';
import LessonsService from '../../../../data/services/lessons.service';
import { SubscriptionsHelper } from '../../../../common/utils/subscriptions.helper';
import { UserSubscriptionsModel } from '../../../../data/models/userSubscription.mode';
import { getUnixMilliseconds } from '../../../../common/utils/date.helpers';

interface PastLessonProps {
  subscriptionInfo?: UserSubscriptionsModel;
}

const PastLessons = (props: PastLessonProps) => {
  const { subscriptionInfo } = props;
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const { orgId } = useParams<Record<string, string | undefined>>();
  const history = useHistory();

  const fetchLessons = useCallback(async () => {
    try {
      const currentOrgId = orgId ?? '';
      const currentUserId = SessionProvider.getUserId();

      const response = await LessonsService.getUserAcceptedLessonsByOrdId(
        currentUserId,
        currentOrgId,
      );

      if (response?.length) {
        setLessons(response);
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [orgId]);

  useEffect(() => {
    fetchLessons();
  }, [fetchLessons]);

  const filteredLessons = useMemo(
    () =>
      lessons
        ?.filter((lesson: Lesson) => isAfter(getUnixMilliseconds(), lesson.date_start))
        .sort((a, b) => {
          return isAfter(a.date_start, b.date_start) ? -1 : 1;
        })
        ?.slice(0, 4),
    [lessons],
  );

  const onLessonClick = (id: string) => {
    history.push(`/org/${orgId}/lesson/${id}`);
  };

  const showListItem = (lesson: Lesson, i: number) => {
    return SubscriptionsHelper.showListItem(i, subscriptionInfo);
  };

  const onAllPastLessons = () => {
    history.push({
      pathname: `/org/${orgId}/lessons`,
      state: { tab: LessonsTabs.Past },
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.lessons}>
        <ListView
          source={filteredLessons}
          onItemClick={onLessonClick}
          emptyListText=""
          showListItem={showListItem}
        />
        {filteredLessons.length > 0 && (
          <div className={styles.listLessons}>
            <a onClick={onAllPastLessons} style={{ cursor: 'pointer' }}>
              See all past lessons...
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default PastLessons;
