import { createSlice } from '@reduxjs/toolkit';
import { AuthenticationState } from './types';
import {
  changeSignInOpen,
  changeSignUpOpen,
  updateGuestModel,
  updateIsHeaderVisible,
  updateIsAuthenticated,
  updateAuthenticatedUser,
  updateInvitedUser,
  loadWholeAuthenticationState,
} from './actions';

const initialState: AuthenticationState = {
  authenticatedUser: null,
  invitedUser: null,
  isAuthenticated: false,
  isVerifyingToken: false,

  signInOpen: false,
  signUpOpen: false,
  isHeaderVisible: false,
  guestModel: {
    userId: '',
    email: '',
    firstName: '',
  },
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    changeSignInOpen,
    changeSignUpOpen,
    updateGuestModel,
    updateIsHeaderVisible,
    updateIsAuthenticated,
    updateAuthenticatedUser,
    updateInvitedUser,
    loadWholeAuthenticationState,
  },
});

export * from './types';
export * from './actions';
