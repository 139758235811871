import React from 'react';
import listViewStyles from '../Pages/Items/ListView/ListView.module.scss';
import attachmentStyles from '../../modules/attachment/components/AttachmentsThumbnailsList/AttachmentsThumbnailsList.module.scss';
import Button from '../Button/Button';
import DateList from './DateList/DateList';
import { SubscriptionsHelper } from '../../common/utils/subscriptions.helper';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export interface HiddenListProps {
  index: number;
  showUpgradePlanButtonIndex?: number;
  subscriptionText: string;
}
const HiddenListItem = ({
  index,
  showUpgradePlanButtonIndex = -1,
  subscriptionText,
}: HiddenListProps) => {
  return (
    <>
      {showUpgradePlanButtonIndex === index && (
        <div className={listViewStyles.upgradeButtonWrapper}>
          <Button
            className={listViewStyles.upgradeButton}
            label="Upgrade plan"
            onClick={SubscriptionsHelper.redirectToBillingPortalSubscriptions}
          />
          <p>{subscriptionText}</p>
        </div>
      )}
      <div
        key={index}
        className={listViewStyles.item}
        style={{ marginTop: showUpgradePlanButtonIndex === index ? '-100px' : 0 }}
      >
        <div className={listViewStyles.privateContent}>
          <DateList dateUnixMilli={getUnixMilliseconds()} />
          <div className={listViewStyles.titleColumn}>
            <div className={listViewStyles.title}>xxx xxxx</div>
            <div className={attachmentStyles.wrapper}>
              {[{ thumb_url: 'https://i.pravatar.cc/150' }].map((thumb, i) => (
                <img
                  key={i}
                  className={attachmentStyles.thumbnail}
                  alt="thumb"
                  src={thumb.thumb_url}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HiddenListItem;
