import React, { useContext, useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { BrandThemeContext } from 'common/context';
import { useMobileViewDetector, useOrgId, useUserId } from 'common/hooks';

import type { FeedItem } from 'modules/home/pagesV2/types';
import { Lesson } from 'data/entities/lessons.entity';
import LessonsService from 'data/services/lessons.service';

import { Grid } from 'components/Grid';
import FeedCard from 'modules/home/components/FeedCard';

const ActivityFeed = () => {
  const theme = useContext(BrandThemeContext);
  const { userId } = useUserId();
  const { orgId } = useOrgId();
  const { isViewedFromMobile, isViewedFromTablet } = useMobileViewDetector();

  const [activityFeed, setActivityFeed] = React.useState<Lesson[] | Event[] | any>([]);

  const fetchActivityFeeds = async () => {
    /** Activity feeds doesn't have coaches object sometimes */
    const activityFeedResult = await LessonsService.getActivityFeed(userId, orgId);
    setActivityFeed(activityFeedResult);
  };

  const memoisedFeed = useMemo(
    () => activityFeed.map((item: FeedItem) => <FeedCard key={item._id} feedItem={item} />),
    [activityFeed],
  );

  useEffect(() => {
    fetchActivityFeeds();
  }, []);

  const styles = {
    heading: {
      typography: isViewedFromMobile ? 'h6' : 'h5',
      fontWeight: 700,
    },
    padding: {
      padding: isViewedFromMobile ? '1rem' : '1rem 2.5rem',
    },
  };

  return (
    <Grid item md={5.5} xs={12} order={isViewedFromTablet ? 1 : 2}>
      <Typography sx={{ ...styles.heading, ...styles.padding }}>Activity feed</Typography>
      {activityFeed.length === 0 ? (
        <Typography textAlign="left" color={theme.colors.black.opacity_40}>
          You have no lessons, practice, or events. Book a lesson to begin.
        </Typography>
      ) : (
        <Box
          bgcolor={theme.colors.black.opacity_5}
          padding="1rem"
          borderRadius={8}
          margin={isViewedFromMobile ? '0 1rem' : '0 2rem'}
        >
          <Stack
            maxHeight={560}
            gap="1rem"
            // Add padding if element is scrollable
            paddingRight={activityFeed.length > 2 ? '0.8rem' : 0}
            sx={{ overflowY: 'scroll' }}
          >
            {memoisedFeed}
          </Stack>
        </Box>
      )}
    </Grid>
  );
};

export default ActivityFeed;
