import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';

/** Common */
import { useMobileViewDetector, useUserId } from 'common/hooks';
import StaticDictionary from 'common/utils/staticDictionary.helper';
import { StaticRoutes } from 'common/utils/routes/StaticRoutes.helper';
import Logger from 'middleware/logger.middleware';

/** Data */
import SessionProvider from 'providers/SessionProvider';
import { BrandThemeContext } from 'common/context';
import { EventType, Lesson, LessonClient } from 'data/entities/lessons.entity';
import { User } from 'data/entities/users.entity';
import UserService from 'data/services/user.service';
import ServicesService from 'data/services/services.service';
import LessonsService from 'data/services/lessons.service';
import { ServiceTypeEnum } from 'data/enums/ServiceType.enum';
import { ServiceInvitedClient, Services } from 'data/entities/service.entity';
import { OrgLocation } from 'data/entities/orgLocations.entity';
import { BookingFormatter } from 'modules/book/utils/booking.helpers';
import type { FeedItem } from 'modules/home/pagesV2/types';

/** Components */
import rightIcon from 'assets/icons/navbar-icons/right-arrow.svg';
import { LessonInfoWithIcon } from 'modules/book/pages/BookingPageV2/components/lessons/commonV2/LessonInfoWithIcon';
import StyledChip from 'modules/home/components/StyledComponents/Chip';
import { Card } from 'components/CardV2';
import { useLocation } from 'modules/home/pagesV2/hooks/useLocation';
import { useCoaches } from 'modules/home/pagesV2/hooks/useCoaches';
import { CoachImageAndText } from 'modules/service/models/service.model';

interface FeedCardProps {
  feedItem: FeedItem;
  handleModal?: Function;
  handleCurrentFeed?: (feed: FeedItem) => void;
  /**
   * NOTE:
   * The following codes are being referenced in other files so they should be kept as they are
   */
  /* eslint-disable */
  deleteEvent?: any;
  cancelLesson?: any;
  /* eslint-enable */
}

const FeedCard: React.FC<FeedCardProps> = ({ feedItem, handleModal, handleCurrentFeed }) => {
  const theme = useContext(BrandThemeContext);
  const history = useHistory();
  const { userId, email } = useUserId();
  const { isViewedFromMobile } = useMobileViewDetector();
  const { getLocationAddressById, addressStringBuilder } = useLocation();
  const { getCoaches } = useCoaches();

  type FeedCoach = Partial<CoachImageAndText & User>;
  const [coaches, setCoaches] = useState<FeedCoach[]>([]);
  const [addressLabel, setAddressLabel] = useState<string>('');
  const [dateLabel, setDateLabel] = useState<string>('');

  /** Left header border color switch case, need to refactor later according to color code */
  const generateBorderColor = () => {
    let borderColor;
    if (feedItem.service_type_enum) {
      switch (feedItem.service_type_enum) {
        case ServiceTypeEnum.Lesson:
          borderColor = theme.colors.black.opacity_100;
          break;
        case ServiceTypeEnum.Package:
          borderColor = theme.colors.jungleGreen.opacity_100;
          break;
        case ServiceTypeEnum.Event:
          borderColor = theme.colors.selectiveYellow.opacity_100;
          break;
        case ServiceTypeEnum.Programme:
          borderColor = theme.colors.argentinianBlue.opacity_100;
          break;
        default:
          break;
      }
    } else {
      switch (feedItem.type) {
        case EventType.Lesson:
          borderColor = theme.colors.slateBlue.opacity_50;
          break;
        case EventType.Event:
          borderColor = theme.colors.yinMnBlue.opacity_50;
          break;
        case EventType.LessonInvite:
          borderColor = theme.colors.argentinianBlue.opacity_50;
          break;
        case EventType.Service:
          borderColor = theme.colors.selectiveYellow.opacity_100;
          break;
        default:
          break;
      }
    }
    return borderColor;
  };

  const formatTitle = (feedItemParam: FeedItem) => {
    let titleText = feedItemParam.title;
    if (feedItemParam.type === EventType.Service) {
      if (ServicesService.isOverdue(feedItemParam)) titleText += ' OVERDUE';
      if ((feedItemParam as Services).is_fully_booked && handleCurrentFeed && handleModal)
        titleText += ' FULLY BOOKED';
    }
    if (feedItemParam.type === EventType.Lesson || feedItemParam.type === EventType.LessonInvite) {
      if (LessonsService.isOverdue(feedItemParam)) titleText += ' OVERDUE';
      if ((feedItemParam as Services).is_fully_booked && handleCurrentFeed && handleModal)
        titleText += ' FULLY BOOKED';
    }

    return titleText;
  };

  /** This function is used if we cannot find the locations/addresses in redux */
  const findAddress = (id: string) => {
    const location = feedItem.locations?.locations.find(
      (item: OrgLocation) => item.location_id === id,
    );
    if (location) return location.address;
    return undefined;
  };

  const onOpenActivityFeed = () => {
    switch (feedItem.type) {
      case EventType.Lesson:
        history.push(`/org/${feedItem.org_id}/lesson/${feedItem._id}`);
        break;
      case EventType.Event:
        history.push(`/org/${feedItem.org_id}/events`);
        break;
      case EventType.Service:
        {
          const service = feedItem as Services;
          const serviceInvitedClient = service.invited_clients?.find(
            (invitedClient: ServiceInvitedClient) =>
              invitedClient.user_id === userId || invitedClient.email === email,
          );
          const link = `${StaticRoutes.Org}/${feedItem.org_id}/service/${feedItem._id}?${StaticDictionary.UrlParameters.Booking.InvitationId}=${serviceInvitedClient?.invite_id}`;
          history.push(link);
        }
        break;
      case EventType.LessonInvite:
        {
          const lessonInvite = feedItem as Lesson;
          const lessonClient = lessonInvite.clients.find(
            (lessonInvitedClient: LessonClient) => lessonInvitedClient.user_id === userId,
          );
          const link = `${StaticRoutes.Org}/${lessonInvite.org_id}/lesson/${lessonInvite._id}/invite?${StaticDictionary.UrlParameters.Booking.InvitationId}=${lessonClient?.invite_id}`;
          history.push(link);
        }
        break;
      default:
        if (Logger.isDevEnvironment) {
          console.error('feedItem.type not supported', feedItem.type);
        }
        break;
    }
  };

  const fetchCoaches = async () => {
    /** Grab coaches from redux first */
    const storeCoaches = getCoaches(feedItem?.coaches);
    if (storeCoaches.length > 0) {
      setCoaches(storeCoaches ?? []);
    } else {
      /** If no coaches in redux, fetch coach from API call */
      const getCoachNames = await UserService.getNamesByIds(feedItem?.coaches ?? []);
      setCoaches(getCoachNames ?? []);
    }
  };

  const fetchDate = async () => {
    /** need to refactor this code later */
    if (
      feedItem.type === EventType.Service &&
      feedItem.scheduled_dates &&
      feedItem.scheduled_dates.length > 0
    ) {
      const dateLabelServiceString = `${BookingFormatter.formatDate(
        feedItem?.scheduled_dates[0].date_start,
        SessionProvider.getTimeZoneInfo(),
      )} - ${BookingFormatter.formatDate(
        feedItem?.scheduled_dates[0].date_end,
        SessionProvider.getTimeZoneInfo(),
      )}`;

      setDateLabel(dateLabelServiceString);
    } else if (feedItem.type !== EventType.Service) {
      const dateLabelString = `${BookingFormatter.formatDate(
        feedItem?.date_start ? feedItem?.date_start : feedItem?.date_begin,
        SessionProvider.getTimeZoneInfo(),
      )} - ${BookingFormatter.formatDate(feedItem?.date_end, SessionProvider.getTimeZoneInfo())}`;

      setDateLabel(dateLabelString);
    } else {
      setDateLabel('');
    }
  };

  const fetchAddress = async () => {
    /** Grab the location Id */
    const itemAddress = feedItem.location_id ?? feedItem.location_ids[0];
    if (itemAddress) {
      /** Try to fetch address from redux store */
      const addressFromRedux = getLocationAddressById(itemAddress);
      if (addressFromRedux) setAddressLabel(addressStringBuilder(addressFromRedux, true));
      else if (feedItem.locations?.locations) {
        /** If address isn't in store, use local feedItem address */
        const localFeedItemAddress = findAddress(itemAddress);
        if (localFeedItemAddress) setAddressLabel(addressStringBuilder(localFeedItemAddress, true));
      }
    } else {
      setAddressLabel('');
    }
  };

  useEffect(() => {
    fetchCoaches();
    fetchDate();
    fetchAddress();
  }, [feedItem]);

  /** Replace scss with css in js */
  const styles = {
    title: {
      fontSize: isViewedFromMobile ? 'subtitle2' : 'body2',
      fontWeight: 600,
      lineHeight: '20px',
      display: '-webkit-box',
      WebkitLineClamp: '2',
      WebkitBoxOrient: 'vertical',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      wordBreak: 'break-all',
    },
    titleStack: {
      gap: 0.5,
      borderLeft: `3px solid ${generateBorderColor()}`,
      paddingX: '0.5rem',
    },
    button: {
      width: '32px',
      height: '32px',
      minWidth: '32px',
      background: theme.colors.jungleGreen.opacity_100,
      color: theme.colors.white,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&:hover': {
        bgcolor: theme.colors.jungleGreen.opacity_75,
      },
    },
    hideButton: {
      width: 'fit-content',
      cursor: 'pointer',
      bgcolor: theme.colors.black.opacity_10,
      padding: '4px 8px',
      borderRadius: '4px',
      fontSize: '10px',
      fontWeight: 'bold',
      marginBottom: '-10px',
    },
  };

  return (
    <Card contentPadding="1.5rem 1rem 0rem">
      <Box justifyContent="space-between">
        {/* Header */}
        <Box display="flex" justifyContent="space-between">
          <Stack sx={styles.titleStack}>
            <Typography sx={styles.title}>{formatTitle(feedItem)}</Typography>
            <Box>
              {coaches.map((item: FeedCoach) => (
                <StyledChip
                  size="small"
                  key={item.id ?? item._id}
                  label={`${item.firstName ?? item.first_name} ${item.lastName ?? item.last_name}`}
                />
              ))}
            </Box>
          </Stack>
          <Box onClick={onOpenActivityFeed} sx={styles.button}>
            <img style={{ filter: 'invert(1)' }} alt="left" src={rightIcon} />
          </Box>
        </Box>
      </Box>
      {/** Footer Content */}
      <Stack display="flex" gap={1}>
        {dateLabel !== '' && (
          <LessonInfoWithIcon key={feedItem._id} type="clock" texts={[dateLabel]} fontSize="12px" />
        )}
        <LessonInfoWithIcon type="location" texts={[addressLabel]} fontSize="12px" />

        {handleCurrentFeed && handleModal && (
          <Box
            onClick={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
              event.stopPropagation();
              handleCurrentFeed(feedItem);
              handleModal(true);
            }}
            sx={styles.hideButton}
          >
            Hide
          </Box>
        )}
      </Stack>
    </Card>
  );
};
export default FeedCard;
