/* eslint-disable no-restricted-syntax */
import { OrgBookingRules } from 'data/entities/orgBookingRules.entity';
import { Config } from '../../config';
import { Organization as OrganizationModel } from '../entities/organization.entity';
import { Client, OrgClient } from '../entities/orgClients.entity';
import { BaseRepo } from './base.repo';

export class OrganizationRepository extends BaseRepo {
  // #region Private Properties
  private orgCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection<OrganizationModel>('organization');

  private orgClientCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection<OrgClient>('org_clients');

  private orgBookingRulesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection<OrgBookingRules>('org_booking_rules');

  // #endregion

  // #region Public Methods
  async getOrgByIdAsync(orgId: string) {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.orgCollection = mongo
        ?.db(Config().RealmDbName as string)
        .collection<OrganizationModel>('organization');
    }

    const orgResult = await this.orgCollection?.findOne({ _id: orgId });
    return orgResult;
  }

  async getOrgByIdAsGuestAsync(orgId: string) {
    this.orgCollection = await this.AllowAnonymousQuery('organization', this.orgCollection!);
    return this.getOrgByIdAsync(orgId);
  }

  async getOrgsByIds(ids: string[]): Promise<OrganizationModel[] | undefined> {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.orgCollection = mongo
        ?.db(Config().RealmDbName as string)
        .collection<OrganizationModel>('organization');
    }

    return this.orgCollection?.find({
      _id: { $in: ids },
    });
  }

  async addUserToOrgClientsAsync(orgId: string, client: Client) {
    return this.orgClientCollection?.updateOne(
      {
        org_id: orgId,
        'clients.user_id': { $ne: client.user_id },
      },
      { $push: { clients: client } },
    );
  }

  async getOrganizationSportType(orgId: string): Promise<number | undefined> {
    const result = await this.orgCollection?.findOne(
      { _id: orgId },
      { projection: { sport_type_enum: 1 } },
    );
    return result?.sport_type_enum;
  }

  public async getBookingRules(orgId: string): Promise<OrgBookingRules | null | undefined> {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.orgBookingRulesCollection = mongo
        .db(Config().RealmDbName as string)
        .collection('org_booking_rules');
    }

    return this.orgBookingRulesCollection?.findOne({ org_id: orgId });
  }
  // #endregion
}
