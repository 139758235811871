import React, { useContext, useState, useEffect } from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { get } from 'lodash';

import { BrandThemeContext } from 'common/context';
import { useMobileViewDetector, useOrgId, useUserId } from 'common/hooks';

import { ProductivityChartData } from 'data/entities/lessons.entity';
import LessonsService from 'data/services/lessons.service';

import RadarChart from 'components/ChartsV2/RadarChart/RadarChart';
import BarCharts from 'components/ChartsV2/BarChart/BarCharts';

const Charts = () => {
  const theme = useContext(BrandThemeContext);
  const { userId } = useUserId();
  const { orgId } = useOrgId();

  const { isViewedFromMobile, isViewedFromTablet } = useMobileViewDetector();
  /** Chart Data */
  const [chartData, setChartData] = useState<ProductivityChartData>();
  const fetchChart = async () => {
    if (!chartData) {
      setChartData(await LessonsService.getProductivityData(userId, orgId as string));
    }
  };

  useEffect(() => {
    fetchChart();
  }, []);

  const styles = {
    title: {
      typography: isViewedFromMobile ? 'h5' : 'h4',
      fontWeight: 600,
    },
    subtitle: {
      typography: isViewedFromMobile ? 'subtitle2' : 'subtitle1',
      fontWeight: 400,
      color: theme.colors.black.opacity_50,
    },
    heading: {
      typography: isViewedFromMobile ? 'h6' : 'h5',
      fontWeight: 700,
    },
    padding: {
      padding: isViewedFromMobile ? '1rem' : '1rem 2.5rem',
    },
  };

  return (
    <Stack
      gap="1rem"
      flexDirection={isViewedFromTablet ? 'column' : 'row'}
      padding={styles.padding}
    >
      <Box flex={1}>
        <Typography sx={styles.heading} paddingY="1rem">
          Complete athlete
        </Typography>
        <Stack
          justifyContent="center"
          alignItems="center"
          bgcolor={theme.colors.white}
          boxShadow="3px 3px 8px rgba(100, 111, 146, 0.15)"
          borderRadius="1rem"
          padding="1rem"
        >
          <RadarChart />
        </Stack>
      </Box>
      <Box flex={1} minHeight="100%" overflow="hidden">
        <Typography sx={styles.heading} paddingY="1rem">
          Lessons and events
        </Typography>
        <Box bgcolor={theme.colors.white} borderRadius="1rem" padding="1rem" height="100%">
          <BarCharts
            lessons={get(chartData, 'lessons', [])}
            practiceLessons={get(chartData, 'practiceLessons', [])}
            events={get(chartData, 'events', [])}
            months={get(chartData, 'months', [])}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default Charts;
