import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { bookingSlice, applicationSlice, authenticationSlice } from './slices';

const persistBookingConfig = {
  key: 'booking',
  storage,
};

const persistApplicationConfig = {
  key: 'application',
  storage,
};

const persistAuthenticationConfig = {
  key: 'authentication',
  storage,
};

const persistedBookingReducer = persistReducer(persistBookingConfig, bookingSlice.reducer);
const persistedApplicationReducer = persistReducer(
  persistApplicationConfig,
  applicationSlice.reducer,
);
const persistedAuthenticationReducer = persistReducer(
  persistAuthenticationConfig,
  authenticationSlice.reducer,
);

export const store = configureStore({
  reducer: {
    booking: persistedBookingReducer,
    application: persistedApplicationReducer,
    authentication: persistedAuthenticationReducer,
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
