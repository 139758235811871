import { Config } from '../../config';
import { UserDetail } from '../entities/profile.entity';
import { BaseRepo } from './base.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export class UserDetailsRepository extends BaseRepo {
  // #region Private Properties
  private userDetailsCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('user_details');
  // #endregion

  // #region Public Methods
  async getProfileDetails(userId: string, orgId: string): Promise<UserDetail[] | undefined> {
    const result: any = await this.userDetailsCollection?.find({
      // TODO: 13.07.23 - Refactor - Should be findOne.
      org_id: orgId,
      user_id: userId,
    });
    return result;
  }

  /**
   * Only upserts document if no matching document of user_id & org_id is found.
   * @param userDetails
   * @returns
   */
  async createUserDetails(userDetails: UserDetail) {
    return this.userDetailsCollection?.findOneAndUpdate(
      {
        user_id: userDetails.user_id,
        org_id: userDetails.org_id,
      },
      {
        $setOnInsert: userDetails,
      },
      {
        upsert: true,
      },
    );
  }

  async updateUserDetails(userDetails: UserDetail) {
    try {
      // eslint-disable-next-line
      const {
        _id,
        gender_enum,
        address,
        contact,
        date_of_birth,
        birth_place,
        occupation,
        allergies,
        medication,
        emergency_contacts,
        practice_locations,
      } = userDetails;

      const height = userDetails.height?.value
        ? {
            ...userDetails.height,
            value: {
              $numberDouble: userDetails.height?.value.toString(),
            },
          }
        : undefined;

      const weight = userDetails.weight?.value
        ? {
            ...userDetails.weight,
            value: {
              $numberDouble: userDetails.weight?.value.toString(),
            },
          }
        : undefined;

      await this.userDetailsCollection?.updateOne(
        { _id },
        {
          $set: {
            gender_enum,
            address,
            contact,
            date_of_birth,
            birth_place,
            occupation,
            weight,
            height,
            allergies,
            medication,
            emergency_contacts,
            practice_locations,
            date_updated: getUnixMilliseconds(),
          },
        },
      );
    } catch (e) {
      console.log(e);
    }
  }

  async updateProfilePic(id: string, fileId: string) {
    return this.userDetailsCollection?.updateOne(
      { _id: id },
      {
        $set: {
          avatar: fileId,
        },
      },
    );
  }
  // #endregion
}
