export interface Dominations {
  eye: DominationsEnum;
  hand: DominationsEnum;
  leg: DominationsEnum;
}

export enum DominationsEnum {
  Right = 0,
  Left = 1,
  Ambidextrous = 2,
}

/* eslint-disable camelcase */
export interface SportInformationModel {
  _id: string;
  user_id: string;
  org_id: string;
  position?: string;
  sport_club?: string;
  previous_coach?: string;
  previous_sports?: string;
  other_sports?: string;
  visible_skills: boolean;
  goals?: string;
  issues?: string;
  dominations: Dominations;
  date_updated: number;
  date_created: number;
  deleted: boolean;
}

export const emptySportInfo: SportInformationModel = {
  _id: '',
  user_id: '',
  org_id: '',
  position: '',
  sport_club: '',
  previous_coach: '',
  previous_sports: '',
  other_sports: '',
  visible_skills: false,
  goals: '',
  issues: '',
  dominations: {
    eye: 0,
    hand: 0,
    leg: 0,
  },
  date_created: 0,
  date_updated: 0,
  deleted: false,
};
