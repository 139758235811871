import { PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from './types';
import { OrgBookingRules } from '../../../modules/book/models/booking.model';
import { Organization } from '../../../data/entities/organization.entity';

export const loadWholeApplicationState = (
  state: ApplicationState,
  action: PayloadAction<ApplicationState>,
) => {
  state = action.payload;
};

export const displayNotification = (
  state: ApplicationState,
  action: PayloadAction<Pick<ApplicationState['notification'], 'content' | 'severity'>>,
) => {
  state.notification = {
    ...action.payload,
    open: true,
  };
};

export const closeNotification = (state: ApplicationState) => {
  state.notification = {
    open: false,
    content: '',
    severity: 'info',
  };
};

export const getOrgId = (state: ApplicationState, action: PayloadAction<string>) => {
  state.active.orgId = action.payload;
};

export const getOrgBookingRules = (
  state: ApplicationState,
  action: PayloadAction<OrgBookingRules>,
) => {
  state.active.orgBookingRules = action.payload;
};

export const getOrgDetails = (state: ApplicationState, action: PayloadAction<Organization>) => {
  state.active.orgDetails = action.payload;
};
