import { Attachment } from '../endpoints/client/Attachment.endpoint';
import { Auth } from '../endpoints/client/Auth.endpoint';
import { Booking } from '../endpoints/client/Booking.endpoint';
import { Lessons } from '../endpoints/client/Lessons.endpoint';
import { BaseApi } from '../base/Api.base';
import { Config } from '../../config';
import { Org } from '../endpoints/client/Org.endpoint';
import { Payments } from '../endpoints/client/Payments.endpoint';
import { Balance } from '../endpoints/client/Balance.endpoint';

export default class ClientRoutes {
  private static api = new BaseApi(Config().BaseClientURL);

  // Endpoints

  public Auth = new Auth(ClientRoutes.api);

  public Lessons = new Lessons(ClientRoutes.api);

  public Attachment = new Attachment(ClientRoutes.api);

  public Booking = new Booking(ClientRoutes.api);

  public Org = new Org(ClientRoutes.api);

  public Payments = new Payments(ClientRoutes.api);

  public Balance = new Balance(ClientRoutes.api);
}
