import React from 'react';

import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import styles from './DeleteEventView.module.scss';
import { OkButtonType } from '../../../../components/Button/Button';
import { EventType } from '../../../../data/entities/lessons.entity';

interface DeleteEventViewProps {
  visible: boolean;
  onClose: Function;
  onDeleteEvent: Function;
  type: EventType;
}

const DeleteEventView: React.FC<DeleteEventViewProps> = ({
  visible,
  onClose,
  onDeleteEvent,
  type,
}) => {
  const typeName = EventType[type];
  const title = `Are you sure you want to remove this ${typeName}?`;
  const btnLabel = `Delete ${typeName}`;

  return (
    <>
      <div className={styles.visibleDesktop}>
        <ModalWindow
          show={visible}
          title={title}
          saveBtnLabel={btnLabel}
          okButtonType={OkButtonType.Danger}
          onCloseClick={onClose}
          onSaveClick={() => {
            onDeleteEvent();
            onClose();
          }}
        >
          <span />
        </ModalWindow>
      </div>
      <div className={styles.visibleMobile}>
        <MobileDrawer
          visible={visible}
          title={btnLabel}
          onClose={onClose}
          okButtonText={btnLabel}
          cancelButtonText="Cancel"
          onClickOkButton={() => {
            onDeleteEvent();
            onClose();
          }}
          okButtonType={OkButtonType.Danger}
        >
          <div>
            <div className={styles.title}>
              <title />
            </div>
          </div>
        </MobileDrawer>
      </div>
    </>
  );
};

export default DeleteEventView;
