/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { Auth } from 'aws-amplify';
import * as Realm from 'realm-web';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { find } from 'lodash';
import { redirectToOrgWithUrl } from '../common/utils/common.helpers';
import { Config } from '../config';
import Spinnerv2 from '../components/Spinnerv2/Spinnerv2';
import { StaticRoutes } from '../common/utils/routes/StaticRoutes.helper';
import SessionProvider from '../providers/SessionProvider';
import { AuthLocalStorage } from '../modules/auth/utils/auth.helper';
import { OrgListModel } from '../data/entities/organization.entity';
import OrganizationService from '../data/services/organization.service';

interface IProps extends RouteComponentProps, ReactCookieProps {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

export default (ComposedComponent: any, componentProps?: any) => {
  const RequiresOrgRoute = (props: IProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dataHasBeenFetched, setDataHasBeenFetched] = useState(false);
    const [userOrgsList, setUserOrgsList] = useState<OrgListModel[]>();
    const { orgId } = useParams<Record<string, string | undefined>>();
    const { history } = props;

    const getUserSelectedOrg = useCallback((userOrgList: OrgListModel[]) => {
      let userSelectedOrg = userOrgList[0].orgId;
      const userLocalStorageOrg = AuthLocalStorage.getSelectedOrg;
      if (userLocalStorageOrg) {
        const doesLocalOrgExist = find(userOrgList, (x) => x.orgId === userLocalStorageOrg);
        if (doesLocalOrgExist) {
          userSelectedOrg = doesLocalOrgExist.orgId;
        }
      }

      return userSelectedOrg;
    }, []);

    const validateUserOrgs = useCallback(
      (userOrgsListParam: OrgListModel[]) => {
        if (!userOrgsListParam || userOrgsListParam.length === 0) {
          // TODO: 04.04.23 - Currently we don't show any organizations too allow the user to book something that allow guest bookings.
          history.push(`${StaticRoutes.NoOrgs}`);

          return;
        }

        let doesOrgIdExistInUserOrgs = false;
        for (let i = 0; i < userOrgsListParam.length; i += 1) {
          if (userOrgsListParam[i].orgId === orgId) {
            doesOrgIdExistInUserOrgs = true;
            break;
          }
        }

        if (!doesOrgIdExistInUserOrgs) {
          const userSelectedOrg = getUserSelectedOrg(userOrgsListParam);
          history.push(`${StaticRoutes.Org}/${userSelectedOrg}`);
        }
      },
      [getUserSelectedOrg, history, orgId],
    );

    const selectOrgForUser = useCallback(
      (orgListModel: OrgListModel[]) => {
        try {
          setIsLoading(true);
          validateUserOrgs(orgListModel);
          if (orgListModel && orgListModel.length > 0) {
            const defaultOrgId = orgListModel[0];

            if (orgListModel.length === 1) {
              AuthLocalStorage.setSelectedOrg(defaultOrgId.orgId);
              return defaultOrgId.orgId;
            }

            const userSelectedOrg = getUserSelectedOrg(orgListModel);
            return userSelectedOrg;
          }

          return '';
        } catch (error) {
          return orgListModel[0].orgId;
        } finally {
          setIsLoading(false);
        }
      },
      [getUserSelectedOrg, validateUserOrgs],
    );

    const fetchUserOrgsAsync = useCallback(async () => {
      const app = Realm.App.getApp(Config().RealmAppId as string);
      try {
        setIsLoading(true);
        const userId = SessionProvider.getUserId();
        if (!userId) {
          if (app.currentUser) {
            if (SessionProvider.getAccessToken()) {
              AuthLocalStorage.removeAuthLocalStorage();
              SessionProvider.removeSession();
            }
            await app.removeUser(app.currentUser);
            await Auth.signOut();
            const fromString = history.location.pathname + history.location.search;
            history.push('/login', { from: fromString });
          }
          return;
        }
        const response: OrgListModel[] = await OrganizationService.getUserOrgList(userId);
        setUserOrgsList(response);
        selectOrgForUser(response);
      } catch (error: any) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
        setDataHasBeenFetched(true);
      }
    }, [history, selectOrgForUser]);

    useEffect(() => {
      if (!orgId && userOrgsList) {
        if (userOrgsList) {
          const userSelectedOrg = selectOrgForUser(userOrgsList);
          redirectToOrgWithUrl(
            userSelectedOrg,
            history.location.pathname + history.location.search,
          );
        }
      }

      if (!userOrgsList && !dataHasBeenFetched) {
        // TODO: 04.04.23 - Perf: We could potentially store user orgs list in the current auth session.
        // Edge Cases:
        // #1 When user accepts new org invite? We could just update session storage manually.
        fetchUserOrgsAsync();
      }
    }, [userOrgsList, orgId, history, fetchUserOrgsAsync, dataHasBeenFetched, selectOrgForUser]);

    if (isLoading) {
      return <Spinnerv2 message="Loading..." />;
    }

    const newProps = { ...componentProps, userOrgsList };
    return <ComposedComponent {...newProps} />;
  };

  return withRouter(withCookies(RequiresOrgRoute));
};
