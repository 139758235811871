import React, { useState } from 'react';
import styles from './ContactItem.module.scss';
import UserAvatar from '../../../../components/UserInfo/UserAvatar';
import ContactDetail from '../ConatactDetail/ContactDetail';
import { UserContact } from '../../../../data/entities/userContacts.entity';

interface IProps {
  contact: UserContact;
  onContactUpdated: (contact: UserContact) => void;
  setHideAddBtn?: any;
}

const ContactItem: React.FC<IProps> = ({ contact, onContactUpdated, setHideAddBtn }) => {
  const [detailOpened, setDetailOpened] = useState(false);
  const contactName = contact.name;

  const onDetailOpen = () => {
    setDetailOpened(true);
  };

  const onDetailClose = () => {
    setDetailOpened(false);
    setHideAddBtn(false);
  };

  return (
    <>
      {detailOpened && (
        <ContactDetail
          onCloseDetail={onDetailClose}
          currentContact={contact}
          onUpdateContact={onContactUpdated}
          setHideAddBtn={setHideAddBtn}
        />
      )}
      <div className={styles.contact} onClick={onDetailOpen}>
        <UserAvatar className={styles.avatar} name={contactName} />
        <div className={styles.contactInfo}>
          <div className={styles.profession}>{contact.profession}</div>
          <div className={styles.name}>{contactName}</div>
          <div className={styles.email}>{contact.email}</div>
        </div>
      </div>
    </>
  );
};

export default ContactItem;
