import * as Realm from 'realm-web';
import { v4 as uuidv4 } from 'uuid';
import { isMobile } from 'react-device-detect';

import { AttachmentSectionIndex, AttachmentSection } from '../models/attachment';
import { getUnixMilliseconds } from '../../../common/utils/date.helpers';

type SplitAttachmentsToSectionsType = (attachments: any[]) => {
  sectionIndex: AttachmentSectionIndex;
  title: string;
  attachments: any[];
}[];

/**
 * Return attachments list by type.
 */
export const splitAttachmentsToSections: SplitAttachmentsToSectionsType = (attachments) => {
  return Object.entries(AttachmentSection)
    .map(([sectionIndex, title]) => ({
      sectionIndex: +sectionIndex,
      title,
      attachments: attachments.filter((attachment) => +attachment.sectionIndex === +sectionIndex),
      // .sort((a, b) => a.order - b.order),
    }))
    .filter(
      ({ sectionIndex, attachments: attachmentsSection }) =>
        attachmentsSection ||
        sectionIndex === AttachmentSectionIndex.ClientUploads ||
        sectionIndex === AttachmentSectionIndex.ReasonOfLesson ||
        sectionIndex === AttachmentSectionIndex.TheSolution,
    );
};

/**
 * Open file uploader.
 */
export const openFileUploader = (onUpload: (files: FileList) => void) => {
  const input = document.createElement('input');

  if (isMobile) {
    document.body.appendChild(input);
  }

  input.style.display = 'none';
  input.type = 'file';
  input.onchange = (e: Event) => onUpload((e.target as HTMLInputElement).files as FileList);
  input.multiple = true;
  input.accept = 'image/*,video/mp4,video/x-m4v,video/quicktime,application/pdf';
  input.click();
};

/**
 * Return attachment type.
 */
// const getAttachmentType = (type: string) => {
//   if (type.includes('image')) {
//     return AttachmentType.Photo;
//   }

//   if (type.includes('pdf')) {
//     return AttachmentType.Pdf;
//   }

//   return AttachmentType.Video;
// };

/**
 * Generate upload attachment entity.
 */

export const generateUploadAttachment = (file: any, orgId: string, userId: string) => {
  const attachmentId = uuidv4();

  return {
    fileObject: {
      _id: attachmentId,
      org_id: orgId,
      user_id: userId,
      file_url: '',
      thumbnail_url: '',
      original_file_name: file.name,
      content_type: file.type,
      extension: file.type.split('/')[1],
      file_size: new Realm.BSON.Double(file.size),
      date_created: getUnixMilliseconds(),
      date_updated: getUnixMilliseconds(),
      deleted: false,
    },
  };
};
