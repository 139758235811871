/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { ICategory } from 'modules/lesson/models/category.model';
import { LessonCategory } from '../../../../data/entities/orgLessonCategories.entity';
import styles from './SelectCategoriesButtons.module.scss';

interface SelectCategoriesButtons {
  categories: ICategory[] | LessonCategory[];
  selectedCategories: string[];
  setSelectedCategories: Function;
  buttonForAttachments?: string;
}

const SelectCategoriesButtons: React.FC<SelectCategoriesButtons> = ({
  categories,
  selectedCategories,
  setSelectedCategories,
  buttonForAttachments,
}) => {
  const [showMore, setShowMore] = useState(false);
  const onClickToSelectCategory = (categoryId: string) => {
    if (selectedCategories?.includes(categoryId)) {
      setSelectedCategories(selectedCategories.filter((id) => id !== categoryId));
    } else {
      setSelectedCategories([...(selectedCategories || []), categoryId]);
    }
  };

  const getButtonClasses = (categoryId: string) => {
    const classes = [styles.button];

    if (selectedCategories?.includes(categoryId)) {
      classes.push(styles.selectedButton);
    }
    if (buttonForAttachments) {
      classes.push(buttonForAttachments);
    }

    return classes.join(' ');
  };

  return (
    <>
      <div className={styles.wrapper} style={{ height: showMore ? 'auto' : '120px' }}>
        {categories?.map((category) => (
          <div
            key={(category as LessonCategory)._id}
            className={getButtonClasses((category as LessonCategory)._id)}
            onClick={() => onClickToSelectCategory((category as LessonCategory)._id)}
          >
            <p className={styles.categoryName}>{category.name}</p>
          </div>
        ))}
      </div>

      <a onClick={() => setShowMore(!showMore)} style={{ cursor: 'pointer', marginTop: '12px' }}>
        <u>{showMore ? 'Show less' : 'Show more'}</u>
      </a>
    </>
  );
};

export default SelectCategoriesButtons;
