/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';

import styles from './Questionnaire.module.scss';
import Button from '../../../../components/Button/Button';
import { allQuestions, QuestionnaireStages } from '../../models/questionnaire.model';

interface IQuestionnaire {
  currentQuestion: any;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: Function;
  children: any;
  onChangeStage: Function;
  onSubmitResults: Function;
  formValues: any;
  sportData: any;
}

const Questionnaire: React.FC<IQuestionnaire> = ({
  currentQuestion,
  currentQuestionIndex,
  setCurrentQuestionIndex,
  children,
  onChangeStage,
  onSubmitResults,
  formValues,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sportData,
}) => {
  const history = useHistory();

  const isDisabled = useCallback((): boolean => {
    if (
      currentQuestion.field !== 'eyeDomination' &&
      currentQuestion.field !== 'handDomination' &&
      currentQuestion.field !== 'legDomination'
    ) {
      return false;
    }
    if (get(formValues, currentQuestion.field)) {
      return false;
    }
    return true;
  }, [currentQuestion.field, formValues]);
  useEffect(() => {
    isDisabled();
  }, [isDisabled]);
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.questionNumber}>{currentQuestionIndex + 1}.</div>
        <div className={styles.questionTitle}>{currentQuestion.question}</div>
      </div>
      {currentQuestion.details && <div className={styles.details}>{currentQuestion.details}</div>}
      <div className={styles.form}>{children}</div>
      <div className={styles.buttons}>
        <Button
          label={currentQuestionIndex === 0 ? 'Cancel' : 'Previous'}
          className={styles.button}
          onClick={() =>
            currentQuestionIndex === 0
              ? history.goBack()
              : setCurrentQuestionIndex(currentQuestionIndex - 1)
          }
        />
        {currentQuestionIndex === allQuestions.length - 1 ? (
          <Button
            label={onSubmitResults ? 'Submit' : 'Complete'}
            className={styles.button}
            onClick={() => {
              onChangeStage(QuestionnaireStages.Finish);
              onSubmitResults();
            }}
          />
        ) : (
          <Button
            label={!get(formValues, currentQuestion.field) ? 'Skip' : 'Next'}
            className={styles.button}
            disabled={isDisabled()}
            onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
          />
        )}
      </div>
    </div>
  );
};

export default Questionnaire;
