import { BaseRoute } from '../../base/Route.base';
import {
  CreateLessonModel,
  CreateLessonPackagesModel,
  GetLessonPositionsLeftModel,
  PackageValidateModel,
  LessonValidateModel,
  ServiceValidateModel,
} from '../../../modules/book/models/booking.model';
import { GuestAuthorizeModel } from '../../models/auth.model';
import CancelLessonModel from '../../models/lessons/cancelLesson.model';

export class Lessons extends BaseRoute {
  async getServicesPositionsLeft(getLessonsPositionLeftModel: GetLessonPositionsLeftModel[]) {
    const response = await this.api.post(`/lessons/positionsLeft`, getLessonsPositionLeftModel);
    return response;
  }

  async createLessonsAsync(createLessonsModel: CreateLessonModel) {
    const response = await this.api.post('/lessons', createLessonsModel);
    return response;
  }

  async createLessonPackages(createLessonPackagesModel: CreateLessonPackagesModel) {
    const response = await this.api.post('/lessons/package', createLessonPackagesModel);
    return response;
  }

  async createGuestLessonsAsync(
    createLessonsModel: CreateLessonModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    const response = await this.api.postWithGuestAuthorize(
      '/lessons',
      createLessonsModel,
      guestAuthorizeModel,
    );
    return response;
  }

  async createGuestLessonPackages(
    createLessonPackagesModel: CreateLessonPackagesModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    const response = await this.api.postWithGuestAuthorize(
      '/lessons/package',
      createLessonPackagesModel,
      guestAuthorizeModel,
    );
    return response;
  }

  async validateLessonForUser(lessonValidateModel: LessonValidateModel) {
    const response = await this.api.post('/lessons/validate', lessonValidateModel);
    return response;
  }

  async validateLessonPackageForUser(lessonPackageValidateModel: PackageValidateModel) {
    const response = await this.api.post('/lessons/package/validate', lessonPackageValidateModel);
    return response;
  }

  async validateLessonServiceForUser(serviceValidateModel: ServiceValidateModel) {
    return await this.api.post('/lessons/service/validate', serviceValidateModel);
  }

  async validateLessonForGuestUser(
    lessonValidateModel: LessonValidateModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    const response = await this.api.postWithGuestAuthorize(
      '/lessons/validate',
      lessonValidateModel,
      guestAuthorizeModel,
    );
    return response;
  }

  async validateLessonPackageForGuestUser(
    lessonPackageValidateModel: PackageValidateModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    const response = await this.api.postWithGuestAuthorize(
      '/lessons/package/validate',
      lessonPackageValidateModel,
      guestAuthorizeModel,
    );
    return response;
  }

  async validateLessonServiceForGuestUser(
    serviceValidateModel: ServiceValidateModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    const response = await this.api.postWithGuestAuthorize(
      '/lessons/service/validate',
      serviceValidateModel,
      guestAuthorizeModel,
    );
    return response;
  }

  async cancelLesson(lessonId: string, cancelLessonModel: CancelLessonModel) {
    return await this.api.post(`/lessons/${lessonId}/cancel`, cancelLessonModel);
  }

  async cancelPackage(packageId: string, cancelLessonModel: CancelLessonModel) {
    return await this.api.post(`/lessons/package/${packageId}/cancel`, cancelLessonModel);
  }
}
