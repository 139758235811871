import React from 'react';
import { File } from 'data/entities/file.entity';
import styles from './ExerciseView.module.scss';
import ModalWindow from '../ModalWindow/ModalWindow';
import CloseButton from '../Button/CloseButton/CloseButton';
import ExerciseContent from './ExerciseContent/ExerciseContent';
import MobileDrawer from '../MobileDrawer/MobileDrawer';
import { ExerciseModel } from '../../data/entities/exercises.entity';

interface IExerciseView {
  title: string;
  categories?: string[];
  text: string | null;
  attachments?: File[] | ExerciseModel;
  onClose: any;
  open: any;
}

const ExerciseView: React.FC<IExerciseView> = ({
  title,
  categories,
  text,
  attachments,
  onClose,
  open,
}) => {
  return (
    <>
      <div className={styles.visibleDesktop}>
        <ModalWindow show={open} hideFooter onCloseClick={onClose}>
          <>
            <div className={styles.closeIcon}>
              <CloseButton onClose={() => onClose()} />
            </div>
            <ExerciseContent
              title={title}
              categories={categories}
              text={text}
              attachments={attachments}
            />
          </>
        </ModalWindow>
      </div>
      <div className={styles.visibleMobile}>
        <MobileDrawer visible={open} title="Exercise" onClose={onClose}>
          <ExerciseContent
            title={title}
            categories={categories}
            text={text}
            attachments={attachments}
          />
        </MobileDrawer>
      </div>
    </>
  );
};

export default ExerciseView;
