/* eslint-disable no-empty */
import React, { useCallback, useEffect, useState } from 'react';
import { Route, Router, useLocation } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useDispatch } from 'react-redux';
import { authenticationSlice } from 'redux/slices';

import SessionProvider from 'providers/SessionProvider';

/** Common & middleware */
import { StaticRoutes } from 'common/utils/routes/StaticRoutes.helper';
import withOrgInviteDetails from 'middleware/withOrgInviteDetails';
import requiresAuth from 'middleware/requiresAuth';
import Logger from 'middleware/logger.middleware';
import requiresOrgRoute from 'middleware/requiresOrgRoute';
import { useAuth, useOrgId, useUserSubscription } from 'common/hooks';

/** Data */
import { UserType } from 'data/enums/UserType.enum';
import { TimeZoneInfo } from 'data/entities/organization.entity';
import OrganizationService from 'data/services/organization.service';

/** Components */
import { LibraryTabs } from 'modules/library/models/librarytabs.model';
import QuestionnairePage from 'modules/questionnaire/page/Questionnaire-Page';
import AcceptOrgClientInvitePage from 'modules/org/pages/AcceptInvite/accept-org-client-invite-page';
import Header from 'components/Header/Header';
import LessonsPage from 'modules/lesson/pages/LessonsPage';
import LessonPage from 'modules/lesson/pages/LessonPage';
import LibraryPage from 'modules/library/pages/library-page';
import CalendarPage from 'modules/calendar/pages/CalendarPage/calendar-page';
import ContactsPage from 'modules/contacts/page/contacts-page';
import PracticeLocationsPage from 'modules/practiceLocations/page/practiceLocations-page';
import HomePage from 'modules/home/pages/home-page';
import HomePageV2 from 'modules/home/pagesV2/HomePage';
import ProfilePage from 'modules/profile/pages/ProfilePage/ProfilePage';
import { ChangePasswordPage } from 'modules/profile/pages/ChangePasswordPage';
import AcceptOrgStaffInvitePage from 'modules/org/pages/AcceptInvite/accept-org-staff-invite-page';
import InviteAcceptedPage from 'modules/org/pages/AcceptInvite/invite-accepted-page';
import SelectOrgPage from 'modules/org/pages/select-org-page';
import ServiceInvitePageV2 from 'modules/book/pages/BookingPageV2/ServiceInvitation';
import BookPurchasedLessonPackPage from 'modules/service/pages/book-purchased-lesson-packs';
import LessonInvitePageV2 from 'modules/book/pages/BookingPageV2/LessonInvitation';
import NoOrgsPage from 'modules/org/pages/no-orgs-page';
import BookingPageV2 from 'modules/book/pages/BookingPageV2';
import { AuthenticatedPages } from './AuthenticatedPages';

import 'styles.scss';
/**
 * TODO:
 * These 3 lines are related to old booking module imports, we need to clean these up when QA is okay with the new booking.
 */
// import BookingPage from '../modules/book/pages/BookingPage/booking-page';
// import ServiceInvitePage from '../modules/service/pages/service-invite-page';
// import LessonInvitePage from '../modules/lesson/pages/LessonInvitePage/lesson-invite-page';

const history = createBrowserHistory({ forceRefresh: true });

const ApplicationLayout = () => {
  /** Hooks */
  const { orgId } = useOrgId();
  const { isAuthorized, setAuthorized, authorize } = useAuth();
  const { subscriptionInfo, isFetchingUserSubscription } = useUserSubscription();
  const location = useLocation();
  const dispatch = useDispatch();

  const [timeZoneInfoFetched, setTimeZoneInfoFetched] = useState<boolean>(false);

  const isOrgSelectionOrInviteAcceptedPage =
    location.pathname === '/select/org' || location.pathname.includes('inviteAccepted');
  const isNoOrgsPage = location.pathname === StaticRoutes.NoOrgs;
  const userId = SessionProvider.getUserId();
  const tokenFromCookie = SessionProvider.getAccessToken();
  const sessionOrgId = SessionProvider.getOrgId();

  const isHeaderVisible =
    isAuthorized && !!tokenFromCookie && !isOrgSelectionOrInviteAcceptedPage && !isNoOrgsPage;

  const getOrgTimeZoneInfo = useCallback(async (orgIdParam: string) => {
    if (Logger.isDevEnvironment) console.log('getOrgTimeZoneInfo()');
    let timeZoneInfoData: TimeZoneInfo = {
      identifier: 'Pacific/Auckland',
      country_codes: ['NZ', 'AQ'],
      embedded_comments: 'New Zealand time',
      type: 'Canonical',
      utc_offset: {
        std: '+12:00',
        dst: '+13:00',
      },
      abbreviation: {
        std: 'NZST',
        dst: 'NZDT',
      },
      source_file: 'australasia',
      notes: '',
    };

    try {
      const organization = await OrganizationService.getOrgByIdAsync(orgIdParam);
      if (organization?.timezone_info) {
        timeZoneInfoData = organization.timezone_info;
        return;
      }
      if (Logger.isDevEnvironment) console.error(`Could not find org with id {orgId}`);
    } catch (error) {
    } finally {
      setTimeZoneInfoFetched(true);
      SessionProvider.setTimeZone(timeZoneInfoData.identifier);
      // We set the timeZoneInfo as a session cookie.
    }
  }, []);

  /** UseEffects */
  useEffect(() => {
    authorize();
  }, []);

  useEffect(() => {
    if (orgId && orgId !== sessionOrgId) {
      SessionProvider.setOrgId(orgId);
    }

    if (!timeZoneInfoFetched && orgId) {
      getOrgTimeZoneInfo(orgId);
    }
  }, [timeZoneInfoFetched]);

  useEffect(() => {
    dispatch(authenticationSlice.actions.updateIsHeaderVisible(isHeaderVisible));
  }, [isHeaderVisible]);

  if (userId.length && isFetchingUserSubscription) {
    return null;
  }

  return (
    <Router history={history}>
      {isHeaderVisible && <Header />}
      {/* Need to refactor requiresOrgRoute functionality */}
      <Route
        exact
        path={[StaticRoutes.Home, StaticRoutes.OrgRoutes.Id]}
        component={requiresAuth(requiresOrgRoute(HomePage, { subscriptionInfo }), setAuthorized)}
      />
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/homepage-new`}>
        <AuthenticatedPages>
          <HomePageV2 />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.SelectOrg}`}>
        <AuthenticatedPages>
          <SelectOrgPage />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.NoOrgs}`}>
        <AuthenticatedPages>
          <NoOrgsPage />
        </AuthenticatedPages>
      </Route>
      <Route
        exact
        path={`${StaticRoutes.OrgRoutes.Id}/invite/:inviteId`}
        component={withOrgInviteDetails(
          requiresAuth(AcceptOrgClientInvitePage, setAuthorized),
          UserType.client,
        )}
      />
      <Route
        exact
        path={`${StaticRoutes.OrgRoutes.Id}/acceptInvite`}
        component={withOrgInviteDetails(
          requiresAuth(AcceptOrgStaffInvitePage, setAuthorized),
          UserType.coach,
        )}
      />
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/select-dates/:serviceId`}>
        <AuthenticatedPages>
          <BookPurchasedLessonPackPage />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/inviteAccepted`}>
        <InviteAcceptedPage />
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/lessons`}>
        <AuthenticatedPages>
          <LessonsPage subscriptionInfo={subscriptionInfo} />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/lesson/:lessonId`}>
        <AuthenticatedPages>
          <LessonPage subscriptionInfo={subscriptionInfo} />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}${StaticRoutes.Favorites}`}>
        <AuthenticatedPages>
          <LibraryPage
            subscriptionInfo={subscriptionInfo}
            defaultTab={LibraryTabs.FavoritesLessonIndividual}
          />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}${StaticRoutes.Practice}`}>
        <AuthenticatedPages>
          <LibraryPage
            subscriptionInfo={subscriptionInfo}
            defaultTab={LibraryTabs.PracticeLessonUpcoming}
          />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}${StaticRoutes.Exercises}`}>
        <AuthenticatedPages>
          <LibraryPage
            subscriptionInfo={subscriptionInfo}
            defaultTab={LibraryTabs.ExerciseIndividual}
          />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}${StaticRoutes.Events}`}>
        <AuthenticatedPages>
          <LibraryPage subscriptionInfo={subscriptionInfo} defaultTab={LibraryTabs.Events} />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}${StaticRoutes.Calendar}`}>
        <AuthenticatedPages>
          <CalendarPage subscriptionInfo={subscriptionInfo} />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/contacts`}>
        <AuthenticatedPages>
          <ContactsPage />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/practice-locations`}>
        <AuthenticatedPages>
          <PracticeLocationsPage />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.Client}/userDetails`}>
        <AuthenticatedPages>
          <ProfilePage />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.Client}/changePassword`}>
        <AuthenticatedPages>
          <ChangePasswordPage />
        </AuthenticatedPages>
      </Route>
      <Route exact path={`${StaticRoutes.Client}/sportInformation`}>
        <AuthenticatedPages>
          <QuestionnairePage />
        </AuthenticatedPages>
      </Route>

      {/**
       * THE OLD BOOKING MODULE
       * As of June 4th, we shut the old booking module off.
       * We still keep the routes here, just in case we need it if QA testers found some major issues in the new booking.
       */}

      {/* 
      <Route
        exact
        path={`${StaticRoutes.OrgRoutes.Id}/service/:serviceId`}
        component={requiresAuth(ServiceInvitePage, setAuthorized)}
      /> 
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}/lesson/:lessonId/invite`}>
        <AuthenticatedPages>
          <LessonInvitePage />
        </AuthenticatedPages>
      </Route> 
      <Route exact path={`${StaticRoutes.OrgRoutes.Id}${StaticRoutes.Book}`}>
        <BookingPage startingBookingStep={BookingStep.FindCoachLocation} />
      </Route> 
      */}

      {/**
       * TODO:
       * As of Feb 8th, 2024, we removed this isFullyLoaded check to allow new features to be implemented.
       * However, we need to re-check this again if the new booking module is not working fine by re-adding the isFullyLoaded check.
       * The isFullyLoaded is preventing the new booking module from being unmounted when it is processing an api calls due to parent re-renders.
       * One of them is because of the HOC implementation where the state is constantly updated from one layer to another. So we need to check this later.
       * isFullyLoaded = isAuthorized && timeZoneInfoFetched && userSubscriptionFetched;
       */}

      {/**
       * THE NEW BOOKING MODULE
       * The new booking module is activated as of June 4th, 2024
       */}

      <Route
        exact
        path={`${StaticRoutes.OrgRoutes.Id}${StaticRoutes.Book}`}
        component={() => <BookingPageV2 />}
      />
      <Route
        exact
        path={`${StaticRoutes.OrgRoutes.Id}/lesson/:lessonId/invite`}
        component={() => <LessonInvitePageV2 />}
      />
      {/* We need to sync the lesson invitation and service invitation URLs */}
      <Route
        exact
        path={`${StaticRoutes.OrgRoutes.Id}/service/:serviceId`}
        component={() => <ServiceInvitePageV2 />}
      />
    </Router>
  );
};

export default ApplicationLayout;
