import React, { useContext } from 'react';
import Box from '@mui/material/Box';

import { Radio } from '@mui/material';
import { useSelector } from 'react-redux';
import ServicesService from 'data/services/services.service';
import { useOrgId } from 'common/hooks';
import { BookingState } from 'redux/slices';
import { Grid } from 'components/Grid';
import { BrandThemeContext } from 'common/context';
import { RootState } from 'redux/store';
import { AvatarOrIconProps } from './AvatarOrIcon';
import { Coach } from '../../../../service/models/service.model';

interface CoachSelectionItemProps
  extends Pick<AvatarOrIconProps, 'imageUrl'>,
    Pick<Coach, 'id' | 'firstName' | 'lastName'> {
  onClick: (id: BookingState['selected']['coachId']) => void;
}

export const CoachSelectionItemStandard = ({
  id,
  firstName,
  lastName,
  onClick,
}: CoachSelectionItemProps) => {
  const { orgId } = useOrgId();
  const theme = useContext(BrandThemeContext);
  const booking = useSelector((state: RootState) => state.booking);

  const name = `${firstName} ${lastName}`;
  const height = 50;
  const [isNoLocationsWarningDisplayed, setIsNoCoachesNoLocationsWarningDisplayed] =
    React.useState(false);
  const [isNoServicesWarningDisplayed, setIsNoServicesNoLocationsWarningDisplayed] =
    React.useState(false);

  const handleClick = async () => {
    const tmpLocationIds = await ServicesService.getLocationsCoachIsOfferingServicesAsync(
      id,
      orgId,
    );
    const workingHoursLocationsCoachResult = await ServicesService.getWorkingHoursLocationsCoach(
      id,
      orgId,
    );
    if (workingHoursLocationsCoachResult?.working_hours_locations.length === (0 || undefined)) {
      onClick(null);
      setIsNoCoachesNoLocationsWarningDisplayed(true);
      setIsNoServicesNoLocationsWarningDisplayed(false);
    } else if (tmpLocationIds.length > 0) {
      setIsNoServicesNoLocationsWarningDisplayed(false);
      setIsNoCoachesNoLocationsWarningDisplayed(false);
      onClick(id);
    } else {
      setIsNoServicesNoLocationsWarningDisplayed(true);
      setIsNoCoachesNoLocationsWarningDisplayed(false);
      onClick(null);
    }
  };

  return (
    <>
      <Radio
        sx={{ height: 'fit-content' }}
        checked={id === booking.selected.coachId}
        onClick={handleClick}
      />
      <Box
        component="div"
        lineHeight={`${height}px`}
        onClick={handleClick}
        display="flex"
        flexGrow={1}
      >
        <Grid container>
          <Grid item md={12}>
            {name}
          </Grid>
          <Grid item md={12}>
            {isNoLocationsWarningDisplayed && (
              <Box color={theme.colors.red.opacity_100}>
                No locations available for the selected coach.
              </Box>
            )}
          </Grid>
          <Grid item md={12}>
            {isNoServicesWarningDisplayed && (
              <Box color={theme.colors.red.opacity_100}>
                No services available for the selected coach.
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const arePropsEqual = (oldProps: CoachSelectionItemProps, newProps: CoachSelectionItemProps) => {
  // eslint-disable-next-line
  const { onClick: oldPropsOnClick, ...cleanedOldProps } = oldProps;

  // eslint-disable-next-line
  const { onClick: newPropsOnClick, ...cleanedNewProps } = newProps;

  const isSame = JSON.stringify(cleanedOldProps) === JSON.stringify(cleanedNewProps);
  return isSame;
};

export const CoachSelectionItem = React.memo(CoachSelectionItemStandard, arePropsEqual);
