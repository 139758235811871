import React, { useRef, useLayoutEffect, useState } from 'react';
import clsx from 'clsx';

import { useOutsideClick } from '../../common/hooks/useOutsideClick';
import { useWindowDimensions } from '../../common/hooks/useWindowDimensions';
import styles from './Dropdown.module.scss';

interface IDropdownProps {
  overlayClassName?: string;
  visible: boolean;
  content: React.ReactNode;
  children: React.ReactChild;
  onClose: () => void;
}

const Dropdown: React.FC<IDropdownProps> = ({
  overlayClassName,
  visible,
  onClose,
  children,
  content,
}) => {
  const [contentLeft, setContentLeft] = useState(0);

  const { width } = useWindowDimensions();

  const triggerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useOutsideClick([triggerRef, contentRef], onClose);

  useLayoutEffect(() => {
    if (visible && triggerRef && triggerRef.current && contentRef && contentRef.current) {
      const contentRect = contentRef.current.getBoundingClientRect();
      const triggerRect = triggerRef.current.getBoundingClientRect();

      setContentLeft(triggerRect.width - contentRect.width);
    } else {
      setContentLeft(0);
    }
  }, [visible, width]);

  return (
    <>
      <div ref={triggerRef}>{children}</div>
      {visible && (
        <div
          ref={contentRef}
          className={clsx(styles.dropdownContent, overlayClassName)}
          style={{ marginLeft: `${contentLeft}px` }}
        >
          {content}
        </div>
      )}
    </>
  );
};

export default Dropdown;
