import React from 'react';
import clsx from 'clsx';
import styles from './Search.module.scss';

import SearchIcon from '../../assets/images/search/search.svg';

interface SearchProps {
  searchValue: string | undefined;
  setSearchValue: Function;
  className?: string;
}

const Search: React.FC<SearchProps> = ({ searchValue, setSearchValue, className }) => (
  <div className={clsx(styles.wrapper, className)}>
    <img className={styles.icon} alt="search-icon" src={SearchIcon} />
    <input
      className={styles.input}
      type="text"
      placeholder="Search..."
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  </div>
);

export default Search;
