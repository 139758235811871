import React from 'react';
import styles from './DateList.module.scss';
import { getDateFromUnixMilli, getZonedDate } from '../../../common/utils/date.helpers';

interface IDateListItemProps {
  dateUnixMilli: number;
  timeZoneInfo?: string;
}

const DateListItem: React.FC<IDateListItemProps> = ({ dateUnixMilli, timeZoneInfo }) => {
  const dateObject = timeZoneInfo
    ? getZonedDate(dateUnixMilli, timeZoneInfo)
    : getDateFromUnixMilli(dateUnixMilli);
  const month = dateObject.toFormat('MMM d');
  const year = dateObject.toFormat('yyyy');
  const time = dateObject.toFormat('HH:mm');

  return (
    <div className={styles.wrapper}>
      <span className={styles.month}>{month}</span>
      <span className={styles.year}>{year}</span>
      <span className={styles.time}>{time}</span>
    </div>
  );
};

export default DateListItem;
