import React from 'react';
import { useSelector } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import type { RootState } from 'redux/store';
import { BookingStep } from 'redux/slices';

import { Grid } from 'components/Grid';
import { LessonSummaryCard } from './lessons';
import { useService, useLesson, useSummary } from '../hooks';

interface BookingSummaryInfoProps {
  children: React.ReactNode;
}

export const BookingSummaryInfo = ({ children }: BookingSummaryInfoProps) => {
  const booking = useSelector((state: RootState) => state.booking);
  const { promoCode, checkoutSummary, timezone, paymentPreference, lessonInviteId } =
    booking.selected;

  const { getServiceDetails } = useService();
  const { getFormattedPrice } = useLesson();
  const { getPositionsLeftInfo, isPrepaidAndPaid, isIncludeTax } = useSummary();
  const getCoachInfo = (id: string) => booking.data.coaches.find((coach) => coach.id === id);

  /** When a booking is confirmed, don't show checkout summary */
  const isCheckoutSummaryDisplayed = booking.bookingStep !== BookingStep.Confirmed;

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        {booking.selected.services.map((service) => {
          const serviceDetails = getServiceDetails(service.id);
          const selectedCoach = getCoachInfo(
            service.coachId ||
              (serviceDetails && serviceDetails.coaches.length > 0
                ? serviceDetails.coaches[0].id
                : ''),
          );
          if (!serviceDetails || !selectedCoach) return null;
          return (
            <LessonSummaryCard
              key={service.id}
              id={service.id}
              count={service.count ?? 0}
              timezone={timezone}
              selectedCoachName={`${selectedCoach.firstName} ${selectedCoach.lastName}`}
              scheduledDates={service.scheduledDates.map(({ dateStart, dateEnd }) => {
                const positionInfo = getPositionsLeftInfo(service.id, dateStart, dateEnd);
                return {
                  dateStart,
                  dateEnd,
                  positionsLeft: positionInfo ? positionInfo.positionsLeft : 0,
                  participants: positionInfo ? positionInfo.participants : 0,
                };
              })}
              isLessonInvite={!!lessonInviteId}
            />
          );
        })}
      </Grid>
      {isCheckoutSummaryDisplayed && children && (
        <Grid item md={12}>
          {children}
        </Grid>
      )}
      {checkoutSummary && !isPrepaidAndPaid && isCheckoutSummaryDisplayed && (
        <Grid item md={12}>
          <Card id="checkout-summary" sx={{ backgroundColor: grey[200] }} elevation={1}>
            <CardContent>
              {paymentPreference === 'in_app' && checkoutSummary.checkoutSummaryPromoCode && (
                <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
                  <Typography>
                    PROMO {promoCode} - {checkoutSummary.checkoutSummaryPromoCode?.percentApplied}%
                    on{' '}
                    {getFormattedPrice(
                      checkoutSummary.checkoutSummaryPromoCode?.amountAppliedToInCents ?? 0,
                    )}
                  </Typography>
                  <Typography marginRight={15}>
                    -
                    {getFormattedPrice(
                      checkoutSummary.checkoutSummaryPromoCode?.amountAppliedInCents ?? 0,
                    )}
                  </Typography>
                </Stack>
              )}

              {paymentPreference === 'in_app' &&
                checkoutSummary.creditAppliedInCents &&
                checkoutSummary.creditAppliedInCents > 0 && (
                  <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
                    <Typography>Credit Applied</Typography>
                    <Typography marginRight={15}>
                      -{getFormattedPrice(checkoutSummary.creditAppliedInCents)}
                    </Typography>
                  </Stack>
                )}
              <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
                <Typography>Subtotal</Typography>
                <Typography>{getFormattedPrice(checkoutSummary.subTotalInCents)}</Typography>
              </Stack>
              <hr />
              {paymentPreference === 'in_app' && (
                <>
                  <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
                    <Typography>Booking Fee</Typography>
                    <Typography>{getFormattedPrice(checkoutSummary.bookingFeeInCents)}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
                    <Typography fontWeight="bold">Total</Typography>
                    <Typography fontWeight="bold">
                      {getFormattedPrice(checkoutSummary.totalInCents)}
                    </Typography>
                  </Stack>
                  {checkoutSummary.totalTaxInCents > 0 && isIncludeTax && (
                    <Typography fontSize="0.8rem">
                      Includes {getFormattedPrice(checkoutSummary.totalTaxInCents)} of taxes
                    </Typography>
                  )}
                </>
              )}
              {paymentPreference === 'outside_app' && (
                <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
                  <Typography fontWeight="bold">Total</Typography>
                  <Typography fontWeight="bold">
                    {getFormattedPrice(checkoutSummary.subTotalInCents)}
                  </Typography>
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};
