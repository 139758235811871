import React, { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { Stack, Box, Button, Typography } from '@mui/material';

import { BrandThemeContext } from 'common/context';
import { useMobileViewDetector, useOrgId, useUserId } from 'common/hooks';

/** Data */
import ServicesService from 'data/services/services.service';
import LessonsService from 'data/services/lessons.service';
import {
  Services,
  ServiceInviteStatusEnum,
  ServiceInvitedClient,
} from 'data/entities/service.entity';
import { LessonClientStatus, Lesson, LessonClient, EventType } from 'data/entities/lessons.entity';

import type { FeedItem } from 'modules/home/pagesV2/types';

/** Assets */
import rightArrow from 'assets/icons/navbar-icons/thick-right-arrow.svg';
import leftArrow from 'assets/icons/navbar-icons/thick-left-arrow.svg';

/** Components */
import FeedCard from 'modules/home/components/FeedCard';
import { Carousel } from 'components/CarouselV2';
import ModalWindow from 'components/ModalWindow/ModalWindow';

interface InvitationFeedProps {
  setLoading: (value: boolean) => void;
}

const InvitationFeed: React.FC<InvitationFeedProps> = ({ setLoading }) => {
  const theme = useContext(BrandThemeContext);
  const { userId, email } = useUserId();
  const { orgId } = useOrgId();
  const { isViewedFromMobile } = useMobileViewDetector();
  /** Fetched invitation feeds */
  const [invitationFeed, setInvitationFeed] = useState<FeedItem[]>([]);
  /** Tracks current feed for deletion */
  const [currentFeed, setCurrentFeed] = useState<FeedItem>();
  /** Display confirmation Modal */
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);

  const fetchInvitationFeed = async () => {
    const invitationServicesResult = await ServicesService.getUserInvitedServicesAsync(
      userId,
      orgId,
      email,
    );
    const invitationLessonsResult = await LessonsService.getInvitedLessons(userId, orgId, email);
    setInvitationFeed([
      ...invitationServicesResult.filter((service: Services) => !ServicesService.isPast(service)),
      ...invitationLessonsResult.filter((lesson: Lesson) => !LessonsService.isPast(lesson)),
    ]);
  };

  const cancelInvitation = useCallback(async () => {
    setLoading(true);
    if (currentFeed) {
      switch (currentFeed.type) {
        case EventType.Service:
          {
            const service = currentFeed as Services;
            const serviceInvitedClient = service.invited_clients?.find(
              (invitedClient: ServiceInvitedClient) =>
                invitedClient.user_id === userId || invitedClient.email === email,
            );
            await ServicesService.UpdateInvitedClientStatus(
              currentFeed._id,
              serviceInvitedClient?.invite_id || '',
              userId,
              ServiceInviteStatusEnum.RemovedByClient,
            );
          }
          setConfirmationModal(false);
          break;
        case EventType.LessonInvite:
          {
            const lessonInvite = currentFeed as Lesson;
            const lessonClient = lessonInvite.clients.find(
              (lessonInvitedClient: LessonClient) => lessonInvitedClient.user_id === userId,
            );
            await LessonsService.UpdateInvitedClientStatus(
              currentFeed._id,
              lessonClient?.invite_id || '',
              userId,
              LessonClientStatus.Cancelled,
            );
          }
          setConfirmationModal(false);
          break;
        default:
          console.log('Unsupported feedItem type');
      }
      setInvitationFeed(invitationFeed.filter((x: any) => x._id !== currentFeed._id));
      setLoading(false);
    }
  }, [currentFeed]);

  const memoisedFeed = useMemo(
    () =>
      invitationFeed.map((item: FeedItem) => (
        <FeedCard
          key={item._id}
          feedItem={item}
          handleModal={setConfirmationModal}
          handleCurrentFeed={setCurrentFeed}
        />
      )),
    [invitationFeed],
  );

  useEffect(() => {
    fetchInvitationFeed();
  }, []);

  const styles = {
    heading: {
      typography: isViewedFromMobile ? 'h6' : 'h5',
      fontWeight: 700,
    },
    padding: {
      padding: isViewedFromMobile ? '1rem' : '1rem 2.5rem',
    },
    button: {
      minHeight: 36,
      minWidth: 36,
      borderRadius: '50%',
      margin: '0.1rem',
      bgcolor: theme.colors.black.opacity_30,
      display: isViewedFromMobile ? 'none' : 'flex',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&:hover': { bgcolor: theme.colors.black.opacity_20 },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&& .MuiTouchRipple-child': { bgcolor: theme.colors.black.opacity_50 },
    },
  };

  return (
    <>
      <Stack paddingBottom="3rem">
        <Typography sx={{ ...styles.heading, ...styles.padding }}>Invitation feed</Typography>
        {memoisedFeed.length > 0 ? (
          <Box display="flex" alignItems="center" padding={{ xs: '1rem', sm: '0' }}>
            <Carousel
              slides={memoisedFeed}
              breakpoints={{
                // eslint-disable-next-line @typescript-eslint/naming-convention
                800: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                1280: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                // eslint-disable-next-line @typescript-eslint/naming-convention
                1460: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              previousButton={
                <Button sx={styles.button}>
                  <img src={leftArrow} alt="left-arrow" />
                </Button>
              }
              nextButton={
                <Button sx={styles.button}>
                  <img src={rightArrow} alt="right-arrow" />
                </Button>
              }
            />
          </Box>
        ) : (
          <Typography
            color={theme.colors.black.opacity_25}
            textAlign="left"
            margin="auto"
            lineHeight={21}
          >
            You have no invitations for lessons or services currently.
          </Typography>
        )}
      </Stack>
      <ModalWindow
        show={confirmationModal}
        title="Confirmation"
        saveBtnLabel="Hide invitation"
        onSaveClick={cancelInvitation}
        onCloseClick={() => setConfirmationModal(false)}
      >
        <Typography>Are you sure you want to hide this invitation from your feed?</Typography>
      </ModalWindow>
    </>
  );
};

export default InvitationFeed;
