import React, { useCallback, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SignupEmailVerificationPage from './modules/verification/pages/signup-email-verification-page';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import MainLayout from './layouts/MainLayout';
import { store, persistor } from './redux/store';
import { Config } from './config';
import { BrandThemeContext, defaultBrandThemeValue } from './common/context';

toast.configure();

const App = () => {
  const setFavicon = useCallback(() => {
    const favicon = document.getElementById('favicon') as HTMLAnchorElement;
    if (favicon) {
      if (import.meta.env.NODE_ENV === 'production') {
        favicon.href = '/favicon_prod.ico';
      }
    }
  }, []);

  useEffect(() => {
    setFavicon();
  }, [setFavicon]);

  /** Wrapping Stripe for the whole app */
  const stripeElements = loadStripe(Config().StripeKey ?? '');

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrandThemeContext.Provider value={defaultBrandThemeValue}>
          <Elements stripe={stripeElements}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <ToastContainer />
              <BrowserRouter>
                <Switch>
                  <Route
                    exact
                    path="/signup-verification"
                    component={SignupEmailVerificationPage}
                  />
                  <Route path="/" component={MainLayout} />
                </Switch>
              </BrowserRouter>
            </LocalizationProvider>
          </Elements>
        </BrandThemeContext.Provider>
      </PersistGate>
    </Provider>
  );
};

export default App;
