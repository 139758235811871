import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export interface RadioFieldProps extends Pick<RadioGroupProps, 'id' | 'value'> {
  options: RadioFieldOption[];
  onChange: (value: string) => void;
}

export interface RadioFieldOption {
  label: string;
  value: string;
}

export const RadioField = ({ id, options, value, onChange }: RadioFieldProps) => {
  const formLabelId = `${id}-label`;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value);
  };
  return (
    <FormControl>
      <FormLabel id={formLabelId}>&nbsp;</FormLabel>
      <RadioGroup
        value={value}
        row
        aria-labelledby={formLabelId}
        sx={{ marginTop: 2 }}
        onChange={handleChange}
      >
        {options.map((option, index) => {
          return (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio color="success" />}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
