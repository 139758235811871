/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { orderBy } from 'lodash';
import EventsService from 'data/services/events.service';
import { useCalendarPage } from 'modules/calendar/pages/CalendarPage/useCalendarPage.hooks';
import { onSearch } from 'common/utils/search';
import UserService from 'data/services/user.service';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import PracticeLocationModal from 'modules/practiceLocations/modals/PracticeLocationsModal';
import Button from 'components/Button/Button';
import DeleteEventView from 'modules/calendar/components/DeleteEventView/DeleteEventView';
import ListPage from '../../../components/Pages/ListPage';
import { LibraryTabs } from '../models/librarytabs.model';
import { ButtonSavingType } from '../../../data/enums/ButtonType.enum';
import List, { ListType } from '../../../components/List/List';
import AttachmentView from '../../attachment/components/AttachmentView/AttachmentView';
import styles from './LibraryPage.module.scss';
import EventView from '../../calendar/components/EventView/EventView';
import SessionProvider from '../../../providers/SessionProvider';
import {
  EventType,
  Lesson,
  LessonClientStatus,
  LessonTypeEnum,
} from '../../../data/entities/lessons.entity';
import { FavoriteModel } from '../../../data/entities/favorites.entity';
import { ExerciseModel } from '../../../data/entities/exercises.entity';
import { LessonCategory } from '../../../data/entities/orgLessonCategories.entity';
import { Api } from '../../../api/Api';
import { AxiosErrorHandler } from '../../../common/utils/errorHandler.helpers';
import LessonHelper from '../../../common/utils/lessons.helper';
import Spinnerv2 from '../../../components/Spinnerv2/Spinnerv2';
import { OrgBookingRules } from '../../book/models/booking.model';
import OrganizationHelper from '../../../common/utils/organization.helper';
import Logger from '../../../middleware/logger.middleware';
import AttachmentsService from '../../../data/services/attachments.service';
import ExercisesService from '../../../data/services/exercises.service';
import FavouritesService from '../../../data/services/favourites.service';
import LessonsService from '../../../data/services/lessons.service';
import OrganizationService from '../../../data/services/organization.service';
import { SubscriptionsHelper } from '../../../common/utils/subscriptions.helper';
import { UserSubscriptionsModel } from '../../../data/models/userSubscription.mode';
import { Event } from '../../../data/entities/events.entity';

export interface LibraryProps {
  subscriptionInfo?: UserSubscriptionsModel;
  defaultTab: number;
}

const LibraryPage = ({ subscriptionInfo, defaultTab }: LibraryProps) => {
  const [favorites, setFavorites] = useState<FavoriteModel[]>([]);
  const [exercises, setExercises] = useState<ExerciseModel[]>([]);
  const [practiceLocations, setPracticeLocations] = useState<UserPracticeLocation[]>([]);
  const [practiceLessons, setPracticeLessons] = useState<Lesson[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [categories, setCategories] = useState<LessonCategory[]>([]);
  const [selectedTab, setSelectedTab] = useState(defaultTab);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedAttachment, setSelectedAttachment] = useState<FavoriteModel | null>(null);
  const [visibleEventView, setVisibleEventView] = useState(false);
  const [isCancellingLesson, setIsCancellingLesson] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNoteSaved, setIsNoteSaved] = useState(ButtonSavingType.save);
  const { orgId } = useParams<Record<string, string>>();
  const [orgBookingRules, setOrgBookingRules] = useState<OrgBookingRules>(
    new OrgBookingRules(orgId),
  );
  const [source, setSource] = useState<
    Lesson[] | ExerciseModel[] | FavoriteModel[] | Event[] | UserPracticeLocation[]
  >([]);
  const [searchValue, setSearchValue] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [, setHideAddBtn] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState<string | null>('');
  const currentOrgId = orgId ?? '';
  const currentUserId = SessionProvider.getUserId();

  const {
    createPracticeLesson,
    setEventToUpdate,
    eventToUpdate,
    isPracticeLesson,
    setIsPracticeLesson,
    setVisibleDeleteView,
    visibleDeleteView,
    instanceOfEvent,
  } = useCalendarPage();

  const shouldIncludeTab = (defaultTabProps: number, currentTabPros: number) => {
    const practiceTabs = [
      LibraryTabs.PracticeLessonUpcoming,
      LibraryTabs.PracticeLessonPast,
      LibraryTabs.PracticeLessonCanceled,
      LibraryTabs.PracticeLessonAll,
      LibraryTabs.PracticeLessonCoach,
      LibraryTabs.PracticeLessonSelf,
      LibraryTabs.PracticeLocation,
    ];

    const exerciseTabs = [LibraryTabs.ExerciseGroup, LibraryTabs.ExerciseIndividual];

    const favoritesTabs = [
      LibraryTabs.FavoritesLessonIndividual,
      LibraryTabs.FavoritesLessonGroup,
      LibraryTabs.FavoritesPracticeSelf,
      LibraryTabs.FavoritesPracticeShared,
    ];

    if (practiceTabs.includes(defaultTabProps)) {
      return practiceTabs.includes(currentTabPros);
    }

    if (exerciseTabs.includes(defaultTabProps)) {
      return exerciseTabs.includes(currentTabPros);
    }

    if (favoritesTabs.includes(defaultTabProps)) {
      return favoritesTabs.includes(currentTabPros);
    }

    return currentTabPros === defaultTabProps;
  };

  const filteredEntries = Object.entries(LibraryTabs).filter(([, value]) =>
    shouldIncludeTab(defaultTab, value),
  );
  const filteredTabs = Object.fromEntries(filteredEntries);

  const fetchLibrary = useCallback(async () => {
    setIsLoading(true);
    const fetchAllDatas = [];

    fetchAllDatas.push(OrganizationHelper.GetOrgBookingRules(orgId, setOrgBookingRules));
    fetchAllDatas.push(OrganizationService.getOrgLessonCategoriesById(currentOrgId));

    let lessonType = LessonTypeEnum.CoachIndividualLesson;
    if (selectedTab === LibraryTabs.FavoritesLessonGroup)
      lessonType = LessonTypeEnum.CoachGroupLesson;
    if (selectedTab === LibraryTabs.FavoritesPracticeSelf)
      lessonType = LessonTypeEnum.PracticeLessonSelf;
    if (selectedTab === LibraryTabs.FavoritesPracticeShared)
      lessonType = LessonTypeEnum.PracticeLessonShare;

    switch (selectedTab) {
      case LibraryTabs.PracticeLessonUpcoming:
      case LibraryTabs.PracticeLessonPast:
      case LibraryTabs.PracticeLessonCanceled:
      case LibraryTabs.PracticeLessonAll:
        fetchAllDatas.push(
          LessonsService.getAllUserPracticeByOrgId(currentUserId, currentOrgId, selectedTab),
        );
        try {
          const fetchedData = await Promise.all(fetchAllDatas);
          setCategories(fetchedData[1]);
          setPracticeLessons(fetchedData[2]);
        } catch (e) {
          setCategories([]);
          setPracticeLessons([]);
          toast.error('Could not load Practice library data');
        }
        break;
      case LibraryTabs.PracticeLessonSelf:
        fetchAllDatas.push(
          LessonsService.getAllUserSelfPracticeByOrgId(currentUserId, currentOrgId, false),
        );
        try {
          const fetchedData = await Promise.all(fetchAllDatas);
          setCategories(fetchedData[1]);
          setPracticeLessons(fetchedData[2]);
        } catch (e) {
          setCategories([]);
          setPracticeLessons([]);
          toast.error('Could not load Practice library data');
        }
        break;
      case LibraryTabs.PracticeLessonCoach:
        fetchAllDatas.push(
          LessonsService.getAllUserSelfPracticeByOrgId(currentUserId, currentOrgId, true),
        );
        try {
          const fetchedData = await Promise.all(fetchAllDatas);
          setCategories(fetchedData[1]);
          setPracticeLessons(fetchedData[2]);
        } catch (e) {
          setCategories([]);
          setPracticeLessons([]);
          toast.error('Could not load Practice library data');
        }
        break;
      case LibraryTabs.Events:
        fetchAllDatas.push(EventsService.getAllEventsByUserId(currentUserId));
        try {
          const fetchedData = await Promise.all(fetchAllDatas);
          setCategories(fetchedData[1]);
          setEvents(fetchedData[2]);
        } catch (e) {
          setCategories([]);
          setEvents([]);
          toast.error('Could not load Practice library data');
        }
        break;
      case LibraryTabs.ExerciseIndividual:
      case LibraryTabs.ExerciseGroup:
        fetchAllDatas.push(
          ExercisesService.getAllExercisesByUserAndOrg(
            currentUserId,
            currentOrgId,
            selectedTab === LibraryTabs.ExerciseGroup,
          ),
        );
        try {
          const fetchedData = await Promise.all(fetchAllDatas);
          setCategories(fetchedData[1]);
          setExercises(fetchedData[2]);
        } catch (e) {
          setCategories([]);
          setExercises([]);
          toast.error('Could not load Exercise library data');
        }
        break;
      case LibraryTabs.FavoritesLessonIndividual:
      case LibraryTabs.FavoritesLessonGroup:
      case LibraryTabs.FavoritesPracticeSelf:
      case LibraryTabs.FavoritesPracticeShared:
        fetchAllDatas.push(
          FavouritesService.getAllFavoritesAsModel(currentUserId, currentOrgId, lessonType),
        );
        try {
          const fetchedData = await Promise.all(fetchAllDatas);
          setCategories(fetchedData[1]);
          setFavorites(fetchedData[2]);
        } catch (e) {
          setCategories([]);
          setFavorites([]);
          toast.error('Could not load Favorite library data');
        }
        break;
      case LibraryTabs.PracticeLocation:
        fetchAllDatas.push(
          UserService.getPracticeLocations({ userId: currentUserId, orgId: currentOrgId }),
        );
        try {
          const fetchedData = await Promise.all(fetchAllDatas);
          setCategories(fetchedData[1]);
          setPracticeLocations(fetchedData[2]);
        } catch (e) {
          setCategories([]);
          setPracticeLocations([]);
          toast.error('Could not load Favorite library data');
        }
        break;
      default:
    }

    setIsLoading(false);
  }, [currentOrgId, currentUserId, orgId, selectedTab]);

  useEffect(() => {
    fetchLibrary();
  }, [fetchLibrary]);

  const { emptyListText, listType } = useMemo(() => {
    switch (selectedTab) {
      case LibraryTabs.PracticeLessonSelf:
        setSource(practiceLessons.sort((a, b) => a.date_start - b.date_start));
        return {
          emptyListText: 'You have no practice (Self) yet.',
          listType: ListType.LessonsList,
        };
      case LibraryTabs.PracticeLessonCoach:
        setSource(practiceLessons.sort((a, b) => a.date_start - b.date_start));
        return {
          emptyListText: 'You have no practice (Shared with coach) yet.',
          listType: ListType.LessonsList,
        };
      case LibraryTabs.ExerciseIndividual:
      case LibraryTabs.ExerciseGroup:
        setSource(
          exercises.sort((a, b) => a.exerciseObj.date_created - b.exerciseObj.date_created),
        );
        return {
          emptyListText: 'You have no exercises yet.',
          listType: ListType.ExercisesList,
        };
      case LibraryTabs.Events:
        setSource(events.sort((a: Event, b: Event) => a.date_begin - b.date_begin));
        return {
          emptyListText: 'You have no events yet.',
          listType: ListType.EventsList,
        };
      case LibraryTabs.FavoritesLessonIndividual:
      case LibraryTabs.FavoritesLessonGroup:
      case LibraryTabs.FavoritesPracticeSelf:
      case LibraryTabs.FavoritesPracticeShared:
        setSource(favorites.sort((a, b) => a.dateCreated - b.dateCreated));
        return {
          emptyListText: 'You have no saved favorites yet.',
          listType: ListType.FavouritesList,
        };
      case LibraryTabs.PracticeLocation:
        setSource(practiceLocations);
        return {
          emptyListText: 'You have no saved practice locations yet.',
          listType: ListType.PracticeLocationList,
        };
      default:
        setSource(practiceLessons.sort((a, b) => a.date_start - b.date_start));
        return {
          emptyListText: 'You have no practice yet.',
          listType: ListType.LessonsList,
        };
    }
  }, [exercises, favorites, practiceLessons, events, practiceLocations]);

  const filteredSource = useMemo(() => {
    const searchedSource = onSearch(source, searchValue);
    switch (selectedTab) {
      case LibraryTabs.PracticeLessonSelf:
        return selectedCategories.length > 0
          ? (searchedSource as Lesson[]).filter((item: Lesson) =>
              item.org_lesson_category_ids?.some((id: string) =>
                selectedCategories.includes(id as never),
              ),
            )
          : searchedSource;
      case LibraryTabs.PracticeLessonCoach:
        return selectedCategories.length > 0
          ? (searchedSource as Lesson[]).filter((item: Lesson) =>
              item.org_lesson_category_ids?.some((id: string) =>
                selectedCategories.includes(id as never),
              ),
            )
          : searchedSource;
      case LibraryTabs.ExerciseIndividual:
      case LibraryTabs.ExerciseGroup:
        return selectedCategories.length > 0
          ? (searchedSource as ExerciseModel[]).filter((item: ExerciseModel) =>
              item.exerciseObj.org_lesson_category_ids?.some((id: string) =>
                selectedCategories.includes(id as never),
              ),
            )
          : searchedSource;
      case LibraryTabs.FavoritesLessonIndividual:
      case LibraryTabs.FavoritesLessonGroup:
      case LibraryTabs.FavoritesPracticeSelf:
      case LibraryTabs.FavoritesPracticeShared:
        return selectedCategories.length > 0
          ? (searchedSource as FavoriteModel[]).filter((item: FavoriteModel) =>
              item.favoriteType.lesson_category_ids?.some((id: string) =>
                selectedCategories.includes(id as never),
              ),
            )
          : searchedSource;
      default:
        return searchedSource;
    }
  }, [source, selectedCategories, searchValue]);

  const onUnfavorited = async (id: string) => {
    if (!favorites) {
      return;
    }

    const favorite = favorites.find((x) => x.favoriteId === id);

    if (favorite) {
      try {
        await FavouritesService.deleteFavoriteById(id);
        let updateFavorites = [...favorites];
        updateFavorites = favorites.filter((x) => x.favoriteId !== id);
        setFavorites(updateFavorites);
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const onFavoriteChanged = async (
    attachmentId: string,
    favoriteId: string,
    favoriteCategories: string[],
    isFavorite: boolean,
  ) => {
    if (!selectedAttachment) {
      return;
    }

    selectedAttachment.isFavorite = isFavorite;
    selectedAttachment.favoriteType.lesson_category_ids = favoriteCategories;
    setSelectedAttachment({ ...selectedAttachment });

    if (!isFavorite) {
      try {
        await FavouritesService.deleteFavoriteById(favoriteId);
        selectedAttachment.isFavorite = false;
        setSelectedAttachment({ ...selectedAttachment });
      } catch (error: any) {
        toast.error(error.message);
      }
    } else if (selectedAttachment.isFavorite && favoriteCategories.length > 0) {
      const favoriteData = {
        lesson_id: selectedAttachment.lessonId,
        lesson_category_ids: favoriteCategories,
        attachment_id: attachmentId,
      };

      if (attachmentId !== null) {
        try {
          await FavouritesService.createFavorite(currentUserId, currentOrgId, favoriteData);
        } catch (error: any) {
          toast.error(error.message);
        }
      }
    }
  };

  const onNoteChanged = async (value: string) => {
    if (selectedAttachment) {
      try {
        setIsNoteSaved(ButtonSavingType.saving);
        const copyFavorite: FavoriteModel = { ...selectedAttachment };
        const favoriteObject = copyFavorite.favoriteType;
        favoriteObject.favorite_note = value;
        await FavouritesService.updateFavoriteType(selectedAttachment.favoriteId, favoriteObject);

        setSelectedAttachment(selectedAttachment);

        toast.success('Note is saved.', {
          position: 'bottom-right',
          autoClose: 3000,
          hideProgressBar: true,
        });
        setIsNoteSaved(ButtonSavingType.saved);
      } catch (error: any) {
        toast.error(error.message);
        setIsNoteSaved(ButtonSavingType.save);
      }
    }
  };

  const createPractice = async (
    title: string,
    note: string,
    startDate: number,
    endDate: number,
    chosenLocationId: string,
    selectedContactList: string[],
    categoriesParam: string[],
    isShareCoach: boolean,
  ) => {
    try {
      const practiceLesson = await createPracticeLesson(
        title,
        note,
        startDate,
        endDate,
        chosenLocationId,
        selectedContactList,
        categoriesParam,
        isShareCoach,
      );

      setVisibleEventView(false);

      if (isShareCoach) setSelectedTab(LibraryTabs.PracticeLessonCoach);
      else setSelectedTab(LibraryTabs.PracticeLessonSelf);

      if (practiceLesson)
        setPracticeLessons(
          orderBy(
            [...practiceLessons, practiceLesson],
            (lesson) => {
              return lesson.date_start;
            },
            'desc',
          ),
        );
    } catch (e) {
      toast.error(e);
    }
  };

  const onCloseFavoriteView = async () => {
    if (selectedAttachment && !selectedAttachment.isFavorite) {
      await onUnfavorited(selectedAttachment.favoriteId);
    }

    setSelectedAttachment(null);
  };

  const onCloseEventView = () => {
    setVisibleEventView(false);
  };

  const onChangeAttachmentTextNote = async (note: string) => {
    if (selectedAttachment) {
      try {
        await AttachmentsService.updateNoteAttachmentById(
          selectedAttachment.attachment?._id as string,
          note,
        );
        fetchLibrary();
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  const cancelLesson = async (lessonId: string, reason?: string) => {
    const lesson = practiceLessons.find((event) => {
      return event._id === lessonId;
    }) as Lesson;

    try {
      setIsCancellingLesson(true);
      await Api.ClientRoutes.Lessons.cancelLesson(lessonId, { userId: currentUserId, reason });
      toast.success(
        LessonHelper.LessonCancelledToastMessage(
          lesson,
          orgBookingRules.cancellationPeriodInMinutes,
        ),
      );
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.warn(compiledErrorMessage);
    } finally {
      const lessonClient = lesson?.clients.find((lessonClientSearch) => {
        return lessonClientSearch.user_id === currentUserId;
      });

      if (lessonClient) {
        lessonClient.status = LessonClientStatus.Cancelled;
      }
      setPracticeLessons(practiceLessons);

      setIsCancellingLesson(false);
    }
  };

  const onCancelPackage = async (packageId: string) => {
    if (Logger.isDevEnvironment) {
      console.log('Cannot cancel practice as it is not a package: ', packageId);
    }
  };

  const showListItem = (item: any, i: number) => {
    return SubscriptionsHelper.showListItem(i, subscriptionInfo);
  };

  if (isCancellingLesson) {
    return <Spinnerv2 message="Canceling lesson..." />;
  }

  const onPracticeLocationUpdated = async (updatedPracticeLocation: UserPracticeLocation) => {
    try {
      const contactIndex = practiceLocations.findIndex(
        (item) => item.location_id === updatedPracticeLocation.location_id,
      );

      const practiceLocationsArray = [...practiceLocations];
      practiceLocationsArray[contactIndex] = updatedPracticeLocation;

      const updates = {
        practice_locations: practiceLocationsArray,
      };

      await UserService.updateUserDetailsV2({
        userId: currentUserId,
        orgId: currentOrgId,
        updates,
      });
      setPracticeLocations(practiceLocationsArray);
    } catch (err: any) {
      toast.error('There was an error updating your contact');
      console.error(err);
    }
  };

  const onPracticeLocationDeleted = async (locationId: string) => {
    try {
      const index = practiceLocations.findIndex(
        (location: UserPracticeLocation) => location.location_id === locationId,
      );

      if (index >= 0) practiceLocations.splice(index, 1);

      const updates = {
        practice_locations: practiceLocations,
      };

      await UserService.updateUserDetailsV2({
        userId: currentUserId,
        orgId: currentOrgId,
        updates,
      });
      fetchLibrary();
    } catch (err: any) {
      toast.error('There was an error deleting your contact.');
      console.error(err);
    } finally {
      setShowAdd(false);
    }
  };

  const onPracticeLocationAdded = async (newPracticeLocation: UserPracticeLocation) => {
    try {
      practiceLocations.push(newPracticeLocation);

      const updates = {
        practice_locations: practiceLocations,
      };

      await UserService.updateUserDetailsV2({
        userId: currentUserId,
        orgId: currentOrgId,
        updates,
      });
      fetchLibrary();
    } catch (err: any) {
      toast.error('There was an error adding your contact.');
      console.error(err);
    } finally {
      setShowAdd(!showAdd);
    }
  };

  const onEventUpdated = async (
    title: string,
    note: string,
    startDate: number,
    endDate: number,
    selectedContactList: string[],
  ) => {
    if (eventToUpdate) {
      const overapped_events = events.filter(
        (eventItem) =>
          ((eventItem.date_begin >= startDate && eventItem.date_begin < endDate) ||
            (eventItem.date_end > startDate && eventItem.date_end <= endDate)) &&
          eventItem._id !== eventToUpdate._id &&
          !eventItem.deleted &&
          instanceOfEvent(eventItem),
      );

      if (overapped_events.length > 0) {
        toast.warn('The event date and time overlaps with another existing event');
      } else {
        await EventsService.updateEventById(
          eventToUpdate._id,
          title,
          note,
          startDate,
          endDate,
          selectedContactList,
        );
        const event: Event = {
          ...eventToUpdate,
          title,
          note,
          date_begin: startDate,
          date_end: endDate,
          contacts_ids: selectedContactList,
        };

        setEvents(events.map((x) => (x._id === event._id ? event : x)));
        setVisibleEventView(false);
      }
    }

    setVisibleEventView(false);
  };

  const onEventAdded = async (
    title: string,
    note: string,
    startDate: number,
    endDate: number,
    isNextDayEndDate: boolean,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCats: string[],
    isShareCoach: boolean,
  ) => {
    const userId = SessionProvider.getUserId();

    const overapped_events = events.filter(
      (eventItem) =>
        (eventItem.date_begin >= startDate &&
          eventItem.date_begin < endDate &&
          !eventItem.deleted) ||
        (eventItem.date_end > startDate && eventItem.date_end <= endDate && !eventItem.deleted),
    );

    if (overapped_events.length > 0) {
      toast.warn('The event date and time overlaps with another existing event');
    } else {
      const newEvent = await EventsService.createEvent(
        userId,
        orgId as string,
        title,
        note,
        startDate,
        endDate,
        chosenLocationId,
        selectedContactList,
        selectedCategories,
        isShareCoach,
      );

      if (newEvent)
        setEvents(
          orderBy(
            [...events, newEvent],
            (event: Event) => {
              return event.date_begin;
            },
            'desc',
          ),
        );
    }

    setVisibleEventView(false);
  };

  const onEventDeleted = async () => {
    if (deleteEventId) {
      setEvents(events.filter((x) => x._id !== deleteEventId));
      await EventsService.deleteEventById(deleteEventId);
    }
  };

  const getBtnGroupAndTitle = () => {
    switch (defaultTab) {
      case LibraryTabs.FavoritesLessonIndividual:
      case LibraryTabs.FavoritesLessonGroup:
      case LibraryTabs.FavoritesPracticeSelf:
      case LibraryTabs.FavoritesPracticeShared:
        return {
          btnGroup: <div />,
          title: 'Favorites',
        };
      case LibraryTabs.ExerciseIndividual:
      case LibraryTabs.ExerciseGroup:
        return {
          btnGroup: <div />,
          title: "Coach's exercises",
        };
      case LibraryTabs.Events:
        return {
          btnGroup: (
            <div className={styles.btnWrapper}>
              <Button
                label="+ Add event"
                className={styles.practiceLessonBtn}
                onClick={() => {
                  setEventToUpdate(undefined);
                  setIsPracticeLesson(false);
                  setVisibleEventView(true);
                }}
              />
            </div>
          ),
          title: 'Events',
        };
      case LibraryTabs.PracticeLessonCoach:
      case LibraryTabs.PracticeLessonSelf:
      case LibraryTabs.PracticeLocation:
      case LibraryTabs.PracticeLessonUpcoming:
      case LibraryTabs.PracticeLessonCanceled:
      case LibraryTabs.PracticeLessonPast:
      case LibraryTabs.PracticeLessonAll:
        return {
          btnGroup: (
            <div className={styles.btnWrapper}>
              <Button
                label="+ Practice"
                className={styles.practiceLessonBtn}
                onClick={() => {
                  setIsPracticeLesson(true);
                  setSelectedTab(LibraryTabs.PracticeLessonSelf);
                  setVisibleEventView(true);
                }}
              />
              <Button
                label="+ Practice location"
                className={styles.practiceLocationBtn}
                onClick={() => {
                  setSelectedTab(LibraryTabs.PracticeLocation);
                  setShowAdd(true);
                }}
              />
            </div>
          ),
          title: 'Practice',
        };
      default:
        return { btnGroup: <div />, title: '' };
    }
  };

  return (
    <div className={styles.wrapper}>
      <AttachmentView
        title="Favorite"
        visible={!!selectedAttachment}
        onClose={onCloseFavoriteView}
        attachment={selectedAttachment}
        categories={categories}
        onFavoriteChanged={onFavoriteChanged}
        onNoteChanged={onNoteChanged}
        onChangeAttachmentTextNote={onChangeAttachmentTextNote}
        isLoading={isNoteSaved}
      />
      <EventView
        event={eventToUpdate}
        isPracticeLesson={isPracticeLesson}
        categories={categories}
        visible={visibleEventView}
        onClose={onCloseEventView}
        createPracticeLesson={createPractice}
        createEvent={onEventAdded}
        updateEvent={onEventUpdated}
        deleteEvent={() => {
          if (eventToUpdate) {
            setVisibleEventView(false);
            setDeleteEventId(eventToUpdate._id);
            setVisibleDeleteView(true);
          }
        }}
      />
      <DeleteEventView
        visible={visibleDeleteView}
        onClose={() => {
          setVisibleDeleteView(false);
          setDeleteEventId(null);
        }}
        onDeleteEvent={onEventDeleted}
        type={EventType.Event}
      />
      <PracticeLocationModal
        showModal={showAdd}
        onModalCloseClick={() => setShowAdd(false)}
        saveLabel="Create"
        onPracticeLocationSaved={onPracticeLocationAdded}
        titleModal="Add practice location"
      />
      <ListPage
        onCategorySelected={setSelectedCategories}
        tabs={filteredTabs}
        searchValue={searchValue}
        onSearchValue={setSearchValue}
        selectedTab={selectedTab}
        onTabSelected={setSelectedTab}
        selectedCategories={selectedCategories}
        orgId={currentOrgId}
        extraContent={getBtnGroupAndTitle().btnGroup}
        title={getBtnGroupAndTitle().title}
      >
        <List
          selectedTab={selectedTab}
          items={filteredSource}
          listType={listType}
          emptyLabel={emptyListText}
          openAttachmentView={(item: FavoriteModel) => {
            setIsNoteSaved(ButtonSavingType.save);
            setSelectedAttachment(item);
          }}
          onUnfavorited={onUnfavorited}
          openEditEventView={(event: Event) => {
            setEventToUpdate(event);
            setVisibleEventView(true);
          }}
          categories={categories}
          cancelLesson={cancelLesson}
          onCancelPackage={onCancelPackage}
          showListItem={showListItem}
          showUpgradePlanButtonIndex={SubscriptionsHelper.showUpgradePlanButtonIndex(
            true,
            filteredSource,
          )}
          isLoading={isLoading}
          onPracticeLocationDeleted={onPracticeLocationDeleted}
          onPracticeLocationUpdated={onPracticeLocationUpdated}
          setHideAddBtn={setHideAddBtn}
        />
      </ListPage>
    </div>
  );
};

export default LibraryPage;
