import React from 'react';
import clsx from 'clsx';
import styles from './Checkbox.module.scss';

interface IProps {
  id: string;
  label?: string;
  onCheck?: any;
  checked?: boolean;
  labelStyles?: any;
  labelPrefix?: string;
  isImportant?: boolean;
  labelLinks?: any;
}

const Checkbox: React.FC<IProps> = ({
  id,
  label,
  onCheck,
  checked,
  labelStyles,
  labelPrefix,
  isImportant,
  labelLinks,
}) => {
  const labelClasses = clsx(styles.label, labelStyles);

  const onChecked = (e: any) => {
    if (onCheck) {
      onCheck(e.target.checked);
    }
  };

  return (
    <div className={styles.inputWrapper}>
      <label className={labelClasses}>
        <input
          className={styles.input}
          type="checkbox"
          id={id}
          onChange={onChecked}
          checked={checked}
        />
        <span className={styles.customCheckbox} />
        {isImportant && <span className={styles.important}>!!!</span>}
        {labelPrefix && <span className={styles.labelPrefix}>{labelPrefix}</span>}
        {labelLinks || label}
      </label>
    </div>
  );
};

export default Checkbox;
