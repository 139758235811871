import React from 'react';
import styles from './Hamburger.module.scss';

interface IHamburger {
  openNav: boolean;
  onClose: any;
}

const Hamburger: React.FC<IHamburger> = ({ openNav, onClose }) => {
  return (
    <div className={styles.Hamburger} onClick={onClose}>
      <input type="checkbox" checked={openNav} readOnly />
      <span />
      <span />
      <span />
    </div>
  );
};

export default Hamburger;
