import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React from 'react';

interface CalendarHeaderProps {
  dayName: string;
}

export const CalendarHeader = ({ dayName }: CalendarHeaderProps) => {
  return (
    <Box width="100%" height={40} borderRadius={2} textAlign="center">
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.25}
        height="100%"
        fontWeight="bold"
      >
        {dayName}
      </Stack>
    </Box>
  );
};
