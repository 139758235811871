import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import shareIcon from '../../../../assets/icons/attachment-icons/share.svg';

interface IProps {
  url: string;
}

const AttachmentCopyBtn: React.FC<IProps> = ({ url }) => (
  <>
    <CopyToClipboard
      text={url}
      onCopy={() =>
        toast('Copied', {
          position: 'bottom-right',
          autoClose: 1500,
          hideProgressBar: true,
        })
      }
    >
      <img src={shareIcon} alt="share icon" />
    </CopyToClipboard>
    <span>Copy</span>
  </>
);

export default AttachmentCopyBtn;
