import { useSelector, useDispatch } from 'react-redux';
import {
  BookingState,
  SelectedService,
  ServicePositionsLeft,
  applicationSlice,
  bookingSlice,
} from 'redux/slices';

import { ServiceTypeEnum } from 'data/enums/ServiceType.enum';
import ServicesService from 'data/services/services.service';
import { SelectedService as AvailableService } from 'modules/book/models/booking.model';
import OrganizationHelper from 'common/utils/organization.helper';
import { BookingHelper } from 'modules/book/utils/booking.helpers';
import { ScheduledDate } from 'modules/service/models/service.model';
import type { RootState } from '../../../../../redux/store';
import { DayOfWeekEnum } from '../../../../../data/enums/DayOfWeek.enum';

interface GetCoachWorkHoursInput {
  serviceId: string;
  coachId: string;
  dayOfWeek: DayOfWeekEnum;
}

interface CoachAndLocation {
  coachId: string;
  locationId: string;
}

interface GetCoachLocationProps {
  orgId: string;
  serviceParam: AvailableService;
}

export type BookedServices = BookingState['selected']['services'];

export const useService = () => {
  const booking = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  /** Helper methods to determine the type of a lesson or a service */
  const isPurchasedService = (service: AvailableService) => ServicesService.isPurchased(service);

  const isLesson = (service: AvailableService) =>
    service.serviceTypeEnum === ServiceTypeEnum.Lesson;

  const isAdvancedLesson = (service: AvailableService) =>
    isLesson(service) && service.scheduledDates && service.scheduledDates.length;

  const isLessonPack = (service: AvailableService) =>
    service.serviceTypeEnum === ServiceTypeEnum.Package;

  const isLessonPackAndNotPurchased = (service: AvailableService) =>
    isLessonPack(service) && !ServicesService.isPurchased(service);

  const isProgramme = (service: AvailableService) =>
    service.serviceTypeEnum === ServiceTypeEnum.Programme;

  const isEvent = (service: AvailableService) => service.serviceTypeEnum === ServiceTypeEnum.Event;

  const shouldPriceDisplayed = (service: AvailableService) => {
    if (isLessonPack(service) && !isLessonPackAndNotPurchased(service)) return false;
    return true;
  };

  const getCurrentCount = (serviceId: string): number => {
    const selectedService = booking.selected.services.find((service) => service.id === serviceId);
    if (selectedService) return selectedService.count ?? 0;
    return 0;
  };

  const isDetailedServiceTimeslotSelectionFlowNeeded = (detailedService: AvailableService) => {
    /**
     * We don't need timeslot selection for
     * - Events with Pay All At Once (isPackage = true)
     * - Programmes with Pay All At Once
     * - Lesson Packs
     * - Advanced Lesson (lesson with scheduled dates)
     * */
    let result = true;

    /** Advanced Lesson */
    if (isAdvancedLesson(detailedService)) {
      result = false;
    }

    /** Lesson Pack */
    if (isLessonPack(detailedService) && !ServicesService.isPurchased(detailedService))
      result = false;

    /** Programme & Event */
    if (isProgramme(detailedService) || isEvent(detailedService)) {
      if (detailedService?.isPackage) {
        result = false;
      } else {
        const maxCount = detailedService.scheduledDates.length;
        if (getCurrentCount(detailedService.id) >= maxCount || maxCount === 1) {
          result = false;
        }
      }
    }

    return result;
  };

  const isServiceTimeslotSelectionFlowNeeded = (
    service: SelectedService | AvailableService,
  ): boolean => {
    let result = true;
    const serviceDetails = booking.data.services.find(
      (serviceItem) => serviceItem.id === service.id,
    );
    if (serviceDetails) {
      result = isDetailedServiceTimeslotSelectionFlowNeeded(serviceDetails);
    }

    return result;
  };

  const getActiveSelectedServiceFromArray = (bookedServices: BookedServices) => {
    const activeSelectedService = bookedServices.find((service) => service.isCalendarActive);
    const activeService = booking.data.services.find(
      (service) => service.id === activeSelectedService?.id,
    );

    return activeService;
  };

  const getActiveSelectedService = () =>
    getActiveSelectedServiceFromArray(booking.selected.services);

  const getSelectedService = () =>
    booking.selected.services.find((item) => item.id === getActiveSelectedService()?.id);

  const getServiceDetails = (serviceId: string): AvailableService | null => {
    const curService = booking.data.services.find((service) => service.id === serviceId);
    if (!curService) return null;

    /** For Lesson */
    let newPriceInCents = curService.priceInCents;

    if (isProgramme(curService) || isEvent(curService)) {
      if (curService.isPackage)
        newPriceInCents = curService.priceInCents * curService.scheduledDates.length;
    }

    /** For Lesson Packs */
    if (isLessonPack(curService) && curService.unscheduledPackageOccurrences)
      newPriceInCents = curService.priceInCents * curService.unscheduledPackageOccurrences;

    return {
      ...curService,
      priceInCents: newPriceInCents,
    };
  };

  const getDetailedSelectedServices = () =>
    booking.selected.services.map((service: SelectedService) => {
      const detailedService = booking.data.services.find(
        (serviceItem: AvailableService) => serviceItem.id === service.id,
      );

      if (detailedService) return detailedService;

      const emptyAvailableService: AvailableService = {
        isSingleDateAndTime: false,
        id: '',
        title: '',
        orgId: '',
        locationIds: [],
        durationInMinutes: 0,
        priceInCents: 0,
        maxParticipants: 0,
        coaches: [],
        assistanceStaff: [],
        isPackage: false,
        scheduledDates: [],
        isNeverEnding: false,
        isPresetWorkHours: false,
        allowGuests: false,
        isInviteOnly: false,
        isFlexibleStartTime: false,
        packageOccurrences: 0,
        packageStartDate: 0,
        isPaymentInApp: false,
        isFullyBooked: false,
        isPackageCancelled: false,
        chosenCoachId: '',
      };

      return emptyAvailableService;
    });

  const getChosenCoachFromService = (serviceId: string): string | null => {
    const curService = booking.selected.services.find((service) => service.id === serviceId);
    const curDetailedService = getServiceDetails(curService?.id ?? '');
    if (curService?.coachId) return curService.coachId;
    if (curDetailedService?.coaches[0].id) return curDetailedService?.coaches[0].id;
    return null;
  };

  const getCoachWorkHours = ({ serviceId, coachId, dayOfWeek }: GetCoachWorkHoursInput) => {
    const curService = booking.data.services.find((service) => service.id === serviceId);
    if (curService) {
      const curCoach = curService.coaches.find((coach) => coach.id === coachId);
      if (curCoach) {
        const workHours = curCoach.workingHours?.workingHours.find(
          (item) => item.dayOfWeekEnum === dayOfWeek,
        );
        return workHours;
      }
    }
    return null;
  };

  /** Service & Lesson related */
  const getDetailedSelectedLessons = () => {
    const selectedLessonIds = booking.selected.services.map((service) => service.id);
    return booking.data.services
      .filter((service) => selectedLessonIds.includes(service.id))
      .filter((service) => !service.isPackage || ServicesService.isPurchased(service))
      .map((service) => {
        const curService = booking.selected.services.find(
          (selectedService) => selectedService.id === service.id,
        );
        return { ...service, addedAt: curService?.addedAt };
      })
      .sort((a, b) => (a.addedAt as number) - (b.addedAt as number))
      .map((service) => ({ ...service, addedAt: undefined }));
  };

  const getTotalTimeSlotsNeededToBeSelected = () => {
    let total = 0;
    booking.selected.services.forEach((service) => {
      const curDetailedService = getServiceDetails(service.id);
      if (curDetailedService) {
        if (!curDetailedService.isPackage || ServicesService.isPurchased(curDetailedService))
          total += service.count ?? 0;
      }
    });
    return total;
  };

  const getUpdatedServicesWithPositions = (servicePositionsLefts: ServicePositionsLeft[]) => {
    // Destructure the services directly from the booking data.
    const { services } = booking.data;
    const updatedServices = services.map((service: AvailableService) => {
      // Clone the service object to avoid mutation of the original data.
      const updatedService = { ...service };
      // Use `.find()` to locate a matching service position object.
      const positionData = servicePositionsLefts.find(
        (sp: ServicePositionsLeft) => sp.id === updatedService.id,
      );

      if (positionData) {
        // Simplify conditions by checking for package type or lesson invite once.
        if (
          !isServiceTimeslotSelectionFlowNeeded(updatedService) ||
          !!booking.selected.lessonInviteId
        ) {
          updatedService.packageParticipants = positionData.participants;
          updatedService.packagePositionsLeft = positionData.positionsLeft;
        } else if (updatedService.chosenDates) {
          // Find the matching date and update it directly.
          const updatedChosenDates = updatedService.chosenDates.map((date: ScheduledDate) => {
            if (date.dateStart === positionData.dateStart) {
              return {
                ...date,
                positionsLeft: positionData.positionsLeft,
                participants: positionData.participants,
              };
            }
            return date;
          });

          updatedService.chosenDates = updatedChosenDates;
        }
      }

      // The updatedService object is implicitly returned and pushed to the updatedServices array.
      return updatedService;
    });

    return updatedServices;
  };

  const handleSelectLessonCard = (serviceId: string) => {
    const tmpServicesList = booking.selected.services.map((service) => ({
      ...service,
      isCalendarActive: false,
    }));
    const selectedLessonIndex = tmpServicesList.findIndex((service) => service.id === serviceId);
    tmpServicesList[selectedLessonIndex] = {
      ...tmpServicesList[selectedLessonIndex],
      isCalendarActive: true,
    };

    dispatch(bookingSlice.actions.updateSelectedServices(tmpServicesList));
  };

  const handleTimezone = (timezone: string) => {
    dispatch(bookingSlice.actions.updateTimezone(timezone));
  };

  const handleLessonScroll = (serviceId: string) => {
    const servicesContainer = document.getElementById('lessons-container');
    const element = document.getElementById(`lesson-${serviceId}`);
    const serviceIndex = booking.selected.services.findIndex((service) => service.id === serviceId);
    let topPos = element ? element.offsetTop : 0;
    if (serviceIndex === 0) topPos = 0;
    if (servicesContainer) {
      servicesContainer.scrollTop = topPos;
    }
  };

  /** Lesson related - potential to be moved to useLesson hook later */
  const addStartEndTimeToActiveSelectedService = (
    dateStart: number,
    dateEnd: number,
  ): BookedServices => {
    const tmpBookedServices = [...booking.selected.services];
    const bookedServiceIndex = tmpBookedServices.findIndex((service) => service.isCalendarActive);
    const tmpScheduledDates = [...tmpBookedServices[bookedServiceIndex].scheduledDates];
    tmpScheduledDates.push({ dateStart, dateEnd });

    tmpBookedServices[bookedServiceIndex] = {
      ...tmpBookedServices[bookedServiceIndex],
      scheduledDates: tmpScheduledDates,
    };

    return tmpBookedServices;
  };

  const cancelStartEndTimeToActiveSelectedService = (dateStart: number): BookedServices => {
    const tmpBookedServices = [...booking.selected.services].map((service) => ({
      ...service,
      isCalendarActive: false,
    }));

    const bookedServiceIndex = tmpBookedServices.findIndex((service) => {
      const isTimeSlotFound =
        service.scheduledDates.find((slot) => slot.dateStart === dateStart) !== undefined;
      return isTimeSlotFound;
    });

    const tmpScheduledDates = [...tmpBookedServices[bookedServiceIndex].scheduledDates].filter(
      (item) => item.dateStart !== dateStart,
    );

    tmpBookedServices[bookedServiceIndex] = {
      ...tmpBookedServices[bookedServiceIndex],
      scheduledDates: tmpScheduledDates,
      isCalendarActive: true,
    };

    return tmpBookedServices;
  };

  const getNextUnbookedLesson = (bookedServices: BookedServices) => {
    if (bookedServices.length > 1) {
      const tmpIndex = bookedServices.findIndex(
        (service) => (service.count ?? 0) > service.scheduledDates.length,
      );

      if (tmpIndex >= 0) {
        const tmpBookedServices = [...bookedServices].map((service) => ({
          ...service,
          isCalendarActive: false,
        }));

        tmpBookedServices[tmpIndex].isCalendarActive = true;
        return tmpBookedServices;
      }
    }
    return bookedServices;
  };

  const handleConfirmLessonDateTime = (dateStart: number, dateEnd: number) => {
    const oldActiveLesson = getActiveSelectedService();

    const bookedServicesAfterStartEndAdded = addStartEndTimeToActiveSelectedService(
      dateStart,
      dateEnd,
    );

    const bookedServicesAfterNextLessonSelected = getNextUnbookedLesson(
      bookedServicesAfterStartEndAdded,
    );

    const newActiveLesson = getActiveSelectedServiceFromArray(
      bookedServicesAfterNextLessonSelected,
    );

    if (newActiveLesson && oldActiveLesson && newActiveLesson?.id !== oldActiveLesson?.id) {
      handleLessonScroll(newActiveLesson?.id);
    }

    dispatch(bookingSlice.actions.updateSelectedServices(bookedServicesAfterNextLessonSelected));
  };

  const handleCancelLessonDateTime = (dateStart: number) => {
    setTimeout(() => {
      const bookedServicesAfterStartEndRemoved =
        cancelStartEndTimeToActiveSelectedService(dateStart);
      dispatch(bookingSlice.actions.updateSelectedServices(bookedServicesAfterStartEndRemoved));
    }, 1);
  };

  const getOrgDetails = async (orgId: string) => {
    const bookingRules = await OrganizationHelper.GetOrgBookingRulesV2({ orgId });
    const orgDetails = await OrganizationHelper.GetOrgDetailsV2({ orgId });

    if (orgDetails) {
      dispatch(applicationSlice.actions.getOrgDetails(orgDetails));
    }

    if (bookingRules) {
      dispatch(applicationSlice.actions.getOrgBookingRules(bookingRules));
    }

    dispatch(applicationSlice.actions.getOrgId(orgId));
  };

  const getLocationIds = (): string[] => {
    const selectedServices = getDetailedSelectedServices();
    const locationIds = [
      ...new Set(selectedServices.flatMap((service: AvailableService) => service.locationIds)),
    ];

    return locationIds;
  };

  const getCoachIds = (): string[] => {
    return Array.from(new Set(booking.selected.services.map((service) => service.coachId)));
  };

  const getCoachAndLocation = async (props: GetCoachLocationProps): Promise<CoachAndLocation> => {
    const { orgId, serviceParam } = props;
    const coachResult = await BookingHelper.GetSelectedCoach(serviceParam);
    const coachAndLocation: CoachAndLocation = {
      coachId: '',
      locationId: '',
    };

    if (!coachResult) {
      return coachAndLocation;
    }

    coachAndLocation.coachId = coachResult.id;
    dispatch(bookingSlice.actions.changeCoachId(coachResult.id));

    const locationResult = await BookingHelper.GetSelectedLocation(serviceParam, orgId);
    if (!locationResult) {
      return coachAndLocation;
    }

    coachAndLocation.locationId = locationResult.id;
    dispatch(bookingSlice.actions.changeLocationId(locationResult.id));

    return coachAndLocation;
  };

  const getServiceType = (
    serviceId: string,
  ): 'Lesson' | 'Lesson pack' | 'Programme' | 'Event' | 'Prepaid Lesson' | '' => {
    const serviceDetails = getServiceDetails(serviceId);
    if (serviceDetails) {
      if (isLesson(serviceDetails)) return 'Lesson';
      if (isLessonPackAndNotPurchased(serviceDetails)) return 'Lesson pack';
      if (isProgramme(serviceDetails)) return 'Programme';
      if (isEvent(serviceDetails)) return 'Event';
      if (isPurchasedService(serviceDetails)) return 'Prepaid Lesson';
    }
    return '';
  };

  const isTimeslotSelectionFlowNeeded = (): boolean => {
    let result = true;
    booking.selected.services.forEach((service) => {
      result = isServiceTimeslotSelectionFlowNeeded(service);
    });
    return result;
  };

  /**
   * For Detailed Selected Service
   * Note: Update the selected services and detailed selected services with additional dats like chosenCoachId, chosenDates, currentDateIndex
   * Added local variables in the case that variables in the store were not refreshing fast enough.
   */
  const getUpdatedServicesWithAdditionalData = (
    servicesLocal?: AvailableService[],
    selectedServicesLocal?: SelectedService[],
  ) => {
    const { services } = booking.data;
    const { services: selectedServices, coachId: bookingCoachId } = booking.selected;
    const currentServices = servicesLocal ?? services;

    // Map through each service to create an updated services list.
    const updatedDetailedServices = [];
    const updatedSelectedServices: SelectedService[] = [];
    for (let i = 0; i < currentServices.length; i++) {
      const service = currentServices[i];
      // Clone the service object to prevent mutation.
      const updatedService = { ...service };

      // Initialize common properties.
      updatedService.count = 0;
      updatedService.currentDateIndex = 0;
      updatedService.chosenCoachId = bookingCoachId ?? '';
      updatedService.chosenDates = [];

      // Find if the service is selected and update the chosen coach and count.
      const currentService = selectedServicesLocal ?? selectedServices;
      const selectedService = currentService.find((s: SelectedService) => s.id === service.id);

      if (selectedService) {
        const updatedSelectedService: SelectedService = {
          ...selectedService,
        };
        // Handle special cases based on the service type.
        if (updatedService.packageOccurrences === selectedService?.count) {
          updatedSelectedService.scheduledDates = updatedService.scheduledDates;
        } else if (!isServiceTimeslotSelectionFlowNeeded(updatedSelectedService)) {
          updatedSelectedService.scheduledDates = service.scheduledDates;
        } else if (!selectedService.scheduledDates) {
          // If a single count is chosen, reset chosen dates.
          updatedSelectedService.scheduledDates = [];
          if (updatedSelectedService && updatedSelectedService.count === 1)
            updatedSelectedService.scheduledDates = [];
          // Ensure chosenDates array is initialized before pushing.
          updatedSelectedService.scheduledDates.push({ dateStart: 0, dateEnd: 0 });
        }

        updatedSelectedServices.push(updatedSelectedService);
      }

      updatedService.count = selectedService?.count;
      updatedService.currentDateIndex = 0;
      updatedService.chosenDates = [];
      if (updatedService.packageOccurrences === selectedService?.count) {
        updatedService.chosenDates = updatedService.scheduledDates;
        updatedService.count = updatedService.scheduledDates.length;
      } else if (!isServiceTimeslotSelectionFlowNeeded(updatedService)) {
        updatedService.chosenDates = updatedService.scheduledDates;
        updatedService.count = updatedService.scheduledDates.length;
      } else if (
        /**
          isUpfrontPayment is only used to check whether the user already paid or not and it's not to be confused with the is_upfront_payment from lessons schema
          TODO: update isUpfrontPayment to a better name 
        */
        updatedService.serviceTypeEnum === ServiceTypeEnum.Package &&
        updatedService.isUpfrontPayment
      ) {
        updatedService.count = updatedService.unscheduledPackageOccurrences;
      } else if (
        BookingHelper.isServiceFixedStartTime(updatedService) &&
        updatedService.scheduledDates.length === 1
      ) {
        const scheduledDate = updatedService.scheduledDates[0];
        updatedService.chosenDates.push({
          dateStart: scheduledDate.dateStart,
          dateEnd: scheduledDate.dateEnd,
        });
        updatedService.isSingleDateAndTime = true;
      } else {
        updatedService.chosenDates.push({ dateStart: 0, dateEnd: 0 });
      }

      updatedDetailedServices.push(updatedService);
    }

    return { updatedDetailedServices, updatedSelectedServices };
  };

  return {
    getActiveSelectedService,
    getActiveSelectedServiceFromArray,
    getCoachWorkHours,
    getChosenCoachFromService,
    getServiceDetails,
    getDetailedSelectedServices,
    getOrgDetails,
    getLocationIds,
    getCoachIds,
    getDetailedSelectedLessons,
    getTotalTimeSlotsNeededToBeSelected,
    handleSelectLessonCard,
    handleTimezone,
    handleLessonScroll,
    handleConfirmLessonDateTime,
    handleCancelLessonDateTime,
    getCoachAndLocation,
    getUpdatedServicesWithPositions,
    getUpdatedServicesWithAdditionalData,
    getSelectedService,

    getServiceType,
    isPurchasedService,
    isLesson,
    isAdvancedLesson,
    isLessonPack,
    isLessonPackAndNotPurchased,
    isProgramme,
    isEvent,
    shouldPriceDisplayed,
    isTimeslotSelectionFlowNeeded,
    isServiceTimeslotSelectionFlowNeeded,
    isDetailedServiceTimeslotSelectionFlowNeeded,
  };
};
