import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { BookingStep, bookingSlice } from 'redux/slices';
import {
  LocationSelectionItem,
  BookingSelectionItem,
  CoachSelectionItem,
  BookingContainer,
} from './components';
import type { RootState } from '../../../../redux/store';
import { Grid } from '../../../../components/Grid';
import { useOrgId, useAxiosFetch, useAuth } from '../../../../common/hooks';
import { Api } from '../../../../api/Api';
import { SignInDialog } from './components/signInDialog';
import { SignUpDialog } from './components/signUpDialog';

type SearchByOption = 'location' | 'coach';

export const BookingSelectCoachLocation = () => {
  const { orgId } = useOrgId();
  const { isAuthorized, authorize } = useAuth();
  useEffect(() => {
    authorize();
  }, []);

  /** Redux slice, actions and dispatch */
  const { changeLocationId, changeCoachId } = bookingSlice.actions;
  const booking = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  /** By default set option to location */
  const [selectedSearchByOption, setSelectedSearchByOption] =
    React.useState<SearchByOption>('location');

  /** Automatically fetch locations and coaches when component is loaded */
  const { isLoading: isLoadingLocations } = useAxiosFetch({
    fetchFn: () => Api.ClientRoutes.Org.getLocationsImageAndText({ orgId }),
    dispatchFn: bookingSlice.actions.getLocations,
  });

  const { isLoading: isLoadingCoaches } = useAxiosFetch({
    fetchFn: () => Api.ClientRoutes.Org.getCoachesImageAndText({ orgId }),
    dispatchFn: bookingSlice.actions.getCoaches,
  });

  /** Is page finished loading necessary initial data */
  const isLoading = isLoadingCoaches || isLoadingLocations;

  /** Auto select location if there is only 1 location */
  React.useEffect(() => {
    if (booking.data.locations.length === 1) {
      const chosenLocation = booking.data.locations[0];
      dispatch(bookingSlice.actions.changeLocationId(chosenLocation.id));
    }
  }, [booking.data.locations]);

  /** Auto select coach if there is only 1 coach */
  React.useEffect(() => {
    if (booking.data.coaches.length === 1) {
      const chosenCoach = booking.data.coaches[0];
      dispatch(bookingSlice.actions.changeCoachId(chosenCoach.id));
    }
  }, [booking.data.coaches]);

  /** Add handler when an option is clicked */
  const handleSwitchType = (option: SearchByOption) => {
    setSelectedSearchByOption(option);
    if (option === 'coach') dispatch(bookingSlice.actions.changeLocationId(null));
    else {
      dispatch(bookingSlice.actions.changeCoachId(null));
    }
  };

  return (
    <BookingContainer
      isLoading={isLoading}
      title="Select Coach and Location"
      continueButton={{
        onClick: () => dispatch(bookingSlice.actions.changeStep(BookingStep.SelectService)),
        disabled: !booking.selected.coachId && !booking.selected.locationId,
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl fullWidth>
            <FormLabel id="searchBy">Search By</FormLabel>
            <Box display="flex" justifyContent="space-between" width="100%">
              <RadioGroup row aria-labelledby="searchBy" name="row-radio-buttons-group">
                <FormControlLabel
                  value="location"
                  control={
                    <Radio
                      checked={selectedSearchByOption === 'location'}
                      onChange={() => handleSwitchType('location')}
                    />
                  }
                  label="Location"
                />
                <FormControlLabel
                  value="coach"
                  control={
                    <Radio
                      checked={selectedSearchByOption === 'coach'}
                      onChange={() => handleSwitchType('coach')}
                    />
                  }
                  label="Coach"
                />
              </RadioGroup>
            </Box>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <Card>
            <CardContent>
              {selectedSearchByOption === 'location' && (
                <Box padding={0}>
                  <Typography gutterBottom variant="h5" component="div" marginBottom={2}>
                    Select Location
                  </Typography>
                  <Grid container spacing={2}>
                    {booking.data.locations.map((location) => {
                      return (
                        <Grid item md={12} key={location.id}>
                          <BookingSelectionItem
                            isSelected={location.id === booking.selected.locationId}
                          >
                            <LocationSelectionItem
                              id={location.id}
                              name={location.name}
                              address={location.address}
                              imageUrl={location.imageAndText ? location.imageAndText.iconUrl : ''}
                              onClick={(selectedId) => dispatch(changeLocationId(selectedId))}
                            />
                          </BookingSelectionItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              )}
              {selectedSearchByOption === 'coach' && (
                <Box padding={0}>
                  <Typography gutterBottom variant="h5" component="div" marginBottom={2}>
                    Select Coach
                  </Typography>
                  <Grid container spacing={2}>
                    {booking.data.coaches.map((coach) => {
                      return (
                        <Grid item md={12} key={coach.id}>
                          <BookingSelectionItem isSelected={coach.id === booking.selected.coachId}>
                            <CoachSelectionItem
                              id={coach.id}
                              firstName={coach.firstName}
                              lastName={coach.lastName}
                              imageUrl={coach.imageAndText ? coach.imageAndText.iconUrl : ''}
                              onClick={(selectedId) => dispatch(changeCoachId(selectedId))}
                            />
                          </BookingSelectionItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {!isAuthorized && (
        <>
          <SignInDialog />
          <SignUpDialog />
        </>
      )}
    </BookingContainer>
  );
};
