/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { isBefore } from 'date-fns';
import { OrgCountLessons } from 'data/entities/orgLessonCategories.entity';
import RealmRepositories from '../base/realm.repo';
import { SportType } from '../enums/SportType.enum';
import UserService from './user.service';
import { UserType } from '../enums/UserType.enum';
import { OrgListModel } from '../entities/organization.entity';
import { Invite } from '../entities/orgInvites.entity';
import { ClientInvite } from '../entities/orgClientsInvites.entity';
import { JourneyMap } from '../../modules/home/components/GolfJourneyMap/GolfJourneyMap';
import Logger from '../../middleware/logger.middleware';
import { Conversions } from '../../common/utils/conversions.helper';
import { OrgBookingRules } from '../../modules/book/models/booking.model';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';
import { IsSuccessfulModel } from '../common/models/isSuccessful.model';
import SessionProvider from '../../providers/SessionProvider';
import { BookingFormatter } from '../../modules/book/utils/booking.helpers';

export interface ValidationResult {
  isActiveInviteAndCorrectUser: boolean;
  errorMessage: string;
  orgName: string;
}

export interface IsPromoCodeValidResult {
  message?: string;
  isValid: boolean;
}

export default class OrganizationService {
  static async getOrgByIdAsync(orgId: string) {
    const orgResult = await RealmRepositories.Organization.getOrgByIdAsync(orgId);
    return orgResult;
  }

  static async getOrgOwnerUserEmail(orgId: string) {
    const orgResult = await OrganizationService.getOrgByIdAsync(orgId);
    if (!orgResult) {
      return undefined;
    }

    const user = await UserService.getUserAsync(orgResult.user_id);
    return user?.email;
  }

  static async getOrgByIdAsGuestAsync(orgId: string) {
    const orgResult = await RealmRepositories.Organization.getOrgByIdAsGuestAsync(orgId);
    return orgResult;
  }

  static async getUserOrgList(userId: string) {
    // TODO: 05.04.23 - PERF - This can be updated to use an aggregation.
    const userResult = await RealmRepositories.Users.getUser(userId);
    if (!userResult) {
      if (Logger.isDevEnvironment) {
        console.error('Could not find user using id: ', userId);
      }
      return [];
    }

    const orgArr = userResult.orgs;

    const orgResult = await RealmRepositories.Organization.getOrgsByIds(orgArr);

    if (!orgResult) {
      if (Logger.isDevEnvironment) {
        console.error('Could not find user orgs in organizations', orgArr);
      }
      return [];
    }

    const orgListResult: OrgListModel[] = [];
    for await (const org of orgResult) {
      const userType = await this.getUserTypeByOrgId(org._id, userId);
      let icon = '';
      if (org.icon) {
        const iconFile = await RealmRepositories.Files.getFileByIdAsync(org.icon);
        icon = iconFile.file_url;
      }
      if (userType === UserType.client) {
        orgListResult.push({
          orgId: org._id,
          orgName: org.org_name,
          sportType: org.sport_type_enum,
          icon,
        });
      }
    }

    return orgListResult;
  }

  static async getOrgSportType(orgId: string): Promise<SportType> {
    const orgResult = await RealmRepositories.Organization.getOrganizationSportType(orgId);
    if (orgResult === undefined || orgResult === null) {
      return SportType.Sports;
    }

    return orgResult;
  }

  static async getUserTypeByOrgId(orgId: string, userId: string): Promise<UserType | null> {
    const orgClientResult = await RealmRepositories.OrgClients.getClientByUserIdAsync(
      orgId,
      userId,
    );
    if (orgClientResult) {
      return UserType.client;
    }

    const orgStaffResult = await RealmRepositories.OrgStaff.getStaffByUserIdAsync(orgId, userId);
    if (orgStaffResult) {
      return UserType.coach;
    }

    return null;
  }

  static async validateOrgInvite(
    userType: UserType,
    orgId: string,
    inviteId: string,
    userEmail: string,
  ): Promise<ValidationResult> {
    const returnResult: ValidationResult = {
      isActiveInviteAndCorrectUser: false,
      errorMessage: 'Could not find invitation for organization',
      orgName: '',
    };

    let orgInvitesResult;
    if (userType === UserType.client) {
      orgInvitesResult = await RealmRepositories.OrgClientsInvites.getInvitesForOrgAsync(orgId);
    } else if (userType === UserType.coach) {
      orgInvitesResult = await RealmRepositories.OrgInvites.getInvitesForOrgAsync(orgId);
    } else {
      console.error('Out of bounds exception for userType.');
      returnResult.errorMessage =
        'There was an error processing your request. Please contact support.';
      return returnResult;
    }

    if (!orgInvitesResult) {
      returnResult.errorMessage = 'Could not find invitation for organization';
      return returnResult;
    }

    let invitesIterator: (Invite | ClientInvite)[] | undefined;

    switch (userType) {
      case UserType.client:
        invitesIterator = orgInvitesResult.client_invites;
        break;
      case UserType.coach:
        invitesIterator = orgInvitesResult.invites;
        break;
      default:
        break;
    }

    const currentInvite = invitesIterator?.filter((invite) => invite._id === inviteId)[0];

    if (!currentInvite) {
      returnResult.errorMessage =
        'Could not find your invitation for the organization. Your invitation may have been removed.';
      return returnResult;
    }

    if (currentInvite.is_accepted) {
      returnResult.errorMessage = 'You have already accepted this invitation.';
      return returnResult;
    }

    if (currentInvite.email !== userEmail) {
      returnResult.errorMessage = `You don’t have permission to accept this invitation.
      Please login with ${currentInvite.email} to accept this invitation.`;
      return returnResult;
    }

    returnResult.isActiveInviteAndCorrectUser = true;

    const orgResult = await RealmRepositories.Organization.getOrgByIdAsync(orgId);
    if (orgResult) {
      returnResult.orgName = orgResult.org_name;
    }

    return returnResult;
  }

  static async getOrgLessonCategoriesById(orgId: string): Promise<any> {
    const result = await RealmRepositories.OrgLessonCategory.getByOrgIdAsync(orgId);
    return result?.lesson_categories;
  }

  static async getCategoryWiseLessonCounts(
    orgId: string,
    userId: string,
    completeAthleteData = false,
  ) {
    const result: JourneyMap[] = [];

    const completeAthleteChartCategory = ['Physical', 'Tactical', 'Mental', 'Ranking'];
    const orgLessonCategories = (await this.getOrgLessonCategoriesById(orgId)) ?? [];

    const orgLessonCounts =
      await RealmRepositories.OrgLessonCategory.getLessonsCountByOrgIdUserIdAsync(userId, orgId);

    for (const [, category] of orgLessonCategories.entries()) {
      const cat_id = category?._id;
      const lesson_index = orgLessonCounts.map((item: OrgCountLessons) => item._id).indexOf(cat_id);
      const lessons = lesson_index >= 0 ? orgLessonCounts[lesson_index].count : 0;

      if (!completeAthleteData) {
        result.push({
          name: category.name ?? '',
          categories: [category._id],
          lessonsCount: lessons ?? 0,
        });
      }
      if (completeAthleteData && completeAthleteChartCategory.includes(category?.name ?? '')) {
        result.push({
          name: category.name ?? '',
          categories: [category._id],
          lessonsCount: lessons ?? 0,
        });
      }
    }

    return result;
  }

  static async isPromoCodeValid(
    userId: string,
    orgId: string,
    code: string,
  ): Promise<IsPromoCodeValidResult> {
    const timezone = SessionProvider.getTimeZoneInfo();
    const promoCode = await RealmRepositories.PromoCodes.getByOrgIdAndCode(orgId, code);
    if (!promoCode) return { message: 'Promo code is not valid.', isValid: false };

    if (!promoCode.active || promoCode.deleted)
      return { message: 'Promo code is no longer active.', isValid: false };

    const currentTime = getUnixMilliseconds();
    if (isBefore(promoCode.expiry_date, currentTime)) {
      const formattedDate = BookingFormatter.formatDate(promoCode.expiry_date, timezone);
      return {
        message: `Promo code has expired on ${formattedDate}.`,
        isValid: false,
      };
    }

    if (promoCode.max_limit !== undefined && promoCode.is_max_limit_reached === true)
      return { message: 'Promo code has reached maximum redemptions.', isValid: false };

    const clientMaxLimitReached = promoCode.client_usage?.find(
      (x) => x.user_id === userId && x.is_max_limit_reached === true,
    );
    if (clientMaxLimitReached)
      return { message: 'You have reached your maximum promo code usage', isValid: false };

    return {
      message: '',
      isValid: true,
    };
  }

  static async getOrgBookingRules(orgId: string) {
    const orgBookingRules = await RealmRepositories.Organization.getBookingRules(orgId);
    if (orgBookingRules === undefined || orgBookingRules === null) {
      return undefined;
    }

    const convertedOrgBookingRules: OrgBookingRules = Conversions.keysToCamel(orgBookingRules);
    return convertedOrgBookingRules;
  }

  /**
   * Validates the user's org_client invitation.
   * - Matches invitationId
   * - Matches email
   * - Checks if it is already accepted
   * @param orgId
   * @param inviteId org_clients.client_invites._id
   * @param email
   * @returns isSuccessfulModel
   */
  static async validateOrgClientInvite(
    orgId: string,
    inviteId: string,
    email: string,
  ): Promise<IsSuccessfulModel> {
    const result: IsSuccessfulModel = { isSuccess: false };
    const orgInvitesResult = await RealmRepositories.OrgClientsInvites.getInvitesForOrgAsync(orgId);
    if (!orgInvitesResult)
      return {
        ...result,
        errors: [{ key: 'getInvitesForOrgAsync', value: 'Could not find matching invite.' }],
      };

    const foundInviteById = orgInvitesResult.client_invites.find((x) => x._id === inviteId);
    if (!foundInviteById)
      return {
        ...result,
        errors: [{ key: '!foundInviteById', value: 'Could not find invitation via invitation id' }],
      };

    const doesEmailMatchInvite = foundInviteById.email.toLowerCase() === email.toLowerCase();
    if (!doesEmailMatchInvite)
      return {
        ...result,
        errors: [{ key: '!doesEmailMatchInvites', value: 'Email does not match invitation' }],
      };

    return { isSuccess: true };
  }
}
