/* eslint-disable no-restricted-syntax */
import { AttachmentSectionIndex } from 'modules/attachment/models/attachment';
import { Config } from '../../config';
import { Attachment } from '../entities/attachment.entity';
import { DeleteResult, InsertResult, UpdateResult } from '../types/realm.types';
import { BaseRepo } from './base.repo';

export class AttachmentsRepository extends BaseRepo {
  // #region Private Properties
  private attachmentCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('attachment');

  private lessonCollection = this.mongo?.db(Config().RealmDbName as string).collection('lessons');

  private fileCollection = this.mongo?.db(Config().RealmDbName as string).collection('file');

  // #endregion

  // #region Public methods
  async insertAttachment(attachment: Attachment): Promise<InsertResult> {
    return this.attachmentCollection?.insertOne(attachment);
  }

  async updateAttachmentTypeById(noteId: string, stringifiedType: string) {
    await this.attachmentCollection?.findOneAndUpdate(
      { _id: noteId },
      { $set: { attachment_type: stringifiedType } },
    );
  }

  async deleteAttachmentById(id: string): Promise<DeleteResult> {
    return this.attachmentCollection?.deleteOne({ _id: id });
  }

  async getAttachmentById(id: string): Promise<Attachment | undefined> {
    return this.attachmentCollection?.findOne({ _id: id });
  }

  async getAttachmentsByIds(ids: string[]) {
    return this.attachmentCollection?.find(
      {
        _id: { $in: ids },
        deleted: false,
      },
      { sort: { date_created: 1 } },
    );
  }

  async removeClientLessonUploadById(
    lessonId: string,
    attachmentId: string,
    sectionIndex: number,
  ): Promise<UpdateResult> {
    switch (sectionIndex) {
      case AttachmentSectionIndex.ClientUploads:
        return this.lessonCollection?.updateOne(
          { _id: lessonId },
          { $pull: { client_uploads: attachmentId } },
        );
      case AttachmentSectionIndex.ReasonOfLesson:
        return this.lessonCollection?.updateOne(
          { _id: lessonId },
          { $pull: { reason_uploads: attachmentId } },
        );
      case AttachmentSectionIndex.TheSolution:
        return this.lessonCollection?.updateOne(
          { _id: lessonId },
          { $pull: { solution_uploads: attachmentId } },
        );
      default:
        return this.lessonCollection?.updateOne(
          { _id: lessonId },
          { $pull: { client_uploads: attachmentId } },
        );
    }
  }

  async getFilesByIds(fileIds: string[]) {
    if (!fileIds || fileIds.length === 0) {
      return [];
    }

    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.fileCollection = mongo.db(Config().RealmDbName as string).collection('file');
    }

    const files = await this.fileCollection?.find(
      {
        _id: { $in: fileIds },
      },
      { sort: { date_created: 1 } },
    );
    return files;
  }

  async setAttachmentDeletedField(attachmentId: string, deletedField: boolean) {
    return this.attachmentCollection?.findOneAndUpdate(
      { _id: attachmentId },
      {
        $set: {
          deleted: deletedField,
        },
      },
    );
  }

  // #endregion
}
