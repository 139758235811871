import { differenceInMinutes, isBefore } from 'date-fns';
import UserService from 'data/services/user.service';
import { Lesson, LessonClientStatus } from '../../data/entities/lessons.entity';
import SessionProvider from '../../providers/SessionProvider';
import { getUnixMilliseconds } from './date.helpers';

export enum LessonCancellation {
  Cancelled = 0,
  Past,
  SelfTaught,
  BeforePolicyDuration,
  AfterPolicyDuration,
}

export default class LessonHelper {
  public static isPackage(lesson: Lesson) {
    return (
      lesson.is_package === true &&
      lesson.package_id !== undefined &&
      lesson.total_package_lessons !== undefined &&
      lesson.total_package_lessons > 0
    );
  }

  /**
   * - Has the coach canceled the lesson?
   * - or Has the user canceled the lesson?
   * @param lesson
   * @returns Passes all checks: TRUE
   */
  public static isLessonCancelled(lesson: Lesson) {
    const currentUserId = SessionProvider.getUserId();
    if (!currentUserId) {
      console.warn('UserId is null. User is not allowed to cancel lesson');
      return false;
    }

    const hasCoachCancelledLesson =
      lesson.cancellation === null || lesson.cancellation === undefined
        ? false
        : lesson.cancellation?.is_cancelled === true;

    const hasUserCancelledLesson =
      lesson.clients.find((lessonClient) => {
        return lessonClient.user_id === currentUserId;
      })?.status === LessonClientStatus.Cancelled;

    const isLessonCancelled = hasCoachCancelledLesson || hasUserCancelledLesson;

    return isLessonCancelled;
  }

  /**
   * Runs through a series of checks
   * - Has the coach canceled the lesson?
   * - Has the client canceled the lesson?
   * - Is the lesson in the past?
   * - Is the lesson self taught?
   * - Does it meet the cancellation policy?
   * @param lesson
   * @param cancellationPeriodInMinutes
   * @returns LessonCancellation
   */
  public static GetCancellationResult(lesson: Lesson, cancellationPeriodInMinutes: number) {
    const dateBegin = lesson.date_start;
    const currentDate = getUnixMilliseconds();

    const isLessonCancelled = LessonHelper.isLessonCancelled(lesson);

    if (isLessonCancelled) {
      return LessonCancellation.Cancelled;
    }

    if (isBefore(dateBegin, currentDate)) {
      return LessonCancellation.Past;
    }

    if (lesson.is_self_taught === true) {
      return LessonCancellation.SelfTaught;
    }

    if (differenceInMinutes(dateBegin, currentDate) > cancellationPeriodInMinutes) {
      return LessonCancellation.BeforePolicyDuration;
    }

    return LessonCancellation.AfterPolicyDuration;
  }

  /**
   *
   * @param lessonCancellation
   * @returns TRUE: Lesson is not canceled or in the past
   */
  public static AllowCancellation(lessonCancellation: LessonCancellation) {
    return (
      lessonCancellation !== LessonCancellation.Cancelled &&
      lessonCancellation !== LessonCancellation.Past
    );
  }

  public static LessonCancelledToastMessage(lesson: Lesson, cancellationPeriodInMinutes: number) {
    let toastMessage = 'Your lesson has been canceled successfully.';
    const lessonCancellationResult = LessonHelper.GetCancellationResult(
      lesson,
      cancellationPeriodInMinutes,
    );

    if (
      !lesson.is_self_taught &&
      LessonHelper.isPackage(lesson) === false &&
      lessonCancellationResult === LessonCancellation.BeforePolicyDuration
    ) {
      toastMessage += ' An email has been sent regarding your credit.';
    }

    return toastMessage;
  }

  public static PackageCancelledToastMessage() {
    return 'Your package has been canceled successfully';
  }

  public static isLessonEditable(lesson: Lesson) {
    return lesson.max_participants === 1;
  }

  public static async isGroupLesson(lesson: Lesson) {
    if (!lesson.is_self_taught) return lesson.max_participants > 1;

    const userId = SessionProvider.getUserId();
    const orgId = SessionProvider.getOrgId();
    const associates = await UserService.getAssociatesByUserIdOrgIdAsync(userId, orgId);

    if (associates) return associates.length > 0;
    return false;
  }
}
