import React from 'react';

import getResizedImageUri from '../../../../common/utils/getResizedImageUri.helpers';
import styles from './PreviewFavoriteItem.module.scss';
// import { AttachmentType } from '../../../attachment/models/attachment';
import PlayIcon from '../../../../assets/images/play.png';
import PdfIcon from '../../../../assets/images/FilePDF.svg';

interface IProps {
  thumbnailUrl: string;
  isFile: boolean;
  note: string;
  isVideo: boolean;
  extension: string | null;
}

const PreviewFavoriteItem = ({ thumbnailUrl, isFile, note, isVideo, extension }: IProps) => {
  return (
    <div className={styles.item}>
      {isFile && thumbnailUrl !== '' && extension !== 'pdf' && (
        <img
          src={getResizedImageUri(thumbnailUrl, '128x128')}
          alt="favorite"
          className={styles.thumbnail}
        />
      )}
      {extension && extension === 'pdf' && <img alt="" src={PdfIcon} />}
      {!isFile && (
        <div className={styles.text}>
          <p>{note}</p>
        </div>
      )}
      {isVideo && <img className={styles.playIcon} alt="play-icon" src={PlayIcon} />}
    </div>
  );
};

export default PreviewFavoriteItem;
