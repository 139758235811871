import React from 'react';
import styles from './CancelLessonViewBody.module.scss';
import { getZonedDate } from '../../../../../common/utils/date.helpers';
import { BookingFormatter } from '../../../../book/utils/booking.helpers';
import SessionProvider from '../../../../../providers/SessionProvider';
import { Lesson } from '../../../../../data/entities/lessons.entity';

interface NoCreditMessageProps {
  lessonPrice: string;
}

export const NoCreditMessage = ({ lessonPrice }: NoCreditMessageProps) => {
  return (
    <span>
      By canceling this lesson you confirm you <b>won’t</b> receive <b>{lessonPrice}</b> TotalCoach
      credit.
    </span>
  );
};

interface CancellationInfoMessageProps {
  lessonCancellationInfo: string;
  lessonPrice: string;
  showNoCreditMessage: boolean;
}

const CancellationInfoMessage = ({
  lessonCancellationInfo,
  lessonPrice,
  showNoCreditMessage,
}: CancellationInfoMessageProps) => {
  return (
    <>
      {lessonCancellationInfo}{' '}
      {showNoCreditMessage && <NoCreditMessage lessonPrice={lessonPrice} />}
    </>
  );
};

interface CancelLessonBodyProps {
  isSelfTaught: boolean;
  lesson: Lesson;
  lessonPrice: string;
  cancellationPeriodInMinutes: number;
  isBeforePolicyDuration: boolean;
  isAfterPolicyDuration: boolean;
  lessonCancellationInfo: string;
  showNoCreditMessage: boolean;
  reason: string;
  setReason: (value: string) => void;
}

export const CancelLessonBody = ({
  isSelfTaught,
  lesson,
  lessonPrice,
  cancellationPeriodInMinutes,
  isBeforePolicyDuration,
  isAfterPolicyDuration,
  lessonCancellationInfo,
  showNoCreditMessage,
  reason,
  setReason,
}: CancelLessonBodyProps) => {
  let formattedDate = '';
  if (!isSelfTaught) {
    const lastAvailableCreditDate = getZonedDate(
      lesson.date_start,
      SessionProvider.getTimeZoneInfo(),
    ).minus({ minutes: cancellationPeriodInMinutes });
    formattedDate = BookingFormatter.formatDate(
      lastAvailableCreditDate.toMillis(),
      SessionProvider.getTimeZoneInfo(),
    );
  }

  return (
    <>
      {!isSelfTaught && (
        <p className="my-1 text-muted text-wrap">Free cancelation until {formattedDate}</p>
      )}

      {isBeforePolicyDuration && (
        <p className="text-muted text-wrap">
          <CancellationInfoMessage
            lessonCancellationInfo={lessonCancellationInfo}
            lessonPrice={lessonPrice}
            showNoCreditMessage={showNoCreditMessage}
          />
        </p>
      )}

      {isAfterPolicyDuration && (
        <div className="alert alert-danger text-wrap" role="alert">
          <CancellationInfoMessage
            lessonCancellationInfo={lessonCancellationInfo}
            lessonPrice={lessonPrice}
            showNoCreditMessage={showNoCreditMessage}
          />
        </div>
      )}
      <textarea
        className={styles.textarea}
        placeholder="Enter your reason for cancelation"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
      />
    </>
  );
};
