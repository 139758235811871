import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'redux/store';
import { useAuth } from 'common/hooks';
import Spinnerv2 from '../components/Spinnerv2/Spinnerv2';

interface AuthenticatedPagesProps {
  children: React.ReactElement;
}

/**
 * Encapsules pages that needs to be authenticated by re-checking if the user has been authenticated from Redux.
 *
 * If the user is authenticated, it will return the children otherwise, will redirect the user to the login page.
 *
 * Once updated to React Router 6, we will use nested routes with Outlet component
 * @param { children }
 * @returns
 */

export const AuthenticatedPages = ({ children }: AuthenticatedPagesProps) => {
  const history = useHistory();
  const location = useLocation();
  const authentication = useSelector((state: RootState) => state.authentication);
  const paramsResult = useAuth().params;
  const fromString = location.pathname;

  paramsResult.append('from', location.pathname);

  if (authentication.isVerifyingToken) {
    return <Spinnerv2 message="Authorising..." />;
  }

  if (!authentication.isAuthenticated) {
    history.push({
      pathname: '/login',
      search: paramsResult.toString(),
      state: { from: fromString },
    });
    return null;
  }

  return authentication.isAuthenticated ? children : null;
};
