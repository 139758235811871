import React from 'react';
import { floor } from 'lodash';
import Button from 'components/Button/Button';
import { UserSubscriptionsModel } from 'data/models/userSubscription.mode';
import { BillingHelper } from 'common/utils/billing.helper';
import ListPageLayout from '../../../../components/Layout/ListPageLayout/ListPageLayout';
import CalendarFilters from '../../components/CalendarFilters/CalendarFilters';
import { Event } from '../../../../data/entities/events.entity';
import List, { ListType } from '../../../../components/List/List';
import CalendarPageHeader from '../../components/CalendarPageHeader/CalendarPageHeader';
import EventView from '../../components/EventView/EventView';
import DeleteEventView from '../../components/DeleteEventView/DeleteEventView';
import { getDateFromIsoString, now } from '../../../../common/utils/date.helpers';
import { EventType, Lesson } from '../../../../data/entities/lessons.entity';
import Spinnerv2 from '../../../../components/Spinnerv2/Spinnerv2';
import styles from './CalendarPage.module.scss';
import { useCalendarPage } from './useCalendarPage.hooks';

export interface CalendarProps {
  subscriptionInfo?: UserSubscriptionsModel;
}

const CalendarPage = ({ subscriptionInfo }: CalendarProps) => {
  const {
    isLoading,
    isCancellingLesson,
    isCancellingPackage,
    isPracticeLesson,
    visibleEventView,
    visibleDeleteView,
    categories,
    eventsByDay,
    selectedDate,
    eventToUpdate,
    orgBookingRules,
    setSelectedDate,
    setIsPracticeLesson,
    setEventToUpdate,
    setDeleteEventId,
    setVisibleDeleteView,
    setVisibleEventView,
    getDaysIndicators,
    fetchEvents,
    cancelLesson,
    onCancelPackage,
    createEvent,
    updateEvent,
    deleteEvent,
    createPracticeLesson,
    isMostRecentPastLesson,
  } = useCalendarPage();

  const instanceOfLesson = (item: any): item is Lesson => {
    return 'is_self_taught' in item;
  };

  const showListItem = (item: Lesson) => {
    if (!instanceOfLesson(item)) return true;
    if (item.is_self_taught) return true;
    if (selectedDate.toMillis() < now().toMillis()) {
      if (isMostRecentPastLesson(item)) return true;
      return BillingHelper.allowUserAccess(subscriptionInfo);
    }
    return true;
  };

  const sortedEventList = eventsByDay
    .sort((a, b) => {
      const compareA = (a as Event).date_begin || (a as Lesson).date_start;
      const compareB = (b as Event).date_begin || (b as Lesson).date_start;

      if (compareA < compareB) return 1;
      return -1;
    })
    .sort((a, b) => {
      if (!BillingHelper.allowUserAccess(subscriptionInfo)) {
        if (!showListItem(a) && showListItem(b)) return 1;
        if (showListItem(a) && !showListItem(b)) return -1;
      }
      return 0;
    });

  const visibleCount = eventsByDay.filter(
    (event) => showListItem(event) || event.is_self_taught || !instanceOfLesson(event),
  ).length;

  if (isLoading) {
    return <Spinnerv2 message="Loading..." />;
  }

  if (isCancellingLesson) {
    return <Spinnerv2 message="Canceling lesson..." />;
  }

  if (isCancellingPackage) {
    return <Spinnerv2 message="Canceling package..." />;
  }

  const showUpgradePlanButtonIndex =
    floor((sortedEventList.length - visibleCount + 1) / 2) + visibleCount - 1;

  return (
    <>
      <EventView
        categories={categories}
        event={eventToUpdate}
        isPracticeLesson={isPracticeLesson}
        visible={visibleEventView}
        onClose={() => {
          setVisibleEventView(false);
          setIsPracticeLesson(false);
          setEventToUpdate(undefined);
        }}
        createPracticeLesson={createPracticeLesson}
        createEvent={createEvent}
        updateEvent={updateEvent}
        deleteEvent={() => {
          if (eventToUpdate) {
            setVisibleEventView(false);
            setDeleteEventId(eventToUpdate._id);
            setVisibleDeleteView(true);
          }
        }}
        calendarSelectedDate={selectedDate.toMillis()}
      />
      <DeleteEventView
        visible={visibleDeleteView}
        onClose={() => {
          setVisibleDeleteView(false);
          setDeleteEventId(null);
        }}
        onDeleteEvent={deleteEvent}
        type={EventType.Event}
      />
      <ListPageLayout
        filterComponent={
          <CalendarFilters
            selectedDate={selectedDate.toISO() ?? ''}
            setSelectedDate={(isoDateString: string) => {
              setSelectedDate(getDateFromIsoString(isoDateString));
            }}
            fetchEvents={fetchEvents}
            getDaysIndicators={getDaysIndicators}
          />
        }
        contentComponent={
          <>
            <CalendarPageHeader
              selectedDate={selectedDate.toISO() ?? ''}
              nextDay={() => {
                setSelectedDate(selectedDate.plus({ days: 1 }));
              }}
              previousDay={() => {
                setSelectedDate(selectedDate.minus({ days: 1 }));
              }}
            />
            <List
              items={sortedEventList}
              listType={ListType.EventsList}
              emptyLabel="You have no events for the selected date."
              cancelLesson={cancelLesson}
              onCancelPackage={onCancelPackage}
              openDeleteEventView={(eventId: string) => {
                setDeleteEventId(eventId);
                setVisibleDeleteView(true);
              }}
              openEditEventView={(event: Event) => {
                setEventToUpdate(event);
                setVisibleEventView(true);
              }}
              orgBookingRules={orgBookingRules}
              showListItem={showListItem}
              showUpgradePlanButtonIndex={showUpgradePlanButtonIndex}
            />
          </>
        }
        additionalComponent={
          <>
            <Button
              className={styles.desktopAddButton}
              label="Add Event +"
              onClick={() => {
                setEventToUpdate(undefined);
                setVisibleEventView(true);
              }}
            />

            <Button
              label="Add practice +"
              className={styles.desktopAddButton}
              onClick={() => {
                setIsPracticeLesson(true);
                setVisibleEventView(true);
              }}
            />
          </>
        }
      />
    </>
  );
};

export default CalendarPage;
