import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import PhoneInput from 'react-phone-number-input';
import styles from './InputField.module.scss';
import ShowPassword from '../../assets/images/input/show-password.svg';
import 'react-phone-number-input/style.css';

interface IProps {
  type?: string;
  label?: string;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  customStyles?: string;
  defaultValue?: string;
  stretchInput?: boolean;
  readonlyInput?: boolean;
  disabled?: boolean;
  showValidationMessage?: boolean;
  maxLength?: number;
}

const errorMessages = {
  valueRequired: {
    condition: (value: string) => value && value.length > 0,
    message: 'This field is required.',
  },
};

const InputField: React.FC<IProps> = ({
  defaultValue,
  type,
  label,
  placeholder,
  onChange,
  onBlur,
  required,
  customStyles,
  stretchInput,
  readonlyInput,
  disabled = false,
  showValidationMessage = true,
  maxLength,
}) => {
  const [error, setError] = useState('');

  const inputRef = React.useRef<HTMLInputElement>(null);

  const onPasswordShow = () => {
    const inputType = inputRef.current?.type;

    if (!inputRef.current) {
      return;
    }

    if (inputType === 'password') {
      inputRef.current.type = 'text';
    } else if (inputType === 'text') {
      inputRef.current.type = 'password';
    }
  };

  const onChangeValue = (e: any) => {
    const value = type === 'tel' ? e : e.target.value;

    if (required && showValidationMessage) {
      if (!errorMessages.valueRequired.condition(value)) {
        setError(errorMessages.valueRequired.message);
      } else {
        setError('');
      }
    }
    onChange(e);
  };
  const fieldStyle = stretchInput ? styles.fieldStretch : styles.field;
  const inputStyle = error.length > 0 ? styles.fieldErrored : fieldStyle;
  return (
    <div className={customStyles || styles.input}>
      {label && (
        <label>
          {label} {required ? <span style={{ color: 'red' }}>*</span> : ''}
        </label>
      )}
      <div className={styles.inputContainer}>
        {type !== 'tel' && (
          <input
            disabled={disabled}
            ref={inputRef}
            className={clsx(inputStyle, disabled && disabled === true && 'border border-light')}
            type={type}
            onInput={onChangeValue}
            placeholder={placeholder}
            value={defaultValue}
            readOnly={readonlyInput}
            maxLength={maxLength ?? 50}
            onBlur={onBlur}
          />
        )}

        {type === 'password' && (
          <img
            alt="show-passord"
            src={ShowPassword}
            className={styles.showPassword}
            onClick={onPasswordShow}
          />
        )}
      </div>

      {error.length > 0 && <div className={styles.error}>{error}</div>}

      {type === 'tel' && (
        <PhoneInput
          className={clsx('form-control', styles)}
          international
          countryCallingCodeEditable={false}
          defaultCountry="NZ"
          onChange={onChangeValue}
        />
      )}
    </div>
  );
};

export default InputField;
