import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { clsx } from 'clsx';
import styles from './GolfJourneyMap.module.scss';
import { SportType } from '../../../../data/enums/SportType.enum';
import GolfFieldImage from '../../../../assets/images/sport-fields/golf-field.svg';
import TennisFieldImage from '../../../../assets/images/sport-fields/tennis-field.svg';
import LacrosseFieldImage from '../../../../assets/images/sport-fields/lacrosse-field.svg';
import SessionProvider from '../../../../providers/SessionProvider';
import { getBaseOrgRoute } from '../../../../common/utils/common.helpers';
import OrganizationService from '../../../../data/services/organization.service';
import Logger from '../../../../middleware/logger.middleware';
import { Carousel } from '../../../../components/Carousel';
import { Grid } from '../../../../components/Grid';
import LessonCategoryTypesService from '../../../../data/services/lessonCategoryTypes.service';

export type JourneyMap = {
  name: string;
  multipleCategories?: string;
  categories: string[];
  lessonsCount: number;
};

const GolfJourneyMap = () => {
  const [journeyMap, setJourneyMap] = useState<JourneyMap[]>([]);
  const [sportType, setSportType] = useState<number | null>(null);
  const { orgId } = useParams<Record<string, string | undefined>>();
  const [lessonCategoryTypes, setLessonCategoryTypes] = useState<string[]>([]);
  const orgBaseRoute = getBaseOrgRoute(window.location.pathname);

  const completeAtheleteChartCategories = useMemo(() => {
    return ['Physical', 'Tactical', 'Ranking', 'Mental'];
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const sportTypeEnum = await OrganizationService.getOrgSportType(orgId ?? '');
      if (typeof sportTypeEnum !== 'number') {
        if (Logger.isDevEnvironment) {
          console.error('sport type enum null for org.', orgId);
        }
        return;
      }

      setSportType(sportTypeEnum);
      const lessonCategoryTypeNames = await LessonCategoryTypesService.getLessonCategoryTypeNames(
        sportTypeEnum,
      );
      setLessonCategoryTypes(lessonCategoryTypeNames);
      const userId = SessionProvider.getUserId();
      const lessonCounts = await OrganizationService.getCategoryWiseLessonCounts(
        orgId ?? '',
        userId,
      );
      setJourneyMap(lessonCounts);

      // eslint-disable-next-line no-empty
    } catch (error) {}
  }, [orgId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let fieldName: string;
  let fieldImageFile;

  switch (sportType) {
    case SportType.Golf:
      fieldImageFile = GolfFieldImage;
      fieldName = 'Golf';
      break;
    case SportType.Tennis:
      fieldImageFile = TennisFieldImage;
      fieldName = 'Tennis';
      break;
    case SportType.Lacrosse:
      fieldImageFile = LacrosseFieldImage;
      fieldName = 'Lacrosse';
      break;
    default:
      break;
  }

  /** Journey image map link items */
  const journeyMapItems =
    sportType === SportType.Lacrosse
      ? journeyMap.filter((item) => lessonCategoryTypes.includes(item.name))
      : journeyMap.filter(
          (item) =>
            lessonCategoryTypes.includes(item.name) &&
            !completeAtheleteChartCategories.includes(item.name),
        );

  /** Journey slider items */
  const journeySliderItems = journeyMap?.filter(
    (item) =>
      !lessonCategoryTypes.includes(item.name) &&
      !completeAtheleteChartCategories.includes(item.name),
  );

  return sportType !== null ? (
    <Grid container columnSpacing={2}>
      <Grid item md={12}>
        <div className={styles.field}>
          <img src={fieldImageFile} alt="field-img" />
          {journeyMapItems?.map((item) => {
            return (
              <NavLink
                key={item.name}
                className={styles[`field${fieldName}${item.name.replace(/[^a-zA-Z]/g, '')}`]}
                to={{
                  pathname: `${orgBaseRoute}/lessons`,
                  state: {
                    categories: item.categories.join(','),
                    tab: item.lessonsCount === 0 ? 0 : 1,
                  },
                }}
              >
                {sportType !== SportType.Golf && (
                  <span
                    className={clsx({
                      [styles.lessonCategoryName]: sportType === SportType.Lacrosse,
                    })}
                  >
                    {item.name}
                  </span>
                )}
                <span
                  className={clsx({
                    [styles.golfCount]: sportType === SportType.Golf,
                    [styles.lacrosseCount]: sportType === SportType.Lacrosse,
                  })}
                >
                  {item.lessonsCount}
                </span>
              </NavLink>
            );
          })}
        </div>
      </Grid>
      <Grid item md={12}>
        <div className={styles.field}>
          <Carousel
            breakpoints={{
              0: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
            slides={journeySliderItems?.map((item, index) => {
              return (
                <div key={index} className={styles.linkDisplay}>
                  <NavLink
                    key={item.name}
                    className={styles.link}
                    to={{
                      pathname: `${orgBaseRoute}/lessons`,
                      state: {
                        categories: item.categories.join(','),
                        tab: 0,
                      },
                    }}
                  >
                    <div className={styles.customCategories}>
                      <div className={styles.numberCircle}>{item.lessonsCount}</div>
                      <span className={styles.categoryName}>{item.name}</span>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          />
        </div>
      </Grid>
    </Grid>
  ) : null;
};

export default GolfJourneyMap;
