import React from 'react';
import clsx from 'clsx';
import styles from './CenteredError.module.scss';
import Button from '../Button/Button';

interface CenteredErrorProps {
  message: string;
  showBtn?: boolean;
  btnLabel?: string;
  onBtnClick?: () => void;
}

const CenteredError = ({
  message: errorMessage,
  showBtn = false,
  btnLabel,
  onBtnClick,
}: CenteredErrorProps) => {
  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.messageWrapper, 'p-4')}>
        <h6 className="mb-3 text-center">{errorMessage}</h6>
        {showBtn && (
          <div className="d-flex flex-column gap-3">
            <Button label={btnLabel ?? ''} onClick={onBtnClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CenteredError;
