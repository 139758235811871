import { createContext } from 'react';
import { alpha } from '@mui/material';
import { BrandTheme, ApplicationColor, PrimaryColor } from './interfaces';

const createApplicationColors = (baseColor: string): ApplicationColor => {
  return {
    opacity_25: alpha(baseColor, 0.25),
    opacity_50: alpha(baseColor, 0.5),
    opacity_75: alpha(baseColor, 0.75),
    opacity_100: alpha(baseColor, 1),
  };
};

const createPrimaryColors = (baseColor: string): PrimaryColor => {
  return {
    opacity_2_5: alpha(baseColor, 0.025),
    opacity_5: alpha(baseColor, 0.05),
    opacity_10: alpha(baseColor, 0.1),
    opacity_20: alpha(baseColor, 0.2),
    opacity_25: alpha(baseColor, 0.25),
    opacity_30: alpha(baseColor, 0.3),
    opacity_40: alpha(baseColor, 0.4),
    opacity_50: alpha(baseColor, 0.5),
    opacity_60: alpha(baseColor, 0.6),
    opacity_70: alpha(baseColor, 0.7),
    opacity_75: alpha(baseColor, 0.75),
    opacity_80: alpha(baseColor, 0.8),
    opacity_90: alpha(baseColor, 0.9),
    opacity_100: alpha(baseColor, 1),
  };
};

export const defaultBrandThemeValue: BrandTheme = {
  colors: {
    /** Application Colors */
    lightCoral: createApplicationColors('#f26c6e'),
    utOrange: createApplicationColors('#f58b33'),
    selectiveYellow: createApplicationColors('#ffbf3c'),
    jungleGreen: createApplicationColors('#1fad83'),
    cerulean: createApplicationColors('#09769a'),
    argentinianBlue: createApplicationColors('#35a7ff'),
    yinMnBlue: createApplicationColors('#2e4976'),
    slateBlue: createApplicationColors('#7167bf'),

    /** Primary Colors */
    black: createPrimaryColors('#000'),
    red: createPrimaryColors('#fd151b'),

    /** White */
    white: '#ffffff',
  },
};

/**
 * AVGAR Branding Theme
 *
 * For more information about brand/theme guidelines, please see
 * https://brandguidelins.wpenginepowered.com/
 */
export const BrandThemeContext = createContext(defaultBrandThemeValue);
