import { useParams } from 'react-router-dom';
import React from 'react';
import Box from '@mui/material/Box';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import MedicationOutlinedIcon from '@mui/icons-material/MedicationOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Config } from '../../../../config';

import SessionProvider from '../../../../providers/SessionProvider';
import { Grid } from '../../../../components/Grid';
import { UserInfoV2 } from '../../../../components/UserInfo/UserInfoV2';
import Spinnerv2 from '../../../../components/Spinnerv2/Spinnerv2';
import { TabContainer } from '../../../../components/TabsV2';
import { MainInformation } from './MainInformation';
import { PersonalInformation } from './PersonalInformation';
import { EmergencyInformation } from './EmergencyInformation';

import { useProfile } from './useProfile';

const ProfilePage = () => {
  const theme = useTheme();
  const isViewedFromMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const userId = SessionProvider.getUserId();
  const { orgId } = useParams<Record<string, string | undefined>>();
  if (!userId || !orgId) return null;

  // eslint-disable-next-line
  const { profile, credits, fetchProfile } = useProfile({
    orgId,
    userId,
  });

  if (!profile) {
    return <Spinnerv2 message="Loading..." />;
  }

  const userFullname = `${profile.user?.first_name} ${profile.user?.last_name}`;
  const userAvatarUrl = profile.picture?.file_name
    ? `${Config().AWSCloudFrontUrl as string}${profile.picture?.file_name}`
    : '';

  return (
    <Box
      width={isViewedFromMobile ? '100%' : 800}
      marginX="auto"
      marginTop={isViewedFromMobile ? 0 : 8}
      padding={2}
    >
      <Grid container>
        <Grid item md={12}>
          <UserInfoV2
            name={userFullname}
            email={profile.user.email}
            credits={credits}
            currency={profile.currency}
            pictureUrl={userAvatarUrl}
            onProfileUpdated={fetchProfile}
          />
        </Grid>
        <Grid item md={12}>
          <Box height={20} />
          <TabContainer
            id="update-profile-tab"
            items={[
              {
                label: 'Information',
                icon: <InfoOutlinedIcon />,
                children: (
                  <MainInformation
                    userId={userId}
                    orgId={orgId}
                    refetch={fetchProfile}
                    sportInfo={profile.sportInfo}
                    profileInfo={profile.profile}
                  />
                ),
              },
              {
                label: 'Personal',
                icon: <BadgeOutlinedIcon />,
                children: (
                  <PersonalInformation
                    userId={userId}
                    orgId={orgId}
                    refetch={fetchProfile}
                    profileInfo={profile.profile}
                    userInfo={profile.user}
                  />
                ),
              },
              {
                label: 'Emergency',
                icon: <MedicationOutlinedIcon />,
                children: (
                  <EmergencyInformation
                    userId={userId}
                    orgId={orgId}
                    refetch={fetchProfile}
                    profileInfo={profile.profile}
                  />
                ),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfilePage;
