import React, { useRef, useState } from 'react';

import styles from './CancelLesson.module.scss';
import DropdownIcon from '../../../../assets/icons/menu/meatball-menu.svg';
import DropdownMenu from '../../../../components/DropdownMenu/DropdownMenu';
import CancelLessonView from '../CancelLessonView/CancelLessonView';
import { LessonCancellation } from '../../../../common/utils/lessons.helper';
import { Lesson } from '../../../../data/entities/lessons.entity';

interface CancelLessonProps {
  lesson: Lesson;
  cancelLesson: Function;
  onCancelPackage: Function;
  lessonCancellation: LessonCancellation;
  cancellationPeriodInMinutes: number;
}

const CancelLesson = ({
  lesson,
  cancelLesson,
  onCancelPackage,
  lessonCancellation,
  cancellationPeriodInMinutes,
}: CancelLessonProps) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [visibleCancelView, setVisibleCancelView] = useState(false);

  const dropdownTriggerRef = useRef(null);

  return (
    <>
      <CancelLessonView
        lesson={lesson}
        visible={visibleCancelView}
        onClose={() => setVisibleCancelView(false)}
        onCancelLesson={cancelLesson}
        onCancelPackage={onCancelPackage}
        lessonCancellation={lessonCancellation}
        cancellationPeriodInMinutes={cancellationPeriodInMinutes}
      />
      <div>
        <div
          ref={dropdownTriggerRef}
          onClick={(e) => {
            e.stopPropagation();
            setVisibleDropdown(!visibleDropdown);
          }}
        >
          <img className={styles.dropdownIcon} alt="" src={DropdownIcon} />
        </div>
        <div className={styles.dropdown}>
          <DropdownMenu
            visible={visibleDropdown}
            onCloseDropdown={() => setVisibleDropdown(false)}
            outsideRefsIgnore={dropdownTriggerRef}
            data={[
              {
                label: 'Cancel',
                onClick: () => setVisibleCancelView(true),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default CancelLesson;
