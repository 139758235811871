import React from 'react';
import Stack from '@mui/material/Stack';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { PaymentMethod } from '@stripe/stripe-js';
import Box from '@mui/material/Box';

export type CardPaymentMethodInfoProps = Pick<PaymentMethod, 'card'>;

export const CardPaymentMethodInfo = ({ card }: CardPaymentMethodInfoProps) => {
  return (
    <Stack direction="row" spacing={1} style={{ marginTop: '-3px' }}>
      <CreditCardOutlinedIcon />
      <Stack direction="row" spacing={1} style={{ marginTop: '3px', fontSize: '0.85rem' }}>
        <Box textTransform="uppercase" marginRight={0.5}>
          {card?.brand}
        </Box>
        <Box marginRight={0.5}>XXXX . XXXX . XXXX . {card?.last4}</Box>
        <Box paddingLeft={1}>
          {card?.exp_month} / {card?.exp_year}
        </Box>
      </Stack>
    </Stack>
  );
};
