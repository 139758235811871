import { v4 } from 'uuid';
import { find } from 'lodash';
import { BSON } from 'realm-web';
import { PaymentEntity } from 'data/entities/payment.entity';
import {
  CheckoutSummary,
  CheckoutSummaryLineItem,
  CheckoutSummaryPromoCode,
  CheckoutSummaryTaxLineItem,
} from 'api/models/payments/checkoutSummary.model';
import RealmRepositories from '../base/realm.repo';
import RealmBase from '../base/realm.base';
import { GetCheckoutSummaryFromInvoiceModel } from '../models/getCheckoutSummaryFromInvoice.model';
import { Conversions } from '../../common/utils/conversions.helper';
import { CreditBalance, CreditBalancePackagesBought } from '../entities/creditBalance.entity';
import { PackagesBoughtHistory } from '../entities/packagesBoughtHistory.entity';
import Logger from '../../middleware/logger.middleware';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export default class PaymentsService extends RealmBase {
  static async getCheckoutSummaryForInvoiceNumber(
    invoiceNumber: string,
  ): Promise<GetCheckoutSummaryFromInvoiceModel | undefined> {
    const paymentObject: PaymentEntity = await RealmRepositories.Payments.getPaymentByInvoiceNumber(
      invoiceNumber,
    );

    if (!paymentObject.invoice || !paymentObject.invoice.checkout_session) {
      return undefined;
    }

    const { invoice } = paymentObject;
    const { checkout_session } = invoice;

    let checkoutSummaryPromoCode: CheckoutSummaryPromoCode | undefined;
    const checkoutSessionPromoCode = checkout_session.promo_code;
    if (checkoutSessionPromoCode && checkoutSessionPromoCode.is_promo_applied) {
      checkoutSummaryPromoCode = Conversions.keysToCamel(checkoutSessionPromoCode);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      checkoutSummaryPromoCode!.subTotalInCentsAfterPromoApplied =
        checkoutSessionPromoCode.subtotal_in_cents_after_promo_applied ?? 0;
    }

    const checkoutSummary: CheckoutSummary = {
      subTotalInCents: checkout_session.subtotal_in_cents ?? 0,
      totalInCents: checkout_session.total_in_cents ?? 0,
      bookingFeeInCents: checkout_session.booking_fee_in_cents ?? 0,
      creditInCents: checkout_session.credit_amount_in_cents,
      creditAppliedInCents: checkout_session.credit_applied_in_cents,
      totalTaxInCents: checkout_session.total_tax_in_cents ?? 0,
      checkoutSummaryPromoCode,
    };
    const taxRateLineItems: CheckoutSummaryTaxLineItem[] = [];
    invoice.tax_rate_line_items?.forEach((taxRateLineItem) => {
      const checkoutSummaryTaxRateLineItem: CheckoutSummaryTaxLineItem = {
        id: taxRateLineItem.id,
        name: taxRateLineItem.display_name ?? '',
        percentage: taxRateLineItem.percentage,
        inclusive: taxRateLineItem.inclusive,
        taxableAmountInCents: taxRateLineItem.taxable_amount_in_cents ?? 0,
        amountInCents: taxRateLineItem.unit_amount_in_cents,
      };
      taxRateLineItems.push(checkoutSummaryTaxRateLineItem);
    });
    const lineItems: CheckoutSummaryLineItem[] = [];
    checkout_session.line_items?.forEach((lineItem) => {
      const checkoutSummaryLineItem: CheckoutSummaryLineItem = {
        name: lineItem.price_data.product_data.name,
        priceInCents: lineItem.price_data.unit_amount_in_cents,
        count: lineItem.quantity,
        lineItemSubTotalInCents: lineItem.quantity * lineItem.price_data.unit_amount_in_cents,
      };
      lineItems.push(checkoutSummaryLineItem);
    });

    checkoutSummary.taxLineItems = taxRateLineItems;
    checkoutSummary.lineItems = lineItems;

    return { paymentEntity: paymentObject, checkoutSummary };
  }

  static async createOrUpdateCreditBalancePackagesBought(
    userId: string,
    orgId: string,
    creditBalancePackagesBought: CreditBalancePackagesBought,
  ) {
    let creditBalance = await RealmRepositories.CreditBalance.getCreditBalance(userId, orgId);
    if (!creditBalance) {
      // Create a new credit balance
      if (Logger.isDevEnvironment) console.log('creating creditBalance');
      creditBalance = await this.createCreditBalance(userId, orgId, [creditBalancePackagesBought]);
      return;
    }

    const serviceId = creditBalancePackagesBought.service_id;
    if (!creditBalance.packages_bought) {
      // Use $set operator
      await this.createNewPackagesBoughtHistory(
        orgId,
        userId,
        creditBalancePackagesBought.service_id,
        creditBalancePackagesBought,
      );

      await RealmRepositories.CreditBalance.setPackagesBoughtDocument(
        userId,
        orgId,
        creditBalancePackagesBought,
      );

      return;
    }

    const foundPackagesBoughtByServiceId = find(
      creditBalance.packages_bought,
      (x: CreditBalancePackagesBought) => {
        return x.service_id === serviceId;
      },
    );

    if (!foundPackagesBoughtByServiceId) {
      // Use $push operator

      if (Logger.isDevEnvironment) console.log('Creating new packages_bought for user');
      await this.createNewPackagesBoughtHistory(
        orgId,
        userId,
        serviceId,
        creditBalancePackagesBought,
      );

      await RealmRepositories.CreditBalance.pushPackagesBoughtDocument(
        userId,
        orgId,
        creditBalancePackagesBought,
      );

      return;
    }

    if (Logger.isDevEnvironment)
      console.log('Found packages_bought for user. Updating entire field');

    const totalPackagesBought = foundPackagesBoughtByServiceId.total_packages_bought ?? 1;
    creditBalancePackagesBought.total_packages_bought = totalPackagesBought + 1;

    await this.createNewPackagesBoughtHistory(
      orgId,
      userId,
      serviceId,
      creditBalancePackagesBought,
    );

    await RealmRepositories.CreditBalance.replacePackagesBoughtField(
      userId,
      orgId,
      serviceId,
      creditBalancePackagesBought,
    );
  }

  static async updatePackagesBoughtLessonsTaken(
    userId: string,
    orgId: string,
    serviceId: string,
    lessonsTaken: number,
  ) {
    await RealmRepositories.CreditBalance.updateLessonsTaken(
      userId,
      orgId,
      serviceId,
      lessonsTaken,
    );
  }

  static async createCreditBalance(
    userId: string,
    orgId: string,
    packagesBought?: CreditBalancePackagesBought[],
  ) {
    const currentTime = getUnixMilliseconds();
    const creditBalance: CreditBalance = {
      _id: v4(),
      org_id: orgId,
      user_id: userId,
      ending_balance: BSON.Decimal128.fromString('0'),
      packages_bought: packagesBought ?? [],
      ending_balance_in_cents: 0,
      date_created: currentTime,
      date_updated: currentTime,
      deleted: false,
    };

    await RealmRepositories.CreditBalance.createCreditBalance(creditBalance);

    return creditBalance;
  }

  static async addLessonIdsForPrepaidService(
    serviceId: string,
    orgId: string,
    userId: string,
    lessonIds: string[],
  ) {
    const updateResult = await RealmRepositories.Payments.addLessonIdsForPrepaid(
      serviceId,
      orgId,
      userId,
      lessonIds,
    );

    if (!updateResult) {
      // This does NOT effect the booking functionally, just won't be able to associate payment document with lessonId.
      console.log('There was an error adding the lessonId to the payment document');
      if (Logger.isDevEnvironment) {
        console.table({
          serviceId,
          orgId,
          userId,
          lessonIds,
        });
      }
    }

    return updateResult;
  }

  static async createNewPackagesBoughtHistory(
    orgId: string,
    userId: string,
    serviceId: string,
    creditBalancePackagesBought: CreditBalancePackagesBought,
  ) {
    const currentMilliseconds = getUnixMilliseconds();
    const packagesBoughtHistory: PackagesBoughtHistory = {
      _id: v4(),
      org_id: orgId,
      user_id: userId,
      service_id: serviceId,
      lessons_occurrences_bought: creditBalancePackagesBought.lessons_occurrences_bought,
      lessons_value_in_cents: creditBalancePackagesBought.lessons_value_in_cents,
      lessons_taken: creditBalancePackagesBought.lessons_taken,
      payment_intent_id: creditBalancePackagesBought.payment_intent_id,
      date_created: currentMilliseconds,
      date_updated: currentMilliseconds,
      deleted: false,
    };

    await RealmRepositories.PackagesBoughtHistory.createPackagesBoughtHistory(
      packagesBoughtHistory,
    );
  }
}
