/* eslint-disable no-underscore-dangle */
import { Config } from '../../config';
import { BaseRepo } from './base.repo';

export class OrgInvitesRepository extends BaseRepo {
  // #region Private Properties
  private orgInviteCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('org_invites');
  // #endregion

  // #region Public Methods
  async getInvitesForOrgAsync(orgId: string) {
    return this.orgInviteCollection?.findOne({ org_id: orgId });
  }
  // #endregion
}
