import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavMenuItemsType } from '../../../../data/enums/NavMenuItemsType.enum';
import { MenuItems } from '../MenuItems';

const styles = {
  menu: {
    marginTop: '30px',
  },
  menuItem: {
    backgroundColor: '#ffffff',
  },
  icon: {
    marginRight: '5px',
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'black',
  },
};

const NavBarHelp = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {MenuItems.filter((item: any) => item.type === NavMenuItemsType.Help).map(
        (currentItem: any) => {
          return (
            <li key={currentItem.alt}>
              <a onClick={handleClick} style={{ cursor: 'pointer' }}>
                <img src={currentItem.icon} alt={currentItem.icon} />
                {currentItem.label}
              </a>
              <Menu
                id="menu-help"
                aria-labelledby="menu-help"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={styles.menu}
              >
                <MenuItem onClick={handleClose} sx={styles.menuItem} autoFocus={false}>
                  <a href="mailto:support@totalcoach.com" style={styles.link}>
                    <MailOutlineIcon sx={styles.icon} />
                    support@totalcoach.com
                  </a>
                </MenuItem>
              </Menu>
            </li>
          );
        },
      )}
    </>
  );
};

export default NavBarHelp;
