import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { isAfter } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { BrandThemeContext } from 'common/context';
import SessionProvider from 'providers/SessionProvider';
import { useMobileViewDetector, useOrgId, useUserSubscription } from 'common/hooks';
import { LessonsTabs } from 'modules/lesson/models/lessons.model';
import { BillingHelper } from 'common/utils/billing.helper';
import { SubscriptionsHelper } from 'common/utils/subscriptions.helper';
import { getUnixMilliseconds } from 'common/utils/date.helpers';

import { Lesson } from 'data/entities/lessons.entity';
import LessonsService from 'data/services/lessons.service';
import ListView from 'components/Pages/Items/ListView/ListView';

const PastLessons = () => {
  const theme = useContext(BrandThemeContext);
  const { subscriptionInfo } = useUserSubscription();
  const { isViewedFromMobile } = useMobileViewDetector();
  const [lessons, setLessons] = useState<Lesson[]>([]);
  const { orgId } = useOrgId();
  const history = useHistory();

  const fetchLessons = useCallback(async () => {
    try {
      const currentOrgId = orgId ?? '';
      const currentUserId = SessionProvider.getUserId();

      const response = await LessonsService.getUserAcceptedLessonsByOrdId(
        currentUserId,
        currentOrgId,
      );

      if (response?.length) {
        setLessons(response);
      }
    } catch (error) {
      console.error(error);
    }
  }, [orgId]);

  useEffect(() => {
    fetchLessons();
  }, [fetchLessons]);

  const filteredLessons = useMemo(
    () =>
      lessons
        ?.filter((lesson: Lesson) => isAfter(getUnixMilliseconds(), lesson.date_start))
        .sort((a, b) => {
          return isAfter(a.date_start, b.date_start) ? -1 : 1;
        })
        ?.slice(0, 4),
    [lessons],
  );

  const onLessonClick = (id: string) => {
    history.push(`/org/${orgId}/lesson/${id}`);
  };

  const showListItem = (lesson: Lesson, i: number) => {
    return SubscriptionsHelper.showListItem(i, subscriptionInfo);
  };

  const onAllPastLessons = () => {
    history.push({
      pathname: `/org/${orgId}/lessons`,
      state: { tab: LessonsTabs.Past },
    });
  };

  const styles = {
    listLessons: {
      display: 'flex',
      justifyContent: 'center',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&:hover': {
        color: theme.colors.black.opacity_30,
      },
    },
    heading: {
      typography: isViewedFromMobile ? 'h6' : 'h5',
      fontWeight: 700,
    },
    padding: {
      padding: isViewedFromMobile ? '1rem' : '1rem 2.5rem',
    },
  };

  return (
    <Box height="100%" maxHeight={546} padding={styles.padding}>
      <Typography sx={styles.heading} paddingY="1rem">
        Past lessons
      </Typography>
      {filteredLessons.length > 0 ? (
        <Stack>
          <ListView
            source={filteredLessons}
            onItemClick={onLessonClick}
            emptyListText=""
            showListItem={showListItem}
          />
          {filteredLessons.length > 0 && BillingHelper.allowUserAccess(subscriptionInfo) && (
            <Box sx={styles.listLessons}>
              <a onClick={onAllPastLessons} style={{ cursor: 'pointer' }}>
                See all past lessons...
              </a>
            </Box>
          )}
        </Stack>
      ) : (
        <Typography>There is no past lessons</Typography>
      )}
    </Box>
  );
};

export default PastLessons;
