import React from 'react';
import ApplicationLayout from './ApplicationLayout';
import LoginLayout from './LoginLayout';

const MainLayout = () => {
  return (
    <div>
      <LoginLayout />
      <ApplicationLayout />
    </div>
  );
};

export default MainLayout;
