import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { BookingStep, bookingSlice } from 'redux/slices';
import Spinnerv2 from '../../../components/Spinnerv2/Spinnerv2';
import BookingPageV2 from '../../book/pages/BookingPageV2';
import { useFetchPurchasedLessonPacks } from '../../../common/hooks/useFetchPurchasedLessonPacks';
import BookingWrapper from '../../book/components/BookingWrapper/BookingWrapper';
import { StaticRoutes } from '../../../common/utils/routes/StaticRoutes.helper';

const BookPurchasedLessonPackPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { serviceId } = useParams<Record<string, string | undefined>>();
  const { orgId } = useParams<Record<string, string>>();
  const { purchasedServices, isLoading } = useFetchPurchasedLessonPacks();
  const selectedService = purchasedServices.find((service) => service.id === serviceId);

  React.useEffect(() => {
    dispatch(bookingSlice.actions.resetBooking());
    if (selectedService && selectedService.packagesBought) {
      dispatch(bookingSlice.actions.changeCoachId(selectedService?.coaches[0].id));
      dispatch(bookingSlice.actions.changeLocationId(selectedService?.locationIds[0]));
      dispatch(
        bookingSlice.actions.updateSelectedServices([
          {
            id: selectedService.id,
            coachId: selectedService?.coaches[0].id,
            count: 0,
            isCalendarActive: true,
            addedAt: DateTime.now().toMillis(),
            packagesBought: selectedService.packagesBought,
            scheduledDates: [],
          },
        ]),
      );

      dispatch(bookingSlice.actions.getServices([selectedService]));
      dispatch(bookingSlice.actions.updateIsLessonPackTimeSlotSelection(true));
      dispatch(bookingSlice.actions.changeStep(BookingStep.SelectService));
    }
  }, [selectedService]);

  if (isLoading) {
    return <Spinnerv2 message="Loading service..." />;
  }

  if (!selectedService) {
    return (
      <div className="mt-5">
        <BookingWrapper
          continueButton={{
            onClick: () => history.push(`${StaticRoutes.Org}/${orgId}${StaticRoutes.Book}`),
            label: 'View services',
          }}
        >
          <div className="alert alert-secondary text-center" role="alert">
            There was an error fetching your service details. Please view available services below.
          </div>
        </BookingWrapper>
      </div>
    );
  }

  return <BookingPageV2 />;
};

export default BookPurchasedLessonPackPage;
