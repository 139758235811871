interface StringToAvatarProps {
  firstName: string;
  lastName: string;
}

export class AvatarHelper {
  public static stringToAvatar = (name: StringToAvatarProps): string => {
    const { firstName, lastName } = name;

    const nonSpaceFirstName = firstName.trim();
    const nonSpaceLastName = lastName.trim();
    if (nonSpaceFirstName === '+') return `${nonSpaceFirstName[0]}${nonSpaceLastName}`;
    return `${nonSpaceFirstName[0]}${nonSpaceLastName[0]}`;
  };
}
