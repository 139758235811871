import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface SelectProps {
  id: string;
  label: string;
  value: string;
  options: SelectOption[];
  hasError?: boolean;
  errorMessage?: string;
  isEditMode?: boolean;
  onChange: (value: string) => void;
}

interface SelectOption {
  id: string;
  label: string;
}

export const Select = (props: SelectProps) => {
  // eslint-disable-next-line
  const { id, label, value, options, hasError, errorMessage, isEditMode, onChange } = props;
  const selectedOption = options.find((item) => item.id === value);

  return (
    <Stack spacing={1}>
      {isEditMode && (
        <>
          <Typography>{label}</Typography>
          <TextField
            id={id}
            select
            variant="filled"
            label=""
            defaultValue={value}
            fullWidth
            hiddenLabel
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(e) => onChange(e.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
      {!isEditMode && (
        <>
          <Typography fontWeight="bold">{label}</Typography>
          <Typography>{value && selectedOption ? selectedOption.label : '-'}</Typography>
        </>
      )}
    </Stack>
  );
};
