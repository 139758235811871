/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Logger from './logger.middleware';
import Spinnerv2 from '../components/Spinnerv2/Spinnerv2';
import { OrgInvitationModel } from '../data/models/orgInvitation.model';
import { useAuth } from '../common/hooks/useAuth';

interface RequireAuthProps extends RouteComponentProps, ReactCookieProps {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

export default (
  ComposedComponent: any,
  onAuthorized: (isAuth: boolean) => void,
  componentProps?: any,
) => {
  const RequiresAuth = (props: RequireAuthProps) => {
    const authHook = useAuth();
    const { params } = authHook;
    const [isLoading, setIsLoading] = useState(true);
    const [authLogicCompleted, setAuthLogicCompleted] = useState(false);
    const [isRedirected, setIsRedirected] = useState(false);

    const { cookies, history, location } = props;

    const logUserOutAndRedirectToLoginPage = useCallback(async () => {
      await authHook.logUserOutAndRedirectToLoginPage({
        savePreviousIntoHistory: true,
        previousParams: params,
      });
    }, []);

    const doAuthLogicAsync = useCallback(async () => {
      try {
        setIsLoading(true);
        const isAuthResult = await authHook.doAuthLogic();

        if (!isAuthResult) {
          const orgInvitationModel: OrgInvitationModel | undefined = cookies?.get('orgInvitation');
          if (orgInvitationModel) {
            // User has clicked on org invitation link to join as client or staff member
            if (Logger.isDevEnvironment) console.log('isOrgInvitation, redirecting to signup page');
            history.push({
              pathname: '/signup',
              search: params.toString(),
              state: {
                from: location.pathname,
              },
            });
            setIsRedirected(true);
            if (Logger.isDevEnvironment) console.log('finished redirecting');
          } else {
            await logUserOutAndRedirectToLoginPage();
            return;
          }
        }

        onAuthorized(isAuthResult);
      } catch (err: any) {
        await logUserOutAndRedirectToLoginPage();
        onAuthorized(false);
      } finally {
        setIsLoading(false);
        setAuthLogicCompleted(true);
      }
    }, []);

    useEffect(() => {
      if (!authLogicCompleted) {
        doAuthLogicAsync();
      }
    }, [authLogicCompleted, doAuthLogicAsync]);

    if (isLoading) {
      return <Spinnerv2 message="Authorising..." />;
    }

    if (isRedirected) {
      // Edge case, when we redirect from middleware, we need to return empty for the component.
      return null;
    }

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ComposedComponent {...componentProps} />;
  };

  return withRouter(withCookies(RequiresAuth));
};
