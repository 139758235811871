/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import AttachmentsThumbnailsList from '../../../modules/attachment/components/AttachmentsThumbnailsList/AttachmentsThumbnailsList';
import styles from './ExercisesListItem.module.scss';
import ExerciseView from '../../ExerciseView/ExerciseView';
import { ExerciseModel } from '../../../data/entities/exercises.entity';
import { LessonCategory } from '../../../data/entities/orgLessonCategories.entity';

interface IExercisesListItemProps {
  item: ExerciseModel;
  categories?: LessonCategory[];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ExercisesListItem: React.FC<IExercisesListItemProps> = ({ item, categories }) => {
  const [exerciseOpened, setExerciseOpened] = useState(false);

  let exerciseCategories: string[] = [];

  if (categories) {
    exerciseCategories = categories
      .filter((x) => item.exerciseObj.org_lesson_category_ids?.some((y) => y === x._id))
      .map((x) => x.name);
  }

  return (
    <>
      <ExerciseView
        title={item.exerciseObj.title}
        categories={exerciseCategories}
        text={item.exerciseObj.description}
        attachments={item.attachments}
        onClose={() => setExerciseOpened(false)}
        open={exerciseOpened}
      />
      <div
        key={item.exerciseObj._id}
        className={styles.exercisesItem}
        onClick={() => setExerciseOpened(true)}
      >
        <div className={styles.exercisesWrapper}>
          <div className={styles.titleColumn}>
            <div className={styles.exercisesTitle}>{item.exerciseObj.title}</div>
            {!!item.attachments.length && (
              <AttachmentsThumbnailsList thumbnails={item.attachments} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExercisesListItem;
