import {
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
  CountryIso2,
} from 'react-international-phone';
import 'react-international-phone/style.css';
import React from 'react';
import {
  InputAdornment,
  MenuItem,
  Select,
  Typography,
  FilledInput,
  FilledInputProps,
} from '@mui/material';
import { Phone } from './interfaces';
import { styles } from './phoneStyles';

export interface MuiPhoneResult {
  phone: string;
  country: CountryIso2;
}

export interface MuiPhoneFieldProps extends Omit<FilledInputProps, 'onChange'> {
  value: Phone;
  defaultCountry: CountryCode;
  onChange: (phone: Phone) => void;
}

const defaultCountriesAsTypes = [...defaultCountries.map((country) => country[1])] as const;
type CountryCode = (typeof defaultCountriesAsTypes)[number];

export const convertPhoneToString = (phone: Phone) =>
  `+${phone.country_code} ${phone.phone_number}`;

export const MuiPhoneField = (props: MuiPhoneFieldProps) => {
  const { value, defaultCountry, onChange } = props;
  const phoneStrValue = convertPhoneToString(value).replace('+', '');

  const { handlePhoneValueChange, country, setCountry, inputRef } = usePhoneInput({
    defaultCountry,
    value: phoneStrValue,
    countries: defaultCountries,
    onChange: (data) => {
      const [code, ...phone] = data.phone.split(' ');
      onChange({
        country_code: code,
        country_iso: data.country.toUpperCase(),
        name: '',
        phone_number: phone.join(' ').trim(),
      });
    },
  });

  return (
    <FilledInput
      fullWidth
      hiddenLabel
      disableUnderline
      color="primary"
      placeholder="Phone number"
      value={phoneStrValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      startAdornment={
        <InputAdornment position="start" style={styles.adornment}>
          <Select
            MenuProps={{
              style: {
                height: '300px',
                width: '360px',
                top: '10px',
                left: '-34px',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            sx={styles.countries}
            value={country}
            onChange={(e) => setCountry(e.target.value as CountryIso2)}
            // eslint-disable-next-line
            renderValue={(value) => <FlagEmoji iso2={value} style={{ display: 'flex' }} />}
          >
            {defaultCountries.map((c) => {
              // eslint-disable-next-line
              const country = parseCountry(c);
              return (
                <MenuItem key={country.iso2} value={country.iso2}>
                  <FlagEmoji iso2={country.iso2} style={{ marginRight: '8px' }} />
                  <Typography marginRight="8px">{country.name}</Typography>
                  <Typography color="gray">+{country.dialCode}</Typography>
                </MenuItem>
              );
            })}
          </Select>
        </InputAdornment>
      }
    />
  );
};
