import React from 'react';
import styles from './Spinner.module.scss';

interface ISpinner {
  color?: string;
  message?: string;
}
const Spinner: React.FC<ISpinner> = ({ color = '#fff', message }) => (
  <>
    <div className={styles.loader} style={{ borderLeft: `2px solid ${color}` }} />
    <span>{message}</span>
  </>
);

export default Spinner;
