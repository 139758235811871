import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';
import styles from './InviteAcceptedPage.module.scss';
import OrganizationService from '../../../../data/services/organization.service';
import { Organization } from '../../../../data/entities/organization.entity';

const InviteAcceptedPage = () => {
  const params = new URLSearchParams(window.location.search);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['orgInvitation']);
  const [organization, setOrganization] = useState<Organization>();
  const msg = params.get('msg');
  const { orgId } = useParams<Record<string, string>>();

  const handleGetWorkspaceName = async (id: string) => {
    try {
      const currentOrg = await OrganizationService.getOrgByIdAsync(id);
      if (currentOrg) {
        setOrganization(currentOrg);
      }
    } catch (e) {
      toast.error('Cannot fetch organization details');
      console.error(e);
    }
  };

  useEffect(() => {
    removeCookie('orgInvitation', { path: '/' });
  }, []);

  useEffect(() => {
    handleGetWorkspaceName(orgId);
  }, [orgId]);

  return (
    <>
      <div className={styles.container}>
        <h1>{msg}</h1>
        <h1>
          Welcome to TotalCoach, you are now a verified staff member of &nbsp;
          {organization && organization?.org_name}.
        </h1>
      </div>
      <div className={styles.container}>
        <h5>
          To get started, download TotalCoach from the App Store onto your iPad, then enter your
          email and password to sign in to your free account.
        </h5>
      </div>
      <div className={styles.container}>
        <img src="/images/downloadFromIos.svg" alt="Download from App Store" />
      </div>
      <div className={styles.container}>
        <img src="/images/welcomeTotalCoach.svg" alt="Welcome to Total Coach" height={80} />
      </div>
    </>
  );
};

export default InviteAcceptedPage;
