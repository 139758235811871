/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';

import { isEqual } from 'lodash';
import styles from './EventListItem.module.scss';
import DateList from '../DateList/DateList';
import { Event } from '../../../data/entities/events.entity';

interface IEventListItemProps {
  item: Event;
  // eslint-disable-next-line react/no-unused-prop-types
  openDeleteEventView: (eventId: string) => void;
  openEditEventView: (eventId: string) => void;
}

const EventListItem: React.FC<IEventListItemProps> = ({
  item,
  // openDeleteEventView,
  openEditEventView,
}) => {
  const [locationName, setLocationName] = useState<string>('');
  useEffect(() => {
    item.org_location?.locations.forEach((location) => {
      if (
        item.organization?.addresses?.length &&
        isEqual(location.address, item.organization?.addresses[0])
      ) {
        setLocationName(location.name);
      }
    });
  }, [item]);
  return (
    <div className={styles.eventItem}>
      <div
        key={item._id}
        className={styles.eventWrapper}
        onClick={() => openEditEventView(item._id)}
      >
        <DateList dateUnixMilli={item.date_begin} />
        <div className={styles.titleColumn}>
          <div className={styles.eventTitle}>{item.title}</div>
          <div className={styles.eventCoachName}>{item.organization?.org_name}</div>
          <div className={styles.eventLocationName}>{locationName}</div>
          {item.organization?.addresses?.length ? (
            <div className={styles.eventAddress}>
              {item.organization?.addresses[0].street_number}{' '}
              {item.organization?.addresses[0].street_name},{' '}
              {item.organization?.addresses[0].suburb}, {item.organization?.addresses[0].city}{' '}
              {item.organization?.addresses[0].zip_code}
            </div>
          ) : (
            ''
          )}
        </div>
        {item.deleted ? <span className={styles.canceledLabel}>Canceled</span> : <div />}
      </div>
    </div>
  );
};

export default EventListItem;
