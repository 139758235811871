/* eslint-disable no-useless-escape */
const Validators = {
  phoneNumber: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  // visa, master, american express, amex
  creditCard: /^(?:4\d{3}|5[1-5]\d{2}|6011|3[47]\d{2})([-\s]?)\d{4}\1\d{4}\1\d{3,4}$/,
  // format: dd/yy
  formattedDate: /(\d\/*)+/,
  // numbers 0-9
  onlyNumbers: /^[0-9]+$/,
  passwordCheck:
    // Must Contain 8 Characters, One Uppercase, One Lowercase,\n One Number and one special case Character
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  positiveAndNegativeNumbers: /^-?[0-9]\d*(\.\d+)?$/,
  // date format: dd/mm/yyy
  formattedDateWithYear:
    /(((0[1-9]|[12][0-9]|3[01])([/])(0[13578]|10|12)([/])([1-2][0,9][0-9][0-9]))|(([0][1-9]|[12][0-9]|30)([/])(0[469]|11)([/])([1-2][0,9][0-9][0-9]))|((0[1-9]|1[0-9]|2[0-8])([/])(02)([/])([1-2][0,9][0-9][0-9]))|((29)(\.|-|\/)(02)([/])([02468][048]00))|((29)([/])(02)([/])([13579][26]00))|((29)([/])(02)([/])([0-9][0-9][0][48]))|((29)([/])(02)([/])([0-9][0-9][2468][048]))|((29)([/])(02)([/])([0-9][0-9][13579][26])))/,
  // email format: abc@nz.co
  emailAddressFormat: /^\S+@\S+\.\S+$/,
};

export default Validators;
