export enum Payment {
  PlatformPayment = 0,
  Credit,
  Cash,
  Free,
  Mixed,
}

export enum PaymentPlatform {
  Stripe = 0,
}
