import { AxiosResponse } from 'axios';
import { BaseRoute } from '../../base/Route.base';
import RealmRepositories from '../../../data/base/realm.repo';
import { Config } from '../../../config';
import {
  Coach,
  CoachImageAndText,
  Location,
  LocationImageAndText,
  ScheduledDate,
} from '../../../modules/service/models/service.model';

import { locationStringBuilder, fullNameBuilder } from '../../../common/utils/response.helper';

interface OrgId {
  orgId: string;
}

interface GetIconUrlsOutputItem {
  id: string;
  url: string;
}

export type BusyTimeItem = ScheduledDate;

export class Org extends BaseRoute {
  async getLocationsByIdAsync(orgId: string, locationIds: string[]) {
    const response = await this.api.post(`/org/${orgId}/locations/ids`, locationIds);
    return response;
  }

  async getCoaches(orgId: string) {
    const response = await this.api.get(`/org/${orgId}/coaches`);
    return response;
  }

  async getLocations(orgId: string) {
    const response = await this.api.get(`/org/${orgId}/locations`);
    return response;
  }

  async getLocationsImageAndText({ orgId }: OrgId): Promise<AxiosResponse<LocationImageAndText[]>> {
    let newLocations: LocationImageAndText[] = [];
    const response: AxiosResponse<Location[]> = await this.api.get(`/org/${orgId}/locations`);
    if (response.data) {
      const oldLocations = response.data;
      newLocations = oldLocations.map((location) => ({
        ...location,
        imageAndText: {
          iconUrl: '',
          text: locationStringBuilder(location),
        },
      }));

      const iconIds = oldLocations
        .map((location) => (location.icon ? location.icon : ''))
        .filter((item) => item !== '');

      const iconUrls = await this.getIconUrl(iconIds);

      if (iconUrls) {
        newLocations = newLocations.map((location) => {
          const icon = iconUrls.find((iconUrl) => iconUrl.id === location.icon);
          return {
            ...location,
            imageAndText: {
              ...location.imageAndText,
              iconUrl: icon ? icon.url : '',
            },
          };
        });
      }
    }

    return {
      ...response,
      data: newLocations,
    };
  }

  async getCoachesImageAndText({ orgId }: OrgId) {
    let newCoaches: CoachImageAndText[] = [];
    const response: AxiosResponse<Coach[]> = await this.api.get(`/org/${orgId}/coaches`);
    if (response.data) {
      const oldCoaches = response.data;
      newCoaches = oldCoaches.map((coach) => ({
        ...coach,
        imageAndText: {
          iconUrl: '',
          text: fullNameBuilder(coach),
        },
      }));

      const iconIds = oldCoaches
        .map((coach) => (coach.avatar ? coach.avatar : ''))
        .filter((item) => item !== '');

      const iconUrls = await this.getIconUrl(iconIds);

      if (iconUrls && iconUrls.length) {
        newCoaches = newCoaches.map((coach) => {
          const icon = iconUrls.find((iconUrl) => iconUrl.id === coach.avatar);
          return {
            ...coach,
            imageAndText: {
              ...coach.imageAndText,
              iconUrl: icon ? icon.url : '',
            },
          };
        });
      }
    }

    return {
      ...response,
      data: newCoaches,
    };
  }

  /** Use this to fetch icon urls, mostly for location icons and coach icons */
  async getIconUrl(iconIds: string[]): Promise<GetIconUrlsOutputItem[] | undefined> {
    const files = await RealmRepositories.Attachments.getFilesByIds(iconIds);
    return files?.map((file) => ({
      id: file._id,
      url:
        file.file_name && file.file_name.length
          ? `${Config().AWSCloudFrontUrl}${file.file_name}`
          : '',
    }));
  }

  async findServices(orgId: string, coachId: string, locationId: string) {
    const response = await this.api.get(
      `/org/${orgId}/services?coachId=${coachId}&locationId=${locationId}`,
    );
    return response;
  }

  async getServiceBusyTimesBetweenDates(
    orgId: string,
    serviceId: string,
    coachId: string,
    assistanceStaff: string[],
    dateStart: number,
    dateEnd: number,
    serviceMaxParticipants: number,
    userId: string,
  ): Promise<AxiosResponse<BusyTimeItem[]>> {
    const response = await this.api.post(`/org/${orgId}/service/${serviceId}/busyTimes`, {
      coachId,
      assistanceStaff,
      dateStart,
      dateEnd,
      serviceMaxParticipants,
      userId,
    });

    return response;
  }
}
