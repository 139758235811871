// import React from 'react';
import { UserType } from '../../data/enums/UserType.enum';
import { OrgInvitationModel } from '../../data/models/orgInvitation.model';
import StaticDictionary from './staticDictionary.helper';

export function redirectToOrgInvitation(history: any, orgInvitationModel: OrgInvitationModel) {
  if (orgInvitationModel.userType === UserType.client) {
    history.push(`/org/${orgInvitationModel.orgId}/invite/${orgInvitationModel.inviteId}`);
  } else {
    history.push(
      `/org/${orgInvitationModel.orgId}/acceptInvite?${StaticDictionary.UrlParameters.Organization.InvitationId}=${orgInvitationModel.inviteId}`,
    );
  }
}
