import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { RootState } from 'redux/store';
import { bookingSlice } from 'redux/slices';
import ServicesService from 'data/services/services.service';
import PaymentsService from 'data/services/payments.service';
import { StaticRoutes } from 'common/utils/routes/StaticRoutes.helper';
import { useOrgId } from 'common/hooks';
import { BookingContainer, BookingSummaryInfo, CardPaymentContainer } from './components';
import { useService, useSummary, useBooking } from './hooks';

export const BookingConfirmed = () => {
  const history = useHistory();
  const { orgId } = useOrgId();

  const { handleResetBooking } = useBooking();

  const [isCalculatingCheckout, setIsCalculatingCheckout] = useState(false);
  const booking = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  const { getDetailedSelectedServices } = useService();
  const {
    isSummaryDisplayed: isPayment,
    isPaymentOptionsDisplayed,
    isRefreshingPositionsLeft,
    refreshPositionsLeft,
  } = useSummary();

  const isBookingError = booking.selected.isRefund;
  const { invoiceId, paymentPreference, services } = booking.selected;
  const isLessonInvite = !!booking.selected.lessonInviteId;
  const selectedDetailedServices = getDetailedSelectedServices();

  /** Check the type of the service is prepaid with redux store */
  const isPrepaidOnly = ServicesService.isPrepaidOnly(selectedDetailedServices);

  /** Count the unbooked prepaid packages */
  const unbookedPrepaidPackagesCount =
    ServicesService.countUnbookedPrepaidPackages(selectedDetailedServices);

  /** Initialize the booking process to select dates - Selete date & time */
  const handleResetDatesSelect = async () => {
    handleResetBooking(true);

    if (services.length === 1) {
      const serviceUrl = `${StaticRoutes.Org}/${orgId}/select-dates/${services[0].id}`;
      history.push(serviceUrl);
    }
  };

  /** Get checkSummary with invoice ID */
  const fetchPaymentInvoice = async () => {
    try {
      /** Set loading status to true */
      setIsCalculatingCheckout(true);

      const invoiceNumber = invoiceId;

      /** If the invoice number is not valid */
      if (!invoiceNumber && booking.selected.checkoutSummary?.totalInCents !== 0) {
        toast.error(`Invoice number is could not be found. Checkout summary won't be displayed.`);
        handleResetBooking(true);
        return;
      }

      /** Get checkoutSummary from invoice with id */
      const getCheckoutSummaryFromInvoiceModel =
        await PaymentsService.getCheckoutSummaryForInvoiceNumber(invoiceNumber);

      if (getCheckoutSummaryFromInvoiceModel?.checkoutSummary) {
        /** Reset the redux store with fetched invoice checkout summary data */
        dispatch(
          bookingSlice.actions.getCheckoutSummary(
            getCheckoutSummaryFromInvoiceModel?.checkoutSummary,
          ),
        );
      }
    } catch (err: any) {
      toast.error(err);
    } finally {
      setIsCalculatingCheckout(false);
    }
  };

  type BookingConfirmedNextAction = 'TRY_AGAIN' | 'BOOK_PREPAID_PACKAGE' | 'BOOK_ANOTHER';

  /** Handle next action */
  const getNextAction = (): BookingConfirmedNextAction => {
    let nextAction: BookingConfirmedNextAction = 'BOOK_ANOTHER';
    if (isBookingError) nextAction = 'TRY_AGAIN';
    if (unbookedPrepaidPackagesCount > 0 && !isLessonInvite) nextAction = 'BOOK_PREPAID_PACKAGE';
    return nextAction;
  };

  const getNextActionLabel = (nextAction: BookingConfirmedNextAction): string => {
    switch (nextAction) {
      case 'TRY_AGAIN':
        return 'Try Again';
      case 'BOOK_PREPAID_PACKAGE':
        return 'Select date and time';
      default:
        return 'Make another booking';
    }
  };

  /** Label logic and card container */
  const titleLabel = `${isPayment() ? 'Payment' : 'Booking'} confirmed`;
  const titleContinue = getNextActionLabel(getNextAction());

  const isCardContainerDisplayed = paymentPreference === 'in_app' && isPaymentOptionsDisplayed();

  const isLoading = isCalculatingCheckout || isRefreshingPositionsLeft;

  useEffect(() => {
    if (!isPrepaidOnly) {
      fetchPaymentInvoice();
    }
    refreshPositionsLeft();
  }, []);

  return (
    <BookingContainer
      isLoading={isLoading}
      title={titleLabel}
      continueButton={{
        label: titleContinue,
        onClick: () => {
          if (isBookingError) {
            handleResetBooking(true);
          } else if (unbookedPrepaidPackagesCount > 0 && !isLessonInvite) {
            handleResetDatesSelect();
          } else if (!unbookedPrepaidPackagesCount || isLessonInvite) {
            handleResetBooking(true);
          }
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <BookingSummaryInfo>
            {/** Card payment container will show when there is a booking error, payment required and no credit applied. Need to test this edgecase further  */}
            {isCardContainerDisplayed && (
              <CardPaymentContainer
                paymentMethod={booking.selected.paymentMethod}
                onSuccess={(newPaymentMethod) => {
                  dispatch(bookingSlice.actions.updatePaymentMethod(newPaymentMethod));
                }}
              />
            )}
          </BookingSummaryInfo>
        </Grid>
      </Grid>
    </BookingContainer>
  );
};
