import { Config } from '../../config';
import { File } from '../entities/file.entity';
import { BaseRepo } from './base.repo';

export default class FilesRepository extends BaseRepo {
  // #region Private Properties
  private fileCollection = this.mongo?.db(Config().RealmDbName as string).collection('file');
  // #endregion

  // #region Public Methods
  async getFileByIdAsync(id: string): Promise<File> {
    return this.fileCollection?.findOne({ _id: id });
  }

  async insertFileAsync(file: File) {
    return this.fileCollection?.insertOne(file);
  }

  async deleteFileByIdAsync(id: string) {
    return this.fileCollection?.deleteOne({ _id: id });
  }
  // #endregion
}
