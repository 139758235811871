import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';

interface ButtonProps extends Pick<MuiButtonProps, 'disabled' | 'fullWidth' | 'variant'> {
  label: string;
  onClick?: (data: any) => void;
  sx?: object;
  size?: 'small' | 'medium' | 'large';
  type?: 'submit' | 'reset' | 'button';
  color?: 'success' | 'warning' | 'primary' | 'secondary' | 'info' | 'inherit' | 'error';
}

export const Button = (props: ButtonProps) => {
  const { label, disabled, onClick, fullWidth, variant, sx, size, type, color } = props;
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <MuiButton
      disabled={disabled}
      variant={variant ?? 'contained'}
      fullWidth={fullWidth}
      color={color ?? 'success'}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        bgcolor: !disabled ? 'rgba(31, 173, 131, 0.9) !important' : undefined,
        color: !disabled ? '#fff! important' : undefined,
        px: 4,
        borderRadius: 2,
        '&:hover': {
          bgcolor: !disabled ? 'rgb(31, 173, 131, 1.0) !important' : undefined,
        },
        ...sx,
      }}
      size={size}
      type={type}
    >
      {label}
    </MuiButton>
  );
};
