import RealmRepositories from 'data/base/realm.repo';
import { SportType } from '../enums/SportType.enum';
import StaticDictionary from '../../common/utils/staticDictionary.helper';

export default class LessonCategoryTypesService {
  static async getLessonCategoryTypeNames(sportTypeEnum: SportType) {
    try {
      const result = await RealmRepositories.LessonCategoryTypes.getLessonCategoryTypes(
        sportTypeEnum,
      );
      if (result && result.length > 0) {
        return result.map((category) => category.name);
      }
    } catch (error) {
      console.error(error);
    }

    let categoryNames: string[] = [];
    switch (sportTypeEnum) {
      case SportType.Golf:
        categoryNames = StaticDictionary.LessonCategories.Golf;
        break;
      case SportType.Tennis:
        categoryNames = StaticDictionary.LessonCategories.Tennis;
        break;
      default:
        categoryNames = StaticDictionary.LessonCategories.Golf;
        break;
    }

    return categoryNames;
  }
}
