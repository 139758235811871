import { Accordion, AccordionDetails, AccordionSummary, AccordionProps } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import React, { useState, useContext } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { BrandThemeContext } from 'common/context';

interface LessonsGroupProps extends Pick<AccordionProps, 'disabled' | 'expanded'> {
  title: string;
  total: number;
  isExpanded?: boolean;
  children: React.ReactNode;
  tooltipMessage: string;
}

export const LessonsGroup = ({
  title,
  total,
  disabled,
  children,
  isExpanded,
  tooltipMessage,
}: LessonsGroupProps) => {
  const theme = useContext(BrandThemeContext);
  const [expanded, setExpanded] = useState(isExpanded || false);

  const [tooltip, setTooltip] = React.useState<HTMLElement | null>(null);
  const open = Boolean(tooltip);
  const marginBottom = open ? 8 : 4;

  const handleDisplayTooltip = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) {
      setTooltip(event.currentTarget);
    }
  };

  const handleCloseTooltip = () => {
    setTooltip(null);
  };

  return (
    <>
      <Box
        marginBottom={marginBottom}
        onClick={handleDisplayTooltip}
        onMouseLeave={handleCloseTooltip}
      >
        <Accordion
          expanded={disabled ? !disabled : expanded}
          onChange={() => setExpanded(!expanded)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={title}>
            {title}
            {total > 0 && (
              <Chip
                label={total}
                size="small"
                sx={{
                  marginLeft: 1,
                  backgroundColor: theme.colors.jungleGreen.opacity_100,
                  color: theme.colors.white,
                }}
              />
            )}
          </AccordionSummary>
          <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
      </Box>
      <Popover
        sx={{
          pointerEvents: 'none',
          marginTop: 0.75,
        }}
        open={open}
        anchorEl={tooltip}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleCloseTooltip}
        disableRestoreFocus
      >
        <Box bgcolor={theme.colors.jungleGreen.opacity_25} padding={1}>
          <Typography sx={{ fontSize: '0.8rem' }}>{tooltipMessage}</Typography>
        </Box>
      </Popover>
    </>
  );
};
