import React from 'react';
import { Coach, Location } from 'modules/service/models/service.model';
import { Address } from 'data/entities/userContacts.entity';

export function locationStringBuilder(location: Location): string {
  const { name, address } = location;
  return `${name}, ${address.streetNumber} ${address.streetName}, ${address.suburb}, ${address.city} ${address.zipCode}`;
}

export function locationsRenderBuilder(location: Location) {
  const { name, address } = location;
  const finalAddressLine = `${address.suburb}, ${address.city}, ${address.zipCode}`;
  return (
    <>
      {name} <br /> {address.streetNumber} {address.streetName} <br /> {finalAddressLine}
    </>
  );
}

export function locationsStringBuilder(locations: Location[]): string[] {
  const formattedLocations: string[] = [];
  for (let i = 0; i < locations.length; i += 1) {
    const currentFormattedLocation = locationStringBuilder(locations[i]);
    formattedLocations.push(currentFormattedLocation);
  }

  return formattedLocations;
}

export function addressStringBuilder(address?: Address, isCountryIncluded = false): string {
  let addressString = '';
  if (!address) {
    return addressString;
  }
  if (address.subpremise) {
    addressString += `${address?.subpremise}/`;
  }
  if (address.street_number) {
    addressString += `${address?.street_number}`;
  }
  if (address.street_name) {
    addressString += ` ${address?.street_name}`;
  }
  if (address.suburb) {
    addressString += `, ${address?.suburb}`;
  }
  if (address.city) {
    addressString += `, ${address?.city}`;
  }
  if (address.country && !isCountryIncluded) {
    addressString += `, ${address?.country}`;
  }
  if (address.zip_code) {
    addressString += ` ${address?.zip_code}`;
  }
  return addressString.trim();
}

export function fullNameBuilder(coach: Coach): string {
  return `${coach.firstName} ${coach.lastName}`;
}

export function fullNamesBuilder(coaches: Coach[]): string[] {
  const formattedFullNames: string[] = [];
  for (let i = 0; i < coaches.length; i += 1) {
    const currentFullName = fullNameBuilder(coaches[i]);
    formattedFullNames.push(currentFullName);
  }

  return formattedFullNames;
}
