import React from 'react';
import styles from './FormErrorMessage.module.scss';

const FormErrorMessage = ({ message }: { message?: string }) => {
  if (!message) {
    return null;
  }

  return (
    <div className={styles.errorContainer}>
      <h6 className={styles.errorText}>{message || ''}</h6>
    </div>
  );
};

export default FormErrorMessage;
