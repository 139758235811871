import React from 'react';
import styles from './SearchClientInput.module.scss';

interface ISearchClientInput {
  onChange: Function;
  clientValue: string;
}
const SearchClientInput: React.FC<ISearchClientInput> = ({ onChange, clientValue }) => {
  return (
    <div className={styles.search}>
      <input
        placeholder="Name email address or phone number"
        className={styles.input}
        type="text"
        value={clientValue}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
export default SearchClientInput;
