import React from 'react';
import styles from './AttachmentDeleteBtn.module.scss';
import CloseWhiteIcon from '../../../../assets/icons/close-btn-white.svg';

interface IProps {
  onClick: Function;
}

const AttachmentDeleteBtn: React.FC<IProps> = ({ onClick }) => (
  <div
    className={styles.deleteButton}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    <img alt="close-btn" src={CloseWhiteIcon} />
  </div>
);

export default AttachmentDeleteBtn;
