/* eslint-disable no-continue */
import { every } from 'lodash';
import { WorkingHours } from 'data/entities/profile.entity';
import { ScheduledDate } from 'modules/service/models/service.model';
import { ServiceTypeEnum } from 'data/enums/ServiceType.enum';
import { FindServiceByCoachesAndLocationsAgg } from 'data/repos/services.repo';
import RealmBase from '../base/realm.base';
import { EventType, Lesson, LessonClient, LessonClientStatus } from '../entities/lessons.entity';
import RealmRepositories from '../base/realm.repo';
import { Conversions } from '../../common/utils/conversions.helper';
import Logger from '../../middleware/logger.middleware';
import { SelectedService } from '../../modules/book/models/booking.model';
import {
  ServiceInviteStatusEnum,
  ServiceScheduledDate,
  Services,
} from '../entities/service.entity';
import { IsSuccessfulModel } from '../common/models/isSuccessful.model';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export default class ServicesService extends RealmBase {
  static async getById(id: string): Promise<Services | undefined> {
    try {
      return await RealmRepositories.Services.getById(id);
    } catch (error) {
      if (Logger.isDevEnvironment) console.error(error);
      return undefined;
    }
  }

  static updateServiceWithFullyBooked = (services: FindServiceByCoachesAndLocationsAgg[]) => {
    const updateServiceResult = services.map((item: FindServiceByCoachesAndLocationsAgg) => {
      if (item.scheduled_dates && item.scheduled_dates?.length > 0) {
        let isFullyBooked = false;
        item.scheduled_dates.forEach((dateItem: ServiceScheduledDate) => {
          if (item.matching_lessons) {
            const matchedLesson = item.matching_lessons.find(
              (lessonItem: Lesson) => lessonItem.date_start === dateItem.date_start,
            );

            if (matchedLesson && matchedLesson.clients) {
              const countAcceptedClients =
                matchedLesson?.clients.filter(
                  (clientItem: LessonClient) =>
                    clientItem.is_accepted === true &&
                    clientItem.status === LessonClientStatus.Accepted,
                ).length ?? 0;

              /** Only mark this as fully booked if total numbers of clients >= max participants for that service or lesson */
              if (countAcceptedClients >= item.max_participants) isFullyBooked = true;
            }
          }
        });

        return {
          ...item,
          is_fully_booked: isFullyBooked,
        };
      }

      return item;
    });

    return updateServiceResult;
  };

  /** Note:
   * Based on observation, the returned results of this method is matching to the Service type
   * rather than to the SelectedService type */
  static async getServicesByCoachesAndLocationsAsync(
    orgId: string,
    coachIds: string[],
    locationIds: string[],
  ): Promise<SelectedService[]> {
    try {
      const filteredServicesResult =
        await RealmRepositories.Services.getServicesByCoachAndLocationAsync(
          orgId,
          coachIds,
          locationIds,
        );

      if (!filteredServicesResult || filteredServicesResult.length === 0) {
        if (Logger.isDevEnvironment) {
          console.error('Could not find filtered services.');
        }
        return [];
      }

      const updateServiceResult = this.updateServiceWithFullyBooked(filteredServicesResult);

      const convertedSnakeToCamelObject: SelectedService[] =
        Conversions.keysToCamel(updateServiceResult);

      return convertedSnakeToCamelObject;
    } catch (error) {
      console.error('There was an error processing the request');
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return [];
    }
  }

  /** Note:
   * Based on observation, the returned results of this method is matching to the Service type
   * rather than to the SelectedService type */
  static async getServiceByCoachesAndLocationsForUserAsync(
    orgId: string,
    userId: string,
    email: string,
    coachIds: string[],
    locationIds: string[],
  ): Promise<SelectedService[]> {
    try {
      if (this.app.currentUser === null) {
        console.error('Can only fetch services for authenticated user');
        return [];
      }

      const filteredServicesResult =
        await RealmRepositories.Services.getServicesByCoachAndLocationForUserAsync(
          orgId,
          userId,
          email,
          coachIds,
          locationIds,
        );
      if (!filteredServicesResult || filteredServicesResult.length === 0) {
        return [];
      }

      const updateServiceResult = this.updateServiceWithFullyBooked(filteredServicesResult);

      const convertedSnakeToCamelObject: SelectedService[] =
        Conversions.keysToCamel(updateServiceResult);

      return convertedSnakeToCamelObject;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return [];
    }
  }

  static async getServicesForUserAsync(orgId: string, userId: string): Promise<SelectedService[]> {
    try {
      if (this.app.currentUser === null) {
        console.error('Can only fetch services for authenticated user');
        return [];
      }

      const filteredServicesResult = await RealmRepositories.Services.getServicesForUserAsync(
        orgId,
        userId,
      );
      if (!filteredServicesResult || filteredServicesResult.length === 0) {
        return [];
      }

      const convertedSnakeToCamelObject: SelectedService[] =
        Conversions.keysToCamel(filteredServicesResult);

      return convertedSnakeToCamelObject;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return [];
    }
  }

  static async getServiceInviteByIdAsync(
    serviceId: string,
    orgId: string,
    inviteId: string,
    userId: string,
    email: string,
  ): Promise<SelectedService | undefined> {
    try {
      const filteredServicesResult = await RealmRepositories.Services.getServiceInviteByIdAsync(
        serviceId,
        orgId,
        inviteId,
        userId,
        email,
      );

      if (!filteredServicesResult || filteredServicesResult.length === 0) {
        return undefined;
      }
      const serviceResult = filteredServicesResult[0];
      return Conversions.keysToCamel(serviceResult);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }

      return undefined;
    }
  }

  static async getUserInvitedServicesAsync(userId: string, orgId: string, email: string) {
    try {
      const invitedServices = await RealmRepositories.Services.getServicesUserHasBeenInvitedToo(
        userId,
        orgId,
        email,
      );
      if (!invitedServices || invitedServices.length === 0) {
        return [];
      }

      const services = invitedServices?.map((service: any) => {
        return { ...service, type: EventType.Service };
      });
      return services;
    } catch (error) {
      return [];
    }
  }

  static async getServiceForLessonInviteAsync(
    serviceId: string,
    orgId: string,
    userId?: string,
  ): Promise<SelectedService | undefined> {
    try {
      const filteredServicesResult =
        await RealmRepositories.Services.getServiceForLessonInviteAsync(serviceId, orgId, userId);
      if (!filteredServicesResult) {
        return undefined;
      }

      const convertedSnakeToCamelObject: SelectedService[] =
        Conversions.keysToCamel(filteredServicesResult);

      const currentService = convertedSnakeToCamelObject[0];

      return currentService;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return undefined;
    }
  }

  static async getServiceInvitationEmailByInvitationId(
    invitationId: string,
  ): Promise<string | undefined> {
    try {
      const userId = await RealmRepositories.Services.getServiceInvitationUserIdByInvitationId(
        invitationId,
      );
      if (userId) {
        const user = await RealmRepositories.Users.getUser(userId);
        return user ? user.email : undefined;
      }
      return undefined;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return undefined;
    }
  }

  static async getLocationsCoachIsOfferingServicesAsync(
    userId: string,
    orgId: string,
  ): Promise<string[]> {
    try {
      return await RealmRepositories.Services.getLocationsByServiceCoachesAsync(userId, orgId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return [];
    }
  }

  static async getWorkingHoursLocationsCoach(
    userId: string,
    orgId: string,
  ): Promise<WorkingHours | undefined> {
    try {
      return await RealmRepositories.Users.getWorkingHoursByUserIdAndOrg(userId, orgId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return undefined;
    }
  }

  static async getCoachesByServiceLocationAsync(
    selectedLocation: string,
    orgId: string,
  ): Promise<string[]> {
    try {
      return await RealmRepositories.Services.getCoachesByServiceLocationAsync(
        selectedLocation,
        orgId,
      );
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return [];
    }
  }

  static UpdateInvitedClientStatus(
    serviceId: string,
    invitedId: string,
    userId: string,
    inviteStatusEnum: ServiceInviteStatusEnum,
  ) {
    return RealmRepositories.Services.updateInvitedClientStatus(
      serviceId,
      invitedId,
      userId,
      inviteStatusEnum,
    );
  }

  static isPurchased(service: SelectedService): boolean {
    return !!(
      service.serviceTypeEnum === ServiceTypeEnum.Package &&
      service.packagesBought &&
      service.packagesBought.lessonsRemaining &&
      service.packagesBought.lessonsRemaining > 0
    );
  }

  static isPrepaid(service: Services): boolean {
    return (
      service?.is_package &&
      service?.is_preset_work_hours &&
      service?.unscheduled_package_occurrences !== undefined &&
      service?.unscheduled_package_occurrences > 0
    );
  }

  static isPackage(service: Services): boolean {
    return (
      service?.is_package === true &&
      service?.is_preset_work_hours === false &&
      service?.package_id !== '' &&
      service?.package_id !== undefined &&
      service?.package_id !== null &&
      service?.scheduled_dates !== undefined &&
      service?.scheduled_dates.length > 0
    );
  }

  /** Check if all services in the array are prepaid and paid */
  static isPrepaidAndPaid(services: SelectedService[]): boolean {
    return every(services, (value: SelectedService) => {
      return value.serviceTypeEnum === ServiceTypeEnum.Package && !value.isUpfrontPayment;
    });
  }

  static isIncludeTax(services: SelectedService[]): boolean {
    return every(services, (value: SelectedService) => {
      return value.taxRateIds?.length !== 0;
    });
  }

  static isPrepaidOnly(services: SelectedService[]): boolean {
    return services.every((x) => x.serviceTypeEnum === ServiceTypeEnum.Package);
  }

  static isServices(obj: SelectedService | Services): obj is Services {
    return (obj as Services).org_id !== undefined;
  }

  static isOverdue(service: SelectedService | Services, isLesson?: boolean): boolean {
    if (this.isServices(service)) {
      if (this.isServiceLesson(service)) {
        return (
          !!service.scheduled_dates && service.scheduled_dates[0].date_start < getUnixMilliseconds()
        );
      }

      if (!service.is_package || !service.scheduled_dates || !service.scheduled_dates.length) {
        return false;
      }

      const firstScheduledDate = service.scheduled_dates?.sort(
        (a, b) => a.date_start - b.date_end,
      )[0];
      return firstScheduledDate.date_start < getUnixMilliseconds();
    }

    if (isLesson === true && service.scheduledDates[0]?.dateStart) {
      return service.scheduledDates[0].dateStart < getUnixMilliseconds();
    }

    if (!service.isPackage || !service.scheduledDates || !service.scheduledDates.length) {
      return false;
    }

    let sortedDates: ScheduledDate[] = [...service.scheduledDates];
    sortedDates = sortedDates.sort(
      (a: ScheduledDate, b: ScheduledDate) => a.dateStart - b.dateStart,
    );
    const firstScheduledDate = sortedDates[0];
    return !!firstScheduledDate?.dateStart && firstScheduledDate.dateStart < getUnixMilliseconds();
  }

  static isPast(service: SelectedService | Services, isLesson?: boolean): boolean {
    const currentTime = getUnixMilliseconds();

    if (this.isServices(service)) {
      if (this.isServiceLesson(service)) {
        return !!service.scheduled_dates && service.scheduled_dates[0].date_end < currentTime;
      }

      if (!service.is_package || !service.scheduled_dates || !service.scheduled_dates.length) {
        return false;
      }

      const scheduledDates = service.scheduled_dates?.filter(
        (item: ServiceScheduledDate) => item.date_start >= currentTime,
      );

      return scheduledDates.length === 0;
    }

    if (isLesson === true) {
      return service.scheduledDates[0].dateEnd < currentTime;
    }

    if (!service.isPackage || !service.scheduledDates || !service.scheduledDates.length) {
      return false;
    }

    const scheduledDates = service.scheduledDates?.filter(
      (item: ScheduledDate) => item.dateStart >= currentTime,
    );
    return scheduledDates.length === 0;
  }

  static countUnbookedPrepaidPackages(services: SelectedService[]): number {
    return services.filter(
      (service) =>
        service.isPackage &&
        service.serviceTypeEnum === ServiceTypeEnum.Package &&
        service.isUpfrontPayment,
    ).length;
  }

  static async validateClientInvite(
    orgId: string,
    inviteId: string,
    email: string,
  ): Promise<IsSuccessfulModel> {
    const result: IsSuccessfulModel = { isSuccess: false };
    const service = await RealmRepositories.Services.getServiceByClientInvite(orgId, inviteId);
    if (!service)
      return {
        ...result,
        errors: [
          { key: 'getServiceByClientInvite', value: 'Could not find service by client invite id.' },
        ],
      };

    const foundInviteById = service.invited_clients?.find((x) => x.invite_id === inviteId);
    if (!foundInviteById)
      return {
        ...result,
        errors: [
          { key: '!foundInviteById', value: 'Could not find invitation via invitation id.' },
        ],
      };

    const doesEmailMatchInvite = foundInviteById.email === email.toLowerCase();
    if (!doesEmailMatchInvite)
      return {
        ...result,
        errors: [{ key: '!doesEmailMatchInvites', value: 'Email does not match invitation.' }],
      };

    if (foundInviteById.invite_status_enum === ServiceInviteStatusEnum.Accepted) {
      return { ...result, errors: [{ key: '', value: 'Already accepted invitation.' }] };
    }

    return { isSuccess: true, value: service };
  }

  static isServiceLesson(service: Services) {
    return (
      service.is_package === false &&
      service.is_preset_work_hours === false &&
      !service.unscheduled_package_occurrences &&
      service.scheduled_dates &&
      service.scheduled_dates.length === 1
    );
  }

  static updateWorkingHours(selectedServices: SelectedService[]) {
    const currentTime = getUnixMilliseconds();
    const updatedServices =
      RealmRepositories.Services.doWorkingHoursIntersectionAndUpdateForSelectedServices(
        currentTime,
        selectedServices,
      );

    return updatedServices;
  }
}
