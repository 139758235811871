import React from 'react';
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';

interface CardProps extends Pick<MuiCardProps, 'onClick' | 'children'> {
  height?: number;
  contentPadding: number | string;
  borderRadius?: number;
}

export const Card = ({ height, borderRadius, contentPadding, children, onClick }: CardProps) => {
  const styles = {
    card: {
      borderRadius: borderRadius ?? 4,
      minHeight: height ?? 200,
      width: '100%',
    },
    cardContent: {
      minHeight: height ?? 200,
      padding: contentPadding,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  };

  return (
    <MuiCard onClick={onClick} sx={styles.card}>
      <MuiCardContent sx={styles.cardContent}>{children}</MuiCardContent>
    </MuiCard>
  );
};
