/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Autocomplete, TextField } from '@mui/material';
import { bookingSlice, BookingStep } from 'redux/slices';
import TimezoneHelper from 'common/utils/timezone.help';
import { TimezoneList } from 'data/entities/service.entity';
import { BookingContainer, MonthlyCalendar, LessonCard, TimePicker } from './components';
import { useMobileViewDetector } from '../../../../common/hooks';
import type { RootState } from '../../../../redux/store';
import { useTimeSlots, useCalendar, useService } from './hooks';

export const BookingSelectDateTime = () => {
  const booking = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  const { isViewedFromMobile } = useMobileViewDetector();
  const { timezone } = booking.selected;

  /** Service hooks */
  const {
    getActiveSelectedService,
    getDetailedSelectedLessons,
    getTotalTimeSlotsNeededToBeSelected,
    getSelectedService,
    handleSelectLessonCard,
    handleTimezone,
    handleConfirmLessonDateTime,
    handleCancelLessonDateTime,
  } = useService();

  const handleChangeTimezone = (zone: string) => {
    handleTimezone(zone.split(' ')[1]);
  };

  const timezoneList: string[] = TimezoneHelper.GetTimezoneList();
  const timezoneParam: TimezoneList[] = [];
  timezoneList.map((zoneName) => {
    timezoneParam.push({
      label: zoneName,
    });
  });

  const findIndex = timezoneList.findIndex((zone: string) => zone.indexOf(timezone) >= 0);

  /** Time slots hooks */
  const { isLoading, handleFetchBusyTimesForSelectedLesson, getAlreadySelectedTimeSlots } =
    useTimeSlots();

  /** Calendar hooks */
  const { calendar, handleCalendarNext, handleCalendarPrev, selectedDate, setSelectedDate } =
    useCalendar();

  const activeSelectedService = getActiveSelectedService();
  const selectedTimeSlotsInThisBookingSession = getAlreadySelectedTimeSlots();
  const selectedService = getSelectedService();

  const isSelectedServiceNeedToSelectTime =
    selectedService && selectedService.count > selectedService.scheduledDates.length;

  /** Decide if calendar is going to be displayed or not */
  const isCalendarDisplayed = !isViewedFromMobile || selectedDate === null;

  /** Decide lesson container height and overflow style on both mobile and desktop views */
  const lessonContainer = {
    height: !isViewedFromMobile ? 485 : undefined,
    overflow: !isViewedFromMobile ? 'auto' : undefined,
    padding: !isViewedFromMobile ? 2 : 0,
  };

  /** Lesson related */
  const detailedSelectedLessons = getDetailedSelectedLessons();
  if (detailedSelectedLessons.length <= 0)
    dispatch(bookingSlice.actions.changeStep(BookingStep.Summary));

  const isContinueButtonEnabled =
    getTotalTimeSlotsNeededToBeSelected() <= getAlreadySelectedTimeSlots().length &&
    !(getTotalTimeSlotsNeededToBeSelected() === 0 && getAlreadySelectedTimeSlots().length === 0);

  /** Use Effects */
  useEffect(() => {
    if (activeSelectedService) {
      handleFetchBusyTimesForSelectedLesson(activeSelectedService.id);
    }
  }, [activeSelectedService, calendar]);

  return (
    <BookingContainer
      /** Let's not hide the calendar when we load the busy times, we will use skeleton component instead */
      isLoading={false}
      title="Select date and time"
      backButton={{
        onClick: () => dispatch(bookingSlice.actions.changeStep(BookingStep.SelectService)),
      }}
      continueButton={{
        onClick: () => {
          dispatch(bookingSlice.actions.changeStep(BookingStep.Summary));
        },
        disabled: !isContinueButtonEnabled,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box fontSize="1.5rem" fontWeight="bold" textAlign="left" marginLeft={2}>
            Services
          </Box>
          <Box
            id="lessons-container"
            sx={{
              ...lessonContainer,
              marginBottom: 2,
              scrollBehavior: 'smooth',
            }}
          >
            <Grid container spacing={1}>
              {detailedSelectedLessons.map((service) => {
                const curLesson = booking.selected.services.find((item) => item.id === service.id);
                if (curLesson) {
                  return (
                    <Grid item xs={12} key={service.id}>
                      <LessonCard
                        id={service.id}
                        count={curLesson.count ?? 1}
                        isActive={curLesson.isCalendarActive ?? false}
                        timezone={timezone}
                        scheduledDates={curLesson.scheduledDates}
                        onSelectCard={() => handleSelectLessonCard(service.id)}
                        onCancelBookingTime={(dateStart) => handleCancelLessonDateTime(dateStart)}
                      />
                    </Grid>
                  );
                }
                return null;
              })}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            {isCalendarDisplayed && (
              <Grid item xs={12} md={selectedDate === null ? 12 : 6}>
                <MonthlyCalendar
                  timezone={timezone}
                  selectedDate={selectedDate}
                  month={calendar.month}
                  year={calendar.year}
                  isLoading={isLoading}
                  onNext={handleCalendarNext}
                  onPrev={handleCalendarPrev}
                  onSelectDate={(date) => setSelectedDate(date)}
                />
              </Grid>
            )}
            {selectedDate && (
              <Grid item xs={12} md={6}>
                <TimePicker
                  timezone={timezone}
                  selectedDate={selectedDate}
                  selectedTimeSlots={selectedTimeSlotsInThisBookingSession}
                  onSwitchSelectedDate={() => setSelectedDate(null)}
                  onConfirmTime={(start, end) => handleConfirmLessonDateTime(start, end)}
                  onCancelTime={(start) => handleCancelLessonDateTime(start)}
                  disableSelectNewTime={!isSelectedServiceNeedToSelectTime}
                />
              </Grid>
            )}
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              flexWrap: 'wrap',
              gap: 2,
              mt: 2,
              alignItems: 'center',
            }}
          >
            <Autocomplete
              disableClearable
              disablePortal
              aria-label={undefined}
              defaultValue={timezoneParam[findIndex]}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              id="timezone-select"
              options={timezoneParam}
              onChange={(event: any, newValue: TimezoneList | null) => {
                handleChangeTimezone(newValue?.label ?? '');
              }}
              size="small"
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Timezone" />}
            />
          </Box>
        </Grid>
      </Grid>
    </BookingContainer>
  );
};
