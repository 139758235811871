import { UpdateCreditBalanceModel } from 'api/models/balance/updateCreditBalance.model';
import { BaseRoute } from '../../base/Route.base';

export class Balance extends BaseRoute {
  /**
   *
   * @param userId
   * @param orgId
   * @returns data: number
   */
  async getClientCreditsForOrgAsync(userId: string, orgId: string) {
    return await this.api.get(`/balance/user/${userId}/org/${orgId}/credits`);
  }

  async updateClientCreditBalanceAsync(
    userId: string,
    orgId: string,
    updateCreditBalanceModel: UpdateCreditBalanceModel,
  ) {
    return await this.api.put(
      `/balance/user/${userId}/org/${orgId}/credits`,
      updateCreditBalanceModel,
    );
  }
}
