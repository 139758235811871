import React, { useState } from 'react';
import * as Realm from 'realm-web';
import styles from './ForgotPassword.module.scss';
import logo from '../../../../assets/images/auth/forgotPassword-image.png';
import AuthMenu from '../../components/AuthMenu/AuthMenu';
import InputField from '../../../../components/InputField/InputField';
import { Config } from '../../../../config';
import Logger from '../../../../middleware/logger.middleware';
import { Validator } from '../../../../common/utils/validator.helper';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailError, setEmailError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const app = new Realm.App({ id: Config().RealmAppId as string });

  const onEmailChanged = (e: any) => {
    const emailValue = e.target.value.trim().toLowerCase();
    if (!emailValue.match(Validator.RegexDictionary.Email)) {
      setEmailError('Please enter a valid Email Address');
    } else {
      setEmailError(undefined);
    }

    setEmail(emailValue);
  };

  const onSendClicked = async () => {
    if (!email) {
      setEmailError('Email is required');
      return;
    }

    if (emailError) {
      return;
    }

    try {
      setIsLoading(true);
      await app.emailPasswordAuth.callResetPasswordFunction(
        { email, password: 'PasswordPlaceholder' },
        [true],
      );
      setIsLoading(false);
    } catch (err: any) {
      if (Logger.isDevEnvironment) {
        console.error('callResetPasswordFunction Error: ', err);
      }
    } finally {
      setEmailSent(true);
    }
  };

  const onSignInClicked = async () => {
    window.location.href = '/login';
  };

  const btnLabel = emailSent ? 'Back to sign in' : 'Send instructions';

  const headerTitle = emailSent ? 'Email sent' : 'Recover your password';

  const headerSubtitle = emailSent
    ? 'Check your email and follow the instructions to reset your password'
    : 'Enter your email and we will send you instructions to generate a new password';

  const authLink = emailSent ? '' : '/login';

  const authLinkText = emailSent ? '' : 'Back to sign in';

  return (
    <AuthMenu
      logo={logo}
      withWelcome={false}
      headerTitle={headerTitle}
      headerSubtitle={headerSubtitle}
      btnLabel={btnLabel}
      onBtnClicked={emailSent ? onSignInClicked : onSendClicked}
      authLink={authLink}
      authLinkText={authLinkText}
      sentEmail={emailSent}
      customStyles={styles}
      isBtn
      btnDisabled={!email || emailError !== undefined || isLoading}
      loading={isLoading}
    >
      <div className="text-danger"> {emailError}</div>
      {!emailSent && (
        <InputField type="email" placeholder="Email" required onChange={onEmailChanged} />
      )}
    </AuthMenu>
  );
};

export default ForgotPasswordPage;
