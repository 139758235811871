import useLocalStorageState from 'use-local-storage-state';
import { GuestInfo } from '../../api/models/payments/intent/createPaymentIntentRequest';
import SessionProvider from '../../providers/SessionProvider';

export const useUserId = () => {
  const [guestInfo] = useLocalStorageState<GuestInfo>('guestInfo');
  const userId = guestInfo?.userId ? guestInfo.userId : SessionProvider.getUserId();
  const email = (guestInfo?.email ? guestInfo.email : SessionProvider.getEmail()).toLowerCase();
  const firstName = guestInfo?.firstName ? guestInfo.firstName : SessionProvider.getFirstName();

  return { userId, email, firstName };
};
