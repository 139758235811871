import { Config } from '../../config';
import { SkillRating as SkillRatingModel } from '../entities/skillRating.entity';
import { BaseRepo } from './base.repo';

export class SkillRatingRepository extends BaseRepo {
  // #region Private Properties
  private skillRatingCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('skill_rating');
  // #endregion

  // #region Public Methods
  async getUserSkillRatingByOrgId(
    orgId: string,
    userId: string,
  ): Promise<SkillRatingModel | undefined> {
    return this.skillRatingCollection?.findOne({
      org_id: orgId,
      user_id: userId,
    });
  }
  // #endregion
}
