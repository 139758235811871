import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PlusIcon from '@mui/icons-material/AddOutlined';
import MinusIcon from '@mui/icons-material/RemoveOutlined';
import { alpha } from '@mui/material/styles';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import IconButton from '@mui/material/IconButton';
import { useLesson, useService } from '../../../hooks';
import { useMobileViewDetector } from '../../../../../../../common/hooks';

interface CounterBoxProps {
  children: React.ReactNode;
  isCounter?: boolean;
  onClick?: () => void;
}

const CounterBox = ({ children, isCounter, onClick }: CounterBoxProps) => {
  const { isViewedFromMobile } = useMobileViewDetector();
  const size = isViewedFromMobile ? 25 : 35;
  return (
    <Box
      width={!isCounter ? size : 'auto'}
      height={size}
      lineHeight={size}
      border={!isCounter ? 1 : 0}
      borderRadius={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : undefined,
      }}
    >
      {children}
    </Box>
  );
};

const CounterIcon = (props: CounterBoxProps) => <CounterBox {...props} />;
const CounterText = (props: CounterBoxProps) => <CounterBox {...props} isCounter />;

interface LessonCounterProps {
  id: string;
  count: number;
  onIncrease: () => void;
  onDecrease: () => void;
}

const LessonCounterMobile = ({ id, count, onDecrease, onIncrease }: LessonCounterProps) => {
  const bgColor = alpha('#000', 0.08);
  const { getServiceDetails, isPurchasedService } = useService();
  const { getFormattedPrice } = useLesson();
  const service = getServiceDetails(id);
  if (!service) return null;
  const formatedTotalPrice = getFormattedPrice(service.priceInCents * (count || 1));
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
      width="100%"
      marginTop={2}
    >
      <Typography fontWeight="bold">
        {!isPurchasedService(service) ? `${formatedTotalPrice} pp` : 'Select dates'}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        fontSize="1.5rem"
        lineHeight="1.5rem"
        justifyContent="center"
        alignItems="center"
      >
        <CounterIcon onClick={onDecrease}>
          <MinusIcon />
        </CounterIcon>
        <Box
          width={70}
          borderRadius={2}
          paddingY={1}
          paddingX={0.5}
          bgcolor={bgColor}
          fontSize="1rem"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {count}
        </Box>
        <CounterIcon onClick={onIncrease}>
          <PlusIcon />
        </CounterIcon>
      </Stack>
    </Stack>
  );
};

const LessonCounterDesktop = ({ id, count, onDecrease, onIncrease }: LessonCounterProps) => {
  const {
    getServiceDetails,
    isPurchasedService,
    isAdvancedLesson,
    isProgramme,
    isEvent,
    isLessonPack,
  } = useService();
  const { getFormattedPrice } = useLesson();
  const service = getServiceDetails(id);
  if (!service) return null;

  const formatedTotalPrice = getFormattedPrice(service.priceInCents * (count || 1));

  /** Default behavior */
  let isCounterDisplayed = true;

  /** Checks for Adv Lesson */
  if (isAdvancedLesson(service)) isCounterDisplayed = false;
  if (isLessonPack(service) && !isPurchasedService(service)) isCounterDisplayed = false;

  /** Event & Programme */
  if (isProgramme(service) || isEvent(service)) {
    if (service.isPackage) {
      isCounterDisplayed = false;
    } else {
      isCounterDisplayed = true;
    }
  }

  const handleIconClick = () => {
    if (!count) {
      onIncrease();
    } else {
      onDecrease();
    }
  };

  return (
    <Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={2}>
      <Typography fontWeight="bold">
        {!isPurchasedService(service) ? `${formatedTotalPrice} pp` : 'Select dates'}
      </Typography>
      {!service.isFullyBooked && !service.isPackageCancelled && (
        <>
          {isCounterDisplayed && (
            <Stack direction="row" spacing={2} fontSize="2rem" lineHeight="2rem">
              <CounterIcon onClick={onDecrease}>
                <MinusIcon />
              </CounterIcon>
              <CounterText>{count}</CounterText>
              <CounterIcon onClick={onIncrease}>
                <PlusIcon />
              </CounterIcon>
            </Stack>
          )}

          {!isCounterDisplayed && (
            <IconButton aria-label="delete" onClick={handleIconClick}>
              {!count ? <RadioButtonUncheckedOutlinedIcon /> : <CheckCircleOutlineOutlinedIcon />}
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};

export const LessonCounter = (props: LessonCounterProps) => {
  const { isViewedFromMobile } = useMobileViewDetector();
  return isViewedFromMobile ? (
    <LessonCounterMobile {...props} />
  ) : (
    <LessonCounterDesktop {...props} />
  );
};
