import React from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';
import Spinner from '../Spinner/Spinner';

export const OkButtonType = {
  Default: styles.button,
  Danger: clsx(styles.button, styles.danger),
  Add: clsx(styles.button, styles.add),
  Success: clsx(styles.button, styles.success),
  Info: clsx(styles.button, styles.info),
};

interface IProps {
  label: string;
  type?: string;
  onClick: any;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  isVisibleBtn?: boolean;
}

const Button: React.FC<IProps> = ({
  label,
  type = OkButtonType.Default,
  onClick,
  className,
  loading = false,
  disabled = false,
  isVisibleBtn = true,
}) => {
  const buttonStyle =
    loading || disabled
      ? clsx(type, className, styles.loading)
      : clsx(type, className, styles.notLoading);

  const getSpinnerColor = () => {
    if (type === OkButtonType.Danger) return '#fa4748';

    return '#fff';
  };

  return (
    <div
      className={isVisibleBtn ? buttonStyle : styles.invisible}
      onClick={() => !loading && !disabled && onClick()}
    >
      {loading && <Spinner color={getSpinnerColor()} />}
      {label}
    </div>
  );
};

export default Button;
