import { BaseApi } from 'api/base/Api.base';
import { CreateGuestAccountModel } from 'api/models/auth/createGuestAccount.model';
import { GuestAccountCreatedModel } from 'api/models/auth/guestAccountCreated.model';
import { SignUpResponseModel } from 'api/models/auth/signUp/signUpResponse.model';
import { SignUpModel } from 'api/models/auth/signup.model';

export class Auth {
  api: BaseApi;

  constructor(apiClass: BaseApi) {
    this.api = apiClass;
  }

  async signUp(signUpModel: SignUpModel): Promise<SignUpResponseModel> {
    return (await this.api.post('auth/mongo/SignUp', signUpModel)).data;
  }

  async guestSignUp(
    createGuestAccountModel: CreateGuestAccountModel,
  ): Promise<GuestAccountCreatedModel> {
    return (await this.api.post('auth/mongo/signup/guest', createGuestAccountModel)).data;
  }

  async login(email: string, password: string) {
    return await this.api.post('auth/mongo/login', { email, password });
  }

  async verifyToken(accessToken: string) {
    return await this.api.postWithConfig('auth/mongo/verifyToken', null, {
      params: { accessToken },
    });
  }

  async refreshToken(refreshToken: string) {
    return await this.api.post(`auth/mongo/refreshToken?refreshToken=${refreshToken}`, null);
  }

  async resetPassword(password: string, token: string, tokenId: string) {
    return await this.api.post('/auth/mongo/ResetPassword', {
      password,
      token,
      tokenId,
    });
  }

  async verifySingupEmail(token: string, tokenId: string) {
    const data = {
      token,
      tokenId,
    };

    const response = await this.api.post('auth/mongo/VerifyEmail', data);
    return response;
  }
}
