import { BaseRepo } from './base.repo';
import { Config } from '../../config';
import { Payment } from '../enums/Payment.enum';
import { PaymentEntity } from '../entities/payment.entity';

export default class PaymentsRepository extends BaseRepo {
  // #region Private Properties
  private paymentsCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('payments');
  // #endregion

  // #region Public Methods
  async getPaymentByInvoiceNumber(invoiceNumber: string) {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.paymentsCollection = mongo.db(Config().RealmDbName as string).collection('payments');
    }

    return this.paymentsCollection?.findOne({
      'invoice.number': invoiceNumber,
    });
  }

  async addLessonIdsForPrepaid(
    serviceId: string,
    orgId: string,
    userId: string,
    lessonIds: string[],
  ): Promise<PaymentEntity | undefined> {
    if (this.app.currentUser == null) {
      const mongo = await this.LogUserInAnonymously();
      this.paymentsCollection = mongo.db(Config().RealmDbName as string).collection('payments');
    }

    // TODO: This needs to be tested thoroughly.
    return this.paymentsCollection?.findOneAndUpdate(
      {
        org_id: orgId,
        user_id: userId,
        selected_services_ids: serviceId,
        paymentEnum: { $ne: Payment.Free },
      },
      {
        $addToSet: {
          lesson_ids: { $each: lessonIds },
        },
      },
      {
        sort: { date_created: -1 },
        returnNewDocument: true,
      },
    );
  }
  // #endregion
}
