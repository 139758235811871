import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { Radio, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { BrandThemeContext } from 'common/context';
import { RootState } from 'redux/store';
import { Grid } from '../../../../../components/Grid';
import { Location } from '../../../../service/models/service.model';
import ServicesService from '../../../../../data/services/services.service';
import { useOrgId } from '../../../../../common/hooks';
import { BookingState } from '../../../../../redux/slices';

import { AvatarOrIconProps } from './AvatarOrIcon';

interface LocationItemProps
  extends Pick<AvatarOrIconProps, 'imageUrl'>,
    Pick<Location, 'id' | 'name' | 'address'> {
  onClick: (id: BookingState['selected']['locationId']) => void;
}

export const LocationSelectionItemStandard = ({
  id,
  name,
  address,
  onClick,
}: LocationItemProps) => {
  const { orgId } = useOrgId();
  const theme = useContext(BrandThemeContext);
  const booking = useSelector((state: RootState) => state.booking);

  const [isNoCoachesWarningDisplayed, setIsNoCoachesWarningDisplayed] = React.useState(false);
  const handleClick = async () => {
    const tmpCoachIds: string[] = await ServicesService.getCoachesByServiceLocationAsync(id, orgId);
    if (tmpCoachIds.length > 0) {
      onClick(id);
      setIsNoCoachesWarningDisplayed(false);
    } else {
      onClick(null);
      setIsNoCoachesWarningDisplayed(true);
    }
  };
  return (
    <>
      <Radio
        sx={{ height: 'fit-content' }}
        checked={id === booking.selected.locationId}
        onClick={handleClick}
      />
      <Box component="div" display="flex" flexGrow={1} onClick={handleClick}>
        <Grid container>
          <Grid item md={12}>
            {name}
          </Grid>
          <Grid item md={12}>
            <Typography sx={{ fontSize: 12 }}>
              {address.streetNumber} {address.streetName}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography sx={{ fontSize: 12 }}>
              {address.suburb}, {address.city}, {address.zipCode}
            </Typography>
          </Grid>
          {isNoCoachesWarningDisplayed && (
            <Grid item md={12}>
              <Box color={theme.colors.red.opacity_100}>
                No coaches available for this location.
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

const arePropsEqual = (oldProps: LocationItemProps, newProps: LocationItemProps) => {
  // eslint-disable-next-line
  const { onClick: oldPropsOnClick, ...cleanedOldProps } = oldProps;

  // eslint-disable-next-line
  const { onClick: newPropsOnClick, ...cleanedNewProps } = newProps;

  const isSame = JSON.stringify(cleanedOldProps) === JSON.stringify(cleanedNewProps);
  return isSame;
};

export const LocationSelectionItem = React.memo(LocationSelectionItemStandard, arePropsEqual);
