import { BaseRoute } from '../../base/Route.base';

export class Attachment extends BaseRoute {
  async getFavorite() {
    const response = await this.api.get('favorites');
    return response;
  }

  async addFavorite(isFavorite: boolean, attachmentId: string, type: number, categories: any) {
    const response = await this.api.post('favorites', {
      isFavorite,
      attachmentId,
      type,
      categories,
    });
    return response;
  }

  async updateNotes(note: string, attachmentId: string, type: number) {
    const response = await this.api.put('lessons/attachment/note', {
      note,
      attachmentId,
      type,
    });
    return response;
  }

  async deleteFavorite(attachmentId: string) {
    const response = await this.api.delete(`favorites/${attachmentId}`);
    return response;
  }

  async uploadAttachment(id: string, type: number, thumbnailFile: File, file: File) {
    const formData = new FormData();

    formData.append('id', id);
    formData.append('type', type.toString());
    formData.append('thumbnail', thumbnailFile);
    formData.append('file', file);

    return await this.api.post('attachments', formData);
  }
}
