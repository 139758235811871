import React from 'react';
import PracticeLocationsContainer from '../containers/PracticeLocationsContainer/PracticeLocationsContainer';

const ContactsPage = () => {
  return (
    <div>
      <PracticeLocationsContainer />
    </div>
  );
};
export default ContactsPage;
