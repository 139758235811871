import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Config } from '../../../../../../config';
import { CardPayment, CardPaymentProps } from './CardPayment';

export const CardPaymentContainer = ({ onSuccess, paymentMethod }: CardPaymentProps) => {
  const stripePromise = loadStripe(Config().StripeKey ?? '');
  return (
    <Elements stripe={stripePromise}>
      <CardPayment onSuccess={onSuccess} paymentMethod={paymentMethod} />
    </Elements>
  );
};
