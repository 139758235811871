// eslint-disable-next-line max-classes-per-file
import { Config } from '../../../config';
import { getOrgId, vaildOrgRoute } from '../common.helpers';

class OrgStaticRoutes {
  static base = `/org`;

  public Id = `${OrgStaticRoutes.base}/:orgId`;

  /**
   * NOTE:
   * The following code need to be kept as it was to prevent the app from breaking.
   */
  // eslint-disable-next-line
  public Home = () => {
    if (vaildOrgRoute(window.location.pathname)) {
      return `/org/${getOrgId()}`;
    }

    return '/';
  };
}

export class StaticRoutes {
  public static Domain = Config().AppUrl;

  public static readonly Home = '/';

  public static readonly Org = '/org';

  public static readonly Lesson = '/lesson';

  public static readonly Lessons = '/lessons';

  public static readonly Service = '/service';

  public static readonly Book = '/book';

  public static readonly SelectOrg = '/select/org';

  public static readonly Client = '/org/:orgId/client/:clientId';

  public static readonly Login = '/login';

  public static readonly NoOrgs = '/no-org';

  public static readonly Favorites = '/favorites';

  public static readonly Practice = '/practice';

  public static readonly Events = '/events';

  public static readonly Exercises = '/exercises';

  public static readonly Calendar = '/calendar';

  // Embedded routes

  public static OrgRoutes = new OrgStaticRoutes();
}
