/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isAfter } from 'date-fns';
import { get, isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import TabView from 'components/TabView/TabView';
import { useCalendarPage } from 'modules/calendar/pages/CalendarPage/useCalendarPage.hooks';
import { LessonsParentTabs, LessonsTabs } from '../../models/lessons.model';
import ListPage from '../../../../components/Pages/ListPage';
import ListView from '../../../../components/Pages/Items/ListView/ListView';
import RealmRepositories from '../../../../data/base/realm.repo';
import SessionProvider from '../../../../providers/SessionProvider';
import { Lesson, LessonClientStatus } from '../../../../data/entities/lessons.entity';
import { BillingHelper } from '../../../../common/utils/billing.helper';
import LessonsService from '../../../../data/services/lessons.service';
import { UserSubscriptionsModel } from '../../../../data/models/userSubscription.mode';
import { getUnixMilliseconds } from '../../../../common/utils/date.helpers';
import styles from './LessonsPage.module.scss';

interface LessonsPageProps {
  subscriptionInfo?: UserSubscriptionsModel;
}

const LessonsPage = ({ subscriptionInfo }: LessonsPageProps) => {
  const [allLessons, setAllLessons] = useState<Lesson[]>([]);
  const [allFetchedLessons, setAllFetchedLessons] = useState<Lesson[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedTab, setSelectedTab] = useState(LessonsTabs.Upcoming);
  const [selectedParentTab, setSelectedParentTab] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [emptyListText, setEmptyListText] = useState('');
  const [allowUserAccess, setAllowUserAccess] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize] = useState(15);
  const [isFilter, setIsFilter] = useState(false);

  const { orgId } = useParams<Record<string, string | undefined>>();
  const currentUserId = SessionProvider.getUserId();
  const email = SessionProvider.getEmail();

  const allowAccessCompleted = useRef(false);

  const { isMostRecentPastLessonFromLessons } = useCalendarPage();

  useEffect(() => {
    if (allowAccessCompleted.current === false) {
      const allowAccess = BillingHelper.allowUserAccess(subscriptionInfo);
      setAllowUserAccess(allowAccess);
      allowAccessCompleted.current = true;
    }

    return () => {
      allowAccessCompleted.current = false;
    };
  }, [subscriptionInfo]);

  const getCurrentUserLessonsAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const currentOrgId = orgId ?? '';

      const allFetchedLessonsAsync = await LessonsService.getUserViewableLessonsByOrdId(
        currentUserId,
        currentOrgId,
      );
      setAllFetchedLessons(allFetchedLessonsAsync);

      const lessons = await LessonsService.getAcceptedOrCancelledLessonsForUser(
        currentUserId,
        email,
        currentOrgId,
        selectedTab,
        selectedParentTab,
        selectedCategories,
        searchValue,
        currentIndex,
        pageSize,
      );

      if (!lessons) {
        setHasMore(false);
        return;
      }

      // Start all asynchronous operations and collect the promises
      const filesPromises = lessons.map((lesson: Lesson) => {
        const reasonSolutionUploads = [
          ...(lesson?.reason_uploads || []),
          ...(lesson?.solution_uploads || []),
        ];
        return RealmRepositories.Attachments.getFilesByIds(reasonSolutionUploads).then((files) => ({
          lesson,
          files,
        }));
      });

      // Wait for all the promises to resolve
      const filesResults = await Promise.all(filesPromises);

      // Apply the results back to the lessons
      filesResults.forEach(({ lesson, files }) => {
        lesson.thumbnails = files;
      });

      setHasMore(true);

      if (isFilter && currentIndex > 0) {
        setAllLessons((prevLessons) => [...prevLessons, ...lessons]);
      } else {
        setAllLessons(lessons);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [
    currentUserId,
    email,
    orgId,
    currentIndex,
    selectedCategories,
    selectedTab,
    selectedParentTab,
    searchValue,
    isFilter,
  ]);

  const showListItem = (lesson: Lesson) => {
    if (selectedTab === LessonsTabs.Past && !allowUserAccess) {
      if (isMostRecentPastLessonFromLessons(lesson, allFetchedLessons)) return true;
      return false;
    }

    if (selectedTab === LessonsTabs.All && !allowUserAccess) {
      if (isMostRecentPastLessonFromLessons(lesson, allFetchedLessons)) return true;
      if (!lesson.cancellation && isAfter(getUnixMilliseconds(), lesson.date_start)) {
        return !lesson.clients.find((lessonClient) => {
          return (
            lessonClient.user_id === currentUserId &&
            lessonClient.status === LessonClientStatus.Accepted
          );
        });
      }
      return true;
    }
    return true;
  };

  const history = useHistory();

  const setCurrentSelectedTab = (value: string | number) => {
    setSelectedTab(parseInt(value.toString(), 10));
    setCurrentIndex(0);
    setIsFilter(false);
    setAllLessons([]);
  };

  const setCurrentSelectedParentTab = (value: number) => {
    setSelectedParentTab(value);
    setCurrentIndex(0);
    setIsFilter(false);
    setAllLessons([]);
  };

  const setCurrentSelectedCategories = (categories: string[]) => {
    setSelectedCategories(categories);
    setCurrentIndex(0);
    setIsFilter(false);
    setAllLessons([]);
  };

  const setCurrentSearchValue = (value: string) => {
    setSearchValue(value);
    setCurrentIndex(0);
    setIsFilter(false);
    setAllLessons([]);
  };

  useEffect(() => {
    getCurrentUserLessonsAsync();
  }, [getCurrentUserLessonsAsync]);

  useEffect(() => {
    if (history.location.state) {
      const currentTap = get(history, 'location.state.tab');
      if (isEmpty(get(history, 'location.state.categories', ''))) setSelectedCategories([]);
      else setSelectedCategories(get(history, 'location.state.categories', '').split(','));

      if ((currentTap ?? 0) >= 0) setCurrentSelectedTab(get(history, 'location.state.tab') ?? 0);
      else setCurrentSelectedTab(LessonsTabs.Upcoming);
    }
  }, [history]);

  const onItemClick = (id: string) => history.push(`lesson/${id}`);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (selectedTab === LessonsTabs.Past) {
      setEmptyListText('You have not booked any lessons in past.');
    } else if (selectedTab === LessonsTabs.Canceled) {
      setEmptyListText('You have no canceled lessons');
    } else {
      setEmptyListText('You have not booked any lessons. Book a lesson to begin.');
    }
  }, [isLoading, selectedTab, setEmptyListText]);

  return (
    <>
      <div className={styles.title}>Lessons</div>
      <TabView
        tabs={LessonsParentTabs}
        selectedTab={selectedParentTab}
        setSelectedTab={setCurrentSelectedParentTab}
        isNotSearch
        isParentTab
      />
      <ListPage
        onCategorySelected={setCurrentSelectedCategories}
        onSearchValue={setCurrentSearchValue}
        tabs={LessonsTabs}
        selectedTab={selectedTab}
        onTabSelected={setCurrentSelectedTab}
        selectedCategories={selectedCategories}
        searchValue={searchValue}
        orgId={orgId}
      >
        <InfiniteScroll
          dataLength={allLessons.length}
          next={() => {
            setIsFilter(true);
            setCurrentIndex((prev) => prev + 1);
          }}
          hasMore={hasMore}
          loader=""
        >
          <ListView
            source={allLessons}
            onItemClick={onItemClick}
            emptyListText={emptyListText}
            showListItem={showListItem}
            selectedTab={selectedTab}
            isLoading={isLoading}
          />
        </InfiniteScroll>
      </ListPage>
    </>
  );
};

export default LessonsPage;
