import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import styles from './styles.module.scss';
import UserAvatar from '../UserAvatar';
import BalanceIcon from '../../../assets/icons/total-coach-dollar.svg';
import CreditsLoader from './credits-loader.gif';
import { Conversions } from '../../../common/utils/conversions.helper';
import { CurrencyData } from '../../../common/utils/currencies.helper';

export interface UserInfoV2Props {
  name: string;
  email: string;
  currency: CurrencyData;
  credits: Credits;
  pictureUrl: string;
  onProfileUpdated: Function;
}

export interface Credits {
  isLoaded: boolean;
  total: number;
}

export const UserInfoV2 = (props: UserInfoV2Props) => {
  const theme = useTheme();
  const isViewedFromMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { name, email, pictureUrl, currency, credits, onProfileUpdated } = props;
  return (
    <div className={styles.avatarWrapper}>
      <UserAvatar
        className={styles.avatarPicture}
        name={name}
        src={pictureUrl}
        profileUpdated={onProfileUpdated}
        showEdit
      />
      <div className={styles.avatarInfo}>
        <p>{name}</p>
        {!isViewedFromMobile && <p>{email}</p>}
        {!credits.isLoaded && (
          <div>
            <img
              className={styles.avatarCreditLoader}
              src={CreditsLoader}
              alt="loading credits ..."
            />
          </div>
        )}
        {credits.isLoaded && (
          <div>
            <span>{!isViewedFromMobile ? 'TotalCoach Credits' : 'Credits'}</span>
            <img className={styles.avatarCreditIcon} alt="balance-icon" src={BalanceIcon} />
            <span>{Conversions.toNumber(currency, credits.total)}</span>
          </div>
        )}
      </div>
      <div className={styles.avatarBottom} />
    </div>
  );
};
