import React from 'react';
import { Stack, Typography } from '@mui/material';

import { useMobileViewDetector } from 'common/hooks';

import { Grid } from 'components/Grid';
import GolfJourneyMap from 'modules/home/components/GolfJourneyMapV2/GolfJourneyMap';

const LessonMap = () => {
  const { isViewedFromMobile, isViewedFromTablet } = useMobileViewDetector();

  const styles = {
    heading: {
      typography: isViewedFromMobile ? 'h6' : 'h5',
      fontWeight: 700,
    },
    padding: {
      padding: isViewedFromMobile ? '1rem' : '1rem 2.5rem',
    },
  };
  return (
    <Grid item md={6.5} xs={12} order={isViewedFromTablet ? 2 : 1}>
      <Typography sx={{ ...styles.heading, ...styles.padding }}>Lesson map</Typography>
      <Stack
        alignItems="center"
        justifyContent="center"
        gap="4rem"
        height={480}
        sx={{
          height: isViewedFromTablet ? 480 : 'auto',
          paddingX: isViewedFromMobile ? '1rem' : '3rem',
        }}
      >
        <Typography>
          Tap on the area of the lesson map to see what lessons have been taught in that area.
        </Typography>
        <GolfJourneyMap />
      </Stack>
    </Grid>
  );
};

export default LessonMap;
