import { ExerciseModel } from '../entities/exercises.entity';
import RealmRepositories from '../base/realm.repo';

export default class ExercisesService {
  static async getAllExercisesByUserAndOrg(userId: string, orgId: string, isGroup?: boolean) {
    const allLessons = await RealmRepositories.Lessons.getLessonsWithExercisesByOrgIdAndUserId(
      orgId,
      userId,
      isGroup,
    );
    if (!allLessons) {
      return [];
    }

    const allExercises: ExerciseModel[] = [];
    for (let i = 0; i < allLessons.length; i++) {
      const lesson = allLessons[i];
      const currentExerciseModelObj: ExerciseModel = {
        title: lesson?.title,
        exerciseObj: lesson?.exerciseObj,
        attachments: [],
      };

      for (let j = 0; j < lesson?.attachments.length; j++) {
        const attachment = lesson?.attachments[j];
        const attachmentType = JSON.parse(attachment.attachment_type as string);
        if (Object.values(attachmentType).includes('file')) {
          const index = Object.keys(attachmentType).indexOf('file_id');
          const fileId = Object.values(attachmentType)[index];
          // eslint-disable-next-line no-await-in-loop
          const fileItem = await RealmRepositories.Files.getFileByIdAsync(fileId as string);
          currentExerciseModelObj.attachments.push(fileItem);
        }
      }

      const index = allExercises.findIndex(
        (exercise: ExerciseModel) =>
          exercise.exerciseObj._id === currentExerciseModelObj.exerciseObj._id,
      );

      if (index < 0) allExercises.push(currentExerciseModelObj);
    }

    return allExercises;
  }
}
