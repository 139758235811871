import { createSlice } from '@reduxjs/toolkit';
import TimezoneHelper from 'common/utils/timezone.help';
import { BookingState } from './types';
import {
  changeCoachId,
  changeLocationId,
  changeStep,
  getLocations,
  getCoaches,
  getServices,
  getBusyTimes,
  getPositionsLeft,
  getCheckoutSummary,
  updateSelectedServices,
  updatePromoCode,
  updateTimezone,
  updatePaymentMethod,
  updatePaymentPreference,
  resetBooking,
  updateIsRefund,
  updateInvoiceId,
  updateIsLessonInvite,
  updateIsServiceInvite,
  updateIsLessonPackTimeSlotSelection,
  loadWholeBookingState,
} from './actions';

const initialState: BookingState = {
  bookingStep: 0,
  data: {
    locations: [],
    coaches: [],
    services: [],
    busyTimes: [],
  },
  selected: {
    coachId: null,
    locationId: null,
    services: [],
    positionsLeft: [],
    checkoutSummary: null,
    promoCode: null,
    paymentMethod: null,
    isRefund: false,
    paymentPreference: 'in_app',
    timezone: TimezoneHelper.GetCurrentUserTimezoneList().split(' ')[1],
    invoiceId: '',
    lessonInviteId: null,
    serviceInviteId: null,
    isLessonPackTimeSlotSelection: false,
  },
};

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    // extract functions that are long
    // into separated file and import it here
    changeStep,
    changeCoachId,
    changeLocationId,
    getLocations,
    getCoaches,
    getServices,
    getBusyTimes,
    getPositionsLeft,
    getCheckoutSummary,
    updateSelectedServices,
    updateTimezone,
    updatePromoCode,
    updatePaymentMethod,
    updatePaymentPreference,
    resetBooking,
    updateIsRefund,
    updateInvoiceId,
    updateIsLessonInvite,
    updateIsServiceInvite,
    updateIsLessonPackTimeSlotSelection,
    loadWholeBookingState,
  },
});

export * from './types';
export * from './actions';
