import React from 'react';
import { some } from 'lodash';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import styles from './CancelLessonView.module.scss';
import { OkButtonType } from '../../../../components/Button/Button';
import { LessonCancellation } from '../../../../common/utils/lessons.helper';
import { Lesson } from '../../../../data/entities/lessons.entity';
import Spinnerv2 from '../../../../components/Spinnerv2/Spinnerv2';
import { useCancelLessonViewHooks } from './CancelLessonView.hooks';
import { CancelLessonBody } from './CancelLessonViewBody/CancelLessonViewBody';

interface CancelLessonViewProps {
  lesson: Lesson;
  visible: boolean;
  onClose: Function;
  onCancelLesson: Function;
  onCancelPackage: Function;
  lessonCancellation: LessonCancellation;
  cancellationPeriodInMinutes: number;
}

const CancelLessonView = ({
  lesson,
  visible,
  onClose,
  onCancelLesson,
  onCancelPackage,
  lessonCancellation,
  cancellationPeriodInMinutes,
}: CancelLessonViewProps) => {
  const {
    loadingState,
    reason,
    setReason,
    isSelfTaught,
    isBeforePolicyDuration,
    isAfterPolicyDuration,
    lessonType,
    lessonCancellationInfo,
    showNoCreditMessage,
    lessonPrice,
  } = useCancelLessonViewHooks({
    lesson,
    lessonCancellation,
    isVisible: visible,
  });

  const isLoading = some(loadingState, (value) => value === true);
  if (isLoading) {
    return <Spinnerv2 message="Loading..." />;
  }

  return (
    <>
      <div className={styles.visibleDesktop}>
        <ModalWindow
          show={visible}
          title="Are you sure you want to cancel your lesson?"
          saveBtnLabel="Cancel lesson"
          okButtonType={OkButtonType.Danger}
          onCloseClick={onClose}
          onSaveClick={() => {
            onCancelLesson(reason);
            onClose();
          }}
          extraButton={lessonType?.isPackage === true}
          extraBtnLabel="Cancel package"
          onExtraClick={() => {
            onCancelPackage(lesson.package_id, reason);
            onClose();
          }}
        >
          <CancelLessonBody
            isSelfTaught={isSelfTaught}
            lesson={lesson}
            lessonPrice={lessonPrice!}
            cancellationPeriodInMinutes={cancellationPeriodInMinutes}
            isBeforePolicyDuration={isBeforePolicyDuration}
            isAfterPolicyDuration={isAfterPolicyDuration}
            lessonCancellationInfo={lessonCancellationInfo}
            showNoCreditMessage={showNoCreditMessage}
            reason={reason}
            setReason={setReason}
          />
        </ModalWindow>
      </div>
      <div className={styles.visibleMobile}>
        <MobileDrawer
          visible={visible}
          title="Cancel Lesson"
          onClose={onClose}
          okButtonText="Cancel lesson"
          cancelButtonText="Cancel"
          onClickOkButton={() => {
            onCancelLesson(reason);
            onClose();
          }}
          okButtonType={OkButtonType.Danger}
          extraButton={lessonType?.isPackage === true}
          extraBtnLabel="Cancel package"
          onExtraClick={() => {
            onCancelPackage(lesson.package_id, reason);
            onClose();
          }}
        >
          <div>
            <div className={styles.title}>Are you sure you want to cancel your lesson?</div>

            <CancelLessonBody
              isSelfTaught={isSelfTaught}
              lesson={lesson}
              lessonPrice={lessonPrice!}
              cancellationPeriodInMinutes={cancellationPeriodInMinutes}
              isBeforePolicyDuration={isBeforePolicyDuration}
              isAfterPolicyDuration={isAfterPolicyDuration}
              lessonCancellationInfo={lessonCancellationInfo}
              showNoCreditMessage={showNoCreditMessage}
              reason={reason}
              setReason={setReason}
            />
          </div>
        </MobileDrawer>
      </div>
    </>
  );
};

export default CancelLessonView;
