import React, { useState } from 'react';
import styles from '../../../modules/attachment/components/AttachmentView/AttachmentView.module.scss';

interface IProps {
  colorFill?: boolean;
}

const Favorite: React.FC<IProps> = ({ colorFill }) => {
  const [animation, setAnimation] = useState(false);

  return (
    <svg
      className={animation ? styles.svgAanimation : styles.svgNone}
      onClick={() => setAnimation(true)}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
    >
      <path
        d="M2.002 2.5C3.502.5 9.5 3 10.5 3c1 0 6.182-3 7.682-1.5S20.5 7 20.5 7.5s-3.5 5-4 5.5l-6 6-4-4L3 11.5l-1.998-2L.5 7s.002-2.5 1.502-4.5z"
        fill={colorFill ? '#FA4748' : 'none'}
      />
      <path
        stroke={colorFill ? '#FA4748' : '#000'}
        strokeWidth="1.5"
        d="M10.001 2.276l.501.45.5-.45a5.498 5.498 0 017.554.209 5.501 5.501 0 01.231 7.535L10.5 18.319l-8.286-8.3a5.502 5.502 0 01.23-7.533s0 0 0 0a5.5 5.5 0 017.557-.21zm8.142.621h0a4.914 4.914 0 00-6.75-.188s0 0 0 0l-.89.8-.891-.799s0 0 0 0a4.916 4.916 0 00-6.754.189 4.916 4.916 0 00-.235 6.7l.016.02.018.018 7.313 7.324.53.532.531-.532 7.313-7.323.018-.018.017-.019a4.917 4.917 0 00-.236-6.704z"
      />
    </svg>
  );
};

export default Favorite;
