import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import styles from './ClientNotesModal.module.scss';
import { ButtonType } from '../../../../data/enums/ButtonType.enum';

interface IProps {
  showModal: boolean;
  onClientNoteSaved: any;
  onModalCloseClick: any;
  saveLabel: string;
  text?: string;
  typeButton: number;
  id?: string;
  title: string;
  placeHolder: string;
}

const ClientNotesModal: React.FC<IProps> = ({
  showModal,
  onClientNoteSaved,
  onModalCloseClick,
  saveLabel,
  text,
  typeButton,
  id,
  title,
  placeHolder,
}) => {
  const [textValue, setTextValue] = useState(get(text, ''));
  const [isCreating, setIsCreating] = useState(false);

  const onTextChanged = (e: any) => setTextValue(e.target.value);

  useEffect(() => {
    setTextValue(text);
  }, [text]);

  const onSaved = async () => {
    if (textValue) {
      setIsCreating(true);
      if (typeButton === ButtonType.Update) {
        await onClientNoteSaved(id, textValue);
      }
      if (typeButton === ButtonType.Save) {
        await onClientNoteSaved(textValue);
      }
      setIsCreating(false);
      onModalCloseClick();
      setTextValue('');
    } else {
      toast.error('Text cannot be empty');
    }
  };

  const onCloseModal = () => {
    onModalCloseClick();
    setTextValue(text);
  };

  return (
    <ModalWindow
      show={showModal}
      title={title}
      saveBtnLabel={saveLabel}
      onSaveClick={onSaved}
      onCloseClick={onCloseModal}
      customStyles={styles.modal}
      loading={isCreating}
    >
      <textarea
        className={styles.text}
        placeholder={placeHolder}
        onChange={onTextChanged}
        defaultValue={text}
        maxLength={500}
      />
    </ModalWindow>
  );
};

export default ClientNotesModal;
