import React, { useState, useEffect } from 'react';

import LessonHelper from 'common/utils/lessons.helper';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import styles from './AddTextNoteView.module.scss';
import { Lesson } from '../../../../data/entities/lessons.entity';
import ModalWarningText from '../../../../components/ModalWindow/ModalWarningText';

interface AddTextNoteViewProps {
  visible: boolean;
  onClose: Function;
  onCreate: Function;
  lesson: Lesson;
}

interface TextAreaElementProps {
  note: string;
  setNote: (note: string) => void;
  warningText: string;
}

const TextAreaElement = ({ note, setNote, warningText }: TextAreaElementProps) => {
  return (
    <>
      <textarea
        className={styles.textarea}
        placeholder="Enter your note here"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      {warningText !== '' && <ModalWarningText message={warningText} />}
    </>
  );
};

const AddTextNoteView = ({ visible, onClose, onCreate, lesson }: AddTextNoteViewProps) => {
  const [note, setNote] = useState('');
  const [warningText, setWarningText] = useState('');

  const updateWarningText = async () => {
    let stringBuilder = '';
    if (!lesson.is_self_taught) {
      stringBuilder += 'By selecting "Create" this note will be shared with your coach(es)';
      if (await LessonHelper.isGroupLesson(lesson)) {
        stringBuilder += ' and other participants';
      }

      stringBuilder += '. ';
    }

    stringBuilder += 'Are you sure you want to continue?';
    if (lesson.is_self_taught && !lesson.share_practice_lesson_with_coach) stringBuilder = '';
    setWarningText(stringBuilder);
  };

  useEffect(() => {
    if (!visible) {
      setNote('');
    } else {
      updateWarningText();
    }
  }, [lesson, visible]);

  return (
    <>
      <div className={styles.visibleDesktop}>
        <ModalWindow
          show={visible}
          title="Text note"
          saveBtnLabel="Create"
          onCloseClick={onClose}
          onSaveClick={() => {
            onCreate(note);
            onClose();
          }}
        >
          <TextAreaElement note={note} setNote={setNote} warningText={warningText} />
        </ModalWindow>
      </div>
      <div className={styles.visibleMobile}>
        <MobileDrawer
          visible={visible}
          title="Text note"
          onClose={onClose}
          okButtonText="Create"
          cancelButtonText="Cancel"
          onClickOkButton={() => {
            onCreate(note);
            onClose();
          }}
        >
          <div className={styles.fullWidth}>
            <div className={styles.title}>Text note</div>
            <TextAreaElement note={note} setNote={setNote} warningText={warningText} />
          </div>
        </MobileDrawer>
      </div>
    </>
  );
};

export default AddTextNoteView;
