import { OrgClientsInvites } from 'data/entities/orgClientsInvites.entity';
import { Config } from '../../config';
import { BaseRepo } from './base.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export class OrgClientsInvitesRepository extends BaseRepo {
  // #region Private Properties
  private orgClientsInvitesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('org_clients_invites');
  // #endregion

  // #region Public Methods
  async getInvitesForOrgAsync(orgId: string): Promise<OrgClientsInvites> {
    if (this.app.currentUser === null) {
      // Edge case, when user is not signed in booking page and need to filter locations/coaches based on selection.
      const mongo = await this.LogUserInAnonymously();
      this.orgClientsInvitesCollection = mongo
        .db(Config().RealmDbName as string)
        .collection('org_clients_invites');
    }
    return this.orgClientsInvitesCollection?.findOne({ org_id: orgId });
  }

  async acceptOrgClientInvite(orgId: string, orgClientInviteId: string, userId: string) {
    return this.orgClientsInvitesCollection?.updateOne(
      {
        org_id: orgId,
        'client_invites._id': orgClientInviteId,
      },
      {
        $set: {
          'client_invites.$.is_accepted': true,
          'client_invites.$.date_accepted': getUnixMilliseconds(),
          'client_invites.$.user_id': userId,
        },
      },
    );
  }
  // #endregion
}
