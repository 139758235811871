import { PaymentMethod } from '@stripe/stripe-js';
import { PayloadAction } from '@reduxjs/toolkit';
import TimezoneHelper from 'common/utils/timezone.help';
import {
  BookingStep,
  BookingState,
  CompactBusyTime,
  ServicePositionsLeft,
  PaymentPreference,
} from './types';
import { CheckoutSummary } from '../../../api/models/payments/checkoutSummary.model';

/**
 * TODO:
 * We need to refactor the placements of interfaces later
 */
import { SelectedService as AvailableService } from '../../../modules/book/models/booking.model';
import {
  LocationImageAndText,
  CoachImageAndText,
} from '../../../modules/service/models/service.model';

export const loadWholeBookingState = (state: BookingState, action: PayloadAction<BookingState>) => {
  console.log('loadWholeBookingState ****', {
    action,
    state,
  });
  state = JSON.parse(JSON.stringify(action.payload));
};

export const changeStep = (state: BookingState, action: PayloadAction<BookingStep>) => {
  state.bookingStep = action.payload;
};

export const changeCoachId = (
  state: BookingState,
  action: PayloadAction<BookingState['selected']['coachId']>,
) => {
  state.selected.coachId = action.payload;
};

export const changeLocationId = (
  state: BookingState,
  action: PayloadAction<BookingState['selected']['locationId']>,
) => {
  state.selected.locationId = action.payload;
};

export const updateSelectedServices = (
  state: BookingState,
  action: PayloadAction<BookingState['selected']['services']>,
) => {
  state.selected.services = action.payload;
};

export const getLocations = (
  state: BookingState,
  action: PayloadAction<LocationImageAndText[]>,
) => {
  state.data.locations = action.payload;
};

export const getCoaches = (state: BookingState, action: PayloadAction<CoachImageAndText[]>) => {
  state.data.coaches = action.payload;
};

export const getServices = (state: BookingState, action: PayloadAction<AvailableService[]>) => {
  state.data.services = action.payload;
};

export const getBusyTimes = (state: BookingState, action: PayloadAction<CompactBusyTime[]>) => {
  state.data.busyTimes = action.payload;
};

export const getPositionsLeft = (
  state: BookingState,
  action: PayloadAction<ServicePositionsLeft[]>,
) => {
  state.selected.positionsLeft = action.payload;
};

export const getCheckoutSummary = (state: BookingState, action: PayloadAction<CheckoutSummary>) => {
  state.selected.checkoutSummary = action.payload;
};

export const updatePromoCode = (state: BookingState, action: PayloadAction<string>) => {
  state.selected.promoCode = action.payload;
};

export const updateTimezone = (state: BookingState, action: PayloadAction<string>) => {
  const timezones = TimezoneHelper.GetTimezoneList();
  const index = timezones.findIndex((timezone: string) => timezone.indexOf(action.payload) >= 0);

  if (index >= 0) state.selected.timezone = action.payload;
  else {
    const defaultTimezone = TimezoneHelper.GetCurrentUserTimezoneList();
    const [, timezoneAbbreviation] = defaultTimezone.split(' ');
    state.selected.timezone = timezoneAbbreviation;
  }
};

export const updatePaymentMethod = (
  state: BookingState,
  action: PayloadAction<PaymentMethod | null>,
) => {
  state.selected.paymentMethod = action.payload;
};

export const updateIsRefund = (state: BookingState, action: PayloadAction<boolean>) => {
  state.selected.isRefund = action.payload;
};

export const updatePaymentPreference = (
  state: BookingState,
  action: PayloadAction<PaymentPreference>,
) => {
  state.selected.paymentPreference = action.payload;
};

export const updateInvoiceId = (state: BookingState, action: PayloadAction<string>) => {
  state.selected.invoiceId = action.payload;
};

/**
 * We should not re-fetch services if this booking is related to lesson invite, service invite
 * or lesson pack time slot selection
 * @param state
 * @param action
 */
export const updateIsLessonInvite = (state: BookingState, action: PayloadAction<string>) => {
  state.selected.lessonInviteId = action.payload;
};

export const updateIsServiceInvite = (state: BookingState, action: PayloadAction<string>) => {
  state.selected.serviceInviteId = action.payload;
};

export const updateIsLessonPackTimeSlotSelection = (
  state: BookingState,
  action: PayloadAction<boolean>,
) => {
  state.selected.isLessonPackTimeSlotSelection = action.payload;
};

export const resetBooking = (state: BookingState) => {
  state.selected = {
    coachId: null,
    locationId: null,
    services: [],
    positionsLeft: [],
    checkoutSummary: null,
    promoCode: null,
    paymentMethod: null,
    paymentPreference: 'in_app',
    timezone: TimezoneHelper.GetCurrentUserTimezoneList().split(' ')[1],
    isRefund: false,
    invoiceId: '',
    lessonInviteId: null,
    serviceInviteId: null,
    isLessonPackTimeSlotSelection: false,
  };
};
