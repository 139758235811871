import React from 'react';

import { Grid, Box } from '@mui/material';

import { BookingContainer } from './components';

interface BookingLoaderProps {
  title: string;
  message: string;
}

export const BookingLoader = ({ title, message }: BookingLoaderProps) => {
  return (
    <BookingContainer isLoading={false} title={title}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box textAlign="center" paddingY={2}>
            {message}
          </Box>
        </Grid>
      </Grid>
    </BookingContainer>
  );
};
