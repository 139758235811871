import React, { useContext, useState, Suspense } from 'react';
import { Box, Stack, Typography } from '@mui/material';

/** Common and Hooks */
import { useMobileViewDetector, useUserId } from 'common/hooks';
import { BrandThemeContext } from 'common/context';

/** Shared Components */
import { Grid } from 'components/Grid';
import Spinnerv2 from 'components/Spinnerv2/Spinnerv2';

/** Local Home components */
import Banners from 'modules/home/pagesV2/components/Banners';
import InvitationFeed from 'modules/home/pagesV2/components/InvitationFeed';
import ActivityFeed from 'modules/home/pagesV2/components/ActivityFeed';
import LessonMap from 'modules/home/pagesV2/components/LessonMap';
import PastLessons from 'modules/home/components/PastLessonsV2';
import Charts from 'modules/home/pagesV2/components/Charts';

const HomePage = () => {
  const theme = useContext(BrandThemeContext);

  const { firstName } = useUserId();
  // Check device view for responsiveness
  const { isViewedFromMobile } = useMobileViewDetector();

  const [isCancellingInvitation, setIsCancellingInvitation] = useState<boolean>(false);

  /**
   * Replaced scss modules with css in js
   * Need to pass this as props to components or put in theme context
   */
  const styles = {
    title: {
      typography: isViewedFromMobile ? 'h5' : 'h4',
      fontWeight: 600,
    },
    subtitle: {
      typography: isViewedFromMobile ? 'subtitle2' : 'subtitle1',
      fontWeight: 400,
      color: theme.colors.black.opacity_50,
    },
    heading: {
      typography: isViewedFromMobile ? 'h6' : 'h5',
      fontWeight: 700,
    },
    padding: {
      padding: isViewedFromMobile ? '1rem' : '1rem 2.5rem',
    },
  };

  if (isCancellingInvitation) {
    return <Spinnerv2 message="Canceling invitation..." />;
  }

  return (
    <Suspense fallback={<Spinnerv2 message="Loading..." />}>
      <Stack bgcolor={theme.colors.black.opacity_10} gap="1rem">
        <Banners />
        <Box sx={styles.padding}>
          <Typography sx={styles.title}>Hello {firstName},</Typography>
          <Typography sx={styles.subtitle}>Here's what we have for you today.</Typography>
        </Box>
        <InvitationFeed setLoading={setIsCancellingInvitation} />
        <Grid container spacing={2}>
          <LessonMap />
          <ActivityFeed />
        </Grid>
        <Stack>
          <PastLessons />
          <Charts />
        </Stack>
      </Stack>
    </Suspense>
  );
};

export default HomePage;
