import React from 'react';

interface InlineSpinnerProps {
  message?: string;
}

const InlineSpinner = (props: InlineSpinnerProps) => {
  const { message } = props;
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      {message && <span className="text-muted float-end ms-4 text-center">{message}</span>}
    </div>
  );
};

export default InlineSpinner;
