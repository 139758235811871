import { BaseRoute } from '../../base/Route.base';

export class Org extends BaseRoute {
  async acceptMemberInvite(orgId: string, invitationId: string, userId: string) {
    const data = {
      orgId,
      invitationId,
      userId,
    };

    const response = await this.api.post('org/member/acceptInvite', data);
    return response;
  }

  async verifyMemberInvite(orgId: string | undefined, invitationId: string | undefined) {
    const data = {
      orgId,
      invitationId,
    };

    const response = await this.api.post('org/member/verifyInvite', data);
    return response;
  }

  async acceptClientInvite(orgId: string, invitationId: string, userId: string) {
    const data = {
      orgId,
      invitationId,
      userId,
    };

    const response = await this.api.post('org/client/acceptInvite', data);
    return response;
  }

  async verifyClientInvite(orgId: string | undefined, invitationId: string | undefined) {
    const data = {
      orgId,
      invitationId,
    };

    const response = await this.api.post('org/client/verifyInvite', data);
    return response;
  }
}
