/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { File } from 'data/entities/file.entity';
import getResizedImageUri from '../../../../common/utils/getResizedImageUri.helpers';
import PDFIcon from '../../../../assets/images/FilePDF.svg';
import styles from './AttachmentsThumbnailsList.module.scss';
import { Config } from '../../../../config';

interface IAttachmentsThumbnailsListProps {
  thumbnails: File[];
}

/* eslint-disable react/no-array-index-key */
const AttachmentsThumbnailsList: React.FC<IAttachmentsThumbnailsListProps> = ({ thumbnails }) => (
  <div className={styles.wrapper}>
    {thumbnails.map((thumbnail) => {
      return (
        <img
          key={thumbnail._id}
          className={styles.thumbnail}
          alt={thumbnail.file_name}
          src={
            thumbnail.extension === 'pdf'
              ? PDFIcon
              : thumbnail.content_type.startsWith('video')
              ? getResizedImageUri(
                  (Config().AWSCloudFrontUrl as string) + thumbnail.thumbnail_url ?? '',
                  '256x256',
                )
              : getResizedImageUri(thumbnail.file_url, '256x256')
          }
        />
      );
    })}
  </div>
);

export default AttachmentsThumbnailsList;
