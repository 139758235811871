import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import * as Realm from 'realm-web';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import SessionProvider from '../../../providers/SessionProvider';
import { redirectToOrgWithUrl } from '../../../common/utils/common.helpers';
import Spinnerv2 from '../../../components/Spinnerv2/Spinnerv2';
import { StaticRoutes } from '../../../common/utils/routes/StaticRoutes.helper';
import { Config } from '../../../config';
import { AuthLocalStorage } from '../../auth/utils/auth.helper';
import OrganizationService from '../../../data/services/organization.service';
import { OrgListModel } from '../../../data/entities/organization.entity';

const SelectOrgPage = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userOrgsList, setUserOrgsList] = useState<OrgListModel[]>();
  const params = new URLSearchParams(window.location.search);
  const userId = SessionProvider.getUserId();
  const returnTo = params.get('returnTo') ?? '';
  const app = Realm.getApp(Config().RealmAppId as string);
  const history = useHistory();
  const removeUser = useCallback(async () => {
    if (app.currentUser) {
      await app.removeUser(app?.currentUser);
    }
  }, [app]);
  const fetchUserOrgsAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await OrganizationService.getUserOrgList(userId);
      setUserOrgsList(response);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (!userOrgsList) {
      fetchUserOrgsAsync();
    }
  }, [userOrgsList, fetchUserOrgsAsync]);

  if (isLoading) {
    return <Spinnerv2 message="Loading organizations..." />;
  }

  if (!isLoading && (!userOrgsList || userOrgsList.length === 0)) {
    return (
      <div
        className="container d-flex flex-column align-items-center justify-content-center w-auto mt-5"
        style={{ maxWidth: '700px' }}
      >
        <h1 className="text-center mb-5">
          You don't belong to any organizations. Lets see what you can book here
        </h1>
        <Link to={StaticRoutes.Book} className="btn btn-primary w-100">
          Book
        </Link>
      </div>
    );
  }

  const handleLogOut = async () => {
    await removeUser();
    await Auth.signOut();
    if (SessionProvider.getAccessToken()) {
      SessionProvider.removeSession();
      AuthLocalStorage.removeAuthLocalStorage();
    }

    history.push('/login');
  };

  return (
    <>
      <div style={{ position: 'absolute', right: 200, top: 50 }}>
        <Button variant="dark" size="lg" onClick={handleLogOut}>
          Sign out
        </Button>
      </div>
      <div
        className="container d-flex flex-column align-items-center justify-content-center w-auto vh-100"
        style={{ maxWidth: '700px' }}
      >
        <h2 className="mb-4">Select an Organization</h2>
        {!isLoading &&
          userOrgsList &&
          userOrgsList.map((org, i) => {
            return (
              <Button
                key={i}
                variant="outline-dark"
                size="lg"
                className="mb-3 w-100"
                onClick={() => redirectToOrgWithUrl(org.orgId, returnTo)}
              >
                {org.orgName}
              </Button>
            );
          })}
      </div>
    </>
  );
};

export default SelectOrgPage;
