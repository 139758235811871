/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import Button, { OkButtonType } from 'components/Button/Button';
import { ButtonSavingType } from 'data/enums/ButtonType.enum';
import SessionProvider from 'providers/SessionProvider';
import { ICategory } from '../../../lesson/models/category.model';
import SelectCategoriesButtons from '../../../lesson/components/SelectCategoriesButtons/SelectCategoriesButtons';
import styles from './AttachmentViewContent.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Config } from '../../../../config';
import Favorite from '../../../../assets/icons/attachment-icons/Favorite';
import AttachmentItem from '../AttachmentItem/AttachmentItem';
import AttachmentCopyBtn from '../AttachmentCopyBtn/AttachmentCopyBtn';
import AttachmentDownloadBtn from '../AttachmentDownloadBtn/AttachmentDownloadBtn';
import CloseButton from '../../../../components/Button/CloseButton/CloseButton';

interface IProps {
  onClose: Function;
  attachment: Record<string, any>;
  categories: ICategory[] | LessonCategory[];
  onFavoriteChanged: Function;
  onNoteChanged: Function;
  onChangeAttachmentTextNote: (note: string) => void;
  isLoading: number;
}

const AttachmentView: React.FC<IProps> = ({
  onClose,
  attachment,
  categories,
  onFavoriteChanged,
  onNoteChanged,
  onChangeAttachmentTextNote,
  isLoading,
}) => {
  const fileUrlBase = Config().AWSCloudFrontUrl as string;
  const userId = SessionProvider.getUserId();
  const [activeFavorite, setActiveFavorite] = useState(attachment?.isFavorite);
  const [openFilter, setOpenFilter] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState(
    attachment.favoriteType?.favorite_note === null
      ? ''
      : attachment.favoriteType?.favorite_note || attachment.favNote,
  );

  const [selectedCategories, setSelectedCategories] = useState(
    attachment.isFavorite
      ? attachment.favoriteType?.lesson_category_ids || attachment.lessonCategoriesInFavorite
      : [],
  );
  let note = attachment.noteContent || '';

  /**
   * Note:
   * Cannot find a proper type for this object attachment?.attachment.
   */
  // eslint-disable-next-line
  if (attachment?.attachment?.attachment_type && !('file_url' in attachment?.attachment)) {
    const noteObj = JSON.parse(attachment?.attachment?.attachment_type);
    const noteIndex = Object.keys(noteObj).indexOf('note_description');
    if (noteIndex !== -1) {
      note = Object.values(noteObj)[noteIndex] as string;
    }
  } else if ('noteContent' in attachment) {
    note = attachment?.noteContent;
  }

  const onSetFavorite = () => {
    const isActive = !activeFavorite;
    setActiveFavorite(() => {
      return isActive;
    });
    if (attachment.favId || attachment.favId === null) {
      onFavoriteChanged(attachment.attachmentId ?? null, attachment.favId, [], isActive);
    } else {
      onFavoriteChanged(
        attachment?.favoriteType?.attachment_id ?? null,
        attachment.favoriteId,
        selectedCategories,
        isActive,
      );
    }

    if (!isActive) {
      setSelectedCategories([]);
      return;
    }

    setOpenFilter(isActive);
  };

  const onChangeTextValue = (value: string) => {
    setIsChanged(true);
    setTextAreaValue(value);
  };

  const onSaveAddedNotes = async () => {
    setIsChanged(false);
    await onNoteChanged(textAreaValue);
  };

  const onDoneFilters = async () => {
    if (selectedCategories.length > 0 && activeFavorite) {
      setOpenFilter(false);

      if (attachment.favId || attachment.favId === null) {
        onFavoriteChanged(
          attachment.attachmentId,
          attachment.favId,
          selectedCategories,
          activeFavorite,
        );
      } else {
        onFavoriteChanged(
          attachment?.favoriteType?.attachment_id ?? null,
          attachment.favoriteId,
          selectedCategories,
          activeFavorite,
        );
      }
    } else {
      toast.error('Categories are required', {
        position: 'bottom-right',
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  let saveBtnLabel = '';
  if (isChanged) {
    saveBtnLabel = 'Save';
  } else {
    if (isLoading === ButtonSavingType.save) saveBtnLabel = 'Save';
    if (isLoading === ButtonSavingType.saving) saveBtnLabel = 'Saving';
    if (isLoading === ButtonSavingType.saved) saveBtnLabel = 'Saved';
  }

  let saveBtnType = OkButtonType.Default;
  saveBtnType = isChanged ? OkButtonType.Success : OkButtonType.Default;
  if (!isChanged && isLoading === ButtonSavingType.save) saveBtnType = OkButtonType.Info;

  return (
    <div className={styles.AttachmentView__wrapper}>
      <div className={styles.closeIcon}>
        <CloseButton onClose={() => onClose()} />
      </div>
      <div className={styles.attachment}>
        <AttachmentItem
          title={attachment.title}
          type={
            // eslint-disable-next-line no-nested-ternary
            attachment.isFileAttachment && attachment?.attachment
              ? attachment?.attachment?.content_type
              : attachment.isFileAttachment && !attachment?.attachment
              ? attachment?.contentType
              : 'note'
          }
          url={
            attachment?.isFileAttachment && attachment?.attachment
              ? `${fileUrlBase}${attachment?.attachment?.file_name}`
              : attachment?.fileUrl
          }
          note={note}
          onChangeAttachmentTextNote={onChangeAttachmentTextNote}
          disabled={userId !== attachment.userId}
        />
      </div>
      <div className={styles.details}>
        <div className={styles.wrapper} onClick={onSetFavorite}>
          <Favorite colorFill={activeFavorite} />
          <span>Favorite</span>
        </div>

        <div className={styles.filters}>
          {activeFavorite && !openFilter
            ? (categories as LessonCategory[])
                .filter((x) => selectedCategories?.some((y: string) => y === x._id))
                ?.map((z: LessonCategory) => <span key={z._id}>{z.name}</span>)
            : null}
        </div>

        <div className={styles.wrapper}>
          <AttachmentCopyBtn
            url={
              // eslint-disable-next-line no-nested-ternary
              attachment.attachment?.file_url
                ? `${fileUrlBase}${attachment.attachment?.file_name}`
                : attachment?.fileUrl
                ? attachment.fileUrl
                : note
            }
          />
        </div>

        <div className={styles.wrapper}>
          <AttachmentDownloadBtn
            url={
              attachment?.attachment
                ? `${fileUrlBase}${attachment?.attachment?.file_name}`
                : attachment?.fileUrl
            }
            type={
              // eslint-disable-next-line no-nested-ternary
              attachment.isFileAttachment && attachment?.attachment
                ? attachment?.attachment?.extension
                : attachment.isFileAttachment && !attachment?.attachment
                ? attachment?.fileExtension
                : 'note'
            }
            text={note}
          />
        </div>
      </div>
      <div className={styles.notes}>
        {activeFavorite && openFilter ? (
          <div className={styles.filterPlace}>
            <span>Choose one or more categories</span>
            <SelectCategoriesButtons
              buttonForAttachments={styles.buttonForAttachments}
              categories={categories}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />

            <button type="button" className={styles.btnDone} onClick={onDoneFilters}>
              Done
            </button>
          </div>
        ) : (
          ''
        )}
        <div className={styles.filterPlace}>
          <textarea
            className={styles.textArea}
            placeholder={attachment.isFavorite ? 'Add a note for this favorite' : 'Add a note'}
            onChange={(e) => onChangeTextValue(e.target.value)}
            onBlur={isChanged ? onSaveAddedNotes : undefined}
            value={textAreaValue}
          />
          <Button
            className={styles.saveNoteBtn}
            label={saveBtnLabel}
            type={saveBtnType}
            onClick={null}
            loading={isLoading === ButtonSavingType.saving}
            disabled={isLoading === ButtonSavingType.save}
          />
        </div>
      </div>
    </div>
  );
};

export default AttachmentView;
