import { Config } from '../../config';
import { BaseRepo } from './base.repo';
import { PromoCodes } from '../entities/promoCodes.entity';

export class PromoCodesRepository extends BaseRepo {
  // #region Private Properties
  private promoCodesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection<PromoCodes>('promo_codes');

  // #endregion

  // #region Public Methods

  async getByOrgIdAndCode(orgId: string, codeParam: string) {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.promoCodesCollection = mongo
        .db(Config().RealmDbName as string)
        .collection('promo_codes');
    }

    return this.promoCodesCollection?.findOne({ org_id: orgId, code: codeParam });
  }

  // #endregion
}
