import React from 'react';

// import { useOutsideClick } from '../../../../common/hooks/useOutsideClick';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import styles from './AttachmentView.module.scss';
import AttachmentViewContent from '../AttachmentViewContent/AttachmentViewContent';
import { FavoriteModel } from '../../../../data/entities/favorites.entity';
import { AttachmentModel } from '../../../../data/entities/attachment.entity';

interface AttachmentViewProps {
  title: string;
  visible: boolean;
  onClose: Function;
  attachment: FavoriteModel | AttachmentModel | null;
  categories: LessonCategory[];
  onFavoriteChanged: Function;
  onNoteChanged: Function;
  onChangeAttachmentTextNote: (note: string) => void;
  isLoading: number;
}

const AttachmentView: React.FC<AttachmentViewProps> = ({
  title,
  visible,
  onClose,
  attachment,
  categories,
  onFavoriteChanged,
  onNoteChanged,
  onChangeAttachmentTextNote,
  isLoading,
}) => {
  const onCloseAttachmentView = () => {
    onClose();
  };

  if (!attachment) {
    document.body.style.overflow = 'auto';
    return null;
  }

  return (
    <>
      <div className={styles.visibleDesktop}>
        <ModalWindow show={visible} onCloseClick={onCloseAttachmentView} hideFooter>
          <AttachmentViewContent
            onClose={onCloseAttachmentView}
            attachment={attachment}
            categories={categories}
            onFavoriteChanged={onFavoriteChanged}
            onNoteChanged={onNoteChanged}
            onChangeAttachmentTextNote={onChangeAttachmentTextNote}
            isLoading={isLoading}
          />
        </ModalWindow>
      </div>
      <div className={styles.visibleMobile}>
        <MobileDrawer visible={visible} title={title} onClose={onCloseAttachmentView}>
          <AttachmentViewContent
            onClose={onCloseAttachmentView}
            attachment={attachment}
            categories={categories}
            onFavoriteChanged={onFavoriteChanged}
            onNoteChanged={onNoteChanged}
            onChangeAttachmentTextNote={onChangeAttachmentTextNote}
            isLoading={isLoading}
          />
        </MobileDrawer>
      </div>
    </>
  );
};

export default AttachmentView;
