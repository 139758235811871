import * as Realm from 'realm-web';
import { Associate, AssociateWithAvatar } from 'data/entities/associate.entity';
import { OrgClient } from 'data/entities/orgClients.entity';
import { Config } from '../../config';
import { WorkingHours } from '../entities/profile.entity';
import { User } from '../entities/users.entity';
import Logger from '../../middleware/logger.middleware';
import { UpdateResult } from '../types/realm.types';
import { WorkingHoursAggregation } from '../types/realm.aggregations';
import { BaseRepo } from './base.repo';

export class UsersRepository extends BaseRepo {
  // #region Private Properties
  private userCollection = this.mongo?.db(Config().RealmDbName as string).collection<User>('user');

  private associateCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection<Associate>('associations');

  private workingHoursCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection<WorkingHours>('working_hours');

  private orgClientsCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection<OrgClient>('org_clients');
  // #endregion

  // #region Public Methods

  async login(realmCredentials: Realm.Credentials) {
    const user = await this.app.logIn(realmCredentials);
    const mongo = user.mongoClient(Config().RealmClusterName as string);
    this.mongo = mongo;
    this.userCollection = mongo.db(Config().RealmDbName as string).collection<User>('user');
    return user;
  }

  async getUser(userId: string): Promise<User | undefined | null> {
    return this.userCollection?.findOne({
      _id: userId,
    });
  }

  async updateUser(updates: User) {
    try {
      await this.userCollection?.updateOne({ _id: updates._id }, { ...updates });
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  async getNamesByIds(userIds: string[]): Promise<User[] | []> {
    if (userIds && userIds.length > 0) {
      const result = this.userCollection?.aggregate([
        {
          $match: {
            _id: { $in: userIds },
          },
        },
        {
          $lookup: {
            from: 'user_details',
            localField: '_id',
            foreignField: 'user_id',
            as: 'detail',
          },
        },
        {
          $unwind: '$detail',
        },
        {
          $lookup: {
            from: 'file',
            let: { avatar_file: '$detail.avatar' },
            pipeline: [
              {
                $match: {
                  $expr: {
                    $and: [{ $eq: ['$_id', '$$avatar_file'] }],
                  },
                },
              },
            ],
            as: 'detail.avatar_file',
          },
        },
        {
          $addFields: {
            has_avatar: {
              $cond: {
                if: {
                  $or: [
                    { $eq: ['$detail.avatar_file', null] },
                    { $eq: ['$detail.avatar_file', ''] },
                  ],
                },
                then: 0,
                else: 1,
              },
            },
          },
        },
        {
          $group: {
            _id: '$_id',
            root: { $mergeObjects: '$$ROOT' },
            detail: { $last: '$detail' },
            avatar_file: { $last: '$detail.avatar_file' },
            has_avatar: { $last: '$has_avatar' },
          },
        },
        {
          $replaceRoot: { newRoot: '$root' },
        },
        {
          $project: {
            has_avatar: 0,
          },
        },
      ]);

      return result ?? [];
    }
    return [];
  }

  async getUserAnonymously(userId: string): Promise<User | undefined | null> {
    this.userCollection = await this.AllowAnonymousQuery('user', this.userCollection!);
    return this.getUser(userId);
  }

  async getCurrentUserCustomData(): Promise<User | undefined> {
    await this.app.currentUser?.refreshCustomData();
    const customData = this.app.currentUser?.customData;
    if (!customData) {
      return undefined;
    }

    const user: User | undefined = JSON.parse(JSON.stringify(customData));

    if (!user) {
      return undefined;
    }

    // Convert BSON Extended types to typescript type
    user.portal_subscription_status_enum = customData.portal_subscription_status_enum
      ? parseInt(
          (customData.portal_subscription_status_enum as Realm.BSON.Int32Extended).$numberInt,
          10,
        )
      : undefined;
    user.date_created = parseInt(
      (customData.date_created as Realm.BSON.LongExtended).$numberLong,
      10,
    );
    user.date_updated = parseInt(
      (customData.date_updated as Realm.BSON.LongExtended).$numberLong,
      10,
    );

    return user;
  }

  async getUserByEmail(email: string): Promise<User | undefined | null> {
    return this.userCollection?.findOne({ email });
  }

  async setAuthIdByEmail(email: string, authId: string) {
    return this.userCollection?.findOneAndUpdate({ email }, { $set: { auth_id: authId } });
  }

  async insertUserCognitoId(userId: string, cognitoId: string) {
    const result = await this.userCollection?.updateOne(
      { _id: userId },
      { $set: { cognito_id: cognitoId } },
    );

    return result;
  }

  async getUsersWorkingHoursByLocationIdAsync(
    userId: string,
    orgId: string,
    locationId: string,
  ): Promise<WorkingHoursAggregation | undefined> {
    if (this.app.currentUser === null) {
      const credentials = Realm.Credentials.anonymous();
      const user = await this.app.logIn(credentials);
      const mongo = user.mongoClient(Config().RealmClusterName as string);
      this.mongo = mongo;
      this.workingHoursCollection = mongo
        ?.db(Config().RealmDbName as string)
        .collection<WorkingHours>('working_hours');
    }

    const aggregation = [
      {
        $match: {
          user_id: userId,
          org_id: orgId,
          deleted: false,
        },
      },
      {
        $unwind: {
          path: '$working_hours_locations',
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $match: {
          'working_hours_locations.org_location_id': locationId,
        },
      },
      {
        $project: {
          _id: 1,
          org_id: 1,
          working_hours: {
            $filter: {
              input: '$working_hours_locations.working_hours',
              as: 'working_hour',
              cond: {
                $and: [
                  {
                    $eq: ['$$working_hour.is_closed_day', false],
                  },
                ],
              },
            },
          },
        },
      },
    ];

    const workingHoursResult: WorkingHoursAggregation[] =
      await this.workingHoursCollection?.aggregate(aggregation);

    if (Logger.isDevEnvironment) {
      console.log('workingHoursResult', workingHoursResult[0]);
    }

    if (!workingHoursResult || workingHoursResult.length === 0) {
      return undefined;
    }

    return workingHoursResult[0];
  }

  async addOrgIdToUserOrgsAsync(userId: string, orgId: string): Promise<UpdateResult> {
    const result = await this.userCollection?.updateOne(
      { _id: userId },
      { $addToSet: { orgs: orgId } },
    );

    return result;
  }

  async getUserByIdAndOrg(userId: string, orgId: string): Promise<User | undefined> {
    const result = await this.userCollection?.aggregate([
      {
        $match: {
          _id: userId,
          orgs: orgId,
        },
      },
    ]);

    if (!result || result.length === 0) {
      return undefined;
    }

    return result[0];
  }

  async getAssociatesByUserIdOrgId(userId: string, orgId: string): Promise<AssociateWithAvatar[]> {
    const result = await this.associateCollection?.aggregate([
      {
        $unwind: '$linked_users',
      },
      {
        $lookup: {
          from: 'user',
          localField: 'linked_users.user_id',
          foreignField: '_id',
          as: 'user',
        },
      },
      {
        $unwind: '$user',
      },
      {
        $lookup: {
          from: 'user_details',
          localField: 'linked_users.user_id',
          foreignField: 'user_id',
          as: 'user_detail',
        },
      },
      {
        $unwind: '$user_detail',
      },
      {
        $lookup: {
          from: 'file',
          localField: 'user_detail.avatar',
          foreignField: '_id',
          as: 'avatar',
        },
      },
      {
        $match: {
          user_id: userId,
          org_id: orgId,
        },
      },
    ]);

    if (!result || result.length === 0) {
      return [];
    }
    return result;
  }

  async getWorkingHoursByUserIdAndOrg(
    userId: string,
    orgId: string,
  ): Promise<WorkingHours | undefined> {
    const result = await this.workingHoursCollection?.aggregate([
      {
        $match: {
          user_id: userId,
          org_id: orgId,
        },
      },
    ]);

    if (!result || result.length === 0) {
      return undefined;
    }
    return result[0];
  }

  // #endregion
}
