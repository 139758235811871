/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { ClientConfidentialNote } from 'data/entities/lessons.entity';
import ClientNotesItem from '../../components/ClientNotesItem/ClientNotesItem';
import LessonSection from '../../components/LessonSection/LessonSection';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import styles from './MobileClientConfidentialNotesContainer.module.scss';

interface IProps {
  clientNotes: ClientConfidentialNote[];
  onClientNoteRemoved: any;
  onShowUpdateModal: any;
  showAddModal: boolean;
  onAddClientNotes: (a: boolean) => void;
}

const MobileClientNotesContainer: React.FC<IProps> = ({
  clientNotes,
  onClientNoteRemoved,
  onShowUpdateModal,
  showAddModal,
  onAddClientNotes,
}) => {
  const [menuOpened, setMenuOpened] = useState(false);

  useEffect(() => {
    if (showAddModal) {
      setMenuOpened(true);
    }
  }, [showAddModal]);

  const onMenuToggled = () => setMenuOpened(!menuOpened);
  const onClientNoteAddClick = () => onAddClientNotes(true);

  return (
    <>
      <MobileDrawer
        visible={menuOpened}
        title="Your notes"
        subTitle="Not shared to coach nor confirmed participants"
        onClose={onMenuToggled}
        onClickAddButton={onClientNoteAddClick}
      >
        {clientNotes.length > 0 ? (
          clientNotes.map((clientNote) => (
            <ClientNotesItem
              key={clientNote._id}
              id={clientNote._id}
              userId={clientNote.user_id}
              clients={[]}
              text={clientNote.message}
              onClientNoteRemoved={onClientNoteRemoved}
              onShowUpdateModal={onShowUpdateModal}
            />
          ))
        ) : (
          <div className={styles.emptyNotes}>
            {' '}
            No Client Notes have been added. Press the plus button to add a new note.
          </div>
        )}
      </MobileDrawer>
      <div className={styles.container}>
        <LessonSection
          title="Your notes"
          hintMessage="Does not go to coach"
          itemsCount={clientNotes.length}
          onClick={onMenuToggled}
        />
      </div>
    </>
  );
};

export default MobileClientNotesContainer;
