import { PaymentMethod } from '@stripe/stripe-js';
import { GuestAuthorizeModel } from 'api/models/auth.model';
import { CreatePaymentIntentRequest } from 'api/models/payments/intent/createPaymentIntentRequest';
import { CreatePaymentModel } from 'api/models/payments/createPaymentModel';
import { PaymentIntentRefundModel } from 'api/models/payments/paymentIntentRefund.model';
import { CalculateCheckoutSummaryModel } from 'api/models/payments/calculateCheckoutSummary.model';
import { PaymentCreatedModel } from 'api/models/payments/paymentCreated.model';
import { UpdatePaymentLessonsModel } from 'api/models/payments/lessons/updatePaymentLessons.model';
import { UpdatePaymentLessonsResult } from 'api/models/payments/lessons/updatePaymentLessonsResult.model';
import { CreatePaymentIntentResponse } from 'api/models/payments/intent/createPaymentIntentResponse';
import { ConfirmPaymentIntentRequest } from 'api/models/payments/intent/confirmPaymentIntentRequest';
import { ConfirmPaymentIntentResponse } from 'api/models/payments/intent/confirmPaymentIntentResponse';
import { BaseRoute } from '../../base/Route.base';

export class Payments extends BaseRoute {
  /**
   * Fetches stripe PaymentMethod type.
   * @param paymentMethodId ID of the payment method used in booking.
   * @returns stripe PaymentMethod object
   *
   */
  async getPaymentMethodByIdAsync(paymentMethodId: string): Promise<PaymentMethod> {
    return (await this.api.get(`/payment/methods/${paymentMethodId}`)).data;
  }

  /**
   * Fetches stripe PaymentMethod type.
   * @param paymentMethodId ID of the payment method used in booking.
   * @returns stripe PaymentMethod object
   *
   */
  async getGuestPaymentMethodByIdAsync(
    paymentMethodId: string,
    guestAuthorizeModel: GuestAuthorizeModel,
  ): Promise<PaymentMethod> {
    return (
      await this.api.getWithGuestAuthorize(
        `/payment/methods/${paymentMethodId}`,
        guestAuthorizeModel,
      )
    ).data;
  }

  /**
   * Calculates the checkout details.
   *
   * @returns checkoutSummary: CheckoutSummary
   */
  async calculateCheckout(paymentSelectedServicesModel: CalculateCheckoutSummaryModel) {
    return await this.api.post('/payment/checkout/calculate', paymentSelectedServicesModel);
  }

  /**
   * Creates a payment intent with the payment provider (Does not confirm).
   * @param createPaymentIntentRequest Payload to be sent
   * @returns CreatePaymentIntentResponse
   */
  async createPaymentIntent(
    createPaymentIntentRequest: CreatePaymentIntentRequest,
  ): Promise<CreatePaymentIntentResponse> {
    return (await this.api.post(`/payment/intent`, createPaymentIntentRequest)).data;
  }

  /**
   *
   * @param paymentIntentId Id of the created payment intent
   * @param confirmPaymentIntentRequest The payload to be sent
   * @returns ConfirmPaymentIntentResponse
   */
  async confirmPaymentIntent(
    paymentIntentId: string,
    confirmPaymentIntentRequest: ConfirmPaymentIntentRequest,
  ): Promise<ConfirmPaymentIntentResponse> {
    return (
      await this.api.post(`payment/intent/${paymentIntentId}/confirm`, confirmPaymentIntentRequest)
    ).data;
  }

  /**
   * Refunds the user for payment intent.
   * @param paymentIntentRefundModel
   * @returns 200 Ok
   */
  async refundPaymentIntent(paymentIntentRefundModel: PaymentIntentRefundModel) {
    const response = await this.api.post(`/payment/intent/refund`, paymentIntentRefundModel);
    return response;
  }

  /**
   * Refunds the user for payment intent.
   * @param paymentIntentRefundModel
   * @param guestAuthorizeModel
   * @returns 200 Ok
   */
  async refundGuestPaymentIntent(
    paymentIntentRefundModel: PaymentIntentRefundModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    const response = await this.api.postWithGuestAuthorize(
      `/payment/intent/refund`,
      paymentIntentRefundModel,
      guestAuthorizeModel,
    );
    return response;
  }

  /**
   *
   * @param stripeCustomerId
   * @returns number
   */
  async getStripeClientCredits(stripeCustomerId: string) {
    return await this.api.get(`/payment/${stripeCustomerId}/credits`);
  }

  async createPaymentAsync(createPaymentModel: CreatePaymentModel): Promise<PaymentCreatedModel> {
    return (await this.api.post('/payment', createPaymentModel)).data;
  }

  async createGuestPaymentAsync(
    createPaymentModel: CreatePaymentModel,
    guestAuthorizeModel: GuestAuthorizeModel,
  ): Promise<PaymentCreatedModel> {
    return (
      await this.api.postWithGuestAuthorize('/payment', createPaymentModel, guestAuthorizeModel)
    ).data;
  }

  async updatePromoCodeForUserAsync(promoCode: string, orgId: string) {
    return await this.api.put(`/payment/promo-code/${promoCode}?orgId=${orgId}`, {});
  }

  async updatePromoCodeForGuestUserAsync(
    promoCode: string,
    orgId: string,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    return await this.api.putWithGuestAuthorize(
      `/payment/promo-code/${promoCode}?orgId=${orgId}`,
      {},
      guestAuthorizeModel,
    );
  }

  async updatePaymentLessonIds(
    updatePaymentLessonsModel: UpdatePaymentLessonsModel,
  ): Promise<UpdatePaymentLessonsResult> {
    return (await this.api.put('/payment/lessons', updatePaymentLessonsModel)).data;
  }
}
