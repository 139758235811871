import { useStripe } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import StaticDictionary from 'common/utils/staticDictionary.helper';

export interface SecurePaymentResult {
  isSuccessfull: boolean;
  isLoading: boolean;
  message: string;
}

export const useStripe3DSecure = () => {
  const stripe = useStripe();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const is3DSecureReturnPage = (): boolean => {
    const paymentAuthKey = StaticDictionary.UrlParameters.Payments.PaymentAuthentication;
    const paymentAuthValue = values[paymentAuthKey];
    if (paymentAuthValue) {
      if (paymentAuthValue.toString().trim().toLowerCase() === 'completed') return true;
    }
    return false;
  };

  const is3DSecurePaymentSuccessfull = async () => {
    let status = false;
    const paymentIntentClientSecretKey =
      StaticDictionary.UrlParameters.Payments.ClientSecretUrlParam;

    const paymentIntentSecret = values[paymentIntentClientSecretKey];
    if (!paymentIntentSecret) return status;

    const paymentIntentResult = await stripe?.retrievePaymentIntent(paymentIntentSecret.toString());
    if (!paymentIntentResult) return status;

    /** Decide the status of the payment authenticaation */
    if (paymentIntentResult.error) {
      status = false;
    }

    if (paymentIntentResult.paymentIntent?.status === 'succeeded') {
      status = true;
    }
    if (paymentIntentResult.paymentIntent?.status === 'requires_payment_method') {
      status = false;
    }

    return status;
  };

  const getSecurePaymentResult = async (): Promise<SecurePaymentResult> => {
    const result: SecurePaymentResult = {
      isSuccessfull: false,
      isLoading: true,
      message: '',
    };

    const paymentIntentClientSecretKey =
      StaticDictionary.UrlParameters.Payments.ClientSecretUrlParam;

    const paymentIntentSecret = values[paymentIntentClientSecretKey];
    if (!paymentIntentSecret) return { ...result, isLoading: false };

    const paymentIntentResult = await stripe?.retrievePaymentIntent(paymentIntentSecret.toString());
    if (!paymentIntentResult) return { ...result, isLoading: false };

    /** Decide the status of the payment authenticaation */
    if (paymentIntentResult.error) {
      result.isSuccessfull = false;
      result.isLoading = false;
      return result;
    }

    if (paymentIntentResult.paymentIntent?.status === 'succeeded') {
      result.isSuccessfull = true;
      result.isLoading = false;
      return result;
    }
    if (paymentIntentResult.paymentIntent?.status === 'requires_payment_method') {
      result.isSuccessfull = false;
      result.isLoading = false;
      result.message = paymentIntentResult.paymentIntent.last_payment_error?.message ?? '';
      return result;
    }

    return result;
  };

  return {
    is3DSecureReturnPage,
    is3DSecurePaymentSuccessfull,
    getSecurePaymentResult,
  };
};
