/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { isEqual } from 'lodash';
import { isPossiblePhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import ModalWindow from '../../../components/ModalWindow/ModalWindow';
import styles from './ContactsModal.module.scss';
import InputField from '../../../components/InputField/InputField';
import AddressInput from '../../../components/Inputs/AddressInput/AddressInput';
import { Address, UserContact } from '../../../data/entities/userContacts.entity';
import SessionProvider from '../../../providers/SessionProvider';
import CountryCodePhoneInput from '../../../components/Inputs/CountryCodePhoneInput/CountryCodePhoneInput';
import Logger from '../../../middleware/logger.middleware';
import { addressStringBuilder } from '../../../common/utils/response.helper';
import { FormErrorMessage } from '../../../common/models/base.model';
import { Validator } from '../../../common/utils/validator.helper';
import { getUnixMilliseconds } from '../../../common/utils/date.helpers';

interface ContactsModalProps {
  showModal: boolean;
  onContactsSaved?: any;
  onModalCloseClick: any;
  saveLabel: string;
  titleModal: string;
  oldValue?: UserContact;
}

interface FormErrors {
  name?: FormErrorMessage;
  email?: FormErrorMessage;
  phoneNumber?: FormErrorMessage;
}

const contactObj: UserContact = {
  _id: uuidv4(),
  user_id: '',
  name: '',
  profession: '',
  phone_number: '',
  email: '',
  country_code: '',
  address: undefined,
  note: '',
  date_created: getUnixMilliseconds(),
  date_updated: getUnixMilliseconds(),
  deleted: false,
};

const ContactsModal = ({
  showModal,
  onContactsSaved,
  onModalCloseClick,
  saveLabel,
  titleModal,
  oldValue,
}: ContactsModalProps) => {
  const [contact, setContact] = useState<UserContact>(oldValue ?? contactObj);
  const [address, setAddress] = useState(contact.address);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [hasUpdated, setHasUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (!hasUpdated) {
      const userId = SessionProvider.getUserId();
      setContact({ ...contact, user_id: userId });
      setHasUpdated(true);
    }
    if (!showModal) {
      setContact(oldValue ?? { ...contactObj, user_id: SessionProvider.getUserId() });
      setFormErrors({});
    }
  }, [contact, hasUpdated, oldValue, showModal]);

  const validateNameField = (value: string) => {
    if (!value.match(Validator.RegexDictionary.Name)) {
      setFormErrors({
        ...formErrors,
        name: { message: 'Please enter a valid Name.' },
      } as FormErrors);
      return;
    }
    setFormErrors({ ...formErrors, name: undefined } as FormErrors);
  };

  const onAddName = (e: ChangeEvent<HTMLInputElement>) => {
    validateNameField(e.target.value.trim());
    setContact({ ...contact, name: e.target.value });
  };

  const onAddProfession = (e: any) => {
    const profession = e.target.value.trim();
    setContact({ ...contact, profession });
  };

  const validateEmailField = (value: string) => {
    if (!value.match(Validator.RegexDictionary.Email)) {
      setFormErrors({
        ...formErrors,
        email: { message: 'Please enter a valid Email Address.' },
      } as FormErrors);
      return;
    }
    setFormErrors({ ...formErrors, email: undefined } as FormErrors);
  };

  const onAddEmail = (e: ChangeEvent<HTMLInputElement>) => {
    validateEmailField(e.target.value.trim());
    setContact({ ...contact, email: e.target.value });
  };

  const onAddNote = (e: any) => {
    const note = e.target.value.trim();
    setContact({ ...contact, note });
  };

  const onAddPhoneNumber = (e: any) => {
    const phoneNumber = e;
    if (!phoneNumber || phoneNumber === undefined) {
      setFormErrors({
        ...formErrors,
        phoneNumber: { message: 'Phone number is required' },
      } as FormErrors);
      return;
    }

    if (!isPossiblePhoneNumber(phoneNumber)) {
      setFormErrors({
        ...formErrors,
        phoneNumber: { message: 'Please enter a valid phone number.' },
      } as FormErrors);
      return;
    }

    setFormErrors({ ...formErrors, phoneNumber: undefined } as FormErrors);

    let countryCallingCode = '';
    const formattedNumber = parsePhoneNumber(phoneNumber);
    if (formattedNumber && formattedNumber.country) {
      countryCallingCode = `+${formattedNumber.countryCallingCode}`;
    }
    const phoneNumberWithoutCallCode = phoneNumber.slice(countryCallingCode.length);

    setContact({
      ...contact,
      phone_number: phoneNumberWithoutCallCode,
      country_code: countryCallingCode,
    });
  };

  const googleAddressToFormattedAddress = (addressParam: any) => {
    if (!addressParam) {
      return undefined;
    }

    if (addressParam?.address_components) {
      const addressObject: Address = {};

      addressObject.latitude = addressParam?.lat ?? Infinity;
      addressObject.longitude = addressParam?.lng ?? Infinity;
      addressObject.suburb = '';

      const addressComponents = addressParam?.address_components || {};

      interface AddressKeys {
        [key: string]: string;
      }

      const keysObject: AddressKeys = {
        street_number: 'street_number',
        route: 'street_name',
        sublocality: 'suburb',
        locality: 'city',
        country: 'country',
        postal_code: 'zip_code',
      };

      for (const component of addressComponents) {
        component?.types?.forEach((type: string) => {
          const key = keysObject[type as keyof AddressKeys];

          if (key) {
            (addressObject as any)[key] = component.long_name;

            if (type === 'country') {
              addressObject.country_short_name = component.short_name;
            }
          }
        });
      }

      return addressObject;
    }

    return undefined;
  };

  const onAddAddress = (addressParam: any) => {
    const addressObject = googleAddressToFormattedAddress(addressParam);
    setAddress(addressObject);
  };

  const onSaveContactItem = () => {
    contact.address = address;

    if (!contact) {
      if (Logger.isDevEnvironment) {
        console.log('contact is undefined.');
      }
      onModalCloseClick();
      return;
    }
    const { name, email, phone_number } = contact;

    if (formErrors.email || formErrors.name || formErrors.phoneNumber) {
      return;
    }

    const nameError = !name ? { message: 'Name is required.' } : undefined;
    const emailError = !email ? { message: 'Email is required.' } : undefined;
    const phoneNumberError = !phone_number ? { message: 'Phone number is required.' } : undefined;

    setFormErrors({
      ...formErrors,
      name: nameError,
      email: emailError,
      phoneNumber: phoneNumberError,
    });

    if (nameError || emailError || phoneNumberError) {
      return;
    }

    if (oldValue && isEqual(oldValue, contact)) {
      if (Logger.isDevEnvironment) {
        console.log('no fields were updated');
      }
      onModalCloseClick();
      return;
    }

    if (!isEqual(contact.address, address)) {
      contact.address = address;
    }

    if (Logger.isDevEnvironment) {
      console.log('contact saved.');
    }

    const contactToSave: UserContact = {
      ...contact,
      name: contact.name.trim(),
      email: contact.email.trim(),
    };
    onContactsSaved(contactToSave);
  };

  return (
    <div>
      <ModalWindow
        show={showModal}
        title={titleModal}
        saveBtnLabel={saveLabel}
        onSaveClick={onSaveContactItem}
        onCloseClick={onModalCloseClick}
        okButtonDisabled={
          formErrors.name !== undefined ||
          formErrors.email !== undefined ||
          formErrors.phoneNumber !== undefined ||
          !contact.phone_number ||
          !contact.email ||
          !contact.name
        }
      >
        <div className={styles.inputsWrapper}>
          <InputField
            type="text"
            placeholder="e.g. Jerod Breitenberg"
            label="Name *"
            required
            showValidationMessage={false}
            customStyles={styles.contactsForm}
            onChange={onAddName}
            onBlur={onAddName}
            defaultValue={contact.name}
            stretchInput
          />
          <p className="text-danger"> {formErrors.name?.message}</p>
          <InputField
            type="text"
            placeholder="Coach"
            label="Profession"
            customStyles={styles.contactsForm}
            onChange={onAddProfession}
            defaultValue={contact.profession}
            stretchInput
          />
          <InputField
            type="email"
            placeholder="example@gmail.com"
            label="Email *"
            required
            showValidationMessage={false}
            customStyles={styles.contactsForm}
            onChange={onAddEmail}
            onBlur={onAddEmail}
            defaultValue={contact.email}
            stretchInput
          />
          <p className="text-danger"> {formErrors.email?.message}</p>
          <CountryCodePhoneInput
            label="Phone Number *"
            onChange={onAddPhoneNumber}
            defaultValue={
              contact && contact.country_code ? contact.country_code + contact.phone_number : ''
            }
          />
          <p className="text-danger"> {formErrors.phoneNumber?.message}</p>
          <AddressInput
            placeholder="Street, City, Zip code "
            label="Address"
            value={addressStringBuilder(contact.address)}
            setValue={onAddAddress}
          />
          <div className={styles.textarea}>
            <label htmlFor="textareaNote">Note</label>
            <textarea
              placeholder="Leave some additional info here"
              name="textareaNote"
              className={styles.textareaField}
              onInput={onAddNote}
              defaultValue={contact.note}
            />
          </div>
        </div>
      </ModalWindow>
    </div>
  );
};

export default ContactsModal;
