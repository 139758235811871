import React from 'react';
import styles from './QuestionnaireDone.module.scss';
import Button from '../../../../components/Button/Button';

interface IQuestionnaireDone {
  onClose: () => void;
}

const QuestionnaireDone: React.FC<IQuestionnaireDone> = ({ onClose }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>That’s all the info we need from you for now.</div>
      <div className={styles.subtitle}>Thanks for your answers.</div>
      <div className={styles.buttons}>
        <Button label="Continue" className={styles.button} onClick={onClose} />
      </div>
    </div>
  );
};

export default QuestionnaireDone;
