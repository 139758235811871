/* eslint-disable no-nested-ternary */
import { v4 as uuidv4 } from 'uuid';
import * as Realm from 'realm-web';
import { DateTime } from 'luxon';
import { AssociateWithAvatar } from 'data/entities/associate.entity';
import { User } from '../entities/users.entity';
import { Client } from '../entities/orgClients.entity';
import RealmRepositories from '../base/realm.repo';
import Logger from '../../middleware/logger.middleware';
import { Dominations, SportInformationModel } from '../entities/sportInformation.entity';
import { Address, UserContact } from '../entities/userContacts.entity';
import { UserDetail, UserPracticeLocation } from '../entities/profile.entity';
import { File as FileModel } from '../entities/file.entity';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';
import { IsSuccessfulModel } from '../common/models/isSuccessful.model';

interface UpdateUserDetailsV2Input {
  userId: string;
  orgId: string;
  updates: Partial<UserDetail>;
}

interface UpdateUserInput {
  userId: string;
  updates: Partial<User>;
}

export default class UserService {
  static async getUserAsync(userId: string) {
    try {
      return await RealmRepositories.Users.getUser(userId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return undefined;
    }
  }

  static async getNamesByIds(userIds: string[]) {
    try {
      return await RealmRepositories.Users.getNamesByIds(userIds);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return undefined;
    }
  }

  static async getCurrentUserCustomData() {
    return RealmRepositories.Users.getCurrentUserCustomData();
  }

  static async userLogin(email: string, password: string) {
    try {
      const credentials = Realm.Credentials.emailPassword(email, password);
      const user = await RealmRepositories.Users.login(credentials);
      const userDataResult = await RealmRepositories.Users.getUserByEmail(email);
      if (!userDataResult) {
        throw Error('Could not find user by email.');
      }

      if (userDataResult?.auth_id === null) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await RealmRepositories.Users.setAuthIdByEmail(email, user.id);
      }

      const returnValue = {
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
        // eslint-disable-next-line no-underscore-dangle
        userId: userDataResult?._id,
        firstName: userDataResult.first_name,
        lastName: userDataResult.last_name,
      };

      return returnValue;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }

      throw error;
    }
  }

  static async insertUserCognitoId(userId: string, cognitoId: string) {
    try {
      await RealmRepositories.Users.insertUserCognitoId(userId, cognitoId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  /**
   * [DEPRECATED] - This logic is now done in SignUp Endpoint
   * - Tries to associate the client with the organization.
   * - Adds the orgId to user.orgs
   * - Adds the user to org_clients.
   * - Update org_clients_invites document.
   * - Creates a new user_details document.
   * @param userId
   * @param orgId
   * @returns isSuccessfulModel
   */
  static async associateClientWithOrg(userId: string, orgId: string): Promise<IsSuccessfulModel> {
    try {
      const isSuccessful: IsSuccessfulModel = { isSuccess: false };
      const userDocument = await RealmRepositories.Users.getUser(userId);
      if (!userDocument) {
        return {
          ...isSuccessful,
          errors: [{ key: 'getUser', value: 'Could not find user document' }],
        };
      }

      const { orgs } = userDocument;
      if (orgs.length > 0 && orgs.find((x) => x === orgId)) {
        return {
          ...isSuccessful,
          errors: [
            {
              key: 'orgAlreadyExists',
              value: 'User has already been associated with organization',
            },
          ],
        };
      }

      const promises = [];
      const client: Client = {
        user_id: userId,
        client_category_ids: [],
        date_joined: getUnixMilliseconds(),
        deleted: false,
      };
      promises.push(RealmRepositories.Users.addOrgIdToUserOrgsAsync(userId, orgId));
      promises.push(RealmRepositories.Organization.addUserToOrgClientsAsync(orgId, client));

      await Promise.all(promises);

      return { isSuccess: true };
    } catch (error: any) {
      console.error(error);
      return { isSuccess: false, errors: [{ key: 'associateClientWithOrg', value: error }] };
    }
  }

  static async getUserByOrgIdAsync(userId: string, orgId: string): Promise<User | undefined> {
    try {
      return await RealmRepositories.Users.getUserByIdAndOrg(userId, orgId);
    } catch (error) {
      console.error('There was an error processing processing realm endpoint request.');
      return undefined;
    }
  }

  static async getAssociatesByUserIdOrgIdAsync(
    userId: string,
    orgId: string,
  ): Promise<AssociateWithAvatar[] | undefined> {
    try {
      return await RealmRepositories.Users.getAssociatesByUserIdOrgId(userId, orgId);
    } catch (error) {
      console.error('There was an error processing processing realm endpoint request.');
      return undefined;
    }
  }

  static async getUserSkillRatingByOrgAsync(orgId: string, userId: string) {
    try {
      return await RealmRepositories.SkillRating.getUserSkillRatingByOrgId(orgId, userId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }

      return undefined;
    }
  }

  static async getUserSportInformationAsync(
    userId: string,
    orgId: string,
  ): Promise<SportInformationModel | undefined> {
    try {
      return await RealmRepositories.Sport.getUserSportInformationAsync(userId, orgId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }

      return undefined;
    }
  }

  static async createSportInformation(userId: string, orgId: string, data: any) {
    try {
      const domination: Dominations = {
        eye: data.eyeDomination ? data.eyeDomination : 0,
        hand: data.handDomination ? data.handDomination : 0,
        leg: data.legDomination ? data.legDomination : 0,
      };

      const sportInformation: SportInformationModel = {
        _id: uuidv4(),
        user_id: userId,
        org_id: orgId,
        position: data.position ? data.position : '',
        sport_club: data.golfClub ? data.golfClub : '',
        previous_coach: data.previousCoach ? data.previousCoach : '',
        previous_sports: data.previousSport ? data.previousSport : '',
        visible_skills: false,
        goals: data.goals ? data.goals : '',
        issues: data.issues ? data.issues : '',
        dominations: domination,
        date_created: getUnixMilliseconds(),
        date_updated: getUnixMilliseconds(),
        deleted: false,
      };

      await RealmRepositories.Sport.createSportInformation(sportInformation);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async updateSportInformation(userId: string, orgId: string, realmData: any, newData: any) {
    try {
      const oldData: SportInformationModel = realmData[0];

      const sportInfo: SportInformationModel = {
        _id: oldData._id,
        user_id: oldData.user_id,
        org_id: oldData.org_id,
        visible_skills: oldData.visible_skills,
        dominations: {
          eye: newData.eyeDomination
            ? newData.eyeDomination
            : newData.eyeDomination === null
            ? 0
            : oldData.dominations.eye,
          hand: newData.handDomination
            ? newData.handDomination
            : newData.handDomination === null
            ? 0
            : oldData.dominations.hand,
          leg: newData.legDomination
            ? newData.legDomination
            : newData.legDomination === null
            ? 0
            : oldData.dominations.leg,
        },
        date_updated: getUnixMilliseconds(),
        date_created: oldData.date_created,
        deleted: oldData.deleted,
        position: newData.position
          ? newData.position
          : newData.position === ''
          ? ''
          : oldData.position,
        sport_club: newData.golfClub
          ? newData.golfClub
          : newData.golfClub === ''
          ? ''
          : oldData.sport_club,
        previous_coach: newData.previousCoach
          ? newData.previousCoach
          : newData.previousCoach === ''
          ? ''
          : oldData.previous_coach,
        previous_sports: newData.previousSport
          ? newData.previousSport
          : newData.previousSport === ''
          ? ''
          : oldData.previous_sports,
        goals: newData.goals ? newData.goals : newData.goals === '' ? '' : oldData.goals,
        issues: newData.issues ? newData.issues : newData.issues === '' ? '' : oldData.issues,
      };

      await RealmRepositories.Sport.updateSportInformation(userId, orgId, sportInfo);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async createUserContact(contact: UserContact): Promise<UserContact | null> {
    try {
      contact._id = uuidv4();
      contact.date_created = getUnixMilliseconds();
      contact.date_updated = getUnixMilliseconds();
      contact.deleted = false;

      await RealmRepositories.UserContacts.createUserContact(contact);

      return contact;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return null;
    }
  }

  static async updateUserContact(contactId: string, contact: UserContact) {
    try {
      await RealmRepositories.UserContacts.updateUserContact(contactId, contact);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async getAllContactsByUserId(userId: string) {
    try {
      return await RealmRepositories.UserContacts.getAllContactsByUserId(userId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return [];
    }
  }

  static async getAllPracticeLocationsByUserId(userId: string, orgId: string) {
    try {
      const userDetail: UserDetail[] | undefined =
        await RealmRepositories.UserDetails.getProfileDetails(userId, orgId);

      return userDetail ? userDetail[0].practice_locations : [];
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return [];
    }
  }

  static async deleteContactById(contactId: string) {
    try {
      await RealmRepositories.UserContacts.deleteContactById(contactId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async getProfileDetails(userId: string, orgId: string): Promise<UserDetail[] | undefined> {
    try {
      return await RealmRepositories.UserDetails.getProfileDetails(userId, orgId);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return undefined;
    }
  }

  static async getPracticeLocations(props: {
    userId: string;
    orgId: string;
  }): Promise<UserPracticeLocation[] | []> {
    const { userId, orgId } = props;
    const userDetailArray = await UserService.getProfileDetails(userId, orgId);
    if (userDetailArray && userDetailArray.length > 0) {
      if (userDetailArray[0].practice_locations) {
        return userDetailArray[0].practice_locations;
      }
      return [];
    }
    return [];
  }

  static async createUserDetails(userId: string, orgId: string, userData: any, addressObj: any) {
    try {
      let addressData: Address | null = null;
      if (addressObj && addressObj.addressObject) {
        const {
          street_number,
          street_name,
          suburb,
          city,
          zip_code,
          country,
          country_short_name,
          longitude,
          latitude,
        } = addressObj.addressObject;

        addressData = {
          street_number,
          street_name,
          suburb,
          city,
          zip_code,
          country,
          country_short_name,
          longitude,
          latitude,
        };
      }

      let genderEnum: number;
      if (userData?.gender === 'female') {
        genderEnum = 2;
      } else if (userData?.gender === 'male') {
        genderEnum = 1;
      } else {
        genderEnum = 0;
      }

      let contacts: any;
      if (userData && userData.fieldCountry) {
        contacts = {
          name: userData.fieldCountry.name ?? '',
          country_code: userData.fieldCountry.dialCode ?? '',
          country_iso: userData.fieldCountry.countryCode ?? '',
          phone_number: userData.fieldNumber ?? '',
        };
      }

      const dataUserDetails: UserDetail = {
        _id: uuidv4(),
        user_id: userId,
        org_id: orgId,
        avatar: '',
        occupation: '',
        ...(userData && userData?.gender && { gender_enum: genderEnum }),
        ...(addressObj && addressObj?.formattedAddress !== '' && { address: addressData }),
        ...(userData && userData.fieldCountry && { contact: contacts }),
        contact_preference: '',
        birth_place: '',
        ...(userData &&
          userData.dob && { date_of_birth: DateTime.fromISO(userData.dob).toMillis() }),
        date_updated: getUnixMilliseconds(),
        date_created: getUnixMilliseconds(),
        deleted: false,
      };

      await RealmRepositories.UserDetails.createUserDetails(dataUserDetails);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static getUserGender(gender: string): number {
    switch (gender) {
      case 'female':
        return 2;
      case 'male':
        return 1;
      default:
        return 0;
    }
  }

  static async updateUserDetailsV2({ userId, orgId, updates }: UpdateUserDetailsV2Input) {
    try {
      const fetchCurrentProfileResponse = await this.getProfileDetails(userId, orgId);
      if (!fetchCurrentProfileResponse) return;
      const oldData = fetchCurrentProfileResponse[0];
      const newUpdates: UserDetail = {
        ...oldData,
        ...updates,
      };
      await RealmRepositories.UserDetails.updateUserDetails(newUpdates);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async updateUser({ userId, updates }: UpdateUserInput) {
    const user = await RealmRepositories.Users.getUser(userId);
    if (!user) return;
    try {
      const oldData = user;
      const newUpdates: User = {
        ...oldData,
        ...updates,
      };
      await RealmRepositories.Users.updateUser(newUpdates);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async updateUserDetails(userData: any, addressObj: any, realmData: any) {
    try {
      const oldData: any = realmData[0];
      let addressData: Address;

      if (addressObj?.formattedAddress !== '') {
        addressData = {
          street_number: addressObj.addressObject.street_number,
          street_name: addressObj.addressObject.street_name,
          suburb: addressObj.addressObject.suburb,
          city: addressObj.addressObject.city,
          zip_code: addressObj.addressObject.zip_code,
          country: addressObj.addressObject.country,
          country_short_name: addressObj.addressObject.country_short_name,
          longitude: addressObj.addressObject.longitude,
          latitude: addressObj.addressObject.latitude,
        };
      } else if (oldData && oldData.address) {
        addressData = {
          street_number: oldData.address.street_number,
          street_name: oldData.address.street_name,
          suburb: oldData.address.suburb,
          city: oldData.address.city,
          zip_code: oldData.address.zip_code,
          country: oldData.address.country,
          country_short_name: oldData.address.country_short_name,
          longitude: oldData.address.longitude,
          latitude: oldData.address.latitude,
        };
      } else {
        addressData = {
          street_number: '',
          street_name: '',
          suburb: '',
          city: '',
          zip_code: '',
          country: '',
          country_short_name: '',
          longitude: Infinity,
          latitude: Infinity,
        };
      }

      let genderEnum: number;
      if (userData?.gender && userData.gender !== null) {
        if (userData?.gender === 'female') {
          genderEnum = 2;
        } else if (userData?.gender === 'male') {
          genderEnum = 1;
        } else {
          genderEnum = 0;
        }
      } else {
        genderEnum =
          oldData && oldData.gender_enum && userData.gender !== null ? oldData.gender_enum : null;
      }

      let contacts: any;
      if (userData && userData.fieldCountry) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        contacts = {
          name: userData.fieldCountry.name ?? '',
          country_code: userData.fieldCountry.dialCode ?? '',
          country_iso: userData.fieldCountry.countryCode ?? '',
          phone_number: userData.fieldNumber ?? '',
        };
      } else {
        contacts = {
          name: (oldData && oldData.contact && oldData.contact.name) ?? '',
          country_code: (oldData && oldData.contact && oldData.contact.country_code) ?? '',
          country_iso: (oldData && oldData.contact && oldData.contact.country_iso) ?? '',
          phone_number: (oldData && oldData.contact && oldData.contact.phone_number) ?? '',
        };
      }

      const userDetails: UserDetail = {
        _id: oldData._id,
        user_id: oldData.user_id,
        org_id: oldData.org_id,
        date_updated: getUnixMilliseconds(),
        date_created: oldData.date_created,
        deleted: oldData.deleted,
        gender_enum: genderEnum,
        address: addressData,
        contact: contacts,
        practice_locations: oldData.practice_locations,
        date_of_birth: userData.dob
          ? DateTime.fromISO(userData.dob).toMillis()
          : oldData.date_of_birth,
      };

      await RealmRepositories.UserDetails.updateUserDetails(userDetails);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async updateProfilePic(fileObj: FileModel, orgId: string, userId: string, fileId: string) {
    try {
      await RealmRepositories.Files.insertFileAsync(fileObj);
      const currentUserDetail = await RealmRepositories.UserDetails.getProfileDetails(
        userId,
        orgId,
      );

      if (currentUserDetail) {
        const userDetails = currentUserDetail[0];
        await RealmRepositories.UserDetails.updateProfilePic(userDetails._id, fileId);
      } else {
        const userDetails: UserDetail = {
          _id: uuidv4(),
          user_id: userId,
          org_id: orgId,
          avatar: fileId,
          practice_locations: [],
          date_updated: getUnixMilliseconds(),
          date_created: getUnixMilliseconds(),
          deleted: false,
        };
        await RealmRepositories.UserDetails.createUserDetails(userDetails);
      }
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }
}
