import React from 'react';
import * as Realm from 'realm-web';
import { Auth } from 'aws-amplify';
import { NavLink, useHistory } from 'react-router-dom';
import Button from 'components/Button/Button';
import { StaticRoutes } from 'common/utils/routes/StaticRoutes.helper';
import { getBaseOrgRoute } from 'common/utils/common.helpers';
import NavBar from '../Header/NavBar/NavBar';
import UserInfo from '../UserInfo/UserInfo';
import styles from './MobileMenu.module.scss';
import logoutIcon from '../../assets/icons/FW_ Icons/Sign out.svg';
import SessionProvider from '../../providers/SessionProvider';
import Logo from '../Header/Logo/Logo';
import Hamburger from '../Header/Hamburger/Hamburger';
import { Config } from '../../config';
import { AuthLocalStorage } from '../../modules/auth/utils/auth.helper';

interface MobileMenuProps {
  onClose: Function;
  openNavBar: boolean;
  onCloseNavBar: any;
  billingText: string;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  onClose,
  openNavBar,
  onCloseNavBar,
  billingText,
}) => {
  const app = Realm.App.getApp(Config().RealmAppId as string);
  const history = useHistory();
  return (
    <div className={styles.MobileMenu}>
      <div className={styles.MobileMenu__header}>
        <Logo onClose={onClose} />
        <div className={styles.MobileBook}>
          <Button
            label="Book"
            className={styles.mobileBookingBtn}
            onClick={() => {
              const baseOrgRoute = getBaseOrgRoute(window.location.pathname);
              let route = '';
              if (baseOrgRoute) {
                route = baseOrgRoute + StaticRoutes.Book;
              } else {
                route = `${StaticRoutes.SelectOrg}?returnTo=${StaticRoutes.Book}`;
              }

              history.push(route);
            }}
          />
          <Hamburger openNav={openNavBar} onClose={onCloseNavBar} />
        </div>
      </div>
      <div className={styles.MobileMenu__mainMenu}>
        <UserInfo onClose={onClose} />
        <NavBar onClose={onClose} billingText={billingText} isMobile />
      </div>
      <div className={styles.MobileMenu__footer}>
        <div className={styles.logOut}>
          <NavLink
            exact
            to="/"
            onClick={async () => {
              SessionProvider.removeSession();
              AuthLocalStorage.removeAuthLocalStorage();
              await Auth.signOut();
              if (app.currentUser) {
                await app.removeUser(app.currentUser);
              }

              window.location.href = '/login';
            }}
          >
            <img src={logoutIcon} alt="logout-icon" />
            Sign out
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
