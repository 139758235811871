import React, { useContext, useMemo } from 'react';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import { DateTime } from 'luxon';

/**
 * Note we need to use this handy tool to translate the dates easily without spending too much time in it.
 * Since the package does not have type definition, we need to ignore type check for that package
 */
// eslint-disable-next-line
// @ts-ignore
import calendar from 'calendar-month-array';
import { BrandThemeContext } from 'common/context';
import { CalendarContext } from './CalendarContext';

import { CalendarHeader } from './CalendarHeader';
import { Grid } from '../../../../../../components/Grid';
import { CalendarDate, SelectedCalendarDate } from './CalendarDate';
import { useTimeSlots } from '../../hooks';

export type { SelectedCalendarDate };

interface MonthlyCalendarProps {
  timezone: string;
  isLoading: boolean;
  month: number;
  year: number;
  selectedDate: SelectedCalendarDate | null;
  onNext: () => void;
  onPrev: () => void;
  onSelectDate: (params: SelectedCalendarDate) => void;
}

type CalendarDate = number | null;
type CalendarDates = CalendarDate[][];

export const MonthlyCalendar = ({
  timezone,
  isLoading,
  month,
  year,
  selectedDate,
  onNext,
  onPrev,
  onSelectDate,
}: MonthlyCalendarProps) => {
  const theme = useContext(BrandThemeContext);
  const { getReduxAvalableTimeSlots } = useTimeSlots();

  const monthYearLabel = DateTime.fromObject({ year, month, day: 1 }).toFormat('LLLL yyyy');
  const timeDiffInMonths = DateTime.fromObject({ year, month, day: 1 }).diffNow('months').months;
  const isPast = timeDiffInMonths < 0;

  const calendarDates: CalendarDates = calendar(new Date(year, month - 1), {
    weekStartDay: 0,
    formatHeader: (date: Date) => date.toString().slice(0, 1),
    formatDate: (date: Date) => date.getDate(),
    formatSiblingMonthDate: () => null,
  });

  const calendarContextValue = useMemo(
    () => ({
      isLoading,
    }),
    [isLoading],
  );

  return (
    <CalendarContext.Provider value={calendarContextValue}>
      <Grid container>
        <Grid item md={12}>
          <Box minHeight={50}>
            <Stack direction="row" justifyContent="space-between" marginBottom={1}>
              <Box fontSize="1.5rem" fontWeight="bold">
                {monthYearLabel}
              </Box>
              <Box>
                {!isPast && (
                  <IconButton onClick={onPrev} sx={{ cursor: 'pointer ' }}>
                    <ArrowBackIosNewIcon />
                  </IconButton>
                )}

                <IconButton onClick={onNext} sx={{ cursor: 'pointer ' }}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box borderRadius={2} bgcolor={theme.colors.black.opacity_5} padding={1} height={430}>
            <table width="100%">
              <tbody>
                {calendarDates.map((week, weekIndex) => {
                  return (
                    <tr key={weekIndex}>
                      {week.map((day, dateIndex) => {
                        return (
                          <td key={dateIndex} style={{ padding: 5 }}>
                            {day && (
                              <>
                                {!weekIndex && <CalendarHeader dayName={day.toString()} />}
                                {weekIndex > 0 && (
                                  <CalendarDate
                                    day={day}
                                    month={month}
                                    year={year}
                                    availableTimeSlot={{
                                      morning:
                                        getReduxAvalableTimeSlots({
                                          timezone,
                                          selectedDate: { day, month, year },
                                          category: 'morning',
                                        }).length > 0,
                                      afternoon:
                                        getReduxAvalableTimeSlots({
                                          timezone,
                                          selectedDate: { day, month, year },
                                          category: 'afternoon',
                                        }).length > 0,
                                    }}
                                    isActive={
                                      selectedDate !== null &&
                                      selectedDate.day === day &&
                                      selectedDate.month === month &&
                                      selectedDate.year === year
                                    }
                                    onClick={() => {
                                      onSelectDate({ day, month, year });
                                    }}
                                  />
                                )}
                              </>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </Grid>
      </Grid>
    </CalendarContext.Provider>
  );
};
