import React, { useEffect, useRef, useState } from 'react';
import { set, pullAt, get } from 'lodash';
import styles from './MultiAnswerInputs.module.scss';

interface IMultiAnswerInput {
  value: any;
  onChanged: Function;
  dataValue: any;
}
const MultiAnswerInput: React.FC<IMultiAnswerInput> = ({ value, onChanged, dataValue }) => {
  const [answers, setAnswers] = useState(['']);
  const inputWrapperRef = useRef(null);

  useEffect(() => {
    if (!value) {
      setAnswers(['']);
    } else {
      setAnswers(value.split('\n'));
    }
  }, [dataValue, value]);

  useEffect(() => {
    if (inputWrapperRef && answers.length) {
      get(inputWrapperRef, `current.children[${answers.length - 1}]`).focus();
    }
  }, [answers.length]);

  const onChange = (index: any, valueAnswer: any) => {
    setAnswers(set(answers, index, valueAnswer));
    if (onChanged) {
      const answersString = answers.join('\n');
      onChanged(answersString);
    }
  };

  const onKeyDown = (index: number, key: string) => {
    const answer = answers[index];
    switch (key) {
      case 'Enter':
        setAnswers([...answers, ''] as never[]);
        break;
      case 'Backspace':
        if (index && !answer) {
          pullAt(answers, index);
        }
        break;
      default:
        return;
    }

    if (onChanged) {
      const answersString = answers.join('\n');
      onChanged(answersString);
    }
  };

  return (
    <div className={styles.container} ref={inputWrapperRef}>
      {answers.map((answer, index) => {
        return (
          <input
            key={index}
            onChange={(e) => onChange(index, e.target.value)}
            onKeyDown={(e) => onKeyDown(index, e.key)}
            placeholder="Type your answer here"
            value={answer}
            className={styles.input}
          />
        );
      })}
      <span className={styles.suggestion}>
        press
        <span className={styles.btnTitle}>Enter &#8629;</span>
        to add answer on a new line
      </span>
    </div>
  );
};

export default MultiAnswerInput;
