import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import React from 'react';
import { TabPanel, TabPanelProps } from './TabPanel';
import { styles } from './styles';

interface TabContainerProps {
  id: string;
  items: TabItem[];
}

interface TabItem extends Pick<TabPanelProps, 'children'>, Pick<TabProps, 'icon'> {
  label: string;
}

export const TabContainer = ({ id, items }: TabContainerProps) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box id={id} borderBottom={1} borderColor="divider">
        <Tabs value={value} onChange={handleChange} aria-label="tabbed content" sx={styles.tabs}>
          {items.map((item, index) => {
            return <Tab key={index} id={`tab-${index}`} label={item.label} sx={styles.tab} />;
          })}
        </Tabs>
      </Box>
      {items.map((item, index) => {
        return (
          <TabPanel key={index} value={value} index={index}>
            {item.children}
          </TabPanel>
        );
      })}
    </>
  );
};
