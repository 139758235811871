import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const fontSize = '0.9rem';

interface LessonInfoWithIconProps {
  type: 'coach' | 'additionalCoach' | 'time' | 'place';
  texts: string[];
}

const InfoIcon = ({ type }: Pick<LessonInfoWithIconProps, 'type'>) => {
  const defaultIconStyle = {
    fontSize: '1rem',
  };

  switch (type) {
    case 'coach':
      return <PersonOutlineOutlinedIcon sx={defaultIconStyle} />;
    case 'additionalCoach':
      return <PeopleAltOutlinedIcon sx={defaultIconStyle} />;
    case 'time':
      return <AccessTimeIcon sx={defaultIconStyle} />;
    case 'place':
      return <PlaceOutlinedIcon sx={defaultIconStyle} />;
    default:
      return null;
  }
};

export const LessonInfoWithIcon = ({ type, texts }: LessonInfoWithIconProps) => {
  const defaultTextStyle = {
    paddingTop: '4px',
    fontSize,
  };

  return (
    <Stack direction="row" spacing={0.25} marginBottom={0}>
      <Box width={25}>
        <InfoIcon type={type} />
      </Box>
      {texts.map((text, index) => {
        return (
          <Typography key={index} {...defaultTextStyle}>
            {text}
          </Typography>
        );
      })}
    </Stack>
  );
};
