import React from 'react';
import { Button } from 'components/Button/MuiButtons/Button';

interface BookingButtonProps {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
}

const BookingButton = (props: BookingButtonProps) => {
  const { text, onClick, isDisabled } = props;
  return (
    <Button
      sx={{ marginLeft: 2 }}
      disabled={isDisabled ?? false}
      variant="contained"
      label={text}
      onClick={onClick}
    />
  );
};

export default BookingButton;
