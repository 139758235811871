/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-classes-per-file
class BookingRules {
  public readonly timeSlotPeriodInMinutes = 30;

  public readonly cancellationPeriodInMinutes = 30;

  public readonly weeksInAdvance = 12;
}

export class DefaultValues {
  public static BookingRules = new BookingRules();
}
