import React, { RefObject, useRef } from 'react';
import { Link } from 'react-router-dom';

import UserAvatar from 'components/UserInfo/UserAvatar';
import { SportType } from 'data/enums/SportType.enum';
import SessionProvider from 'providers/SessionProvider';
import styles from './DropdownMenu.module.scss';
import { useOutsideClick } from '../../common/hooks/useOutsideClick';
import greenCheck from '../../assets/icons/green-check-icon.svg';

export interface DataItem {
  label: string;
  path?: string;
  creditsCount?: string;
  onClick?: any;
  isHasAvatar?: boolean;
  sportType?: SportType;
  icon?: string;
}

interface IProps {
  visible: boolean;
  customStyles?: string;
  onCloseDropdown: Function;
  outsideRefsIgnore: RefObject<HTMLDivElement> | RefObject<HTMLDivElement>[];
  data?: DataItem[];
  title?: string;
}

/* eslint-disable react/no-array-index-key */
const DropdownMenu: React.FC<IProps> = ({
  visible,
  onCloseDropdown,
  outsideRefsIgnore,
  data,
  customStyles,
  title,
}) => {
  const orgId = SessionProvider.getOrgId();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(
    [dropdownRef, ...(Array.isArray(outsideRefsIgnore) ? outsideRefsIgnore : [outsideRefsIgnore])],
    onCloseDropdown,
  );

  const itemOnClick = (item: DataItem) => {
    if (item.onClick) item.onClick();
  };

  return visible ? (
    <div className={customStyles || styles.DropdownMenu} ref={dropdownRef}>
      {title && <div className={styles.nameDropDown}>{title}</div>}
      <ul>
        {data?.map((item, index) => {
          const buttonStyle =
            item.label === 'Remove' || item.label === 'Cancel' || item.label === 'Unfavorite'
              ? styles.removeButton
              : styles.button;

          return (
            <li key={index} className={styles.item} onClick={() => itemOnClick(item)}>
              {item.onClick ? (
                <div style={{ width: '100%' }}>
                  {item.isHasAvatar ? (
                    <div className={styles.userMenuContainer}>
                      <div style={{ display: 'flex' }}>
                        {item.icon ? (
                          <UserAvatar src={item.icon} className={styles.avatar} name={item.label} />
                        ) : (
                          <UserAvatar className={styles.avatar} name={item.label} />
                        )}
                        <div className={styles.subUserMenu}>
                          <span className={styles.userFirstName}>{item.label}</span>
                          <span className={styles.userOrgName}>
                            {SportType[item.sportType ?? 0]}
                          </span>
                        </div>
                      </div>
                      {item.path === orgId && (
                        <div className={styles.checkMark} style={{ display: 'flex' }}>
                          <img src={greenCheck} alt="Check" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className={styles.userMenu}>
                      <div className={buttonStyle}>{item.label}</div>
                    </div>
                  )}
                </div>
              ) : (
                <Link to={`${item.path}`}>
                  {item.label}
                  {item.creditsCount ? <span>{item.creditsCount} lesson credits </span> : null}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default DropdownMenu;
