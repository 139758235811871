import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { get } from 'lodash';
import styles from './AuthMenu.module.scss';
import Header from '../Header/Header';
import Button from '../../../../components/Button/Button';
import Link from '../../../../components/Link/Link';
import logoMini from '../../../../assets/images/auth/logo-mini.svg';
import { useWindowDimensions } from '../../../../common/hooks/useWindowDimensions';

interface IProps extends RouteComponentProps {
  logo: string;
  children: any;
  withWelcome: boolean;
  headerTitle: string;
  headerSubtitle?: string;
  isBtn: boolean;
  btnDisabled?: boolean;
  btnLabel: string;
  onBtnClicked: any;
  authLinkHeader?: string;
  authLink?: string;
  authLinkText?: string;
  customStyles?: any;
  loading?: boolean;
  sentEmail?: boolean;
}

const AuthMenu: React.FC<IProps> = ({
  logo,
  children,
  withWelcome,
  headerTitle,
  headerSubtitle,
  isBtn,
  btnDisabled,
  btnLabel,
  onBtnClicked,
  authLinkHeader,
  authLink,
  authLinkText,
  customStyles,
  loading,
  sentEmail,
}) => {
  const { width } = useWindowDimensions();

  const onKeyPressed = (e: any) => {
    const enterCode = 13;
    if (e.charCode === enterCode && onBtnClicked) {
      onBtnClicked();
    }
  };

  return (
    <div className={styles.container} onKeyPress={onKeyPressed}>
      <img alt="logo" src={width > 1024 ? logo : logoMini} className={styles.logo} />
      <div
        className={get(customStyles, 'wrapper', '') ? customStyles.wrapper : styles.formContainer}
      >
        <div
          className={get(customStyles, 'formWrapper', '') ? customStyles.formWrapper : styles.form}
        >
          {withWelcome && <div className={styles.welcomeHeader}>Welcome</div>}
          <Header
            title={headerTitle}
            subtitle={headerSubtitle}
            customStyles={customStyles}
            sentEmail={sentEmail}
          />
          {children}
          {isBtn && (
            <Button
              label={btnLabel}
              onClick={onBtnClicked}
              className={customStyles ? customStyles.btn : null}
              loading={loading}
              disabled={btnDisabled === true}
            />
          )}
          {authLink && authLinkText && (
            <div className={styles.authLink}>
              <div className={styles.authLinkHeader}>{authLinkHeader}</div>
              <Link url={authLink} text={authLinkText} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(AuthMenu);
