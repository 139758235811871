import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorageState from 'use-local-storage-state';
import { toast } from 'react-toastify';
import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useAuth, useOrgId, useUserId } from 'common/hooks';
import { SelectedService, Session, SessionStatusEnum } from 'modules/book/models/booking.model';
import { RootState } from 'redux/store';
import StaticDictionary from 'common/utils/staticDictionary.helper';
import { Api } from 'api/Api';
import { BookingHelper } from 'modules/book/utils/booking.helpers';
import { BookingStep, bookingSlice } from 'redux/slices';
import {
  BookingProcessModel,
  BookingProcessRes,
  SelectedServiceBooking,
  TimeSlotBooking,
} from 'api/models/booking/BookingProcess.model';
import { AxiosErrorHandler } from 'common/utils/errorHandler.helpers';
import { ServiceTypeEnum } from 'data/enums/ServiceType.enum';
import { BrandThemeContext } from 'common/context';
import { BookingContainer, BookingSummaryInfo } from './components';
import {
  useService,
  useSummary,
  useStripe3DSecure,
  useLocation,
  SecurePaymentResult,
  useBooking,
} from './hooks';
import { ScheduledDate as ScheduledDateBooking } from '../../../service/models/service.model';

export const BookingConfirmation = () => {
  const { handleResetBooking } = useBooking();
  const theme = useContext(BrandThemeContext);

  const { orgId } = useOrgId();
  const { userId } = useUserId();

  const authHook = useAuth();
  const dispatch = useDispatch();
  const [bookingSession, , bookingSessionOptions] = useLocalStorageState<Session>('bookingSession');

  const { locationName } = useLocation();

  /** 3D Secure */
  const { is3DSecureReturnPage, getSecurePaymentResult } = useStripe3DSecure();
  const [paymentAuthStatus, setPaymentAuthStatus] = React.useState<null | SecurePaymentResult>(
    null,
  );

  /** Service related */
  const { getDetailedSelectedServices, getLocationIds, getCoachIds, isLessonPack } = useService();
  const { isPrepaidAndUpfront, isPrepaidAndPaid, validateChosenDatesForUser } = useSummary();
  /** Check if the service is a paid prepaid and lesson pack */
  const isPrepaidLessonPack =
    getDetailedSelectedServices().every((service) => isLessonPack(service)) && isPrepaidAndPaid;

  /** Redux slice, actions and dispatch */
  const booking = useSelector((state: RootState) => state.booking);
  const application = useSelector((state: RootState) => state.application);

  const { checkoutSummary } = booking.selected;

  const isPaymentInApp = booking.selected.paymentPreference === 'in_app';
  const coachIds = getCoachIds();
  const locationIds = getLocationIds();

  const isFreeLesson = booking.selected.checkoutSummary
    ? BookingHelper.isFreeLesson(booking.selected.checkoutSummary)
    : false;

  const currencyIsoCode = application.active.orgDetails?.currency_type;
  const lessonInviteId = booking.selected.lessonInviteId ?? '';
  const serviceInviteId = booking.selected.serviceInviteId ?? '';
  const selectedServices = booking.selected.services;

  const isLessonInvite = !!lessonInviteId;
  const isServiceInvite = !!serviceInviteId;

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  /** Payment Intent ID from URL */
  const paymentIntentId = url.searchParams.get(
    StaticDictionary.UrlParameters.Payments.PaymentIntentUrlParam,
  );

  /** Payment Client Secret from URL */
  const paymentClientSecret = url.searchParams.get(
    StaticDictionary.UrlParameters.Payments.ClientSecretUrlParam,
  );

  /** Process booking function as main */
  const processBooking = async () => {
    const isAuthorized = await authHook.doAuthLogic();
    // Check the dates validation
    try {
      if (!isPrepaidAndUpfront) {
        await validateChosenDatesForUser(isAuthorized);
      }
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.error(compiledErrorMessage);
      dispatch(bookingSlice.actions.changeStep(BookingStep.SelectTime));
      return;
    }
    /** Check if the user was already logged in or not */
    let refundPayment = false;

    try {
      const selectedServicesArray: SelectedServiceBooking[] = [];
      const detailedSelectedServices = getDetailedSelectedServices();
      detailedSelectedServices.forEach((service: SelectedService, index: number) => {
        let { count } = selectedServices[index];
        if (service.serviceTypeEnum === ServiceTypeEnum.Package && service.isUpfrontPayment)
          count *= service.unscheduledPackageOccurrences ?? 1;

        selectedServicesArray.push({
          id: service.id,
          count,
          isUpfrontPayment: service.isUpfrontPayment,
          isFullyBooked: BookingHelper.isServiceFullyBooked(service, isLessonInvite),
          packageParticipants: service.packageParticipants,
          timeslots:
            (selectedServices[index].scheduledDates.map((timeItem: ScheduledDateBooking) => {
              return {
                dateStart: timeItem.dateStart,
                dateEnd: timeItem.dateEnd,
                participants: timeItem.participants,
              };
            }) as TimeSlotBooking[]) ?? [],
        });
      });

      let invitationType = 0;
      if (isServiceInvite) invitationType = 1;
      if (isLessonInvite) invitationType = 0;

      const bookingProcessModel: BookingProcessModel = {
        orgId,
        userId,
        isPaymentInApp,
        payment: {
          paymentIntentId,
          paymentIntentClientSecret: paymentClientSecret,
          currency: currencyIsoCode,
        },
        checkoutSummary,
        selectedServices: selectedServicesArray,
        selectedCoachIds: coachIds,
        selectedLocationIds: locationIds,
        invitation: {
          userInviteId: lessonInviteId || serviceInviteId,
          isInvitation: isLessonInvite || isServiceInvite,
          invitationType,
        },
      };

      const invoiceObj: BookingProcessRes = await Api.ClientRoutes.Booking.bookingProcessAsync(
        bookingProcessModel,
      );

      /** Set the invoice ID to search param of the URL */
      dispatch(bookingSlice.actions.updateInvoiceId(invoiceObj.invoice.number));
    } catch (e) {
      refundPayment = isFreeLesson === false && isPaymentInApp;
    } finally {
      if (bookingSession) {
        // If there is a booking session, we need to update and remove it.
        await Api.ClientRoutes.Booking.updateBookingSessionAsync({
          paymentIntentId: bookingSession.id,
          sessionStatus: SessionStatusEnum.Completed,
        });

        bookingSessionOptions.removeItem();
      }
    }

    /** Update the is error of the booking */
    dispatch(bookingSlice.actions.updateIsRefund(refundPayment));

    /** Move to next step - confirmed */
    dispatch(bookingSlice.actions.changeStep(BookingStep.Confirmed));
  };

  /** Handle 3D secure process */
  const handleProcess3DSecure = async () => {
    if (is3DSecureReturnPage()) {
      const result = await getSecurePaymentResult();
      setPaymentAuthStatus(result);

      if (bookingSession && !result.isSuccessfull) {
        await Api.ClientRoutes.Booking.updateBookingSessionAsync({
          paymentIntentId: bookingSession.id,
          sessionStatus: SessionStatusEnum.Completed,
        });
        bookingSessionOptions.removeItem();
      }

      const cleanedUrl = currentUrl.split('?')[0];
      window.history.pushState({}, '', cleanedUrl);
    }
  };

  interface ConfirmationResponse {
    title: string;
    message: string;
    bgColor: string;
  }

  const getResponseBasedOnPaymentAuthStatus = (): ConfirmationResponse => {
    if (booking.selected.paymentPreference === 'outside_app' || isPrepaidLessonPack) {
      return {
        title: 'Processing Your Booking ...',
        message: 'Please wait while we are processing your booking.',
        bgColor: theme.colors.black.opacity_5,
      };
    }

    if (paymentAuthStatus && !paymentAuthStatus?.isLoading) {
      if (paymentAuthStatus?.isSuccessfull) {
        return {
          title: 'Booking ...',
          message: 'Payment is successfull, we are processing your booking now',
          bgColor: theme.colors.jungleGreen.opacity_25,
        };
      }
    }
    return {
      title: 'Processing Payment ...',
      message: 'Please wait while we are processing your payment.',
      bgColor: theme.colors.black.opacity_5,
    };
  };

  const paymentResponse = getResponseBasedOnPaymentAuthStatus();

  useEffect(() => {
    handleProcess3DSecure();
  }, []);

  useEffect(() => {
    if (!is3DSecureReturnPage()) {
      /** Start booking process */
      processBooking();
    } else {
      if (paymentAuthStatus === null) return;

      if (paymentAuthStatus.isSuccessfull === true) {
        processBooking();
      }

      if (paymentAuthStatus.isSuccessfull === false) {
        toast.error(
          '3D Secure authentication failure. Please ensure that you have entered the correct authentication details and try again',
        );
        dispatch(bookingSlice.actions.updatePaymentMethod(null));
        dispatch(bookingSlice.actions.changeStep(BookingStep.Pay));
      }
    }
  }, [paymentAuthStatus]);

  return (
    <BookingContainer
      isLoading={false}
      title={paymentResponse.title}
      continueButton={
        paymentAuthStatus?.isSuccessfull === false
          ? {
              label: 'Restart Booking',
              onClick: () => handleResetBooking(true),
            }
          : undefined
      }
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box fontSize="1.5rem" textAlign="center" mb={4}>
            {locationName}
          </Box>
        </Grid>
        <Grid item md={12}>
          <BookingSummaryInfo>
            <Card sx={{ backgroundColor: paymentResponse.bgColor }} elevation={1}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box height={37} paddingY={1} fontWeight="bold">
                      {paymentResponse.title}
                    </Box>
                    <Box height={37} paddingY={1}>
                      {paymentResponse.message}
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </BookingSummaryInfo>
        </Grid>
      </Grid>
    </BookingContainer>
  );
};
