import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { BrandThemeContext } from 'common/context';
import { getBaseOrgRoute } from '../../../common/utils/common.helpers';
import styles from './RadarChart.module.scss';

interface RadarChartLegendProps {
  category: string;
  skillRating: number;
  lessonsCount: number;
  lessonCategories: string;
}

export const RadarChartLegend = ({
  category,
  skillRating,
  lessonsCount,
  lessonCategories,
}: RadarChartLegendProps) => {
  const theme = useContext(BrandThemeContext);
  const orgBaseRoute = getBaseOrgRoute(window.location.pathname);
  return (
    <NavLink
      key={category}
      className={styles.link}
      to={{
        pathname: `${orgBaseRoute}/lessons`,
        state: {
          categories: lessonCategories,
          tab: 0,
        },
      }}
    >
      <span className={styles.skillCategory}>
        {skillRating} {category}
      </span>
      <div
        className={styles.lessonsTaken}
        style={{
          background: theme.colors.jungleGreen.opacity_100,
        }}
      >
        {`${lessonsCount} ${lessonsCount > 1 ? 'lessons' : 'lesson'}`}
      </div>
    </NavLink>
  );
};
