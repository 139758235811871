/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { File } from 'data/entities/file.entity';
import { Config } from '../../../config';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from '../ExerciseView.module.scss';
import AttachmentItem from '../../../modules/attachment/components/AttachmentItem/AttachmentItem';
import { ExerciseModel } from '../../../data/entities/exercises.entity';

interface IExerciseContent {
  title: string;
  categories?: string[];
  text: string | null;
  attachments?: File[] | ExerciseModel;
}
const ExerciseContent: React.FC<IExerciseContent> = ({ title, categories, text, attachments }) => {
  const [carouselVisibleItemIndex, setCaruselVisibleItemIndex] = useState<number>(0);
  const [currentItemToEnlarge, setCurrentItemToEnlarge] = useState<any>(undefined);
  const [carouselCurrentItem, setCaruselCurrentItem] = useState<any>(
    (attachments as File[])[0]?.content_type ?? undefined,
  );

  return (
    <>
      <div className={styles.attachments}>
        {attachments && (attachments as File[])?.length > 0 && !('exerciseObj' in attachments) ? (
          <>
            <Carousel
              showArrows
              width="100%"
              centerMode={false}
              onChange={(index, item: any) => {
                setCaruselVisibleItemIndex(index);
                setCaruselCurrentItem(item?.props?.type);
              }}
              showThumbs={false}
              autoPlay={false}
              interval={7200000}
              className={carouselCurrentItem?.startsWith('image') ? styles.carousel : undefined}
              onClickItem={(_, item: any) => {
                if (item.props.type.startsWith('image')) {
                  if (currentItemToEnlarge === item) {
                    setCurrentItemToEnlarge(undefined);
                  } else {
                    setCurrentItemToEnlarge(item);
                  }
                }
              }}
            >
              {(attachments as File[]).map((item: File, i) => {
                return (
                  <AttachmentItem
                    key={i}
                    url={`${Config().AWSCloudFrontUrl as string}${item?.file_name}`}
                    type={item.content_type?.toLowerCase()}
                    note=""
                    play={i === carouselVisibleItemIndex && true}
                  />
                );
              })}
            </Carousel>
            {currentItemToEnlarge !== undefined && (
              <div
                className={styles.fullPageImage}
                onClick={() => setCurrentItemToEnlarge(undefined)}
              >
                <img src={currentItemToEnlarge.props.url} alt="" />
              </div>
            )}
          </>
        ) : attachments && 'exerciseObj' in attachments ? (
          <Carousel
            showArrows
            width="100%"
            centerMode={false}
            onChange={(index) => setCaruselVisibleItemIndex(index)}
            showThumbs={false}
            autoPlay={false}
            interval={7200000}
          >
            {(attachments as ExerciseModel).attachments?.map((item: File, i) => {
              return (
                <AttachmentItem
                  key={i}
                  url={`${Config().AWSCloudFrontUrl as string}${item?.file_name}`}
                  type={item.content_type?.toLowerCase()}
                  note=""
                  play={i === carouselVisibleItemIndex && true}
                />
              );
            })}
          </Carousel>
        ) : (
          <div className={styles.emptyAttachmentsList}>No attachments</div>
        )}
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.categories}>
        {categories?.map((name: string) => (
          <span key={name}>{name}</span>
        ))}
      </div>
      <div className={styles.text}>{text}</div>
    </>
  );
};
export default ExerciseContent;
