import { alpha } from '@mui/material';

const baseColors = {
  jungleGreen: '#1FAD883',
  black: '#000',
};

export const themeColors = {
  black: {
    p100: alpha(baseColors.black, 1),
  },
  jungleGreen: {
    p100: alpha(baseColors.jungleGreen, 1),
    p75: alpha(baseColors.jungleGreen, 0.75),
    p50: alpha(baseColors.jungleGreen, 0.5),
    p25: alpha(baseColors.jungleGreen, 0.25),
  },
};
