import homeIcon from '../../../assets/icons/navbar-icons/home-icon.svg';
import lessonsIcon from '../../../assets/icons/FW_ Icons/Lessons.svg';
import supportIcon from '../../../assets/icons/FW_ Icons/Help.svg';
import accountIcon from '../../../assets/icons/FW_ Icons/Account.svg';
import eventIcon from '../../../assets/icons/FW_ Icons/Events.svg';
import favoriteIcon from '../../../assets/icons/FW_ Icons/heart.svg';
import coachExerciseIcon from '../../../assets/icons/FW_ Icons/Exercises.svg';
import calendarIcon from '../../../assets/icons/FW_ Icons/calendar.svg';
import practiceIcon from '../../../assets/icons/FW_ Icons/Practice.svg';
import { NavMenuItemsType } from '../../../data/enums/NavMenuItemsType.enum';
import { StaticRoutes } from '../../../common/utils/routes/StaticRoutes.helper';

export const MenuItems = [
  {
    path: `${StaticRoutes.Home}`,
    label: 'Home',
    icon: homeIcon,
    alt: 'home-icon',
    type: NavMenuItemsType.Link,
  },
  {
    path: `${StaticRoutes.Favorites}`,
    label: 'Favorites',
    icon: favoriteIcon,
    alt: 'favorite-videos-icon',
    type: NavMenuItemsType.Link,
  },
  {
    path: `${StaticRoutes.Lessons}`,
    label: 'Lessons',
    icon: lessonsIcon,
    alt: 'lessons-icon',
    type: NavMenuItemsType.Link,
  },
  {
    path: `${StaticRoutes.Practice}`,
    label: 'Practice',
    icon: practiceIcon,
    alt: 'practice-icon',
    type: NavMenuItemsType.Link,
  },
  {
    path: `${StaticRoutes.Exercises}`,
    label: 'Exercises',
    icon: coachExerciseIcon,
    alt: 'coach-exercise-icon',
    type: NavMenuItemsType.Link,
  },
  {
    path: `${StaticRoutes.Events}`,
    label: 'Events',
    icon: eventIcon,
    alt: 'event-icon',
    type: NavMenuItemsType.Link,
  },
  {
    path: `${StaticRoutes.Calendar}`,
    label: 'Calendar',
    icon: calendarIcon,
    alt: 'calendar-icon',
    type: NavMenuItemsType.Link,
  },
  {
    fullPath: 'https://support.totalcoach.com/athletes',
    label: 'Support',
    icon: supportIcon,
    alt: 'support-icon',
    type: NavMenuItemsType.Support,
  },
  {
    icon: accountIcon,
    alt: 'Account',
    type: NavMenuItemsType.MyAccount,
  },
];
