/* eslint-disable no-restricted-syntax */
import { toast } from 'react-toastify';
import OrganizationService from 'data/services/organization.service';
import { UserType } from 'data/enums/UserType.enum';
import { AuthLocalStorage } from 'modules/auth/utils/auth.helper';
import { StaticRoutes } from './routes/StaticRoutes.helper';

export function getOrgId(): string {
  return window.location.pathname.split('/')[2];
}

export function redirectToOrg(orgId: string) {
  window.location.href = `${StaticRoutes.Org}/${orgId}`;
}

export function redirectToOrgWithUrl(orgId: string, returnPath: string) {
  window.location.href = `${StaticRoutes.Org}/${orgId}${returnPath}`;
}

export function vaildOrgRoute(pathname: string) {
  const result = new RegExp(`^${StaticRoutes.Org}/[a-z0-9_]+(?:-[a-z0-9_]+)*/?`);
  return result.test(pathname);
}

export function getBaseOrgRoute(pathname: string) {
  const result = new RegExp(`^${StaticRoutes.Org}/[a-z0-9_]+(?:-[a-z0-9_]+)*/?`);
  let baseOrgRoute = result.exec(pathname)?.[0];
  if (baseOrgRoute && baseOrgRoute.slice(-1) === '/') {
    baseOrgRoute = baseOrgRoute.slice(0, -1);
  }

  return baseOrgRoute;
}

export const getDomain = () => {
  const { hostname } = window.location;
  const hostParts = hostname.split('.');

  if (hostParts.length <= 2) {
    return `.${hostname}`;
  }

  return hostname.substring(hostname.indexOf('.') + 1);
};

export function getCoachFullName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`;
}

export function reloadCurrentPageWithoutSearchParams(history: any) {
  history.push(history.location.path, history.location.state);
}

/**
 * Returns the subdomain of a url string. Includes subdomains: [www, dev, app]
 * @param url https://www.example.com/path/to/resources
 * @returns example.com
 */
export function getDomainFromUrl(url: string): string | null {
  const regex = /^(?:https?:\/\/)?(?:www\.|dev\.|app\.)?([^:/\n]+)/im;
  const match = url.match(regex);

  return match ? match[1] : null;
}

export interface KeyValue {
  key: string;
  value: string;
}

export function updateUrlParamsAndAppendUrl(dictionary: KeyValue[]) {
  const searchParams = new URLSearchParams(window.location.search);
  dictionary.forEach((item) => {
    searchParams.set(item.key, item.value);
  });

  const newUrl = `${window.location.href}?${searchParams.toString()}`;
  window.history.pushState({ path: newUrl }, '', newUrl);
}

export function removeUrlParamAndAppendUrl(parameterKeys: string[]) {
  const url = window.location.href;
  const r = new URL(url);
  parameterKeys.forEach((paramKey) => r.searchParams.delete(paramKey));
  const newUrl = r.href;
  window.history.pushState({ path: newUrl }, '', newUrl);
}

export function getSearchParamsWithoutFrom() {
  const searchParams = new URLSearchParams(window.location.search);
  const filteredParams = new URLSearchParams();

  for (const [key, value] of searchParams.entries()) {
    if (key !== 'from' && key !== 'email' && key !== 'first_name' && key !== 'last_name') {
      filteredParams.append(key, value);
    }
  }

  return filteredParams.toString() ? `?${filteredParams.toString()}` : '';
}

export function getSearchParamsExcluding(paramsToExclude?: string[]) {
  const searchParams = new URLSearchParams(window.location.search);
  const filteredParams = new URLSearchParams();

  if (!paramsToExclude || paramsToExclude.length === 0) {
    return searchParams.toString() ? `?${searchParams.toString()}` : '';
  }

  for (const [key, value] of searchParams.entries()) {
    const foundMatch = paramsToExclude.find((x) => x === key);
    if (!foundMatch) {
      filteredParams.append(key, value);
    }
  }

  return filteredParams.toString() ? `?${filteredParams.toString()}` : '';
}

export const checkUserTypeAndRedirect = async (UserId: string, OrgId: string) => {
  const result = await OrganizationService.getUserTypeByOrgId(OrgId, UserId);
  if (result === UserType.client) {
    AuthLocalStorage.setSelectedOrg(OrgId);
    redirectToOrg(OrgId);
  } else if (result === UserType.coach) {
    toast.error(
      'You have been added to this workspace as a staff, you can’t use the portal as a staff and client at the same time. Please ask your administrator to add add you as a client from a different email address',
    );
  } else {
    toast.error('Looks like you don’t belong to this organisation as a client or staff member');
  }
};
