import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import { BrandThemeContext } from 'common/context';
import { CalendarDot } from './CalendarDot';
import { CalendarContext } from './CalendarContext';

export interface SelectedCalendarDate {
  day: number;
  month: number;
  year: number;
}

interface CalendarDateProps extends SelectedCalendarDate {
  isActive: boolean;
  availableTimeSlot: {
    morning: boolean;
    afternoon: boolean;
  };
  onClick: () => void;
}

export const CalendarDate = ({
  day,
  month,
  year,
  isActive,
  availableTimeSlot,
  onClick,
}: CalendarDateProps) => {
  const theme = useContext(BrandThemeContext);
  const calendarContextValue = React.useContext(CalendarContext);
  const isDayPassed = DateTime.fromObject({ year, month, day }).diffNow('days').days < -1;
  const isMorningPassed =
    DateTime.fromObject({ year, month, day, hour: 12 }).diffNow('hours').hours < 0;

  /** Rules around availability */
  const txtColor =
    !availableTimeSlot.morning && !availableTimeSlot.afternoon
      ? theme.colors.black.opacity_25
      : undefined;

  /** Clickability */
  const isClickable = !isDayPassed && (availableTimeSlot.morning || availableTimeSlot.afternoon);

  /** Cursor Style */
  const cursor = isClickable ? 'pointer' : undefined;

  const handleClick = () => {
    if (isClickable) {
      onClick();
    }
  };

  const getDateBgColor = () => {
    if (isActive) return theme.colors.jungleGreen.opacity_100;
    if (!isDayPassed) return theme.colors.white;
    return undefined;
  };

  const getDateAvailabilityIndicatorBgColor = () => {
    if (!isDayPassed) return theme.colors.white;
    return undefined;
  };

  const getDateColor = () => {
    if (isActive) return theme.colors.white;
    return undefined;
  };

  return (
    <Box
      width="100%"
      height={50}
      borderRadius={2}
      textAlign="center"
      bgcolor={getDateBgColor()}
      color={txtColor}
      onClick={handleClick}
      sx={{ cursor }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.25}
        color={getDateColor()}
        height="100%"
      >
        {day}
        {calendarContextValue.isLoading && !isDayPassed && (
          <Skeleton variant="rectangular" width={34} height={12} sx={{ borderRadius: 4 }} />
        )}
        {!calendarContextValue.isLoading && (
          <Box
            bgcolor={getDateAvailabilityIndicatorBgColor()}
            padding={0.25}
            borderRadius={4}
            paddingX={1}
          >
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.25}>
              {!isDayPassed && (
                <>
                  {!isMorningPassed && <CalendarDot isOn={availableTimeSlot.morning} />}
                  <CalendarDot isOn={availableTimeSlot.afternoon} />
                </>
              )}
            </Stack>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
