/* eslint-disable no-underscore-dangle */
import React from 'react';

// import { get } from 'lodash';
import PreviewFavoriteItem from '../../../modules/library/components/PreviewFavoriteItem/PreviewFavoriteItem';
import Unfavorite from '../../../modules/library/components/Unfavorite/Unfavorite';
import { Config } from '../../../config';
import styles from './FavouritesItem.module.scss';
import { FavoriteModel } from '../../../data/entities/favorites.entity';
import { Attachment } from '../../../data/entities/attachment.entity';
import { File } from '../../../data/entities/file.entity';

interface IFavouritesItemProps {
  item: FavoriteModel;
  openFavouriteView: Function;
  onUnfavorited?: (id: string) => void;
}

const FavouritesItem: React.FC<IFavouritesItemProps> = ({
  item,
  openFavouriteView,
  onUnfavorited,
}) => {
  let note = '';
  let thumbnail = '';
  let isVideo = false;
  const fileUrlBase = Config().AWSCloudFrontUrl as string;
  if (item.attachment && 'file_url' in item.attachment && item.attachment.file_url) {
    if (item.attachment?.thumbnail_url !== '') {
      thumbnail = `${fileUrlBase}${item.attachment?.thumbnail_url}`;
      isVideo = true;
    } else {
      thumbnail = `${fileUrlBase}${item.attachment?.file_name}`;
    }
  } else if (item.attachment && !('thumbnail_url' in item.attachment)) {
    const itemAttachment = item.attachment as Attachment;
    const noteObj = JSON.parse(itemAttachment?.attachment_type as string);
    const noteIndex = Object.keys(noteObj).indexOf('note_description');
    if (noteIndex !== -1) {
      note = Object.values(noteObj)[noteIndex] as string;
    }
  }

  return (
    <div className={styles.favouriteItem}>
      <div
        key={item.favoriteId}
        className={styles.favouriteWrapper}
        onClick={() => openFavouriteView(item)}
      >
        <PreviewFavoriteItem
          thumbnailUrl={thumbnail}
          isFile={item.isFileAttachment}
          note={note}
          isVideo={isVideo}
          extension={(item.attachment as unknown as File)?.extension ?? null}
        />
        <div className={styles.titleColumn}>
          <div className={styles.favouriteTitle}>{item.favoriteType?.favorite_note}</div>
        </div>
      </div>
      {onUnfavorited && <Unfavorite onUnfavorited={onUnfavorited} id={item.favoriteId} />}
    </div>
  );
};

export default FavouritesItem;
