import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Button } from 'components/Button/MuiButtons/Button';
import { BrandThemeContext } from 'common/context';
import { useMobileViewDetector } from '../../../../../common/hooks';
import { Grid } from '../../../../../components/Grid';
import OrganizationService, {
  IsPromoCodeValidResult,
} from '../../../../../data/services/organization.service';

interface PromoCodeFormProps {
  userId: string;
  orgId: string;
  onApply: (value: string) => void;
  promoCodeValue: string;
}

interface PromoCodeInvalidMessageProps {
  text: string;
}

interface ButtonProps {
  onClick: () => void;
  disabled: boolean;
  promoCodeValue?: string;
}

const PromoCodeBtn = ({ onClick, disabled, promoCodeValue }: ButtonProps) => {
  const theme = useContext(BrandThemeContext);
  let bgcolor = '';
  if (!disabled) bgcolor = `${theme.colors.black.opacity_100} !important`;
  else bgcolor = `${theme.colors.black.opacity_25} !important`;
  if (promoCodeValue) bgcolor = `${theme.colors.black.opacity_25} !important`;
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={{
        height: 40,
        textTransform: 'capitalize',
        bgcolor,
        color: `${theme.colors.white} !important`,
        '&:hover': {
          bgcolor:
            !disabled && !promoCodeValue
              ? `${theme.colors.black.opacity_75} !important`
              : undefined,
        },
      }}
      label={promoCodeValue ? 'Applied' : 'Apply'}
    />
  );
};

const PromoCodeTextField = ({ value, onChange }: Pick<TextFieldProps, 'value' | 'onChange'>) => {
  return (
    <TextField
      required
      size="small"
      id="promoCode"
      label="Promotional Code"
      sx={{
        marginRight: 1.5,
        width: '300px',
      }}
      value={value}
      onChange={onChange}
    />
  );
};

const PromoCodeInvalidMessage = ({ text }: PromoCodeInvalidMessageProps) => {
  const theme = useContext(BrandThemeContext);
  return <Typography color={theme.colors.red.opacity_100}>{text}</Typography>;
};

export const PromoCodeForm = ({ promoCodeValue, onApply, userId, orgId }: PromoCodeFormProps) => {
  const { isViewedFromMobile } = useMobileViewDetector();
  const [promoCode, setPromoCode] = React.useState(promoCodeValue);
  const [promoCodeCheckResult, setPromoCodeCheckResult] =
    React.useState<IsPromoCodeValidResult | null>(null);

  const handleApply = async () => {
    const isPromoCodeValidResult = await OrganizationService.isPromoCodeValid(
      userId,
      orgId,
      promoCode,
    );

    setPromoCodeCheckResult(isPromoCodeValidResult);
    if (isPromoCodeValidResult.isValid) {
      onApply(promoCode);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPromoCode(e.target.value.toUpperCase());
    setPromoCodeCheckResult(null);
  };

  return (
    <>
      {isViewedFromMobile && (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography fontWeight="bold">Have promotional code? Enter it here</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="space-between" marginTop={1} marginBottom={1}>
              <PromoCodeTextField value={promoCode} onChange={handleChange} />
              <PromoCodeBtn
                onClick={handleApply}
                disabled={promoCode.length < 6}
                promoCodeValue={promoCodeValue}
              />
            </Stack>
            {promoCodeCheckResult !== null && !promoCodeCheckResult?.isValid && (
              <PromoCodeInvalidMessage text={promoCodeCheckResult?.message ?? ''} />
            )}
          </Grid>
        </Grid>
      )}
      {!isViewedFromMobile && (
        <Stack direction="row" justifyContent="space-between" marginBottom={1.5}>
          <Box textAlign="right" width="100%" lineHeight={56} paddingX={4} paddingY={1.2}>
            {promoCodeCheckResult !== null && !promoCodeCheckResult?.isValid ? (
              <PromoCodeInvalidMessage text={promoCodeCheckResult?.message ?? ''} />
            ) : (
              <Typography fontWeight="bold">Have promotional code? Enter it here</Typography>
            )}
          </Box>
          <PromoCodeTextField value={promoCode} onChange={handleChange} />
          <PromoCodeBtn
            onClick={handleApply}
            disabled={promoCode.length < 6}
            promoCodeValue={promoCodeValue}
          />
        </Stack>
      )}
    </>
  );
};
