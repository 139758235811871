import React from 'react';
import clsx from 'clsx';
import InputField from '../../../../components/InputField/InputField';
import FormErrorMessage from '../../components/FormErrorMessage/FormErrorMessage';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import StaticDictionary from '../../../../common/utils/staticDictionary.helper';
import styles from './SignupPage.module.scss';
import { EmergencyContact, SignUpCheckBoxes, SignUpErrorMessageEvent } from './useSignUp';

interface SignUpProps {
  isPhoneNumView?: boolean;
  onUnder18Checked?: (checked: boolean) => void;
  isEmailFieldDisabled?: boolean;
  isConfirmEmailFieldDisabled?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password?: string;
  confirmPassword?: string;
  onFirstNameChanged: (value: any) => void;
  onLastNameChanged: (value: any) => void;
  onEmailChanged: (value: any) => void;
  onConfirmEmailChanged: (value: any) => void;
  onPasswordChanged: (value: any) => void;
  onConfirmPasswordChanged: (value: any) => void;
  onChangePhoneNumber: (value: any) => void;
  errorMessageEvent: SignUpErrorMessageEvent;
  checkBoxes: SignUpCheckBoxes;
  setCheckBoxes: (value: SignUpCheckBoxes) => void;
  UpdateErrorMessageEvent: (value: SignUpErrorMessageEvent) => void;
  onChangeDateOfBirth: (value: any) => void;
  onChangeAllergies: (value: any) => void;
  onChangeMedication: (value: any) => void;
  onChangeEmergencyContactFirstName: (value: any, index: number) => void;
  onChangeEmergencyContactLastName: (value: any, index: number) => void;
  onChangeEmergencyContactRelationship: (value: any, index: number) => void;
  onChangeEmergencyContactPhone: (value: any, index: number) => void;
  isInvite?: boolean;
}

export const SignUp = (props: SignUpProps) => {
  const {
    isPhoneNumView = false,
    onUnder18Checked,
    isEmailFieldDisabled = false,
    isConfirmEmailFieldDisabled = false,
    firstName,
    lastName,
    email,
    confirmEmail,
    password,
    confirmPassword,
    onFirstNameChanged,
    onLastNameChanged,
    onEmailChanged,
    onConfirmEmailChanged,
    onPasswordChanged,
    onConfirmPasswordChanged,
    onChangePhoneNumber,
    errorMessageEvent,
    checkBoxes,
    setCheckBoxes,
    UpdateErrorMessageEvent,
    onChangeDateOfBirth,
    onChangeAllergies,
    onChangeMedication,
    onChangeEmergencyContactFirstName,
    onChangeEmergencyContactLastName,
    onChangeEmergencyContactRelationship,
    onChangeEmergencyContactPhone,
    isInvite = false,
  } = props;

  const isUnder18Checked = checkBoxes.under18 === true;

  return (
    <>
      <InputField
        label="First Name"
        required
        placeholder="John"
        onChange={onFirstNameChanged}
        defaultValue={firstName}
      />
      <FormErrorMessage message={errorMessageEvent.firstNameError} />
      <InputField
        label="Last Name"
        required
        placeholder="Doe"
        onChange={onLastNameChanged}
        defaultValue={lastName}
      />
      <FormErrorMessage message={errorMessageEvent.lastNameError} />
      <InputField
        label="Email"
        required
        placeholder="johndoe@example.com"
        onChange={onEmailChanged}
        disabled={isEmailFieldDisabled}
        defaultValue={email}
      />
      <FormErrorMessage message={errorMessageEvent.emailInputError} />
      {!isInvite && (
        <>
          <InputField
            label="Confirm Email"
            required
            placeholder="Re-enter email"
            onChange={onConfirmEmailChanged}
            disabled={isConfirmEmailFieldDisabled}
            defaultValue={confirmEmail}
          />
          <FormErrorMessage message={errorMessageEvent.confirmEmailInputError} />
        </>
      )}
      <InputField
        type="password"
        label="Password"
        placeholder="Password"
        required
        onChange={onPasswordChanged}
        defaultValue={password}
      />
      <FormErrorMessage message={errorMessageEvent.passwordInputError} />
      <InputField
        type="password"
        label="Confirm Password"
        placeholder="Re-enter password"
        required
        onChange={onConfirmPasswordChanged}
        defaultValue={confirmPassword}
      />
      <FormErrorMessage message={errorMessageEvent.confirmPasswordInputError} />
      <div id="passwordHelpBlock" className={clsx('form-text', styles.field)}>
        {StaticDictionary.Password.formatMessage}
      </div>

      {isPhoneNumView && (
        <>
          <InputField label="Contact Number" type="tel" onChange={(e) => onChangePhoneNumber(e)} />
          <FormErrorMessage message={errorMessageEvent.phoneNumberInputError} />
        </>
      )}

      <div className={styles.checkboxes}>
        <Checkbox
          id="policy"
          labelLinks={
            <div>
              {`* (Required) Creating an account means you agree with TotalCoach's `}
              <a href={StaticDictionary.ExternalLinks.termsOfUse} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
              {`, `}
              <a
                href={StaticDictionary.ExternalLinks.privacyPolicy}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              , and default Notification Settings.
            </div>
          }
          checked={checkBoxes.termsOfService === true}
          onCheck={(checked: boolean) => {
            setCheckBoxes({ ...checkBoxes, termsOfService: checked });
            if (checked === true) {
              UpdateErrorMessageEvent({
                checkBoxesErrors: {
                  ...errorMessageEvent.checkBoxesErrors,
                  termsOfService: undefined,
                },
              });
            }
          }}
        />
        <FormErrorMessage message={errorMessageEvent.checkBoxesErrors.termsOfService} />
        <Checkbox
          id="under18"
          label="  I am under 18 years old"
          onCheck={(checked: boolean) => {
            setCheckBoxes({ ...checkBoxes, under18: checked });
            if (onUnder18Checked) onUnder18Checked(checked);
          }}
          checked={checkBoxes.under18}
        />
        <FormErrorMessage message={errorMessageEvent.checkBoxesErrors.under18} />
        <Checkbox
          id="rememberMe"
          label="Remember me"
          checked={checkBoxes.rememberMe === true}
          onCheck={(checked: boolean) => setCheckBoxes({ ...checkBoxes, rememberMe: checked })}
        />
      </div>
      <FormErrorMessage
        message={
          errorMessageEvent.requestError === 'AccountNameInUse: name already in use'
            ? 'This user already has an account.'
            : errorMessageEvent.requestError
        }
      />
      {isUnder18Checked && (
        <div className="bg-light p-3 mb-4 w-100">
          <label className="fs-5">Under 18 Information</label>
          <InputField
            type="date"
            label="Date of Birth"
            required={isUnder18Checked}
            onChange={onChangeDateOfBirth}
          />
          <FormErrorMessage message={errorMessageEvent.dateOfBirth} />

          <InputField type="text" label="Allergies" onChange={onChangeAllergies} />
          <InputField type="text" label="Medication" onChange={onChangeMedication} />
          {[0, 1].map((index) => {
            const dynamicKey = `emergencyContact${index}` as keyof SignUpErrorMessageEvent;
            const errorMessage = errorMessageEvent[dynamicKey] as EmergencyContact;

            const requiredProps = index === 0 ? { required: true } : {};

            return (
              <React.Fragment key={index}>
                <label>{`Emergency Contact ${index + 1}`}</label>
                <InputField
                  label="First Name"
                  {...requiredProps}
                  placeholder="Mary"
                  onChange={(e) => onChangeEmergencyContactFirstName(e, index)}
                />
                <FormErrorMessage message={errorMessage?.firstName} />
                {/* Repeat similar pattern for other fields */}
                <InputField
                  label="Last Name"
                  {...requiredProps}
                  placeholder="Jane"
                  onChange={(e) => onChangeEmergencyContactLastName(e, index)}
                />
                <FormErrorMessage message={errorMessage?.lastName} />
                <InputField
                  label="Relationship"
                  {...requiredProps}
                  placeholder="Mother"
                  onChange={(e) => onChangeEmergencyContactRelationship(e, index)}
                  maxLength={20}
                />
                <FormErrorMessage message={errorMessage?.relationship} />
                <InputField
                  label="Contact Number"
                  {...requiredProps}
                  type="tel"
                  onChange={(e) => onChangeEmergencyContactPhone(e, index)}
                />
                <FormErrorMessage message={errorMessage?.contact as string} />
              </React.Fragment>
            );
          })}
        </div>
      )}
    </>
  );
};
