import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { Button } from 'components/Button/MuiButtons/Button';
import Header from 'components/Header/Header';
import { RootState } from 'redux/store';
import { BrandThemeContext } from 'common/context';
import { useMobileViewDetector } from '../../../../../common/hooks';

interface BookingContainerProps {
  isLoading: boolean;
  title: string;
  children?: React.ReactNode;
  backButton?: {
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
  };
  continueButton?: {
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
  };
}

const footerStyle = {
  position: 'sticky',
  bottom: 0,
  backgroundColor: '#FFF',
  height: 56,
  boxShadow: '1px 0 2px 1px #dddddd',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 10px',
  zIndex: 500,
};

export const BookingContainer = ({
  isLoading,
  title,
  children,
  backButton,
  continueButton,
}: BookingContainerProps) => {
  const theme = useContext(BrandThemeContext);
  const { isViewedFromMobile } = useMobileViewDetector();
  const containerMaxWidth = isViewedFromMobile ? '100%' : '1024px';

  const isHeaderVisible = useSelector((state: RootState) => state.authentication.isHeaderVisible);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [title]);

  return (
    <>
      {!isHeaderVisible && <Header />}
      <Box minHeight="calc(100vh - 112px)" bgcolor={theme.colors.black.opacity_2_5}>
        <Box marginX="auto" paddingX={2} maxWidth={containerMaxWidth}>
          <Box display="flex" justifyContent="center" paddingY={2} fontSize="1.75em">
            <Grid container>
              <Grid item md={12}>
                <Box
                  display="flex"
                  flexGrow={1}
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  fontSize="1.5rem"
                >
                  {isLoading ? 'Loading ...' : title}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {!isLoading && (
            <Box marginX="auto" padding={0}>
              {children}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={footerStyle}>
        {!isLoading && (
          <Grid item md={12}>
            <Box display="flex" justifyContent="flex-end">
              {backButton?.onClick && (
                <Button
                  label="Back"
                  onClick={backButton.onClick}
                  color="info"
                  sx={{
                    mr: 2,
                    minWidth: 140,
                  }}
                />
              )}
              {continueButton?.onClick && (
                <Button
                  disabled={continueButton.disabled}
                  variant="contained"
                  label={continueButton.label || 'Continue'}
                  onClick={continueButton.onClick}
                  sx={{
                    minWidth: 140,
                  }}
                />
              )}
            </Box>
          </Grid>
        )}
      </Box>
    </>
  );
};
