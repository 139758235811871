/* eslint-disable no-restricted-syntax */
import { Config } from '../../config';
import { Favorite } from '../entities/favorites.entity';
import { BaseRepo } from './base.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export class FavoritesRepository extends BaseRepo {
  // #region Private properties
  private favoritesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('favorites');
  // #endregion

  // #region Public Methods
  async createFavorite(favorite: Favorite) {
    const result = await this.favoritesCollection?.insertOne(favorite);
    return result;
  }

  async updateFavoriteType(favoriteId: string, stringifiedType: string) {
    const result = await this.favoritesCollection?.findOneAndUpdate(
      { _id: favoriteId },
      {
        $set: {
          favorite_type: stringifiedType,
          date_updated: getUnixMilliseconds(),
        },
      },
    );

    return result;
  }

  async getAllUserFavouritesByOrg(userId: string, orgId: string): Promise<Favorite[] | undefined> {
    const favoriteResult = await this.favoritesCollection?.find(
      { user_id: userId, org_id: orgId, deleted: false },
      { sort: { date_created: 1 } },
    );
    return favoriteResult as Favorite[] | undefined;
  }

  async deleteFavoriteById(favoriteId: string) {
    const result = await this.favoritesCollection?.deleteOne({ _id: favoriteId });
    return result;
  }
  // #endregion
}
