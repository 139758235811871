import React, { useEffect, useState } from 'react';
import { ReactCookieProps, withCookies } from 'react-cookie';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import Spinnerv2 from '../components/Spinnerv2/Spinnerv2';
import { OrgInvitationModel } from '../data/models/orgInvitation.model';
import { UserType } from '../data/enums/UserType.enum';
import CenteredError from '../components/CenteredError/CenteredError';
import Logger from './logger.middleware';
import StaticDictionary from '../common/utils/staticDictionary.helper';

interface IProps extends RouteComponentProps, ReactCookieProps {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

export default (ComposedComponent: any, userType: UserType, componentProps?: any) => {
  const WithOrgInviteDetails = (props: IProps) => {
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState({ isError: false, message: '' });
    const { orgId, inviteId } = useParams<Record<string, string | undefined>>();
    const params = new URLSearchParams(window.location.search);
    const invitationIdParam = params.get(StaticDictionary.UrlParameters.Organization.InvitationId);
    const { cookies, history } = props;

    useEffect(() => {
      if (isLoading) {
        try {
          const invitationId = userType === UserType.client ? inviteId : invitationIdParam;
          if (!invitationId) {
            setError({ isError: true, message: 'Your invitation id is not valid.' });
            return;
          }

          if (!orgId) {
            setError({
              isError: true,
              message: 'Your invitation is not associated with an organisation.',
            });
            return;
          }

          cookies?.remove('orgInvitation');

          const orgModelStateValue: OrgInvitationModel = {
            orgId,
            inviteId: invitationId,
            userType,
          };
          if (Logger.isDevEnvironment) {
            console.log('orgInvitationModel: ', orgModelStateValue);
          }

          cookies?.set('orgInvitation', orgModelStateValue, { path: '/' });
        } finally {
          setLoading(false);
        }
      }
    }, [isLoading, orgId, invitationIdParam, inviteId, cookies]);

    if (isLoading) {
      return <Spinnerv2 message="Loading..." />;
    }

    if (error && error.isError) {
      return (
        <CenteredError
          message={error.message}
          showBtn
          btnLabel="Log in"
          onBtnClick={() => {
            history.push('/signup');
          }}
        />
      );
    }

    const newProps = { ...props, ...componentProps };
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ComposedComponent {...newProps} />;
  };

  return withRouter(withCookies(WithOrgInviteDetails));
};
