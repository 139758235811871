import React from 'react';
import { Lesson } from '../../../../data/entities/lessons.entity';
import styles from './LessonDateBegin.module.scss';
import ClockIcon from '../../../../assets/icons/clock/clock.svg';
import CancelLesson from '../CancelLesson/CancelLesson';
import { OrgBookingRules } from '../../../book/models/booking.model';
import LessonHelper from '../../../../common/utils/lessons.helper';
import SessionProvider from '../../../../providers/SessionProvider';
import { BookingFormatter } from '../../../book/utils/booking.helpers';

interface LessonDateBeginProps {
  lesson: Lesson;
  cancelLesson: Function;
  onCancelPackage: Function;
  orgBookingRules: OrgBookingRules;
}

const LessonDateBegin = ({
  lesson,
  cancelLesson,
  onCancelPackage,
  orgBookingRules,
}: LessonDateBeginProps) => {
  const dateLabel = BookingFormatter.formatDateStartAndEnd(
    lesson.date_start,
    lesson.date_end,
    SessionProvider.getTimeZoneInfo(),
  );

  const lessonCancellation = LessonHelper.GetCancellationResult(
    lesson,
    orgBookingRules.cancellationPeriodInMinutes,
  );

  const allowCancellation = LessonHelper.AllowCancellation(lessonCancellation);
  const isLessonCancelled = LessonHelper.isLessonCancelled(lesson);

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.title}>Date</div>
        {isLessonCancelled && <div className={styles.canceled}>Canceled</div>}
        {!isLessonCancelled && (
          <div className={styles.date}>
            <img className={styles.icon} alt="clock-icon" src={ClockIcon} />
            <span>{dateLabel}</span>
          </div>
        )}
      </div>
      {allowCancellation && (
        <CancelLesson
          lesson={lesson}
          cancelLesson={cancelLesson}
          onCancelPackage={onCancelPackage}
          lessonCancellation={lessonCancellation}
          cancellationPeriodInMinutes={orgBookingRules.cancellationPeriodInMinutes}
        />
      )}
    </div>
  );
};

export default LessonDateBegin;
