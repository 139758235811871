import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FilledInput, { FilledInputProps } from '@mui/material/FilledInput';

interface TextfieldProps extends Pick<FilledInputProps, 'placeholder' | 'value' | 'disabled'> {
  type?: 'text' | 'number';
  label: string;
  value: string;
  hasError?: boolean;
  errorMessage?: string;
  isEditMode?: boolean;
  // eslint-disable-next-line
  onChange: (value: string) => void;
}

export const Textfield = (props: TextfieldProps) => {
  const {
    label,
    placeholder,
    value,
    hasError,
    errorMessage,
    isEditMode,
    onChange,
    type,
    disabled,
  } = props;
  return (
    <Stack spacing={1}>
      {isEditMode && (
        <>
          <Typography>{label}</Typography>
          <FilledInput
            fullWidth
            hiddenLabel
            disableUnderline
            disabled={disabled}
            placeholder={placeholder}
            type={type ?? 'text'}
            value={value !== null ? value : ''}
            onChange={(e) => onChange(e.target.value)}
          />
          {hasError && (
            <Typography color="#ff0000" fontSize="0.8rem">
              {errorMessage}
            </Typography>
          )}
        </>
      )}
      {!isEditMode && (
        <>
          <Typography fontWeight="bold">{label}</Typography>
          <Typography>{value && value.length && value !== '' ? value : '-'}</Typography>
        </>
      )}
    </Stack>
  );
};
