import { Auth } from 'aws-amplify';
import { Config } from '../../config';
import { getUnixMilliseconds } from './date.helpers';

interface FederatedUser {
  id: string;
  firstName: string;
  email: string;
  accessToken: string;
}

const reAuthenticate = async (user: FederatedUser) => {
  try {
    const openIdTokenResult = await fetch(
      `${Config().BaseCognitoOpenIdURL}/cognito/getOpenIDToken`,
      {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessToken: user.accessToken,
          userId: user.id,
        }),
      },
    );

    const result = await openIdTokenResult.json();
    await Auth.signOut();
    await Auth.federatedSignIn(
      'developer',
      {
        token: result.data.Token,
        identity_id: result.data.IdentityId,
        expires_at: 1800000 + getUnixMilliseconds(),
      },
      {
        name: user.firstName,
        email: user.email,
      },
    );
  } catch (e) {
    console.error(e);
  }
};

export default reAuthenticate;
