import { Auth } from 'aws-amplify';
import Logger from '../../middleware/logger.middleware';

const isAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (e) {
    if (Logger.isDevEnvironment) {
      console.log('AWS Amplify Authenticated: false');
    }
    return false;
  }
};

export default isAuthenticated;
