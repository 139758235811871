import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const useMobileViewDetector = () => {
  const theme = useTheme();
  /** These queries uses default MUI breakpoint values but custom values can be specified if needed */
  /** Can be renamed later on to useViewDetector for more types of views */
  /** 600px */
  const isViewedFromMobile = useMediaQuery(theme.breakpoints.down('sm'));
  /** 900px */
  const isViewedFromTablet = useMediaQuery(theme.breakpoints.down('md'));

  return {
    isViewedFromMobile,
    isViewedFromTablet,
  };
};
