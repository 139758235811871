import React from 'react';
import styles from './QuestionnaireStart.module.scss';
import Button from '../../../../components/Button/Button';
import { QuestionnaireStages } from '../../models/questionnaire.model';

interface IQuestionnaireStart {
  onClose: () => void;
  getQuestionnaire: Function;
}

const QuestionnaireStart: React.FC<IQuestionnaireStart> = ({ onClose, getQuestionnaire }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>Before you get started on your golf journey</span>
        <span>we'd love to learn more about you.</span>
      </div>
      <div className={styles.subtitle}>Can you spare 2 minutes?</div>
      <div className={styles.content}>
        <Button
          label="Sure"
          className={styles.button}
          onClick={() => getQuestionnaire(QuestionnaireStages.Pending)}
        />
      </div>
      <div className={styles.skip} onClick={onClose}>
        Skip for now
      </div>
    </div>
  );
};

export default QuestionnaireStart;
