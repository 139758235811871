import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import { BrandThemeContext } from 'common/context';
import { containerRadius } from './config';

interface TabProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
}

export const Tab = ({ label, isActive, onClick }: TabProps) => {
  const theme = useContext(BrandThemeContext);
  return (
    <Box
      bgcolor={isActive ? theme.colors.black.opacity_5 : undefined}
      onClick={onClick}
      sx={{
        paddingTop: 1.5,
        paddingBottom: 0.5,
        margin: 0,
        paddingX: 3,
        fontSize: '0.9rem',
        fontWeight: isActive ? 'bold' : undefined,
        textAlign: 'center',
        borderTopLeftRadius: containerRadius,
        borderTopRightRadius: containerRadius,
        cursor: 'pointer',
      }}
    >
      {label}
    </Box>
  );
};
