/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useCallback } from 'react';

import { toast } from 'react-toastify';
import EventViewContent from 'modules/calendar/components/EventViewContent/EventViewContent';
import { getDateFromUnixMilli, getUnixMilliseconds } from 'common/utils/date.helpers';
import { Address, UserContact } from 'data/entities/userContacts.entity';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import { AssociateWithAvatar } from 'data/entities/associate.entity';
import SessionProvider from 'providers/SessionProvider';
import UserService from 'data/services/user.service';
import styles from './EditLesson.module.scss';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import { Lesson } from '../../../../data/entities/lessons.entity';
import { LessonCategory } from '../../../../data/entities/orgLessonCategories.entity';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import EditLessonContent from '../EditLessonContent/EditLessonContent';

interface EditLessonProps {
  lesson: Lesson;
  categories: LessonCategory[];
  visible: boolean;
  onClose: Function;
  onUpdateLesson: Function;
  onUpdatePracticeLesson: Function;
  isUpdating: boolean;
}

const EditLesson: React.FC<EditLessonProps> = ({
  lesson,
  categories,
  visible,
  onClose,
  onUpdateLesson,
  onUpdatePracticeLesson,
  isUpdating,
}) => {
  const [lessonTitle, setLessonTitle] = useState('');
  const [note, setNote] = useState<string>('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [startDate, setStartDate] = useState(getUnixMilliseconds());
  const [startDateIndex, setStartDateIndex] = useState(0);
  const [endDate, setEndDate] = useState(
    getDateFromUnixMilli(startDate).plus({ hour: 1 }).toMillis(),
  );
  const [isPractice, setIsPractice] = useState<boolean>(false);
  const [isShareCoach, setIsShareCoach] = useState<boolean>(false);
  const [address, setAddress] = useState<Address>();
  const [practiceLocations, setPracticeLocations] = useState<UserPracticeLocation[]>([]);
  const [chosenLocationId, setChosenLocationId] = useState<string>();
  const [associates, setAssociates] = useState<AssociateWithAvatar[]>([]);
  const [contacts, setContacts] = useState<UserContact[]>([]);
  const [selectedContactList, setSelectedContactList] = useState<string[]>([]);
  const [addPracticeLocationView, setAddPracticeLocationView] = useState<boolean>(false);
  const [addContactView, setAddContactView] = useState<boolean>(false);

  const fetchPracticeLocations = useCallback(async () => {
    const userId: string = SessionProvider.getUserId();
    const orgId: string = SessionProvider.getOrgId();

    const associatesAsync = await UserService.getAssociatesByUserIdOrgIdAsync(userId, orgId);
    setAssociates(associatesAsync ?? []);

    const userContacts = await UserService.getAllContactsByUserId(userId);
    setContacts(userContacts ?? []);

    const userPracticeLocations = await UserService.getPracticeLocations({ userId, orgId });
    setPracticeLocations(userPracticeLocations);

    setChosenLocationId(lesson.location_id);
    setSelectedContactList(lesson.contact_ids);
    setStartDate(lesson.date_start);
    setEndDate(lesson.date_end);
    setIsShareCoach(lesson.share_practice_lesson_with_coach);
    if (lesson.client_notes)
      if (lesson.client_notes.length > 0) setNote(lesson.client_notes[0].message);
  }, [lesson]);

  useEffect(() => {
    fetchPracticeLocations();
  }, []);

  useEffect(() => {
    if (visible) {
      setLessonTitle(lesson.title);
      setSelectedCategories(lesson.org_lesson_category_ids as never[]);
    }
  }, [lesson.org_lesson_category_ids, lesson.title, visible]);

  const onUpdate = () => {
    if (lesson.is_self_taught)
      onUpdatePracticeLesson(
        lesson._id,
        lessonTitle,
        note,
        startDate,
        endDate,
        chosenLocationId,
        selectedContactList,
        selectedCategories,
        isShareCoach,
      );
    else onUpdateLesson(lessonTitle, selectedCategories);
  };

  const googleAddressToFormattedAddress = (addressParam: any) => {
    if (!addressParam) {
      return undefined;
    }

    if (addressParam?.address_components) {
      const addressObject: Address = {};

      addressObject.latitude = addressParam?.lat ?? Infinity;
      addressObject.longitude = addressParam?.lng ?? Infinity;
      addressObject.suburb = '';

      const addressComponents = addressParam?.address_components || {};

      interface AddressKeys {
        [key: string]: string;
      }

      const keysObject: AddressKeys = {
        street_number: 'street_number',
        route: 'street_name',
        sublocality: 'suburb',
        locality: 'city',
        country: 'country',
        postal_code: 'zip_code',
      };

      for (const component of addressComponents) {
        component?.types?.forEach((type: string) => {
          const key = keysObject[type as keyof AddressKeys];

          if (key) {
            (addressObject as any)[key] = component.long_name;

            if (type === 'country') {
              addressObject.country_short_name = component.short_name;
            }
          }
        });
      }

      return addressObject;
    }

    return undefined;
  };

  const onAddAddress = (addressParam: any) => {
    const addressObject = googleAddressToFormattedAddress(addressParam);
    setAddress(addressObject);
  };

  const onPracticeLocationAdded = async (newPracticeLocation: UserPracticeLocation) => {
    try {
      const userId = SessionProvider.getUserId();
      const orgId = SessionProvider.getOrgId();

      practiceLocations.push(newPracticeLocation);
      const updates = {
        practice_locations: practiceLocations,
      };

      await UserService.updateUserDetailsV2({ userId, orgId, updates });
      await fetchPracticeLocations();
    } catch (err: any) {
      toast.error('There was an error adding your contact.');
      console.error(err);
    } finally {
      setAddPracticeLocationView(false);
    }
  };

  const onContactAdded = async (newContact: UserContact) => {
    try {
      await UserService.createUserContact(newContact);
      await fetchPracticeLocations();
    } catch (err: any) {
      toast.error('There was an error adding your contact.');
      console.error(err);
    } finally {
      setAddContactView(false);
    }
  };

  return (
    <>
      <div className={styles.desktopWrapper}>
        <ModalWindow
          show={visible}
          saveBtnLabel="Update"
          onCloseClick={onClose}
          loading={isUpdating}
          onSaveClick={onUpdate}
        >
          {lesson.is_self_taught ? (
            <EventViewContent
              categories={categories}
              isPracticeLesson
              isUpdate
              deleteEvent={() => {}}
              eventTitle={lessonTitle}
              setEventTitle={setLessonTitle}
              eventNote={note}
              setEventNote={setNote}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              startDateIndex={startDateIndex}
              setStartDateIndex={setStartDateIndex}
              isShareCoach={isShareCoach}
              setIsShareCoach={setIsShareCoach}
              isPractice={isPractice}
              setIsPractice={setIsPractice}
              categorieslist={categories}
              placeholderName=""
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              address={address}
              setAddress={onAddAddress}
              locations={practiceLocations}
              setLocations={setPracticeLocations}
              chosenLocationId={chosenLocationId}
              setChosenLocationId={setChosenLocationId}
              associates={associates}
              contacts={contacts}
              selectedContactList={selectedContactList}
              setSelectedContactList={setSelectedContactList}
              addPracticeLocationView={addPracticeLocationView}
              setAddPracticeLocationView={setAddPracticeLocationView}
              onPracticeLocationAdded={onPracticeLocationAdded}
              addContactView={addContactView}
              setAddContactView={setAddContactView}
              onContactAdded={onContactAdded}
            />
          ) : (
            <EditLessonContent
              lessonTitle={lessonTitle}
              categories={categories}
              setLessonTitle={setLessonTitle}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
          )}
        </ModalWindow>
      </div>
      <div className={styles.mobileWrapper}>
        <MobileDrawer
          visible={visible}
          title="Lesson Details"
          cancelButtonText="Cancel"
          okButtonText="Update"
          onClose={onClose}
          onClickOkButton={onUpdate}
        >
          {lesson.is_self_taught ? (
            <EventViewContent
              categories={categories}
              isPracticeLesson
              isUpdate
              deleteEvent={() => {}}
              eventTitle={lessonTitle}
              setEventTitle={setLessonTitle}
              eventNote={note}
              setEventNote={setNote}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              startDateIndex={startDateIndex}
              setStartDateIndex={setStartDateIndex}
              isShareCoach={isShareCoach}
              setIsShareCoach={setIsShareCoach}
              isPractice={isPractice}
              setIsPractice={setIsPractice}
              categorieslist={categories}
              placeholderName=""
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              address={address}
              setAddress={onAddAddress}
              locations={practiceLocations}
              setLocations={setPracticeLocations}
              chosenLocationId={chosenLocationId}
              setChosenLocationId={setChosenLocationId}
              associates={associates}
              contacts={contacts}
              selectedContactList={selectedContactList}
              setSelectedContactList={setSelectedContactList}
              addPracticeLocationView={addPracticeLocationView}
              setAddPracticeLocationView={setAddPracticeLocationView}
              onPracticeLocationAdded={onPracticeLocationAdded}
              addContactView={addContactView}
              setAddContactView={setAddContactView}
              onContactAdded={onContactAdded}
            />
          ) : (
            <EditLessonContent
              lessonTitle={lessonTitle}
              categories={categories}
              setLessonTitle={setLessonTitle}
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
            />
          )}
        </MobileDrawer>
      </div>
    </>
  );
};

export default EditLesson;
