import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as Realm from 'realm-web';
import InputField from 'components/InputField/InputField';
import Button from 'components/Button/Button';
import Logger from 'middleware/logger.middleware';
import FormErrorMessage from 'modules/auth/components/FormErrorMessage/FormErrorMessage';
import styles from '../../../auth/pages/ForgotPassword/ForgotPassword.module.scss';
import { Grid } from '../../../../components/Grid';
import { Config } from '../../../../config';
import { Snackbar } from '../../../../components/Snackbar';

export const ChangePasswordPage = () => {
  const app = new Realm.App({ id: Config().RealmAppId as string });
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState<string>();
  const [emailSent, setEmailSent] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);

  const handleUpdatePassword = async () => {
    if (!email) {
      setEmailError('Email is required');
      return;
    }

    if (emailError) {
      return;
    }

    try {
      setIsSubmitting(true);
      await app.emailPasswordAuth.callResetPasswordFunction(
        { email, password: 'PasswordPlaceholder' },
        [true],
      );
      setIsSubmitting(false);
    } catch (err: any) {
      if (Logger.isDevEnvironment) {
        console.error('callResetPasswordFunction Error: ', err);
      }
    } finally {
      setEmailSent(true);
    }
  };

  let btnLabel = !isSubmitting ? 'Send email' : 'Sending email ...';
  if (emailSent) btnLabel = 'Email sent';

  return (
    <Box width={500} border={0} marginX="auto" marginTop={8} padding={2}>
      <Snackbar
        open={isNotificationOpen}
        severity="success"
        label="Password updated"
        onClose={() => {
          setIsNotificationOpen(false);
        }}
      />
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h5" marginBottom={2}>
            Update Your Password
          </Typography>
        </Grid>
        <Grid item md={12}>
          <Typography fontSize="0.9rem">
            Please enter your email below to receive the reset password link
          </Typography>
        </Grid>
        <Grid item md={12}>
          <InputField
            type="email"
            placeholder="Email"
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <FormErrorMessage message={emailError} />}
        </Grid>
        <Grid item md={12}>
          <Button
            label={btnLabel}
            onClick={handleUpdatePassword}
            className={styles.btn}
            loading={isSubmitting}
            disabled={isSubmitting === true || !email || emailSent}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
