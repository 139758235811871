import React, { useState } from 'react';
import clsx from 'clsx';

import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import styles from './Filters.module.scss';
import Search from '../../../../components/Search/Search';
import Categories from '../Categories/Categories';
import FiltersIcon from '../../../../assets/images/filters/filters.svg';
import MobileCategories from '../MobileCategories/MobileCategories';

interface FiltersProps {
  categories: LessonCategory[];
  searchValue?: string;
  setSearchValue?: Function;
  selectedCategories: string[];
  setSelectedCategories: Function;
}

const Filters: React.FC<FiltersProps> = ({
  categories,
  searchValue,
  setSearchValue,
  selectedCategories,
  setSelectedCategories,
}) => {
  const [visibleMobileCategories, setVisibleMobileCategories] = useState(false);

  return (
    <div
      className={setSearchValue ? styles.wrapper : clsx(styles.wrapper, styles.singleFiltersButton)}
    >
      <div className={styles.search}>
        {setSearchValue && <Search searchValue={searchValue} setSearchValue={setSearchValue} />}
        <div className={styles.filtersButton} onClick={() => setVisibleMobileCategories(true)}>
          <img alt="filter-icon" src={FiltersIcon} />
          {!!selectedCategories.length && <span className={styles.indicator} />}
        </div>
      </div>
      <div className={styles.desktopCategories}>
        <Categories
          categories={categories}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
      </div>
      <MobileCategories
        visible={visibleMobileCategories}
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        onClose={() => setVisibleMobileCategories(false)}
      />
    </div>
  );
};

export default Filters;
