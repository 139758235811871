import moment from 'moment-timezone';
import SessionProvider from 'providers/SessionProvider';

const userTimezone = SessionProvider.getTimeZoneInfo();

export default class TimezoneHelper {
  public static GetTimezoneList = () => {
    const timezones = moment.tz
      .names()
      .sort(
        (a: string, b: string) =>
          parseInt(moment.tz(a).format('Z').replace(':', ''), 10) -
          parseInt(moment.tz(b).format('Z').replace(':', ''), 10),
      );

    const zoneNames = timezones.map((tz) => {
      const offset = moment.tz(tz).format('Z');
      return `(GMT${offset}) ${tz}`;
    });

    return zoneNames;
  };

  public static GetCurrentUserTimezoneList = () => {
    const timezones = moment.tz.names();

    const zoneNames: string[] = timezones.map((tz) => {
      const offset = moment.tz(tz).format('Z');
      return `(GMT${offset}) ${tz}`;
    });

    const index = zoneNames.findIndex((timezone: string) => timezone.indexOf(userTimezone) >= 0);
    if (index >= 0) return zoneNames[index];
    return zoneNames[0];
  };
}
