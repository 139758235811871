import { Config } from '../../config';
import { Event } from '../entities/events.entity';
import { BaseRepo } from './base.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export class EventsRepository extends BaseRepo {
  // #region Private Properties
  private eventsCollection = this.mongo?.db(Config().RealmDbName as string).collection('events');
  // #endregion

  async getAllEventsByUserId(userId: string): Promise<Event[]> {
    const result = await this.eventsCollection?.aggregate([
      {
        $match: {
          user_id: userId,
        },
      },
    ]);
    return result;
  }

  // #region Public methods
  async createEvent(event: Event) {
    return this.eventsCollection?.insertOne(event);
  }

  async updateEventById(
    eventId: string,
    title: string,
    note: string,
    dateBegin: number,
    dateEnd: number,
    selectedContactList: string[],
  ) {
    const result = await this.eventsCollection?.findOneAndUpdate(
      { _id: eventId },
      {
        $set: {
          title,
          note,
          date_begin: dateBegin,
          date_end: dateEnd,
          date_updated: getUnixMilliseconds(),
          contacts_ids: selectedContactList,
        },
      },
    );
    return result;
  }

  async deleteEventById(eventId: string) {
    const result = await this.eventsCollection?.deleteOne({ _id: eventId });
    return result;
  }

  async getNextTenEventsForUser(orgId: string, userId: string): Promise<Event[]> {
    const currentUnixTime = getUnixMilliseconds();

    const nextTenEvent = await this.eventsCollection?.aggregate([
      {
        $lookup: {
          from: 'organization',
          localField: 'org_id',
          foreignField: '_id',
          as: 'organization',
        },
      },
      {
        $unwind: '$organization',
      },
      {
        $match: {
          org_id: orgId,
          user_id: userId,
          deleted: false,
          date_begin: {
            $gt: currentUnixTime,
          },
        },
      },
      {
        $sort: {
          date_begin: 1,
        },
      },
      {
        $limit: 10,
      },
    ]);

    return nextTenEvent;
  }

  async getEventsCountForUserBetweenDates(
    orgId: string,
    userId: string,
    startDate: number,
    endDate: number,
  ) {
    return this.eventsCollection?.count({
      org_id: orgId,
      user_id: userId,
      date_begin: { $gt: startDate, $lt: endDate },
    });
  }

  // #endregion
}
