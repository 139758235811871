import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEqual } from 'lodash';
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
} from '@mui/material';
import { Location } from 'modules/service/models/service.model';
import { User } from 'data/entities/users.entity';
import { Address } from 'data/entities/userContacts.entity';
import { OrgLocation } from 'data/entities/orgLocations.entity';
import UserService from 'data/services/user.service';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import OrganizationService from 'data/services/organization.service';
import ServicesService from 'data/services/services.service';
import LessonsService from 'data/services/lessons.service';
import { BookingFormatter } from 'modules/book/utils/booking.helpers';
import { AssociateWithAvatar } from 'data/entities/associate.entity';
import { addressStringBuilder } from 'common/utils/response.helper';
import { Card } from '../../../../components/Card';
import styles from './NewsItem.module.scss';
import {
  EventType,
  Lesson,
  LessonClient,
  LessonClientStatus,
} from '../../../../data/entities/lessons.entity';
import SessionProvider from '../../../../providers/SessionProvider';
import Logger from '../../../../middleware/logger.middleware';
import { StaticRoutes } from '../../../../common/utils/routes/StaticRoutes.helper';
import {
  ServiceInviteStatusEnum,
  ServiceInvitedClient,
  Services,
} from '../../../../data/entities/service.entity';
import { Event } from '../../../../data/entities/events.entity';
import { OrgBookingRules } from '../../../book/models/booking.model';
import StaticDictionary from '../../../../common/utils/staticDictionary.helper';
import { AvatarHelper } from '../../../../common/utils/avatar.helpers';

interface INewsItem {
  feedItem: any;
  onOpenEvent: any;
  setEventData: any;
  onCancelInvitation?: (eventType: EventType, id: string, inviteId: string) => void;
  /**
   * NOTE:
   * The following codes are being referenced in other files so they should be kept as they are
   */
  /* eslint-disable */
  deleteEvent: any;
  cancelLesson: any;
  orgBookingRules?: OrgBookingRules;
  /* eslint-enable */
}

const NewsItem: React.FC<INewsItem> = ({
  feedItem,
  onOpenEvent,
  setEventData,
  onCancelInvitation,
}) => {
  const [locationName, setLocationName] = useState<string>('');
  const [location, setLocation] = useState<Address>({});
  const [coaches, setCoaches] = useState<User[]>([]);
  const [staffs, setStaffs] = useState<User[]>([]);
  const [clients, setClients] = useState<User[]>([]);
  const [address, setAddress] = useState<Address>({});
  const [associates, setAssociates] = useState<AssociateWithAvatar[]>([]);
  const [lessonCategories, setLessonCategories] = useState<LessonCategory[]>([]);
  const [locationHeaderName, setLocationHeaderName] = useState<string>('');
  const [dateLabel, setDateLabel] = useState<string>('');
  const userId = SessionProvider.getUserId();
  const userEmail = SessionProvider.getEmail();
  const history = useHistory();
  const orgId = SessionProvider.getOrgId();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleAllParticipants = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAllParticipantsClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const max_avatars = 6;

  const avatarStyle = {
    width: '24px',
    height: '24px',
    fontSize: '12px',
    marginLeft: '-8px',
    border: '2px white solid',
  };

  useEffect(() => {
    if (
      (!(feedItem as Lesson).is_self_taught && feedItem.type === EventType.Lesson) ||
      feedItem.type === EventType.Service
    ) {
      const index = feedItem.locations?.locations.findIndex((item: OrgLocation) => {
        if (feedItem.type === EventType.Lesson)
          return item.location_id === (feedItem as Lesson).location_id;
        if (feedItem.type === EventType.Service) {
          return (
            feedItem?.location_ids &&
            feedItem?.location_ids.length > 0 &&
            item.location_id === feedItem?.location_ids[0]
          );
        }
        return false;
      });

      if (index !== undefined && index >= 0) {
        setAddress(feedItem.locations?.locations[index].address ?? {});
        setLocationHeaderName(feedItem.locations?.locations[index].name ?? '');
      }

      const fetchData = async () => {
        const getCoachNames = await UserService.getNamesByIds(feedItem?.coaches ?? []);
        setCoaches(getCoachNames ?? []);

        const getStaffsNames = await UserService.getNamesByIds(feedItem?.assistance_staff ?? []);
        setStaffs(getStaffsNames ?? []);

        let clientsTemp =
          feedItem?.clients?.filter(
            (clientItem: LessonClient) => clientItem.status === LessonClientStatus.Accepted,
          ) ?? [];
        if (feedItem.type === EventType.Service)
          clientsTemp =
            feedItem?.invited_clients?.filter(
              (clientItem: ServiceInvitedClient) =>
                clientItem.invite_status_enum === ServiceInviteStatusEnum.Accepted,
            ) ?? [];
        const getClientsNames = await UserService.getNamesByIds(
          clientsTemp.map((item: any) => item?.user_id),
        );
        setClients(getClientsNames ?? []);
      };

      fetchData();
    } else if (
      ((feedItem as Lesson).is_self_taught && feedItem.type === EventType.Lesson) ||
      feedItem.type === EventType.Event
    ) {
      const fetchData = async () => {
        const associatesAsync = await UserService.getAssociatesByUserIdOrgIdAsync(userId, orgId);
        setAssociates(associatesAsync ?? []);

        const category = await OrganizationService.getOrgLessonCategoriesById(orgId);
        if (category) {
          const filteredLessonCategories = category.filter((item: LessonCategory) =>
            feedItem?.org_lesson_category_ids?.includes(item._id),
          );
          setLessonCategories(filteredLessonCategories);
        }

        const userDetailArray = await UserService.getProfileDetails(userId, orgId);
        if (userDetailArray && userDetailArray.length > 0) {
          if (userDetailArray[0].practice_locations) {
            const locationIndex = userDetailArray[0].practice_locations.findIndex(
              (locationItem: UserPracticeLocation) => {
                return (
                  locationItem.location_id === (feedItem as Lesson)?.location_id ||
                  locationItem.location_id === (feedItem as Event)?.practice_location_id
                );
              },
            );

            if (locationIndex >= 0) {
              setLocation(userDetailArray[0].practice_locations[locationIndex].address);
              setLocationName(userDetailArray[0].practice_locations[locationIndex].name);
            }
          }
        }
      };

      fetchData();
    } else if (feedItem.type === EventType.LessonInvite) {
      const index = feedItem.locations?.locations.findIndex((item: OrgLocation) => {
        return item.location_id === feedItem?.location_id && !item.deleted;
      });

      if (index !== undefined && index >= 0) {
        setAddress(feedItem.locations?.locations[index].address ?? {});
        setLocationHeaderName(feedItem.locations?.locations[index].name ?? '');
      }

      const fetchData = async () => {
        const getCoachNames = await UserService.getNamesByIds(feedItem?.coaches ?? []);
        setCoaches(getCoachNames ?? []);

        const clientsTemp =
          feedItem?.clients?.filter(
            (clientItem: LessonClient) => clientItem.status === LessonClientStatus.Accepted,
          ) ?? [];
        const getClientsNames = await UserService.getNamesByIds(
          clientsTemp.map((item: any) => item?.user_id),
        );

        setClients(getClientsNames ?? []);
      };

      fetchData();
    }

    if (feedItem.type === EventType.Service && feedItem?.scheduled_dates?.length > 0) {
      const dateLabelServiceString = BookingFormatter.formatDateStartAndEnd(
        feedItem?.scheduled_dates[0].date_start,
        feedItem?.scheduled_dates[0].date_end,
        SessionProvider.getTimeZoneInfo(),
      );

      setDateLabel(dateLabelServiceString);
    } else if (feedItem.type !== EventType.Service) {
      const dateLabelString = BookingFormatter.formatDateStartAndEnd(
        feedItem?.date_start ? feedItem?.date_start : feedItem?.date_begin,
        feedItem?.date_end,
        SessionProvider.getTimeZoneInfo(),
      );

      setDateLabel(dateLabelString);
    } else {
      setDateLabel('');
    }
  }, [feedItem]);

  useEffect(() => {
    feedItem.org_location?.locations.forEach((locationItem: Location) => {
      if (
        feedItem.organization?.addresses?.length &&
        isEqual(locationItem.address, feedItem.organization?.addresses[0])
      ) {
        setLocationName(locationItem.name);
      }
    });
  }, [feedItem]);

  const onOpenActivityFeed = () => {
    switch (feedItem.type) {
      case EventType.Lesson:
        history.push(`/org/${feedItem.org_id}/lesson/${feedItem._id}`);
        break;
      case EventType.Event:
        onOpenEvent(true);
        setEventData(feedItem);
        break;
      case EventType.Service:
        {
          const service = feedItem as Services;
          const serviceInvitedClient = service.invited_clients?.find(
            (invitedClient: ServiceInvitedClient) =>
              invitedClient.user_id === userId || invitedClient.email === userEmail,
          );
          const link = `${StaticRoutes.Org}/${feedItem.org_id}/service/${feedItem._id}?${StaticDictionary.UrlParameters.Booking.InvitationId}=${serviceInvitedClient?.invite_id}`;
          history.push(link);
        }
        break;
      case EventType.LessonInvite:
        {
          const lessonInvite = feedItem as Lesson;
          const lessonClient = lessonInvite.clients.find(
            (lessonInvitedClient: LessonClient) => lessonInvitedClient.user_id === userId,
          );
          const link = `${StaticRoutes.Org}/${lessonInvite.org_id}/lesson/${lessonInvite._id}/invite?${StaticDictionary.UrlParameters.Booking.InvitationId}=${lessonClient?.invite_id}`;
          history.push(link);
        }
        break;
      default:
        if (Logger.isDevEnvironment) {
          console.error('feedItem.type not supported', feedItem.type);
        }
        break;
    }
  };

  const formatTitle = (feedItemParam: any) => {
    let titleText = feedItemParam.title;
    if (feedItemParam.type === EventType.Service) {
      if (ServicesService.isOverdue(feedItemParam)) titleText += ' OVERDUE';
      if ((feedItemParam as Services).is_fully_booked && onCancelInvitation)
        titleText += ' FULLY BOOKED';
    }
    if (feedItemParam.type === EventType.Lesson || feedItemParam.type === EventType.LessonInvite) {
      if (LessonsService.isOverdue(feedItemParam)) titleText += ' OVERDUE';
      if ((feedItemParam as Services).is_fully_booked && onCancelInvitation)
        titleText += ' FULLY BOOKED';
    }

    return titleText;
  };

  const cancelInvitation = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.stopPropagation();
    if (onCancelInvitation) {
      switch (feedItem.type) {
        case EventType.Service:
          {
            const service = feedItem as Services;
            const serviceInvitedClient = service.invited_clients?.find(
              (invitedClient: ServiceInvitedClient) =>
                invitedClient.user_id === userId || invitedClient.email === userEmail,
            );
            onCancelInvitation(feedItem.type, feedItem._id, serviceInvitedClient?.invite_id || '');
          }
          break;
        case EventType.LessonInvite:
          {
            const lessonInvite = feedItem as Lesson;
            const lessonClient = lessonInvite.clients.find(
              (lessonInvitedClient: LessonClient) => lessonInvitedClient.user_id === userId,
            );
            onCancelInvitation(feedItem.type, lessonInvite._id, lessonClient?.invite_id || '');
          }
          break;
        default:
          if (Logger.isDevEnvironment) {
            console.error('feedItem.type not supported', feedItem.type);
          }
          break;
      }
    }
  };

  return (
    <Card onClick={onOpenActivityFeed} contentPadding={3}>
      <div className={styles.title}>
        <div className={styles.shareCoach}>
          <div>{feedItem.organization.org_name}</div>
          {onCancelInvitation && (
            <div>
              <div
                onClick={cancelInvitation}
                style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginBottom: '10px',
                  backgroundColor: '#dddddd',
                  padding: '4px 8px',
                  borderRadius: '4px',
                  fontSize: '10px',
                  fontWeight: 'bold',
                }}
              >
                Hide
              </div>
            </div>
          )}
        </div>
        <div className={styles.shareTitle}>{formatTitle(feedItem)}</div>
        <div>
          {(!feedItem.is_self_taught && feedItem.type === EventType.Lesson) ||
          feedItem.type === EventType.Service
            ? coaches.map((item: User) => (
                <div className={styles.shareCoach} key={item._id}>
                  {item.first_name} {item.last_name}
                </div>
              ))
            : ''}
        </div>
        {(!feedItem.is_self_taught && feedItem.type === EventType.Lesson) ||
        feedItem.type === EventType.Service
          ? staffs.map((item: User) => (
              <div className={styles.shareCoach} key={item._id}>
                {item.first_name} {item.last_name}
              </div>
            ))
          : ''}
        {(feedItem.share_practice_lesson_with_coach && feedItem.is_self_taught) ||
        feedItem.type === EventType.Event ? (
          <div className={styles.shareCoach}>Shared with {feedItem.organization?.org_name}</div>
        ) : (
          ''
        )}
      </div>
      {feedItem.is_self_taught || feedItem.type === EventType.Event ? (
        <>
          <div className={styles.locationName}>{locationName}</div>
          <div className={styles.address}>{addressStringBuilder(location)}</div>
        </>
      ) : (
        <>
          <div className={styles.locationName}>{locationHeaderName}</div>
          <div className={styles.address}>{addressStringBuilder(address)}</div>
        </>
      )}
      <div className={styles.categories}>
        {lessonCategories?.map(({ _id, name }) => (
          <span key={_id} className={styles.category}>
            {name}
          </span>
        ))}

        {((!feedItem.is_self_taught && feedItem.type === EventType.Lesson) ||
          feedItem.type === EventType.Service ||
          feedItem.type === EventType.LessonInvite) &&
          !onCancelInvitation && (
            <div className={styles.avatarWrapper}>
              {clients.map((client: User, index: number) => {
                const client_avatar_url_valid =
                  client.detail?.avatar_file &&
                  client.detail?.avatar_file.length > 0 &&
                  client.detail?.avatar_file[0].file_url;

                if (index === max_avatars) {
                  return (
                    <Avatar
                      onMouseEnter={handleAllParticipants}
                      sx={avatarStyle}
                      key={client.detail?._id}
                    >
                      {AvatarHelper.stringToAvatar({
                        firstName: '+',
                        lastName: `${clients.length - max_avatars}`,
                      })}
                    </Avatar>
                  );
                }
                if (index < max_avatars)
                  if (
                    client_avatar_url_valid &&
                    client.detail?.avatar_file &&
                    client.detail?.avatar_file?.length > 0
                  )
                    return (
                      <Tooltip key={index} title={`${client.first_name} ${client.last_name}`}>
                        <Avatar
                          alt={`${client.first_name} ${client.last_name}`}
                          src={client.detail?.avatar_file[0].file_url ?? ''}
                          sx={avatarStyle}
                        />
                      </Tooltip>
                    );
                  else
                    return (
                      <Tooltip key={client._id} title={`${client.first_name} ${client.last_name}`}>
                        <Avatar sx={avatarStyle}>
                          {AvatarHelper.stringToAvatar({
                            firstName: client.first_name,
                            lastName: client.last_name,
                          })}
                        </Avatar>
                      </Tooltip>
                    );

                return '';
              })}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleAllParticipantsClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <List dense onMouseLeave={handleAllParticipantsClose}>
                  {clients.map((user: User) => {
                    return (
                      <ListItem key={user._id}>
                        <ListItemIcon>
                          {user.detail?.avatar_file && user.detail?.avatar_file.length > 0 ? (
                            <Avatar
                              alt={`${user.first_name} ${user.last_name}`}
                              src={user.detail?.avatar_file[0].file_url ?? ''}
                              sx={{
                                width: '32px',
                                height: '32px',
                              }}
                            />
                          ) : (
                            <Avatar sx={{ width: '32px', height: '32px' }}>
                              {AvatarHelper.stringToAvatar({
                                firstName: user.first_name,
                                lastName: user.last_name,
                              })}
                            </Avatar>
                          )}
                        </ListItemIcon>
                        <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                      </ListItem>
                    );
                  })}
                </List>
              </Popover>
            </div>
          )}

        {((feedItem.is_self_taught && feedItem.type === EventType.Lesson) ||
          feedItem.type === EventType.Service ||
          feedItem.type === EventType.LessonInvite) && (
          <div className={styles.avatarWrapper}>
            {associates.map((client: AssociateWithAvatar, index: number) => {
              if (index === max_avatars) {
                return (
                  <Avatar key={index} onMouseEnter={handleAllParticipants} sx={avatarStyle}>
                    {AvatarHelper.stringToAvatar({
                      firstName: '+',
                      lastName: `${associates.length - max_avatars}`,
                    })}
                  </Avatar>
                );
              }

              if (index < max_avatars) {
                if (client.avatar.length > 0) {
                  return (
                    <Tooltip
                      key={index}
                      title={`${client.user.first_name} ${client.user.last_name}`}
                    >
                      <Avatar
                        alt={`${client.user.first_name} ${client.user.last_name}`}
                        src={client.avatar[0].file_url}
                        sx={avatarStyle}
                      />
                    </Tooltip>
                  );
                }

                return (
                  <Tooltip key={index} title={`${client.user.first_name} ${client.user.last_name}`}>
                    <Avatar key={client._id} sx={avatarStyle}>
                      {AvatarHelper.stringToAvatar({
                        firstName: client.user.first_name,
                        lastName: client.user.last_name,
                      })}
                    </Avatar>
                  </Tooltip>
                );
              }

              return null;
            })}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleAllParticipantsClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <List dense onMouseLeave={handleAllParticipantsClose}>
                {associates.map((user: AssociateWithAvatar) => {
                  return (
                    <ListItem key={user._id}>
                      <ListItemIcon>
                        {user.avatar.length > 0 ? (
                          <Avatar
                            alt={`${user.user.first_name} ${user.user.last_name}`}
                            src={user.avatar[0].file_url}
                            sx={{
                              width: '32px',
                              height: '32px',
                            }}
                          />
                        ) : (
                          <Avatar sx={{ width: '32px', height: '32px' }}>
                            {AvatarHelper.stringToAvatar({
                              firstName: user.user.first_name,
                              lastName: user.user.last_name,
                            })}
                          </Avatar>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={`${user.user.first_name} ${user.user.last_name}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          </div>
        )}
      </div>

      {feedItem.type === EventType.Event ||
      feedItem.type === EventType.Lesson ||
      feedItem.type === EventType.LessonInvite ||
      feedItem.type === EventType.Service ? (
        <div className={styles.time}>{dateLabel}</div>
      ) : (
        ''
      )}
      {feedItem.type === EventType.Service && feedItem.invite_message !== '' ? (
        <div className={styles.subtitle}>{feedItem.invite_message}</div>
      ) : (
        ''
      )}
    </Card>
  );
};
export default NewsItem;
