import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, PaymentMethod } from '@stripe/stripe-js';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { BrandThemeContext } from 'common/context';
import { Grid } from '../../../../../../components/Grid';
import { Button } from '../../../../../../components/Button/MuiButtons/Button';
import UserService from '../../../../../../data/services/user.service';
import { AxiosErrorHandler } from '../../../../../../common/utils/errorHandler.helpers';
import { CardPaymentMethodInfo, CardPaymentMethodInfoProps } from './CardPaymentMethodInfo';

export interface CardPaymentProps {
  paymentMethod: CardPaymentMethodInfoProps | null;
  onSuccess: (addedPaymentMethod: PaymentMethod) => void;
}

export const CardPayment = ({ paymentMethod, onSuccess }: CardPaymentProps) => {
  const theme = useContext(BrandThemeContext);
  const stripe = useStripe();
  const stripeElements = useElements();
  const stripeCardElement = stripeElements ? stripeElements.getElement('card') : null;

  // eslint-disable-next-line
  const [isReady, setIsReady] = React.useState(false);
  const [isComplete, setIsComplete] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isEmpty, setIsEmpty] = React.useState(true);

  const handleChange = (e: StripeCardElementChangeEvent) => {
    setIsComplete(e.complete);
    setIsEmpty(e.empty);
  };

  const handleAddPaymentMethod = async () => {
    try {
      if (!stripe || !stripeElements || !stripeCardElement) {
        setErrorMessage('Cannot load stripe module');
        return;
      }

      /** In first iteration ticket, let's handle as if the user is authenticated first */
      const user = await UserService.getCurrentUserCustomData();
      if (!user) {
        setErrorMessage('Cannot fetch user details from database');
        return;
      }

      setIsProcessing(true);

      const userFullname = `${user?.first_name} ${user?.last_name}`;
      const { error, paymentMethod: newPaymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: stripeCardElement,
        billing_details: {
          name: userFullname,
        },
      });

      setIsProcessing(false);

      if (error || !newPaymentMethod) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)

        toast.error(
          'We couldn’t validate this card. Please check the number, expiry and security code, or try another card.',
        );

        return;
      }

      onSuccess(newPaymentMethod);
    } catch (err: any) {
      const error = {
        response: err.response,
        request: err.request,
        message: err.message,
      };
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage(error);
      setErrorMessage(compiledErrorMessage);
    }

    setIsProcessing(false);
  };

  const getButtonText = (): string => {
    if (paymentMethod) return 'Card added';
    return isComplete ? 'Add card' : 'Add card details';
  };

  /** Clear error messages */
  React.useEffect(() => {
    setTimeout(() => {
      setErrorMessage('');
    }, 5000);
  }, [errorMessage]);

  return (
    <Card id="stripe-payment" sx={{ bgcolor: theme.colors.jungleGreen.opacity_25 }} elevation={1}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Box height={37} paddingY={1} fontWeight="bold">
              Payment Info
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              height={37}
              paddingY={1}
              paddingX={1.5}
              bgcolor={theme.colors.white}
              border={1}
              borderColor={theme.colors.black.opacity_10}
              borderRadius={1}
            >
              {paymentMethod && <CardPaymentMethodInfo card={paymentMethod.card} />}
              {!paymentMethod && (
                <CardElement onReady={() => setIsReady(true)} onChange={handleChange} />
              )}
            </Box>
            {errorMessage && (
              <Box color={theme.colors.red.opacity_100} marginTop={1}>
                <strong>Error :</strong> {errorMessage}
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              sx={{
                color: `${theme.colors.white} !important`,
                bgcolor:
                  !isEmpty || paymentMethod
                    ? `${theme.colors.black.opacity_100} !important`
                    : `${theme.colors.black.opacity_25} !important`,
                '&:hover': {
                  bgcolor: `${theme.colors.black.opacity_50} !important`,
                },
              }}
              fullWidth
              disabled={(isEmpty || !!paymentMethod) && !isProcessing}
              variant="contained"
              label={getButtonText()}
              onClick={handleAddPaymentMethod}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
