/* eslint-disable no-underscore-dangle */
import { v4 as uuidv4 } from 'uuid';
import { Storage } from 'aws-amplify';
import { AttachmentSectionIndex } from 'modules/attachment/models/attachment';
import { Config } from '../../config';
import RealmBase from '../base/realm.base';
import { Attachment, AttachmentModel } from '../entities/attachment.entity';
import RealmRepositories from '../base/realm.repo';
import Logger from '../../middleware/logger.middleware';
import { File } from '../entities/file.entity';
import { Favorite } from '../entities/favorites.entity';
import isAuthenticated from '../../common/utils/isAuthenticated';
import reAuthenticate from '../../common/utils/reAuthenticated';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export default class AttachmentsService extends RealmBase {
  static async addNoteAttachmentToLesson(
    lessonId: string,
    userId: string,
    orgId: string,
    noteContent: string,
    sectionIndex: number,
  ): Promise<AttachmentModel | undefined> {
    try {
      const note = {
        name: 'note',
        note_description: noteContent,
      };

      const attachmentId = uuidv4();

      const attachmentData: Attachment = {
        _id: attachmentId,
        org_id: orgId,
        user_id: userId,
        attachment_type: JSON.stringify(note),
        date_created: getUnixMilliseconds(),
        date_updated: getUnixMilliseconds(),
        deleted: false,
      };

      const result = await RealmRepositories.Attachments.insertAttachment(attachmentData);
      if (!result) {
        console.error('There was an error inserting the attachment to its collection.');
        return undefined;
      }

      const lesson = await RealmRepositories.Lessons.getLessonById(lessonId);
      if (!lesson) {
        console.error('Could not find lesson with id.');
        return undefined;
      }

      if (lesson.client_uploads === null) {
        await RealmRepositories.Lessons.addLessonAttachments(lessonId, attachmentId, sectionIndex);
      } else {
        await RealmRepositories.Lessons.insertLessonAttachments(
          lessonId,
          attachmentId,
          sectionIndex,
        );
      }

      const attachment: AttachmentModel = {
        lessonId,
        isFavorite: false,
        favId: null,
        lessonCategoriesInFavorite: [],
        attachmentId,
        noteContent,
        sectionIndex,
        isFileAttachment: false,
        userId,
      };

      return attachment;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
      return undefined;
    }
  }

  static async addFileAttachmentForLesson(
    fileObject: File,
    lessonId: string,
    sectionIndex: number,
  ) {
    try {
      const attachmentType = {
        name: 'file',
        file_id: fileObject._id,
      };

      const attachmentId = uuidv4();
      const attachmentData: Attachment = {
        _id: attachmentId,
        org_id: fileObject.org_id,
        user_id: fileObject.user_id,
        attachment_type: JSON.stringify(attachmentType),
        date_created: getUnixMilliseconds(),
        date_updated: getUnixMilliseconds(),
        deleted: false,
      };

      await RealmRepositories.Attachments.insertAttachment(attachmentData);
      await RealmRepositories.Files.insertFileAsync(fileObject);

      const currentLesson = await RealmRepositories.Lessons.getLessonById(lessonId);

      if (!currentLesson) {
        return undefined;
      }

      if (currentLesson.client_uploads === null) {
        await RealmRepositories.Lessons.addLessonAttachments(lessonId, attachmentId, sectionIndex);
      } else {
        await RealmRepositories.Lessons.insertLessonAttachments(
          lessonId,
          attachmentId,
          sectionIndex,
        );
      }

      const attachment: AttachmentModel = {
        lessonId,
        isFavorite: false,
        favId: null,
        lessonCategoriesInFavorite: [],
        attachmentId,
        isFileAttachment: true,
        fileUrl: (Config().AWSCloudFrontUrl as string) + fileObject.file_name,
        thumbnailUrl: this.getThumbnailDisplayUrlFromFileUrl(fileObject.thumbnail_url as string),
        fileExtension: fileObject.extension,
        contentType: fileObject.content_type,
        sectionIndex,
        userId: fileObject.user_id,
      };

      return attachment;
    } catch (error) {
      return undefined;
    }
  }

  static async updateNoteAttachmentById(noteId: string, noteContent: string) {
    try {
      const note = {
        name: 'note',
        note_description: noteContent,
      };

      const stringifiedNote = JSON.stringify(note);
      await RealmRepositories.Attachments.updateAttachmentTypeById(noteId, stringifiedNote);
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  public static async getAllUserUploadsForLesson(lessonId: string, userId: string, orgId: string) {
    try {
      const currentLesson = await RealmRepositories.Lessons.getLessonById(lessonId);
      if (!currentLesson) {
        console.error('Could not find lesson with id');
        return undefined;
      }

      const favorites = await RealmRepositories.Favorites.getAllUserFavouritesByOrg(userId, orgId);
      let attachments: AttachmentModel[] = [];

      if (currentLesson && favorites) {
        // client uploads
        const allClientUploads: string[] = currentLesson?.client_uploads || [];
        const allClientUploadResult = await this.getUploadsForLessons(
          allClientUploads,
          favorites,
          lessonId,
          AttachmentSectionIndex.ClientUploads,
        );
        // solution uploads
        const allSolutionUploads: string[] = currentLesson?.solution_uploads || [];
        const allSolutionUploadResult = await this.getUploadsForLessons(
          allSolutionUploads,
          favorites,
          lessonId,
          AttachmentSectionIndex.TheSolution,
        );
        // reason uploads
        const allReasonUploads: string[] = currentLesson?.reason_uploads || [];
        const allReasonUploadResult = await this.getUploadsForLessons(
          allReasonUploads,
          favorites,
          lessonId,
          AttachmentSectionIndex.ReasonOfLesson,
        );

        attachments = [
          ...allClientUploadResult,
          ...allSolutionUploadResult,
          ...allReasonUploadResult,
        ];
      }

      return attachments;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.log(error);
      }
      return undefined;
    }
  }

  static async uploadFileAttachment(
    id: string,
    userId: string,
    orgId: string,
    component: string,
    file: any,
    onUploaded: Function,
    onUploading: Function,
    typeForThumbnails: string,
  ) {
    try {
      const isUserAuthenticated = await isAuthenticated();
      if (isUserAuthenticated) {
        this.uploadFile(
          id,
          userId,
          orgId,
          component,
          file,
          onUploaded,
          onUploading,
          typeForThumbnails,
        );
      } else {
        const { currentUser } = AttachmentsService.app;
        if (currentUser) {
          await reAuthenticate({
            id: currentUser?.customData._id as string,
            accessToken: currentUser?.accessToken as string,
            firstName: currentUser?.customData.firstName as string,
            email: currentUser?.customData.email as string,
          });
        }

        this.uploadFile(
          id,
          userId,
          orgId,
          component,
          file,
          onUploaded,
          onUploading,
          typeForThumbnails,
        );
      }
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error, 'error from uploading file attachment');
      }
    }
  }

  static async removeLessonUploads(lessonId: string, attachmentId: string, sectionIndex: number) {
    try {
      await RealmRepositories.Attachments.removeClientLessonUploadById(
        lessonId,
        attachmentId,
        sectionIndex,
      );
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async deleteClientUpload(
    lessonId: string,
    attachmentId: string,
    sectionIndex: number,
    favId: string | null,
  ) {
    try {
      await RealmRepositories.Attachments.removeClientLessonUploadById(
        lessonId,
        attachmentId,
        sectionIndex,
      );

      const attachmentResult = await RealmRepositories.Attachments.getAttachmentById(attachmentId);

      if (!attachmentResult) {
        if (Logger.isDevEnvironment) {
          console.error('Could not find attachment with id.');
        }
        return;
      }

      const attachmentType = JSON.parse(attachmentResult.attachment_type as string);
      if (favId) {
        await RealmRepositories.Favorites.deleteFavoriteById(favId);
      }
      if (Object.values(attachmentType).includes('note')) {
        await RealmRepositories.Attachments.deleteAttachmentById(attachmentId);
      } else if (Object.values(attachmentType).includes('file')) {
        const index = Object.keys(attachmentType).indexOf('file_id');
        const fileId = Object.values(attachmentType)[index];
        const currentFile = await RealmRepositories.Files.getFileByIdAsync(fileId as string);
        if (currentFile && currentFile?.file_name) {
          const fileName = currentFile?.file_name;
          const splitFileName = fileName.split('/');
          const id = splitFileName[splitFileName.length - 1];
          const fileType = currentFile?.content_type?.split('/');
          const type = fileType[0];

          await Storage.remove(`${type}s/${id}`, {
            level: 'private',
          });
          await RealmRepositories.Files.deleteFileByIdAsync(fileId as string);
          await RealmRepositories.Attachments.deleteAttachmentById(attachmentId);

          if (type === 'video' && currentFile?.thumbnail_url) {
            const thumbnailUrl = currentFile.thumbnail_url;
            const splitThumbnailName = thumbnailUrl.split('/');
            const thumbnailId = splitThumbnailName[splitThumbnailName.length - 1];
            await Storage.remove(`thumbnails/${thumbnailId}`, {
              level: 'private',
            });
          }
        }
      }
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  }

  static async updateAttachments(lessonId: string, attachmentIds: string[], sectionIndex: number) {
    try {
      RealmRepositories.Lessons.updateLessonAttachments(lessonId, attachmentIds, sectionIndex);
    } catch (error) {
      console.error(error, 'error from updating the attachment ids');
    }
  }

  static async getUploadsForLessons(
    uploadIds: string[],
    favorites: Favorite[],
    lessonId: string,
    sectionIndex: number,
  ) {
    const uploadAttachments = await RealmRepositories.Attachments.getAttachmentsByIds(uploadIds);
    const attachments: AttachmentModel[] = [];
    // eslint-disable-next-line no-restricted-syntax
    const attachmentsPromises = (uploadAttachments || []).map(async (attachment) => {
      if (attachment != null) {
        const attachmentType = attachment?.attachment_type;
        const attachmentObj = JSON.parse(attachmentType);
        let favId;
        let lessonCategoryIds;
        let favNoteContent;
        favorites?.forEach((fav: Favorite) => {
          const favType = JSON.parse(fav.favorite_type);

          if (Object.keys(favType).includes('attachment_id')) {
            const index = Object.keys(favType).indexOf('attachment_id');
            const favNote = Object.keys(favType).indexOf('favorite_note');
            const lessonCatIdx = Object.keys(favType).indexOf('lesson_category_ids');
            if (index > -1) {
              const currentAttachmentId = Object.values(favType)[index];
              const LessonCategories = Object.values(favType)[lessonCatIdx];
              const favoNote = Object.values(favType)[favNote];
              if (currentAttachmentId === attachment._id) {
                favId = fav._id;
                lessonCategoryIds = LessonCategories;
                favNoteContent = favoNote;
              }
            }
          }
        });
        let fileItem;
        let title = '';
        if ('title' in attachmentObj) {
          const titleIndex = Object.keys(attachmentObj).indexOf('title');
          title = Object.values(attachmentObj)[titleIndex] as string;
        }

        if (Object.values(attachmentObj).includes('file')) {
          const index = Object.keys(attachmentObj).indexOf('file_id');
          const fileId = Object.values(attachmentObj)[index] as string;

          fileItem = await RealmRepositories.Files.getFileByIdAsync(fileId);
          if (fileItem) {
            attachments.push({
              lessonId,
              isFavorite: (lessonCategoryIds || []).length !== 0,
              favId: favId || null,
              title,
              favNote: favNoteContent,
              lessonCategoriesInFavorite: lessonCategoryIds || [],
              attachmentId: attachment._id,
              isFileAttachment: true,
              contentType: fileItem.content_type,
              fileUrl: Config().AWSCloudFrontUrl + fileItem.file_name,
              thumbnailUrl: this.getThumbnailDisplayUrlFromFileUrl(
                fileItem.thumbnail_url as string,
              ),
              fileExtension: fileItem.extension,
              sectionIndex,
              userId: attachment.user_id,
            });
          }
        }
        if (Object.values(attachmentObj).includes('note')) {
          const index = Object.keys(attachmentObj).indexOf('note_description');

          attachments.push({
            lessonId,
            isFavorite: (lessonCategoryIds || []).length !== 0,
            favId: favId || null,
            title,
            favNote: favNoteContent,
            lessonCategoriesInFavorite: lessonCategoryIds || [],
            attachmentId: attachment._id,
            noteContent: Object.values(attachmentObj)[index] as string,
            isFileAttachment: false,
            sectionIndex,
            userId: attachment.user_id,
          });
        }
      }
    });

    await Promise.all(attachmentsPromises);
    return attachments;
  }

  // #region Private Functions

  private static getThumbnailDisplayUrlFromFileUrl(fileUrl: string): string {
    if (fileUrl) {
      const splitOriginalUrl = fileUrl.split('/');
      if (fileUrl.includes('https')) {
        const sliceUrl = splitOriginalUrl.slice(3, splitOriginalUrl.length).join('/');
        return Config().AWSCloudFrontUrl + sliceUrl;
      }
      return Config().AWSCloudFrontUrl + fileUrl;
    }

    return '';
  }

  private static async uploadFile(
    id: string,
    userId: string,
    orgId: string,
    component: string,
    file: any,
    onUploaded: Function,
    onUploading: Function,
    typeForThumbnails: string,
  ) {
    const dirId = uuidv4();
    await AttachmentsService.app.currentUser?.refreshCustomData();
    const cognitoId = AttachmentsService.app.currentUser?.customData?.cognito_id;
    const fileId = id;
    let fileName = '';
    if (typeForThumbnails) {
      fileName = `thumbnails/${fileId}`;
    } else if (file.type?.startsWith('image/')) {
      fileName = `images/${fileId}`;
    } else if (file.type?.startsWith('video/')) {
      fileName = `videos/${fileId}`;
    } else {
      fileName = `files/${fileId}`;
    }

    const type = typeForThumbnails || file.type.split('/')[0];
    Storage.put(fileName, file, {
      resumable: true,
      level: 'private',
      contentType: typeForThumbnails || file.type,
      metadata: {
        filename: typeForThumbnails
          ? 'Video thumbnail'
          : file.name.replace(/[^\x20-\x7E]/g, (char: string) => {
              return encodeURIComponent(char);
            }),
        filetype: typeForThumbnails || file.type,
        orgID: orgId,
        userID: userId,
        dirID: type === 'video' ? dirId : '',
        contentType: typeForThumbnails || file.type,
        component,
      },

      progressCallback: (progress: any) => {
        const percentageRemaining = progress.loaded / progress.total;
        onUploading(id, percentageRemaining * 100, true);
      },

      errorCallback: (error: any) => {
        console.error(error, 'error');
        throw new Error(error);
      },

      completeCallback: (event: any) => {
        const url = event.key;
        onUploaded(id, url, cognitoId, type);
      },
    });
  }

  // #endregion
}
