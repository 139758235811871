import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bookingSlice, BookingStep } from 'redux/slices';
import { StaticRoutes } from '../../../../../common/utils/routes/StaticRoutes.helper';
import { useOrgId } from '../../../../../common/hooks';

export const useBooking = () => {
  const { orgId } = useOrgId();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleResetBooking = (redirectToBookingUrl = false) => {
    localStorage.removeItem('persist:booking');
    dispatch(bookingSlice.actions.resetBooking());
    dispatch(bookingSlice.actions.changeStep(BookingStep.FindCoachLocation));

    if (redirectToBookingUrl) {
      const url = `${StaticRoutes.OrgRoutes.Id.replace(':orgId', orgId)}${StaticRoutes.Book}`;
      history.push(url);
    }
  };

  return { handleResetBooking };
};
