import { useCallback, useEffect, useRef, useState } from 'react';
import { filter, find } from 'lodash';
import { useParams } from 'react-router-dom';
import LessonsService from '../../../../data/services/lessons.service';
import { Lesson, LessonClientStatus } from '../../../../data/entities/lessons.entity';
import { Conversions } from '../../../../common/utils/conversions.helper';
import Logger from '../../../../middleware/logger.middleware';
import RealmRepositories from '../../../../data/base/realm.repo';
import ServicesService from '../../../../data/services/services.service';
import OrganizationHelper from '../../../../common/utils/organization.helper';
import {
  CurrencyData,
  DefaultCurrencyData,
  getByIsoCode,
} from '../../../../common/utils/currencies.helper';
import { LessonCancellation } from '../../../../common/utils/lessons.helper';
import SessionProvider from '../../../../providers/SessionProvider';
import { LessonType } from '../../../../data/common/models/lessonType.model';

interface UseCancelLessonViewProps {
  lesson: Lesson;
  lessonCancellation: LessonCancellation;
  isVisible: boolean;
}

export const useCancelLessonViewHooks = (props: UseCancelLessonViewProps) => {
  const { lesson, lessonCancellation, isVisible } = props;

  const { orgId } = useParams<Record<string, string>>();
  const userId = SessionProvider.getUserId();
  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    isFetchingService: false,
    isFetchingCurrency: false,
  });
  const [reason, setReason] = useState('');
  const [lessonCancellationInfo, setLessonCancellationInfo] = useState<string>('');
  const [showNoCreditMessage, setShowNoCreditMessage] = useState<boolean>(false);
  const [lessonsCount, setLessonsCount] = useState<number>(1);
  const [isAfterPolicyDuration, setIsAfterPolicyDuration] = useState<boolean>(false);
  const [isBeforePolicyDuration, setIsBeforePolicyDuration] = useState<boolean>(false);
  const [currencyData, setCurrencyData] = useState<CurrencyData>(DefaultCurrencyData);
  const [lessonPrice, setLessonPrice] = useState<string>();
  const [lessonType, setLessonType] = useState<LessonType>();

  const isSelfTaught = lessonCancellation === LessonCancellation.SelfTaught;
  const isAfter = lessonCancellation === LessonCancellation.AfterPolicyDuration;
  const isBefore = lessonCancellation === LessonCancellation.BeforePolicyDuration;

  const lessonClient = lesson.clients.find((x) => x.user_id === userId);
  const isPaymentOutsideApp = lessonClient?.is_payment_in_app === false;

  const getServiceFromLessonLogicCompleted = useRef(false);

  useEffect(() => {
    const currencyType = lesson.currency_type;
    const currencyDataResult = getByIsoCode(currencyType === undefined ? '' : currencyType);
    if (currencyDataResult) {
      setLessonPrice(Conversions.toCurrencyString(currencyDataResult, lesson.price_in_cents));
      setCurrencyData(currencyDataResult);
    } else {
      setLessonPrice(Conversions.toCurrencyString(DefaultCurrencyData, lesson.price_in_cents));
    }

    const getServiceFromLesson = async () => {
      setLoadingState({ ...loadingState, isFetchingService: true });
      const result = await RealmRepositories.Services.getById(lesson.service_id);
      setLessonType({
        ...lessonType,
        isPrepaid: ServicesService.isPrepaid(result),
        isPackage: ServicesService.isPackage(result),
      });
      setLoadingState({ ...loadingState, isFetchingService: false });
    };

    if (!getServiceFromLessonLogicCompleted.current) {
      getServiceFromLesson();
      getServiceFromLessonLogicCompleted.current = true;
    }

    return () => {
      getServiceFromLessonLogicCompleted.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getServiceFromLessonLogicCompleted]);

  useEffect(() => {
    const doOrgAndCurrencyLogic = async () => {
      try {
        setLoadingState({ ...loadingState, isFetchingCurrency: true });
        let currencyType = lesson.currency_type;
        if (!currencyType) {
          const orgResult = await OrganizationHelper.GetOrgDetails(orgId);
          if (!orgResult) {
            return;
          }
          currencyType = orgResult.currency_type;
        }

        const currencyDataResult = getByIsoCode(currencyType);
        if (currencyDataResult) {
          setLessonPrice(Conversions.toCurrencyString(currencyDataResult, lesson.price_in_cents));
          setCurrencyData(currencyDataResult);
        } else {
          setLessonPrice(Conversions.toCurrencyString(DefaultCurrencyData, lesson.price_in_cents));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingState({ ...loadingState, isFetchingCurrency: false });
      }
    };
    if (!currencyData) {
      doOrgAndCurrencyLogic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyData]);

  const doPackageInfoLogicAsync = useCallback(async () => {
    try {
      const lessons: Lesson[] | undefined = await LessonsService.getAllLessonsByPackageId(
        lesson.package_id!,
      );

      if (!lessons) {
        if (Logger.isDevEnvironment) {
          console.log('Could not find lessons with packageId: ', lesson.package_id!);
        }
        return;
      }

      const packageLessons = filter(lessons, (lessonItem) => {
        return lessonItem.cancellation === undefined || lessonItem.cancellation === null;
      });

      setLessonsCount(packageLessons.length);

      let hasCancelledLesson = false;
      // TODO: This can be moved into a service/helper
      for (let i = 0; i < packageLessons.length; i += 1) {
        const lessonItem = packageLessons[i];
        const userHasCancelledLesson = find(lessonItem.clients, (lessonClientValue) => {
          return (
            lessonClientValue.user_id === userId &&
            lessonClientValue.status === LessonClientStatus.Cancelled
          );
        });

        if (userHasCancelledLesson) {
          hasCancelledLesson = true;
          break;
        }
      }

      const remainingPackageLessons = filter(packageLessons, (lessonItem) => {
        return (
          find(
            lessonItem.clients,
            (client) => client.user_id === userId && client.status === LessonClientStatus.Accepted,
          ) !== undefined
        );
      });

      let totalPackagePriceInCents = lesson.price_in_cents * packageLessons.length;
      setLessonPrice(Conversions.toCurrencyString(currencyData, totalPackagePriceInCents));
      if (hasCancelledLesson) {
        totalPackagePriceInCents = lesson.price_in_cents * remainingPackageLessons.length;
      }

      let message = '';
      let showAlertMessage = false;
      const formattedPackagePrice = Conversions.toCurrencyString(
        currencyData,
        totalPackagePriceInCents,
      );
      const noPackageCreditMessage = `By canceling this package/lesson, you confirm you won't receive ${formattedPackagePrice}/${lessonPrice} TotalCoach credit.`;

      if (hasCancelledLesson) {
        const totalCancelledLessons = packageLessons.length - remainingPackageLessons.length;
        message += `You have already canceled ${totalCancelledLessons} lesson(s) in this package. ${noPackageCreditMessage}`;
        showAlertMessage = true;
      } else if (isPaymentOutsideApp) {
        message += `You are canceling a package/lesson that you paid your coach directly. ${noPackageCreditMessage}`;
        showAlertMessage = true;
      } else if (isAfter) {
        message += `You are canceling a package/lesson after its free cancelation date. ${noPackageCreditMessage}`;
        showAlertMessage = true;
      }

      setIsBeforePolicyDuration(!showAlertMessage);
      setIsAfterPolicyDuration(showAlertMessage);
      setLessonCancellationInfo(message);
    } finally {
      setLoadingState({ ...loadingState, isLoading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doPrepaidInfoLogicAsync = useCallback(async () => {
    let message = '';
    let showAlertMessage = false;
    const creditMessage =
      'By canceling this lesson you confirm that one lesson will not be returned to your lesson pack.';

    if (isPaymentOutsideApp) {
      message += `You are canceling a lesson that you paid your coach directly. ${creditMessage}`;
      showAlertMessage = true;
    } else if (isAfter) {
      message += `You are canceling a lesson after its free cancelation date. ${creditMessage}`;
      showAlertMessage = true;
    } else {
      message += 'By canceling this lesson, one lesson will be returned to your lesson pack.';
    }

    setLessonCancellationInfo(message);
    setIsBeforePolicyDuration(!showAlertMessage);
    setIsAfterPolicyDuration(showAlertMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isVisible || !lessonType) {
      return;
    }

    try {
      setLoadingState({ ...loadingState, isLoading: true });
      setIsAfterPolicyDuration(isAfter);
      setIsBeforePolicyDuration(isBefore);

      if (isSelfTaught) {
        // Do nothing
        return;
      }
      if (lessonType.isPrepaid) {
        doPrepaidInfoLogicAsync();
        return;
      }

      if (lessonType.isPackage) {
        doPackageInfoLogicAsync();
        return;
      }

      if (isPaymentOutsideApp) {
        const message = `You are canceling a lesson that you paid your coach for directly.`;

        setLessonCancellationInfo(message);
        setShowNoCreditMessage(true);

        setIsBeforePolicyDuration(false);
        setIsAfterPolicyDuration(true);

        return;
      }

      const message = isBefore
        ? `By canceling this lesson you will receive ${lessonPrice} TotalCoach credit.`
        : `You are canceling a lesson after its free cancelation date.`;

      setLessonCancellationInfo(message);
      setShowNoCreditMessage(!isBefore);
    } finally {
      setLoadingState({ ...loadingState, isLoading: false });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, setLoadingState, lessonType]);

  return {
    loadingState,
    reason,
    setReason,
    isSelfTaught,
    isAfterPolicyDuration,
    isBeforePolicyDuration,
    lessonType,
    lessonsCount,
    showNoCreditMessage,
    lessonCancellationInfo,
    lessonPrice,
  };
};
