import Logger from '../../../middleware/logger.middleware';

export class AuthLocalStorage {
  private static readonly refreshTokenName = 'refreshToken';

  private static readonly userIdName = 'UserId';

  private static readonly emailName = 'Email';

  private static readonly selectedOrg = 'selectedOrg';

  public static getAuthLocalStorageObject = () => {
    return {
      refreshToken: AuthLocalStorage.getRefreshToken,
      UserId: AuthLocalStorage.getUserId,
      Email: AuthLocalStorage.getEmail,
    };
  };

  public static getRefreshToken = window.localStorage.getItem(AuthLocalStorage.refreshTokenName);

  public static getUserId = window.localStorage.getItem(AuthLocalStorage.userIdName) ?? '';

  public static getEmail = window.localStorage.getItem(AuthLocalStorage.emailName) ?? '';

  public static getSelectedOrg = window.localStorage.getItem(AuthLocalStorage.selectedOrg) ?? '';

  public static setSelectedOrg = (value: string) =>
    window.localStorage.setItem(AuthLocalStorage.selectedOrg, value);

  public static setAuthLocalStorage = (refreshToken: string, userId: string, email: string) => {
    window.localStorage.setItem(AuthLocalStorage.refreshTokenName, refreshToken);
    window.localStorage.setItem(AuthLocalStorage.userIdName, userId);
    window.localStorage.setItem(AuthLocalStorage.emailName, email);
  };

  public static removeAuthLocalStorage = () => {
    window.localStorage.removeItem(AuthLocalStorage.refreshTokenName);
    window.localStorage.removeItem(AuthLocalStorage.userIdName);
    window.localStorage.removeItem(AuthLocalStorage.emailName);
    if (Logger.isDevEnvironment) {
      console.log('Auth local storage has been removed.');
    }
  };
}
