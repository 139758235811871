/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './ContactsContainer.module.scss';
import { UserContact } from '../../../../data/entities/userContacts.entity';
import ContactItem from '../../components/ContactItem/ContactItem';
import Search from '../../../../components/Search/Search';
import MobileAddButton from '../../../../components/Button/MobileAddButton/MobileAddButton';
import ContactsModal from '../../modals/ContactsModal';
import { onSearch } from '../../../../common/utils/search';
import SessionProvider from '../../../../providers/SessionProvider';
import UserService from '../../../../data/services/user.service';

const ContactsContainer = () => {
  const [contacts, setContacts] = useState<UserContact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<UserContact[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [hideAddBtn, setHideAddBtn] = useState(false);

  const fetchContacts = useCallback(async () => {
    const userId = SessionProvider.getUserId();
    const userContacts = await UserService.getAllContactsByUserId(userId);
    setContacts(userContacts ?? []);
  }, []);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  useEffect(() => {
    const result = onSearch(contacts.filter((item) => !item.deleted) as UserContact[], searchValue);
    setFilteredContacts(result);
  }, [contacts, searchValue]);

  const onContactAdded = async (newContact: UserContact) => {
    try {
      await UserService.createUserContact(newContact);
      fetchContacts();
    } catch (err: any) {
      toast.error('There was an error adding your contact.');
      console.error(err);
    } finally {
      setShowAdd(!showAdd);
    }
  };

  const onAddClick = () => {
    setShowAdd(!showAdd);
  };

  const onContactUpdated = async (updatedContact: UserContact) => {
    try {
      await UserService.updateUserContact(updatedContact._id, updatedContact);
      const contactIndex = contacts.findIndex((item) => item._id === updatedContact._id);
      const contactsArray = [...contacts];
      contactsArray[contactIndex] = updatedContact;
      setContacts(contactsArray);
    } catch (err: any) {
      toast.error('There was an error updating your contact');
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.searchWrapper}>
          {contacts.length > 0 && (
            <Search
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              className={styles.search}
            />
          )}
          <div
            style={{
              position: 'absolute',
              right: 0,
              transform: 'translateX(calc(100% + 20px))',
              top: 0,
            }}
          >
            <button className={styles.addBtn} type="button" onClick={onAddClick}>
              Add contact +
            </button>
          </div>
        </div>

        <ContactsModal
          showModal={showAdd}
          onModalCloseClick={() => setShowAdd(false)}
          saveLabel="Create"
          onContactsSaved={onContactAdded}
          titleModal="Add contact"
        />
        <div className={styles.contacts}>
          {filteredContacts.length === 0 && (
            <div className={styles.notFound}>No contacts found.</div>
          )}
          {filteredContacts.map((x) => (
            <ContactItem
              key={x._id}
              contact={x}
              onContactUpdated={onContactUpdated}
              setHideAddBtn={setHideAddBtn}
            />
          ))}
        </div>
      </div>
      <MobileAddButton onClick={onAddClick} showBtn={!showAdd && !hideAddBtn} />
    </div>
  );
};

export default ContactsContainer;
