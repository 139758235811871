import React from 'react';
import { useDispatch } from 'react-redux';
import { AxiosResponse, AxiosError } from 'axios';
import { toast } from 'react-toastify';

interface FetchOutput<T> {
  data?: T;
  error?: any;
}

interface UseAxiosFetchProps<T> {
  fetchFn: (params?: any) => Promise<AxiosResponse<T, any>>;
  dispatchFn?: Function;
  isAutoRun?: boolean;
}

export const useAxiosFetch = <T>(props: UseAxiosFetchProps<T>) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useDispatch();
  const isAutoRun = props.isAutoRun ?? true;

  const fetch = async (): Promise<FetchOutput<T>> => {
    setIsLoading(true);
    try {
      const response = await props.fetchFn();
      const { data } = response;
      setIsLoading(false);
      if (props.dispatchFn) {
        dispatch(props.dispatchFn(data));
      }
      return {
        data,
      };
    } catch (error: any) {
      const axiosError: AxiosError = error;
      setIsLoading(false);
      toast.error(axiosError.message);
      return {
        error,
      };
    }
  };

  React.useEffect(() => {
    if (isAutoRun) {
      fetch();
    } else {
      setIsLoading(false);
    }
  }, []);

  return { isLoading, fetch };
};
