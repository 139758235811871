/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import styles from './PracticeLocationsContainer.module.scss';
import Search from '../../../../components/Search/Search';
import MobileAddButton from '../../../../components/Button/MobileAddButton/MobileAddButton';
import PracticeLocationModal from '../../modals/PracticeLocationsModal';
import { onSearch } from '../../../../common/utils/search';
import SessionProvider from '../../../../providers/SessionProvider';
import UserService from '../../../../data/services/user.service';
import PracticeLocationItem from '../../components/PracticeLocationItem/PracticeLocationItem';

const ContactsContainer = () => {
  const [practiceLocations, setPracticeLocations] = useState<UserPracticeLocation[]>([]);
  const [filteredPracticeLocations, setFilteredPracticeLocations] = useState<
    UserPracticeLocation[]
  >([]);
  const [searchValue, setSearchValue] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [hideAddBtn, setHideAddBtn] = useState(false);

  const fetchPracticeLocations = useCallback(async () => {
    const userId = SessionProvider.getUserId();
    const orgId = SessionProvider.getOrgId();

    const userPracticeLocations = await UserService.getPracticeLocations({ userId, orgId });
    setPracticeLocations(userPracticeLocations);
  }, []);

  useEffect(() => {
    fetchPracticeLocations();
  }, []);

  useEffect(() => {
    const result = onSearch(practiceLocations as UserPracticeLocation[], searchValue);
    setFilteredPracticeLocations(result);
  }, [practiceLocations, searchValue]);

  const onPracticeLocationAdded = async (newPracticeLocation: UserPracticeLocation) => {
    try {
      const userId = SessionProvider.getUserId();
      const orgId = SessionProvider.getOrgId();
      practiceLocations.push(newPracticeLocation);

      const updates = {
        practice_locations: practiceLocations,
      };

      await UserService.updateUserDetailsV2({ userId, orgId, updates });
      fetchPracticeLocations();
    } catch (err: any) {
      toast.error('There was an error adding your contact.');
      console.error(err);
    } finally {
      setShowAdd(!showAdd);
    }
  };

  const onPracticeLocationDeleted = async (locationId: string) => {
    try {
      const userId = SessionProvider.getUserId();
      const orgId = SessionProvider.getOrgId();
      const index = practiceLocations.findIndex(
        (location: UserPracticeLocation) => location.location_id === locationId,
      );

      if (index >= 0) practiceLocations.splice(index, 1);

      const updates = {
        practice_locations: practiceLocations,
      };

      await UserService.updateUserDetailsV2({ userId, orgId, updates });
      fetchPracticeLocations();
    } catch (err: any) {
      toast.error('There was an error deleting your contact.');
      console.error(err);
    } finally {
      setShowAdd(false);
    }
  };

  const onAddClick = () => {
    setShowAdd(!showAdd);
  };

  const onPracticeLocationUpdated = async (updatedPracticeLocation: UserPracticeLocation) => {
    try {
      const userId = SessionProvider.getUserId();
      const orgId = SessionProvider.getOrgId();

      const contactIndex = practiceLocations.findIndex(
        (item) => item.location_id === updatedPracticeLocation.location_id,
      );

      const practiceLocationsArray = [...practiceLocations];
      practiceLocationsArray[contactIndex] = updatedPracticeLocation;

      const updates = {
        practice_locations: practiceLocationsArray,
      };

      await UserService.updateUserDetailsV2({ userId, orgId, updates });
      setPracticeLocations(practiceLocationsArray);
    } catch (err: any) {
      toast.error('There was an error updating your contact');
      console.error(err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.searchWrapper}>
          {practiceLocations.length > 0 && (
            <Search
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              className={styles.search}
            />
          )}

          <div
            style={{
              position: 'absolute',
              right: 0,
              transform: 'translateX(calc(100% + 20px))',
              top: 0,
            }}
          >
            <button className={styles.addBtn} type="button" onClick={onAddClick}>
              Add practice location +
            </button>
          </div>
        </div>
        <PracticeLocationModal
          showModal={showAdd}
          onModalCloseClick={() => setShowAdd(false)}
          saveLabel="Create"
          onPracticeLocationSaved={onPracticeLocationAdded}
          titleModal="Add practice location"
        />
        <div className={styles.contacts}>
          {filteredPracticeLocations.length === 0 && (
            <div className={styles.notFound}>No practice locations found.</div>
          )}
          {filteredPracticeLocations.map((x) => (
            <PracticeLocationItem
              key={x.location_id}
              practiceLocation={x}
              onPracticeLocationUpdated={onPracticeLocationUpdated}
              onDeletePracticeLocation={onPracticeLocationDeleted}
              setHideAddBtn={setHideAddBtn}
            />
          ))}
        </div>
      </div>
      <MobileAddButton onClick={onAddClick} showBtn={!showAdd && !hideAddBtn} />
    </div>
  );
};

export default ContactsContainer;
