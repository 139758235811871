import React, { useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { useMobileViewDetector } from 'common/hooks';
import { BrandThemeContext } from 'common/context';
import { BookingHelper } from 'modules/book/utils/booking.helpers';

import { Select } from 'components/SelectInput';
import { useService } from 'modules/book/pages/BookingPageV2/hooks';
import { LessonCounter } from './LessonCounter';
import { LessonSelectionContainerProps } from './interfaces';

export const LessonSelectionContainer = ({
  id,
  counter,
  bgColor,
  children,
  isDisabled,
}: LessonSelectionContainerProps) => {
  const theme = useContext(BrandThemeContext);
  const { isViewedFromMobile } = useMobileViewDetector();
  const { getServiceDetails } = useService();
  const [chosenCoachId, setChosenCoachId] = React.useState<string>('');
  const [invitedServiceCoachId, setInvitedServiceCoachId] = React.useState<string>('');
  const [isCoachesSelectionDisplayed, setIsCoachesSelectionDisplayed] = React.useState(false);

  const service = getServiceDetails(id);

  const getSingleCoachId = async () => {
    if (!service) return '';

    const selectedCoach = await BookingHelper.GetSelectedCoach(service);
    if (!selectedCoach) {
      return '';
    }

    return selectedCoach.id;
  };

  const getSelectedCoachId = async () => {
    const selectedCoachId = await getSingleCoachId();
    setChosenCoachId(selectedCoachId);
    if (service && service.coaches.length > 1) {
      setInvitedServiceCoachId(selectedCoachId);
    }
  };

  useEffect(() => {
    getSelectedCoachId();
  }, []);

  if (!service) return null;
  const { coaches } = service;

  const coachSelectOptions = coaches.map((coach: any) => ({
    id: coach.id,
    label: `${coach.firstName} ${coach.lastName}`,
  }));

  const handleDecrease = () => {
    /** Clear chosen coachId if count is 1 */
    if (counter.count === 1) {
      setChosenCoachId('');
      setIsCoachesSelectionDisplayed(false);
    }
    counter.onDecrease();
  };

  const handleIncrease = () => {
    if (coaches.length > 1 && chosenCoachId === '') {
      setIsCoachesSelectionDisplayed(true);
      counter.onIncrease('');
    } else {
      counter.onIncrease(chosenCoachId);
    }
  };

  const selectedBgColor = theme.colors.jungleGreen.opacity_25;

  return (
    <Box marginBottom={2} sx={{ position: 'relative' }}>
      <Card id={`lesson-${id}`}>
        <CardContent sx={{ bgcolor: counter.count ? selectedBgColor : bgColor }}>
          <Grid container>
            <Grid item xs={!isViewedFromMobile ? 9 : 12}>
              {children}
            </Grid>
            <Grid item xs={!isViewedFromMobile ? 3 : 12} border={0}>
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                {!isDisabled && (
                  <LessonCounter
                    id={id}
                    count={counter.count}
                    onDecrease={handleDecrease}
                    onIncrease={handleIncrease}
                  />
                )}
              </Box>
            </Grid>
            {isCoachesSelectionDisplayed && (
              <Grid item xs={12}>
                <Box marginTop={2}>
                  <Select
                    id="choose-coach"
                    label="Select a coach"
                    value={chosenCoachId}
                    options={coachSelectOptions}
                    disabled={!!invitedServiceCoachId}
                    onChange={(event) => {
                      counter.onChangeCoach(event.target.value);
                      setChosenCoachId(event.target.value);
                    }}
                  />
                  {!chosenCoachId && (
                    <Typography color={theme.colors.red.opacity_100} mt={1}>
                      Please choose a coach first from the list
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
