import Box from '@mui/material/Box';
import React from 'react';
import Stack from '@mui/material/Stack';
import { useHistory } from 'react-router-dom';
import { useMobileViewDetector } from '../../../../common/hooks/useMobileViewDetector';
import { themeColors } from './brandColors';
import { useFetchPurchasedLessonPacks } from '../../../../common/hooks/useFetchPurchasedLessonPacks';
import { useOrgId } from '../../../../common/hooks/useOrgId';
import { StaticRoutes } from '../../../../common/utils/routes/StaticRoutes.helper';

export const BannerPurchasedLessonPacks = () => {
  const history = useHistory();
  const { orgId } = useOrgId();
  const { isViewedFromMobile } = useMobileViewDetector();
  const { purchasedServices } = useFetchPurchasedLessonPacks();
  const maxTitleLength = isViewedFromMobile ? 30 : 70;

  return (
    <>
      {purchasedServices.map((service) => {
        const serviceUrl = `${StaticRoutes.Org}/${orgId}/select-dates/${service.id}`;
        const truncatedTitle =
          service.title.length > maxTitleLength
            ? `${service.title.substring(0, maxTitleLength)} ...`
            : service.title;

        return (
          <Box
            key={service.id}
            component="div"
            bgcolor={themeColors.jungleGreen.p25}
            color={themeColors.black.p100}
            marginBottom="4px"
            padding={1}
            paddingX={2}
            fontSize="0.75rem"
            sx={{ cursor: 'pointer' }}
            onClick={() => history.push(serviceUrl)}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box>
                Select dates for <span style={{ fontWeight: 'bold' }}>{truncatedTitle}</span>
              </Box>
              <Box>click here</Box>
            </Stack>
          </Box>
        );
      })}
    </>
  );
};
