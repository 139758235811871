import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { isEmpty } from 'lodash';
import { Textfield } from '../../../../components/Textfield';
import { Grid } from '../../../../components/Grid';
import { Button } from '../../../../components/Button/MuiButtons/Button';
import UserService from '../../../../data/services/user.service';
import { UserDetailInfoState } from './useProfile';
import { Phone, PhoneField, emptyPhoneValue } from '../../../../components/PhoneField';
import {
  AddressAutocompleteTextfield,
  FullAddress,
} from '../../../../components/AddressAutocompleteTextfield';

import { DatePickerTextField } from '../../../../components/DatepickerTextfield';
import { Select } from '../../../../components/SelectV2';
import { Gender } from '../../../../data/enums/Gender.enum';
import { User } from '../../../../data/entities/users.entity';
import { Address } from '../../../../data/entities/userContacts.entity';

interface PersonalInformationProps extends PersonalInformationFormFields {
  userId: string;
  orgId: string;
  refetch: () => void;
}

interface PersonalInformationFormFields {
  profileInfo: UserDetailInfoState;
  userInfo: User;
}

export const PersonalInformation = ({
  userId,
  orgId,
  profileInfo,
  userInfo,
  refetch,
}: PersonalInformationProps) => {
  const theme = useTheme();
  const isViewedFromMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [profileInfoChanges, setProfileInfoChanges] = React.useState(0);
  const [form, setForm] = React.useState<PersonalInformationFormFields>({
    profileInfo,
    userInfo,
  });

  const [error, setError] = useState({
    firstName: '',
    lastName: '',
  });

  const displayedAddress =
    form.profileInfo && form.profileInfo.address
      ? `${form.profileInfo.address?.street_number} ${form.profileInfo.address?.street_name}, ${form.profileInfo.address?.suburb}, ${form.profileInfo.address?.city}, ${form.profileInfo.address?.country} ${form.profileInfo.address?.zip_code}`
      : '';

  const genderList = Object.keys(Gender)
    .filter((v) => Number.isNaN(Number(v)))
    .map((value, index) => ({ id: index.toString(), label: value }));

  const handleSelectPlace = (address: FullAddress) => {
    setForm({
      ...form,
      profileInfo: {
        ...form.profileInfo,
        address,
      },
    });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleAddressChange = (key: keyof Address, value: string | number) => {
    setForm({
      ...form,
      profileInfo: {
        ...form.profileInfo,
        address: {
          ...form.profileInfo.address,
          [key]: value,
        },
      },
    });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleProfileChange = (key: keyof UserDetailInfoState, value: string | number) => {
    setForm({ ...form, profileInfo: { ...form.profileInfo, [key]: value } });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handlePhoneChange = (value: Phone) => {
    const tmpUpdatedProfileInfo = { ...form.profileInfo };
    tmpUpdatedProfileInfo.contact = value;
    setForm({ ...form, profileInfo: tmpUpdatedProfileInfo });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleFirstnameChange = (value: string) => {
    const tmpUpdatedUserInfo = { ...form.userInfo };
    tmpUpdatedUserInfo.first_name = value;
    setForm({ ...form, userInfo: tmpUpdatedUserInfo });
    if (isEmpty(value))
      setError({
        ...error,
        firstName: 'First name is required',
      });
    else
      setError({
        ...error,
        firstName: '',
      });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleLastnameChange = (value: string) => {
    const tmpUpdatedUserInfo = { ...form.userInfo };
    tmpUpdatedUserInfo.last_name = value;
    setForm({ ...form, userInfo: tmpUpdatedUserInfo });
    if (isEmpty(value))
      setError({
        ...error,
        lastName: 'Last name is required',
      });
    else
      setError({
        ...error,
        lastName: '',
      });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const handleProfileInfoSubmit = async () => {
    const { date_of_birth, gender_enum, contact, address } = form.profileInfo;
    const { first_name, last_name } = form.userInfo;

    const updatesUserDetail = {
      date_of_birth,
      gender_enum,
      contact,
      address,
    };
    await UserService.updateUserDetailsV2({ userId, orgId, updates: updatesUserDetail });

    const updateUser = {
      first_name,
      last_name,
    };
    await UserService.updateUser({ userId, updates: updateUser });
  };

  /** Final submit */
  const handleSubmit = async () => {
    const { first_name, last_name } = form.userInfo;
    if (isEmpty(first_name) || isEmpty(last_name)) return;

    setIsSubmitting(true);
    if (profileInfoChanges) {
      await handleProfileInfoSubmit();
      setProfileInfoChanges(0);
    }
    await refetch();
    setIsSubmitting(false);
    setIsEditMode(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Stack
          direction="row"
          justifyContent={isViewedFromMobile ? 'flex-start' : 'flex-end'}
          alignItems="center"
        >
          <FormGroup>
            <FormControlLabel
              control={<Switch color="success" checked={isEditMode} />}
              label="Edit mode"
              onChange={() => setIsEditMode(!isEditMode)}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="First name"
          value={form.userInfo.first_name}
          onChange={(value) => handleFirstnameChange(value)}
          hasError={!!error.firstName}
          errorMessage={error.firstName}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Last name"
          value={form.userInfo.last_name}
          onChange={(value) => handleLastnameChange(value)}
          hasError={!!error.lastName}
          errorMessage={error.lastName}
        />
      </Grid>
      <Grid item md={6}>
        <Select
          id="gender"
          label="Gender"
          isEditMode={isEditMode}
          value={form.profileInfo.gender_enum ? form.profileInfo.gender_enum.toString() : '0'}
          options={genderList}
          onChange={(selectedGender) =>
            handleProfileChange('gender_enum', parseInt(selectedGender, 10))
          }
        />
      </Grid>
      <Grid item md={6}>
        <DatePickerTextField
          isEditMode={isEditMode}
          label="Date of birth"
          value={form.profileInfo.date_of_birth ?? null}
          onChange={(newDob) => {
            if (newDob) {
              handleProfileChange('date_of_birth', newDob);
            }
          }}
        />
      </Grid>
      <Grid item md={6}>
        <PhoneField
          label="Phone number"
          value={
            form.profileInfo.contact
              ? (form.profileInfo.contact as unknown as Phone)
              : emptyPhoneValue
          }
          defaultCountry="nz"
          isEditMode={isEditMode}
          onChange={(newPhone) => {
            handlePhoneChange(newPhone);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          disabled
          isEditMode={isEditMode}
          label="Email"
          value={form.userInfo.email}
          onChange={(value) => {}} // eslint-disable-line
        />
      </Grid>
      <Grid item md={12}>
        <AddressAutocompleteTextfield
          isEditMode={isEditMode}
          label="Address"
          value={displayedAddress}
          placeholder="Search an address"
          onChange={(value) => {
            handleSelectPlace(value);
          }}
        />
      </Grid>
      {isEditMode && (
        <>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              label="Street number"
              value={form.profileInfo.address?.street_number ?? ''}
              onChange={(value) => {
                handleAddressChange('street_number', value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              label="Street name"
              value={form.profileInfo.address?.street_name ?? ''}
              onChange={(value) => {
                handleAddressChange('street_name', value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              label="Suburb"
              value={form.profileInfo.address?.suburb ?? ''}
              onChange={(value) => {
                handleAddressChange('suburb', value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              label="Zip code"
              value={form.profileInfo.address?.zip_code ?? ''}
              onChange={(value) => {
                handleAddressChange('zip_code', value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              disabled
              label="City"
              value={form.profileInfo.address?.city ?? ''}
              onChange={(value) => {
                handleAddressChange('city', value);
              }}
            />
          </Grid>
          <Grid item md={6}>
            <Textfield
              isEditMode={isEditMode}
              disabled
              label="Country"
              value={form.profileInfo.address?.country ?? ''}
              onChange={(value) => {
                handleAddressChange('country', value);
              }}
            />
          </Grid>
        </>
      )}

      {isEditMode && (
        <Grid item md={12}>
          <Stack direction="row" justifyContent="center" alignItems="center" columnGap={1}>
            <Button
              label={!isSubmitting ? 'Save' : 'Updating Profile ...'}
              disabled={isSubmitting}
              onClick={handleSubmit}
            />
            <Button
              label="Cancel"
              disabled={isSubmitting}
              variant="outlined"
              onClick={() => setIsEditMode(false)}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};
