import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { BrandThemeContext } from 'common/context';

interface DotProps {
  isOn?: boolean;
}

export const CalendarDot = ({ isOn }: DotProps) => {
  const theme = useContext(BrandThemeContext);
  const size = 8;
  const bgColor = isOn ? theme.colors.jungleGreen.opacity_100 : theme.colors.black.opacity_25;
  return <Box width={size} height={size} borderRadius={3} bgcolor={bgColor} />;
};
