import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BaseLessonSelectionProps } from './interfaces';
import { useLesson, useService, useLocation } from '../../../hooks';
import { LessonInfoWithIcon } from '../common';
import { LessonSelectionContainer } from './Container';
import { HiddenServiceItem } from './HiddenServiceItem';

type EventProps = BaseLessonSelectionProps;

export const Event = ({ id, counter }: EventProps) => {
  const { getCoachesNames } = useLesson();
  const { getLocationNameById } = useLocation();
  const { getServiceDetails } = useService();
  const service = getServiceDetails(id);
  if (!service) return null;

  const {
    durationInMinutes,
    title,
    maxParticipants,
    coaches,
    assistanceStaff,
    scheduledDates,
    locationIds,
    isFullyBooked,
    isPackageCancelled,
  } = service;

  const handeIncreaseClick = () => {
    if (counter.count < scheduledDates.length) counter.onIncrease();
  };

  let message = '';
  if (isFullyBooked) message = 'Fully Booked';
  if (isPackageCancelled) message = 'Canceled';

  return (
    <LessonSelectionContainer
      id={id}
      counter={{
        ...counter,
        onIncrease: handeIncreaseClick,
      }}
    >
      <Stack direction="column">
        <Box marginBottom={2}>
          <Typography fontWeight="bold">{title}</Typography>

          <LessonInfoWithIcon type="time" texts={[`${durationInMinutes} mins`]} />

          <LessonInfoWithIcon type="coach" texts={[getCoachesNames(coaches)]} />

          {assistanceStaff && assistanceStaff.length > 0 && (
            <LessonInfoWithIcon
              type="additionalCoach"
              texts={[
                `${
                  assistanceStaff?.length > 1 ? 'Additional Coaches:' : 'Additional Coach:'
                } ${getCoachesNames(assistanceStaff)}`,
              ]}
            />
          )}

          {locationIds.map((locationId) => (
            <LessonInfoWithIcon
              key={locationId}
              type="place"
              texts={[getLocationNameById(locationId)]}
            />
          ))}
        </Box>
        <Typography>Max Participants: {maxParticipants}</Typography>
      </Stack>
      <HiddenServiceItem message={message} />
    </LessonSelectionContainer>
  );
};
