import React from 'react';
import clsx from 'clsx';

import styles from './TabView.module.scss';

interface TabViewProps {
  tabs: Record<string, number | string>;
  selectedTab: number | string;
  setSelectedTab: Function;
  isNotSearch?: boolean;
  isParentTab?: boolean;
}

const TabView: React.FC<TabViewProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  isNotSearch = false,
  isParentTab = false,
}) => {
  let divStyle = isNotSearch ? clsx(styles.filter, styles.filtersNotSearch) : styles.filter;
  if (isParentTab) divStyle = styles.parentFilter;

  return (
    <div className={divStyle}>
      {Object.entries(tabs).map(([key, value]) => {
        let style = isParentTab ? styles.filterItemParent : styles.filterItem;

        if (selectedTab === value) {
          style = isParentTab ? styles.filterItemActiveParent : styles.filterItemActive;
        }

        let tabName = key;
        if (key === 'PracticeLessonUpcoming') tabName = 'Upcoming';
        if (key === 'PracticeLessonPast') tabName = 'Past';
        if (key === 'PracticeLessonCanceled') tabName = 'Canceled';
        if (key === 'PracticeLessonAll') tabName = 'All';
        if (key === 'PracticeLocation') tabName = 'Locations';
        if (key === 'PracticeLessonSelf') tabName = 'Practice (Self)';
        if (key === 'PracticeLessonCoach') tabName = 'Practice (Shared with coach)';
        if (key === 'PracticeLocation') tabName = 'Practice location';
        if (key === 'ExerciseIndividual') tabName = 'Individual lessons';
        if (key === 'ExerciseGroup') tabName = 'Group lessons';
        if (key === 'FavoritesLessonIndividual') tabName = 'Individual lessons';
        if (key === 'FavoritesLessonGroup') tabName = 'Group lessons';
        if (key === 'FavoritesPracticeShared') tabName = 'Practice (shared)';
        if (key === 'FavoritesPracticeSelf') tabName = 'Practice (self)';

        return (
          <div key={key} className={style} onClick={() => setSelectedTab(value)}>
            {tabName}
          </div>
        );
      })}
    </div>
  );
};

export default TabView;
