import React from 'react';
import styles from './ContactsPage.module.scss';
import ContactsContainer from '../containers/ContactsContainer/ContactsContainer';

const ContactsPage = () => {
  return (
    <div className={styles.Contacts}>
      <ContactsContainer />
    </div>
  );
};
export default ContactsPage;
