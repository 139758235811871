import { Config } from 'config';
import { BaseRepo } from './base.repo';
import { CreditBalance, CreditBalancePackagesBought } from '../entities/creditBalance.entity';

export default class CreditBalanceRepository extends BaseRepo {
  // #region Private Properties
  private creditBalanceCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('credit_balance');
  // #endregion

  // #region Public Methods

  async createCreditBalance(creditBalance: CreditBalance) {
    await this.creditBalanceCollection?.insertOne(creditBalance);
  }

  async setPackagesBoughtDocument(
    userId: string,
    orgId: string,
    creditBalancePackagesBought: CreditBalancePackagesBought,
  ) {
    await this.creditBalanceCollection?.updateOne(
      { user_id: userId, org_id: orgId },
      {
        $set: {
          packages_bought: [creditBalancePackagesBought],
        },
      },
    );
  }

  async pushPackagesBoughtDocument(
    userId: string,
    orgId: string,
    creditBalancePackagesBought: CreditBalancePackagesBought,
  ) {
    await this.creditBalanceCollection?.updateOne(
      { user_id: userId, org_id: orgId },
      {
        $push: {
          packages_bought: creditBalancePackagesBought,
        },
      },
    );
  }

  async updateLessonsTaken(userId: string, orgId: string, serviceId: string, lessonsTaken: number) {
    await this.creditBalanceCollection?.updateOne(
      {
        user_id: userId,
        org_id: orgId,
        'packages_bought.service_id': serviceId,
      },
      {
        $inc: {
          'packages_bought.$.lessons_taken': lessonsTaken,
        },
      },
    );
  }

  async getCreditBalance(userId: string, orgId: string): Promise<CreditBalance | undefined> {
    if (this.app.currentUser === null) {
      const mongo = await this.LogUserInAnonymously();
      this.creditBalanceCollection = mongo
        .db(Config().RealmDbName as string)
        .collection('payments');
    }
    return this.creditBalanceCollection?.findOne({ user_id: userId, org_id: orgId });
  }

  async resetPackagesBoughtForService(userId: string, orgId: string, serviceId: string) {
    await this.creditBalanceCollection?.updateOne(
      {
        user_id: userId,
        org_id: orgId,
        'packages_bought.service_id': serviceId,
      },
      {
        $set: {
          'packages_bought.$.lessons_occurrences_bought': 0,
          'packages_bought.$.lessons_taken': 0,
        },
      },
    );
  }

  async replacePackagesBoughtField(
    userId: string,
    orgId: string,
    serviceId: string,
    packagesBought: CreditBalancePackagesBought,
  ) {
    await this.creditBalanceCollection?.updateOne(
      {
        user_id: userId,
        org_id: orgId,
        'packages_bought.service_id': serviceId,
      },
      {
        $set: {
          'packages_bought.$': packagesBought,
        },
      },
    );
  }

  // #endregion
}
