import { useSelector } from 'react-redux';
import type { RootState } from 'redux/store';
import type { Address } from 'common/models/base.model';
import type { Address as UserAddress } from 'data/entities/userContacts.entity';

export const useLocation = () => {
  /** This hook is borrowed from the booking pages hooks. Need to refactor into commons later */
  const booking = useSelector((state: RootState) => state.booking);
  const getLocationName = (): string => {
    const selectedLocation = booking.data.locations.find(
      (location) => location.id === booking.selected.locationId,
    );

    if (selectedLocation) return selectedLocation.imageAndText.text;
    return '';
  };

  const getLocationNameById = (id: string) => {
    const location = booking.data.locations.find((locationItem) => locationItem.id === id);
    if (location) return location.name;
    return '';
  };

  /** This function borrowed from addressStringBuilder in common utils response helper.
   * Later on might be better to directly edit the original function in response.helper
   * Currently there are two types of Address props as well - should only be one. */
  type CombinedAddress = Partial<Address & UserAddress>;
  const addressStringBuilder = (address?: CombinedAddress, isCountryIncluded = false): string => {
    let addressString = '';
    if (!address) {
      return addressString;
    }
    if (address.subpremise) {
      addressString += `${address.subpremise}/`;
    }
    if (address.street_number) {
      addressString += `${address.street_number}`;
    }
    if (address.streetNumber) {
      addressString += `${address.streetNumber}`;
    }
    if (address.street_name) {
      addressString += ` ${address.street_name}`;
    }
    if (address.streetName) {
      addressString += ` ${address.streetName}`;
    }
    if (address.suburb) {
      addressString += `, ${address.suburb}`;
    }
    if (address.city) {
      addressString += `, ${address.city}`;
    }
    if (address.country && !isCountryIncluded) {
      addressString += `, ${address?.country}`;
    }
    if (address.zip_code) {
      addressString += ` ${address.zip_code}`;
    }
    if (address.zipCode) {
      addressString += ` ${address.zipCode}`;
    }
    return addressString.trim();
  };

  const getLocationAddressById = (id: string) => {
    const location = booking.data.locations.find((locationItem) => locationItem.id === id);
    if (location) return location.address;
    return undefined;
  };

  return {
    locationName: getLocationName(),
    getLocationNameById,
    getLocationAddressById,
    addressStringBuilder,
  };
};
