import React from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './Spinner2.module.scss';

interface SpinnerProps {
  message: string;
}

const Spinnerv2 = (props: SpinnerProps) => {
  const { message } = props;
  return (
    <div className={`${styles.spinner} position-absolute top-50 start-50 translate-middle`}>
      <div style={{ minWidth: '2rem' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
      <p className="text-muted float-end ms-4 text-center mb-0">{message}</p>
    </div>
  );
};

export default Spinnerv2;
