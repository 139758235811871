import { useCallback, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import OrganizationService from 'data/services/organization.service';
import OrganizationHelper from '../../../../common/utils/organization.helper';
import LessonHelper from '../../../../common/utils/lessons.helper';
import EventsService from '../../../../data/services/events.service';
import LessonsService from '../../../../data/services/lessons.service';
import Logger from '../../../../middleware/logger.middleware';
import {
  getDateFromUnixMilli,
  getZonedDate,
  isUserTimeZoneEvent,
  now,
} from '../../../../common/utils/date.helpers';
import SessionProvider from '../../../../providers/SessionProvider';
import { Lesson, LessonClientStatus } from '../../../../data/entities/lessons.entity';
import { Api } from '../../../../api/Api';
import { AxiosErrorHandler } from '../../../../common/utils/errorHandler.helpers';
import { OrgBookingRules } from '../../../book/models/booking.model';
import { Event } from '../../../../data/entities/events.entity';

export const useCalendarPage = () => {
  const timeZone = SessionProvider.getTimeZoneInfo();
  const [selectedDate, setSelectedDate] = useState(now());
  const [lastFetchDates, setLastFetchDates] = useState<string>();
  const [events, setEvents] = useState<any[]>([]);
  const [eventToUpdate, setEventToUpdate] = useState<Event>();
  const [visibleEventView, setVisibleEventView] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState<string | null>(null);
  const [visibleDeleteView, setVisibleDeleteView] = useState(false);
  const [isPracticeLesson, setIsPracticeLesson] = useState(false);
  const { orgId } = useParams<Record<string, string>>();
  const currentUserId = SessionProvider.getUserId();
  const [orgBookingRules, setOrgBookingRules] = useState<OrgBookingRules>(
    new OrgBookingRules(orgId ?? ''),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCancellingLesson, setIsCancellingLesson] = useState<boolean>(false);
  const [isCancellingPackage, setIsCancellingPackage] = useState<boolean>(false);
  const [categories, setCategories] = useState<LessonCategory[]>([]);

  const fetchOrgBookingRulesAsync = useCallback(async () => {
    try {
      setIsLoading(true);
      await OrganizationHelper.GetOrgBookingRules(orgId, setOrgBookingRules);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    fetchOrgBookingRulesAsync();
  }, [fetchOrgBookingRulesAsync]);

  const instanceOfEvent = (item: any): item is Lesson => {
    return 'share_event_with_coach' in item;
  };

  const fetchEvents = useCallback(
    async (startDate: DateTime, endDate: DateTime) => {
      if (Logger.isDevEnvironment) console.log('fetchEvents()');
      const start = getZonedDate(startDate.toMillis(), timeZone).toFormat('yyyy-MM-dd HH:mm:ss');
      const end = getZonedDate(endDate.toMillis(), timeZone).toFormat('yyyy-MM-dd HH:mm:ss');

      const lastFetch = `${start}_${end}`;
      if (lastFetchDates === lastFetch) return [];

      const startDay = selectedDate.startOf('day');
      const endDay = selectedDate.endOf('day');

      const selectedEvents = events.filter((event) => {
        const dateStart = isUserTimeZoneEvent(event)
          ? getDateFromUnixMilli(event.date_begin ?? event.date_start)
          : getZonedDate(event.date_start, timeZone);

        return (startDay < dateStart && endDay > dateStart) || startDay.equals(dateStart);
      });

      try {
        const userId = SessionProvider.getUserId();
        const eventsResponse = (await EventsService.getAllEventsByUserId(userId)) ?? [];
        const lessons = await LessonsService.getUserViewableLessonsByOrdId(userId, orgId as string);
        const category = await OrganizationService.getOrgLessonCategoriesById(orgId);

        setCategories(category);

        const eventsResult = uniqBy([...eventsResponse, ...selectedEvents, ...lessons], '_id');
        setEvents(eventsResult);
        setLastFetchDates(lastFetch);
        return eventsResult;
        // eslint-disable-next-line no-empty
      } catch (error) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastFetchDates, selectedDate],
  );

  const getDaysIndicators = useCallback(
    (startDate: DateTime, endDate: DateTime, eventsParam: any[]) => {
      if (Logger.isDevEnvironment) console.log('getDaysIndicator()');
      const diffInDays = endDate.diff(startDate, 'days').days + 1;
      const normalizedDiffInDays = Math.min(Math.round(diffInDays), 31);
      return new Array(normalizedDiffInDays).fill(null).map((_, day) => {
        const startDay = startDate.plus({ days: day });
        const filter = eventsParam.filter((event) => {
          const eventDate = isUserTimeZoneEvent(event)
            ? getDateFromUnixMilli(event.date_begin ?? event.date_start)
            : getZonedDate(event.date_start, timeZone);
          return eventDate.hasSame(startDay, 'day');
        });
        return filter.length;
      });
    },
    [timeZone],
  );

  const cancelLesson = async (lessonId: string, reason?: string) => {
    const lesson = events.find((event) => {
      return event._id === lessonId;
    }) as Lesson;

    try {
      setIsCancellingLesson(true);

      await Api.ClientRoutes.Lessons.cancelLesson(lessonId, {
        userId: currentUserId,
        reason,
      });
      toast.success(
        LessonHelper.LessonCancelledToastMessage(
          lesson,
          orgBookingRules.cancellationPeriodInMinutes,
        ),
      );
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.warn(compiledErrorMessage);
    } finally {
      const lessonClient = lesson?.clients.find((lessonClientSearch) => {
        return lessonClientSearch.user_id === currentUserId;
      });

      if (lessonClient) {
        lessonClient.status = LessonClientStatus.Cancelled;
      }

      setEvents(events);
      setIsCancellingLesson(false);
    }
  };

  const onCancelPackage = async (packageId: string, reason?: string) => {
    try {
      setIsCancellingPackage(true);
      await Api.ClientRoutes.Lessons.cancelPackage(packageId, {
        userId: currentUserId,
        reason,
      });
      toast.success(LessonHelper.PackageCancelledToastMessage());

      for (let i = 0; i < events.length; i += 1) {
        const eventItem = events[i];
        if (eventItem.package_id === packageId) {
          const lesson = eventItem as Lesson;
          for (let j = 0; j < lesson.clients.length; j += 1) {
            const lessonClient = lesson.clients[j];
            if (lessonClient.user_id === currentUserId) {
              lessonClient.status = LessonClientStatus.Cancelled;
              break;
            }
          }
        }
      }

      setEvents(events);
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.warn(compiledErrorMessage);
    } finally {
      setIsCancellingPackage(false);
    }
  };

  /**
   * Events are created in user's local time.
   * @param title
   * @param note
   * @param startDate
   * @param endDate
   * @param isNextDayEndDate
   */
  const createEvent = async (
    title: string,
    note: string,
    startDate: number,
    endDate: number,
    isNextDayEndDate: boolean,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCategories: string[],
    isShareCoach: boolean,
  ) => {
    const userId = SessionProvider.getUserId();
    const startDateObject = getDateFromUnixMilli(startDate);
    const endDateObject = getDateFromUnixMilli(endDate);

    const overapped_events = events.filter(
      (event) =>
        (event.date_begin >= startDate && event.date_begin < endDate && !event.deleted) ||
        (event.date_end > startDate && event.date_end <= endDate && !event.deleted),
    );

    if (overapped_events.length > 0) {
      toast.warn('The event date and time overlaps with another existing event');
      return undefined;
    }

    const newEvent = await EventsService.createEvent(
      userId,
      orgId as string,
      title,
      note,
      startDate,
      endDate,
      chosenLocationId,
      selectedContactList,
      selectedCategories,
      isShareCoach,
    );
    setVisibleEventView(false);
    fetchEvents(startDateObject, endDateObject);
    return newEvent;
  };

  const updateEvent = async (
    title: string,
    note: string,
    startDate: number,
    endDate: number,
    selectedContactList: string[],
  ): Promise<Event | null> => {
    if (eventToUpdate) {
      const overapped_events = events.filter(
        (eventItem) =>
          ((eventItem.date_begin >= startDate && eventItem.date_begin < endDate) ||
            (eventItem.date_end > startDate && eventItem.date_end <= endDate)) &&
          eventItem._id !== eventToUpdate._id &&
          !eventItem.deleted &&
          instanceOfEvent(eventItem),
      );

      if (overapped_events.length > 0) {
        toast.warn('The event date and time overlaps with another existing event');
      } else {
        await EventsService.updateEventById(
          eventToUpdate._id,
          title,
          note,
          startDate,
          endDate,
          selectedContactList,
        );
        const event: Event = {
          ...eventToUpdate,
          title,
          note,
          date_begin: startDate,
          date_end: endDate,
          contacts_ids: selectedContactList,
        };

        setEvents(events.map((x) => (x._id === event._id ? event : x)));
        setVisibleEventView(false);

        return event;
      }

      return null;
    }

    return null;
  };

  const deleteEvent = async () => {
    if (deleteEventId) {
      setEvents(events.filter((x) => x._id !== deleteEventId));
      await EventsService.deleteEventById(deleteEventId);
    }
  };

  const createPracticeLesson = async (
    title: string,
    note: string,
    startDate: number,
    endDate: number,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCategories: string[],
    isShareCoach: boolean,
  ) => {
    const startDateObject = getDateFromUnixMilli(startDate);
    const endDateObject = getDateFromUnixMilli(endDate);

    let lesson = null;

    const overapped_lessons = events.filter(
      (eventItem) =>
        ((eventItem.date_begin >= startDate && eventItem.date_begin < endDate) ||
          (eventItem.date_end > startDate && eventItem.date_end <= endDate)) &&
        !eventItem.deleted,
    );

    if (overapped_lessons.length > 0) {
      toast.warn('The lesson date and time overlaps with another existing event');
    } else {
      lesson = await LessonsService.createPracticeLesson(
        orgId as string,
        currentUserId,
        title,
        note,
        startDate,
        endDate,
        chosenLocationId,
        selectedContactList,
        selectedCategories,
        isShareCoach,
      );
      setVisibleEventView(false);
    }

    fetchEvents(startDateObject, endDateObject);
    return lesson;
  };

  const eventsByDay = useMemo(() => {
    if (events.length === 0) {
      return [];
    }
    const filterResult = events.filter((event) => {
      const eventDate = isUserTimeZoneEvent(event)
        ? getDateFromUnixMilli(event.date_begin ?? event.date_start)
        : getZonedDate(event.date_start, timeZone);
      return eventDate.hasSame(selectedDate, 'day');
    });

    return filterResult;
  }, [events, selectedDate, timeZone]);

  const isMostRecentPastLesson = (currentLesson: Lesson) => {
    const sortedLessons: Lesson[] = events
      .filter((lesson: Lesson) => {
        return lesson.date_start && lesson.date_start < now().toMillis();
      })
      .sort((a: Lesson, b: Lesson) => b.date_start - a.date_start);
    return sortedLessons[0] === currentLesson || sortedLessons[1] === currentLesson;
  };

  const isMostRecentPastLessonFromLessons = (currentLesson: Lesson, lessons: Lesson[]) => {
    const sortedLessons: Lesson[] = lessons
      .filter((lesson: Lesson) => {
        return lesson.date_start && lesson.date_start < now().toMillis();
      })
      .sort((a: Lesson, b: Lesson) => b.date_start - a.date_start);
    return sortedLessons[0]._id === currentLesson._id || sortedLessons[1]._id === currentLesson._id;
  };

  return {
    isLoading,
    isCancellingLesson,
    isCancellingPackage,
    isPracticeLesson,
    visibleEventView,
    visibleDeleteView,
    categories,
    eventsByDay,
    selectedDate,
    eventToUpdate,
    orgBookingRules,
    setSelectedDate,
    setIsPracticeLesson,
    setEventToUpdate,
    setDeleteEventId,
    setVisibleDeleteView,
    setVisibleEventView,
    getDaysIndicators,
    fetchEvents,
    cancelLesson,
    onCancelPackage,
    createEvent,
    updateEvent,
    deleteEvent,
    createPracticeLesson,
    isMostRecentPastLesson,
    isMostRecentPastLessonFromLessons,
    instanceOfEvent,
  };
};
