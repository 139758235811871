import React from 'react';
import clsx from 'clsx';

export interface ModalWarningTextProps {
  message: string;
  customClass?: string;
}

const ModalWarningText = ({ message, customClass }: ModalWarningTextProps) => {
  return <p className={clsx(customClass ?? 'mt-4')}>{message}</p>;
};

export default ModalWarningText;
