import React, { useRef } from 'react';
import { OrgListModel } from 'data/entities/organization.entity';
import { SportType } from 'data/enums/SportType.enum';
import SessionProvider from 'providers/SessionProvider';
import { checkUserTypeAndRedirect } from 'common/utils/common.helpers';
import styles from './OrgSelectorDropdown.module.scss';
import UserAvatar from '../UserAvatar';
import greenCheck from '../../../assets/icons/green-check-icon.svg';

interface IProps {
  onClose: any;
  dropDownOpen: boolean;
  arrowTrigger: Function;
  orgList: OrgListModel[];
}

const NavBarDropDown: React.FC<IProps> = ({ onClose, dropDownOpen, arrowTrigger, orgList }) => {
  const orgId = SessionProvider.getOrgId();
  const userId = SessionProvider.getUserId();

  const mobileMenuRef = useRef(null);

  if (!dropDownOpen) {
    arrowTrigger();
  }

  return (
    <div ref={mobileMenuRef} className={styles.selectorDrop}>
      <ul className={styles.dropDownList}>
        {orgList?.map((item: OrgListModel) => {
          return (
            <li key={item.orgId}>
              <div
                className={styles.userMenu}
                onClick={async () => {
                  if (item.orgId !== orgId) {
                    await checkUserTypeAndRedirect(userId, item.orgId);
                    onClose();
                  }
                }}
              >
                <div className={styles.userMenuContainer}>
                  <div style={{ display: 'flex' }}>
                    <UserAvatar className={styles.avatar} name={item.orgName} />
                    <div className={styles.subUserMenu}>
                      <span className={styles.userFirstName}>{item.orgName}</span>
                      <span className={styles.userOrgName}>{SportType[item.sportType ?? 0]}</span>
                    </div>
                  </div>
                  {item.orgId === orgId && (
                    <div className={styles.checkMark} style={{ display: 'flex' }}>
                      <img src={greenCheck} alt="Check" />
                    </div>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavBarDropDown;
