/* eslint-disable no-underscore-dangle */
import React from 'react';
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import { alpha } from '@mui/material';

export const Card = ({ height, borderRadius, contentPadding, children, onClick }: CardProps) => {
  const styles = {
    card: {
      minHeight: '200px',
      height: '100%',
      minWidth: '100%',
      borderRadius: borderRadius ?? 4,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '&:hover': {
        backgroundColor: alpha('#CACBCE', 0.2),
      },
    },
    cardContent: {
      width: '100%',
      height: height ?? '200px',
      padding: contentPadding,
    },
  };

  return (
    <MuiCard onClick={onClick} elevation={2} sx={styles.card}>
      <MuiCardContent sx={styles.cardContent}>{children}</MuiCardContent>
    </MuiCard>
  );
};

interface CardProps extends Pick<MuiCardProps, 'onClick' | 'children'> {
  height?: number;
  contentPadding: number;
  borderRadius?: number;
}
