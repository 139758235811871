import React, { useContext } from 'react';
import { useTimer } from 'react-timer-hook';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import { BrandThemeContext } from 'common/context';

export const BookingSessionTimer = ({ expiresAtMilis, onExpire }: BookingSessionTimerProps) => {
  const now = DateTime.now().minus({ second: 1 });
  const theme = useContext(BrandThemeContext);

  const { minutes, seconds, totalSeconds } = useTimer({
    expiryTimestamp: expiresAtMilis
      ? DateTime.fromMillis(expiresAtMilis).toJSDate()
      : now.toJSDate(),
    onExpire,
  });

  const displayTimeUnit = (num: number) => (num < 10 ? `0${num}` : num);

  return (
    <Box fontSize="1rem" textAlign="center" mb={2}>
      <Box
        display="inline-block"
        padding={1}
        width={150}
        bgcolor={
          totalSeconds > 60 ? theme.colors.jungleGreen.opacity_25 : theme.colors.red.opacity_20
        }
        color={totalSeconds > 60 ? theme.colors.black.opacity_100 : theme.colors.white}
        borderRadius={1}
      >
        Time left: {displayTimeUnit(minutes)}:{displayTimeUnit(seconds)}
      </Box>
    </Box>
  );
};

interface BookingSessionTimerProps {
  expiresAtMilis: number;
  onExpire: () => void;
}
