import React, { useEffect, useState } from 'react';
import LessonHelper from 'common/utils/lessons.helper';
import ModalWarningText from '../../../../components/ModalWindow/ModalWarningText';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import { Lesson } from '../../../../data/entities/lessons.entity';

interface AddFileViewProps {
  visible: boolean;
  onClose: Function;
  onContinue: Function;
  lesson: Lesson;
}

const AddFileView = ({ visible, onClose, onContinue, lesson }: AddFileViewProps) => {
  const [warningText, setWarningText] = useState('');

  const checkLessonDetails = async () => {
    let stringBuilder = '';
    if (!lesson.is_self_taught) {
      stringBuilder += 'Files added to the lesson page will be shared with your coach(es)';
      if (await LessonHelper.isGroupLesson(lesson)) {
        stringBuilder += ' and other participants';
      }

      stringBuilder += '. ';
    }

    stringBuilder += 'Are you sure you want to continue?';
    if (lesson.is_self_taught && !lesson.share_practice_lesson_with_coach) stringBuilder = '';
    setWarningText(stringBuilder);
  };

  useEffect(() => {
    if (visible) {
      checkLessonDetails();
    }
  }, [lesson, visible]);

  return (
    <ModalWindow
      show={visible}
      title="Add file"
      saveBtnLabel="Continue"
      onCloseClick={onClose}
      onSaveClick={() => {
        onContinue();
        onClose();
      }}
    >
      <ModalWarningText message={warningText} customClass="" />
    </ModalWindow>
  );
};

export default AddFileView;
