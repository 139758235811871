import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import { GuestAuthorizeModel, RefreshTokenModel } from 'api/models/auth.model';
import { handleApiError } from '../../common/utils/errorHandler.helpers';
import SessionProvider from '../../providers/SessionProvider';
import { DecodedToken } from '../../data/entities/token.entity';
import { AuthLocalStorage } from '../../modules/auth/utils/auth.helper';
import { Config } from '../../config';

export class BaseApi {
  public instance: AxiosInstance;

  constructor(baseUrl: string | undefined) {
    if (!baseUrl) {
      throw console.error('Could not create Api Base. parameter  `baseUrl` is null');
    }
    this.instance = this.CreateAxiosInstance(baseUrl);
  }

  public async getWithConfig(url: string, config: AxiosRequestConfig) {
    return this.instance.get(url, config);
  }

  public async get(url: string) {
    return this.instance.get(url);
  }

  public async getWithGuestAuthorize(url: string, guestAuthorizeModel: GuestAuthorizeModel) {
    return this.instance.get(url, { headers: { Guest: JSON.stringify(guestAuthorizeModel) } });
  }

  public async post(url: string, data: any) {
    return this.instance.post(url, data);
  }

  public async postWithConfig(url: string, data: any, axiosConfig: any) {
    return this.instance.post(url, data, axiosConfig);
  }

  public async postWithGuestAuthorize(
    url: string,
    data: any,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    return this.instance.post(url, data, {
      headers: { Guest: JSON.stringify(guestAuthorizeModel) },
    });
  }

  public async put(url: string, data: any) {
    return this.instance.put(url, data);
  }

  public async putWithGuestAuthorize(
    url: string,
    data: any,
    guestAuthorizeModel: GuestAuthorizeModel,
  ) {
    return this.instance.put(url, data, {
      headers: { Guest: JSON.stringify(guestAuthorizeModel) },
    });
  }

  public async delete(url: string) {
    return this.instance.delete(url);
  }

  public async deleteWithGuestAuthorize(url: string, guestAuthorizeModel: GuestAuthorizeModel) {
    return this.instance.delete(url, { headers: { Guest: JSON.stringify(guestAuthorizeModel) } });
  }

  /**
   * Checks whether the access token is near to expiration (60 seconds before expiration)
   * @param token
   * @returns
   */
  private isAccessTokenExpired(token?: string): boolean {
    if (!token || !token.length) return true;
    const decodedToken = <DecodedToken>jwtDecode(token);
    const diffInSeconds = DateTime.fromMillis(decodedToken.exp * 1000).diffNow('seconds').seconds;
    const isExpired = diffInSeconds - 60 < 0;
    return isExpired;
  }

  /**
   * Checks whether a url is a backen url or a mongo db realm url
   * @param url
   * @returns
   */
  private isBackendApiUrl(url: string): boolean {
    return url.toLowerCase().trim() === Config().BaseClientURL.toLowerCase().trim();
  }

  /**
   * Requests new access token from refresh token
   */
  private async baseApiRefreshToken(
    refreshToken: string,
  ): Promise<AxiosResponse<RefreshTokenModel>> {
    return await axios.post(
      `${Config().BaseClientURL}/auth/mongo/refreshToken?refreshToken=${refreshToken}`,
      null,
    );
  }

  private CreateAxiosInstance(url: string): AxiosInstance {
    const instance = axios.create({
      baseURL: url,
    });

    instance.interceptors.request.use(async (config) => {
      let accessToken = SessionProvider.getAccessToken();
      const refreshToken = SessionProvider.getRefreshToken();
      const isAccessTokenExpired = this.isAccessTokenExpired(accessToken);
      const isBackendApiUrl = this.isBackendApiUrl(config.baseURL ?? '');

      /** Refresh the access token when it is needed to be refreshed */
      if (accessToken && refreshToken && isAccessTokenExpired && isBackendApiUrl) {
        try {
          const response = await this.baseApiRefreshToken(refreshToken);
          const fetchNewAccessTokenResponse = response.data;
          if (fetchNewAccessTokenResponse) {
            accessToken = fetchNewAccessTokenResponse.accessToken;
            SessionProvider.updateRefreshTokenSession(
              accessToken,
              AuthLocalStorage.getUserId,
              AuthLocalStorage.getEmail,
            );
          }
        } catch (error) {
          console.log('ERROR:', error);
        }
      }

      config.headers.Authorization = accessToken;
      return config;
    });

    // Inject error handler for every response error.
    instance.interceptors.response.use(undefined, (error) => {
      handleApiError(error);
      throw error;
    });

    return instance;
  }
}
