import React, { useEffect, useState } from 'react';
import { Address } from 'data/entities/organization.entity';
import { Lesson } from 'data/entities/lessons.entity';
import { OrgLocation } from 'data/entities/orgLocations.entity';
import { addressStringBuilder } from 'common/utils/response.helper';
import styles from './LessonLocation.module.scss';
import HomeIcon from '../../../../assets/icons/navbar-icons/home-icon.svg';

interface LessonLocationProps {
  location: Address;
  locationName: string;
  lesson: Lesson;
}

const LessonLocation = ({ location, locationName, lesson }: LessonLocationProps) => {
  const [address, setAddress] = useState<Address>({});
  const [locationHeaderName, setLocationHeaderName] = useState<string>('');

  useEffect(() => {
    const index = lesson.locations?.locations.findIndex(
      (item: OrgLocation) => item.location_id === lesson.location_id,
    );

    if (index !== undefined && index >= 0) {
      setAddress(lesson.locations?.locations[index].address ?? {});
      setLocationHeaderName(lesson.locations?.locations[index].name ?? '');
    }
  }, [lesson]);
  return (
    <div className={styles.wrapper}>
      <img className={styles.icon} alt="clock-icon" src={HomeIcon} />
      {lesson.is_self_taught ? (
        <div className={styles.wrapperContent}>
          <div className={styles.workspace}>
            <span className={styles.organization}>{lesson.organization?.org_name}</span>
          </div>
          <div className={styles.locationName}>{locationName}</div>
          <div className={styles.location}>{addressStringBuilder(location)}</div>
        </div>
      ) : (
        <div className={styles.wrapperContent}>
          <div className={styles.workspace}>
            <span className={styles.organization}>{lesson.organization?.org_name}</span>
          </div>
          <div className={styles.locationName}>{locationHeaderName}</div>
          <div className={styles.location}>{addressStringBuilder(address)}</div>
        </div>
      )}
    </div>
  );
};

export default LessonLocation;
