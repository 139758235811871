import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { RootState } from 'redux/store';
import StaticDictionary from 'common/utils/staticDictionary.helper';
import { SelectedService as AvailableService } from 'modules/book/models/booking.model';

import { getByIsoCode } from 'common/utils/currencies.helper';
import { Conversions } from 'common/utils/conversions.helper';

import { Lesson, LessonClient, LessonClientStatus } from 'data/entities/lessons.entity';
import LessonsService from 'data/services/lessons.service';
import { useUserId } from 'common/hooks';

export const useLesson = () => {
  const application = useSelector((state: RootState) => state.application);
  const params = new URLSearchParams(window.location.search);
  const invitationIdParam = params.get(StaticDictionary.UrlParameters.Booking.InvitationId);
  const { lessonId } = useParams<Record<string, string>>();
  const { userId, email } = useUserId();

  /** Org Currency */
  const currencyCode = application.active.orgDetails?.currency_type ?? 'NZD';

  const getCoachesNames = (coaches: AvailableService['coaches']) =>
    coaches.map((coach) => `${coach.firstName} ${coach.lastName}`).join(', ');

  const getFormattedPrice = (priceInCents: number) => {
    const currency = getByIsoCode(currencyCode ?? 'NZD');
    return currency
      ? Conversions.toCurrencyShortString(currency, priceInCents)
      : priceInCents.toString();
  };

  /**
   * Fetches the lesson details by lesson ID.
   * @returns {Promise<Lesson>} - The lesson details.
   * @throws Will throw an error if the lesson is not found.
   */
  const getLesson = async (): Promise<Lesson> => {
    const lesson = await LessonsService.getLessonById(lessonId);
    if (!lesson) {
      throw new Error(
        'Lesson could not be found. It has either already occurred or no longer exists',
      );
    }
    return lesson;
  };

  /**
   * Validates if the lesson invite is still valid.
   * @param {Lesson} lesson - The lesson and invite details.
   * @param ErrorHandler - Handles the error
   * @returns {void} - Sets error for parent function to handle.
   */
  type ErrorHandler = (message: string) => void;

  const validateLesson = (lesson: Lesson, errorHandler: ErrorHandler): void => {
    const foundedInvite = lesson.clients.find(
      (lessonClient: LessonClient) => lessonClient.invite_id === invitationIdParam,
    );
    if (!foundedInvite) {
      errorHandler('Your invitation ID is not valid for this lesson');
    }

    if (foundedInvite?.is_accepted && foundedInvite.status === LessonClientStatus.Accepted) {
      errorHandler('You have already accepted this lesson invitation');
    }

    const totalClientsAttending = lesson.clients.filter(
      (lessonClient: LessonClient) => lessonClient.status === LessonClientStatus.Accepted,
    ).length;

    if (totalClientsAttending >= lesson.max_participants) {
      errorHandler('The lesson that you have been invited to has reached its maximum participants');
    }
  };

  /**
   * Validates if the invite matches the user.
   * @param {Lesson} lesson - The found invite.
   * @returns {void} - Will set error for parent function to handle.
   */
  const validateInvite = (lesson: Lesson, errorHandler: ErrorHandler): void => {
    const foundInvite = lesson.clients.find(
      (lessonClient: LessonClient) => lessonClient.invite_id === invitationIdParam,
    );
    if (!foundInvite) {
      errorHandler('Your invitation ID is not valid for this lesson');
    }

    const userMatchesInvite = foundInvite?.user_id === userId || foundInvite?.email === email;
    if (!userMatchesInvite) {
      errorHandler("You don't have permission to accept this invitation");
    }
  };

  return {
    getCoachesNames,
    getFormattedPrice,
    getLesson,
    validateInvite,
    validateLesson,
  };
};
