import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import EmptyState from '../../EmptyState/EmptyState';
import styles from './RadarChart.module.scss';
import SessionProvider from '../../../providers/SessionProvider';
import { JourneyMap } from '../../../modules/home/components/GolfJourneyMap/GolfJourneyMap';
import OrganizationService from '../../../data/services/organization.service';
import UserService from '../../../data/services/user.service';
import { SkillRating } from '../../../data/entities/skillRating.entity';
import { RadarChartLegend } from './RadarCharLegend';
import { useWindowDimensions } from './useWindowDimensions';

const emptyListText = 'No data entered yet by your coach';

const getSkillRatings = (skillRating?: SkillRating) => {
  let physical = 0;
  let tactical = 0;
  let mental = 0;
  let ranking = 0;

  if (skillRating && skillRating !== null && skillRating.rating) {
    physical = skillRating.rating.physical ? skillRating.rating.physical : 0;
    tactical = skillRating.rating.tactical ? skillRating.rating.tactical : 0;
    mental = skillRating.rating.mental ? skillRating.rating.mental : 0;
    ranking = skillRating.rating.ranking ? skillRating.rating.ranking : 0;
  }

  return { physical, tactical, mental, ranking };
};

const RadarChart = () => {
  const { width: windowWidth } = useWindowDimensions();
  const { orgId } = useParams<Record<string, string | undefined>>();
  const userId = SessionProvider.getUserId();
  const [skillData, setSkillData] = useState<number[]>([]);
  const [skillRating, setSkillRating] = useState<SkillRating>();
  const [athleteChartCategoriesCounts, setAthleteChartCategoriesCounts] = useState<JourneyMap[]>(
    [],
  );

  const fetchData = useCallback(async () => {
    const skillRatingResponse = await UserService.getUserSkillRatingByOrgAsync(
      orgId as string,
      userId as string,
    );
    setSkillRating(skillRatingResponse);

    const athleteCategoriesResponse = await OrganizationService.getCategoryWiseLessonCounts(
      orgId as string,
      userId as string,
      true,
    );

    setAthleteChartCategoriesCounts(athleteCategoriesResponse);

    const { physical, tactical, mental, ranking } = getSkillRatings(skillRatingResponse);
    setSkillData([physical, tactical, mental, ranking]);
  }, [orgId, userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const highestSkillNumber = Math.max(...skillData) + 1;
  const tickAmount = highestSkillNumber > 5 ? 5 : highestSkillNumber;
  const highestTickValue = Math.ceil(highestSkillNumber / tickAmount) * tickAmount;

  const options: ApexOptions = {
    xaxis: {
      categories: ['Physical', 'Tactical', 'Mental', 'Ranking'],
      labels: {
        show: false,
      },
    },
    yaxis: {
      decimalsInFloat: 0,
      min: 0,
      max: highestTickValue,
      tickAmount,
    },
    fill: {
      colors: ['rgba(102, 178, 69, 0.2)'],
    },
    stroke: {
      colors: ['rgba(102, 178, 69, 1)'],
    },
    markers: {
      colors: ['rgba(102, 178, 69, 1)'],
      size: 4,
    },
    chart: {
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
    },
  };

  const getAthleteLessonCount = (categoryName: string) => {
    const matchedCateory = athleteChartCategoriesCounts.filter(
      (category) => category.name === categoryName,
    );
    return matchedCateory.length > 0 && matchedCateory[0]?.lessonsCount
      ? matchedCateory[0]?.lessonsCount
      : 0;
  };

  const getLessonCategories = (categoryName: string) => {
    const matchedCateory = athleteChartCategoriesCounts.filter(
      (category) => category.name === categoryName,
    );
    return matchedCateory[0]?.categories ?? [];
  };

  const chartWidth = windowWidth <= 720 ? windowWidth - 110 : 400;

  return (
    <>
      {!skillRating && <EmptyState text={emptyListText} />}
      <div className={styles.chart}>
        <div>
          <div className={styles.firstLastRow}>
            <RadarChartLegend
              category="Physical"
              skillRating={skillData[0]}
              lessonCategories={getLessonCategories('Physical').join(',')}
              lessonsCount={getAthleteLessonCount('Physical')}
            />
          </div>
          <div className={styles.row}>
            <RadarChartLegend
              category="Ranking"
              skillRating={skillData[3]}
              lessonCategories={getLessonCategories('Ranking').join(',')}
              lessonsCount={getAthleteLessonCount('Ranking')}
            />

            <Chart
              series={[
                {
                  name: 'Skill Rating',
                  data: skillData ?? [],
                },
              ]}
              options={options}
              type="radar"
              width={chartWidth}
              height={chartWidth}
            />

            <RadarChartLegend
              category="Tactical"
              skillRating={skillData[1]}
              lessonCategories={getLessonCategories('Tactical').join(',')}
              lessonsCount={getAthleteLessonCount('Tactical')}
            />
          </div>
          <div className={styles.firstLastRow}>
            <RadarChartLegend
              category="Mental"
              skillRating={skillData[2]}
              lessonCategories={getLessonCategories('Mental').join(',')}
              lessonsCount={getAthleteLessonCount('Mental')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default RadarChart;
