import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import styles from './ContactDetail.module.scss';
import UserAvatar from '../../../../components/UserInfo/UserAvatar';
import ContactDetailItem from './ContactDetailItem/ContactDetailItem';
import ContactsModal from '../../modals/ContactsModal';
import CloseButton from '../../../../components/Button/CloseButton/CloseButton';
import HeaderNavView from '../../../../components/Header/HeaderNavView/HeaderNavView';
import { UserContact } from '../../../../data/entities/userContacts.entity';
import { addressStringBuilder } from '../../../../common/utils/response.helper';

interface IContactDetail {
  currentContact: UserContact;
  onUpdateContact: any;
  onCloseDetail: any;
  setHideAddBtn?: any;
}
const ContactDetail: React.FC<IContactDetail> = ({
  currentContact,
  onUpdateContact,
  onCloseDetail,
  setHideAddBtn,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [confimationModal, setConfirmationModal] = useState(false);

  const onUpdateOpened = () => {
    setOpenModal(!openModal);
    setHideAddBtn(true);
  };

  const onDeleteContact = async () => {
    currentContact.deleted = true;
    onUpdateContact(currentContact);
    setConfirmationModal(false);
  };

  const onContactUpdated = (newContact: UserContact) => {
    onUpdateContact(newContact);
    onUpdateOpened();
    setHideAddBtn(true);
  };

  const addressObjToString = (address: any) => {
    return addressStringBuilder(address);
  };

  return (
    <>
      <ContactsModal
        showModal={openModal}
        onModalCloseClick={onUpdateOpened}
        onContactsSaved={onContactUpdated}
        saveLabel="Update"
        titleModal="Update contact"
        oldValue={currentContact}
      />
      <div className={!openModal ? styles.ContactDetail : styles.ContactDetailHidden}>
        <div
          className={styles.backdrop}
          onClick={() => {
            onCloseDetail();
            document.body.style.overflow = 'auto';
          }}
        />
        <div className={styles.wrapper}>
          <div className={styles.closeBtn}>
            <CloseButton onClose={onCloseDetail} />
          </div>
          <HeaderNavView onClose={onCloseDetail} label="Contact" />
          <div className={styles.contactInfo}>
            <UserAvatar className={styles.avatar} name={currentContact.name} />
            <div className={styles.infoWrapper}>
              <div className={styles.profession}>{currentContact.profession}</div>
              <div className={styles.name}>{currentContact.name}</div>
            </div>
          </div>
          <div className={styles.contactItems}>
            {Object.entries(currentContact).map(([key, value]) => {
              let description = value;
              if (key === 'phone_number') {
                description = `${currentContact.country_code} ${value}`;
              }

              if (key === 'address') {
                description = addressObjToString(value);
              }
              return <ContactDetailItem label={key} description={description} key={uuidv4()} />;
            })}
          </div>
          <div className={styles.btns}>
            <div className={styles.editBtn} onClick={onUpdateOpened}>
              Edit
            </div>
            <div className={styles.deleteBtn} onClick={() => setConfirmationModal(true)}>
              Delete
            </div>
          </div>
        </div>
      </div>
      <ModalWindow
        show={confimationModal}
        title="Confirmation"
        saveBtnLabel="Delete"
        onSaveClick={onDeleteContact}
        onCloseClick={() => setConfirmationModal(false)}
      >
        <div>Are you sure to delete to this contact?</div>
      </ModalWindow>
    </>
  );
};
export default ContactDetail;
