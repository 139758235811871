import { NeverEndingPattern } from './base/neverEndingPattern.entity';
import { ServiceTypeEnum } from '../enums/ServiceType.enum';

export enum ServiceStatusEnum {
  Active = 0,
  Inactive,
  Draft,
}

export interface Services {
  /** GUID */
  _id: string;
  org_id: string;
  /** Who created this service? */
  user_id: string;
  title: string;
  /** Only assigned when service_type_enum = 5 */
  custom_service_type_id?: string;
  service_type_enum?: ServiceTypeEnum;
  /** Defaults to 1 */
  max_participants?: number;
  duration_in_minutes?: number;
  price_in_cents?: number;
  /** From org collection */
  tax_rate_ids?: string[];
  icon_enum?: number;
  /** hex value
   * @example #ffff */
  color?: string;
  is_calendar_appointment?: boolean;
  unscheduled_package_occurrences?: number;
  /** array of user id's */
  coaches: string[];
  /** user id's */
  assistance_staff?: string[];
  location_ids: string[];
  client_category_ids?: string[];
  allow_guests: boolean;
  is_regular?: boolean;
  is_invite_only: boolean;
  /** TRUE: A client has to pay upfront for all scheduled_dates */
  is_package: boolean;
  /** Start times can be a range
   * @example  "08:00" to "10:00" */
  is_flexible_start_time: boolean;
  /** Use the coaches working hours for the selected location */
  is_preset_work_hours: boolean;
  is_never_ending: boolean;
  scheduled_dates?: ServiceScheduledDate[];
  never_ending_pattern?: NeverEndingPattern;
  email_invite_link: boolean;
  service_status_enum: ServiceStatusEnum;
  org_resource_ids?: string[];
  invite_message?: string;
  invited_clients?: ServiceInvitedClient[];
  occurs_more_than_once?: boolean;
  /** Use in edit/view modes to display after how many occurrences the service will end */
  after_occurrence?: number;
  package_id?: string;
  /** TRUE: Requires client to pay for service on client portal */
  is_payment_in_app: boolean;
  is_fully_booked: boolean;
  is_package_cancelled: boolean;
  /** Epoch milliseconds */
  date_created: number;
  /** Epoch milliseconds */
  date_updated: number;
  deleted: boolean;
}

export interface ServiceInvitedClient {
  invite_id: string;
  user_id: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  invite_status_enum?: ServiceInviteStatusEnum;
  /** Epoch milliseconds */
  date_accepted?: number;
  /** Epoch milliseconds */
  date_updated?: number;
  deleted?: boolean;
  selected_coach_id?: string;
}

export enum ServiceInviteStatusEnum {
  Pending = 0,
  Accepted = 1,
  Declined = 2,
  RemovedByClient = 3,
}

export interface ServiceScheduledDate {
  is_lesson_created: boolean;
  /** Epoch milliseconds */
  date_start: number;
  /** Epoch milliseconds */
  date_end: number;
  org_lesson_category_ids?: string[];
}

export interface TimezoneList {
  label: string;
}
