import Logger from '../../middleware/logger.middleware';

// TODO: all types must be centralized in one central location to ensure easier access, avoid confusion and prevent circular dependency imports.
import { OrgBookingRules } from '../../modules/book/models/booking.model';
import { DefaultValues } from './defaultValues.helper';
import OrganizationService from '../../data/services/organization.service';
import { Organization } from '../../data/entities/organization.entity';

interface OrgId {
  orgId: string;
}

// TODO: 05/01/24 Since org details is required by multiple modules, it makes sense that we should dispatch    into the new redux store (in application slice)

export default class OrganizationHelper {
  /**
   * Wrapper to get OrgBookingRules and then set state value
   * @param orgId
   * @param setOrgBookingRules
   */
  public static async GetOrgBookingRules(
    orgId: string,
    setOrgBookingRules?: (orgBookingRules: OrgBookingRules) => void,
  ) {
    const orgBookingRules = await OrganizationService.getOrgBookingRules(orgId);

    if (!orgBookingRules) {
      if (Logger.isDevEnvironment) {
        console.error(
          `Booking Rules has not been set for organization. Defaulting to ${DefaultValues.BookingRules.timeSlotPeriodInMinutes}`,
        );
      }
      return;
    }

    if (setOrgBookingRules && setOrgBookingRules !== undefined) {
      setOrgBookingRules(orgBookingRules);
    }
  }

  /**
   * Wrapper to get OrgDetails and then set state value
   * @param orgId
   * @param setOrgDetails
   */
  public static async GetOrgDetails(
    orgId: string,
    setOrgDetails?: (orgDetails: Organization) => void,
    isAuth?: boolean,
  ) {
    let orgDetailsResponse;

    if (isAuth === true) {
      orgDetailsResponse = await OrganizationService.getOrgByIdAsync(orgId);
    } else {
      orgDetailsResponse = await OrganizationService.getOrgByIdAsGuestAsync(orgId);
    }

    if (orgDetailsResponse) {
      if (setOrgDetails !== undefined) setOrgDetails(orgDetailsResponse);
      return orgDetailsResponse;
    }

    if (Logger.isDevEnvironment) {
      console.error(`Failed to get OrgDetails. Could not find organization by id: ${orgId}`);
    }

    return undefined;
  }

  /**
   * GetOrgBookingRulesV2 is the newer version function to fetch organisation booking rules.
   * It complies to the single responsibility principle, in which it will cause no side effect,
   * and thus it will be safe to be used in any component as it causes no state changes
   * @param orgId
   */
  public static async GetOrgBookingRulesV2({ orgId }: OrgId): Promise<OrgBookingRules | undefined> {
    try {
      const orgBookingRules = await OrganizationService.getOrgBookingRules(orgId);
      if (!orgBookingRules && Logger.isDevEnvironment) {
        console.error(
          `Booking Rules has not been set for organization. Defaulting to ${DefaultValues.BookingRules.timeSlotPeriodInMinutes}`,
        );
      }
      return orgBookingRules;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(
          `Failed to get Org Booking Rules for OrgId : ${orgId}. Known error: ${error}`,
        );
      }
    }
    return undefined;
  }

  /**
   * GetOrgDetailsV2 is the newer version function to fetch organisation data.
   * It complies to the single responsibility principle, in which it will cause no side effect,
   * and thus it will be safe to be used in any component as it causes no state changes
   * @param orgId
   * @param setOrgDetails
   */

  public static async GetOrgDetailsV2({ orgId }: OrgId): Promise<Organization | undefined> {
    let orgDetailsResponse;

    try {
      orgDetailsResponse = await OrganizationService.getOrgByIdAsync(orgId);

      if (!orgDetailsResponse || orgDetailsResponse === null) {
        if (Logger.isDevEnvironment) {
          console.error(`Failed to get OrgDetails. Could not find organization by id: ${orgId}`);
        }
        return undefined;
      }

      return orgDetailsResponse;
    } catch (error) {
      if (Logger.isDevEnvironment) {
        console.error(`Failed to get OrgDetails for OrgId : ${orgId}. Known error: ${error}`);
      }
    }
    return undefined;
  }
}
