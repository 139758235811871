import { Config } from 'config';

export class BillingModuleRoutes {
  public static readonly Base = Config().Billing.ApiUrl;

  public static readonly Info = `${BillingModuleRoutes.Base}/info`;

  public static readonly Subscriptions = `${BillingModuleRoutes.Base}/subscriptions`;

  public static readonly BillingInfo = `${BillingModuleRoutes.Base}/info`;
}
