/* eslint-disable max-classes-per-file */
class RegexDictionary {
  /**
   * FirstName, LastName ( with accent and union )
   * @examples Naouraï Aedhan Aël Aëla Aélia Aëlig Aëlis Aelith Aëlred Aénor Afra Lily-Rose Lou-Ann Lou-Ann Marie-Lou Marie-Lou Léo-Paul Léo-Paul May-Lee May-Lee
   * @credit regexr.com/39j13
   * */
  public readonly Name =
    /(\b([A-Za-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9]{1,}(([-'][A-Za-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9]{1,})+)?\b))/gi;

  /**
   * Our current password format:
   * @format
   * 8 characters in length,
   * 1 capital letter,
   * 1 special character
   * 1 digit
   * @examples
   * T3st!ng123, P4ssword!, #H3llo78
   * */
  public readonly Password =
    /^(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[`~!@#$%^&*()\-_=+{};:,<.>])(?=.*\d).{8,}$/gm;

  /**
   * Email Validation as per RFC2822 standards.
   * @credit regexr.com/2rhq7
   * */
  public readonly Email =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

  /**
   * Phone number format
   * @examples
   * +42 555.123.4567 +1-(800)-123-4567 +7 555 1234567 +7(926)1234567 (926) 1234567 +79261234567 926 1234567 9261234567 1234567 123-4567 123-89-01 495 123
   * @credit regexr.com/38pvb
   */
  public readonly Phone =
    /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;
}

export class Validator {
  /**
   * Contains a list of regex patterns.
   * @credit https://regexr.com/ - community patterns
   */
  public static RegexDictionary = new RegexDictionary();
}
