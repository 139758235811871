import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.svg';
import { StaticRoutes } from '../../../common/utils/routes/StaticRoutes.helper';
import { getOrgId, vaildOrgRoute } from '../../../common/utils/common.helpers';
import styles from '../Header.module.scss';

interface ILogo {
  onClose?: any;
}
const Logo: React.FC<ILogo> = ({ onClose }) => {
  const [routeHasBeenChecked, setRouteHasBeenChecked] = useState(false);
  const [linkTo, setLinkTo] = useState(StaticRoutes.Home);

  useEffect(() => {
    if (!routeHasBeenChecked) {
      if (vaildOrgRoute(window.location.pathname)) {
        setLinkTo(`${StaticRoutes.Org}/${getOrgId()}`);
      }

      setRouteHasBeenChecked(true);
    }
  }, [routeHasBeenChecked]);

  return (
    <div onClick={onClose}>
      <Link to={linkTo} className={styles.Logo}>
        <img src={logo} alt="logo" style={{ width: 100 }} />
        <span className={styles.logoLetter}> ATHLETE</span>
      </Link>
    </div>
  );
};

export default Logo;
