import React from 'react';
import { useFetchPurchasedLessonPacks } from '../../../../common/hooks/useFetchPurchasedLessonPacks';
import { useMobileViewDetector } from '../../../../common/hooks/useMobileViewDetector';

interface BannerUpcomingLessonContainerProps {
  children: React.ReactElement;
}

export const BannerUpcomingLessonContainer = ({ children }: BannerUpcomingLessonContainerProps) => {
  const { purchasedServices, isLoading } = useFetchPurchasedLessonPacks();
  const { isViewedFromMobile } = useMobileViewDetector();

  let isBookButtonDisplayed = false;
  if (!isViewedFromMobile) {
    isBookButtonDisplayed = true;
  } else if (!isLoading && !purchasedServices.length) {
    isBookButtonDisplayed = true;
  }

  return isBookButtonDisplayed ? children : null;
};
