import { get } from 'lodash';

export const onSearch = (filteredData: any, searchValues = '') => {
  let filteredItems = filteredData;
  const searchValueList = searchValues.split(' ');
  for (let i = 0; i < searchValueList.length; i++) {
    const searchValue = searchValueList[i];
    if (searchValues) {
      filteredItems = filteredItems.filter((item: any) => {
        return (
          get(item, 'title', '').toLowerCase().includes(searchValue.toLowerCase()) ||
          get(item, 'name', '').toLowerCase().includes(searchValue.toLowerCase()) ||
          get(item, 'firstName', '').toLowerCase().includes(searchValue.toLowerCase()) ||
          get(item, 'lastName', '').toLowerCase().includes(searchValue.toLowerCase()) ||
          get(item, 'email', '').toLowerCase().includes(searchValue.toLowerCase()) ||
          get(item, 'phone', '').toLowerCase().includes(searchValue.toLowerCase())
        );
      });
    }
  }

  return filteredItems;
};
