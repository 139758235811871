import React from 'react';
import styles from './NavBar.module.scss';
import NavLinks from './NavLinks/NavLinks';
import NavBarMyAccount from './NavBarMyAccount/NavBarMyAccount';
import NavBarHelp from './NavBarHelp/NavBarHelp';
import NavBarProfile from './NavBarProfile';
import NavBarChangePW from './NavBarChangePW';
import NavBarSupport from './NavBarSupport';
import NavBarItemBillingInfo from './NavBarItemBillingInfo';

interface NavBarProps {
  onClose?: Function;
  billingText: string;
  isMobile: boolean;
}
const NavBar: React.FC<NavBarProps> = ({ onClose, billingText, isMobile }) => {
  return (
    <div className={styles.NavBar}>
      <nav>
        <ul>
          <NavLinks onClose={() => onClose && onClose()} />
          <NavBarSupport />
          <NavBarMyAccount onClose={() => onClose && onClose()} billingText={billingText} />
          <NavBarHelp />
          {isMobile ? (
            <>
              <NavBarProfile />
              <NavBarChangePW />
              <NavBarItemBillingInfo billingText={billingText} />
            </>
          ) : (
            ''
          )}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
