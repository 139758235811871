import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-input-2/lib/style.css';
import styles from './CountryCodePhoneInput.module.scss';

interface ICountryCodePhone {
  label?: string;
  /**
   * NOTE:
   * This needs to be kept as it is as it is being referenced / used / called from other file.
   */
  // eslint-disable-next-line
  placeholder?: string;
  onChange: any;
  defaultValue?: string;
}

const CountryCodePhoneInput: React.FC<ICountryCodePhone> = ({ label, onChange, defaultValue }) => {
  return (
    <div className={styles.inputWrapper}>
      <label>{label}</label>
      <PhoneInput
        className={styles.field}
        international
        countryCallingCodeEditable={false}
        defaultCountry="NZ"
        value={defaultValue}
        onChange={onChange}
      />
    </div>
  );
};

export default CountryCodePhoneInput;
