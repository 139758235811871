/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import clsx from 'clsx';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import PracticeLocationItem from 'modules/practiceLocations/components/PracticeLocationItem/PracticeLocationItem';
import { LibraryTabs } from 'modules/library/models/librarytabs.model';
import { Lesson } from '../../data/entities/lessons.entity';
import { Event } from '../../data/entities/events.entity';
import EmptyState from '../EmptyState/EmptyState';
import { FavoriteModel } from '../../data/entities/favorites.entity';
import listStyles from './List.module.scss';
import listViewStyles from '../Pages/Items/ListView/ListView.module.scss';
import LessonListItem from './LessonListItem/LessonListItem';
import EventListItem from './EventListItem/EventListItem';
import FavouritesItem from './FavouritesItem/FavouritesItem';
import ExercisesListItem from './ExercisesListItem/ExercisesListItem';
import { ExerciseModel } from '../../data/entities/exercises.entity';
import { LessonCategory } from '../../data/entities/orgLessonCategories.entity';
import { OrgBookingRules } from '../../modules/book/models/booking.model';
import HiddenListItem from './HiddenListItem';
import InlineSpinner from '../InlineSpinner/InlineSpinner';
import CheckBox from '../Checkbox/Checkbox';

export enum ListType {
  LessonsList = 0,
  EventsList = 1,
  FavouritesList = 2,
  ExercisesList = 3,
  PracticeLocationList = 4,
}

type CancelLessonType = (lessonId: string, reason: string) => void;
type CancelPackageType = (packageId: string, reason: string) => void;

type EventOrLessonType = Event | Lesson;
type EventOrLessonItems = Event[] | Lesson[];

interface ListProps {
  selectedTab?: number;
  items: any;
  listType: ListType;
  emptyLabel: string;
  cancelLesson?: CancelLessonType;
  onCancelPackage?: CancelPackageType;
  openDeleteEventView?: (eventId: string) => void;
  openEditEventView?: (event: Event) => void;
  onUnfavorited?: (id: string) => void;
  openAttachmentView?: Function;
  categories?: LessonCategory[];
  orgBookingRules?: OrgBookingRules;
  showListItem?: any;
  showUpgradePlanButtonIndex?: number;
  isLoading?: boolean;
  selectedItemIds?: string[];
  setSelectedItemIds?: (ids: string[]) => void;
  onPracticeLocationUpdated?: (updatedPracticeLocation: UserPracticeLocation) => void;
  onPracticeLocationDeleted?: (locationId: string) => void;
  setHideAddBtn?: (hide: boolean) => void;
}

const List = ({
  selectedTab,
  items,
  listType,
  emptyLabel,
  cancelLesson,
  onCancelPackage,
  openDeleteEventView,
  openEditEventView,
  onUnfavorited,
  openAttachmentView,
  categories,
  orgBookingRules,
  showListItem = () => {
    return true;
  },
  showUpgradePlanButtonIndex = -1,
  isLoading = false,
  selectedItemIds,
  setSelectedItemIds,
  onPracticeLocationUpdated,
  onPracticeLocationDeleted,
  setHideAddBtn,
}: ListProps) => {
  const [refresh, setRefresh] = useState<boolean>(false);

  if (isLoading) {
    return (
      <div className={clsx(listViewStyles.wrapper, 'mt-5')}>
        <InlineSpinner />
      </div>
    );
  }

  let sortedSource = items;

  if (
    selectedTab === LibraryTabs.PracticeLessonUpcoming ||
    selectedTab === LibraryTabs.PracticeLessonPast ||
    selectedTab === LibraryTabs.PracticeLessonCanceled ||
    selectedTab === LibraryTabs.PracticeLessonAll
  ) {
    sortedSource = items.sort((a: Lesson, b: Lesson) => {
      return b.date_start - a.date_start;
    });

    if (selectedTab !== LibraryTabs.PracticeLessonUpcoming)
      sortedSource = sortedSource.sort((a: Lesson, b: Lesson) => {
        if (!showListItem(a) && showListItem(b)) return 1;
        if (showListItem(a) && !showListItem(b)) return -1;
        return 0;
      });
    else
      sortedSource = items.sort((a: Lesson, b: Lesson) => {
        return a.date_start - b.date_start;
      });
  }

  function instanceOfLesson(item: any): item is Lesson {
    return 'is_self_taught' in item;
  }

  const isSelected = (id: string): boolean => {
    if (selectedItemIds) {
      const index = selectedItemIds.findIndex((value: string) => value === id);
      return index >= 0;
    }

    return false;
  };

  const setSelected = (id: string) => {
    if (selectedItemIds && setSelectedItemIds) {
      const index = selectedItemIds.findIndex((value: string) => value === id);

      if (index >= 0) {
        setSelectedItemIds(selectedItemIds.filter((value: string) => value !== id));
        setRefresh(!refresh);
        return;
      }

      selectedItemIds.push(id);
      setSelectedItemIds(selectedItemIds);
      setRefresh(!refresh);
    }
  };

  return (
    <div className={listViewStyles.wrapper}>
      {!sortedSource.length && <EmptyState text={emptyLabel} />}
      {listType === ListType.LessonsList &&
        (sortedSource as Lesson[]).map((item, i) =>
          !showListItem(item, i) && !item.is_self_taught ? (
            <HiddenListItem
              index={i}
              key={i}
              showUpgradePlanButtonIndex={showUpgradePlanButtonIndex}
              subscriptionText="Subscribe to Total Plan to view all your past lessons"
            />
          ) : (
            <LessonListItem
              key={item._id}
              item={item}
              cancelLesson={cancelLesson as CancelLessonType}
              onCancelPackage={onCancelPackage as CancelPackageType}
            />
          ),
        )}
      {listType === ListType.EventsList &&
        (sortedSource as EventOrLessonItems).map((item: EventOrLessonType, i) => {
          if (instanceOfLesson(item)) {
            return !showListItem(item, i) && !item.is_self_taught ? (
              <HiddenListItem
                index={i}
                key={i}
                showUpgradePlanButtonIndex={showUpgradePlanButtonIndex}
                subscriptionText="Subscribe to Total Plan to view all your past lessons"
              />
            ) : (
              <LessonListItem
                key={item._id}
                item={item}
                cancelLesson={cancelLesson as CancelLessonType}
                onCancelPackage={onCancelPackage as CancelPackageType}
                orgBookingRules={orgBookingRules}
              />
            );
          }
          return (
            <EventListItem
              key={item._id}
              item={item}
              openDeleteEventView={() => openDeleteEventView && openDeleteEventView(item._id)}
              openEditEventView={() => openEditEventView && openEditEventView(item)}
            />
          );
        })}
      {listType === ListType.FavouritesList &&
        (sortedSource as FavoriteModel[]).map((item, i) =>
          !showListItem(item, i) ? (
            <HiddenListItem
              index={i}
              key={i}
              showUpgradePlanButtonIndex={showUpgradePlanButtonIndex}
              subscriptionText="Subscribe to Total Plan to view all your past favorites"
            />
          ) : (
            <div key={item.favoriteId} className={listStyles.listItems}>
              {setSelectedItemIds && (
                <CheckBox
                  key={item.attachment?._id}
                  id={item.attachment?._id ?? ''}
                  checked={isSelected(item.attachment?._id ?? '')}
                  onCheck={() => {
                    setSelected(item.attachment?._id ?? '');
                  }}
                />
              )}
              <FavouritesItem
                key={i}
                item={item}
                openFavouriteView={() => openAttachmentView && openAttachmentView(item)}
                onUnfavorited={onUnfavorited}
              />
            </div>
          ),
        )}
      {listType === ListType.ExercisesList &&
        (sortedSource as ExerciseModel[]).map((item, i) =>
          !showListItem(item, i) ? (
            <HiddenListItem
              index={i}
              key={i}
              showUpgradePlanButtonIndex={showUpgradePlanButtonIndex}
              subscriptionText="Subscribe to Total Plan to view all your past exercises"
            />
          ) : (
            <div key={item.exerciseObj._id} className={listStyles.listItems}>
              {setSelectedItemIds && (
                <CheckBox
                  key={item.exerciseObj._id}
                  id={item.exerciseObj._id}
                  checked={isSelected(item.exerciseObj._id)}
                  onCheck={() => {
                    setSelected(item.exerciseObj._id);
                  }}
                />
              )}
              <ExercisesListItem key={item.exerciseObj._id} item={item} categories={categories} />
            </div>
          ),
        )}
      {listType === ListType.PracticeLocationList &&
        (sortedSource as UserPracticeLocation[]).map((item) => (
          <div key={item.location_id}>
            <PracticeLocationItem
              key={item.location_id}
              practiceLocation={item}
              onPracticeLocationUpdated={onPracticeLocationUpdated}
              onDeletePracticeLocation={onPracticeLocationDeleted}
              setHideAddBtn={setHideAddBtn}
            />
          </div>
        ))}
    </div>
  );
};

export default List;
