/* eslint-disable camelcase */
import { File } from './file.entity';
import { Organization } from './organization.entity';
import { Exercise } from './exercises.entity';
import { Attachment } from './attachment.entity';
import { OrgLocation } from './orgLocations.entity';
import { Event as EventInterface } from './events.entity';

export interface Cancellation {
  is_cancelled: boolean;
  cancellation_reason?: string;
  cancelled_by?: string;
}
export interface Note {
  _id: string;
  message: string;
  date_created?: number;
  date_updated?: number;
  deleted: boolean;
}

export interface ConfidentialNote {
  _id: string;
  user_id: string;
  message: string;
  date_created?: number;
  date_updated?: number;
  deleted: boolean;
}

export interface LessonClient {
  user_id: string;
  status: LessonClientStatus;
  invite_id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  is_accepted: boolean;
  /** Check if the client is required to pay in app */
  is_payment_in_app: boolean;
  is_paid: boolean;
  /** Epoch milliseconds */
  date_sent?: number;
  /** Epoch milliseconds */
  date_accepted?: number;
  /** If payment was made in app and not all in credit */
  payment_intent?: string;
  cancellation_reason?: string;
  /** Epoch milliseconds */
  date_updated?: number;
  is_invite_removed_by_client?: boolean;
}

export enum LessonClientStatus {
  'Accepted' = 'Accepted',
  'Pending' = 'Pending',
  'Cancelled' = 'Cancelled',
  'Declined' = 'Declined',
}

export enum LessonTypeEnum {
  CoachIndividualLesson = 0,
  CoachGroupLesson = 1,
  PracticeLessonSelf = 2,
  PracticeLessonShare = 3,
}

export interface ClientNote {
  _id: string;
  user_id: string;
  message: string;
  date_created: number;
  date_updated: number;
  deleted: boolean;
}

export interface ClientConfidentialNote {
  _id: string;
  user_id: string;
  message: string;
  date_created: number;
  date_updated: number;
  deleted: boolean;
}

interface OrgLocations {
  _id: string;
  org_id: string;
  locations: OrgLocation[];
}

export interface Lesson {
  _id: string;
  service_id: string;
  org_id: string;
  type: EventType;
  cancellation?: Cancellation;
  reason_uploads?: string[];
  solution_uploads?: string[];
  habit_uploads?: string[];
  client_uploads?: string[];
  exercise_ids?: string[];
  notes?: Note[];
  confidential_notes?: ConfidentialNote[];
  max_participants: number;
  price?: number;
  price_in_cents: number;
  tax?: number;
  client_notes?: ClientNote[];
  client_confidential_notes?: ClientConfidentialNote[];
  duration_in_minutes: number;
  title: string;
  coaches: string[];
  assistance_staff?: string[];
  location_id?: string;
  is_package: boolean;
  client_category_ids?: string[];
  org_lesson_category_ids?: string[];
  org_resource_ids?: string[];
  allow_guests: boolean;
  is_invite_only: boolean;
  package_id?: string;
  total_package_lessons?: number;
  date_start: number;
  date_end: number;
  clients: LessonClient[];
  is_self_taught: boolean;
  date_created: number;
  date_updated: number;
  deleted: boolean;
  thumbnails?: File[];
  currency_type?: string;
  is_upfront_payment?: boolean;
  organization?: Organization;
  org_location?: OrgLocations;
  share_practice_lesson_with_coach: boolean;
  contact_ids: string[];
  locations?: OrgLocations;
  /** guid */
  upfront_payment_id?: string;
}

export enum EventType {
  Lesson = 0,
  Event = 1,
  Service = 2,
  LessonInvite = 3,
}

export interface ProductivityChartData {
  months: number[];
  lessons: number[];
  practiceLessons: number[];
  events: number[];
}

export interface LessonWithExerciseAttachment {
  _id: {
    exerciseId: string;
    originalId: string;
  };
  title: string;
  exerciseObj: Exercise;
  attachments: Attachment[];
}

export interface CheckOverapped {
  startDate: number;
  endDate: number;
  event: EventInterface | Lesson | null;
}
