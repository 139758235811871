import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AuthenticatedUser, authenticationSlice } from 'redux/slices';
import UserService from 'data/services/user.service';
import SessionProvider from 'providers/SessionProvider';
import Logger from 'middleware/logger.middleware';
import { Dialog } from 'components/Dialog/Dialog';
import InputField from 'components/InputField/InputField';
import FormErrorMessage from 'modules/auth/components/FormErrorMessage/FormErrorMessage';
import { useAuth } from 'common/hooks';
import Link from 'components/Link/Link';

export const SignInDialog = () => {
  const authHook = useAuth();
  const { params } = authHook;

  const { signInOpen, invitedUser } = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch();

  /** Use email address from url if it is available, otherwise use the one from redux */
  const invitedEmail = params.get('email') ?? invitedUser?.email;
  const [email, setEmail] = useState(invitedEmail?.toLowerCase() ?? '');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    emailInputError: '',
    passwordInputError: '',
    requestError: '',
  });

  const onSignInClicked = async () => {
    try {
      const result = await UserService.userLogin(email, password);
      if (!result) {
        return;
      }
      const { accessToken, refreshToken, userId, firstName, lastName } = result;

      SessionProvider.updateSession(
        accessToken as string,
        refreshToken as string,
        userId as string,
        email,
        firstName,
        true,
      );

      const authenticatedUser: AuthenticatedUser = {
        accessToken: accessToken as string,
        refreshToken: refreshToken as string,
        userId,
        firstName,
        lastName,
      };

      dispatch(authenticationSlice.actions.changeSignInOpen(false));
      dispatch(authenticationSlice.actions.updateIsAuthenticated(true));
      dispatch(authenticationSlice.actions.updateAuthenticatedUser(authenticatedUser));

      /**
       * TODO:
       * Until the authentication is fully refactored, we will use this temporary solution.
       * The following delay is needed to prevent browser from reloading before the state is fully saved
       * into the localStorage by redux-persist
       *
       * TODO NEXT: Remove the following window.location.reload()
       */
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error: any) {
      if (Logger.isDevEnvironment) {
        console.error(error);
      }
    }
  };

  const onEmailChanged = (e: any) => {
    setErrors({ ...errors, emailInputError: '' });
    setEmail((e.target.value as string).toLowerCase());
  };
  const onPasswordChanged = (e: any) => {
    setErrors({ ...errors, passwordInputError: '' });
    setPassword(e.target.value);
  };

  React.useEffect(() => {
    if (invitedUser && invitedUser.email) {
      setEmail(invitedUser.email);
    }
  }, [invitedUser]);

  return (
    <Dialog
      title="Sign in to your account"
      open={signInOpen}
      onClose={() => dispatch(authenticationSlice.actions.changeSignInOpen(false))}
      submitBtnTitle="Sign In"
      submitAction={onSignInClicked}
      alternativeBtnAction={() => {
        dispatch(authenticationSlice.actions.changeSignInOpen(false));
        dispatch(authenticationSlice.actions.changeSignUpOpen(true));
      }}
      alternativeBtnTitle="Sign Up"
    >
      <Stack spacing={2} marginTop={2}>
        <InputField
          type="email"
          placeholder="Email"
          onChange={onEmailChanged}
          defaultValue={email}
          required
        />
        <FormErrorMessage message={errors.emailInputError} />
        <InputField type="password" placeholder="Password" onChange={onPasswordChanged} required />
        <FormErrorMessage message={errors.passwordInputError} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1em',
          }}
        >
          <Link url="/login/forgotPassword" text="Forgot password?" />
        </div>
        <FormErrorMessage message={errors.requestError} />
      </Stack>
    </Dialog>
  );
};
