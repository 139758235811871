/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { get, orderBy } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { ClientConfidentialNote } from 'data/entities/lessons.entity';
import SessionProvider from 'providers/SessionProvider';
import MobileClientConfidentialNotesContainer from './MobileClientConfidentialNotesContainer';
import styles from './ClientConfidentialNotesContainer.module.scss';
import ClientNotesModal from '../../modals/ClientNotesModal/ClientNotesModal';
import ClientNotesItem from '../../components/ClientNotesItem/ClientNotesItem';
import { ButtonType } from '../../../../data/enums/ButtonType.enum';
import LessonsService from '../../../../data/services/lessons.service';
import HintIcon from '../../../../assets/icons/hint-icon.svg';

interface IProps {
  lessonId: string;
  isLessonCancelled: boolean;
  clientNotes: ClientConfidentialNote[];
  onSelfNotesCreated: Function;
}

const ClientConfidentialNotesContainer: React.FC<IProps> = ({
  lessonId,
  clientNotes,
  isLessonCancelled,
  onSelfNotesCreated,
}) => {
  const userId = SessionProvider.getUserId();
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [ClientConfidentialNotesList, setClientConfidentialNotesList] = useState<
    ClientConfidentialNote[]
  >([]);
  const [ClientConfidentialNotesUpdate, setClientConfidentialNotesUpdate] =
    useState<ClientConfidentialNote | null>(null);

  const filterClientConfidentialNotes = useCallback(async () => {
    setClientConfidentialNotesList(clientNotes.filter((clientNote) => !clientNote.deleted));
  }, [clientNotes]);

  useEffect(() => {
    filterClientConfidentialNotes();
  }, [filterClientConfidentialNotes]);

  const onClientConfidentialNoteAddClick = () => setShowModal(true);
  const onModalCloseClick = () => setShowModal(false);

  const onClientConfidentialNotesRemoved = async (id: string) => {
    await LessonsService.deleteClientConfidentialNote(lessonId, id);
    setClientConfidentialNotesList(
      ClientConfidentialNotesList.filter((clientNote) => clientNote._id !== id),
    );
  };

  const onClientConfidentialNotesUpdated = async (id: string, text: string) => {
    const clientNoteIndex = ClientConfidentialNotesList.findIndex((item) => item._id === id);
    const clientNote = ClientConfidentialNotesList[clientNoteIndex];
    clientNote.message = text;

    if (clientNote) {
      const updatingResult = await LessonsService.updateClientConfidentialNote(lessonId, id, text);
      if (!updatingResult) {
        toast.error(updatingResult.message);
        return;
      }

      const clientNotesArr = [...ClientConfidentialNotesList];
      setClientConfidentialNotesList(clientNotesArr);
    }
  };

  const onClientNotesCreated = async (text: string) => {
    const result = await LessonsService.addClientConfidentialNote(lessonId, text, userId);
    if (result?.client_confidential_notes) {
      const client_confidential_notes = result.client_confidential_notes.filter(
        (clientNote: ClientConfidentialNote) => !clientNote.deleted,
      );
      setClientConfidentialNotesList(
        orderBy(
          client_confidential_notes,
          (clientNote: ClientConfidentialNote) => {
            return clientNote.date_created;
          },
          'desc',
        ),
      );

      onSelfNotesCreated(client_confidential_notes);
      setShowModal(false);
    }
  };

  const onShowUpdateModal = useCallback(
    (id: string, value: boolean) => {
      const currItem = ClientConfidentialNotesList.find((item) => item._id === id) || null;
      setClientConfidentialNotesUpdate(currItem);
      setShowUpdateModal(value);
    },
    [ClientConfidentialNotesList],
  );

  const onCloseUpdateModal = () => setShowUpdateModal(false);

  return (
    <>
      <ClientNotesModal
        showModal={showModal}
        onClientNoteSaved={onClientNotesCreated}
        onModalCloseClick={onModalCloseClick}
        saveLabel="Save"
        typeButton={ButtonType.Save}
        title="Notes"
        placeHolder="Enter your note here"
      />
      <ClientNotesModal
        text={get(ClientConfidentialNotesUpdate, 'message', '')}
        id={get(ClientConfidentialNotesUpdate, '_id', '')}
        showModal={showUpdateModal}
        onClientNoteSaved={onClientConfidentialNotesUpdated}
        onModalCloseClick={onCloseUpdateModal}
        saveLabel="Update"
        typeButton={ButtonType.Update}
        title="Notes"
        placeHolder="Enter your note here"
      />
      <MobileClientConfidentialNotesContainer
        clientNotes={ClientConfidentialNotesList}
        onClientNoteRemoved={onClientConfidentialNotesRemoved}
        showAddModal={showModal}
        onShowUpdateModal={onShowUpdateModal}
        onAddClientNotes={onClientConfidentialNoteAddClick}
      />
      <div className={styles.ClientNotes}>
        <div>
          <div className={styles.header}>
            <div className={styles.ClientNotesHeader}>
              <span>Your notes</span>
              <Tooltip title="Does not go to coach">
                <img alt="Hint Icon" src={HintIcon} className={styles.hintIcon} />
              </Tooltip>
            </div>
            {!isLessonCancelled && <span onClick={onClientConfidentialNoteAddClick}>Add+</span>}
          </div>
          <div className={styles.wrapper}>
            {ClientConfidentialNotesList.map((clientNote, i) => (
              <ClientNotesItem
                key={i}
                userId={clientNote.user_id}
                clients={[]}
                id={clientNote._id}
                text={clientNote.message}
                onClientNoteRemoved={onClientConfidentialNotesRemoved}
                onShowUpdateModal={onShowUpdateModal}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientConfidentialNotesContainer;
