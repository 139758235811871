import { BaseRepo } from './base.repo';
import { Config } from '../../config';
import { SportType } from '../enums/SportType.enum';
import { LessonCategoryTypes } from '../entities/lessonCategoryTypes.entity';

export class LessonCategoryTypesRepository extends BaseRepo {
  // #region Private Properties

  private lessonCategoryTypesCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('lesson_category_types');

  // #endregion

  // #region Public Methods

  async getLessonCategoryTypes(
    sportTypeEnum: SportType,
  ): Promise<LessonCategoryTypes[] | undefined> {
    return this.lessonCategoryTypesCollection?.find({ sport_type_enum: sportTypeEnum });
  }

  // #endregion
}
