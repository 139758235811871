/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { v4 as uuidv4 } from 'uuid';
import { LessonsParentTabs, LessonsTabs } from 'modules/lesson/models/lessons.model';
import { AttachmentSectionIndex, CoachUploads } from 'modules/attachment/models/attachment';
import { LibraryTabs } from 'modules/library/models/librarytabs.model';
import { Config } from '../../config';
import {
  Lesson,
  LessonClientStatus,
  LessonWithExerciseAttachment,
  Note,
} from '../entities/lessons.entity';
import { UpdateResult } from '../types/realm.types';
import { BaseRepo } from './base.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export class LessonsRepository extends BaseRepo {
  // #region Private Properties

  private lessonsCollection = this.mongo?.db(Config().RealmDbName as string).collection('lessons');

  private static addSearchAgg = (values: string[]) => {
    const initialAggregation: { $match: { title: { $regex: string; $options: string } } }[] = [];

    values.forEach((value: string) => {
      initialAggregation.push({
        $match: {
          title: { $regex: value, $options: 'i' },
        },
      });
    });

    return initialAggregation;
  };

  private static filterPracticeLessonSearchForTabs = (selectedTab: number, userId: string) => {
    const currentTime = getUnixMilliseconds();

    const commonClientMatch = {
      clients: {
        $elemMatch: {
          user_id: userId,
        },
      },
    };

    const commonCancellationMatch = {
      $or: [
        {
          cancellation: null,
        },
        {
          'cancellation.is_cancelled': false,
        },
      ],
    };

    const matchBasedOnTab = (tab: number) => {
      switch (tab) {
        case LibraryTabs.PracticeLessonUpcoming:
          return {
            ...commonClientMatch,
            date_start: { $gt: currentTime },
            ...commonCancellationMatch,
          };
        case LibraryTabs.PracticeLessonPast:
          return {
            ...commonClientMatch,
            date_start: { $lt: currentTime },
            ...commonCancellationMatch,
          };
        case LibraryTabs.PracticeLessonCanceled:
          return {
            $or: [
              {
                'cancellation.is_cancelled': true,
                clients: {
                  $elemMatch: {
                    user_id: userId,
                  },
                },
              },
            ],
          };
        case LibraryTabs.PracticeLessonAll:
          return {
            clients: {
              $elemMatch: {
                user_id: userId,
              },
            },
          };
        default:
          return null;
      }
    };

    return [{ $match: matchBasedOnTab(selectedTab) }];
  };

  private static filterSearchForTabs = (
    selectedTab: number,
    selectedParentTab: number,
    userId: string,
  ) => {
    const currentTime = getUnixMilliseconds();

    const commonClientMatch = {
      clients: {
        $elemMatch: {
          user_id: userId,
          status: {
            $eq: LessonClientStatus.Accepted,
          },
        },
      },
    };

    const commonCancellationMatch = {
      $or: [
        {
          cancellation: null,
        },
        {
          'cancellation.is_cancelled': false,
        },
      ],
    };

    const matchBasedOnTab = (tab: number) => {
      switch (tab) {
        case LessonsTabs.Upcoming:
          return {
            ...commonClientMatch,
            date_start: { $gt: currentTime },
            ...commonCancellationMatch,
          };
        case LessonsTabs.Past:
          return {
            ...commonClientMatch,
            date_start: { $lt: currentTime },
            ...commonCancellationMatch,
          };
        case LessonsTabs.Canceled:
          return {
            $or: [
              {
                'cancellation.is_cancelled': true,
                clients: {
                  $elemMatch: {
                    user_id: userId,
                    status: {
                      $in: [LessonClientStatus.Accepted, LessonClientStatus.Cancelled],
                    },
                  },
                },
              },
              {
                clients: {
                  $elemMatch: {
                    user_id: userId,
                    status: LessonClientStatus.Cancelled,
                  },
                },
              },
            ],
          };
        case LessonsTabs.All:
          return {
            clients: {
              $elemMatch: {
                user_id: userId,
                status: {
                  $in: [LessonClientStatus.Accepted, LessonClientStatus.Cancelled],
                },
              },
            },
          };
        default:
          return null;
      }
    };

    let matchQuery = null;
    if (selectedParentTab === LessonsParentTabs.Group) {
      matchQuery = {
        ...matchBasedOnTab(selectedTab),
        max_participants: { $gt: 1 },
      };
    }

    if (selectedParentTab === LessonsParentTabs.Individual) {
      matchQuery = {
        ...matchBasedOnTab(selectedTab),
        max_participants: 1,
      };
    }

    return matchQuery ? [{ $match: matchQuery }] : [];
  };

  private static filterCategoriesForTabs = (selectedCategories: string[]) => {
    if (selectedCategories.length > 0) {
      return [
        {
          $addFields: {
            matchingSubArray: {
              $filter: {
                input: '$org_lesson_category_ids',
                as: 'element',
                cond: {
                  $in: ['$$element', selectedCategories],
                },
              },
            },
          },
        },
        {
          $match: {
            'matchingSubArray.0': { $exists: true },
          },
        },
      ];
    }

    return [];
  };

  private static sortForTabs = (selectedTab: number) => {
    switch (selectedTab) {
      case LessonsTabs.Upcoming:
        return [
          {
            $sort: {
              date_start: 1,
            },
          },
        ];
      case LessonsTabs.Past:
        return [
          {
            $sort: {
              date_start: -1,
            },
          },
        ];
      case LessonsTabs.Canceled:
        return [
          {
            $sort: {
              date_start: -1,
            },
          },
        ];
      case LessonsTabs.All:
        return [
          {
            $sort: {
              date_start: -1,
            },
          },
        ];
      default:
        return [
          {
            $sort: {
              date_start: -1,
            },
          },
        ];
    }
  };

  // #endregion

  // #region Public Methods

  async getAllUserLessonsByOrgId(userId: string, orgId: string): Promise<Lesson[]> {
    const result = this.lessonsCollection?.aggregate([
      {
        $match: {
          org_id: orgId,
          'clients.user_id': userId,
        },
      },
      {
        $sort: {
          date_start: -1,
        },
      },
    ]);

    return result;
  }

  async getAllUserPracticesByOrgId(
    userId: string,
    orgId: string,
    selectedTab: number,
  ): Promise<Lesson[]> {
    const aggregation = [
      {
        $match: {
          org_id: orgId,
          is_self_taught: true,
        },
      },
      ...LessonsRepository.filterPracticeLessonSearchForTabs(selectedTab, userId),
      {
        $sort: {
          date_start: 1,
        },
      },
    ];

    const result = this.lessonsCollection?.aggregate(aggregation);

    return result;
  }

  async getAllUserSelfPracticesByOrgId(
    userId: string,
    orgId: string,
    self: boolean,
  ): Promise<Lesson> {
    const result = this.lessonsCollection?.aggregate([
      {
        $match: {
          org_id: orgId,
          'clients.user_id': userId,
          is_self_taught: true,
          share_practice_lesson_with_coach: self,
        },
      },
      {
        $sort: {
          date_start: -1,
        },
      },
    ]);

    return result;
  }

  async getAllLessonsByPackageId(packageId: string): Promise<Lesson[] | undefined> {
    const result = await this.lessonsCollection?.find(
      { package_id: packageId },
      { sort: { date_start: 1 } }, // asc order
    );

    return result;
  }

  async createPracticeLesson(lesson: Lesson) {
    return this.lessonsCollection?.insertOne(lesson);
  }

  async insertLessonAttachments(lessonId: string, attachmentId: string, sectionIndex: number) {
    switch (sectionIndex) {
      case AttachmentSectionIndex.ClientUploads:
        return this.lessonsCollection?.findOneAndUpdate(
          { _id: lessonId },
          { $addToSet: { client_uploads: attachmentId } },
        );
      case AttachmentSectionIndex.ReasonOfLesson:
        return this.lessonsCollection?.findOneAndUpdate(
          { _id: lessonId },
          { $addToSet: { reason_uploads: attachmentId } },
        );
      case AttachmentSectionIndex.TheSolution:
        return this.lessonsCollection?.findOneAndUpdate(
          { _id: lessonId },
          { $addToSet: { solution_uploads: attachmentId } },
        );
      default:
        return this.lessonsCollection?.findOneAndUpdate(
          { _id: lessonId },
          { $addToSet: { client_uploads: attachmentId } },
        );
    }
  }

  async updateInvitedClientStatus(
    lessonsId: string,
    invitedId: string,
    userId: string,
    inviteStatusEnum: LessonClientStatus,
  ) {
    const currentTime = getUnixMilliseconds();

    return this.lessonsCollection?.updateOne(
      {
        $and: [{ _id: lessonsId }, { 'clients.invite_id': invitedId }],
      },
      {
        $set: {
          'clients.$.user_id': userId,
          'clients.$.status': inviteStatusEnum,
          'clients.$.date_accepted': currentTime,
          'clients.$.date_updated': currentTime,
          'clients.$.is_removed_by_client': true,
        },
      },
    );
  }

  async addLessonAttachments(
    lessonId: string,
    attachmentId: string,
    sectionIndex: number,
  ): Promise<UpdateResult> {
    switch (sectionIndex) {
      case AttachmentSectionIndex.ClientUploads:
        return this.lessonsCollection?.updateOne(
          { _id: lessonId },
          { $set: { client_uploads: [attachmentId] } },
        );
      case AttachmentSectionIndex.ReasonOfLesson:
        return this.lessonsCollection?.updateOne(
          { _id: lessonId },
          { $set: { reason_uploads: [attachmentId] } },
        );
      case AttachmentSectionIndex.TheSolution:
        return this.lessonsCollection?.updateOne(
          { _id: lessonId },
          { $set: { solution_uploads: [attachmentId] } },
        );
      default:
        return this.lessonsCollection?.updateOne(
          { _id: lessonId },
          { $set: { client_uploads: [attachmentId] } },
        );
    }
  }

  async updateLessonAttachments(
    lessonId: string,
    attachmentIds: string[],
    sectionIndex: number,
  ): Promise<UpdateResult> {
    try {
      switch (sectionIndex) {
        case AttachmentSectionIndex.ClientUploads:
          return this.lessonsCollection?.updateOne(
            { _id: lessonId },
            { $push: { client_uploads: { $each: attachmentIds } } },
          );
        case AttachmentSectionIndex.ReasonOfLesson:
          return this.lessonsCollection?.updateOne(
            { _id: lessonId },
            { $push: { reason_uploads: { $each: attachmentIds } } },
          );
        case AttachmentSectionIndex.TheSolution:
          return this.lessonsCollection?.updateOne(
            { _id: lessonId },
            { $push: { solution_uploads: { $each: attachmentIds } } },
          );
        case CoachUploads:
          return this.lessonsCollection?.updateOne(
            { _id: lessonId },
            { $set: { exercise_ids: attachmentIds } },
          );
        default:
          return this.lessonsCollection?.updateOne(
            { _id: lessonId },
            { $push: { client_uploads: { $each: attachmentIds } } },
          );
      }
    } catch (error) {
      // Handle error
      console.error(error);
      throw error;
    }
  }

  // only for practice, is_self_taught is true & if current user is the only client
  async updateLessonTitleAndCategories(id: string, title: string, categories: string[]) {
    const result = await this.lessonsCollection?.findOneAndUpdate(
      { _id: id },
      { $set: { title, org_lesson_category_ids: categories, date_updated: getUnixMilliseconds() } },
    );
    return result;
  }

  async updatePracticeLesson(
    lessonID: string,
    lessonTitle: string,
    note: string,
    startDate: number,
    endDate: number,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCategories: string[],
    isShareCoach: boolean,
  ) {
    const noteList: Note[] = [
      {
        _id: uuidv4(),
        message: note,
        deleted: false,
      },
    ];

    const result = await this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonID },
      {
        $set: {
          title: lessonTitle,
          org_lesson_category_ids: selectedCategories,
          date_start: startDate,
          date_end: endDate,
          location_id: chosenLocationId,
          contact_ids: selectedContactList,
          share_practice_lesson_with_coach: isShareCoach,
          date_updated: getUnixMilliseconds(),
          notes: noteList,
        },
      },
    );
    return result;
  }

  async setLessonClientNotes(lessonId: string, note: Note): Promise<Lesson | undefined> {
    return this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId },
      { $set: { client_notes: [note] } },
      { returnNewDocument: true },
    );
  }

  async setLessonClientConfidentialNotes(
    lessonId: string,
    note: Note,
  ): Promise<Lesson | undefined> {
    return this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId },
      { $set: { client_confidential_notes: [note] } },
      { returnNewDocument: true },
    );
  }

  async pushLessonClientNotes(lessonId: string, note: Note): Promise<Lesson | undefined> {
    return this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId },
      { $push: { client_notes: note } },
      { returnNewDocument: true },
    );
  }

  async pushLessonClientConfidentialNotes(
    lessonId: string,
    note: Note,
  ): Promise<Lesson | undefined> {
    return this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId },
      { $push: { client_confidential_notes: note } },
      { returnNewDocument: true },
    );
  }

  async getLessonById(lessonId: string): Promise<Lesson | undefined> {
    const result: Lesson | undefined = await this.lessonsCollection?.findOne({
      _id: lessonId,
      deleted: false,
    });
    return result;
  }

  async getLessonByIdAndUserId(lessonId: string, userId: string): Promise<Lesson | undefined> {
    const result = await this.lessonsCollection?.findOne({
      _id: lessonId,
      'clients.user_id': userId,
    });
    return result;
  }

  async getLessonByOrgIdAndUserId(orgId: string, userId: string): Promise<Lesson[] | undefined> {
    return this.lessonsCollection?.find({
      org_id: orgId,
      'clients.user_id': userId,
    });
  }

  async getLessonsWithExercisesByOrgIdAndUserId(
    orgId: string,
    userId: string,
    isGroup?: boolean,
  ): Promise<LessonWithExerciseAttachment[] | undefined> {
    return this.lessonsCollection?.aggregate([
      {
        $match: {
          org_id: orgId,
          'clients.user_id': userId,
          ...(isGroup !== undefined && {
            max_participants: isGroup ? { $gt: 1 } : 1,
          }),
        },
      },
      {
        $unwind: '$exercise_ids',
      },
      {
        $lookup: {
          from: 'exercises',
          localField: 'exercise_ids',
          foreignField: '_id',
          as: 'exercise',
        },
      },
      {
        $unwind: '$exercise',
      },
      {
        $unwind: '$exercise.attachment_ids',
      },
      {
        $lookup: {
          from: 'attachment',
          localField: 'exercise.attachment_ids',
          foreignField: '_id',
          as: 'exercise.attachment',
        },
      },
      {
        $unwind: '$exercise.attachment',
      },
      {
        $group: {
          _id: {
            originalId: '$_id',
            exerciseId: '$exercise._id',
          },
          title: { $first: '$exercise.title' },
          exerciseObj: { $first: '$exercise' },
          attachments: { $push: '$exercise.attachment' },
        },
      },
      {
        $addFields: {
          'exercise.attachment': '$attachments',
        },
      },
    ]);
  }

  async updateClientNote(lessonId: string, noteId: string, text: string) {
    const result = await this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId, 'client_notes._id': noteId },
      {
        $set: {
          'client_notes.$.message': text,
        },
      },
      { returnNewDocument: true },
    );
    return result;
  }

  async updateClientConfidentialNote(lessonId: string, noteId: string, text: string) {
    const result = await this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId, 'client_confidential_notes._id': noteId },
      {
        $set: {
          'client_confidential_notes.$.message': text,
        },
      },
      { returnNewDocument: true },
    );
    return result;
  }

  async deleteClientNote(lessonId: string, noteId: string) {
    const result = await this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId, 'client_notes._id': noteId },
      {
        $set: {
          'client_notes.$.deleted': true,
        },
      },
      { returnNewDocument: true },
    );
    return result;
  }

  async deleteClientConfidentialNote(lessonId: string, noteId: string) {
    const result = await this.lessonsCollection?.findOneAndUpdate(
      { _id: lessonId, 'client_confidential_notes._id': noteId },
      {
        $set: {
          'client_confidential_notes.$.deleted': true,
        },
      },
      { returnNewDocument: true },
    );
    return result;
  }

  async getNextTenLessonsForUser(orgId: string, userId: string): Promise<Lesson[]> {
    const currentTime = getUnixMilliseconds();
    const nextTenLessons = await this.lessonsCollection?.aggregate([
      {
        $lookup: {
          from: 'organization',
          localField: 'org_id',
          foreignField: '_id',
          as: 'organization',
        },
      },
      {
        $unwind: '$organization',
      },
      {
        $lookup: {
          from: 'org_locations',
          localField: 'org_id',
          foreignField: 'org_id',
          as: 'locations',
        },
      },
      {
        $unwind: '$locations',
      },
      {
        $match: {
          org_id: orgId,
          clients: {
            $elemMatch: {
              user_id: userId,
              status: {
                $eq: LessonClientStatus.Accepted,
              },
            },
          },
          deleted: false,
          cancellation: null,
          date_start: {
            $gt: currentTime,
          },
        },
      },
      {
        $sort: {
          date_start: 1,
        },
      },
      {
        $limit: 10,
      },
    ]);
    return nextTenLessons;
  }

  async getUserInvitedLessons(orgId: string, userId: string, email: string): Promise<Lesson[]> {
    const currentTime = getUnixMilliseconds();
    const agg = [
      {
        $lookup: {
          from: 'organization',
          localField: 'org_id',
          foreignField: '_id',
          as: 'organization',
        },
      },
      {
        $unwind: '$organization',
      },
      {
        $lookup: {
          from: 'org_locations',
          localField: 'org_id',
          foreignField: 'org_id',
          as: 'locations',
        },
      },
      {
        $unwind: '$locations',
      },
      {
        $match: {
          org_id: orgId,
          deleted: false,
          cancellation: null,
          clients: {
            $elemMatch: {
              status: 'Pending',
              $or: [{ user_id: userId }, { email: email }],
            },
          },
          date_start: {
            $gte: currentTime,
          },
        },
      },
      {
        $unwind: {
          path: '$clients',
          includeArrayIndex: 'index',
          preserveNullAndEmptyArrays: true,
        },
      },
      {
        $match: {
          $expr: {
            $and: [
              {
                $eq: [
                  {
                    $ifNull: ['$clients.is_removed_by_client', false],
                  },
                  false,
                ],
              },
            ],
          },
        },
      },
      {
        $addFields: {
          is_upfront_payment: {
            $ifNull: ['$is_upfront_payment', false],
          },
        },
      },
      {
        $facet: {
          upfrontPaymentLessons: [
            {
              $match: {
                is_upfront_payment: true,
              },
            },
            {
              $group: {
                _id: '$upfront_payment_id',
                scheduled_dates: {
                  $push: {
                    date_start: '$date_start',
                    date_end: '$date_end',
                  },
                },
                document: {
                  $first: '$$ROOT',
                },
              },
            },
            {
              $replaceRoot: {
                newRoot: {
                  $mergeObjects: [
                    '$document',
                    {
                      scheduled_dates: '$scheduled_dates',
                    },
                  ],
                },
              },
            },
          ],
          otherLessons: [
            {
              $match: {
                is_upfront_payment: false,
              },
            },
          ],
        },
      },
      {
        $project: {
          combinedLessons: {
            $concatArrays: ['$upfrontPaymentLessons', '$otherLessons'],
          },
        },
      },
      {
        $unwind: '$combinedLessons',
      },
      {
        $replaceRoot: {
          newRoot: '$combinedLessons',
        },
      },
      {
        $sort: {
          date_start: 1,
        },
      },
      {
        $limit: 20,
      },
      {
        $facet: {
          regatheredClients: [
            {
              $group: {
                _id: '$_id',
                combinedClients: {
                  $push: '$clients',
                },
                root: {
                  $first: '$$ROOT',
                },
              },
            },
            {
              $addFields: {
                'root.clients': '$combinedClients',
              },
            },
            {
              $replaceRoot: {
                newRoot: '$root',
              },
            },
          ],
        },
      },
      {
        $unwind: '$regatheredClients',
      },
      {
        $replaceRoot: {
          newRoot: '$regatheredClients',
        },
      },
      {
        $addFields: {
          clients: '$clients',
        },
      },
    ];

    const invitedLessons = await this.lessonsCollection?.aggregate(agg);
    return invitedLessons;
  }

  async getLessonsCountForUserBetweenDates(
    orgId: string,
    userId: string,
    startDate: number,
    endDate: number,
  ): Promise<number | undefined> {
    return this.lessonsCollection?.count({
      org_id: orgId,
      clients: {
        $elemMatch: {
          user_id: userId,
          is_accepted: true,
        },
      },
      is_self_taught: false,
      date_start: { $gt: startDate, $lt: endDate },
    });
  }

  async getPracticeLessonsCountForUserBetweenDates(
    orgId: string,
    userId: string,
    startDate: number,
    endDate: number,
  ) {
    return this.lessonsCollection?.count({
      org_id: orgId,
      'clients.user_id': userId,
      is_self_taught: true,
      date_start: { $gt: startDate, $lt: endDate },
    });
  }

  async countUserLessonsMatchingCategoryId(userId: string, categoryId: string) {
    return this.lessonsCollection?.count({
      'clients.user_id': userId,
      org_lesson_category_ids: categoryId,
    });
  }

  async updateClientUserIdsForAllLessons(orgId: string, email: string, userId: string) {
    return this.lessonsCollection?.updateMany(
      {
        org_id: orgId,
        'clients.email': email,
        'clients.user_id': { $in: ['guest', null] },
      },
      {
        $set: {
          'clients.$.user_id': userId,
        },
      },
    );
  }

  async getAllLessonsByUpfrontPaymentId(upfrontPaymentId: string): Promise<Lesson[] | undefined> {
    return this.lessonsCollection?.find({
      upfront_payment_id: upfrontPaymentId,
    });
  }

  async getUserAcceptedOrCancelledLessonsByUserIdOrEmailAndOrgId(
    userId: any,
    email: string,
    orgId: string,
    selectedTab: number,
    selectedParentTab: number,
    selectedCategories: string[],
    searchValue: string,
    currentIndex: number,
    pageSize: number,
  ): Promise<Lesson[] | undefined> {
    const searchValues = searchValue.split(' ');
    return this.lessonsCollection?.aggregate([
      ...LessonsRepository.addSearchAgg(searchValues),
      ...LessonsRepository.filterCategoriesForTabs(selectedCategories),
      ...LessonsRepository.filterSearchForTabs(selectedTab, selectedParentTab, userId),
      {
        $match: {
          $or: [
            {
              'clients.user_id': userId,
              'clients.is_accepted': true,
              'clients.status': {
                $in: [LessonClientStatus.Accepted, LessonClientStatus.Cancelled],
              },
            },
            {
              $and: [
                { 'clients.user_id': { $in: ['guest', null, ''] } },
                { 'clients.email': email },
                { 'clients.is_accepted': true },
                {
                  'clients.status': {
                    $in: [LessonClientStatus.Accepted, LessonClientStatus.Cancelled],
                  },
                },
              ],
            },
          ],
        },
      },
      {
        $lookup: {
          from: 'organization',
          localField: 'org_id',
          foreignField: '_id',
          as: 'organization',
        },
      },
      {
        $unwind: '$organization',
      },
      {
        $match: {
          'organization._id': orgId,
        },
      },
      ...LessonsRepository.sortForTabs(selectedTab),
      {
        $skip: currentIndex * pageSize,
      },
      {
        $limit: pageSize,
      },
    ]);
  }

  async getUserAcceptedLessonByIdAndIsValid(
    lessonId: string,
    userId: string,
    email: string,
  ): Promise<Lesson | undefined> {
    return this.lessonsCollection
      ?.aggregate([
        {
          $lookup: {
            from: 'organization',
            localField: 'org_id',
            foreignField: '_id',
            as: 'organization',
          },
        },
        {
          $unwind: '$organization',
        },
        {
          $lookup: {
            from: 'org_locations',
            localField: 'org_id',
            foreignField: 'org_id',
            as: 'locations',
          },
        },
        {
          $unwind: '$locations',
        },
        {
          $match: {
            _id: lessonId,
            clients: {
              $elemMatch: {
                $or: [{ user_id: userId }, { email: email }],
                is_accepted: true,
                // Ensure that 'Accepted' matches the exact value in your database
                status: 'Accepted',
              },
            },
          },
        },
        {
          $limit: 1, // Ensure only one document is returned if needed
        },
      ])
      .then((results) => results[0]); // Convert to array and get the first result
  }

  async getLessonInvitationEmailByInvitationId(invitationId: string): Promise<string | undefined> {
    const result = await this.lessonsCollection?.findOne({
      clients: { $elemMatch: { invite_id: invitationId } },
    });
    if (result) {
      const lesson = result as Lesson;
      const invitedClient = lesson.clients.find((client) => client.invite_id === invitationId);
      if (invitedClient) {
        return invitedClient.email;
      }
    }
    return undefined;
  }

  // #endregion
}
