import { v4 } from 'uuid';
import { DateTime } from 'luxon';
import { Coach, NeverEndingPattern, ScheduledDate } from 'modules/service/models/service.model';
import { WorkingHour } from 'data/repos/services.repo';
import { DefaultValues } from '../../../common/utils/defaultValues.helper';
import { ServiceTypeEnum } from '../../../data/enums/ServiceType.enum';
import { ServiceInviteStatusEnum } from '../../../data/entities/service.entity';
import { ServicePrepaidPaymentModel } from '../../../data/models/balance/servicePayment.model';

export interface IconAndText {
  id: string;
  icon?: string;
  text: string;
  renderElement?: JSX.Element;
}

export interface Service {
  id: string;
  title: string;
  orgId: string;
  locationIds: string[];
  customServiceTypeId?: string;
  serviceTypeEnum?: ServiceTypeEnum;
  durationInMinutes: number;
  priceInCents: number;
  maxParticipants: number;
  isCalendarAppointment?: boolean;
  unscheduledPackageOccurrences?: number;
  coaches: Coach[];
  invitedClients?: ServiceInvitedClient[];
  assistanceStaff: AssistanceStaff[];
  isPackage: boolean;
  scheduledDates: ScheduledDate[];
  isNeverEnding: boolean;
  neverEndingPattern?: NeverEndingPattern;
  isPresetWorkHours: boolean;
  allowGuests: boolean;
  isInviteOnly: boolean;
  isFlexibleStartTime: boolean;
  packageOccurrences: number;
  packageStartDate: number;
  packageId?: string;
  isPaymentInApp: boolean;
  isFullyBooked: boolean;
  isPackageCancelled: boolean;
  taxRateIds?: string[];
  isPackageType?: boolean;
  isPrepaidType?: boolean;
  prepaidPayment?: ServicePrepaidPaymentModel;
  isUpfrontPayment?: boolean;
}

export interface OrgBookingRules {
  id: string;
  orgId: string;
  cancellationPeriodInMinutes: number;
  timeslotPeriodInMinutes: number;
  isBookingEnabled: boolean;
  weeksInAdvance: number;
}

export class OrgBookingRules implements OrgBookingRules {
  /**
   * Creates a new OrgBookingRules with default values
   * @param orgId
   */
  constructor(orgId: string) {
    this.id = v4();
    this.orgId = orgId;
    this.timeslotPeriodInMinutes = DefaultValues.BookingRules.timeSlotPeriodInMinutes;
    this.cancellationPeriodInMinutes = DefaultValues.BookingRules.cancellationPeriodInMinutes;
    this.isBookingEnabled = true;
    this.weeksInAdvance = DefaultValues.BookingRules.weeksInAdvance;
  }
}

export interface SelectedService extends Service {
  afterOccurrence?: number;
  coachId?: string;
  isCalendarActive?: boolean;
  addedAt?: number;
  count?: number;
  availableMonths?: AvailableMonth[];
  chosenCoachId: string;
  chosenDates?: ScheduledDate[];
  currentDateIndex?: number;
  selectedDay?: AvailableDay;
  selectedDate?: DateTime;
  busyTimes?: ScheduledDate[];
  packageParticipants?: number;
  packagePositionsLeft?: number;
  isSingleDateAndTime: boolean;
  packagesBought?: SelectedServicePackagesBought;
  workingHoursIntersection?: WorkingHour[];
  taxRateIds?: string[];
}

export interface SelectedServicePackagesBought {
  serviceId: string;
  lessonsOccurrencesBought?: number;
  lessonsValueInCents?: number;
  lessonsTaken?: number;
  lessonsRemaining?: number;
  paymentId?: string;
  totalPackagesBought?: number;
}

export interface AssistanceStaff {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface ServiceInvitedClient {
  inviteId: string;
  userId: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  inviteStatusEnum?: ServiceInviteStatusEnum;

  isAccepted?: boolean;
  isPaid?: boolean;
  isPaymentInApp?: boolean;

  /** Epoch milliseconds */
  dateSent?: number;
  dateAccepted?: number;
  dateUpdated?: number;
  deleted?: boolean;

  selectedCoachId?: string;
}

export interface AvailableMonth {
  month: DateTime;
  availableDays: AvailableDay[];
}

export interface AvailableDay {
  day: number;
  date?: DateTime;
  isDayFullyBooked: boolean;
  morningTimes: AvailableDate[];
  afternoonTimes: AvailableDate[];
}

export interface AvailableDate {
  date: DateTime;
  isBusyTime: boolean;
  isPurchasedTime: boolean;
  isUserSelected: boolean;
}

export interface TimeSlots {
  morningTimes: AvailableDate[];
  afternoonTimes: AvailableDate[];
}

export interface CreateLessonModel {
  orgId: string;
  serviceId: string;
  userId: string;
  email?: string;
  locationId: string;
  coachId: string;
  scheduledDates: ScheduledDate[];
  allowGuests: boolean;
  isPaymentInApp: boolean;
  isFullyBooked: boolean;
  userInviteId?: string;
  paymentIntent?: string;
  taxRateIds?: string[];
}

export interface LessonValidateModel {
  orgId: string;
  lessonId: string;
  userId: string;
  email: string;
  userInviteId?: string;
}

export interface PackageValidateModel {
  orgId: string;
  serviceId: string;
  packageId: string;
  userId: string;
  email: string;
  userInviteId?: string;
}

export interface ServiceValidateModel {
  orgId: string;
  serviceId: string;
  userId: string;
  email: string;
  scheduledDates: ScheduledDate[];
  selectedCoachId: string;
  userInviteId?: string;
}

export interface CreateLessonPackagesModel extends CreateLessonModel {
  packageId: string;
}

export interface GetLessonPositionsLeftModel {
  orgId: string;
  serviceId: string;
  maxParticipants: number;
  locationId: string;
  scheduledDates: ScheduledDate[];
}

export enum SessionStatusEnum {
  Completed,
  Incomplete,
  Expired,
}

export interface Session {
  id: string;
  expires?: number;
  expiresText?: string;
}
