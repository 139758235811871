export enum ButtonType {
  Save = 1,
  Delete,
  Update,
  Create,
  PracticeLessons,
}

export enum ButtonSavingType {
  save = 0,
  saving = 1,
  saved = 2,
}
