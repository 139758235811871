export interface IQuestClient {
  dob: string;
  gender: number;
  contactPreference: string;
  homeAddress: string;
  birthPlace: string;
  occupation: string;
  qualification: string;
  previousCoach: string;
  golfClub: string;
  goalAndIssues: string;
  otherSports: string;
  legDomination: number;
  handDomination: number;
  eyeDomination: number;
  phone: string;
}

export const QuestionnaireType = {
  Phone: 1,
  MultiTask: 2,
  Choice: 3,
  Address: 4,
  Date: 5,
};

export const QuestionnaireStages = {
  Start: 1,
  Pending: 2,
  Finish: 3,
};

export const allQuestions = [
  {
    question: 'Phone Number',
    field: 'phone',
    fieldCountry: 'fieldCountry',
    fieldNumber: 'fieldNumber',
    type: QuestionnaireType.Phone,
  },
  {
    question: 'Which sport club or clubs do you belong to?',
    field: 'golfClub',
    type: QuestionnaireType.MultiTask,
  },
  {
    question: 'Previous coach(es)',
    field: 'previousCoach',
    type: QuestionnaireType.MultiTask,
  },
  {
    question: 'Previous sports',
    field: 'PreviousSport',
    type: QuestionnaireType.MultiTask,
  },
  {
    question: 'Positions',
    field: 'position',
    type: QuestionnaireType.MultiTask,
  },
  {
    question: 'What is your dominant eye?',
    details: 'If you have to look through one eye which eye will stay open?',
    field: 'eyeDomination',
    type: QuestionnaireType.Choice,
    choices: [
      { label: 'Left', value: 1 },
      { label: 'Ambidextrous', value: 3 },
      { label: 'Right', value: 2 },
    ],
  },
  {
    question: 'What is your dominant hand?',
    field: 'handDomination',
    type: QuestionnaireType.Choice,
    choices: [
      { label: 'Left', value: 1 },
      { label: 'Ambidextrous', value: 3 },
      { label: 'Right', value: 2 },
    ],
  },
  {
    question: 'What is your dominant leg?',
    field: 'legDomination',
    type: QuestionnaireType.Choice,
    choices: [
      { label: 'Left', value: 1 },
      { label: 'Ambidextrous', value: 3 },
      { label: 'Right', value: 2 },
    ],
  },
  {
    question: 'What are your sporting goals?',
    field: 'goals',
    type: QuestionnaireType.MultiTask,
  },
  {
    question: 'What are your sporting issues?',
    field: 'issues',
    type: QuestionnaireType.MultiTask,
  },
  {
    question: "What's your home address?",
    field: 'homeAddress',
    type: QuestionnaireType.Address,
  },
  {
    question: 'Gender',
    field: 'gender',
    type: QuestionnaireType.Choice,
    choices: [
      { label: 'Male', value: 'male' },
      { label: 'Female', value: 'female' },
      { label: 'Other', value: 'other' },
    ],
  },
  {
    question: 'Date of birth',
    field: 'dob',
    type: QuestionnaireType.Date,
  },
];
