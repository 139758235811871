import { Api } from '../../api/Api';
import Logger from '../../middleware/logger.middleware';
import { getOrgId } from './common.helpers';

export default class UserHelper {
  /**
   * Wrapper to get the client's credit and then set state value passed.
   * @param userId
   * @param setCredits
   */
  public static async GetCredits(userId: string, setCredits: (credits: number) => void) {
    const orgId = getOrgId();
    const response = await Api.ClientRoutes.Balance.getClientCreditsForOrgAsync(userId, orgId);
    if (response.data) {
      setCredits(response.data);
      return;
    }

    if (Logger.isDevEnvironment) {
      console.warn(`Could not find credits for user: ${userId}`);
    }
  }

  public static async GetCreditsAsync(userId: string): Promise<number | undefined> {
    const orgId = getOrgId();
    const response = await Api.ClientRoutes.Balance.getClientCreditsForOrgAsync(userId, orgId);
    if (!response.data) {
      if (Logger.isDevEnvironment) {
        console.warn(`Could not find credits for user: ${userId}`);
      }
    }
    return response.data;
  }
}
