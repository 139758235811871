import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect, { SelectChangeEvent } from '@mui/material/Select';

export const Select = ({ id, label, value, options, onChange, disabled = false }: SelectProps) => {
  const labelId = `${id}-label`;

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <MuiSelect
          labelId={labelId}
          id={id}
          value={value}
          label={label}
          onChange={onChange}
          disabled={disabled}
        >
          {options.map((option) => {
            return (
              <MenuItem value={option.id} key={option.id} selected={value === option.id}>
                {option.label}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

interface SelectProps {
  id: string;
  label: string;
  value: string;
  options: SelectOption[];
  onChange: (event: SelectChangeEvent) => void;
  disabled?: boolean;
}

interface SelectOption {
  id: string;
  label: string;
}
