import React from 'react';
import { Button } from 'components/Button/MuiButtons/Button';
import styles from './BookingWrapper.module.scss';
import BookingButton from '../BookingButton/BookingButton';

interface BookingWrapperProps {
  children?: React.ReactNode;
  backButton?: {
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
  };
  continueButton?: {
    label?: string;
    disabled?: boolean;
    onClick?: () => void;
  };
}

const BookingWrapper = (props: BookingWrapperProps) => {
  const { children, backButton, continueButton } = props;
  return (
    <>
      <div className={styles.childrenWrapper}>{children}</div>
      <div className={styles.footerWrapper}>
        <div className="d-flex">
          {backButton?.onClick && (
            <Button
              sx={{
                bgcolor: 'rgba(0, 0, 0, 0.25) !important',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.5) !important',
                },
              }}
              label="Back"
              onClick={backButton?.onClick}
            />
          )}
          {continueButton?.onClick && (
            <BookingButton
              isDisabled={continueButton.disabled}
              text={continueButton.label || 'Continue'}
              onClick={continueButton?.onClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default BookingWrapper;
