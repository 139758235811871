/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch } from 'react';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import LoginPage from '../modules/auth/pages/Login/login-page';
import ForgotPasswordPage from '../modules/auth/pages/ForgotPassword/forgot-password-page';
import ResetPasswordPage from '../modules/auth/pages/ResetPassword/ResetPasswordPage';
import SignUpPage from '../modules/auth/pages/Signup/signup-page';
import SearchClient from '../modules/auth/components/SearchClient/SearchClient';

const history = createBrowserHistory({ forceRefresh: true });
export interface Props {
  setUser: (user: any) => void;
}
const LoginLayout = () => {
  return (
    <Router history={history}>
      <Route exact path="/" component={LoginPage} />
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/login/forgotPassword" component={ForgotPasswordPage} />
      <Route exact path="/login/password/reset" component={ResetPasswordPage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route exact path="/search" component={SearchClient} />
    </Router>
  );
};

export default LoginLayout;
