import { IAttachment } from '../../attachment/models/attachment';
import { IListItem } from '../../../components/Pages/models/listItem.model';

export const LessonsTabs = {
  Upcoming: 0,
  Past: 1,
  Canceled: 2,
  All: 3,
};

export const LessonsParentTabs = {
  Individual: 0,
  Group: 1,
};
export interface LessonOld extends IListItem {
  attachments: IAttachment[];
  duration: number;
  isCancellationAvailable: boolean;
  isSelfTaught: boolean;
}
