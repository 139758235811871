/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { isEqual } from 'lodash';
import { Lesson } from '../../../data/entities/lessons.entity';
import { Event } from '../../../data/entities/events.entity';
import CancelLesson from '../../../modules/lesson/components/CancelLesson/CancelLesson';

import styles from './LessonListItem.module.scss';
import DateList from '../DateList/DateList';
import { getBaseOrgRoute } from '../../../common/utils/common.helpers';
import LessonHelper from '../../../common/utils/lessons.helper';
import { OrgBookingRules } from '../../../modules/book/models/booking.model';
import { DefaultValues } from '../../../common/utils/defaultValues.helper';
import SessionProvider from '../../../providers/SessionProvider';

interface LessonListItemProps {
  item: Lesson | Event;
  cancelLesson: (lessonId: string, reason: string) => void;
  onCancelPackage: Function;
  orgBookingRules?: OrgBookingRules;
}

const LessonListItem: React.FC<LessonListItemProps> = ({
  item,
  cancelLesson,
  onCancelPackage,
  orgBookingRules,
}) => {
  const [locationName, setLocationName] = useState<string>('');
  const history = useHistory();
  const orgBaseRoute = getBaseOrgRoute(window.location.pathname);
  const onRedirectToLessonPage = (lessonId: string) => {
    history.push(`${orgBaseRoute}/lesson/${lessonId}`);
  };
  const timeZone = SessionProvider.getTimeZoneInfo();

  useEffect(() => {
    (item as Lesson).org_location?.locations.forEach((location) => {
      if (
        item.organization?.addresses?.length &&
        isEqual(location.address, item.organization?.addresses[0])
      ) {
        setLocationName(location.name);
      }
    });
  }, [item]);

  const lessonCancellationResult = LessonHelper.GetCancellationResult(
    item as Lesson,
    orgBookingRules?.cancellationPeriodInMinutes ??
      DefaultValues.BookingRules.cancellationPeriodInMinutes,
  );

  const allowLessonCancellation = LessonHelper.AllowCancellation(lessonCancellationResult);
  /**
   * We check if item is a lesson to see if we need to use the org timezone. But if it is a practices we user the user's local time.
   */
  const useOrgTimeZone =
    (item as Lesson)._id !== null &&
    (item as Lesson)._id !== undefined &&
    (item as Lesson).is_self_taught === false;

  return (
    <div className={styles.lessonItem}>
      <div
        key={(item as Event)._id || (item as Lesson)._id}
        className={styles.lessonWrapper}
        onClick={() => onRedirectToLessonPage((item as Event)._id || (item as Lesson)._id)}
      >
        <DateList
          dateUnixMilli={(item as Event).date_begin || (item as Lesson).date_start}
          timeZoneInfo={useOrgTimeZone ? timeZone : undefined}
        />
        <div className={styles.titleColumn}>
          <div className={styles.lessonTitle}>{item.title}</div>
          <div className={styles.lessonCoachName}>{item.organization?.org_name}</div>
          <div className={styles.lessonLocationName}>{locationName}</div>
          {item.organization?.addresses?.length ? (
            <div className={styles.eventAddress}>
              {item.organization.addresses[0].street_number}{' '}
              {item.organization.addresses[0].street_name}, {item.organization.addresses[0].suburb},{' '}
              {item.organization.addresses[0].city} {item.organization.addresses[0].zip_code}
            </div>
          ) : (
            ''
          )}
        </div>
        {LessonHelper.isLessonCancelled(item as Lesson) && (
          <span className={styles.canceledLabel}>Canceled</span>
        )}

        {allowLessonCancellation && (
          <div
            className={styles.dropdown}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <CancelLesson
              lesson={item as Lesson}
              cancelLesson={(reason: string) =>
                cancelLesson((item as Event)._id || (item as Lesson)._id, reason)
              }
              onCancelPackage={onCancelPackage}
              lessonCancellation={lessonCancellationResult}
              cancellationPeriodInMinutes={
                orgBookingRules?.cancellationPeriodInMinutes ??
                DefaultValues.BookingRules.cancellationPeriodInMinutes
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LessonListItem;
