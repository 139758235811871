import React, { useContext } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { BrandThemeContext } from 'common/context';

interface BookingSelectionItemProps {
  children: React.ReactNode;
  isSelected: boolean;
}

export const BookingSelectionItem = ({ children, isSelected }: BookingSelectionItemProps) => {
  const theme = useContext(BrandThemeContext);
  return (
    <Box
      component="div"
      bgcolor={isSelected ? theme.colors.black.opacity_5 : theme.colors.white}
      borderRadius={1.5}
      padding={1}
      sx={{ cursor: 'pointer' }}
    >
      <Stack direction="row" spacing={3} sx={{ alignItems: 'center' }}>
        {children}
      </Stack>
    </Box>
  );
};
