/* eslint-disable max-classes-per-file */
import { Config } from '../../config';
import Logger from '../../middleware/logger.middleware';
import { UserSubscriptionsModel } from '../../data/models/userSubscription.mode';
import { PortalSubscriptionStatusEnum } from '../../data/enums/PortalSubscriptionStatus.enum';

class ProductTypes {
  public readonly FreePlanProductId: string;

  /**
   *
   */
  constructor() {
    const prodId = Config().StripeFreeProductId;

    if (!prodId) {
      console.error('REACT_APP_STRIPE_FREE_PRODUCT_ID has not been set.');
      console.error('This will effect the billing module');
      this.FreePlanProductId = '';
    } else {
      this.FreePlanProductId = prodId;
    }
  }
}

export class BillingHelper {
  /** A list of properties with product_id's from stripe */
  public static ProductTypes = new ProductTypes();

  /** Runs through a series of checks to see if the user is allowed access. */
  static allowUserAccess(userSubscriptionModel?: UserSubscriptionsModel): boolean {
    if (
      userSubscriptionModel === undefined ||
      userSubscriptionModel === null ||
      !userSubscriptionModel
    ) {
      if (Logger.isDevEnvironment) console.info('User does not have a subscription.');
      return false;
    }

    const activeSubscription =
      userSubscriptionModel.portal_subscription_status_enum ===
        PortalSubscriptionStatusEnum.Active ||
      userSubscriptionModel.portal_subscription_status_enum ===
        PortalSubscriptionStatusEnum.Trialing;
    if (!activeSubscription) {
      if (Logger.isDevEnvironment) {
        console.info(
          `User's portal_subscription_status_enum !== Active || Trialing. status = ${userSubscriptionModel.portal_subscription_status_enum}`,
        );
      }
      return false;
    }

    return true;
  }

  static isTrial(userSubscriptionModel?: UserSubscriptionsModel): boolean {
    if (!userSubscriptionModel) {
      if (Logger.isDevEnvironment) {
        console.warn('Users billing subscription is undefined.');
      }
      return false;
    }

    return (
      userSubscriptionModel.portal_subscription_status_enum ===
      PortalSubscriptionStatusEnum.Trialing
    );
  }
}
