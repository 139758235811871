import { LessonsRepository } from '../repos/lessons.repo';
import { OrgLessonCategoryRepository } from '../repos/orgLessonCategories.repo';
import { EventsRepository } from '../repos/events.repo';
import { UsersRepository } from '../repos/users.repo';
import { FavoritesRepository } from '../repos/favorites.repo';
import { AttachmentsRepository } from '../repos/attachments.repo';
import { ExercisesRepository } from '../repos/exercises.repo';
import { UserContactsRepository } from '../repos/userContacts.repo';
import { SkillRatingRepository } from '../repos/skillRating.repo';
import { UserDetailsRepository } from '../repos/userDetails.repo';
import { OrganizationRepository } from '../repos/organization.repo';
import { ServicesRepository } from '../repos/services.repo';
import { OrgInvitesRepository } from '../repos/orgInvites.repo';
import { OrgClientsInvitesRepository } from '../repos/orgClientsInvites.repo';
import FilesRepository from '../repos/files.repo';
import OrgClientsRepository from '../repos/orgClients.repo';
import OrgStaffRepository from '../repos/orgStaff.repo';
import { SportInformationRepository } from '../repos/sportInformation.repo';
import PaymentsRepository from '../repos/payments.repo';
import { PromoCodesRepository } from '../repos/promoCodes.repo';
import CreditBalanceRepository from '../repos/creditBalance.repo';
import PackagesBoughtHistoryRepository from '../repos/packagesBoughtHistory.repo';
import { LessonCategoryTypesRepository } from '../repos/lessonCategoryTypes.repo';

export default class RealmRepositories {
  public static Lessons = new LessonsRepository();

  public static OrgLessonCategory = new OrgLessonCategoryRepository();

  public static Events = new EventsRepository();

  public static Favorites = new FavoritesRepository();

  public static Attachments = new AttachmentsRepository();

  public static Exercises = new ExercisesRepository();

  public static UserContacts = new UserContactsRepository();

  public static SkillRating = new SkillRatingRepository();

  public static Users = new UsersRepository();

  public static UserDetails = new UserDetailsRepository();

  public static Sport = new SportInformationRepository();

  public static Organization = new OrganizationRepository();

  public static Services = new ServicesRepository();

  public static OrgInvites = new OrgInvitesRepository();

  public static OrgStaff = new OrgStaffRepository();

  public static OrgClients = new OrgClientsRepository();

  public static OrgClientsInvites = new OrgClientsInvitesRepository();

  public static Files = new FilesRepository();

  public static Payments = new PaymentsRepository();

  public static PromoCodes = new PromoCodesRepository();

  public static CreditBalance = new CreditBalanceRepository();

  public static PackagesBoughtHistory = new PackagesBoughtHistoryRepository();

  public static LessonCategoryTypes = new LessonCategoryTypesRepository();
}
