import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItems } from '../MenuItems';
import { NavMenuItemsType } from '../../../../data/enums/NavMenuItemsType.enum';
import { getBaseOrgRoute } from '../../../../common/utils/common.helpers';

interface INavLinks {
  onClose: any;
}
const NavLinks: React.FC<INavLinks> = ({ onClose }) => {
  const [routeHasBeenChecked, setRouteHasBeenChecked] = useState(false);
  const [orgBaseLink, setOrgBaseLink] = useState<string>();

  useEffect(() => {
    if (!routeHasBeenChecked) {
      const baseOrgRoute = getBaseOrgRoute(window.location.pathname);
      if (baseOrgRoute) {
        setOrgBaseLink(baseOrgRoute);
      }

      setRouteHasBeenChecked(true);
    }
  }, [routeHasBeenChecked]);

  return (
    <>
      {MenuItems.filter((item: any) => item.type === NavMenuItemsType.Link).map(
        (currentItem: any) => {
          return (
            <li key={currentItem.alt}>
              <NavLink
                exact
                to={orgBaseLink ? orgBaseLink + currentItem.path : currentItem.path}
                onClick={onClose}
              >
                <img src={currentItem.icon} alt={currentItem.icon} width={20} height={20} />
                {currentItem.label}
              </NavLink>
            </li>
          );
        },
      )}
    </>
  );
};

export default NavLinks;
