export interface IAttachment {
  id: string;
  url: string;
  note: string | null;
  type: AttachmentType;
  categories: string[];
  coachAttachment: boolean;
  order: number;
  sectionIndex: AttachmentSectionIndex;
  thumbnailUrl: string;
  userNote: string | null;
  isFavorite: boolean;
}

export enum AttachmentType {
  Photo = 1,
  Video = 2,
  Pdf = 3,
  Text = 4,
}

export enum AttachmentSectionIndex {
  ReasonOfLesson = 1,
  TheSolution = 2,
  ClientUploads = 0,
}

export const CoachUploads = 4;

export const AttachmentSection: Record<AttachmentSectionIndex, string> = {
  [AttachmentSectionIndex.ReasonOfLesson]: 'Reason for Lesson',
  [AttachmentSectionIndex.TheSolution]: 'The Solution',
  [AttachmentSectionIndex.ClientUploads]: 'Client Uploads',
};
