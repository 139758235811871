import React, { useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthMenu from '../../components/AuthMenu/AuthMenu';
import InputField from '../../../../components/InputField/InputField';
import logo from '../../../../assets/images/auth/forgotPassword-image.png';
import { Api } from '../../../../api/Api';

interface IProps {
  hash: string;
}

const ResetPasswordPage = ({ history }: RouteComponentProps<IProps>) => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token') || '';
  const tokenId = params.get('tokenId') || '';

  const [isReset, setReset] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const headerTitle = !isReset ? 'Create new password' : 'Your password has been reset';
  const btnLabel = !isReset ? 'Reset password' : 'Log In';

  const onPasswordChanged = (e: any) => setPassword(e.target.value);
  const onPasswordConfirmationChanged = (e: any) => setPasswordConfirmation(e.target.value);
  const passwordFormat =
    // Must Contain 8 Characters, One Uppercase, One Lowercase,\n One Number and one special case Character
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  const onResetClicked = async () => {
    if (!password.match(passwordFormat)) {
      toast.error(
        'Must Contain 8 Characters, One Uppercase, One Lowercase,\n One Number and one special case Character',
      );
      return;
    }
    if (password !== passwordConfirmation) {
      toast.error('Passwords do not match.');
      return;
    }

    if (isReset) {
      history.push('/login/');
    } else {
      Api.ClientRoutes.Auth.resetPassword(password, token, tokenId)
        .then(() => {
          setReset(true);
        })
        .catch((err) => {
          toast.error(err.message);
          console.log(err, 'reset password error');
        });
    }
  };

  return (
    <AuthMenu
      logo={logo}
      withWelcome={false}
      headerTitle={headerTitle}
      btnLabel={btnLabel}
      onBtnClicked={onResetClicked}
      isBtn
    >
      {!isReset && (
        <>
          <InputField
            placeholder="Enter your new password"
            type="password"
            required
            onChange={onPasswordChanged}
          />

          <InputField
            placeholder="Confirm password"
            type="password"
            required
            onChange={onPasswordConfirmationChanged}
          />
        </>
      )}
    </AuthMenu>
  );
};

export default withRouter(ResetPasswordPage);
