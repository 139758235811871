import React, { FC } from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'components/Grid';
import { bookingSlice } from 'redux/slices';
import { RootState } from 'redux/store';

export interface PaymentOptionProps {
  isShowPaymentOtions: boolean;
}

export const PaymentOption: FC<PaymentOptionProps> = ({ isShowPaymentOtions }) => {
  const dispatch = useDispatch();

  const booking = useSelector((state: RootState) => state.booking);
  const { paymentPreference } = booking.selected;

  if (!isShowPaymentOtions) return null;
  return (
    <Grid item md={12}>
      <Box>
        <RadioGroup row aria-labelledby="payment-preference" name="row-radio-buttons-group">
          <FormControlLabel
            value="outside_app"
            control={
              <Radio
                checked={paymentPreference === 'outside_app'}
                onChange={() => {
                  dispatch(bookingSlice.actions.updatePaymentPreference('outside_app'));
                }}
              />
            }
            label="Pay your coach directly"
          />
          <FormControlLabel
            value="in_app"
            control={
              <Radio
                checked={paymentPreference === 'in_app'}
                onChange={() => dispatch(bookingSlice.actions.updatePaymentPreference('in_app'))}
              />
            }
            label="Pay by credit card"
          />
        </RadioGroup>
      </Box>
    </Grid>
  );
};
