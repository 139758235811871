import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import BookingPageV2 from 'modules/book/pages/BookingPageV2';
import { BookingStep, bookingSlice, authenticationSlice } from 'redux/slices';
import { StaticRoutes } from 'common/utils/routes/StaticRoutes.helper';
import { RootState } from 'redux/store';
import { useSearchParams } from 'common/hooks/useSearchParams';
import SessionProvider from '../../../../providers/SessionProvider';
import { PackageValidateModel, SelectedServicePackagesBought } from '../../models/booking.model';
import { AxiosErrorHandler } from '../../../../common/utils/errorHandler.helpers';
import { BookingHelper } from '../../utils/booking.helpers';
import ServicesService from '../../../../data/services/services.service';
import { Api } from '../../../../api/Api';
import StaticDictionary from '../../../../common/utils/staticDictionary.helper';
import { BookingContainer } from './components';
import { useService } from './hooks';

const defaultError =
  'Service could not be found. It has either already occurred, is now fully booked, you were not invited or no longer exists';

const invitationError = 'Please sign in into your account first to see your invited service';

const ServiceInvitePage = () => {
  const authentication = useSelector((state: RootState) => state.authentication);
  const { serviceId } = useParams<Record<string, string | undefined>>();
  const { orgId } = useParams<Record<string, string>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const query = useSearchParams();
  const invitationId = query.get(StaticDictionary.UrlParameters.Booking.InvitationId) ?? '';

  const [isError, setIsError] = useState<string>('');

  const userId = SessionProvider.getUserId();
  const email = SessionProvider.getEmail();

  const dispatch = useDispatch();
  const history = useHistory();

  const { getServiceDetails } = useService();

  const {
    getCoachAndLocation,
    getOrgDetails,
    isDetailedServiceTimeslotSelectionFlowNeeded,
    isAdvancedLesson,
  } = useService();

  /**
   * Handle error and set the error message.
   * @param message The error message to be set.
   */
  const handleError = (message: string) => {
    setIsError(message);
  };

  /**
   * Validate the lesson package for the selected service.
   * @param serviceIdParam The selected service id.
   */
  const isServiceBookable = async (serviceIdParam: string) => {
    const selectedService = getServiceDetails(serviceIdParam);
    if (selectedService !== null)
      if (
        !isDetailedServiceTimeslotSelectionFlowNeeded(selectedService) &&
        !isAdvancedLesson(selectedService) &&
        !!selectedService.packageId
      ) {
        const lessonPackageValidateModel: PackageValidateModel = {
          packageId: selectedService.packageId,
          orgId,
          serviceId: selectedService.id,
          userId: SessionProvider.getUserId(),
          email: SessionProvider.getEmail(),
          userInviteId: invitationId,
        };

        try {
          await Api.ClientRoutes.Lessons.validateLessonPackageForUser(lessonPackageValidateModel);
          return true;
        } catch (err: any) {
          const message = AxiosErrorHandler.getErrorMessage({
            request: err.request,
            response: err.response,
            message: err.message,
          });

          handleError(message);
          return false;
        }
      }

    return true;
  };

  /**
   * Update the store with selected services and booking rules.
   * @param serviceDurationInMinute The coach and location information.
   */

  /**
   * It already loads the org booking rules from database. If the tester found some issue,
   * we can simply re-enable this. If not, then we can clean this up later
   */

  // const updateOrgBookingRules = (durationInMinutes: number) => {
  //   const orgBookingRules = new OrgBookingRules(orgId);
  //   dispatch(
  //     applicationSlice.actions.getOrgBookingRules({
  //       id: orgBookingRules.id,
  //       orgId: orgBookingRules.orgId,
  //       cancellationPeriodInMinutes: orgBookingRules.cancellationPeriodInMinutes,
  //       timeslotPeriodInMinutes: durationInMinutes,
  //       isBookingEnabled: orgBookingRules.isBookingEnabled,
  //       weeksInAdvance: orgBookingRules.weeksInAdvance,
  //     }),
  //   );
  // };

  /**
   * Load services based on the invitation ID and service ID.
   */
  const processServiceInvitation = async () => {
    try {
      setIsLoading(true);
      dispatch(bookingSlice.actions.resetBooking());
      await getOrgDetails(orgId);

      if (!serviceId || !invitationId) {
        handleError('Your invitation is not valid');
        setIsLoading(false);
        return;
      }

      const selectedService = await ServicesService.getServiceInviteByIdAsync(
        serviceId,
        orgId,
        invitationId,
        userId,
        email,
      );

      if (!selectedService) {
        const finalErrorMessage = !userId && invitationId ? invitationError : defaultError;
        handleError(finalErrorMessage);
        setIsLoading(false);
        return;
      }

      const coachAndLocation = await getCoachAndLocation({
        orgId,
        serviceParam: selectedService,
      });

      const updatedSelectedService = {
        id: selectedService.id,
        scheduledDates: selectedService.scheduledDates,
        packagesBought: selectedService.packagesBought as SelectedServicePackagesBought,
        addedAt: DateTime.now().toMillis(),
        isCalendarActive: true,
        coachId: coachAndLocation.coachId,
      };

      dispatch(bookingSlice.actions.getServices([selectedService]));
      dispatch(bookingSlice.actions.updateIsServiceInvite(invitationId));
      dispatch(
        bookingSlice.actions.updatePaymentPreference(
          selectedService.isPaymentInApp ? 'in_app' : 'outside_app',
        ),
      );

      /**
       * In case a service is available in multiple locations, we will need to ask the client to choose the location first
       */
      if (selectedService.locationIds.length > 1) {
        dispatch(bookingSlice.actions.changeStep(BookingStep.FindCoachLocation));
        setIsLoading(false);
        return;
      }

      /**
       * If it is a service with single scheduled date, let's go to the summary page
       */
      if (
        !isDetailedServiceTimeslotSelectionFlowNeeded(selectedService) ||
        (BookingHelper.isServiceFixedStartTime(selectedService) &&
          selectedService.scheduledDates.length === 1)
      ) {
        if (selectedService.isFullyBooked) {
          const message = `Service '${selectedService.title}' is fully booked`;
          handleError(message);
          setIsLoading(false);
          return;
        }

        if (selectedService.isPackageCancelled) {
          const message = `Service '${selectedService.title}' has been canceled`;
          handleError(message);
          setIsLoading(false);
          return;
        }

        const isBookable = await isServiceBookable(selectedService.id);

        if (isBookable) {
          dispatch(
            bookingSlice.actions.updateSelectedServices([
              {
                ...updatedSelectedService,
                count: 1,
                isCalendarActive: false,
              },
            ]),
          );

          /**
           * It already loads the org booking rules from database. If the tester found some issue,
           * we can simply re-enable this. If not, then we can clean this up later
           */
          // updateOrgBookingRules(selectedService.durationInMinutes);

          // If it is a service with single scheduled date, let's go to the summary page
          dispatch(bookingSlice.actions.changeStep(BookingStep.Summary));
          if (isError) handleError('');
        }
      }

      /**
       * if the service is fully selected, let's go to the summary page.
       */

      if (!isDetailedServiceTimeslotSelectionFlowNeeded(selectedService)) {
        dispatch(
          bookingSlice.actions.updateSelectedServices([
            {
              ...updatedSelectedService,
              count: 1,
            },
          ]),
        );

        // if the service is fully selected, let's go to the summary page.
        dispatch(bookingSlice.actions.changeStep(BookingStep.Summary));
      } else {
        /**
         * If the service is not fully selected, let's go to the select service page.
         */
        dispatch(
          bookingSlice.actions.updateSelectedServices([
            {
              ...updatedSelectedService,
              count: 0,
              scheduledDates: [],
            },
          ]),
        );
        dispatch(bookingSlice.actions.changeStep(BookingStep.SelectService));
      }

      if (isError) handleError('');
    } catch (err: any) {
      const message = AxiosErrorHandler.getErrorMessage({
        request: err.request,
        response: err.response,
        message: err.message,
      });

      handleError(message);
    }

    setIsLoading(false);
  };

  const openSignInModal = async () => {
    /** Open signin popup if user is not signed in */
    if (!authentication.authenticatedUser) {
      if (invitationId) {
        const invitedEmail = await ServicesService.getServiceInvitationEmailByInvitationId(
          invitationId,
        );

        dispatch(
          authenticationSlice.actions.updateInvitedUser(
            invitedEmail
              ? {
                  email: invitedEmail,
                }
              : null,
          ),
        );

        dispatch(authenticationSlice.actions.changeSignInOpen(true));
      }
    }
  };

  useEffect(() => {
    processServiceInvitation();
    openSignInModal();
  }, []);

  return isError !== '' || isLoading ? (
    <BookingContainer
      isLoading={isLoading}
      title="Service Invitation"
      continueButton={{
        label: 'View services',
        onClick: () => {
          history.push(`${StaticRoutes.Org}/${orgId}${StaticRoutes.Book}`);
        },
      }}
    >
      {isError !== invitationError && (
        <div className="alert alert-secondary text-center" role="alert">
          {isError}. Please view available services below.
        </div>
      )}
    </BookingContainer>
  ) : (
    <BookingPageV2 />
  );
};

export default ServiceInvitePage;
