import { BillingHelper } from './billing.helper';
import { UserSubscriptionsModel } from '../../data/models/userSubscription.mode';
import { BillingModuleRoutes } from './routes/BillingModuleRoutes.helper';

export class SubscriptionsHelper {
  /**
   * Determines if a list item should be shown.
   * @param i current index of iteration
   * @param userSubscriptionModel user's subscription
   * @returns TRUE: Valid Subscription or first iteration
   */
  public static showListItem = (i: number, userSubscriptionModel?: UserSubscriptionsModel) => {
    if (BillingHelper.allowUserAccess(userSubscriptionModel) === false) {
      return i === 0 || i === 1;
    }
    return true;
  };

  /**
   * Helps determines where upgrade button should be shown in a list.
   * @param condition pre-condition to check
   * @param array a list containing data
   * @returns number: divides length by 2 and subtracts 1, otherwise -1
   */
  public static showUpgradePlanButtonIndex = (condition: boolean, array: any[] | undefined) => {
    if (!condition || array === undefined) {
      return -1;
    }

    if (array.length < 4 && array.length > 0) {
      return array.length - 1;
    }

    return Math.ceil(array.length / 2) - 1;
  };

  public static redirectToBillingPortal = () => {
    window.location.replace(BillingModuleRoutes.BillingInfo);
  };

  public static redirectToBillingPortalSubscriptions = () => {
    window.location.replace(BillingModuleRoutes.Subscriptions);
  };

  public static redirectToBillingPortalHome = () => {
    window.location.replace(BillingModuleRoutes.Info);
  };
}
