import React from 'react';
import closeButton from '../../../assets/icons/close-btn.svg';

interface ICloseButton {
  onClose: (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  width?: number;
  height?: number;
}

const CloseButton: React.FC<ICloseButton> = ({ onClose, width, height }) => {
  const onCloseEvent = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    document.body.style.overflow = 'auto';
    onClose(event);
  };
  return (
    <img
      src={closeButton}
      alt="close button"
      onClick={onCloseEvent}
      width={width}
      height={height}
    />
  );
};

export default CloseButton;
