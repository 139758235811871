import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MuiPhoneField, MuiPhoneFieldProps } from './phone';
import { Phone } from './interfaces';
import { convertPhoneToString } from './functions';

interface PhoneFieldProps extends MuiPhoneFieldProps {
  label: string;
  hasError?: boolean;
  errorMessage?: string;
  isEditMode?: boolean;
  // eslint-disable-next-line
  onChange: (value: Phone) => void;
}

export const PhoneField = (props: PhoneFieldProps) => {
  const { label, value, hasError, errorMessage, isEditMode, onChange, defaultCountry } = props;
  return (
    <Stack spacing={1}>
      {isEditMode && (
        <>
          <Typography>{label}</Typography>
          <MuiPhoneField value={value} onChange={onChange} defaultCountry={defaultCountry} />
          {hasError && (
            <Typography color="#ff0000" fontSize="0.8rem">
              {errorMessage}
            </Typography>
          )}
        </>
      )}
      {!isEditMode && (
        <>
          <Typography fontWeight="bold">{label}</Typography>
          <Typography>{value && value.phone_number ? convertPhoneToString(value) : '-'}</Typography>
        </>
      )}
    </Stack>
  );
};

export * from './interfaces';

export const emptyPhoneValue: Phone = {
  country_code: '+64',
  country_iso: 'nz',
  name: '',
  phone_number: '',
};
