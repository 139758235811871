import React, { useRef, useState } from 'react';
import styles from './Unfavorite.module.scss';
import DropdownIcon from '../../../../assets/icons/menu/meatball-menu.svg';
import DropdownMenu from '../../../../components/DropdownMenu/DropdownMenu';

interface IProps {
  onUnfavorited: Function;
  id: string;
}

const Unfavorite: React.FC<IProps> = ({ onUnfavorited, id }) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const dropdownTriggerRef = useRef(null);

  return (
    <div>
      <div
        ref={dropdownTriggerRef}
        onClick={(e) => {
          e.stopPropagation();
          setVisibleDropdown(!visibleDropdown);
        }}
      >
        <img className={styles.dropdownIcon} alt="" src={DropdownIcon} />
      </div>
      <div className={styles.dropdown}>
        <DropdownMenu
          visible={visibleDropdown}
          onCloseDropdown={() => setVisibleDropdown(false)}
          outsideRefsIgnore={dropdownTriggerRef}
          data={[
            {
              label: 'Unfavorite',
              onClick: () => onUnfavorited(id),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default Unfavorite;
