import { useSelector } from 'react-redux';
import type { RootState } from 'redux/store';

export const useCoaches = () => {
  const booking = useSelector((state: RootState) => state.booking);

  const getCoaches = (ids: string[]) => {
    const coaches = booking.data.coaches.filter((coach) => ids.find((id) => id === coach.id));
    return coaches;
  };

  const getCoachNameById = (id: string) => {
    const coach = booking.data.coaches.find((coachItem) => coachItem.id === id);
    if (coach) return coach.firstName;
    return '';
  };

  return {
    getCoachNameById,
    getCoaches,
  };
};
