import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import styles from './BannerUpcomingLesson.module.scss';
import ClockIcon from '../../../../assets/icons/clock/clock.svg';
import Banner from '../../../../components/Banner/Banner';
import Spinner from '../../../../components/Spinner/Spinner';
import { getZonedDate } from '../../../../common/utils/date.helpers';
import SessionProvider from '../../../../providers/SessionProvider';
import { BannerUpcomingLessonContainer } from './BannerUpcomingLessonContainer';

interface INextLesson {
  id: string;
  dataBegin: string;
  duration: number;
}
const BannerUpcomingLesson = () => {
  const [nextLesson, setNextLesson] = useState<INextLesson>();
  const [loading, setLoading] = useState(false);
  const [dateBegin, setDateBegin] = useState<DateTime>();

  const fetchLessons = useCallback(async () => {
    setLoading(true);
    try {
      // TODO: 20.04.23 - Get next lesson from realm endpoint.
      const foundNextLesson = undefined;
      if (foundNextLesson) {
        const date = getZonedDate(get(nextLesson, 'dateBegin'), SessionProvider.getTimeZoneInfo());
        setDateBegin(date);
      }
      setNextLesson(foundNextLesson);
      // eslint-disable-next-line no-empty
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, [nextLesson]);

  useEffect(() => {
    fetchLessons();
  }, [fetchLessons]);

  if (loading) {
    return <Spinner color="#000" message="Fetching your lessons..." />;
  }

  return (
    <BannerUpcomingLessonContainer>
      {nextLesson ? (
        <Banner
          content={
            <>
              <img className={styles.icon} alt="clock-icon" src={ClockIcon} />
              Your next lesson {dateBegin?.toFormat('H:mm')} -{' '}
              {dateBegin?.plus({ seconds: nextLesson.duration }).toFormat('h:mm')},{' '}
              {dateBegin?.toFormat('E d MMM yyy')}.
              <Link to={`/lesson/${get(nextLesson, 'id', '')}`} className={styles.link}>
                View here
              </Link>
            </>
          }
        />
      ) : (
        <>&nbsp;</>
      )}
    </BannerUpcomingLessonContainer>
  );
};

export default BannerUpcomingLesson;
