import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';

import styles from './DatePicker.module.scss';
import Calendar from '../Calendar/Calendar';
import Dropdown from '../Dropdown/Dropdown';
import CalendarIcon from '../../assets/icons/navbar-icons/calendar-icon.svg';
import MobileDrawer from '../MobileDrawer/MobileDrawer';

interface IDatePickerProps {
  min?: Date;
  max?: Date;
  date: string | null;
  /**
   *
   * @param date ISO date string (e.g. 2017-04-22T20:47:05.335-04:00)
   * @returns
   */
  onSelect: (date: string) => void;
}

const DatePicker: React.FC<IDatePickerProps> = ({ min, max, date, onSelect }) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  return (
    <>
      <Dropdown
        visible={visibleDropdown}
        onClose={() => setVisibleDropdown(false)}
        content={
          <div className={styles.dropdownContent}>
            <Calendar
              min={min}
              max={max}
              isYearSelection
              selectedDate={date}
              setSelectedDate={(isoDate: string) => onSelect(isoDate)}
            />
          </div>
        }
      >
        <div
          onClick={() => {
            setVisibleDropdown(!visibleDropdown);
            setVisibleDrawer(!visibleDrawer);
          }}
        >
          <input
            className={styles.dateInput}
            type="tel"
            placeholder="00/00/0000"
            value={date ? format(parseISO(date), 'dd/MM/yyyy') : '00/00/0000'}
            disabled
          />
          <img className={styles.calendarIcon} alt="calendar-icon" src={CalendarIcon} />
        </div>
      </Dropdown>
      <MobileDrawer
        title="Select date"
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
      >
        <div className={styles.mobileCalendarWrapper}>
          <Calendar
            min={min}
            max={max}
            isYearSelection
            selectedDate={date}
            setSelectedDate={(isoDate: string) => {
              onSelect(isoDate);
              setVisibleDrawer(false);
            }}
          />
        </div>
      </MobileDrawer>
    </>
  );
};

export default DatePicker;
