/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import { Address, UserContact } from 'data/entities/userContacts.entity';
import SessionProvider from 'providers/SessionProvider';
import UserService from 'data/services/user.service';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import { AssociateWithAvatar } from 'data/entities/associate.entity';
import styles from './EventView.module.scss';
import ModalWindow from '../../../../components/ModalWindow/ModalWindow';
import MobileDrawer from '../../../../components/MobileDrawer/MobileDrawer';
import EventViewContent from '../EventViewContent/EventViewContent';
import { getDateFromUnixMilli, getUnixMilliseconds } from '../../../../common/utils/date.helpers';
import { Event } from '../../../../data/entities/events.entity';

interface EventViewProps {
  event?: Event;
  isPracticeLesson?: boolean;
  visible: boolean;
  onClose: Function;
  createPracticeLesson?: Function;
  createEvent?: Function;
  updateEvent?: Function;
  deleteEvent?: Function;
  calendarSelectedDate?: number;
  categories: LessonCategory[];
}

const EventView: React.FC<EventViewProps> = ({
  event = null,
  isPracticeLesson,
  visible,
  onClose,
  createPracticeLesson = () => null,
  createEvent = () => null,
  updateEvent = () => null,
  deleteEvent = () => null,
  calendarSelectedDate,
  categories,
}) => {
  const [eventTitle, setEventTitle] = useState('');
  const [eventNote, setEventNote] = useState('');
  const [startDate, setStartDate] = useState(calendarSelectedDate ?? getUnixMilliseconds());
  const [startDateIndex, setStartDateIndex] = useState(0);
  const [endDate, setEndDate] = useState(
    getDateFromUnixMilli(startDate).plus({ hour: 1 }).toMillis(),
  );
  const [loading, setIsLoading] = useState(false);
  const [isPractice, setIsPractice] = useState<boolean>(false);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [isShareCoach, setIsShareCoach] = useState<boolean>(false);
  const [address, setAddress] = useState<Address>();
  const [practiceLocations, setPracticeLocations] = useState<UserPracticeLocation[]>([]);
  const [chosenLocationId, setChosenLocationId] = useState<string>();
  const [associates, setAssociates] = useState<AssociateWithAvatar[]>([]);
  const [contacts, setContacts] = useState<UserContact[]>([]);
  const [newContacts, setNewContacts] = useState<UserContact[]>([]);
  const [selectedContactList, setSelectedContactList] = useState<string[]>([]);
  const [addPracticeLocationView, setAddPracticeLocationView] = useState<boolean>(false);
  const [addContactView, setAddContactView] = useState<boolean>(false);

  const fetchPracticeLocations = useCallback(async () => {
    const userId: string = SessionProvider.getUserId();
    const orgId: string = SessionProvider.getOrgId();

    const associatesAsync = await UserService.getAssociatesByUserIdOrgIdAsync(userId, orgId);
    setAssociates(associatesAsync ?? []);

    const userContacts = await UserService.getAllContactsByUserId(userId);
    setContacts(userContacts ?? []);

    const userPracticeLocations = await UserService.getPracticeLocations({ userId, orgId });
    setPracticeLocations(userPracticeLocations);
    if (userPracticeLocations.length > 0) setChosenLocationId(userPracticeLocations[0].location_id);
  }, []);

  useEffect(() => {
    fetchPracticeLocations();
  }, []);

  useMemo(() => {
    if (calendarSelectedDate) {
      setStartDate(calendarSelectedDate);
      setEndDate(getDateFromUnixMilli(calendarSelectedDate).plus({ hour: 1 }).toMillis());
    }
  }, [calendarSelectedDate]);

  useEffect(() => {
    fetchPracticeLocations();

    if (!visible || !event) {
      setStartDate(calendarSelectedDate ?? getUnixMilliseconds());
      setEndDate(getDateFromUnixMilli(startDate).plus({ hour: 1 }).toMillis());
      setEventTitle('');
      setEventNote('');
      setStartDateIndex(0);
      setSelectedContactList([]);
      setSelectedCategories([]);
      setNewContacts([]);
      setIsShareCoach(false);
      return;
    }

    const start = event.date_begin;
    const end = event.date_end;

    setEventTitle(event.title);
    setEventNote(event.note ?? '');
    setStartDate(start);
    setEndDate(end);
    setSelectedContactList(event?.contacts_ids ?? []);
    setSelectedCategories(event.org_lesson_category_ids);
    setChosenLocationId(event.practice_location_id);
    setIsShareCoach(event.share_event_with_coach);

    if (!isPracticeLesson) setStartDateIndex(getDateFromUnixMilli(start).get('hour'));
  }, [visible, event, isPracticeLesson]);

  const googleAddressToFormattedAddress = (addressParam: any) => {
    if (!addressParam) {
      return undefined;
    }

    if (addressParam?.address_components) {
      const addressObject: Address = {};

      addressObject.latitude = addressParam?.lat ?? Infinity;
      addressObject.longitude = addressParam?.lng ?? Infinity;
      addressObject.suburb = '';

      const addressComponents = addressParam?.address_components || {};

      interface AddressKeys {
        [key: string]: string;
      }

      const keysObject: AddressKeys = {
        street_number: 'street_number',
        route: 'street_name',
        sublocality: 'suburb',
        locality: 'city',
        country: 'country',
        postal_code: 'zip_code',
      };

      for (const component of addressComponents) {
        component?.types?.forEach((type: string) => {
          const key = keysObject[type as keyof AddressKeys];

          if (key) {
            (addressObject as any)[key] = component.long_name;

            if (type === 'country') {
              addressObject.country_short_name = component.short_name;
            }
          }
        });
      }

      return addressObject;
    }

    return undefined;
  };

  const onAddAddress = (addressParam: any) => {
    const addressObject = googleAddressToFormattedAddress(addressParam);
    setAddress(addressObject);
  };

  const onSaveClick = useCallback(async () => {
    if (startDate >= endDate) {
      toast.error('End time must be greater than start time');
      return;
    }

    setIsLoading(true);

    const userId = SessionProvider.getUserId();
    const orgId = SessionProvider.getOrgId();
    const updates = {
      practice_locations: practiceLocations,
    };

    await UserService.updateUserDetailsV2({ userId, orgId, updates });

    const contactCreatePromises = newContacts.map((contact) =>
      UserService.createUserContact(contact),
    );
    const contactResults = await Promise.all(contactCreatePromises);
    const contactIds = contactResults.map((result) => result?._id ?? '');

    await fetchPracticeLocations();

    // Save created practice locations
    try {
      if (event) {
        await updateEvent(eventTitle, eventNote, startDate, endDate, [
          ...contactIds,
          ...selectedContactList,
        ]);
      }

      if (!event && isPracticeLesson) {
        await createPracticeLesson(
          eventTitle,
          eventNote,
          startDate,
          endDate,
          chosenLocationId,
          [...contactIds, ...selectedContactList],
          selectedCategories,
          isShareCoach,
        );
      }

      if (!event && !isPracticeLesson) {
        await createEvent(
          eventTitle,
          eventNote,
          startDate,
          endDate,
          startDateIndex === 24,
          chosenLocationId,
          [...contactIds, ...selectedContactList],
          selectedCategories,
          isShareCoach,
        );
      }
    } catch (err: any) {
      toast.error('There was an error adding your contact.');
      console.error(err);
      setIsLoading(false);
    } finally {
      setAddPracticeLocationView(false);
    }

    setIsLoading(false);
  }, [
    createEvent,
    createPracticeLesson,
    event,
    eventNote,
    eventTitle,
    isPracticeLesson,
    startDate,
    endDate,
    startDateIndex,
    updateEvent,
    onClose,
    selectedCategories,
    selectedContactList,
    chosenLocationId,
    isShareCoach,
  ]);

  const title = useMemo(() => {
    if (event) return 'Update event';
    if (isPracticeLesson) return 'Add practice';
    return 'Add event';
  }, [event, isPracticeLesson]);

  const placeholder = useMemo(() => {
    return isPracticeLesson ? 'Practice ' : 'Event name';
  }, [isPracticeLesson]);

  const onPracticeLocationAdded = async (newPracticeLocation: UserPracticeLocation) => {
    practiceLocations.push(newPracticeLocation);
    setChosenLocationId(practiceLocations[0].location_id);
    setPracticeLocations(practiceLocations);
    setAddPracticeLocationView(false);
  };

  const onContactAdded = async (newContact: UserContact) => {
    newContacts.push(newContact);
    setNewContacts(newContacts);
    setAddContactView(false);
  };

  return (
    <>
      <div className={styles.desktopWrapper}>
        <ModalWindow
          show={visible}
          title={title}
          saveBtnLabel={event ? 'Update' : 'Create'}
          onCloseClick={onClose}
          onSaveClick={onSaveClick}
          loading={loading}
          okButtonDisabled={!eventTitle || !startDate || !endDate}
        >
          <EventViewContent
            categories={categories}
            isPracticeLesson={isPracticeLesson}
            isUpdate={!!event}
            deleteEvent={deleteEvent}
            eventTitle={eventTitle}
            setEventTitle={setEventTitle}
            eventNote={eventNote}
            setEventNote={setEventNote}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startDateIndex={startDateIndex}
            setStartDateIndex={setStartDateIndex}
            isShareCoach={isShareCoach}
            setIsShareCoach={setIsShareCoach}
            isPractice={isPractice}
            setIsPractice={setIsPractice}
            categorieslist={categories}
            placeholderName={placeholder}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            address={address}
            setAddress={onAddAddress}
            locations={practiceLocations}
            setLocations={setPracticeLocations}
            chosenLocationId={chosenLocationId}
            setChosenLocationId={setChosenLocationId}
            associates={associates}
            contacts={[...contacts, ...newContacts]}
            selectedContactList={selectedContactList}
            setSelectedContactList={setSelectedContactList}
            addPracticeLocationView={addPracticeLocationView}
            setAddPracticeLocationView={setAddPracticeLocationView}
            onPracticeLocationAdded={onPracticeLocationAdded}
            addContactView={addContactView}
            setAddContactView={setAddContactView}
            onContactAdded={onContactAdded}
          />
        </ModalWindow>
      </div>
      <div className={styles.mobileWrapper}>
        <MobileDrawer
          visible={visible}
          title={title}
          cancelButtonText="Cancel"
          okButtonText={event ? 'Update' : 'Create'}
          onClose={onClose}
          onClickOkButton={onSaveClick}
          loading={loading}
          okButtonDisabled={!eventTitle || !startDate || !endDate}
        >
          <EventViewContent
            categories={categories}
            isPracticeLesson={isPracticeLesson}
            isUpdate={!!event}
            deleteEvent={deleteEvent}
            eventTitle={eventTitle}
            setEventTitle={setEventTitle}
            eventNote={eventNote}
            setEventNote={setEventNote}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            startDateIndex={startDateIndex}
            setStartDateIndex={setStartDateIndex}
            isShareCoach={isShareCoach}
            setIsShareCoach={setIsShareCoach}
            isPractice={isPractice}
            setIsPractice={setIsPractice}
            categorieslist={categories}
            placeholderName={placeholder}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            address={address}
            setAddress={onAddAddress}
            locations={practiceLocations}
            setLocations={setPracticeLocations}
            chosenLocationId={chosenLocationId}
            setChosenLocationId={setChosenLocationId}
            associates={associates}
            contacts={[...contacts, ...newContacts]}
            selectedContactList={selectedContactList}
            setSelectedContactList={setSelectedContactList}
            addPracticeLocationView={addPracticeLocationView}
            setAddPracticeLocationView={setAddPracticeLocationView}
            onPracticeLocationAdded={onPracticeLocationAdded}
            addContactView={addContactView}
            setAddContactView={setAddContactView}
            onContactAdded={onContactAdded}
          />
        </MobileDrawer>
      </div>
    </>
  );
};

export default EventView;
