import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BookingStep, bookingSlice } from 'redux/slices';
import type { RootState } from '../../../../redux/store';
import { BookingSelectCoachLocation } from './SelectCoachLocation';
import { BookingSelectService } from './SelectService';
import { BookingSelectDateTime } from './SelectDateTime';
import { BookingLoader } from './Loader';
import { BookingUnavailable } from './Unavailable';
import { BookingSummary } from './Summary';
import { BookingPayment } from './Payment';
import { BookingConfirmation } from './Confirmation';
import { BookingConfirmed } from './Confirmed';
import { useStripe3DSecure, useService } from './hooks';
import { useOrgId } from '../../../../common/hooks';

const BookingPage = () => {
  const { orgId } = useOrgId();
  const { is3DSecureReturnPage } = useStripe3DSecure();
  const { getOrgDetails } = useService();
  const [isLoading, setIsLoading] = React.useState(false);

  const application = useSelector((state: RootState) => state.application);
  const booking = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();

  const fetchOrgDetailsAndBookingRules = async () => {
    setIsLoading(true);
    if (orgId && !application.active.orgBookingRules) {
      await getOrgDetails(orgId);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    /** Fetch org details + booking rules */
    fetchOrgDetailsAndBookingRules();

    /** Process the booking once 3d secure auth is completed */
    if (is3DSecureReturnPage()) {
      dispatch(bookingSlice.actions.changeStep(BookingStep.ProcessBooking));
    }
  }, []);

  if (isLoading) {
    return (
      <BookingLoader
        title="Loading"
        message="Please wait while we are loading the booking page ..."
      />
    );
  }

  if (!application.active.orgBookingRules?.isBookingEnabled) {
    return <BookingUnavailable />;
  }

  switch (booking.bookingStep) {
    case BookingStep.FindCoachLocation:
      return <BookingSelectCoachLocation />;
    case BookingStep.SelectService:
      return <BookingSelectService />;
    case BookingStep.SelectTime:
      return <BookingSelectDateTime />;
    case BookingStep.Summary:
      return <BookingSummary />;
    case BookingStep.Pay:
      return <BookingPayment />;
    case BookingStep.ProcessBooking:
      return <BookingConfirmation />;
    case BookingStep.Confirmed:
      return <BookingConfirmed />;
    default:
      return null;
  }
};

export default BookingPage;
