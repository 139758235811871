/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { v4 as uuidv4 } from 'uuid';
import { LessonTypeEnum } from 'data/entities/lessons.entity';
import { Favorite, FavoriteModel } from '../entities/favorites.entity';
import RealmRepositories from '../base/realm.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

enum AttachmentTypeName {
  File = 'file',
  Note = 'note',
}

export default class FavouritesService {
  static async createFavorite(userId: string, orgId: string, favoriteTypeObject: any) {
    const favourite: Favorite = {
      _id: uuidv4(),
      user_id: userId,
      org_id: orgId,
      favorite_type: JSON.stringify(favoriteTypeObject),
      date_updated: getUnixMilliseconds(),
      date_created: getUnixMilliseconds(),
      deleted: false,
    };
    const result = await RealmRepositories.Favorites.createFavorite(favourite);

    return result;
  }

  static async updateFavoriteType(favoriteId: string, favoriteTypeObject: any) {
    const stringifiedType = JSON.stringify(favoriteTypeObject);
    const result = await RealmRepositories.Favorites.updateFavoriteType(
      favoriteId,
      stringifiedType,
    );
    return result;
  }

  static async getAllFavoritesAsModel(
    userId: string,
    orgId: string,
    lessonType?: number,
  ): Promise<FavoriteModel[]> {
    const favoriteResult = await RealmRepositories.Favorites.getAllUserFavouritesByOrg(
      userId,
      orgId,
    );

    const result: FavoriteModel[] = [];

    if (!favoriteResult) {
      return result;
    }

    for await (const favorite of favoriteResult) {
      const index = result.findIndex((value: FavoriteModel) => value.favoriteId === favorite._id);
      if (index >= 0) continue;
      // exercises and other types will be added in the future
      const favoriteTypeJsonObject = JSON.parse(favorite.favorite_type);

      const currentLesson = await RealmRepositories.Lessons.getLessonById(
        favoriteTypeJsonObject.lesson_id,
      );

      if (
        lessonType === LessonTypeEnum.CoachIndividualLesson &&
        (currentLesson?.is_self_taught !== false || currentLesson.max_participants !== 1)
      )
        continue;
      if (
        lessonType === LessonTypeEnum.CoachGroupLesson &&
        (currentLesson?.is_self_taught !== false || currentLesson.max_participants <= 1)
      )
        continue;
      if (
        lessonType === LessonTypeEnum.PracticeLessonSelf &&
        (currentLesson?.is_self_taught !== true ||
          currentLesson?.share_practice_lesson_with_coach !== false)
      )
        continue;
      if (
        lessonType === LessonTypeEnum.PracticeLessonShare &&
        (currentLesson?.is_self_taught !== true ||
          currentLesson?.share_practice_lesson_with_coach !== true)
      )
        continue;

      const attachmentId = favoriteTypeJsonObject.attachment_id;

      const attachmentItem = await RealmRepositories.Attachments.getAttachmentById(attachmentId);
      if (!attachmentItem || !attachmentItem.attachment_type) {
        continue;
      }

      const attachmentTypeJson = JSON.parse(attachmentItem.attachment_type);
      const attachmentNameType = attachmentTypeJson.name as AttachmentTypeName; // file || note as string
      const isFileAttachment = attachmentNameType === AttachmentTypeName.File;
      let attachmentObject = attachmentItem;
      if (isFileAttachment) {
        const fileId = attachmentTypeJson.file_id;
        attachmentObject = await RealmRepositories.Files.getFileByIdAsync(fileId as string);
      }

      attachmentObject._id = attachmentId;

      const favoriteData: FavoriteModel = {
        title: currentLesson?.title ?? '',
        lessonId: favoriteTypeJsonObject.lesson_id as string,
        attachment: attachmentObject,
        favoriteId: favorite._id,
        favoriteType: favoriteTypeJsonObject,
        isFavorite: true,
        isFileAttachment,
        dateCreated: favorite.date_created,
      };

      result.push(favoriteData);
    }

    return result;
  }

  static async getAllUserFavouritesByOrg(
    userId: string,
    orgId: string,
  ): Promise<Favorite[] | undefined> {
    return RealmRepositories.Favorites.getAllUserFavouritesByOrg(userId, orgId);
  }

  static async deleteFavoriteById(favoriteId: string) {
    const result = await RealmRepositories.Favorites.deleteFavoriteById(favoriteId);
    return result;
  }
}
