import BillingRoutes from './routes/billing.route';
import ClientRoutes from './routes/client.route';
import CoachRoutes from './routes/coach.route';

export class Api {
  /**
   * Route for client api.
   */
  public static ClientRoutes = new ClientRoutes();

  /**
   * Route for coach api.
   */
  public static CoachRoutes = new CoachRoutes();

  /**
   * Route for billing api.
   */
  public static BillingRoutes = new BillingRoutes();
}
