/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import OrganizationService from 'data/services/organization.service';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import { ServiceInviteStatusEnum } from 'data/entities/service.entity';
import { useCalendarPage } from 'modules/calendar/pages/CalendarPage/useCalendarPage.hooks';
import { Grid } from '../../../components/Grid';
import styles from './HomePage.module.scss';
import RadarChart from '../../../components/Charts/RadarChart/RadarChart';
import BarCharts from '../../../components/Charts/BarChart/BarCharts';
import PastLessons from '../components/PastLessons/PastLessons';
import NewsFeed from '../components/NewsFeed/NewsFeed';
import GolfJourneyMap from '../components/GolfJourneyMap/GolfJourneyMap';
import BannerUpcomingLesson from '../components/BannerUpcomingLesson/BannerUpcomingLesson';
import EventView from '../../calendar/components/EventView/EventView';
import DeleteEventView from '../../calendar/components/DeleteEventView/DeleteEventView';
import {
  Lesson,
  EventType,
  ProductivityChartData,
  LessonClientStatus,
} from '../../../data/entities/lessons.entity';
import { Event } from '../../../data/entities/events.entity';
import CancelLessonView from '../../lesson/components/CancelLessonView/CancelLessonView';
import BannerTrial from '../components/BannerTrial/BannerTrial';
import SessionProvider from '../../../providers/SessionProvider';
import { Api } from '../../../api/Api';
import Spinnerv2 from '../../../components/Spinnerv2/Spinnerv2';
import { AxiosErrorHandler } from '../../../common/utils/errorHandler.helpers';
import LessonHelper, { LessonCancellation } from '../../../common/utils/lessons.helper';
import { OrgBookingRules } from '../../book/models/booking.model';
import OrganizationHelper from '../../../common/utils/organization.helper';
import ServicesService from '../../../data/services/services.service';
import EventsService from '../../../data/services/events.service';
import LessonsService from '../../../data/services/lessons.service';
import { UserSubscriptionsModel } from '../../../data/models/userSubscription.mode';
import { BannerPurchasedLessonPacks } from '../components/BannerPurchasedLessonPacks';

interface HomePageProps {
  subscriptionInfo?: UserSubscriptionsModel;
}

interface CancelInvitationProps {
  type: EventType;
  id: string;
  inviteId: string;
}

const HomePage = (props: HomePageProps) => {
  const { subscriptionInfo } = props;
  const [confimationModal, setConfirmationModal] = useState<boolean>(false);
  const [activityFeeds, setActivityFeeds] = useState<Lesson[] | Event[] | any>([]);
  const [invitedItems, setInvitedItems] = useState<any>([]);
  const [visibleEventView, setVisibleEventView] = useState(false);
  const [deleteEventId, setDeleteEventId] = useState<string | null>(null);
  const [visibleDeleteView, setVisibleDeleteView] = useState(false);
  const [cancelledLesson, setCancelledLesson] = useState<Lesson>();
  const [lessonCancellationResult, setLessonCancellationResult] = useState<LessonCancellation>(
    LessonCancellation.Past,
  );
  const [showCancelLessonView, setShowCancelLessonView] = useState(false);
  const [chartData, setChartData] = useState<ProductivityChartData>();
  const [dataHasBeenFetched, setDataHasBeenFetched] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [isCancellingLesson, setIsCancellingLesson] = useState<boolean>(false);
  const [isCancellingInvitation, setIsCancellingInvitation] = useState<boolean>(false);
  const [isCancellingPackage, setIsCancellingPackage] = useState<boolean>(false);
  const [categories, setCategories] = useState<LessonCategory[]>([]);
  const [cancelInviteObject, setCancelInviteObject] = useState<CancelInvitationProps>({
    type: EventType.Service,
    id: '',
    inviteId: '',
  });

  const { orgId } = useParams<Record<string, string>>();
  const userId = SessionProvider.getUserId();
  const [orgBookingRules, setOrgBookingRules] = useState<OrgBookingRules>(
    new OrgBookingRules(orgId),
  );

  const { updateEvent, eventToUpdate, setEventToUpdate } = useCalendarPage();

  const fetchData = useCallback(async () => {
    try {
      setIsFetchingData(true);

      await OrganizationHelper.GetOrgBookingRules(orgId, setOrgBookingRules);

      const activityFeedResult = await LessonsService.getActivityFeed(userId, orgId as string);

      setActivityFeeds(activityFeedResult);

      const invitedServicesResult = await ServicesService.getUserInvitedServicesAsync(
        userId,
        orgId as string,
        SessionProvider.getEmail(),
      );

      const invitedLessonsResult = await LessonsService.getInvitedLessons(
        userId,
        orgId,
        SessionProvider.getEmail(),
      );

      const category = await OrganizationService.getOrgLessonCategoriesById(orgId);
      setCategories(category);

      setInvitedItems([
        ...invitedServicesResult.filter((service: any) => !ServicesService.isPast(service)),
        ...invitedLessonsResult.filter((lesson: any) => !LessonsService.isPast(lesson)),
      ]);
      if (!chartData) {
        setChartData(await LessonsService.getProductivityData(userId, orgId as string));
      }
    } catch (err: any) {
      toast.warning(`Failed to fetch activity feed: ${err.message}`);
    } finally {
      setIsFetchingData(false);
      setDataHasBeenFetched(true);
    }
  }, [chartData, orgId, userId]);

  // Fetch page data.
  useEffect(() => {
    if (!dataHasBeenFetched) {
      fetchData();
    }
  }, [dataHasBeenFetched, fetchData]);

  const homeUpdateEvent = async (
    title: string,
    note: string,
    startDate: number,
    endDate: number,
    selectedContactList: string[],
  ) => {
    if (eventToUpdate) {
      const updatedEvent = await updateEvent(title, note, startDate, endDate, selectedContactList);

      if (updatedEvent)
        setActivityFeeds(
          activityFeeds.map((activityFeedItem: any) => {
            return activityFeedItem._id === updatedEvent._id
              ? {
                  ...activityFeedItem,
                  title: updatedEvent.title,
                  contacts_ids: updatedEvent.contacts_ids,
                  note: updatedEvent.note,
                  date_begin: updatedEvent.date_begin,
                  date_end: updatedEvent.date_end,
                  date_updated: updatedEvent.date_updated,
                }
              : activityFeedItem;
          }),
        );
    }
    setVisibleEventView(false);
  };

  const deleteEvent = async () => {
    if (deleteEventId) {
      setActivityFeeds(activityFeeds.filter((x: any) => x._id !== deleteEventId));
      await EventsService.deleteEventById(deleteEventId);
    }
  };

  const onCancelLesson = async (lessonId: string, reason?: string) => {
    try {
      setIsCancellingLesson(true);

      await Api.ClientRoutes.Lessons.cancelLesson(lessonId, { userId, reason });

      const lesson = activityFeeds.find((item: any) => {
        return item._id === lessonId;
      }) as Lesson;

      toast.success(
        LessonHelper.LessonCancelledToastMessage(
          lesson,
          orgBookingRules.cancellationPeriodInMinutes,
        ),
      );
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.warning(compiledErrorMessage);
    } finally {
      setActivityFeeds(activityFeeds.filter((x: any) => x._id !== lessonId));
      setIsCancellingLesson(false);
    }
  };

  const onCancelPackage = async (packageId: string, reason?: string) => {
    try {
      setIsCancellingPackage(true);

      await Api.ClientRoutes.Lessons.cancelPackage(packageId, { userId, reason });

      toast.success(LessonHelper.PackageCancelledToastMessage());
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.warning(compiledErrorMessage);
    } finally {
      setActivityFeeds(activityFeeds.filter((x: any) => x.package_id !== packageId));
      setIsCancellingPackage(false);
    }
  };

  const onDeleteActivityEvent = useCallback((deletedEventId: string, showDeleteView: boolean) => {
    setDeleteEventId(deletedEventId);
    setVisibleDeleteView(showDeleteView);
  }, []);

  const onCancelActivityLesson = useCallback(
    (canceledLessonId: string, showCanceledView: boolean, lesson: Lesson) => {
      setCancelledLesson(lesson);
      const lessonCancellation = LessonHelper.GetCancellationResult(
        lesson,
        orgBookingRules.cancellationPeriodInMinutes,
      );
      setLessonCancellationResult(lessonCancellation);
      setShowCancelLessonView(showCanceledView);
    },
    [orgBookingRules.cancellationPeriodInMinutes],
  );

  const cancelInvitation = async () => {
    setIsCancellingInvitation(true);

    switch (cancelInviteObject.type) {
      case EventType.Service:
        await ServicesService.UpdateInvitedClientStatus(
          cancelInviteObject.id,
          cancelInviteObject.inviteId,
          userId,
          ServiceInviteStatusEnum.RemovedByClient,
        );
        break;
      case EventType.LessonInvite:
        await LessonsService.UpdateInvitedClientStatus(
          cancelInviteObject.id,
          cancelInviteObject.inviteId,
          userId,
          LessonClientStatus.Cancelled,
        );
        break;
      default:
        console.log('Unsupported feedItem type');
    }

    setInvitedItems(invitedItems.filter((x: any) => x._id !== cancelInviteObject.id));
    setIsCancellingInvitation(false);
    setConfirmationModal(false);
  };

  const onCancelInvitation = (eventType: EventType, id: string, inviteId: string) => {
    setCancelInviteObject({
      type: eventType,
      id,
      inviteId,
    });
    setConfirmationModal(true);
  };

  if (isFetchingData) {
    return <Spinnerv2 message="Loading..." />;
  }

  if (isCancellingLesson) {
    return <Spinnerv2 message="Canceling lesson..." />;
  }

  if (isCancellingInvitation) {
    return <Spinnerv2 message="Canceling invitation..." />;
  }

  if (isCancellingPackage) {
    return <Spinnerv2 message="Canceling package..." />;
  }

  return (
    <>
      <BannerPurchasedLessonPacks />
      <BannerUpcomingLesson />
      <BannerTrial subscriptionInfo={subscriptionInfo} />
      <div className={styles.wrapper}>
        <EventView
          categories={categories}
          event={eventToUpdate}
          visible={visibleEventView}
          onClose={() => {
            setVisibleEventView(false);
            setEventToUpdate(undefined);
          }}
          updateEvent={homeUpdateEvent}
          deleteEvent={() => {
            if (eventToUpdate) {
              setVisibleEventView(false);
              setDeleteEventId(eventToUpdate._id);
              setVisibleDeleteView(true);
            }
          }}
        />
        <DeleteEventView
          visible={visibleDeleteView}
          onClose={() => {
            setVisibleDeleteView(false);
            setDeleteEventId(null);
          }}
          onDeleteEvent={deleteEvent}
          type={EventType.Event}
        />
        {cancelledLesson && (
          <CancelLessonView
            lesson={cancelledLesson}
            visible={showCancelLessonView}
            onClose={() => setShowCancelLessonView(false)}
            onCancelLesson={onCancelLesson}
            onCancelPackage={onCancelPackage}
            lessonCancellation={lessonCancellationResult}
            cancellationPeriodInMinutes={orgBookingRules.cancellationPeriodInMinutes}
          />
        )}
        <div className={styles.tileMap}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Grid container>
                <Grid item md={12}>
                  <h3 className={styles.title}>Invitation Feed</h3>
                  <NewsFeed
                    activityFeed={invitedItems}
                    noItemsFoundText="You have no invitations for lessons or services currently."
                    onCancelInvitation={onCancelInvitation}
                  />
                </Grid>
                <Grid item md={12}>
                  <h3 className={styles.title}>Activity Feed</h3>
                  <NewsFeed
                    onOpenEvent={setVisibleEventView}
                    setEventData={setEventToUpdate}
                    activityFeed={activityFeeds}
                    deleteEvent={onDeleteActivityEvent}
                    cancelLesson={onCancelActivityLesson}
                    orgBookingRules={orgBookingRules}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5}>
              <h3 className={styles.title}>Lesson Map</h3>
              <h3 className={styles.subtitle}>
                Tap on the area of the lesson map to see what lessons have been taught in that area.
              </h3>
              <GolfJourneyMap />
            </Grid>
          </Grid>
        </div>

        <div className={styles.pastlesson}>
          <h3 className={styles.title}>Past Lessons</h3>
          <PastLessons subscriptionInfo={subscriptionInfo} />
        </div>
        <div className={styles.charts}>
          <div className={styles.radar}>
            <h3 className={styles.title}>Complete athlete</h3>
            <RadarChart />
          </div>
          <div className={styles.bar}>
            <h3 className={styles.title}>Lessons and Events</h3>
            <BarCharts
              lessons={get(chartData, 'lessons', [])}
              practiceLessons={get(chartData, 'practiceLessons', [])}
              events={get(chartData, 'events', [])}
              months={get(chartData, 'months', [])}
            />
          </div>
        </div>
      </div>
      <ModalWindow
        show={confimationModal}
        title="Confirmation"
        saveBtnLabel="Hide invitation"
        onSaveClick={cancelInvitation}
        onCloseClick={() => setConfirmationModal(false)}
      >
        <div>Are you sure you want to hide this invitation from your feed?</div>
      </ModalWindow>
    </>
  );
};

export default HomePage;
