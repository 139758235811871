import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get, has } from 'lodash';

import styles from './SearchClient.module.scss';
import SearchClientInput from '../SearchClientInput/SearchClientInput';
import SearchClientDropdown from '../SearchClientDropdown/SearchClientDropdown';
import SearchClientItem from '../SearchClientItem/SearchClientItem';
import { ISearchClient } from '../../models/searchClient.model';
import { onSearch } from '../../../../common/utils/search';
import Spinner from '../../../../components/Spinner/Spinner';
import AuthMenu from '../AuthMenu/AuthMenu';
import logo from '../../../../assets/images/auth/signup-image.png';

const SearchClient = () => {
  const [clients, setClients] = useState<ISearchClient[]>([]);
  const [defaultClientList, setDefaultClientList] = useState<ISearchClient[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [filteredClients, setFilteredClients] = useState<ISearchClient[]>([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedClient, setSelectedClient] = useState<ISearchClient[]>([]);
  const [signUp, setSignUp] = useState(false);
  const [downloadProccessing, setDownloadProcessing] = useState(false);
  const history = useHistory();

  const getCoachClients = useCallback(async () => {
    // const coachName = window.location.host.split('.')[0];
    setDownloadProcessing(true);
    // const result = await Api.ClientRoutes.CoachClients.getClientsList(coachName);
    const result: any = [];
    setDownloadProcessing(false);
    setClients(result);
    setDefaultClientList(result);
  }, []);

  useEffect(() => {
    getCoachClients();
  }, [getCoachClients]);

  useEffect(() => {
    const result = onSearch(clients, searchValue);
    setFilteredClients(result);
  }, [clients, searchValue]);

  useEffect(() => {
    if (searchValue.length) {
      setOpenDropdown(true);
    }
    if (!searchValue.length) {
      setOpenDropdown(false);
      setSignUp(false);
    }
  }, [searchValue]);

  const onSelectedClient = useCallback(
    (selectedItem: ISearchClient[], checked: boolean) => {
      if (checked) {
        setSelectedClient(selectedItem);
        setClients(selectedItem);
        setSignUp(true);
      } else {
        setSelectedClient(selectedItem);
        setClients(defaultClientList);
        setSignUp(false);
      }
    },
    [defaultClientList],
  );

  return (
    <AuthMenu
      logo={logo}
      withWelcome
      headerTitle="Sign up to get started"
      btnLabel="Sign up"
      onBtnClicked={() =>
        has(selectedClient, '0.id') &&
        history.push(`/signup?clientId=${get(selectedClient, '0.id')}`)
      }
      authLink="/ "
      authLinkHeader="Already have an account?"
      authLinkText="Sign in"
      customStyles={styles}
      isBtn={signUp}
    >
      <SearchClientInput onChange={setSearchValue} clientValue={searchValue} />
      <SearchClientDropdown openList={openDropdown}>
        {!downloadProccessing &&
          (filteredClients.length > 0 ? (
            filteredClients.map((item: any) => {
              return <SearchClientItem client={item} key={item.id} onChecked={onSelectedClient} />;
            })
          ) : (
            <span className={styles.empty}>No search results. Please contact your coach</span>
          ))}
        {downloadProccessing && <Spinner color="#000" />}
      </SearchClientDropdown>
    </AuthMenu>
  );
};

export default SearchClient;
