import React from 'react';
import Box from '@mui/material/Box';
import { getZonedDate } from 'common/utils/date.helpers';
import { Grid } from '../../../../../../components/Grid';
import { TabContainer, TabContainerProps, ActiveTab } from './MorningAfternoonTab';
import { TimeSlot } from './TimeSlot';
import { useTimeSlots, useService, BusyTime } from '../../hooks';
import { SelectedCalendarDate } from '../MonthlyCalendar';

interface TimePickerProps
  extends Omit<TabContainerProps, 'children' | 'activeTab' | 'onSwitchTab'> {
  selectedTimeSlots: BusyTime[];
  onConfirmTime: (start: number, end: number) => void;
  onCancelTime: (start: number, end: number) => void;
  timezone: string;
  selectedDate: SelectedCalendarDate;
  disableSelectNewTime: boolean;
}

export const TimePicker = ({
  selectedDate,
  selectedTimeSlots,
  onSwitchSelectedDate,
  onConfirmTime,
  onCancelTime,
  timezone,
  disableSelectNewTime,
}: TimePickerProps) => {
  const [activeTab, setActiveTab] = React.useState<ActiveTab>('morning');
  const [selectedStartMilis, setSelectedStartMilis] = React.useState(0);
  const {
    isTimeSlotBooked,
    isTimeSlotPurchased,
    isTimeSlotBusy,
    getReduxConnectedAvailableCategoryTimeSlots,
    getServiceEndTime,
    getAlreadySelectedTimeSlots,
  } = useTimeSlots();
  const { getActiveSelectedService, getTotalTimeSlotsNeededToBeSelected } = useService();
  const activeSelectedService = getActiveSelectedService();

  const morningTimeSlots = getReduxConnectedAvailableCategoryTimeSlots({
    timezone,
    selectedDate,
    category: 'morning',
  });

  const afternoonTimeSlots = getReduxConnectedAvailableCategoryTimeSlots({
    timezone,
    selectedDate,
    category: 'afternoon',
  });

  const getDefaultActiveTab = (): ActiveTab => (morningTimeSlots.length ? 'morning' : 'afternoon');
  let displayedTimeSlots = activeTab === 'morning' ? morningTimeSlots : afternoonTimeSlots;

  displayedTimeSlots = displayedTimeSlots.map((slot) => {
    const startZonedDate = getZonedDate(slot.start.milis, timezone);
    const endZonedDate = getZonedDate(slot.end.milis, timezone);

    return {
      start: {
        dateTimeShort: startZonedDate.toFormat('dd MMM - hh:mm a'),
        milis: slot.start.milis,
        timeShort: startZonedDate.toFormat('hh:mm a'),
      },
      end: {
        dateTimeShort: endZonedDate.toFormat('dd MMM - hh:mm a'),
        milis: slot.end.milis,
        timeShort: endZonedDate.toFormat('hh:mm a'),
      },
    };
  });

  const isContinueButtonEnabled =
    getTotalTimeSlotsNeededToBeSelected() <= getAlreadySelectedTimeSlots().length &&
    !(getTotalTimeSlotsNeededToBeSelected() === 0 && getAlreadySelectedTimeSlots().length === 0);

  /** Automatically choose the tab that has available time slots on date change */
  React.useEffect(() => {
    const tab = getDefaultActiveTab();
    setActiveTab(tab);
  }, [selectedDate]);

  if (!activeSelectedService) return null;
  return (
    <Grid container>
      <Grid item md={12}>
        <Box minHeight={50}>
          <Box fontSize="1.5rem" fontWeight="bold">
            Pick a time
          </Box>
        </Box>
      </Grid>
      <Grid item md={12}>
        <TabContainer
          activeTab={activeTab}
          selectedDate={selectedDate}
          onSwitchSelectedDate={onSwitchSelectedDate}
          onSwitchTab={(newActiveTab) => setActiveTab(newActiveTab)}
        >
          {displayedTimeSlots.map((timeSlot, index) => {
            const isPurchased = isTimeSlotPurchased(timeSlot);
            const isBusy = isTimeSlotBusy({ timeSlot, serviceId: activeSelectedService.id });
            const hasBeenSelected = isTimeSlotBooked(timeSlot, selectedTimeSlots);
            const serviceEndTime = getServiceEndTime({
              startTimeInMilis: timeSlot.start.milis,
              durationInMinutes: activeSelectedService.durationInMinutes,
              timezone,
            });

            const isTimeSlotStartTimeFoundInSelectedTimeSlots = selectedTimeSlots.find(
              (selectedTimeSlot) => selectedTimeSlot.dateStart === timeSlot.start.milis,
            );

            const isTimeSlotBookable = selectedTimeSlots.find(
              (selectedTimeSlot) =>
                serviceEndTime.milis > selectedTimeSlot.dateStart &&
                serviceEndTime.milis < selectedTimeSlot.dateEnd,
            );

            return (
              <TimeSlot
                key={index}
                startTime={timeSlot.start.timeShort}
                endTime={serviceEndTime.timeShort}
                isPurchased={isPurchased}
                isBusy={isBusy}
                isActive={selectedStartMilis === timeSlot.start.milis && !isContinueButtonEnabled}
                hasBeenSelected={hasBeenSelected}
                onClick={() => {
                  setSelectedStartMilis(timeSlot.start.milis);
                }}
                onConfirm={() => onConfirmTime(timeSlot.start.milis, serviceEndTime.milis)}
                onCancel={
                  hasBeenSelected && isTimeSlotStartTimeFoundInSelectedTimeSlots
                    ? () => {
                        onCancelTime(timeSlot.start.milis, serviceEndTime.milis);
                      }
                    : undefined
                }
                disableSelectNewTime={
                  disableSelectNewTime || typeof isTimeSlotBookable !== 'undefined'
                }
              />
            );
          })}
        </TabContainer>
      </Grid>
    </Grid>
  );
};
