import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, SwiperOptions } from 'swiper';

interface CarouselContainerProps extends Pick<SwiperOptions, 'breakpoints' | 'navigation'> {
  slides: React.ReactNode[];
  previousButton?: React.ReactNode;
  nextButton?: React.ReactNode;
}

export const Carousel = ({
  slides,
  breakpoints,
  previousButton,
  nextButton,
}: CarouselContainerProps) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const defaultBreakpoints: SwiperOptions['breakpoints'] = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    480: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    1024: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    // eslint-disable-next-line @typescript-eslint/naming-convention
    1280: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  };

  const handleNextSlide = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const handlePrevSlide = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  return (
    <>
      <a onClick={handlePrevSlide}>{previousButton}</a>
      <Swiper
        direction="horizontal"
        modules={[Navigation]}
        slidesPerView="auto"
        spaceBetween={20}
        breakpoints={breakpoints ?? defaultBreakpoints}
        onSwiper={setSwiper}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} style={{ padding: 2, height: 'auto', display: 'flex' }}>
            {slide}
          </SwiperSlide>
        ))}
      </Swiper>
      <a onClick={handleNextSlide}>{nextButton}</a>
    </>
  );
};
