import React from 'react';
import { DateTime } from 'luxon';

import { SelectedCalendarDate } from '../components';

interface CalendarState {
  month: number;
  year: number;
}

export const useCalendar = () => {
  const now = DateTime.now();

  /** Main calendar state */
  const [calendar, setCalendar] = React.useState<CalendarState>({
    month: now.month,
    year: now.year,
  });

  /** Calendar date state */
  const [selectedDate, setSelectedDate] = React.useState<SelectedCalendarDate | null>(null);

  /** Calendar related handlers : prev and next buttons */
  const handleCalendarNext = () => {
    if (calendar.month === 12) {
      setCalendar({
        month: 1,
        year: calendar.year + 1,
      });
    } else {
      setCalendar({ ...calendar, month: calendar.month + 1 });
    }
  };

  const handleCalendarPrev = () => {
    if (calendar.month === 1) {
      setCalendar({
        month: 12,
        year: calendar.year - 1,
      });
    } else {
      setCalendar({ ...calendar, month: calendar.month - 1 });
    }
  };

  /** Convert luxon weekday (Monday is 1, Sunday is 7) to Avgar weekday (0 = Sunday) */
  const getAvgarDayOfWeek = (luxonWeekday: number) => (luxonWeekday === 7 ? 0 : luxonWeekday);

  return {
    calendar,
    selectedDate,
    setSelectedDate,
    getAvgarDayOfWeek,
    handleCalendarPrev,
    handleCalendarNext,
  };
};
