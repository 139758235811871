import { Config } from '../../config';
import { SportInformationModel } from '../entities/sportInformation.entity';
import { BaseRepo } from './base.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export class SportInformationRepository extends BaseRepo {
  // #region Private Properties
  private sportInformationCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('sport_information');
  // #endregion

  // #region Public Methods
  async getUserSportInformationAsync(
    userId: string,
    orgId: string,
  ): Promise<SportInformationModel | undefined> {
    return this.sportInformationCollection?.findOne({
      org_id: orgId,
      user_id: userId,
    });
  }

  async createSportInformation(sportInformationModel: SportInformationModel) {
    return this.sportInformationCollection?.insertOne(sportInformationModel);
  }

  async updateSportInformation(
    userId: string,
    orgId: string,
    sportInformation: SportInformationModel,
  ) {
    return this.sportInformationCollection?.updateOne(
      { user_id: userId, org_id: orgId },
      {
        $set: {
          position: sportInformation.position,
          sport_club: sportInformation.sport_club,
          previous_coach: sportInformation.previous_coach,
          previous_sports: sportInformation.previous_sports,
          visible_skills: false,
          goals: sportInformation.goals,
          issues: sportInformation.issues,
          dominations: sportInformation.dominations,
          date_updated: getUnixMilliseconds(),
        },
      },
    );
  }
  // #endregion
}
