import React, { useState } from 'react';
import styles from './ContactsDetailItem.module.scss';

interface IContactDetailItem {
  label: string;
  description: string;
}

const ContactDetailItem: React.FC<IContactDetailItem> = ({ label, description }) => {
  const [checkLabel, setCheckLabel] = useState(label);

  switch (checkLabel) {
    case 'phone_number':
      setCheckLabel('phone');
      break;
    case '_id':
      setCheckLabel('');
      break;
    case 'name':
      setCheckLabel('');
      break;
    case 'profession':
      setCheckLabel('');
      break;
    case 'date_created':
      setCheckLabel('');
      break;
    case 'date_updated':
      setCheckLabel('');
      break;
    case 'user_id':
      setCheckLabel('');
      break;
    case 'deleted':
      setCheckLabel('');
      break;
    case 'country_code':
      setCheckLabel('');
      break;
    default:
      // eslint-disable-next-line no-unused-expressions
      checkLabel;
  }

  return checkLabel ? (
    <div className={styles.ContactDetailItem}>
      <span>{checkLabel}</span>
      <div className={styles.description}>{description}</div>
    </div>
  ) : null;
};

export default ContactDetailItem;
