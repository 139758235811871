/* eslint-disable no-underscore-dangle */
import { head } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './UserAvatar.module.scss';
import {
  openFileUploader,
  generateUploadAttachment,
} from '../../modules/attachment/utils/attachment.helpers';
import SessionProvider from '../../providers/SessionProvider';
import { Config } from '../../config';
import getResizedImageUri from '../../common/utils/getResizedImageUri.helpers';
import AttachmentsService from '../../data/services/attachments.service';
import UserService from '../../data/services/user.service';
import InlineSpinner from '../InlineSpinner/InlineSpinner';

interface IProps {
  className?: string;
  name?: string;
  src?: string;
  showEdit?: boolean;
  profileUpdated?: Function;
}

const UserAvatar = ({ className, name, src, showEdit, profileUpdated }: IProps) => {
  const { orgId } = useParams<Record<string, string | undefined>>();
  const [isUpdatingAvatar, setIsUpdatingAvatar] = useState(false);
  const [isInvalidAvatar, setIsInvalidAvatar] = useState(false);
  const currentOrgId = orgId ?? '';
  const currentUserId = SessionProvider.getUserId();

  const initials = useMemo(() => {
    if (!name) return '';

    const splitName = name.split(' ');
    return splitName
      .reduce((res: string[], cur: string) => {
        const emoji = cur.match(/^[\p{Emoji}\u200d]/gu);

        if (emoji) {
          return [...res, head(emoji) as string];
        }

        return [...res, cur.charAt(0).toUpperCase()];
      }, [])
      .join('')
      .substr(0, 2);
  }, [name]);

  const uploadFiles = async (files: FileList) => {
    const file = files.length > 0 && files[0]?.type.includes('image') ? files[0] : null;

    if (files[0]?.type.includes('image')) setIsInvalidAvatar(false);
    else setIsInvalidAvatar(true);

    const component = 'UserDetails';
    if (file) {
      const fileObj = generateUploadAttachment(file, currentOrgId, currentUserId);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const onUploaded = async (id: string, url: string, cognitoId: string, type: string) => {
        const fileUrl = `${Config().AWSCloudFrontUrl as string}private/${cognitoId}/${url}`;
        const fileName = `private/${cognitoId}/${url}`;
        const thumbnail = '';
        await UserService.updateProfilePic(
          {
            ...fileObj.fileObject,
            file_url: fileUrl,
            file_name: fileName,
            thumbnail_url: thumbnail,
          },
          currentOrgId,
          currentUserId,
          id,
        );
        if (profileUpdated) {
          profileUpdated();
        }
        setIsUpdatingAvatar(false);
      };
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      const onUploading = () => {
        setIsUpdatingAvatar(true);
      };

      await AttachmentsService.uploadFileAttachment(
        fileObj.fileObject._id,
        currentUserId,
        currentOrgId,
        component,
        file,
        onUploaded,
        onUploading,
        '',
      );
    }
  };

  const AvatarIcon = () => {
    if (isUpdatingAvatar) {
      return <InlineSpinner />;
    }

    if (src)
      return (
        <img
          src={getResizedImageUri(src, '128x128')}
          className={`${styles.avatarImg}`}
          alt="Profile"
        />
      );

    // eslint-disable-next-line
    return <>{initials}</>;
  };

  return (
    <div className={`${styles.avatarMidWrapper}`}>
      <div
        className={
          className ? `${className} ${styles.avatarInfo}` : `${styles.avatar} ${styles.avatarInfo}`
        }
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={showEdit ? () => openFileUploader(uploadFiles) : () => {}}
      >
        <AvatarIcon />
      </div>
      {showEdit && (
        <div className={`${styles.avatarImage}`}>
          <button
            className={`${styles.chooseImage}`}
            type="button"
            onClick={() => openFileUploader(uploadFiles)}
          >
            Choose Image
          </button>
          {isInvalidAvatar ? <p className="text-danger">Invalid Type</p> : ''}
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
