import React, { useState, useEffect, useRef, useCallback } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { Config } from 'config';

import SessionProvider from 'providers/SessionProvider';

/** Commons */
import { useUserSubscription } from 'common/hooks';
import { useAuth } from 'common/hooks/useAuth';
import { checkUserTypeAndRedirect, getBaseOrgRoute, getOrgId } from 'common/utils/common.helpers';
import { StaticRoutes } from 'common/utils/routes/StaticRoutes.helper';
import { BillingHelper } from 'common/utils/billing.helper';
import { BookingStep } from 'redux/slices';

import BackIcon from 'assets/icons/navbar-icons/arrow-back.svg';

/** Data */
import RealmRepositories from 'data/base/realm.repo';
import { File } from 'data/entities/file.entity';
import OrganizationService from 'data/services/organization.service';
import UserService from 'data/services/user.service';
import { User } from 'data/entities/users.entity';
import { SportType } from 'data/enums/SportType.enum';
import { OrgListModel } from 'data/entities/organization.entity';

/** Components */
import { SignInDialog } from 'modules/book/pages/BookingPageV2/components/signInDialog';
import { SignUpDialog } from 'modules/book/pages/BookingPageV2/components/signUpDialog';
import { useService, useBooking } from 'modules/book/pages/BookingPageV2/hooks';
import SignButton from 'modules/book/components/SignButton/SignButton';
import Button from 'components/Button/Button';
import MobileMenu from 'components/MobileMenu/MobileMenu';
import DropdownMenu, { DataItem } from 'components/DropdownMenu/DropdownMenu';
import UserAvatar from 'components/UserInfo/UserAvatar';
import Logo from './Logo/Logo';
import NavBar from './NavBar/NavBar';
import Hamburger from './Hamburger/Hamburger';

import styles from './Header.module.scss';

interface HeaderProps {
  title?: string;
  onBack?: any;
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { handleResetBooking } = useBooking();
  const booking = useSelector((state: RootState) => state.booking);

  const {
    logUserOutAndRedirectToBookingPage,
    logUserOutAndRedirectToLoginPage,
    isAuthorized,
    authorize,
  } = useAuth();
  const { title, onBack } = props;
  const { pathname } = useLocation();

  const { subscriptionInfo } = useUserSubscription();

  const history = useHistory();
  const isBookBtnDisplayed = (): boolean => {
    const isBookingPathActive = !!matchPath(history.location.pathname, '/org/:id/book');
    if (isBookingPathActive) {
      if (booking.bookingStep === BookingStep.Confirmed) return true;
      return false;
    }
    return true;
  };

  const [navbarOpen, setNavbarOpen] = useState(false);

  const userId = SessionProvider.getUserId();
  const orgId = pathname.split('/')[2];
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [userOrgs, setUserOrgs] = useState<OrgListModel[]>();
  const [user, setUser] = useState<User>();
  const [orgName, setOrgName] = useState('');
  const [profilePicFile, setProfilePicFile] = useState<File>();
  const dropdownAvatarTriggerRef = useRef(null);
  const dropdownMenuTriggerRef = useRef(null);
  const [billingText, setBillingText] = useState<string>('Upgrade plan');

  const guestUserIdFromRedux = useSelector(
    (state: RootState) => state.authentication.guestModel.userId,
  );
  const { getOrgDetails } = useService();

  // TODO: 20.04.23 - Future Update: PERF - This only needs to be called once, as it is rarely going to change. This should only be updated if user logs out or in again.
  const fetchData = useCallback(
    async (userIdParam: string) => {
      await getOrgDetails(orgId);
      const userResponse = await UserService.getUserAsync(userIdParam);
      setUser(userResponse ?? undefined);

      const orgListResponse: OrgListModel[] = await OrganizationService.getUserOrgList(userId);
      setUserOrgs(orgListResponse);

      const profileResponse: any = await UserService.getProfileDetails(
        userId as string,
        orgId as string,
      );
      if (profileResponse && profileResponse.length > 0 && profileResponse[0]?.avatar !== '') {
        const fileReponse = await RealmRepositories.Attachments.getFilesByIds([
          profileResponse[0]?.avatar || '',
        ]);
        if (fileReponse && fileReponse.length > 0) {
          setProfilePicFile(fileReponse[0]);
        }
      }
      if (orgId) {
        const index = orgListResponse.findIndex((org: OrgListModel) => org.orgId === orgId);
        if (index >= 0) setOrgName(orgListResponse[index].orgName);
      }
    },
    [orgId, userId, guestUserIdFromRedux],
  );

  const handleBookBtnClick = () => {
    const baseOrgRoute = getBaseOrgRoute(window.location.pathname);
    let route = '';
    if (baseOrgRoute) {
      route = baseOrgRoute + StaticRoutes.Book;
    } else {
      route = `${StaticRoutes.SelectOrg}?returnTo=${StaticRoutes.Book}`;
    }

    /** Resetting persisted redux booking state */
    handleResetBooking(false);
    history.push(route);
  };

  useEffect(() => {
    if (guestUserIdFromRedux) fetchData(guestUserIdFromRedux);
    else fetchData(userId);
  }, [fetchData, guestUserIdFromRedux]);

  useEffect(() => {
    if (navbarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [navbarOpen]);

  useEffect(() => {
    if (BillingHelper.allowUserAccess(subscriptionInfo)) {
      setBillingText('My plan');
    }
  }, [subscriptionInfo]);

  const userDropDowns = [
    {
      label: 'Sign out',
      onClick: () => {
        if (pathname === `/org/${getOrgId()}${StaticRoutes.Book}-new`) {
          logUserOutAndRedirectToBookingPage();
        } else logUserOutAndRedirectToLoginPage({ savePreviousIntoHistory: false });
      },
    },
  ];

  const organizationDropdown:
    | DataItem[]
    | {
        sportType: SportType;
        label: string;
        onClick: () => void;
        isHasAvatar: boolean;
        icon?: string;
      }[]
    | undefined = [];

  if (userOrgs && userOrgs?.length > 0) {
    userOrgs?.forEach((org: OrgListModel) => {
      organizationDropdown.push({
        sportType: org.sportType,
        label: org.orgName,
        onClick: async () => {
          if (org.orgId !== orgId) checkUserTypeAndRedirect(userId, org.orgId);
        },
        isHasAvatar: true,
        path: org.orgId,
        icon: org.icon,
      });

      return true;
    });
  }

  useEffect(() => {
    authorize();
  }, [guestUserIdFromRedux]);

  return (
    <header className={styles.Header}>
      {!onBack ? <Logo /> : <img src={BackIcon} onClick={onBack} alt="back-icon" />}
      {isAuthorized ? (
        <>
          {navbarOpen ? (
            <MobileMenu
              onClose={() => setNavbarOpen(false)}
              openNavBar={navbarOpen}
              onCloseNavBar={() => setNavbarOpen(!navbarOpen)}
              billingText={billingText}
            />
          ) : null}
          <div className={styles.desktopNavbar}>
            <NavBar
              onClose={() => setNavbarOpen(false)}
              billingText={billingText}
              isMobile={false}
            />
          </div>
          {title && <span>{title}</span>}
          <div className={styles.mobileMenu}>
            <Button
              label="Book"
              className={styles.mobileBookingBtn}
              onClick={handleBookBtnClick}
              isVisibleBtn={isBookBtnDisplayed()}
            />
            <Hamburger openNav={navbarOpen} onClose={() => setNavbarOpen(!navbarOpen)} />
          </div>
          <div className={styles.info}>
            <Button
              label="Book"
              className={styles.bookingBtn}
              onClick={handleBookBtnClick}
              isVisibleBtn={isBookBtnDisplayed()}
            />
            <div className={styles.dropDown}>
              {dropDownOpen ? (
                <div ref={dropdownMenuTriggerRef}>
                  <DropdownMenu
                    visible={dropDownOpen}
                    onCloseDropdown={() => setDropDownOpen(false)}
                    outsideRefsIgnore={[dropdownAvatarTriggerRef, dropdownMenuTriggerRef]}
                    data={organizationDropdown}
                    title="Switch workspace"
                  />
                  <DropdownMenu
                    visible={dropDownOpen}
                    onCloseDropdown={() => setDropDownOpen(false)}
                    outsideRefsIgnore={[dropdownAvatarTriggerRef, dropdownMenuTriggerRef]}
                    data={userDropDowns}
                  />
                </div>
              ) : null}
            </div>
            <div
              ref={dropdownAvatarTriggerRef}
              onClick={() => {
                setDropDownOpen(!dropDownOpen);
              }}
              className={styles.userMenu}
            >
              {user ? (
                <UserAvatar
                  name={`${user?.first_name} ${user?.last_name}`}
                  src={
                    profilePicFile?.file_name
                      ? `${Config().AWSCloudFrontUrl as string}${profilePicFile?.file_name}`
                      : ''
                  }
                  profileUpdated={fetchData}
                />
              ) : (
                <UserAvatar name="null null" />
              )}
              <div className={styles.subUserMenu}>
                <span className={styles.userFirstName}>{`${user?.first_name}`}</span>
                <span className={styles.userOrgName}>{orgName}</span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <SignButton />
          <SignInDialog />
          <SignUpDialog />
        </>
      )}
    </header>
  );
};

export default Header;
