import { BaseRoute } from '../../base/Route.base';
import SessionProvider from '../../../providers/SessionProvider';
import { Config } from '../../../config';

export class Internal extends BaseRoute {
  async getUserSubscriptionInfo() {
    const billingKey = Config().Billing.InternalKey;
    const userId = SessionProvider.getUserId();
    const url = `/internal/users/${userId}`;

    return await this.api.getWithConfig(url, {
      headers: { InternalAuthorization: billingKey },
    });
  }
}
