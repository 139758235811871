import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import SessionProvider from 'providers/SessionProvider';
import { SubscriptionsHelper } from 'common/utils/subscriptions.helper';
import styles from '../NavBar.module.scss';
import stylesDropDown from './NavBarDropDown.module.scss';
import myPlanIcon from '../../../../assets/icons/FW_ Icons/My plan.svg';
import changePasswordIcon from '../../../../assets/icons/FW_ Icons/Change password.svg';
import profileIcon from '../../../../assets/icons/navbar-icons/contact-icon.svg';
import contactsIcon from '../../../../assets/icons/navbar-icons/contacts-icon.svg';
import DropdownMenu from '../../../DropdownMenu/DropdownMenu';
import { getBaseOrgRoute } from '../../../../common/utils/common.helpers';

interface IProps {
  onClose: any;
  dropDownOpen: boolean;
  triggerDropRef: any;
  arrowTrigger: Function;
  billingText: string;
}

const NavBarDropDown: React.FC<IProps> = ({
  onClose,
  dropDownOpen,
  triggerDropRef,
  arrowTrigger,
  billingText,
}) => {
  const userId = SessionProvider.getUserId();
  const [open, setOpen] = useState(dropDownOpen);
  const [isPaidSubscription, setIsPaidSubscription] = useState<boolean>(false);

  const mobileMenuRef = useRef(null);

  if (!open) {
    arrowTrigger();
  }

  useEffect(() => {
    if (billingText === 'Upgrade plan') setIsPaidSubscription(false);
    else setIsPaidSubscription(true);
  }, [billingText]);

  const userDropDowns = [
    {
      label: 'Contacts',
      path: `${getBaseOrgRoute(window.location.pathname)}/contacts`,
      icon: contactsIcon,
      alt: 'contacts-icon',
    },
    {
      label: 'Profile',
      path: `${getBaseOrgRoute(window.location.pathname)}/client/${userId}/userDetails`,
      icon: profileIcon,
      alt: 'calendar-icon',
    },
    {
      label: 'Change password',
      path: `${getBaseOrgRoute(window.location.pathname)}/client/${userId}/changePassword`,
      icon: changePasswordIcon,
      alt: 'practice-locations-icon',
    },
    {
      label: billingText,
      onClick: async () => {
        if (isPaidSubscription) {
          SubscriptionsHelper.redirectToBillingPortalHome();
        } else {
          SubscriptionsHelper.redirectToBillingPortalSubscriptions();
        }
      },
      icon: myPlanIcon,
      alt: 'library-icon',
    },
  ];

  return (
    <>
      {open && (
        <div className={styles.desktopDropdown}>
          <DropdownMenu
            visible={dropDownOpen}
            onCloseDropdown={() => setOpen(false)}
            outsideRefsIgnore={[triggerDropRef, mobileMenuRef]}
            data={userDropDowns}
            customStyles={stylesDropDown.dropDown}
          />
        </div>
      )}
      <div ref={mobileMenuRef}>
        <ul className={styles.dropDownList}>
          {userDropDowns?.map((item) => {
            return (
              <li key={item.alt}>
                {item.onClick ? (
                  <a onClick={item.onClick}>
                    <img src={item.icon} alt={item.icon} width={20} height={20} />
                    {item.label}
                  </a>
                ) : (
                  <NavLink exact to={item.path ?? ''} onClick={onClose}>
                    <img src={item.icon} alt={item.icon} width={20} height={20} />
                    {item.label}
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default NavBarDropDown;
