export enum PortalSubscriptionStatusEnum {
  None = 0,
  Active = 1,
  PastDue = 2,
  Unpaid = 3,
  Canceled = 4,
  Incomplete = 5,
  IncompleteExpired = 6,
  Trialing = 7,
  Paused = 8,
}
