import currencyData from 'currency-library/lib/currencies.json';
import numericCodesMapping from 'currency-library/lib/numericCodesMapping.json';
import symbolMapping from 'currency-library/lib/symbolMapping.json';

const currencyDataTyped: Record<string, CurrencyData> = currencyData;
const numericCodesMappingTyped: Record<string, string> = numericCodesMapping;
const symbolMappingTyped: Record<string, string> = symbolMapping;

export const getAll = (): CurrencyData[] => {
  return Object.values(currencyDataTyped);
};
export const getByIsoCode = (isoCode: string): CurrencyData | undefined =>
  currencyDataTyped[isoCode];
export const getByNumericCode = (numericCode: string): CurrencyData | undefined =>
  currencyDataTyped[numericCodesMappingTyped[numericCode]];
export const getBySymbol = (symbol: string): CurrencyData | undefined =>
  currencyDataTyped[symbolMappingTyped[symbol]];

export interface CurrencyData {
  isoCode: string;
  numericCode: string;
  minorUnits: number;
  name: string;
  symbol: string;
}

export const DefaultCurrencyData: CurrencyData = {
  isoCode: 'NZD',
  numericCode: '554',
  minorUnits: 2,
  name: 'New Zealand Dollar',
  symbol: '$',
};
