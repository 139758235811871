import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FilledInput, { FilledInputProps } from '@mui/material/FilledInput';
import PlacesAutocomplete from 'react-google-autocomplete';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface GoogleMapPlaceResult {
  address_components: AddressComponent[];
  geometry: {
    location: {
      lat: Function;
      lng: Function;
    };
    viewport: {
      south: number;
      west: number;
      north: number;
      east: number;
    };
  };
}

export interface FullAddress {
  street_number: string;
  street_name: string;
  suburb: string;
  city: string;
  zip_code: string;
  country: string;
  country_short_name: string;
}

interface AddressAutocompleteTextfieldProps
  extends Pick<FilledInputProps, 'placeholder' | 'value' | 'disabled'> {
  type?: 'text' | 'number';
  label: string;
  value: string;
  hasError?: boolean;
  errorMessage?: string;
  isEditMode?: boolean;
  // eslint-disable-next-line
  onChange: (value: FullAddress) => void;
}

export const AddressAutocompleteTextfield = (props: AddressAutocompleteTextfieldProps) => {
  const {
    label,
    placeholder,
    value,
    hasError,
    errorMessage,
    isEditMode,
    onChange,
    type,
    disabled,
  } = props;

  const getSelectedPriceField = (place: GoogleMapPlaceResult, field: string) =>
    place.address_components.find((component) => component.types.includes(field));

  const handleSelectPlace = (place: GoogleMapPlaceResult) => {
    const street_number = getSelectedPriceField(place, 'street_number');
    const street_name = getSelectedPriceField(place, 'route');

    let suburb = getSelectedPriceField(place, 'sublocality');
    if (!suburb) {
      suburb = getSelectedPriceField(place, 'locality');
    }

    const city = getSelectedPriceField(place, 'administrative_area_level_1');
    const zip_code = getSelectedPriceField(place, 'postal_code');
    const country = getSelectedPriceField(place, 'country');

    onChange({
      street_number: street_number?.short_name ?? '',
      street_name: street_name?.short_name ?? '',
      suburb: suburb?.short_name ?? '',
      city: city?.short_name ?? '',
      zip_code: zip_code?.short_name ?? '',
      country: country?.long_name ?? '',
      country_short_name: country?.short_name ?? '',
    });
  };

  return (
    <Stack spacing={1}>
      {isEditMode && (
        <>
          <Typography>{label}</Typography>
          <FilledInput
            fullWidth
            hiddenLabel
            disableUnderline
            disabled={disabled}
            placeholder={placeholder}
            type={type}
            // eslint-disable-next-line
            inputComponent={({ inputRef, onFocus, onBlur, ...props }) => (
              <PlacesAutocomplete
                {...props} // eslint-disable-line
                apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
                options={{
                  fields: ['address_components', 'geometry'],
                  types: ['address'],
                }}
                onPlaceSelected={(selected) => {
                  handleSelectPlace(selected);
                }}
              />
            )}
          />
          {hasError && (
            <Typography color="#ff0000" fontSize="0.8rem">
              {errorMessage}
            </Typography>
          )}
        </>
      )}
      {!isEditMode && (
        <>
          <Typography fontWeight="bold">{label}</Typography>
          <Typography>{value && value.length && value !== '' ? value : '-'}</Typography>
        </>
      )}
    </Stack>
  );
};
