import { Config } from '../../config';
import { BaseRepo } from './base.repo';

export default class OrgClientsRepository extends BaseRepo {
  // #region Private Properties
  private orgClientsCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('org_clients');
  // #endregion

  // #region Public Methods
  async getClientByUserIdAsync(orgId: string, userId: string) {
    return this.orgClientsCollection?.findOne({
      org_id: orgId,
      'clients.user_id': userId,
    });
  }
  // #endregion
}
