import React from 'react';
import styles from './Link.module.scss';

interface IProps {
  url: string;
  text: string;
}

const Link: React.FC<IProps> = ({ url, text }) => (
  <a href={url} className={styles.link}>
    {text}
  </a>
);

export default Link;
