import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import NavBarDropDown from '../NavBarDropdown/NavBarDropDown';
import styles from '../NavBar.module.scss';
import { MenuItems } from '../MenuItems';
import { NavMenuItemsType } from '../../../../data/enums/NavMenuItemsType.enum';

interface INavBarMyAccount {
  onClose: Function;
  billingText: string;
}
const NavBarMyAccount: React.FC<INavBarMyAccount> = ({ onClose, billingText }) => {
  const [openDropdown, setDropDownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const mySportMenuOpen = clsx(openDropdown ? styles.dropDownOpen : styles.mygolfDefault);

  return (
    <>
      {MenuItems.filter((item: any) => item.type === NavMenuItemsType.MyAccount).map(
        (currentItem: any) => {
          return (
            <React.Fragment key={currentItem.alt}>
              <div
                key={currentItem.alt}
                className={mySportMenuOpen}
                onClick={() => setDropDownOpen(!openDropdown)}
                ref={dropdownRef}
              >
                <img src={currentItem.icon} alt={currentItem.icon} width={20} height={20} />
                Account
              </div>
              {openDropdown && (
                <NavBarDropDown
                  onClose={onClose}
                  dropDownOpen={openDropdown}
                  triggerDropRef={dropdownRef}
                  arrowTrigger={() => setDropDownOpen(false)}
                  billingText={billingText}
                />
              )}
            </React.Fragment>
          );
        },
      )}
    </>
  );
};

export default NavBarMyAccount;
