import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import type { RootState } from '../../../../redux/store';

import { BookingContainer } from './components';

export const BookingUnavailable = () => {
  const application = useSelector((state: RootState) => state.application);
  const orgName = application.active.orgDetails?.org_name ?? 'Your organization';
  return (
    <BookingContainer isLoading={false} title="Bookings unavailable">
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Card elevation={1}>
            <CardContent>
              <Box textAlign="center" paddingY={2}>
                {orgName} is not taking any bookings at the moment.
                <br />
                Please contact your coach.
                <br />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BookingContainer>
  );
};
