import React, { useState } from 'react';
import { Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'components/Dialog/Dialog';
import { RootState } from 'redux/store';
import { AuthenticatedUser, authenticationSlice } from 'redux/slices';
import { SignUpModel } from 'api/models/auth/signup.model';
import { Contact } from 'data/entities/organization.entity';
import { Api } from 'api/Api';
import { AxiosErrorHandler } from 'common/utils/errorHandler.helpers';
import { getOrgId } from 'common/utils/common.helpers';
import UserService from 'data/services/user.service';
import SessionProvider from 'providers/SessionProvider';
import { useSignUp } from '../../../../../auth/pages/Signup/useSignUp';
import { SignUp } from '../../../../../auth/pages/Signup/SignUp';
import { Config } from '../../../../../../config';

export const SignUpDialog = () => {
  const [isError, setIsError] = useState<boolean>(false);

  const signUpOpen = useSelector((state: RootState) => state.authentication.signUpOpen);

  const dispatch = useDispatch();

  const orgId = getOrgId();

  const [isProcessingGuestSignUp, setIsProcessingGuestSignUp] = useState<boolean>(false);

  const {
    firstName,
    lastName,
    email,
    confirmEmail,
    contact,
    password,
    under18Info,
    checkboxes,
    errorMessageEvent,
    onEmailChanged,
    onConfirmEmailChange,
    onFirstNameChanged,
    onLastNameChanged,
    onPasswordChanged,
    onConfirmPasswordChange,
    onChangePhoneNumber,
    UpdateErrorMessageEvent,
    setCheckBoxes,
    onChangeDateOfBirth,
    onChangeAllergies,
    onChangeMedication,
    onChangeEmergencyContactFirstName,
    onChangeEmergencyContactLastName,
    onChangeEmergencyContactRelationship,
    onChangeEmergencyContactPhone,
    isSignUpFieldsComplete,
  } = useSignUp(false);

  /**
   * Note:
   * This is a temporary fix, it should be done on the backend endpoint during the signup process.
   *
   */
  const getOpenIDToken = async (userAccessToken: string, id: string) => {
    try {
      const response = await fetch(`${Config().BaseCognitoOpenIdURL}/cognito/getOpenIDToken`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessToken: userAccessToken,
          userId: id,
        }),
      });
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error getting open ID token: ', error);
      return null;
    }
  };

  const onGuestSignUpClicked = async () => {
    try {
      setIsProcessingGuestSignUp(true);
      if (!password) {
        toast.error('Please complete your account information');
        return;
      }

      const signUpModel: SignUpModel = {
        email,
        password,
        firstName,
        lastName,
        isStaffInvite: false,
        orgId: orgId ?? '',
        userDetails: {
          isUnder18: checkboxes.under18,
          contact,
          dateOfBirth: under18Info?.dateOfBirth,
          allergies: under18Info?.allergies,
          medication: under18Info?.medication,
          emergencyContact1: {
            first_name: under18Info?.emergencyContact1?.firstName ?? '',
            last_name: under18Info?.emergencyContact1?.lastName ?? '',
            relationship: under18Info?.emergencyContact1?.relationship ?? '',
            contact: under18Info?.emergencyContact1?.contact as Contact,
          },
          emergencyContact2: {
            first_name: under18Info?.emergencyContact2?.firstName ?? '',
            last_name: under18Info?.emergencyContact2?.lastName ?? '',
            relationship: under18Info?.emergencyContact2?.relationship ?? '',
            contact: under18Info?.emergencyContact2?.contact as Contact,
          },
        },
      };

      const signUpResponse = await Api.ClientRoutes.Auth.signUp(signUpModel);
      const result = await UserService.userLogin(email, password);

      const { accessToken, refreshToken, userId, firstName: firstUserName } = result;

      if (accessToken && userId) {
        /**
         * Note:
         * This is a temporary fix, it should be done on the backend endpoint during the signup process.
         *
         */
        const fetchOpenIDTokenResult = await getOpenIDToken(accessToken, userId);
        await UserService.insertUserCognitoId(userId, fetchOpenIDTokenResult.data.IdentityId);
      }

      SessionProvider.updateSession(
        accessToken as string,
        refreshToken as string,
        userId as string,
        email,
        firstUserName,
        true,
      );

      dispatch(
        authenticationSlice.actions.updateGuestModel({
          userId: signUpResponse.id,
          email,
          firstName,
        }),
      );

      const authenticatedUser: AuthenticatedUser = {
        accessToken: accessToken as string,
        refreshToken: refreshToken as string,
        userId,
        firstName,
        lastName,
      };

      /** First step to decouple logic by using redux state */
      dispatch(authenticationSlice.actions.updateIsAuthenticated(true));
      dispatch(authenticationSlice.actions.updateAuthenticatedUser(authenticatedUser));

      toast.success(
        'Your account has been successfully created. An email has been sent with your account details',
      );

      setIsError(false);
      dispatch(authenticationSlice.actions.changeSignUpOpen(false));

      /**
       * TODO:
       * Until the authentication is fully refactored, we will use this temporary solution.
       * The following delay is needed to prevent browser from reloading before the state is fully saved
       * into the localStorage by redux-persist
       *
       * TODO NEXT: Remove the following window.location.reload()
       */
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.error(compiledErrorMessage);
      setIsError(true);
    } finally {
      setIsProcessingGuestSignUp(false);
    }
  };

  return (
    <Dialog
      title="Create an account"
      open={signUpOpen}
      onClose={() => dispatch(authenticationSlice.actions.changeSignUpOpen(false))}
      submitBtnTitle="Sign Up"
      submitAction={onGuestSignUpClicked}
      alternativeBtnAction={() => {
        dispatch(authenticationSlice.actions.changeSignUpOpen(false));
        dispatch(authenticationSlice.actions.changeSignInOpen(true));
      }}
      alternativeBtnTitle="Sign In"
      submitButtonDisabled={isProcessingGuestSignUp || !isSignUpFieldsComplete || isError}
    >
      <Stack spacing={2} marginTop={2}>
        <SignUp
          isPhoneNumView
          firstName={firstName}
          lastName={lastName}
          email={email}
          confirmEmail={confirmEmail}
          isEmailFieldDisabled={false}
          onFirstNameChanged={onFirstNameChanged}
          onLastNameChanged={onLastNameChanged}
          onEmailChanged={onEmailChanged}
          onConfirmEmailChanged={onConfirmEmailChange}
          onPasswordChanged={onPasswordChanged}
          onConfirmPasswordChanged={onConfirmPasswordChange}
          onChangePhoneNumber={onChangePhoneNumber}
          errorMessageEvent={errorMessageEvent}
          checkBoxes={checkboxes}
          setCheckBoxes={setCheckBoxes}
          UpdateErrorMessageEvent={UpdateErrorMessageEvent}
          onChangeDateOfBirth={onChangeDateOfBirth}
          onChangeAllergies={onChangeAllergies}
          onChangeMedication={onChangeMedication}
          onChangeEmergencyContactFirstName={onChangeEmergencyContactFirstName}
          onChangeEmergencyContactLastName={onChangeEmergencyContactLastName}
          onChangeEmergencyContactRelationship={onChangeEmergencyContactRelationship}
          onChangeEmergencyContactPhone={onChangeEmergencyContactPhone}
        />
      </Stack>
    </Dialog>
  );
};
