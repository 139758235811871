import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import React from 'react';

export const Snackbar = (props: SnackbarProps) => {
  const { open, label, onClose, severity } = props;
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MuiAlert onClose={onClose} severity={severity} sx={{ width: '100%' }}>
        {label}
      </MuiAlert>
    </MuiSnackbar>
  );
};

interface SnackbarProps {
  open: boolean;
  label: string;
  onClose: () => void;
  severity: AlertColor;
}
