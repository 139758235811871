import React, { useState, useEffect, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import {
  parse,
  parseISO,
  getDaysInMonth,
  format,
  startOfDay,
  getYear,
  getMonth,
  isAfter,
  isBefore,
} from 'date-fns';
import { DateTime } from 'luxon';
import { get } from 'lodash';
import LeftArrowIcon from '../../assets/icons/arrows/left-arrow.svg';
import RightArrowIcon from '../../assets/icons/arrows/right-arrow.svg';
import Spinner from '../Spinner/Spinner';
import { normalizeYear, now } from '../../common/utils/date.helpers';
import styles from './Calendar.module.scss';

interface CalendarProps {
  min?: Date;
  max?: Date;
  isYearSelection?: boolean;
  selectedDate: string | null;
  availableDays?: Record<string, any>[];
  setSelectedDate: Function;
  onChangeMonth?: (year: number, month: number) => Promise<void>;
  onFetchEvents?: (startDate: DateTime, endDate: DateTime) => Promise<any[]>;
  getDaysIndicators?: (startDate: DateTime, endDate: DateTime, events: any[]) => number[];
  isLoading?: boolean;
}

const Calendar = (props: CalendarProps) => {
  const {
    min,
    max,
    isYearSelection,
    selectedDate,
    availableDays,
    setSelectedDate,
    onChangeMonth,
    onFetchEvents,
    getDaysIndicators,
    isLoading = false,
  } = props;

  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [prevMonth, setPrevMonth] = useState(month);
  const [days, setDays] = useState(0);
  const [loading, setLoading] = useState(false);
  const [daysIndicators, setDaysIndicators] = useState<number[]>();
  const onMonthChange = useCallback(
    async (m, y) => {
      setPrevMonth(m);
      if (onChangeMonth) {
        setLoading(true);
        await onChangeMonth(y, m);
        setLoading(false);
      }
      const startDate = now().set({ year: y, month: m }).startOf('month');
      const endDate = startDate.endOf('month');
      if (onFetchEvents) {
        setLoading(true);
        const events = await onFetchEvents(startDate.minus({ seconds: 1 }), endDate);
        if (getDaysIndicators) {
          setDaysIndicators(getDaysIndicators(startDate, endDate, events));
        }
        setLoading(false);
      }
    },
    [getDaysIndicators, onChangeMonth, onFetchEvents],
  );

  useEffect(() => {
    const date = parseISO(selectedDate || new Date().toISOString());

    setYear(getYear(date));
    setMonth(getMonth(date) + 1);
  }, [selectedDate]);

  useEffect(() => {
    setDays(getDaysInMonth(parse(`${month}/${year}`, 'L/yyyy', new Date())));
    if (year && month && month !== prevMonth) {
      onMonthChange(month, year);
    }
  }, [month, onMonthChange, prevMonth, year]);

  const monthLabel = useMemo(
    () => (month && year ? format(parse(`${month}/${year}`, 'L/yyyy', new Date()), 'MMMM') : ''),
    [month, year],
  );

  const startDayNumber = useMemo(
    () =>
      month && year ? startOfDay(parse(`${month}/${year}`, 'L/yyyy', new Date())).getDay() : 0,
    [month, year],
  );

  const getButtonClassNames = useCallback(
    (currentDay: number) => {
      const currentDate = `${year}-${month}-${currentDay}`;
      const date = selectedDate && format(parseISO(selectedDate), 'yyyy-L-d');

      return currentDate === date ? clsx(styles.dayButton, styles.activeButton) : styles.dayButton;
    },
    [month, selectedDate, year],
  );

  const onSelectDate = (selectDay: number) => {
    setSelectedDate(parse(`${year}-${month}-${selectDay}`, 'yyyy-L-d', new Date()).toISOString());
  };

  const nextMonth = () => {
    if (month === 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const previousMonth = () => {
    if (month === 1) {
      if (year !== 1) {
        setMonth(12);
        setYear(year - 1);
      }
    } else {
      setMonth(month - 1);
    }
  };

  const daysToAvailable = availableDays && availableDays.map(({ day }) => day);

  return (
    <div className={styles.Calendar}>
      <div className={styles.header}>
        <span>
          {monthLabel}{' '}
          {isYearSelection && (
            <span className={styles.yearArrowLeft} onClick={() => year > 1 && setYear(year - 1)}>
              <img alt="left-arrow" src={LeftArrowIcon} />
            </span>
          )}
          {isYearSelection ? (
            <input
              className={styles.yearInput}
              type="tel"
              value={normalizeYear(year.toString(), 4)}
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/, '');

                if (+value >= 1000 && value.length > 4) {
                  setYear(+value.slice(1, 5) || 1);
                } else if (+value === 0) {
                  setYear(1);
                } else {
                  setYear(+value);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === 'ArrowUp') setYear(year + 1);
                if (e.key === 'ArrowDown' && year > 1) setYear(year - 1);
              }}
            />
          ) : (
            <span>{normalizeYear(year.toString(), 4)}</span>
          )}
          {isYearSelection && (
            <span className={styles.yearArrowRight} onClick={() => setYear(year + 1)}>
              <img alt="right-arrow" src={RightArrowIcon} />
            </span>
          )}
        </span>
        <div className={styles.arrows}>
          <span onClick={previousMonth}>
            <img alt="left-arrow" src={LeftArrowIcon} />
          </span>
          <span onClick={nextMonth}>
            <img alt="right-arrow" src={RightArrowIcon} />
          </span>
        </div>
      </div>
      <div className={styles.weekDays}>
        <span>S</span>
        <span>M</span>
        <span>T</span>
        <span>W</span>
        <span>T</span>
        <span>F</span>
        <span>S</span>
      </div>
      {!loading && isLoading === false ? (
        <div className={styles.dateGrid}>
          {!!days &&
            new Array(days)
              .fill(null)
              .map((_, index) => index)
              .map((day) => {
                const availableDay = availableDays?.find((x) => x.day === day + 1);
                const isDayFullyBooked: boolean = availableDay?.isDayFullyBooked ?? false;
                const morningTimes = get(availableDay, 'morningTimes', []);
                const afternoonTimes = get(availableDay, 'afternoonTimes', []);
                const dayDoesNotExist = availableDays && !daysToAvailable?.includes(day + 1);

                const isMin = min
                  ? isAfter(min, parse(`${day + 1}-${month}-${year}`, 'd-M-yyyy', new Date()))
                  : false;
                const isMax = max
                  ? isBefore(max, parse(`${day + 1}-${month}-${year}`, 'd-M-yyyy', new Date()))
                  : false;

                const disabled = isMin || isMax || dayDoesNotExist || isDayFullyBooked;

                const isIndicatorsVisible = morningTimes.length > 0 || afternoonTimes.length > 0;

                const morningIndicatorStyle = clsx(
                  styles.indicator,
                  !isDayFullyBooked && morningTimes.length > 0 && styles.activeIndicator,
                );

                const afternoonIndicatorStyle = clsx(
                  styles.indicator,
                  !isDayFullyBooked && afternoonTimes.length > 0 && styles.activeIndicator,
                );

                return day === 0 ? (
                  <button
                    key={day}
                    className={getButtonClassNames(day + 1)}
                    type="button"
                    style={{ gridColumn: startDayNumber + 1 }}
                    onClick={() => onSelectDate(day + 1)}
                    disabled={disabled}
                  >
                    {day + 1}
                    {availableDays && isIndicatorsVisible && (
                      <div className={styles.indicators}>
                        <div className={morningIndicatorStyle} />
                        <div className={afternoonIndicatorStyle} />
                      </div>
                    )}
                    {daysIndicators && (
                      <div className={styles.daysIndicators}>
                        {new Array(daysIndicators[day])
                          .fill(null)
                          .slice(0, 4)
                          .map((_, i) => (
                            <div className={styles.dayIndicator} key={i} />
                          ))}
                      </div>
                    )}
                  </button>
                ) : (
                  <button
                    key={day}
                    className={getButtonClassNames(day + 1)}
                    type="button"
                    onClick={() => onSelectDate(day + 1)}
                    disabled={disabled}
                  >
                    {day + 1}
                    {availableDays && isIndicatorsVisible && (
                      <div className={styles.indicators}>
                        <div className={morningIndicatorStyle} />
                        <div className={afternoonIndicatorStyle} />
                      </div>
                    )}
                    {daysIndicators && (
                      <div className={styles.daysIndicators}>
                        {new Array(daysIndicators[day])
                          .fill(null)
                          .slice(0, 4)
                          .map((_, i) => (
                            <div className={styles.dayIndicator} key={i} />
                          ))}
                      </div>
                    )}
                  </button>
                );
              })}
        </div>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner color="#000" />
        </div>
      )}
    </div>
  );
};

export default Calendar;
