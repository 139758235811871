import React from 'react';

import styles from './ListPageLayout.module.scss';

interface ListPageLayoutProps {
  filterComponent: React.ReactNode;
  contentComponent: React.ReactNode;
  additionalComponent?: React.ReactNode;
}

const ListPageLayout: React.FC<ListPageLayoutProps> = ({
  filterComponent,
  contentComponent,
  additionalComponent,
}) => {
  return (
    <div className={styles.wrapper}>
      {filterComponent}
      <div className={styles.content}>{contentComponent}</div>
      <div className={styles.additional}>{additionalComponent}</div>
    </div>
  );
};

export default ListPageLayout;
