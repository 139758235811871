import React from 'react';

import { LessonCategory } from '../../../../data/entities/orgLessonCategories.entity';
import styles from './EditLessonContent.module.scss';
import SelectCategoriesButtons from '../SelectCategoriesButtons/SelectCategoriesButtons';
import ModalWarningText from '../../../../components/ModalWindow/ModalWarningText';

interface EditLessonContentProps {
  lessonTitle: string;
  categories: LessonCategory[];
  selectedCategories: string[];
  setSelectedCategories: Function;
  setLessonTitle: Function;
}

const EditLessonContent: React.FC<EditLessonContentProps> = ({
  lessonTitle,
  categories,
  setLessonTitle,
  selectedCategories,
  setSelectedCategories,
}) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Lesson Title</span>
      <input
        className={styles.input}
        type="text"
        placeholder="Update lesson title"
        value={lessonTitle}
        maxLength={50}
        onChange={(e) => setLessonTitle(e.target.value)}
      />
      <span className={styles.title}>Categories</span>
      <SelectCategoriesButtons
        categories={categories}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
      />
      <ModalWarningText message='By selecting "Update" your coach(es) will see the edited Lesson title and/or Category' />
    </div>
  );
};

export default EditLessonContent;
