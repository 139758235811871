import React from 'react';
import { DateTime } from 'luxon';
import Calendar from '../../../../components/Calendar/Calendar';
import MobileCalendar from '../MobileCalendar/MobileCalendar';
import { useWindowDimensions } from '../../../../common/hooks/useWindowDimensions';

import styles from './CalendarFilters.module.scss';

interface CalendarFiltersProps {
  /**
   * ISO string
   */
  selectedDate: string;
  setSelectedDate: (isoDateString: string) => void;
  fetchEvents: (startDate: DateTime, endDate: DateTime) => Promise<any[]>;
  getDaysIndicators?: (startDate: DateTime, endDate: DateTime, events: any[]) => number[];
}

const CalendarFilters: React.FC<CalendarFiltersProps> = ({
  selectedDate,
  setSelectedDate,
  fetchEvents,
  getDaysIndicators,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div className={styles.wrapper}>
      {width > 1024 ? (
        <div className={styles.desktopCalendarWrapper}>
          <Calendar
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            onFetchEvents={fetchEvents}
            getDaysIndicators={getDaysIndicators}
          />
        </div>
      ) : (
        <MobileCalendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          onFetchEvents={fetchEvents}
          getDaysIndicators={getDaysIndicators}
        />
      )}
    </div>
  );
};

export default CalendarFilters;
