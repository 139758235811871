import { useSelector, useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import React from 'react';
import { bookingSlice, CompactBusyTime } from 'redux/slices';
import { getUnixMilliseconds, getZonedDate } from 'common/utils/date.helpers';
import {
  AvailableDate,
  AvailableDay,
  AvailableMonth,
  SelectedService,
} from 'modules/book/models/booking.model';
import { BusyTimeItem } from '../../../../../api/endpoints/client/Org.endpoint';
import type { RootState } from '../../../../../redux/store';
import { Api } from '../../../../../api/Api';
import { useOrgId, useUserId } from '../../../../../common/hooks';
import { SelectedCalendarDate } from '../components';
import { useCalendar } from './useCalendar';
import { useService } from './useService';

export type FractionOfDay = 'morning' | 'afternoon';

export type BusyTime = Pick<
  BusyTimeItem,
  'dateStart' | 'dateEnd' | 'isFullyBooked' | 'isPurchased'
>;

interface GetAllTimeSlotsInput {
  selectedDate: SelectedCalendarDate;
  durationInMinutes: number;
}

interface GetWorkingHourTimeSlotsInput extends GetAllTimeSlotsInput {
  startTime: string; // 08:00
  endTime: string; // 23:00
  serviceDurationInMinutes: number; // this is coming from the lesson / service object
}

interface GetAvailableWorkingHourTimeSlotsInput extends GetWorkingHourTimeSlotsInput {
  busyTimes?: BusyTime[];
}

interface GetMorningAfternoonAvailableTimeSlotsInput extends GetAvailableWorkingHourTimeSlotsInput {
  category: FractionOfDay;
  timezone: string;
}

interface GetServiceEndTimeInput {
  startTimeInMilis: number;
  durationInMinutes: number;
  timezone: string;
}

interface GetReduxConnectedAvailableCategoryTimeSlotsInput {
  timezone: string;
  selectedDate: SelectedCalendarDate;
  category: FractionOfDay;
}

interface IsContainingScheduledTimeSlotsInput
  extends GetReduxConnectedAvailableCategoryTimeSlotsInput {
  serviceId: string;
}

interface IsTimeSlotInSelectedDateInput
  extends Pick<IsContainingScheduledTimeSlotsInput, 'selectedDate'> {
  timeSlotStartTimeMilis: number;
}

interface SlotStartEndDetail {
  milis: number;
  dateTimeShort: string;
  timeShort: string;
}

interface TimeSlot {
  start: SlotStartEndDetail;
  end: SlotStartEndDetail;
}

interface IsTimeSlotCheckProps {
  timeSlot: TimeSlot;
  serviceId: string;
}

export const useTimeSlots = () => {
  const { orgId } = useOrgId();
  const { userId } = useUserId();
  const { getAvgarDayOfWeek } = useCalendar();
  const {
    getActiveSelectedService,
    getServiceDetails,
    getCoachWorkHours,
    getChosenCoachFromService,
    isAdvancedLesson,
    isProgramme,
    isEvent,
  } = useService();

  const now = DateTime.now();
  const booking = useSelector((state: RootState) => state.booking);
  const application = useSelector((state: RootState) => state.application);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = React.useState(false);

  /** Date/Time formats for all methods */
  const timeShortFormat = 'hh:mm a';
  const dateTimeShortFormat = `dd LLL - ${timeShortFormat}`;

  /** Helper function : Generate unique busy times from previous data stored in redux and the new incoming data from the parameter */
  const getUniqueBusyTimes = (busyTimes: BusyTimeItem[]): CompactBusyTime[] => {
    return busyTimes.map(({ dateStart, dateEnd, isFullyBooked, isPurchased, serviceId }) => ({
      dateStart,
      dateEnd,
      isFullyBooked,
      isPurchased,
      serviceId,
    }));
  };

  /** Helper function */
  const getFriendlyCompactBusyTimes = (compactBusyTimes: CompactBusyTime[]) =>
    compactBusyTimes.map((busyTime) => ({
      ...busyTime,
      dateStart: DateTime.fromMillis(busyTime.dateStart).toFormat('dd LLL yyyy HH:mm:ss'),
      dateEnd: DateTime.fromMillis(busyTime.dateEnd).toFormat('dd LLL yyyy HH:mm:ss'),
    }));

  /**
   * Get all time slots in one day by dividing one day into slots of durationInMinutes minutes, and
   * return the slots with milis, and friendly texts (dateTimeShort and timeShort) for future use */
  const getAllTimeSlots = (params: GetAllTimeSlotsInput): TimeSlot[] => {
    const timeSlots: TimeSlot[] = [];
    const { selectedDate, durationInMinutes } = params;

    const totalTimeSlotsInOneDay = (24 * 60) / durationInMinutes;
    const startOfDayMilis = DateTime.fromObject(selectedDate).startOf('day');

    for (let i = 1; i <= totalTimeSlotsInOneDay; i++) {
      const curStartTime = startOfDayMilis.plus({ minutes: (i - 1) * durationInMinutes });
      const curEndTime = curStartTime.plus({ minutes: durationInMinutes }).minus({ second: 1 });
      timeSlots.push({
        start: {
          milis: curStartTime.toMillis(),
          dateTimeShort: curStartTime.toFormat(dateTimeShortFormat),
          timeShort: curStartTime.toFormat(timeShortFormat),
        },
        end: {
          milis: curEndTime.toMillis(),
          dateTimeShort: curEndTime.toFormat(dateTimeShortFormat),
          timeShort: curEndTime.toFormat(timeShortFormat),
        },
      });
    }

    return timeSlots;
  };

  /** Returns milisecond value of a selected date and time such as 23:00 */
  const getMilisFromSelectedDateAndTimeString = (
    selectedDate: SelectedCalendarDate,
    timeStr: string,
  ): number => {
    const tmp = timeStr.split(':');
    const hour = parseInt(tmp[0], 10);
    const minute = parseInt(tmp[1], 10);
    return DateTime.fromObject({ ...selectedDate, hour, minute }).toMillis();
  };

  /** Returns all time slots that fall within selected working hour (boundary set by startTime and endTime) */
  const getWorkingHourTimeSlots = (params: GetWorkingHourTimeSlotsInput): TimeSlot[] => {
    const { selectedDate, durationInMinutes, startTime, endTime, serviceDurationInMinutes } =
      params;

    const serviceDurationInMilis = serviceDurationInMinutes * 60 * 1000;
    const startTimeMilis = getMilisFromSelectedDateAndTimeString(selectedDate, startTime);

    /** ensure that the last service in that work day is calculated too */
    const endTimeMilis = getMilisFromSelectedDateAndTimeString(selectedDate, endTime);

    const timeSlots = getAllTimeSlots({ selectedDate, durationInMinutes });
    return timeSlots
      .filter(
        (timeSlot) => timeSlot.start.milis >= startTimeMilis && timeSlot.end.milis <= endTimeMilis,
      )
      .filter((timeSlot) => timeSlot.start.milis + serviceDurationInMilis <= endTimeMilis);
  };

  /** Checks whether a specific timeslot is booked or not, and returns a boolean */
  const isTimeSlotBooked = (
    timeSlot: TimeSlot,
    bookedSlots: Pick<BusyTimeItem, 'dateStart' | 'dateEnd' | 'isFullyBooked' | 'isPurchased'>[],
  ): boolean => {
    return bookedSlots.some((bookedSlot) => {
      /**
       * Is time slot start time between bookend start/end times?
       * [booked dateStart] --- [timeSlot start] --- [booked dateEnd]
       */
      const isTimeSlotStartTimeWithinBookedStartEndTimes =
        timeSlot.start.milis >= bookedSlot.dateStart && timeSlot.start.milis < bookedSlot.dateEnd;

      /**
       * Is time slot end time between bookend start/end times?
       * [booked dateStart] --- [timeSlot end] --- [booked dateEnd]
       */
      const isTimeSlotEndTimeWithinBookedStartEndtimes =
        timeSlot.end.milis > bookedSlot.dateStart && timeSlot.end.milis <= bookedSlot.dateEnd;

      /**
       * Is booked times inside time slot start/end times?
       * [timeSlot start] --- [booked dateStart] --- [booked dateEnd] --- [timeSlot end]
       */

      const isBookedTimesWithinTimeSlotStartEndTimes =
        timeSlot.start.milis <= bookedSlot.dateStart && timeSlot.end.milis >= bookedSlot.dateEnd;

      return (
        isTimeSlotStartTimeWithinBookedStartEndTimes ||
        isTimeSlotEndTimeWithinBookedStartEndtimes ||
        isBookedTimesWithinTimeSlotStartEndTimes
      );
    });
  };

  const isTimeSlotPurchased = (timeSlot: TimeSlot): boolean => {
    return booking.data.busyTimes.some((busyTime: CompactBusyTime) => {
      const timeSlotStart = DateTime.fromMillis(timeSlot.start.milis);
      const busyTimeStart = DateTime.fromMillis(busyTime.dateStart);

      return timeSlotStart.hasSame(busyTimeStart, 'minute') && busyTime.isPurchased;
    });
  };

  const isTimeSlotBusy = (props: IsTimeSlotCheckProps): boolean => {
    const { timeSlot, serviceId } = props;
    const serviceDetails = getServiceDetails(serviceId);
    const currentTimeSlotInUnixMilliseconds = timeSlot.start.milis;

    if (currentTimeSlotInUnixMilliseconds < getUnixMilliseconds()) {
      return true;
    }

    for (let l = 0; l < booking.data.busyTimes.length; l += 1) {
      const busyTime = booking.data.busyTimes[l];

      // Convert busy times and the time slot to DateTime objects
      const busyTimeStart = DateTime.fromMillis(busyTime.dateStart);
      const busyTimeEnd = DateTime.fromMillis(busyTime.dateEnd);
      const timeSlotDateTime = DateTime.fromMillis(timeSlot.start.milis);

      // Check if the time slot and the busy time are on the same day
      const isSameDay = timeSlotDateTime.hasSame(busyTimeStart, 'day');
      if (isSameDay) {
        const serviceDurationMillis = (serviceDetails?.durationInMinutes ?? 0) * 60000;
        const newBusyTimeStart = busyTime.dateStart - serviceDurationMillis;
        const timeSlotMillis = timeSlot.start.milis;

        // If the busy time is not fully booked and not purchased
        if (busyTime.serviceId === serviceId && !busyTime.isFullyBooked && !busyTime.isPurchased) {
          if (busyTimeStart.hasSame(timeSlotDateTime, 'minute')) {
            return false;
          }

          // Determine if the time slot is before or after the busy time start
          const isBeforeBusyStart = newBusyTimeStart < timeSlotMillis;
          const isAfterBusyStart = timeSlotMillis > busyTime.dateStart;
          const isDuringBusy =
            (isBeforeBusyStart || isAfterBusyStart) && timeSlotMillis < busyTime.dateEnd;

          // If the time slot is during the busy time, it cannot be booked
          if (isDuringBusy) {
            return true;
          }
        }
        // If the busy time is fully booked
        const isBusyPeriod =
          newBusyTimeStart < timeSlotMillis &&
          busyTimeEnd.minus({ minutes: 1 }).toMillis() > timeSlotMillis;

        // If the time slot is within the busy period, it cannot be booked
        if (isBusyPeriod) {
          return true;
        }
      }
    }

    return false;
  };

  /**
   * Returns all available working hour time slots by removing passed + booked time slots.
   * If busyTimes array is not provided, it will use the one from the redux store (booking.data.busyTimes)
   * */
  const getAvailableWorkingHourTimeSlots = (
    params: GetAvailableWorkingHourTimeSlotsInput,
  ): TimeSlot[] => {
    const nowInMilis = now.toMillis();
    const { selectedDate, durationInMinutes, startTime, endTime, serviceDurationInMinutes } =
      params;

    const workingHourTimeSlots: TimeSlot[] = getWorkingHourTimeSlots({
      selectedDate,
      durationInMinutes,
      startTime,
      endTime,
      serviceDurationInMinutes,
    }).filter((timeSlot) => timeSlot.start.milis > nowInMilis);

    /** Use busyTimes from redux if it is not provided in the call parameters */
    return workingHourTimeSlots;
  };

  /** Returns all available working hour time slots in the morning or afternoon only */
  const getMorningAfternoonAvailableTimeSlots = (
    params: GetMorningAfternoonAvailableTimeSlotsInput,
  ) => {
    const {
      selectedDate,
      durationInMinutes,
      startTime,
      endTime,
      busyTimes,
      category,
      timezone,
      serviceDurationInMinutes,
    } = params;
    const availableTimeSlots = getAvailableWorkingHourTimeSlots({
      selectedDate,
      durationInMinutes,
      startTime,
      endTime,
      busyTimes,
      serviceDurationInMinutes,
    });

    if (category === 'morning') {
      return availableTimeSlots.filter(
        (timeSlot) => getZonedDate(timeSlot.end.milis, timezone ?? '').toFormat('a') === 'AM',
      );
    }

    return availableTimeSlots.filter(
      (timeSlot) => getZonedDate(timeSlot.end.milis, timezone ?? '').toFormat('a') === 'PM',
    );
  };

  /** Returns service end time nicely formatted */
  const getServiceEndTime = ({
    startTimeInMilis,
    durationInMinutes,
    timezone,
  }: GetServiceEndTimeInput): SlotStartEndDetail => {
    const endTime = getZonedDate(startTimeInMilis + durationInMinutes * 60 * 1000, timezone);
    return {
      milis: endTime.toMillis(),
      dateTimeShort: endTime.toFormat(dateTimeShortFormat),
      timeShort: endTime.toFormat(timeShortFormat),
    };
  };

  /**
   * Returns return Timeslot item with timeInMilis
   * */
  const getStartEndDetailFromMilis = (timeInMilis: number) => {
    const curTime = DateTime.fromMillis(timeInMilis);
    return {
      milis: curTime.toMillis(),
      dateTimeShort: curTime.toFormat(dateTimeShortFormat),
      timeShort: curTime.toFormat(timeShortFormat),
    };
  };

  /**
   * Returns return Timeslot item with timeInMilis and timezone
   * It will convert the DateTime with specific timezone.
   * */
  const getStartEndDetailFromMilisWithTimeZone = (timeInMilis: number, timezone: string) => {
    const curTime = DateTime.fromMillis(timeInMilis).setZone(timezone);
    return {
      milis: curTime.toMillis(),
      dateTimeShort: curTime.toFormat(dateTimeShortFormat),
      timeShort: curTime.toFormat(timeShortFormat),
    };
  };

  const isTimeSlotInSelectedDate = ({
    timeSlotStartTimeMilis,
    selectedDate,
  }: IsTimeSlotInSelectedDateInput): boolean => {
    const timeslot = DateTime.fromMillis(timeSlotStartTimeMilis);
    return (
      timeslot.day === selectedDate.day &&
      timeslot.month === selectedDate.month &&
      timeslot.year === selectedDate.year
    );
  };

  const isContainingScheduledTimeSlots = ({
    timezone,
    selectedDate,
    category,
    serviceId,
  }: IsContainingScheduledTimeSlotsInput): boolean => {
    let isTimeSlotHere = false;
    const serviceDetails = getServiceDetails(serviceId);
    serviceDetails?.scheduledDates.forEach((scheduledTimeSlot) => {
      if (
        isTimeSlotInSelectedDate({
          timeSlotStartTimeMilis: scheduledTimeSlot.dateStart,
          selectedDate,
        })
      ) {
        const timeSlotDaySection = getZonedDate(
          scheduledTimeSlot.dateStart,
          timezone ?? '',
        ).toFormat('a');

        const isMorning = timeSlotDaySection === 'AM';
        const isAfternoon = timeSlotDaySection === 'PM';

        if (isMorning && category === 'morning') isTimeSlotHere = true;
        if (isAfternoon && category === 'afternoon') isTimeSlotHere = true;
      }

      return isTimeSlotHere;
    });
    return isTimeSlotHere;
  };

  /**
   * Returns available time slots in the morning or afternoon by using data stored in Redux store
   * */
  const getReduxConnectedAvailableCategoryTimeSlots = ({
    timezone,
    selectedDate,
    category,
  }: GetReduxConnectedAvailableCategoryTimeSlotsInput): TimeSlot[] => {
    const dayOfWeek = getAvgarDayOfWeek(DateTime.fromObject(selectedDate).weekday);
    const durationInMinutes = application.active.orgBookingRules?.timeslotPeriodInMinutes;
    const service = getActiveSelectedService();

    if (service && durationInMinutes) {
      const serviceId = service.id;

      /** Should display coach work hours or hours from the scheduled dates? */
      let shouldUseCoachWorkHours = true;

      if (isAdvancedLesson(service)) shouldUseCoachWorkHours = false;
      if (isProgramme(service) || isEvent(service)) {
        if (!service.isPackage) shouldUseCoachWorkHours = false;
      }

      if (!shouldUseCoachWorkHours) {
        const hasTimeSlot = isContainingScheduledTimeSlots({
          timezone,
          selectedDate,
          category,
          serviceId: service.id,
        });

        if (hasTimeSlot) {
          const timeSlots = service.scheduledDates
            .filter((serviceTimeSlot) =>
              isTimeSlotInSelectedDate({
                timeSlotStartTimeMilis: serviceTimeSlot.dateStart,
                selectedDate,
              }),
            )
            .map((serviceTimeSlot) => ({
              start: getStartEndDetailFromMilis(serviceTimeSlot.dateStart),
              end: getStartEndDetailFromMilis(serviceTimeSlot.dateEnd),
            }));

          return timeSlots;
        }

        return [];
      }

      const coachId = getChosenCoachFromService(serviceId);
      if (coachId) {
        const workHours = getCoachWorkHours({ serviceId, coachId, dayOfWeek });
        if (workHours) {
          const tmpAllAvailableTimes: TimeSlot[] = [];
          if (workHours.isClosedDay) return [];
          if (workHours.timePeriods && Array.isArray(workHours.timePeriods)) {
            workHours.timePeriods.forEach(({ startTime, endTime }) => {
              const availableTimes = getMorningAfternoonAvailableTimeSlots({
                selectedDate,
                durationInMinutes,
                startTime,
                endTime,
                category,
                timezone,
                serviceDurationInMinutes: service.durationInMinutes,
              });

              availableTimes.forEach((time) => tmpAllAvailableTimes.push(time));
            });
          }

          return tmpAllAvailableTimes;
        }
      }
    }
    return [];
  };

  /**
   * Returns available time (Not busy, Not purchased) slots in the morning or afternoon by using data stored in Redux store
   * */
  const getReduxAvalableTimeSlots = ({
    timezone,
    selectedDate,
    category,
  }: GetReduxConnectedAvailableCategoryTimeSlotsInput): TimeSlot[] => {
    const timeSlots = getReduxConnectedAvailableCategoryTimeSlots({
      timezone,
      selectedDate,
      category,
    });

    const service = getActiveSelectedService();

    return timeSlots.filter((timeSlot: TimeSlot) => {
      const isBusy = isTimeSlotBusy({ timeSlot, serviceId: service?.id ?? '' });
      const isPurchased = isTimeSlotPurchased(timeSlot);

      return !isBusy && !isPurchased;
    });
  };

  const getAlreadySelectedTimeSlots = (): BusyTime[] =>
    booking.selected.services.map((service) => [...service.scheduledDates]).flat();

  /** Main handler function */
  const handleFetchBusyTimesForSelectedLesson = async (serviceId: string) => {
    try {
      const dateStart = now.startOf('month').toMillis();
      const dateEnd = now.plus({ month: 1 }).endOf('month').toMillis();
      const currentService = booking.data.services.find((service) => service.id === serviceId);
      if (!currentService) return;

      /** IMPORTANT TODO: need to integrate choose coach if lesson has multiple coaches */
      const chosenCoachId = getChosenCoachFromService(serviceId);

      setIsLoading(true);
      const response = await Api.ClientRoutes.Org.getServiceBusyTimesBetweenDates(
        orgId,
        currentService.id,
        chosenCoachId as string,
        currentService.assistanceStaff.map((staff) => staff.id),
        dateStart,
        dateEnd,
        currentService.maxParticipants,
        userId,
      );

      const busyTimes = response.data;
      const allUniqueBusyTimes = getUniqueBusyTimes(busyTimes);
      dispatch(bookingSlice.actions.getBusyTimes(allUniqueBusyTimes));
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getAvailableMonths = (detailedServices: SelectedService[], timezone: string) => {
    const updatedDetailedServices = detailedServices.map((serviceItem: SelectedService) => {
      const updatedService = {
        ...serviceItem,
      };

      const availableMonths: AvailableMonth[] = [];
      if (serviceItem.scheduledDates.length > 0) {
        for (let i = 0; i < serviceItem.scheduledDates.length; i++) {
          const dateStart = DateTime.fromMillis(serviceItem.scheduledDates[i].dateStart);
          const month = dateStart.set({
            day: 0,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });

          const indexMonthValue = availableMonths.findIndex(
            (item: AvailableMonth) => item.month.toMillis() === month.toMillis(),
          );

          const { day } = dateStart;
          const availableMorningTimeSlots = getReduxConnectedAvailableCategoryTimeSlots({
            timezone,
            selectedDate: {
              day: dateStart.day,
              month: dateStart.month,
              year: dateStart.year,
            },
            category: 'morning',
          });

          const availableMorningDate: AvailableDate[] = availableMorningTimeSlots.map(
            (timeSlot: TimeSlot) => {
              return {
                date: DateTime.fromMillis(timeSlot.start.milis),
                isBusyTime: isTimeSlotBusy({ timeSlot, serviceId: updatedService.id }),
                isUserSelected: false,
                isPurchasedTime: isTimeSlotPurchased(timeSlot),
              };
            },
          );

          const availableAfternoonTimeSlots = getReduxConnectedAvailableCategoryTimeSlots({
            timezone,
            selectedDate: {
              day: dateStart.day,
              month: dateStart.month,
              year: dateStart.year,
            },
            category: 'afternoon',
          });

          const availableAfternoonDate: AvailableDate[] = availableAfternoonTimeSlots.map(
            (timeSlot: TimeSlot) => {
              return {
                date: DateTime.fromMillis(timeSlot.start.milis),
                isBusyTime: isTimeSlotBusy({ timeSlot, serviceId: updatedService.id }),
                isUserSelected: false,
                isPurchasedTime: isTimeSlotPurchased(timeSlot),
              };
            },
          );

          if (indexMonthValue < 0) {
            const newAvailableDays: AvailableDay[] = [];
            newAvailableDays.push({
              day,
              afternoonTimes: availableAfternoonDate,
              morningTimes: availableMorningDate,
              date: dateStart,
              isDayFullyBooked:
                availableMorningDate.length === 0 && availableAfternoonDate.length === 0,
            });
            availableMonths.push({
              month,
              availableDays: newAvailableDays,
            });
          } else {
            availableMonths[indexMonthValue].availableDays.push({
              day,
              afternoonTimes: availableAfternoonDate,
              morningTimes: availableMorningDate,
              date: dateStart,
              isDayFullyBooked:
                availableMorningDate.length === 0 && availableAfternoonDate.length === 0,
            });
          }
        }
      }

      updatedService.availableMonths = availableMonths;
      return updatedService;
    });

    return updatedDetailedServices;
  };

  return {
    isLoading,
    isTimeSlotBooked,
    getFriendlyCompactBusyTimes,
    getReduxConnectedAvailableCategoryTimeSlots,
    getReduxAvalableTimeSlots,
    getServiceEndTime,
    getStartEndDetailFromMilis,
    getAlreadySelectedTimeSlots,
    handleFetchBusyTimesForSelectedLesson,
    getStartEndDetailFromMilisWithTimeZone,
    isTimeSlotPurchased,
    isTimeSlotBusy,
    getAvailableMonths,
  };
};
