/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
} from '@mui/material';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import { Address } from 'data/entities/userContacts.entity';
import UserService from 'data/services/user.service';
import { User } from 'data/entities/users.entity';
import { AvatarHelper } from 'common/utils/avatar.helpers';
import SessionProvider from 'providers/SessionProvider';
import { AssociateWithAvatar } from 'data/entities/associate.entity';
import { OrgBookingRules } from 'modules/book/models/booking.model';
import styles from './LessonHeader.module.scss';
import { Lesson, LessonClient, LessonClientStatus } from '../../../../data/entities/lessons.entity';
import LessonHelper from '../../../../common/utils/lessons.helper';
import LessonLocation from '../LessonLocation/LessonLocation';
import LessonDateBegin from '../LessonDateBegin/LessonDateBegin';

interface LessonHeaderProps {
  lesson: Lesson;
  categories: LessonCategory[];
  onOpenEditModal: Function;
  location: Address;
  locationName: string;
  cancelLesson: (lessonId: string, reason?: string) => void;
  onCancelPackage: (packageId: string, reason?: string) => void;
  orgBookingRules: OrgBookingRules;
}

const LessonHeader = ({
  lesson,
  categories,
  onOpenEditModal,
  location,
  locationName,
  cancelLesson,
  onCancelPackage,
  orgBookingRules,
}: LessonHeaderProps) => {
  const [coaches, setCoaches] = useState<User[]>([]);
  const [staffs, setStaffs] = useState<User[]>([]);
  const [clients, setClients] = useState<User[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [associates, setAssociates] = useState<AssociateWithAvatar[]>([]);

  const handleAllParticipants = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAllParticipantsClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const max_avatars = 9;

  useEffect(() => {
    const fetchData = async () => {
      const userId: string = SessionProvider.getUserId();
      const orgId: string = SessionProvider.getOrgId();

      const associatesAsync = await UserService.getAssociatesByUserIdOrgIdAsync(userId, orgId);
      setAssociates(associatesAsync ?? []);

      const getCoachNames = await UserService.getNamesByIds(lesson.coaches);
      setCoaches(getCoachNames ?? []);

      const getStaffsNames = await UserService.getNamesByIds(lesson.assistance_staff ?? []);
      setStaffs(getStaffsNames ?? []);

      const getClientsNames = await UserService.getNamesByIds(
        lesson.clients
          .filter((clientItem: LessonClient) => clientItem.status === LessonClientStatus.Accepted)
          .map((item) => item?.user_id),
      );

      setClients(getClientsNames ?? []);
    };

    fetchData();
  }, [lesson]);

  let associateAvatars = {};

  if (lesson.is_self_taught)
    associateAvatars = associates.map((associate: AssociateWithAvatar, index: number) => {
      if (index < max_avatars)
        if (associate.avatar.length > 0) {
          return (
            <Tooltip title={`${associate.user.first_name} ${associate.user.last_name}`}>
              <Avatar
                alt={`${associate.user.first_name} ${associate.user.last_name}`}
                src={associate.avatar[0].file_url ?? ''}
                sx={{ ml: -1, border: '2px white solid' }}
              />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={`${associate.user.first_name} ${associate.user.last_name}`}>
              <Avatar sx={{ ml: -1, border: '2px white solid' }}>
                {AvatarHelper.stringToAvatar({
                  firstName: associate.user.first_name,
                  lastName: associate.user.last_name,
                })}
              </Avatar>
            </Tooltip>
          );
        }
      if (index === max_avatars) {
        return (
          <Avatar
            onClick={handleAllParticipants}
            sx={{ ml: -1, border: '2px white solid', cursor: 'pointer' }}
          >
            {AvatarHelper.stringToAvatar({
              firstName: '+',
              lastName: `${clients.length - index}`,
            })}
          </Avatar>
        );
      }
    });
  else
    associateAvatars = clients.map((client: User, index: number) => {
      if (index < max_avatars)
        if (client.detail?.avatar_file && client.detail?.avatar_file.length > 0) {
          if (client.detail?.avatar_file[0].file_url)
            return (
              <Tooltip title={`${client.first_name} ${client.last_name}`}>
                <Avatar
                  alt={`${client.first_name} ${client.last_name}`}
                  src={client.detail?.avatar_file[0].file_url ?? ''}
                  sx={{ ml: -1, border: '2px white solid' }}
                />
              </Tooltip>
            );
        } else {
          return (
            <Tooltip title={`${client.first_name} ${client.last_name}`}>
              <Avatar sx={{ ml: -1, border: '2px white solid' }}>
                {AvatarHelper.stringToAvatar({
                  firstName: client.first_name,
                  lastName: client.last_name,
                })}
              </Avatar>
            </Tooltip>
          );
        }
      else if (index === max_avatars) {
        return (
          <Avatar
            onClick={handleAllParticipants}
            sx={{ ml: -1, border: '2px white solid', cursor: 'pointer' }}
          >
            {AvatarHelper.stringToAvatar({
              firstName: '+',
              lastName: `${clients.length - index}`,
            })}
          </Avatar>
        );
      }
    });

  return (
    <div className={styles.wrapper}>
      <div className={styles.fullWidth}>
        <div className={styles.header}>
          <div className={styles.title}>
            {lesson.title}{' '}
            {!lesson.is_self_taught &&
              coaches.map((item: User) => (
                <span className={styles.shareCoach}>
                  {item.first_name} {item.last_name}
                </span>
              ))}
            {!lesson.is_self_taught &&
              staffs.map((item: User) => (
                <span className={styles.shareCoach}>
                  {item.first_name} {item.last_name}
                </span>
              ))}
            {lesson.share_practice_lesson_with_coach && lesson.is_self_taught && (
              <span className={styles.shareCoach}>Shared with {lesson.organization?.org_name}</span>
            )}
          </div>
          {LessonHelper.isLessonEditable(lesson) && (
            <div className={styles.editButton} onClick={() => onOpenEditModal()}>
              Edit
            </div>
          )}
        </div>

        <div className={styles.visibleMobile}>
          {lesson?.date_start && (
            <LessonDateBegin
              lesson={lesson}
              cancelLesson={cancelLesson}
              onCancelPackage={onCancelPackage}
              orgBookingRules={orgBookingRules}
            />
          )}
          <LessonLocation location={location} locationName={locationName} lesson={lesson} />
        </div>

        <div className={styles.categories}>
          {categories?.map(({ _id, name }) => (
            <span key={_id} className={styles.category}>
              {name}
            </span>
          ))}

          <div className={styles.avatarWrapper}>{associateAvatars}</div>

          {!lesson.is_self_taught && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleAllParticipantsClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <List dense>
                {clients.map((user: User) => {
                  return (
                    <ListItem>
                      <ListItemIcon>
                        {user.detail?.avatar_file && user.detail?.avatar_file.length > 0 ? (
                          <Avatar
                            alt={`${user.first_name} ${user.last_name}`}
                            src={user.detail?.avatar_file[0].file_url ?? ''}
                            sx={{
                              width: '32px',
                              height: '32px',
                            }}
                          />
                        ) : (
                          <Avatar sx={{ width: '32px', height: '32px' }}>
                            {AvatarHelper.stringToAvatar({
                              firstName: user.first_name,
                              lastName: user.last_name,
                            })}
                          </Avatar>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={`${user.first_name} ${user.last_name}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          )}

          {lesson.is_self_taught && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleAllParticipantsClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <List dense>
                {associates.map((user: AssociateWithAvatar) => {
                  return (
                    <ListItem>
                      <ListItemIcon>
                        {user.avatar.length > 0 ? (
                          <Avatar
                            alt={`${user.user.first_name} ${user.user.last_name}`}
                            src={user.avatar[0].file_url ?? ''}
                            sx={{
                              width: '32px',
                              height: '32px',
                            }}
                          />
                        ) : (
                          <Avatar sx={{ width: '32px', height: '32px' }}>
                            {AvatarHelper.stringToAvatar({
                              firstName: user.user.first_name,
                              lastName: user.user.last_name,
                            })}
                          </Avatar>
                        )}
                      </ListItemIcon>
                      <ListItemText primary={`${user.user.first_name} ${user.user.last_name}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default LessonHeader;
