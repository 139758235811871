import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { bookingSlice, BookingStep, authenticationSlice } from 'redux/slices';
import type { RootState } from 'redux/store';

import { Api } from 'api/Api';
import { Grid } from 'components/Grid';
import { useOrgId, useUserId, useAxiosFetch, useAuth, useMobileViewDetector } from 'common/hooks';

import { CheckoutSummarySelectedService } from 'api/models/payments/calculateCheckoutSummary.model';
import { AxiosErrorHandler } from 'common/utils/errorHandler.helpers';

import { PaymentOption } from './components/PaymentOption';
import { useService, useSummary, useTimeSlots } from './hooks';
import { BookingContainer, PromoCodeForm, BookingSummaryInfo } from './components';

export const BookingSummary = () => {
  const { orgId } = useOrgId();
  const { userId } = useUserId();
  const { isAuthorized, authorize } = useAuth();
  const {
    validateChosenDatesForUser,
    getCheckoutCalculationInput,
    isPaymentRequired,
    isPaymentOptionsDisplayed,
    isValidatingService,
    isPrepaidAndUpfront,
    isPrepaidAndPaid,
    isAllServiceNotRequiresPayment,
    isAllServiceRequiresPayment,
    isRefreshingPositionsLeft,
    refreshPositionsLeft,
  } = useSummary();
  const { isTimeslotSelectionFlowNeeded, getUpdatedServicesWithAdditionalData } = useService();

  const [isCheckoutTotalNotZero, setIsCheckoutTotalNotZero] = useState(true);

  const isShowPaymentOption = isAuthorized && isPaymentOptionsDisplayed() && isCheckoutTotalNotZero;

  const { isViewedFromMobile } = useMobileViewDetector();

  const { getAvailableMonths } = useTimeSlots();

  const [isLoadingCheckoutSummary, setIsLoadingCheckoutSummary] = useState<boolean>(false);

  const booking = useSelector((state: RootState) => state.booking);
  const authentication = useSelector((state: RootState) => state.authentication);
  const dispatch = useDispatch();

  const { paymentPreference, promoCode, timezone } = booking.selected;

  const getCheckoutSummary = async () => {
    try {
      const calcInput = getCheckoutCalculationInput(promoCode ?? '');
      const checkoutSummary = (await Api.ClientRoutes.Payments.calculateCheckout(calcInput)).data;

      if (paymentPreference === 'in_app') {
        dispatch(bookingSlice.actions.getCheckoutSummary(checkoutSummary));
      }

      if (paymentPreference === 'outside_app') {
        let subTotal = 0;
        calcInput.selectedServices.forEach((service: CheckoutSummarySelectedService) => {
          subTotal += (service.count ?? 0) * service.priceInCents;
        });

        dispatch(
          bookingSlice.actions.getCheckoutSummary({
            ...checkoutSummary,
            subTotalInCents: subTotal,
            totalInCents: subTotal,
            bookingFeeInCents: 0,
            totalTaxInCents: 0,
          }),
        );
      }
      if (checkoutSummary.totalInCents === 0) setIsCheckoutTotalNotZero(false);
      setIsLoadingCheckoutSummary(false);
    } catch (error: any) {
      console.log(error);
      setIsLoadingCheckoutSummary(false);
    }
  };

  const handlePromoCodeApply = async (newPromoCode: string) => {
    try {
      const response = await Api.ClientRoutes.Payments.calculateCheckout(
        getCheckoutCalculationInput(newPromoCode),
      );

      dispatch(bookingSlice.actions.updatePromoCode(newPromoCode));
      dispatch(bookingSlice.actions.getCheckoutSummary(response.data));
    } catch (e) {
      console.error('failed to refetch calculation due to', e);
    }
  };

  const checkPaymentOptions = () => {
    if (!booking.selected.lessonInviteId) {
      if (isAllServiceNotRequiresPayment)
        dispatch(bookingSlice.actions.updatePaymentPreference('outside_app'));
      if (isAllServiceRequiresPayment)
        dispatch(bookingSlice.actions.updatePaymentPreference('in_app'));
    }
  };

  const { isLoading: isLoadingLocations } = useAxiosFetch({
    fetchFn: () => Api.ClientRoutes.Org.getLocationsImageAndText({ orgId }),
    dispatchFn: bookingSlice.actions.getLocations,
  });

  const { isLoading: isLoadingCoaches } = useAxiosFetch({
    fetchFn: () => Api.ClientRoutes.Org.getCoachesImageAndText({ orgId }),
    dispatchFn: bookingSlice.actions.getCoaches,
  });

  useEffect(() => {
    authorize();
    checkPaymentOptions();
    refreshPositionsLeft();
  }, []);

  useEffect(() => {
    setIsLoadingCheckoutSummary(true);
    getCheckoutSummary();
  }, [paymentPreference, promoCode]);

  const isLoading =
    isLoadingCheckoutSummary ||
    isLoadingCoaches ||
    isLoadingLocations ||
    isValidatingService ||
    isRefreshingPositionsLeft;

  const noPaymentRequired = paymentPreference === 'outside_app' || !isPaymentRequired();

  const handleBackBtnClick = () => {
    const nextStep: BookingStep = !isTimeslotSelectionFlowNeeded()
      ? BookingStep.SelectService
      : BookingStep.SelectTime;
    dispatch(bookingSlice.actions.changeStep(nextStep));
  };

  const handleContinueBtnClick = async () => {
    try {
      const { updatedDetailedServices } = getUpdatedServicesWithAdditionalData();
      if (!isPrepaidAndUpfront) await validateChosenDatesForUser(isAuthorized);

      const updatedServicesWithAvailableMonths = getAvailableMonths(
        updatedDetailedServices,
        timezone,
      );

      dispatch(bookingSlice.actions.getServices(updatedServicesWithAvailableMonths));

      if (!authentication.authenticatedUser) {
        dispatch(authenticationSlice.actions.changeSignInOpen(true));
      } else {
        dispatch(
          bookingSlice.actions.changeStep(
            !noPaymentRequired ? BookingStep.Pay : BookingStep.ProcessBooking,
          ),
        );
      }
    } catch (err: any) {
      const compiledErrorMessage = AxiosErrorHandler.getErrorMessage({
        response: err.response,
        request: err.request,
        message: err.message,
      });
      toast.error(compiledErrorMessage);
    }
  };

  return (
    <BookingContainer
      isLoading={isLoading}
      title="Summary"
      backButton={{
        onClick: handleBackBtnClick,
      }}
      continueButton={{
        onClick: handleContinueBtnClick,
        label: !noPaymentRequired ? 'Continue' : 'Confirm',
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <BookingSummaryInfo>
            {paymentPreference === 'in_app' && !isPrepaidAndPaid && (
              <PromoCodeForm
                userId={userId}
                orgId={orgId}
                onApply={handlePromoCodeApply}
                promoCodeValue={promoCode ?? ''}
              />
            )}
            <PaymentOption isShowPaymentOtions={isShowPaymentOption && isViewedFromMobile} />
          </BookingSummaryInfo>
        </Grid>
        <PaymentOption isShowPaymentOtions={isShowPaymentOption && !isViewedFromMobile} />
      </Grid>
    </BookingContainer>
  );
};
