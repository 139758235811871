export const LibraryTabs = {
  PracticeLessonUpcoming: 0,
  PracticeLessonPast: 1,
  PracticeLessonCanceled: 2,
  PracticeLessonAll: 3,
  PracticeLessonSelf: 4,
  PracticeLessonCoach: 5,
  Events: 6,
  PracticeLocation: 7,
  ExerciseIndividual: 8,
  ExerciseGroup: 9,
  FavoritesLessonIndividual: 10,
  FavoritesLessonGroup: 11,
  FavoritesPracticeSelf: 12,
  FavoritesPracticeShared: 13,
};
