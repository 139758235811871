import {
  Dialog as MUIDialog,
  DialogContent,
  Box,
  useMediaQuery,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import React, { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from 'components/Button/MuiButtons/Button';
import { BrandThemeContext } from 'common/context';

interface IMUIDialogProps {
  title?: string;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  alternativeBtnTitle?: string;
  alternativeBtnAction?: () => void;
  submitBtnTitle?: string;
  submitAction?: (data: any) => void;
  isLoading?: boolean;
  submitButtonDisabled?: boolean;
}
export const Dialog: React.FC<IMUIDialogProps> = ({
  title,
  children,
  open,
  onClose,
  alternativeBtnTitle,
  alternativeBtnAction,
  submitBtnTitle,
  submitAction,
  isLoading = false,
  submitButtonDisabled = false,
}) => {
  const matches = useMediaQuery('(max-width:450px)');
  const theme = useContext(BrandThemeContext);

  return (
    <MUIDialog open={open} onClose={onClose} fullWidth fullScreen={matches}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'right',
          paddingRight: '12px',
        }}
      >
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          overflowX: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            minHeight: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              fontSize: 24,
              fontWeight: 400,
            }}
          >
            {title}
          </Box>
          <Box>{children}</Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              marginTop: 4,
            }}
          >
            <Stack direction="row" spacing={1}>
              {alternativeBtnTitle && alternativeBtnAction && (
                <Button
                  label={alternativeBtnTitle}
                  type="submit"
                  onClick={alternativeBtnAction}
                  sx={{
                    bgcolor: `${theme.colors.black.opacity_25} !important`,
                    '&:hover': {
                      bgcolor: `${theme.colors.black.opacity_50} !important`,
                    },
                  }}
                />
              )}
              {!!submitBtnTitle && !!submitAction && (
                <Button
                  label={submitBtnTitle}
                  type="submit"
                  onClick={submitAction}
                  disabled={isLoading || submitButtonDisabled}
                />
              )}
            </Stack>
          </Box>
        </Box>
      </DialogContent>
    </MUIDialog>
  );
};
