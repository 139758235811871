import { useState, useEffect, useCallback } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import * as Realm from 'realm-web';
import { AxiosResponse } from 'axios';
import { Config } from 'config';
import { useAuth, useAxiosFetch, useUserId } from 'common/hooks';

import SessionProvider from 'providers/SessionProvider';
import isAuthenticated from 'common/utils/isAuthenticated';
import reAuthenticate from 'common/utils/reAuthenticated';
import { getUnixMilliseconds } from 'common/utils/date.helpers';

/** Data */
import UserService from 'data/services/user.service';
import { UserSubscriptionsModel } from 'data/models/userSubscription.mode';

Amplify.configure({
  aws_project_region: Config().AWSProjectRegion,
  aws_cognito_identity_pool_id: Config().AWSCognitoIdentityPoolId,
  aws_cognito_region: Config().AWSCognitoRegion,
  aws_user_pools_id: Config().AWSUserPoolsId,
  aws_user_pools_web_client_id: Config().AWSUserPoolsWebClientId,
  aws_user_files_s3_bucket: Config().AWSUserFilesS3Bucket,
  aws_user_files_s3_bucket_region: Config().AWSUserFilesS3BucketRegion,
});

type OpenIdType = {
  IdentityId: string;
  Token: string;
};
/** In VGRPR-2984, will need to integrate this version of the useUserSubscription hook instead of this one. */
export const useUserSubscription = () => {
  const { isAuthorized } = useAuth();
  const { userId } = useUserId();
  const [isFetchingUserSubscription, setIsFetchingUserSubscription] = useState<boolean>(true);
  const [subscriptionInfo, setSubscriptionInfo] = useState<UserSubscriptionsModel | undefined>(
    undefined,
  );
  const app = new Realm.App({ id: Config().RealmAppId as string });
  const user = app?.currentUser;
  const id = user?.customData?._id;
  const token = user?.accessToken;
  const tokenFromCookie = SessionProvider.getAccessToken();

  /** Fetch Open ID token */
  const getOpenIDToken: () => Promise<AxiosResponse<OpenIdType>> = async () => {
    await user?.refreshCustomData();
    const isUserAuthenticated = await isAuthenticated();
    const response = await fetch(`${Config().BaseCognitoOpenIdURL}/cognito/getOpenIDToken`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accessToken: token,
        userId: id,
      }),
    });
    const { data } = await response.json();
    if (!user?.customData?.cognito_id || !isUserAuthenticated) {
      try {
        if (!user?.customData?.cognito_id || user?.customData?.cognito_id !== data.IdentityId) {
          // Save the cognito_id to db if not exist already from
          // result.data.IdentityId
          await UserService.insertUserCognitoId(user?.customData?._id as string, data.IdentityId);
          await user?.refreshCustomData();
        }
        if (
          !isUserAuthenticated &&
          user &&
          user.customData &&
          user.customData.firstName &&
          user.customData.email
        ) {
          Auth.federatedSignIn(
            'developer',
            {
              token: data.Token,
              identity_id: data.IdentityId,
              expires_at: 1800000 + getUnixMilliseconds(),
            },
            {
              name: user.customData.firstName as string,
              email: user.customData.email as string,
            },
          );
        }
      } catch (e) {
        console.error('isUserAuthenticated error ', e);
      }
    } else {
      reAuthenticate({
        id: user?.customData._id as string,
        accessToken: user?.accessToken as string,
        firstName: user?.customData.firstName as string,
        email: user?.customData.email as string,
      });
    }
    return data;
  };

  /** Fetch current user subscription Info */
  const fetchUserSubscriptionAsync = useCallback(async () => {
    try {
      if (!user) {
        return;
      }
      setIsFetchingUserSubscription(true);
      const userObject = await UserService.getCurrentUserCustomData();

      if (!userObject) {
        return;
      }

      const userSubscriptionModel: UserSubscriptionsModel = {
        portal_plan: userObject.portal_plan,
        portal_subscription_status_enum: userObject.portal_subscription_status_enum,
      };
      setSubscriptionInfo(userSubscriptionModel);
      setIsFetchingUserSubscription(false);
    } catch (err: any) {
      console.error(err);
    }
  }, [user]);

  const { isLoading: isLoadingOpenIdToken } = useAxiosFetch({
    fetchFn: () => getOpenIDToken(),
    isAutoRun: !!(user && tokenFromCookie && isAuthorized),
  });

  useEffect(() => {
    if (userId.length) {
      fetchUserSubscriptionAsync();
    }
  }, [userId]);

  return {
    subscriptionInfo,
    isFetchingUserSubscription,
    isLoadingOpenIdToken,
    user,
    token,
  };
};
