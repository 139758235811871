import { Config } from '../../config';
import { UserContact } from '../entities/userContacts.entity';
import { BaseRepo } from './base.repo';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export class UserContactsRepository extends BaseRepo {
  // #region Private Properties
  private userContactsCollection = this.mongo
    ?.db(Config().RealmDbName as string)
    .collection('user_contacts');
  // #endregion

  // #region Public Methods
  async createUserContact(contact: UserContact) {
    return this.userContactsCollection?.insertOne(contact);
  }

  async updateUserContact(contactId: string, contact: UserContact) {
    return this.userContactsCollection?.updateOne(
      { _id: contactId },
      {
        $set: {
          address: contact.address,
          name: contact.name,
          profession: contact.profession,
          email: contact.email,
          country_code: contact.country_code,
          phone_number: contact.phone_number,
          note: contact.note,
          date_updated: getUnixMilliseconds(),
          deleted: contact.deleted,
        },
      },
    );
  }

  async getAllContactsByUserId(userId: string): Promise<UserContact[] | undefined> {
    return this.userContactsCollection?.find({ user_id: userId, deleted: false });
  }

  async deleteContactById(contactId: string) {
    return this.userContactsCollection?.deleteOne({ _id: contactId });
  }
  // #endregion
}
