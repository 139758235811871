/* eslint-disable no-continue */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-classes-per-file */
import SessionProvider from 'providers/SessionProvider';
import {
  fullNameBuilder,
  locationsRenderBuilder,
  locationStringBuilder,
} from '../../../common/utils/response.helper';
import { Contact } from '../../../data/entities/organization.entity';
import { Coach, Location } from '../../service/models/service.model';
import { Api } from '../../../api/Api';
import {
  AvailableDate,
  IconAndText,
  SelectedService,
  ServiceInvitedClient,
} from '../models/booking.model';
import { Config } from '../../../config';
import RealmRepositories from '../../../data/base/realm.repo';
import { CheckoutSummary } from '../../../api/models/payments/checkoutSummary.model';
import StaticDictionary from '../../../common/utils/staticDictionary.helper';
import ServicesService from '../../../data/services/services.service';
import { getZonedDate, getZonedDateFromIsoString } from '../../../common/utils/date.helpers';

export const getCombinedServices = (services: Record<string, any>) =>
  services.reduce((res: Record<string, any>[], cur: Record<string, any>) => {
    const current = res.find((x: any) => x.id === cur.id);

    if (current) {
      return res.map((x: any) => (x.id === cur.id ? { ...x, count: x.count + 1 } : x));
    }
    return [...res, { ...cur, count: 1 }];
  }, []);

export class BookingFormatter {
  public static formatDuration(durationInMinutes: number) {
    const formatted = `${durationInMinutes} mins`;
    return formatted;
  }

  public static formatPrice(price: number) {
    return `${price}`;
  }

  public static formatContact(contact: Contact) {
    const formatted = `${contact.country_code}${contact.phone_number}`;
    return formatted;
  }

  public static formatCoachFullName(firstName: string, lastName: string) {
    return `${firstName} ${lastName}`;
  }

  /**
   *
   * @param date unix milliseconds
   * @returns formattedDate: "EEE d MMM yyy hh:mm bb"
   */
  public static formatDate(date: number, timezone: string) {
    const zonedDate = getZonedDate(date, timezone);
    const formattedDate = zonedDate.toFormat('EEE d MMM yyyy hh:mm a');
    return formattedDate;
  }

  /**
   *
   * @param date unix milliseconds
   * @returns formattedTime: "hh:mm bb"
   */
  public static formatTime(date: number, timezone: string) {
    const zonedDate = getZonedDate(date, timezone);
    const formattedTime = zonedDate.toFormat('hh:mm a');
    return formattedTime;
  }

  /**
   *
   * @param dateStart unixMilliseconds
   * @param dateEnd unixMilliseconds
   * @returns formattedDate: "EEE d MMM yyy HH:mm - HH:mm"
   */
  public static formatDateStartAndEnd(dateStart: number, dateEnd: number, timezone: string) {
    const formattedDateStart = BookingFormatter.formatDate(dateStart, timezone);
    const formattedTimeEnd = BookingFormatter.formatTime(dateEnd, timezone);
    const formattedDate = `${formattedDateStart} - ${formattedTimeEnd}`;
    return formattedDate;
  }
}

export class BookingHelper {
  public static isServiceFixedStartTime(service: SelectedService) {
    return (
      service.isPackage === false &&
      service.isNeverEnding === false &&
      service.isFlexibleStartTime === false &&
      service.isPresetWorkHours === false
    );
  }

  public static isServiceFullyBooked(service: SelectedService, isLessonInvite?: boolean) {
    if (service.isNeverEnding || service.isPresetWorkHours || service.isUpfrontPayment) {
      return false;
    }

    if (service.isPackageType) {
      if (service.packageParticipants !== undefined) {
        return service.packageParticipants + 1 >= service.maxParticipants;
      }
    }

    if (service.isSingleDateAndTime && isLessonInvite) {
      let maxParticipantsReached = false;
      service.scheduledDates.forEach((x) => {
        if (x.participants! + 1 >= service.maxParticipants) {
          maxParticipantsReached = true;
          return true;
        }

        return false;
      });

      return maxParticipantsReached;
    }

    if (service.isSingleDateAndTime) {
      // Edge case, Service invite for single day.
      return service.chosenDates![0].participants! + 1 >= service.maxParticipants;
    }

    // We manually check the service's available days and available timeslots.
    // If we find available timeslots, we see if this matches the user's chosen timeslots.
    // We check if the positions left for that selected timeslot reaches service.maxParticipants.
    // We then count all instances to see if the month(s) has been fully booked.
    let monthsFullyBooked = 0;
    for (let i = 0; i < service.availableMonths!.length; i += 1) {
      const availableMonth = service.availableMonths![i];
      let daysFullyBooked = 0;
      for (let j = 0; j < availableMonth.availableDays.length; j += 1) {
        const availableDay = availableMonth.availableDays[j];
        if (availableDay.isDayFullyBooked) {
          daysFullyBooked += 1;
          continue;
        }

        const openTimeslots: AvailableDate[] = [];
        const availableMorningTimeSlots = availableDay.morningTimes.filter((morningTime) => {
          return morningTime.isBusyTime === false || morningTime.isUserSelected === true;
        });
        if (availableMorningTimeSlots) {
          openTimeslots.push(...availableMorningTimeSlots);
        }

        const availableAfternoonTimeSlots = availableDay.afternoonTimes.filter((afternoonTime) => {
          return afternoonTime.isBusyTime === false || afternoonTime.isUserSelected === true;
        });
        if (availableAfternoonTimeSlots) {
          openTimeslots.push(...availableAfternoonTimeSlots);
        }

        if (openTimeslots.length === 0) {
          // Current available day is fully booked.
          daysFullyBooked += 1;
          continue;
        }

        let fullyBookedTimeslots = 0;
        if (isLessonInvite && !service.isUpfrontPayment && service.isSingleDateAndTime) {
          for (let k = 0; k < openTimeslots.length; k += 1) {
            const openTimeSlot = openTimeslots[k];
            if (openTimeSlot.date.toMillis() === service.scheduledDates[0].dateStart) {
              const reachedMaxParticipantsForTimeSlot =
                service.packageParticipants! + 1 === service.maxParticipants;
              if (reachedMaxParticipantsForTimeSlot) {
                fullyBookedTimeslots += 1;
                // We break loop as a lesson invite is only for a single timeslot
                break;
              }
            }
          }
        } else {
          for (let k = 0; k < openTimeslots.length; k += 1) {
            const openTimeSlot = openTimeslots[k];
            for (let l = 0; l < service.chosenDates!.length; l += 1) {
              const chosenDate = service.chosenDates![l];
              if (openTimeSlot.date.toMillis() === chosenDate.dateStart) {
                const reachedMaxParticipantsForTimeSlot =
                  chosenDate.participants! + 1 === service.maxParticipants;
                if (reachedMaxParticipantsForTimeSlot) {
                  fullyBookedTimeslots += 1;
                  continue;
                }
              }
            }
          }
        }

        if (fullyBookedTimeslots === openTimeslots.length) {
          daysFullyBooked += 1;
        }
      }

      if (daysFullyBooked === availableMonth.availableDays.length) {
        monthsFullyBooked += 1;
      }
    }

    return monthsFullyBooked === service.availableMonths!.length;
  }

  /**
   *
   * @param selectedService
   * @param setSelectedCoachObject
   * @returns TRUE if no errors, FALSE if unable to get coach.
   */
  public static async GetSelectedCoachObject(
    selectedService: SelectedService,
    setSelectedCoachObject: (selectedCoach: IconAndText) => void,
  ) {
    const userId = SessionProvider.getUserId();
    const selectedCoachId = selectedService.invitedClients?.find(
      (clientItem: ServiceInvitedClient) => clientItem.userId === userId,
    )?.selectedCoachId;

    let coachObj = selectedService.coaches.find(
      (coachItem: Coach) => coachItem.id === selectedCoachId,
    );

    // If the selected coach id is undefined, it will select the first item of the coaches array.
    if (!selectedCoachId && selectedService.coaches.length === 1) {
      [coachObj] = selectedService.coaches;
    }

    if (!coachObj) {
      console.error('Could not find coach for service using id: ', selectedService.id);
      return false;
    }

    const selectedCoach: IconAndText = {
      id: coachObj.id,
      icon: coachObj.avatar,
      text: fullNameBuilder(coachObj),
    };
    setSelectedCoachObject(selectedCoach);

    return true;
  }

  /**
   *
   * @param selectedService
   * @returns Coach Object.
   */
  public static async GetSelectedCoach(selectedService: SelectedService) {
    const userId = SessionProvider.getUserId();
    const selectedCoachId = selectedService.invitedClients?.find(
      (clientItem: ServiceInvitedClient) => clientItem.userId === userId,
    )?.selectedCoachId;

    let coachObj = selectedService.coaches.find(
      (coachItem: Coach) => coachItem.id === selectedCoachId,
    );

    // If the selected coach id is undefined, it will select the first item of the coaches array.
    if (!selectedCoachId && selectedService.coaches.length === 1) {
      [coachObj] = selectedService.coaches;
    }

    if (!coachObj) {
      console.error('Could not find coach for service using id: ', selectedService.id);
      return false;
    }

    const selectedCoach: IconAndText = {
      id: coachObj.id,
      icon: coachObj.avatar,
      text: fullNameBuilder(coachObj),
    };

    return selectedCoach;
  }

  /**
   *
   * @param selectedService
   * @param orgId
   * @param setSelectedLocationObject
   * @returns TRUE if no errors. FALSE there was an error.
   */
  public static async GetSelectedLocationObject(
    selectedService: SelectedService,
    orgId: string,
    setSelectedLocationObject: (selectedLocation: IconAndText) => void,
  ) {
    const locationId = selectedService.locationIds[0];
    const response = await Api.ClientRoutes.Org.getLocationsByIdAsync(
      orgId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [locationId],
    );
    const locationsData: Location[] = response.data;
    if (locationsData.length <= 0) {
      console.error('Could not find locations using id: ', locationId);
      return false;
    }
    const location = locationsData[0];
    const locationFileObjs = await RealmRepositories.Attachments.getFilesByIds([location.icon]);
    const filePath = locationFileObjs?.filter((file) => file._id === location.icon);

    const selectedLocation: IconAndText = {
      id: location.id,
      icon:
        filePath && filePath?.length > 0 && filePath[0]?.file_name
          ? `${Config().AWSCloudFrontUrl}${filePath[0]?.file_name}`
          : '',
      text: locationStringBuilder(location),
      renderElement: locationsRenderBuilder(location),
    };

    setSelectedLocationObject(selectedLocation);
    return true;
  }

  /**
   *
   * @param selectedService
   * @param orgId
   * @returns Location Object.
   */
  public static async GetSelectedLocation(selectedService: SelectedService, orgId: string) {
    const locationId = selectedService.locationIds[0];
    const response = await Api.ClientRoutes.Org.getLocationsByIdAsync(
      orgId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      [locationId],
    );
    const locationsData: Location[] = response.data;
    if (locationsData.length <= 0) {
      console.error('Could not find locations using id: ', locationId);
      return false;
    }
    const location = locationsData[0];
    const locationFileObjs = await RealmRepositories.Attachments.getFilesByIds([location.icon]);
    const filePath = locationFileObjs?.filter((file) => file._id === location.icon);

    const selectedLocation: IconAndText = {
      id: location.id,
      icon:
        filePath && filePath?.length > 0 && filePath[0]?.file_name
          ? `${Config().AWSCloudFrontUrl}${filePath[0]?.file_name}`
          : '',
      text: locationStringBuilder(location),
      renderElement: locationsRenderBuilder(location),
    };

    return selectedLocation;
  }

  public static async GetLocationsImageAndTextObject(
    locations: Location[],
    setLocationsObjects?: (locations: IconAndText[]) => void,
  ) {
    const locationFileIds = locations?.map((file) => file.icon);
    const locationFileObjs = await RealmRepositories.Attachments.getFilesByIds(locationFileIds);
    const imageAndTextArray: IconAndText[] = [];

    for (let i = 0; i < locations.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const filePath = locationFileObjs?.filter((file) => file._id === locations[i].icon);
      const imageAndText: IconAndText = {
        id: locations[i].id,
        text: locationStringBuilder(locations[i]),
        icon:
          filePath && filePath?.length > 0 && filePath[0]?.file_name
            ? `${Config().AWSCloudFrontUrl}${filePath[0]?.file_name}`
            : '',
        renderElement: locationsRenderBuilder(locations[i]),
      };

      imageAndTextArray.push(imageAndText);
    }
    if (setLocationsObjects !== undefined && setLocationsObjects !== null) {
      setLocationsObjects(imageAndTextArray);
    }
    return imageAndTextArray;
  }

  public static async GetCoachesImageAndTextObject(
    coaches: Coach[],
    setCoachesObject?: (coaches: IconAndText[]) => void,
  ) {
    const coachesImageAndTextArray: IconAndText[] = [];
    const coachFileIds = coaches?.map((file) => file.avatar) as string[];
    const coachFileObjs = await RealmRepositories.Attachments.getFilesByIds(coachFileIds);
    for (let i = 0; i < coaches.length; i += 1) {
      const filePath = coachFileObjs?.filter((file) => file._id === coaches[i].avatar);
      const imageAndText: IconAndText = {
        id: coaches[i].id,
        text: fullNameBuilder(coaches[i]),
        icon:
          filePath && filePath?.length > 0 && filePath[0]?.file_name
            ? `${Config().AWSCloudFrontUrl}${filePath[0]?.file_name}`
            : '',
      };
      coachesImageAndTextArray.push(imageAndText);
    }

    if (setCoachesObject !== undefined && setCoachesObject !== null) {
      setCoachesObject(coachesImageAndTextArray);
    }
    // if (coaches.length === 1) {
    //   setSelectedCoachObject(coachesImageAndTextArray[0]);
    // }

    return coachesImageAndTextArray;
  }

  public static async FilterLocationsBySelectedCoach(
    selectedCoachId: string,
    orgId: string,
    locations: IconAndText[],
  ) {
    const locationsResult: string[] =
      await ServicesService.getLocationsCoachIsOfferingServicesAsync(selectedCoachId, orgId);
    const newLocations = locations.filter((location: IconAndText) =>
      locationsResult.find((x: any) => x === location.id),
    );
    return newLocations;
  }

  public static async FilterCoachesBySelectedLocation(
    selectedLocationId: string,
    orgId: string,
    coaches: IconAndText[],
  ) {
    const coachesResult: string[] = await ServicesService.getCoachesByServiceLocationAsync(
      selectedLocationId,
      orgId,
    );
    const newCoaches = coaches.filter((coach: IconAndText) =>
      coachesResult.find((x) => x === coach.id),
    );
    return newCoaches;
  }

  public static isCreditApplied(checkoutSummary: CheckoutSummary) {
    return (
      checkoutSummary.creditInCents &&
      checkoutSummary.creditInCents !== undefined &&
      checkoutSummary.creditInCents > 0
    );
  }

  public static isFreeLesson(checkoutSummary: CheckoutSummary) {
    return checkoutSummary.totalInCents === 0;
  }

  static clearBookingUrlSearchParameters() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

    url.searchParams.delete(StaticDictionary.UrlParameters.Payments.ClientSecretUrlParam);
    url.searchParams.delete(StaticDictionary.UrlParameters.Payments.PaymentIntentUrlParam);
    url.searchParams.delete(StaticDictionary.UrlParameters.Payments.InvoiceNumberUrlParam);
    url.searchParams.delete(StaticDictionary.UrlParameters.Payments.CurrencyUrlParam);
    url.searchParams.delete('error');

    const newUrl = url.toString();
    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  public static convertAllDateIsoStringsToDateTimeObjects(
    services: SelectedService[],
    timeZone: string,
  ) {
    services.forEach((service) => {
      service.availableMonths?.forEach((month) => {
        if (typeof month.month === 'string') {
          const isoDateString = month.month as any;
          month.month = getZonedDateFromIsoString(isoDateString, timeZone);
        }

        month.availableDays.forEach((day) => {
          if (typeof day.date === 'string') {
            const dayIsoString = day.date as any;
            day.date = getZonedDateFromIsoString(dayIsoString, timeZone);
          }

          day.morningTimes.forEach((morningTime) => {
            if (typeof morningTime.date === 'string') {
              const morningTimeIsoString = morningTime as any;
              morningTime.date = getZonedDateFromIsoString(morningTimeIsoString, timeZone);
            }
          });

          day.afternoonTimes.forEach((afternoonTime) => {
            if (typeof afternoonTime.date === 'string') {
              const afternoonTimeIsoString = afternoonTime as any;
              afternoonTime.date = getZonedDateFromIsoString(afternoonTimeIsoString, timeZone);
            }
          });
        });
      });
    });

    return services;
  }
}
