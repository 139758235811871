import { Config } from '../../config';

function getResizedImageUri(originalUri: string, imageSize: string): string {
  const splitUri = originalUri?.split('/');
  const sliced = splitUri?.slice(3, splitUri.length).join('/');
  const newUri = `${Config().AWSResizeImageUrl}/fit-in/${imageSize}/${sliced}`;

  return newUri;
}

export default getResizedImageUri;
