import React, { useContext } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BrandThemeContext } from 'common/context';
import LocationIcon from '../../../../../../../assets/icons/FW_ Icons/Location.svg';
import ClockIcon from '../../../../../../../assets/icons/FW_ Icons/Clock.svg';

const defaultFontSize = '0.9rem';

interface LessonInfoWithIconProps {
  type: 'coach' | 'additionalCoach' | 'time' | 'place' | 'location' | 'clock' | 'etc';
  texts: string[];
  fontSize?: string;
}

const InfoIcon = ({ type }: Pick<LessonInfoWithIconProps, 'type'>) => {
  const defaultIconStyle = {
    fontSize: '1rem',
  };

  switch (type) {
    case 'coach':
      return <PersonOutlineOutlinedIcon sx={defaultIconStyle} />;
    case 'additionalCoach':
      return <PeopleAltOutlinedIcon sx={defaultIconStyle} />;
    case 'time':
      return <AccessTimeIcon sx={defaultIconStyle} />;
    case 'place':
      return <PlaceOutlinedIcon sx={defaultIconStyle} />;
    case 'location':
      return <img alt="location" src={LocationIcon} style={defaultIconStyle} width={19} />;
    case 'clock':
      return <img alt="location" src={ClockIcon} style={defaultIconStyle} width={18} />;
    default:
      return null;
  }
};

export const LessonInfoWithIcon = ({ type, texts, fontSize }: LessonInfoWithIconProps) => {
  const theme = useContext(BrandThemeContext);

  const defaultTextStyle = {
    fontSize: fontSize ?? defaultFontSize,
    color: theme.colors.black.opacity_100,
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
  };

  return (
    <Stack direction="row" spacing={1} marginBottom={0}>
      <Box display="flex">
        <InfoIcon type={type} />
      </Box>
      {texts.map((text, index) => {
        return (
          <Typography key={index} typography={defaultTextStyle}>
            {text}
          </Typography>
        );
      })}
    </Stack>
  );
};
