import { toast } from 'react-toastify';
import { IAxiosRequest } from 'common/models/axios.model';
import { StaticRoutes } from './routes/StaticRoutes.helper';
import { VerifyTokenResult } from '../../data/enums/VerifyTokenResult.enum';
import { VerifyTokenResultModel } from '../../providers/SessionProvider';

export class AxiosErrorHandler {
  /**
   * Call this method when you want to return the error message from the api.
   * @param err :IAxiosRequest
   * @returns Compiled error message
   */
  public static getErrorMessage = (err: IAxiosRequest): string => {
    let errorMessage;
    if (err.response) {
      if (err.response.data.Detail) {
        errorMessage = `${err.response.data.Detail}`;
      } else if (err.response.data.message) {
        errorMessage = `${err.response.data.message}`;
      } else if (!err.response.data.title) {
        // The client was given an error response (5xx, 4xx)
        // statusText = err.response.statusText
        errorMessage = `${err.response.data}`;
      } else {
        // The client was given an error response (5xx, 4xx)
        let additionalMessage = '';
        const errorKeys = Object.keys(err.response.data.errors);
        if (errorKeys.length > 0) {
          // Extract all error messages
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const errorMessages = errorKeys.map((key) => err.response!.data.errors[key][0]);
          additionalMessage = errorMessages.join(', ');
        }
        const message = additionalMessage ?? err.response.data;
        errorMessage = `${err.response.data.title} ${message}`;
      }
    } else if (err.request) {
      // The client never received a response, and the request was never left
      errorMessage = `${err.message}: endpoint could not be reached.`;
    } else {
      // Anything else
      errorMessage = `Error: ${err.message}`;
    }

    if (typeof errorMessage !== 'string') {
      return 'Something went wrong. Please try again later. If the problem persists, please contact us.';
    }

    return errorMessage;
  };
}

export function handleApiError(error: any) {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response);
    if (error.response.status === 401) {
      let errorMessage = '';
      if (error.response.data) {
        const verifyTokenResultModel: VerifyTokenResultModel = error.response.data;
        if (verifyTokenResultModel.verifyTokenResultEnum === VerifyTokenResult.Expired) {
          errorMessage = 'Your session has expired. Please sign in again.';
        } else if (verifyTokenResultModel.verifyTokenResultEnum === VerifyTokenResult.Invalid) {
          errorMessage = 'Your credentials are not valid. Please sign in again';
        }
      }
      const fromString = window.location.pathname + window.location.search;
      let route = `${StaticRoutes.Login}?from=${fromString}`;
      if (errorMessage) {
        route += `&error=${errorMessage}`;
      }

      window.location.href = route;
    }
    // console.error('response error');
    // console.error(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);
  } else if (error.request) {
    console.log('request error');
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // console.log(error.request);
    console.error(error.config);
    toast.error(`${error.toString()}. Could not reach ${error.config.baseURL}`);
    // toast.error('Could not reach TotalCoach service. Contact support@totalcoach.com');
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error);
    // toast.error('Internal server error. Contact support@totalcoach.com');
  }

  if (!import.meta.env.NODE_ENV || import.meta.env.NODE_ENV === 'development') {
    // console.log(error.config); // use for debugging
  }
}
