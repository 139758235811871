import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { debounce } from 'lodash';

import styles from '../AttachmentViewContent/AttachmentViewContent.module.scss';
import getResizedImageUri from '../../../../common/utils/getResizedImageUri.helpers';

interface IProps {
  title?: string;
  url: string;
  type: string;
  note: string | null;
  onChangeAttachmentTextNote?: (note: string) => void;
  play?: boolean | undefined;
  disabled?: boolean;
}

interface AttachmentItemImageProps {
  showFullImage: boolean;
  onClick: () => void;
  imageUri: string;
}

const AttachmentItemImage = ({ showFullImage, onClick, imageUri }: AttachmentItemImageProps) => {
  return showFullImage ? (
    <div className={styles.fullPageImage} onClick={onClick}>
      <img src={imageUri} alt="" />
    </div>
  ) : (
    <img src={getResizedImageUri(imageUri, '1028x1028')} alt="attachment img" onClick={onClick} />
  );
};

const AttachmentItem: React.FC<IProps> = ({
  title,
  url,
  type,
  note,
  onChangeAttachmentTextNote,
  play,
  disabled = false,
}) => {
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [controlPlayVideo, setControlPlayVideo] = useState<boolean | undefined>(false);
  const [showFullImage, setShowFullImage] = useState(false);
  useEffect(() => {
    if (play !== false) {
      setIsPlay(false);
    }
  }, [play]);

  const onChangeTextNoteAttachment =
    onChangeAttachmentTextNote &&
    debounce((e: { target: { value: string } }) => {
      onChangeAttachmentTextNote(e.target.value);
    }, 1000);

  return (
    <>
      {type?.startsWith('video') ? (
        <div className={styles.videoPlayerContainer}>
          <ReactPlayer
            url={url}
            width="100%"
            className={styles.videoPlayer}
            playing={play === false ? isPlay : controlPlayVideo}
            onPlay={() => {
              setControlPlayVideo(play);
            }}
            config={{
              file: {
                attributes: {
                  preload: 'metadata',
                },
              },
            }}
            controls
          />
          {title && <span className={styles.videoTitle}>{title}</span>}
        </div>
      ) : null}
      {type?.startsWith('image') ? (
        <AttachmentItemImage
          showFullImage={showFullImage}
          onClick={() => setShowFullImage(!showFullImage)}
          imageUri={url}
        />
      ) : null}
      {type === 'note' && onChangeAttachmentTextNote ? (
        <textarea
          disabled={disabled}
          className={styles.AttachmentText}
          defaultValue={note || ''}
          onChange={onChangeTextNoteAttachment}
        />
      ) : null}
      {type === 'note' && !onChangeAttachmentTextNote ? (
        <div className={styles.AttachmentText}>{note}</div>
      ) : null}
      {type?.includes('pdf') ? (
        <iframe
          title="PDF Viewer"
          src={`https://docs.google.com/gview?url=${encodeURIComponent(`${url}`)}&embedded=true`}
          style={{ width: '100%', height: '335px', margin: 0 }}
          frameBorder="0"
        />
      ) : null}
    </>
  );
};

export default AttachmentItem;
