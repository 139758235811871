/* eslint-disable react/react-in-jsx-scope */
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Button } from 'components/Button/MuiButtons/Button';
import { authenticationSlice } from 'redux/slices';

const SignButton = () => {
  const url = new URL(window.location.href);
  url.searchParams.append('from', window.location.pathname);

  const dispatch = useDispatch();

  return (
    <Stack direction="row" spacing={1} justifyContent="center">
      <Button
        variant="contained"
        label="Sign up"
        onClick={() => {
          dispatch(authenticationSlice.actions.changeSignUpOpen(true));
        }}
      />
      <Button
        variant="contained"
        label="Sign in"
        onClick={() => {
          dispatch(authenticationSlice.actions.changeSignInOpen(true));
        }}
      />
    </Stack>
  );
};

export default SignButton;
