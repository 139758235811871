import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { SportInformationModel } from 'data/entities/sportInformation.entity';
import { Textfield } from '../../../../components/Textfield';
import { RadioField, RadioFieldOption } from '../../../../components/RadioField';
import { Grid } from '../../../../components/Grid';
import { Button } from '../../../../components/Button/MuiButtons/Button';
import UserService from '../../../../data/services/user.service';
import { useProfile, UserDetailInfoState } from './useProfile';

interface MainInformationProps extends MainInformationFormFields {
  userId: string;
  orgId: string;
  refetch: () => void;
}

interface MainInformationFormFields {
  sportInfo: SportInformationModel;
  profileInfo: UserDetailInfoState;
}

const weightOptions: RadioFieldOption[] = [
  { label: 'kg', value: '0' },
  { label: 'pound', value: '1' },
];

const heightOptions: RadioFieldOption[] = [
  { label: 'cm', value: '0' },
  { label: 'inch', value: '1' },
];

export const MainInformation = ({
  userId,
  orgId,
  sportInfo,
  profileInfo,
  refetch,
}: MainInformationProps) => {
  const theme = useTheme();
  const isViewedFromMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { getMeasurementFromState } = useProfile({
    orgId,
    userId,
  });

  const [isEditMode, setIsEditMode] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [form, setForm] = React.useState<MainInformationFormFields>({
    sportInfo,
    profileInfo,
  });

  const [sportInfoChanges, setSportInfoChanges] = React.useState(0);
  const [profileInfoChanges, setProfileInfoChanges] = React.useState(0);

  /** Sport Info related */
  const getSportInfoValue = (key: keyof SportInformationModel): string =>
    form.sportInfo ? (form.sportInfo[key] as string) : '';

  const handleSportInfoChange = (key: keyof SportInformationModel, value: string) => {
    setForm({ ...form, sportInfo: { ...form.sportInfo, [key]: value } });
    setSportInfoChanges(sportInfoChanges + 1);
  };

  const handleSportInfoSubmit = async () => {
    /** Adjust update fields since the field names in db is different than the ones in UserService */
    const { sport_club, previous_coach, previous_sports, goals, position, issues } = form.sportInfo;
    const updates = {
      golfClub: sport_club,
      previousCoach: previous_coach,
      previousSport: previous_sports,
      goals,
      position,
      issues,
    };

    /**
     * Check if the sport info has been created in the db first,
     * if it is already created, then update
     * otherwise, create the new sport info
     */

    const isSportInfoAlreadyCreated = form.sportInfo._id !== '';

    if (isSportInfoAlreadyCreated) {
      await UserService.updateSportInformation(userId, orgId, [sportInfo], updates);
    } else {
      await UserService.createSportInformation(userId, orgId, updates);
    }
  };

  const handleProfileInfoSubmit = async () => {
    const { occupation, birth_place, weight, weightUnit, height, heightUnit } = form.profileInfo;
    const updates = {
      occupation,
      birth_place,
      weight: weight && weightUnit ? getMeasurementFromState(weight, weightUnit) : undefined,
      height: height && heightUnit ? getMeasurementFromState(height, heightUnit) : undefined,
    };
    await UserService.updateUserDetailsV2({ userId, orgId, updates });
  };

  /** Profile related */
  const getProfileValue = (key: keyof UserDetailInfoState): string =>
    (form.profileInfo[key] as string) ?? '';

  const handleProfileChange = (key: keyof UserDetailInfoState, value: string) => {
    setForm({ ...form, profileInfo: { ...form.profileInfo, [key]: value } });
    setProfileInfoChanges(profileInfoChanges + 1);
  };

  const getWeightHeightText = (
    key: keyof UserDetailInfoState,
    unitKey: keyof Pick<UserDetailInfoState, 'weightUnit' | 'heightUnit'>,
    options: RadioFieldOption[],
  ) => {
    const unit = options.find((option) => option.value === getProfileValue(unitKey).toString());
    const unitLabel = unit ? unit.label : '';
    return getProfileValue(key) ? `${getProfileValue(key)} ${unitLabel}` : '';
  };

  /** Final submit */
  const handleSubmit = async () => {
    setIsSubmitting(true);
    if (sportInfoChanges) {
      await handleSportInfoSubmit();
      setSportInfoChanges(0);
    }
    if (profileInfoChanges) {
      await handleProfileInfoSubmit();
      setProfileInfoChanges(0);
    }
    await refetch();
    setIsSubmitting(false);
    setIsEditMode(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <Stack
          direction="row"
          justifyContent={isViewedFromMobile ? 'flex-start' : 'flex-end'}
          alignItems="center"
        >
          <FormGroup>
            <FormControlLabel
              control={<Switch color="success" checked={isEditMode} />}
              label="Edit mode"
              onChange={() => setIsEditMode(!isEditMode)}
            />
          </FormGroup>
        </Stack>
      </Grid>
      <Grid item md={12}>
        <Textfield
          isEditMode={isEditMode}
          label="Sports club"
          value={getSportInfoValue('sport_club')}
          onChange={(value) => {
            handleSportInfoChange('sport_club', value);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Previous coach"
          value={getSportInfoValue('previous_coach')}
          onChange={(value) => {
            handleSportInfoChange('previous_coach', value);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Previous sports"
          value={getSportInfoValue('previous_sports')}
          onChange={(value) => {
            handleSportInfoChange('previous_sports', value);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Positions"
          value={getSportInfoValue('position')}
          onChange={(value) => {
            handleSportInfoChange('position', value);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Occupation"
          value={getProfileValue('occupation')}
          onChange={(value) => {
            handleProfileChange('occupation', value);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Place of birth"
          value={getProfileValue('birth_place')}
          onChange={(value) => {
            handleProfileChange('birth_place', value);
          }}
        />
      </Grid>
      {!isViewedFromMobile && (
        <Grid item md={6}>
          &nbsp;
        </Grid>
      )}
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Goals"
          value={getSportInfoValue('goals')}
          onChange={(value) => {
            handleSportInfoChange('goals', value);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Textfield
          isEditMode={isEditMode}
          label="Issues"
          value={getSportInfoValue('issues')}
          onChange={(value) => {
            handleSportInfoChange('issues', value);
          }}
        />
      </Grid>
      <Grid item md={6}>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={3}>
            <Textfield
              isEditMode={isEditMode}
              label="Weight"
              type="number"
              value={
                isEditMode
                  ? getProfileValue('weight')
                  : getWeightHeightText('weight', 'weightUnit', weightOptions)
              }
              onChange={(value) => {
                handleProfileChange('weight', value);
              }}
            />
          </Grid>
          {isEditMode && (
            <Grid item xs={9}>
              <RadioField
                id="weight-unit"
                options={weightOptions}
                value={getProfileValue('weightUnit')}
                onChange={(value) => {
                  handleProfileChange('weightUnit', value);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item md={6}>
        <Grid container rowSpacing={0} columnSpacing={2}>
          <Grid item xs={3}>
            <Textfield
              isEditMode={isEditMode}
              type="number"
              label="Height"
              value={
                isEditMode
                  ? getProfileValue('height')
                  : getWeightHeightText('height', 'heightUnit', heightOptions)
              }
              onChange={(value) => {
                handleProfileChange('height', value);
              }}
            />
          </Grid>
          {isEditMode && (
            <Grid item xs={9}>
              <RadioField
                id="height-unit"
                options={heightOptions}
                value={getProfileValue('heightUnit')}
                onChange={(value) => {
                  handleProfileChange('heightUnit', value);
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isEditMode && (
        <Grid item md={12}>
          <Stack direction="row" justifyContent="center" alignItems="center" columnGap={1}>
            <Button
              label={!isSubmitting ? 'Save' : 'Updating Profile ...'}
              disabled={isSubmitting}
              onClick={handleSubmit}
            />
            <Button
              label="Cancel"
              disabled={isSubmitting}
              variant="outlined"
              onClick={() => setIsEditMode(false)}
            />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};
