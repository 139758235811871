/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { get, orderBy } from 'lodash';
import Tooltip from '@mui/material/Tooltip';
import { ClientNote } from 'data/entities/lessons.entity';
import UserService from 'data/services/user.service';
import { User } from 'data/entities/users.entity';
import MobileClientNotesContainer from './MobileClientNotesContainer';
import styles from './ClientNotesContainer.module.scss';
import ClientNotesModal from '../../modals/ClientNotesModal/ClientNotesModal';
import ClientNotesItem from '../../components/ClientNotesItem/ClientNotesItem';
import { ButtonType } from '../../../../data/enums/ButtonType.enum';
import SessionProvider from '../../../../providers/SessionProvider';
import LessonsService from '../../../../data/services/lessons.service';
import HintIcon from '../../../../assets/icons/hint-icon.svg';

interface IProps {
  lessonId: string;
  isLessonCancelled: boolean;
  clientNotes: ClientNote[];
  onNotesCreated: Function;
}

const ClientNotesContainer: React.FC<IProps> = ({
  lessonId,
  clientNotes,
  isLessonCancelled,
  onNotesCreated,
}) => {
  const userId = SessionProvider.getUserId();
  const [showModal, setShowModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [clientNotesList, setClientNotesList] = useState<ClientNote[]>([]);
  const [clientsList, setCliensList] = useState<User[]>([]);
  const [clientNotesUpdate, setClientNotesUpdate] = useState<ClientNote | null>(null);

  const fetchClientNotes = useCallback(async () => {
    setClientNotesList(clientNotes.filter((clientNote) => !clientNote.deleted));
    const noteClients = await UserService.getNamesByIds(
      clientNotes.map((item: ClientNote) => item.user_id),
    );

    setCliensList(noteClients ?? []);
  }, [clientNotes]);

  useEffect(() => {
    fetchClientNotes();
  }, [fetchClientNotes]);

  const onClientNoteAddClick = () => setShowModal(true);
  const onModalCloseClick = () => setShowModal(false);

  const onClientNotesRemoved = async (id: string) => {
    await LessonsService.deleteClientNote(lessonId, id);
    setClientNotesList(clientNotesList.filter((clientNote) => clientNote._id !== id));
  };

  const onClientNotesUpdated = async (id: string, text: string) => {
    const clientNoteIndex = clientNotesList.findIndex((item) => item._id === id);
    const clientNote = clientNotesList[clientNoteIndex];
    clientNote.message = text;

    if (clientNote) {
      const updatingResult = await LessonsService.updateClientNote(lessonId, id, text);
      if (!updatingResult) {
        toast.error(updatingResult.message);
        return;
      }

      const clientNotesArr = [...clientNotesList];
      setClientNotesList(clientNotesArr);
    }
  };

  const onClientNotesCreated = async (text: string) => {
    const result = await LessonsService.addClientNote(lessonId, userId, text);
    if (result?.client_notes) {
      const client_notes = result.client_notes.filter((clientNote) => !clientNote.deleted);
      setClientNotesList(
        orderBy(
          client_notes,
          (clientNote: ClientNote) => {
            return clientNote.date_created;
          },
          'desc',
        ),
      );

      onNotesCreated(client_notes);
      setShowModal(false);
    }
  };

  const onShowUpdateModal = useCallback(
    (id: string, value: boolean) => {
      const currItem = clientNotesList.find((item) => item._id === id) || null;
      setClientNotesUpdate(currItem);
      setShowUpdateModal(value);
    },
    [clientNotesList],
  );

  const onCloseUpdateModal = () => setShowUpdateModal(false);

  return (
    <>
      <ClientNotesModal
        showModal={showModal}
        onClientNoteSaved={onClientNotesCreated}
        onModalCloseClick={onModalCloseClick}
        saveLabel="Save"
        typeButton={ButtonType.Save}
        title="Comments"
        placeHolder="Enter your comment here"
      />
      <ClientNotesModal
        text={get(clientNotesUpdate, 'message', '')}
        id={get(clientNotesUpdate, '_id', '')}
        showModal={showUpdateModal}
        onClientNoteSaved={onClientNotesUpdated}
        onModalCloseClick={onCloseUpdateModal}
        saveLabel="Update"
        typeButton={ButtonType.Update}
        title="Comments"
        placeHolder="Enter your comment here"
      />
      <MobileClientNotesContainer
        clientNotes={clientNotesList}
        onClientNoteRemoved={onClientNotesRemoved}
        showAddModal={showModal}
        clientsList={clientsList}
        onShowUpdateModal={onShowUpdateModal}
        onAddClientNotes={onClientNoteAddClick}
      />
      <div className={styles.ClientNotes}>
        <div>
          <div className={styles.header}>
            <div className={styles.ClientNotesHeader}>
              <span>Comments</span>
              <Tooltip title="Shared to coach and confirmed participants">
                <img alt="Hint Icon" src={HintIcon} className={styles.hintIcon} />
              </Tooltip>
            </div>
            {!isLessonCancelled && <span onClick={onClientNoteAddClick}>Add+</span>}
          </div>
          <div className={styles.wrapper}>
            {clientNotesList.map((clientNote, i) => (
              <ClientNotesItem
                key={i}
                id={clientNote._id}
                text={clientNote.message}
                userId={clientNote.user_id}
                clients={clientsList}
                onClientNoteRemoved={onClientNotesRemoved}
                onShowUpdateModal={onShowUpdateModal}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientNotesContainer;
