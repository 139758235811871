import React from 'react';
import clsx from 'clsx';
import { subDays } from 'date-fns';
import { get } from 'lodash';
import styles from './QuestionnaireInputs.module.scss';
import { QuestionnaireType } from '../../models/questionnaire.model';
import AddressInput from '../../../../components/Inputs/AddressInput/AddressInput';
import MultiAnswerInput from '../../../../components/Inputs/MultiAnswerInputs/MultiAnswerInputs';
import CheckBox from '../../../../components/Checkbox/Checkbox';
import DatePicker from '../../../../components/DatePicker/DatePicker';
import CountryCodePhoneInput from '../../../../components/Inputs/CountryCodePhoneInput/CountryCodePhoneInput';

interface IQuestionnaireInputs {
  currentType: number;
  currentQuestion: Record<string, any>;
  formValues: any;
  setFormValues: Function;
  profileData: any;
  sportData: any;
}

const QuestionnaireInputs: React.FC<IQuestionnaireInputs> = ({
  currentType,
  currentQuestion,
  formValues,
  setFormValues,
  profileData,
  sportData,
}) => {
  let sportDataObj: any;
  let profileDataObj: any;
  if (sportData) {
    // eslint-disable-next-line prefer-destructuring
    sportDataObj = sportData[0];
  }
  if (profileData) {
    // eslint-disable-next-line prefer-destructuring
    profileDataObj = profileData[0];
  }

  // eslint-disable-next-line consistent-return
  const getGenderValue = (item: any) => {
    if (item === 1) {
      return 'male';
    }
    if (item === 2) {
      return 'female';
    }
    if (item === 0) {
      return 'other';
    }
  };

  let addressString = '';
  if (profileDataObj && profileDataObj.address) {
    addressString += `${profileDataObj.address.street_number} ` ?? '';
    addressString += `${profileDataObj.address.street_name} ` ?? '';
    addressString += `${profileDataObj.address.suburb} ` ?? '';
    addressString += `${profileDataObj.address.city} ` ?? '';
    addressString += `${profileDataObj.address.country}` ?? '';
  }

  const getDefaultValues = (value: string) => {
    let values: any = '';
    if (sportDataObj) {
      // eslint-disable-next-line no-unused-expressions
      (value === 'golfClub' && (values = sportDataObj.sport_club)) ||
        (value === 'previousCoach' && (values = sportDataObj.previous_coach)) ||
        (value === 'previousSport' && (values = sportDataObj.previous_sports)) ||
        (value === 'position' && (values = sportDataObj.position)) ||
        (value === 'goals' && (values = sportDataObj.goals)) ||
        (value === 'issues' && (values = sportDataObj.issues));
    }
    return values;
  };

  return (
    <div className={styles.container}>
      {currentType === QuestionnaireType.Address && (
        <AddressInput
          label=""
          value={get(formValues, currentQuestion.field, addressString)}
          setValue={(address) => {
            if (address !== '') {
              setFormValues({
                ...formValues,
                [currentQuestion.field]: address,
              });
            } else {
              setFormValues({
                ...formValues,
                [currentQuestion.field]: null,
              });
            }
          }}
        />
      )}

      {currentType === QuestionnaireType.Choice &&
        currentQuestion.choices.map((item: { value: any; label: any }, index: any) => {
          let matchValue: any = '';
          if (currentQuestion.field === 'eyeDomination') {
            matchValue = sportDataObj && sportDataObj.dominations.eye;
          } else if (currentQuestion.field === 'handDomination') {
            matchValue = sportDataObj && sportDataObj.dominations.hand;
          } else if (currentQuestion.field === 'legDomination') {
            matchValue = sportDataObj && sportDataObj.dominations.leg;
          } else if (currentQuestion.field === 'gender') {
            matchValue = getGenderValue(profileDataObj && profileDataObj.gender_enum);
          }

          // eslint-disable-next-line no-nested-ternary, no-prototype-builtins
          const checked = formValues?.hasOwnProperty(currentQuestion.field)
            ? get(formValues, currentQuestion.field)
              ? get(formValues, currentQuestion.field) === item.value
              : false
            : matchValue === item.value;

          return (
            <div
              key={`${item.label}-${item.value}`}
              className={clsx(styles.choiceBox, checked && styles.selectedChoice)}
              onClick={() => {
                if (checked) {
                  setFormValues({
                    ...formValues,
                    [currentQuestion.field]: null,
                  });
                } else {
                  setFormValues({
                    ...formValues,
                    [currentQuestion.field]: item.value,
                  });
                }
              }}
            >
              <div
                className={styles.choiceBoxCheck}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <CheckBox id={index} checked={!!checked} />
              </div>
              {item.label}
            </div>
          );
        })}
      {currentType === QuestionnaireType.Phone && (
        <CountryCodePhoneInput
          label="Phone Number"
          onChange={(value: any, country: any, e: any, formattedValue: any) => {
            setFormValues({
              ...formValues,
              [currentQuestion.field]: formattedValue,
              [currentQuestion.fieldCountry]: country,
              [currentQuestion.fieldNumber]: value.slice(country?.dialCode.length),
            });
          }}
          defaultValue={get(
            formValues,
            currentQuestion.field,
            profileDataObj &&
              profileDataObj.contact &&
              `${profileDataObj.contact.country_code}${profileDataObj.contact.phone_number}`,
          )}
          placeholder="+64 000 000 00 00"
        />
      )}
      {currentType === QuestionnaireType.MultiTask && (
        <MultiAnswerInput
          value={get(formValues, currentQuestion.field, getDefaultValues(currentQuestion.field))}
          dataValue={sportDataObj}
          onChanged={(answers: any) => {
            setFormValues({
              ...formValues,
              [currentQuestion.field]: answers,
            });
          }}
        />
      )}
      {currentType === QuestionnaireType.Date && (
        <DatePicker
          min={new Date('01.01.1900')}
          max={subDays(new Date(), 1)}
          date={get(
            formValues,
            currentQuestion.field,
            profileDataObj && profileDataObj.date_of_birth
              ? new Date(profileDataObj.date_of_birth).toISOString()
              : new Date().toISOString(),
          )}
          onSelect={(isoDate: string) => {
            setFormValues({
              ...formValues,
              [currentQuestion.field]: isoDate,
            });
          }}
        />
      )}
    </div>
  );
};

export default QuestionnaireInputs;
