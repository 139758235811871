import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import React, { useContext } from 'react';
import { DateTime } from 'luxon';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { BrandThemeContext } from 'common/context';
import { containerRadius } from './config';
import { Tab } from './Tab';
import { SelectedCalendarDate } from '../../MonthlyCalendar';
import { useMobileViewDetector } from '../../../../../../../common/hooks';

export type ActiveTab = 'morning' | 'afternoon';

export interface TabContainerProps {
  activeTab: ActiveTab;
  selectedDate: SelectedCalendarDate;
  onSwitchSelectedDate: () => void;
  onSwitchTab: (activeTab: ActiveTab) => void;
  children: React.ReactNode;
}

export const TabContainer = ({
  activeTab,
  selectedDate,
  onSwitchTab,
  onSwitchSelectedDate,
  children,
}: TabContainerProps) => {
  const theme = useContext(BrandThemeContext);
  const { isViewedFromMobile } = useMobileViewDetector();
  const formattedSelectedDate = DateTime.fromObject(selectedDate).toFormat('dd LLL yyyy');
  const handleSwitchTab = (tab: ActiveTab) => onSwitchTab(tab);

  const timeSlotsBox = {
    height: !isViewedFromMobile ? 360 : undefined,
    paddingRight: !isViewedFromMobile ? 2 : 2.5,
  };

  return (
    <Box>
      <Box>
        <Stack direction="row">
          <Tab
            label="Morning"
            isActive={activeTab === 'morning'}
            onClick={() => handleSwitchTab('morning')}
          />
          <Tab
            label="Afternoon"
            isActive={activeTab === 'afternoon'}
            onClick={() => handleSwitchTab('afternoon')}
          />
        </Stack>
      </Box>
      <Box
        bgcolor={theme.colors.black.opacity_5}
        sx={{
          borderBottomLeftRadius: containerRadius,
          borderBottomRightRadius: containerRadius,
          borderTopRightRadius: containerRadius,
          borderTopLeftRadius: activeTab !== 'morning' ? containerRadius : 0,
          paddingY: 2,
        }}
      >
        <Box
          height={timeSlotsBox.height}
          fontSize="1rem"
          // Keep this as auto. The scroll should be displayed only if the content overflow. If the overflow prop is 'scroll', the horizontal scroll will be shown, and it will cause the UI problem again. There is no need for horizontal scroll.
          overflow="auto"
          paddingLeft={2}
          paddingRight={timeSlotsBox.paddingRight}
        >
          <Box
            bgcolor={theme.colors.black.opacity_10}
            fontWeight="bold"
            borderRadius={1}
            marginBottom={0.5}
            padding={1}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box>{formattedSelectedDate}</Box>
              {isViewedFromMobile && (
                <Box
                  fontSize="0.8rem"
                  bgcolor={theme.colors.jungleGreen.opacity_75}
                  borderRadius={4}
                  paddingX={1}
                  sx={{ cursor: 'pointer' }}
                  onClick={onSwitchSelectedDate}
                >
                  switch
                  <EventRepeatIcon sx={{ fontSize: '1rem', marginLeft: 0.5 }} />
                </Box>
              )}
            </Stack>
          </Box>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
