import React, { useEffect, useState } from 'react';
import styles from './SearchClientItem.module.scss';
import UserAvatar from '../../../../components/UserInfo/UserAvatar';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import { ISearchClient } from '../../models/searchClient.model';

interface ISearchClientItem {
  client: ISearchClient;
  onChecked: Function;
}
const SearchClientItem: React.FC<ISearchClientItem> = ({ client, onChecked }) => {
  const [checkedItem, setCheckedItem] = useState(false);

  useEffect(() => {
    if (checkedItem) {
      onChecked([client], checkedItem);
    } else {
      onChecked([], checkedItem);
    }
  }, [checkedItem, client, onChecked]);

  return (
    <div className={!checkedItem ? styles.item : styles.chekedItem}>
      <div className={styles.content} onClick={() => setCheckedItem(!checkedItem)}>
        <UserAvatar name={client.name} className={styles.clientAvatar} />
        <div className={styles.info}>
          <span className={styles.name}>{client.name}</span>
          <span className={styles.contacts}>{client.phone}</span>
          <span className={styles.contacts}>{client.email}</span>
        </div>
      </div>
      <Checkbox id={client.id} onCheck={setCheckedItem} checked={checkedItem} />
    </div>
  );
};

export default SearchClientItem;
