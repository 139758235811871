/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect } from 'react';
import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import SelectCategoriesButtons from 'modules/lesson/components/SelectCategoriesButtons/SelectCategoriesButtons';
import { LessonCategory } from 'data/entities/orgLessonCategories.entity';
import { AssociateWithAvatar } from 'data/entities/associate.entity';
import { addressStringBuilder } from 'common/utils/response.helper';
import { Address, UserContact } from 'data/entities/userContacts.entity';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import PracticeLocationModal from 'modules/practiceLocations/modals/PracticeLocationsModal';
import ContactsModal from 'modules/contacts/modals/ContactsModal';
import { AvatarHelper } from 'common/utils/avatar.helpers';
import styles from './EventViewContent.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import RemoveIcon from '../../../../assets/icons/remove-icon.svg';

interface IEventViewContentProps {
  isUpdate: boolean;
  deleteEvent: Function;
  eventTitle: string;
  setEventTitle: (title: string) => void;
  eventNote: string;
  setEventNote: (note: string) => void;
  startDate: number;
  setStartDate: (date: number) => void;
  endDate: number;
  setEndDate: (date: number) => void;
  startDateIndex: number;
  setStartDateIndex: (index: number) => void;
  placeholderName: string;
  isPracticeLesson: boolean | undefined;
  categories: LessonCategory[];
  isPractice: boolean;
  setIsPractice: (type: boolean) => void;
  selectedCategories: string[];
  setSelectedCategories: (categories: []) => void;
  categorieslist: LessonCategory[];
  isShareCoach: boolean;
  setIsShareCoach: (isShare: boolean) => void;
  address: Address | undefined;
  setAddress: (address: any) => void;
  locations: UserPracticeLocation[];
  setLocations: (locations: UserPracticeLocation[]) => void;
  chosenLocationId: string | undefined;
  setChosenLocationId: (location_id: string) => void;
  associates: AssociateWithAvatar[];
  contacts: UserContact[];
  selectedContactList: string[];
  setSelectedContactList: (list: string[]) => void;
  addPracticeLocationView: boolean;
  setAddPracticeLocationView: (flag: boolean) => void;
  onPracticeLocationAdded: (location: UserPracticeLocation) => void;
  addContactView: boolean;
  setAddContactView: (flag: boolean) => void;
  onContactAdded: (contact: UserContact) => void;
}

const EventViewContent: React.FC<IEventViewContentProps> = ({
  isUpdate,
  deleteEvent,
  eventTitle,
  setEventTitle,
  eventNote,
  setEventNote,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  isPractice,
  setIsPractice,
  selectedCategories,
  setSelectedCategories,
  categorieslist,
  isShareCoach,
  setIsShareCoach,
  locations,
  chosenLocationId,
  setChosenLocationId,
  isPracticeLesson,
  placeholderName,
  associates,
  contacts,
  selectedContactList,
  setSelectedContactList,
  addPracticeLocationView,
  setAddPracticeLocationView,
  onPracticeLocationAdded,
  addContactView,
  setAddContactView,
  onContactAdded,
}) => {
  useEffect(() => {
    setIsPractice(isPracticeLesson || false);
  }, [isPracticeLesson]);

  const locationSelectOptions = locations.map((location: UserPracticeLocation) => (
    <option key={location.location_id} value={location.location_id}>
      {location.name}
    </option>
  ));

  const associateAvatars = associates.map((associate: AssociateWithAvatar) => (
    <div key={associate.user_id} className={styles.associates}>
      {associate.avatar.length > 0 ? (
        <Avatar
          key={associate._id}
          alt={`${associate.user.first_name} ${associate.user.last_name}`}
          src={associate.avatar[0].file_url}
          sx={{
            width: '48px',
            height: '48px',
          }}
        />
      ) : (
        <Avatar key={associate._id} sx={{ width: '48px', height: '48px' }}>
          {AvatarHelper.stringToAvatar({
            firstName: associate.user.first_name,
            lastName: associate.user.last_name,
          })}
        </Avatar>
      )}
      <span
        style={{ fontSize: '12px', textAlign: 'center' }}
      >{`${associate.user.first_name} ${associate.user.last_name}`}</span>
    </div>
  ));

  const currentLocationIndex = locations.findIndex(
    (location: UserPracticeLocation) => location.location_id === chosenLocationId,
  );

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Title</span>
      <input
        className={styles.titleInput}
        type="text"
        placeholder={placeholderName}
        value={eventTitle}
        onChange={(e) => setEventTitle(e.target.value)}
        maxLength={50}
      />

      {isPracticeLesson && (
        <>
          <span className={styles.associate}>Location</span>
          {locations.length > 0 && (
            <select
              id="choose-location"
              className={styles.associateInput}
              value={chosenLocationId ?? ''}
              onChange={(event) => {
                setChosenLocationId(event.target.value);
              }}
            >
              {locationSelectOptions}
            </select>
          )}
          <div className={styles.button} onClick={() => setAddPracticeLocationView(true)}>
            + Add Practice locations
          </div>
          {locations.length > 0 && (
            <>
              <span className={styles.associate}>Address</span>
              <input
                className={styles.associateInput}
                type="text"
                placeholder="Address"
                value={addressStringBuilder(locations[currentLocationIndex]?.address)}
                disabled
                maxLength={50}
              />
            </>
          )}
        </>
      )}

      <span className={styles.associate}>Associates</span>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)' }}>
        {associateAvatars}
      </div>

      <span className={styles.associate}>Contacts</span>

      {selectedContactList &&
        selectedContactList
          .map((id) => contacts.find((item) => item._id === id))
          .filter(Boolean)
          .map((contact: UserContact | undefined, i) => (
            <div key={i} className={styles.contactList}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{contact?.name}</span>
                <span style={{ fontSize: '14px' }}>{contact?.email}</span>
              </div>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setSelectedContactList(
                    selectedContactList.filter((item) => item !== contact?._id),
                  );
                }}
              >
                x
              </span>
            </div>
          ))}

      {selectedContactList && contacts && contacts.length > 0 && (
        <select
          id="choose-contacts"
          className={styles.associateInput}
          value={0}
          onChange={(event) => {
            setSelectedContactList([...selectedContactList, event.target.value]);
          }}
        >
          <option value={0} />
          {contacts
            .filter((item) => !selectedContactList.includes(item._id))
            .map((item, i) => (
              <option key={i} value={item._id}>
                {item.name}
              </option>
            ))}
        </select>
      )}

      <div className={styles.button} onClick={() => setAddContactView(true)}>
        + Add Contacts
      </div>

      {isPracticeLesson && (
        <>
          <span className={styles.associate}>Categories</span>
          <SelectCategoriesButtons
            categories={categorieslist}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </>
      )}
      <span className={styles.note}>Note</span>
      <textarea
        className={styles.noteTextarea}
        placeholder="Additional info that can help you"
        value={eventNote}
        onChange={(e) => setEventNote(e.target.value)}
      />

      <div className={styles.selectDates}>
        <div>
          <span>Starts</span>
          <div className={styles.selectWrapper}>
            <DatePicker
              selected={startDate ? new Date(startDate) : new Date()}
              showTimeSelect
              timeIntervals={10}
              dateFormat="Pp"
              onChange={(date) => {
                if (date instanceof Date) {
                  const chosenDate = DateTime.fromJSDate(date);
                  setStartDate(chosenDate.toMillis());
                  const defaultingEndDate = chosenDate.plus({ hour: 1 }).toMillis();
                  setEndDate(defaultingEndDate);
                }
              }}
            />
          </div>
        </div>
        <div>
          <span>Ends</span>

          <div className={styles.selectWrapper}>
            <DatePicker
              selected={endDate ? new Date(endDate) : new Date()}
              showTimeSelect
              dateFormat="Pp"
              timeIntervals={10}
              onChange={(date) => {
                if (date instanceof Date) {
                  const convertedDate = DateTime.fromJSDate(date);
                  const convertedDateMilli = convertedDate.toMillis();
                  if (convertedDateMilli < startDate) {
                    toast.error('End time must be greater than start time');
                    return;
                  }
                  setEndDate(convertedDateMilli);
                }
              }}
            />
          </div>
        </div>

        {isUpdate && !isPractice && (
          <img
            className={styles.remove}
            alt="remove-icon"
            src={RemoveIcon}
            onClick={() => deleteEvent()}
          />
        )}
      </div>

      {isPracticeLesson && (
        <div style={{ marginTop: 15, marginBottom: 15, width: '100%' }}>
          <Switch
            color="success"
            checked={isShareCoach}
            onChange={() => setIsShareCoach(!isShareCoach)}
          />
          <span>Share with Coach</span>
        </div>
      )}

      <PracticeLocationModal
        showModal={addPracticeLocationView}
        onModalCloseClick={() => setAddPracticeLocationView(false)}
        saveLabel="Create"
        onPracticeLocationSaved={onPracticeLocationAdded}
        titleModal="Add practice location"
      />

      <ContactsModal
        showModal={addContactView}
        onModalCloseClick={() => setAddContactView(false)}
        saveLabel="Create"
        onContactsSaved={onContactAdded}
        titleModal="Add contact"
      />
    </div>
  );
};

export default EventViewContent;
