import React, { useCallback } from 'react';
import * as Realm from 'realm-web';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import SessionProvider from '../../../providers/SessionProvider';
import { Config } from '../../../config';
import { AuthLocalStorage } from '../../auth/utils/auth.helper';
import StaticDictionary from '../../../common/utils/staticDictionary.helper';

const NoOrgsPage = () => {
  const history = useHistory();
  const app = Realm.getApp(Config().RealmAppId as string);

  const removeUser = useCallback(async () => {
    if (app.currentUser) {
      await app.removeUser(app?.currentUser);
    }
  }, [app]);

  const handleLogOut = async () => {
    await removeUser();
    await Auth.signOut();
    if (SessionProvider.getAccessToken()) {
      SessionProvider.removeSession();
      AuthLocalStorage.removeAuthLocalStorage();
    }

    history.push('/login');
  };

  return (
    <div
      className="container d-flex flex-column align-items-center justify-content-center w-auto mt-5"
      style={{
        maxWidth: '700px',
      }}
    >
      <h1 className="text-center mb-5">
        Your account is not linked to any organizations, please check your email address
      </h1>
      {/* 045.04.23 - Future Feature: Allow user's to search for orgs that have guest services? */}
      {/* <Link to={StaticRoutes.Book} className="btn btn-dark w-100 mb-2 p-3">
        Lets see what you can book
      </Link> */}
      <button type="button" className="btn btn-outline-dark w-100 mb-4 p-3" onClick={handleLogOut}>
        Sign out
      </button>

      <a
        href={StaticDictionary.ExternalLinks.help}
        target="_blank"
        rel="noreferrer"
        className="text-centre text-muted"
      >
        Need some help?
      </a>
    </div>
  );
};

export default NoOrgsPage;
