/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import * as Realm from 'realm-web';
import { Auth } from 'aws-amplify';
import SessionProvider from '../../../../providers/SessionProvider';
import { Api } from '../../../../api/Api';
import Spinnerv2 from '../../../../components/Spinnerv2/Spinnerv2';
import { Config } from '../../../../config';
import { AuthLocalStorage } from '../../../auth/utils/auth.helper';
import OrganizationService from '../../../../data/services/organization.service';
import { UserType } from '../../../../data/enums/UserType.enum';
import { OrgInvitationModel } from '../../../../data/models/orgInvitation.model';
import CenteredError from '../../../../components/CenteredError/CenteredError';
import Logger from '../../../../middleware/logger.middleware';

const AcceptOrgStaffInvitePage = () => {
  const [loading, setLoading] = useState(true);
  const [isValidInvite, setIsValidInvite] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['orgInvitation']);

  const app = Realm.getApp(Config().RealmAppId as string);
  const userId = SessionProvider.getUserId();
  const history = useHistory();

  const validateLogicCompleted = useRef(false);

  const doInviteInvalidLogic = useCallback(
    (errorMessageParam: string) => {
      setErrorMessage(errorMessageParam);
      setIsValidInvite(false);
      setLoading(false);
      removeCookie('orgInvitation', { path: '/' });
    },
    [removeCookie],
  );

  const handleLogOut = async () => {
    if (app.currentUser) {
      await app.removeUser(app?.currentUser);
    }
    await Auth.signOut();
    if (SessionProvider.getAccessToken()) {
      SessionProvider.removeSession();
      AuthLocalStorage.removeAuthLocalStorage();
    }

    history.push('/login');
  };

  const acceptInviteAsync = useCallback(
    async (orgInvitationModelResult: OrgInvitationModel) => {
      const response = await Api.CoachRoutes.Org.acceptMemberInvite(
        orgInvitationModelResult.orgId,
        orgInvitationModelResult.inviteId,
        userId,
      );
      removeCookie('orgInvitation', { path: '/' });
      history.push(
        `/org/${orgInvitationModelResult.orgId}/inviteAccepted?type=staff&success=true&msg=${response?.data}`,
      );
    },
    [history, removeCookie, userId],
  );

  const validateAndAcceptStaffInvite = useCallback(async () => {
    try {
      if (Logger.isDevEnvironment) console.log('validateAndAcceptStaffInvite');
      const orgInvitationModelResult: OrgInvitationModel | undefined = cookies.orgInvitation;
      if (!orgInvitationModelResult) {
        doInviteInvalidLogic('There was an error fetching your invitation data.');
        return;
      }
      // setOrgInvitationModel(orgInvitationModelResult);

      const validationResult = await OrganizationService.validateOrgInvite(
        UserType.coach,
        orgInvitationModelResult.orgId,
        orgInvitationModelResult.inviteId,
        SessionProvider.getEmail(),
      );
      if (!validationResult?.isActiveInviteAndCorrectUser) {
        doInviteInvalidLogic(validationResult.errorMessage);
        return;
      }

      setIsValidInvite(true);
      await acceptInviteAsync(orgInvitationModelResult);
    } catch (error: any) {
      console.log(error);
      doInviteInvalidLogic(error.message);
    } finally {
      setLoading(false);
    }
  }, [acceptInviteAsync, cookies.orgInvitation, doInviteInvalidLogic]);

  useEffect(() => {
    if (!validateLogicCompleted.current) {
      validateAndAcceptStaffInvite();
      validateLogicCompleted.current = true;
    }

    return () => {
      validateLogicCompleted.current = false;
    };
    // We leave dependency array empty so logic is only called once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Spinnerv2 message="Accepting invite..." />;
  }

  if (!isValidInvite) {
    return (
      <CenteredError message={errorMessage} showBtn btnLabel="Log out" onBtnClick={handleLogOut} />
    );
  }

  return <Spinnerv2 message="Loading..." />;
};

export default AcceptOrgStaffInvitePage;
