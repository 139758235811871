import { v4 as uuidv4 } from 'uuid';
import RealmRepositories from '../base/realm.repo';
import { Event } from '../entities/events.entity';
import { getUnixMilliseconds } from '../../common/utils/date.helpers';

export default class EventsService {
  static async createEvent(
    userId: string,
    orgId: string,
    title: string,
    note: string,
    dateBegin: number,
    dateEnd: number,
    chosenLocationId: string,
    selectedContactList: string[],
    selectedCategories: string[],
    isShareCoach: boolean,
  ) {
    const clientNote = note !== '' ? note : undefined;
    const event: Event = {
      _id: uuidv4(),
      user_id: userId,
      org_id: orgId,
      title,
      note: clientNote,
      date_begin: dateBegin,
      date_end: dateEnd,
      date_created: getUnixMilliseconds(),
      date_updated: getUnixMilliseconds(),
      deleted: false,
      org_lesson_category_ids: selectedCategories,
      share_event_with_coach: isShareCoach,
      contacts_ids: selectedContactList,
      practice_location_id: chosenLocationId,
    };

    await RealmRepositories.Events.createEvent(event);
    return event;
  }

  static async getAllEventsByUserId(userId: string) {
    const result = await RealmRepositories.Events.getAllEventsByUserId(userId);
    return result;
  }

  static async updateEventById(
    eventId: string,
    title: string,
    note: string,
    dateBegin: number,
    dateEnd: number,
    selectedContactList: string[],
  ): Promise<Event> {
    const result = await RealmRepositories.Events.updateEventById(
      eventId,
      title,
      note,
      dateBegin,
      dateEnd,
      selectedContactList,
    );
    return result;
  }

  static async deleteEventById(eventId: string) {
    const result = await RealmRepositories.Events.deleteEventById(eventId);
    return result;
  }
}
