import React, { useContext } from 'react';
import { SxProps, Box, Stack, Typography } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { BrandThemeContext } from 'common/context';
import { Button } from '../../../../../../../components/Button/MuiButtons/Button';

export interface TimeSlotProps {
  startTime: string;
  endTime: string;
  isActive: boolean;
  isPurchased?: boolean;
  isBusy?: boolean;
  hasBeenSelected: boolean;
  onClick: () => void;
  onConfirm: () => void;
  onCancel?: () => void;
  disableSelectNewTime: boolean;
}

export const TimeSlot = ({
  startTime,
  endTime,
  isActive,
  isPurchased,
  isBusy,
  hasBeenSelected,
  onClick,
  onConfirm,
  onCancel,
  disableSelectNewTime,
}: TimeSlotProps) => {
  /** Text Styles */
  const theme = useContext(BrandThemeContext);
  const defaultTextStyle: SxProps = { fontSize: '0.85rem' };
  const selectedTextStyle: SxProps = { ...defaultTextStyle, color: theme.colors.black.opacity_20 };

  let labelForSelectedTimeSlot = '';
  if (isPurchased) {
    labelForSelectedTimeSlot = '- Purchased';
  } else if (isBusy) {
    labelForSelectedTimeSlot = '- Unavailable';
  } else if (hasBeenSelected) {
    labelForSelectedTimeSlot = '- Selected';
  }

  return (
    <Box
      padding={1}
      borderRadius={1}
      bgcolor={theme.colors.white}
      marginBottom={0.5}
      onClick={!labelForSelectedTimeSlot ? onClick : undefined}
      sx={{ cursor: 'pointer' }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={
              !labelForSelectedTimeSlot || labelForSelectedTimeSlot === '- Selected'
                ? defaultTextStyle
                : selectedTextStyle
            }
          >
            {startTime} {labelForSelectedTimeSlot}
          </Typography>
          {isActive && !labelForSelectedTimeSlot && (
            <Typography sx={selectedTextStyle}>- {endTime}</Typography>
          )}
        </Stack>
        {onCancel && (
          <Stack direction="row" marginLeft={1}>
            <CheckBoxIcon
              sx={{
                color: theme.colors.jungleGreen.opacity_100,
                fontSize: '1.2rem',
                marginRight: '4px',
              }}
              onClick={onCancel}
            />
            <Box fontSize="0.8rem" onClick={onCancel}>
              Remove
            </Box>
          </Stack>
        )}
        {isActive && !labelForSelectedTimeSlot && (
          <Button
            size="small"
            sx={{
              px: 2,
            }}
            variant="contained"
            label="Confirm"
            onClick={onConfirm}
            disabled={disableSelectNewTime}
          />
        )}
      </Stack>
    </Box>
  );
};
