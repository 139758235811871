/* eslint-disable max-classes-per-file */
class Password {
  public readonly formatMessage =
    'Your password must be at least 8 characters long. 1 Capitalized letter, 1 Special character and 1 digit.';
}

class ExternalLinks {
  public readonly help = 'https://gettotalcoach.com/contact/';

  public readonly privacyPolicy =
    'https://app.termly.io/document/privacy-policy/6e00f951-a647-420a-923a-256d49fe3e97';

  public readonly termsOfUse =
    'https://app.termly.io/document/terms-of-use-for-website/a9c5b794-f403-4713-ae46-8fb5289d868f';
}

class PaymentParams {
  public readonly PaymentIntentUrlParam = 'payment_intent';

  public readonly ClientSecretUrlParam = 'payment_intent_client_secret';

  public readonly InvoiceNumberUrlParam = 'invoice_number';

  public readonly CurrencyUrlParam = 'currency';

  public readonly PaymentAuthentication = 'payment_authentication';
}

class BookingParams {
  public readonly InvitationId = 'invitationId';
}

class OrganizationParams {
  public readonly InvitationId = 'invitationId';
}

class UrlParameters {
  public Payments = new PaymentParams();

  public Booking = new BookingParams();

  public Organization = new OrganizationParams();
}

class LessonCategories {
  public readonly Golf = [
    'Putting',
    'Chipping',
    'Pitching',
    'Irons/Hybrids',
    'Fairway woods',
    'Drivers',
    'Game Prep',
  ];

  public readonly Tennis = [
    'Serve',
    'Backhand',
    'Approach',
    'Forehand',
    'Volley',
    'Smash',
    'Lob',
    'Passing',
    'Return',
    'Game Prep',
  ];
}

class StaticDictionary {
  public static UrlParameters = new UrlParameters();

  public static Password = new Password();

  public static ExternalLinks = new ExternalLinks();

  public static readonly AppName = 'TotalCoach';

  public static LessonCategories = new LessonCategories();
}

export default StaticDictionary;
