import { createSlice } from '@reduxjs/toolkit';
import { ApplicationState } from './types';
import {
  displayNotification,
  closeNotification,
  getOrgId,
  getOrgDetails,
  getOrgBookingRules,
  loadWholeApplicationState,
} from './actions';

const initialState: ApplicationState = {
  active: {
    orgId: null,
    orgBookingRules: null,
    orgDetails: null,
  },
  notification: {
    open: false,
    severity: 'warning',
    content: '',
  },
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    /** Notification related */
    displayNotification,
    closeNotification,

    /** Org related */
    getOrgId,
    getOrgDetails,
    getOrgBookingRules,

    /** Localstorage related */
    loadWholeApplicationState,
  },
});

export * from './types';
export * from './actions';
