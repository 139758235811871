import React, { useState } from 'react';
import { UserPracticeLocation } from 'data/entities/profile.entity';
import styles from './PracticeLocationItem.module.scss';
import UserAvatar from '../../../../components/UserInfo/UserAvatar';
import PracticeLocationDetail from '../PracticeLocationDetail/PracticeLocationDetail';

interface IProps {
  practiceLocation: UserPracticeLocation;
  onPracticeLocationUpdated?: (practiceLocation: UserPracticeLocation) => void;
  onDeletePracticeLocation?: (locationId: string) => void;
  setHideAddBtn?: any;
}

const PracticeLocationItem: React.FC<IProps> = ({
  practiceLocation,
  onPracticeLocationUpdated,
  onDeletePracticeLocation,
  setHideAddBtn,
}) => {
  const [detailOpened, setDetailOpened] = useState(false);
  const practiceLocationName = practiceLocation.name;

  const onDetailOpen = () => {
    setDetailOpened(true);
  };

  const onDetailClose = () => {
    setDetailOpened(false);
    setHideAddBtn(false);
  };

  return (
    <>
      {detailOpened && (
        <PracticeLocationDetail
          onCloseDetail={onDetailClose}
          currentPracticeLocation={practiceLocation}
          onUpdatePracticeLocation={onPracticeLocationUpdated}
          onDeletePracticeLocation={onDeletePracticeLocation}
          setHideAddBtn={setHideAddBtn}
        />
      )}
      <div className={styles.practiceLocation} onClick={onDetailOpen}>
        <UserAvatar className={styles.avatar} name={practiceLocationName} />
        <div className={styles.practiceLocationInfo}>
          <div className={styles.name}>{practiceLocationName}</div>
        </div>
      </div>
    </>
  );
};

export default PracticeLocationItem;
